import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import i18next from 'i18next';
import { AppState } from '../../internal/modules/Reducers';
import Actions from '../../internal/modules/Actions';
import {Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import $ from 'jquery';
import './User.css';

interface Props extends Translate{
  rowData?: any,
  formtype?: any;
  closeModal?: any;
  editUserList: (any) => {};
  createUser: (any) => {};
}

interface State{
  formData?: any;
  rowData?: any;
  dropdownOpen: boolean;
  dropdownLabel: string;
  fieldErrMsg?: any;
}

class UserForm extends Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        rowData: this.props.rowData,
        dropdownOpen:false,
        dropdownLabel: this.props.rowData['role'] ? i18next.t('mngUser.'+this.props.rowData['role']) : '',
        fieldErrMsg:{
          firstNameErr: '',
          lastNameErr: '',
          emailErr: '',
          passwordErr: ''
        }
      }
      this.toggle = this.toggle.bind(this);
      this.dropdownHandler = this.dropdownHandler.bind(this);
  }

   componentDidMount(){
     if (this.props.formtype === 'add') {
       let data = {...this.state.rowData};
       data['role'] = 'user';
       this.setState({rowData: data});
       this.setState({dropdownLabel: i18next.t('mngUser.user')});
     }
   }

   // setting input field value for first name, last name, email and password on change.
  eventHandler(e){
    let data = {...this.state.rowData};
    data[e.target.id] = e.target.value;
    this.setState({rowData: data});
  }

  //open role dropdown
  toggle = () =>{
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  //set dropdown value.
  dropdownHandler = (role) =>{
    let data = {...this.state.rowData};
    data['role'] = role;
    this.setState({rowData: data});
    this.setState({dropdownLabel: i18next.t('mngUser.'+role)});
  }

   //check mandatory field are filled or not and display error message.
  validateField = (event) =>{
    let {fieldErrMsg} = this.state;
    fieldErrMsg[event.target.id+"Err"] = '';
    var emailFormat = /\S+@\S+\.\S+/;
    if ((event.target.type === 'text' || event.target.type === 'password') && event.target.value.trim() === '') {
      event.target.value = '';
      fieldErrMsg[event.target.id+"Err"] = i18next.t('error.requiredFieldMsg');
    }
    else if (event.target.type === 'email' && !emailFormat.test(event.target.value)) {
      fieldErrMsg[event.target.id+"Err"] = i18next.t('error.requiredFieldMsg');
    }
    this.setState(fieldErrMsg);
  }

  //create and edit user api call and save changes.
  SaveFormHandler = () =>{
    if (this.props.formtype === 'edit') {
      this.props.editUserList(this.state.rowData);
    }
    else{
      this.props.createUser(this.state.rowData);
    }
    this.props.closeModal();
  }

  render() {
    const {fieldErrMsg} = this.state;

    return (
      <Translation>{t => (
        <Fragment>
          <form onSubmit={this.SaveFormHandler} id="userForm">
            <div className="form-group row" id="userRoleToggleDiv">
              <h6 className="col-sm-4 col-form-label"><b>{t('role')} *</b></h6>
              <div className="col-sm-8">
              <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret id={this.state.rowData['role']+'ID'}>
                  {this.state.dropdownLabel && this.state.dropdownLabel.charAt(0).toUpperCase() + this.state.dropdownLabel.slice(1)}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() =>this.dropdownHandler('user')}>{i18next.t('mngUser.user')}</DropdownItem>
                  <DropdownItem onClick={() =>this.dropdownHandler('admin')}>{i18next.t('mngUser.admin')}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              </div>
            </div>
            <div className="form-group row">
              <h6 className="col-sm-4 col-form-label"><b>{t('firstName')} *</b></h6>
              <div className="col-sm-8">
                <input type="text" style={fieldErrMsg['firstNameErr'] ? {'border': '1px solid red'} : {}} onBlur={this.validateField} value={this.state.rowData['firstName']} onChange={this.eventHandler.bind(this)} className="form-control" id="firstName" placeholder={t('userForm.inputName')} required/>
                {fieldErrMsg['firstNameErr'] !== '' &&
                  <span id="firstNameErr" style={{'color': 'red'}}>{fieldErrMsg['firstNameErr']}</span>}
              </div>
            </div>
            <div className="form-group row">
              <h6 className="col-sm-4 col-form-label"><b>{t('lastName')} *</b></h6>
              <div className="col-sm-8">
                <input type="text" style={fieldErrMsg['lastNameErr'] ? {'border': '1px solid red'} : {}} onBlur={this.validateField} value={this.state.rowData['lastName']} onChange={this.eventHandler.bind(this)} className="form-control" id="lastName" placeholder={t('userForm.inputLastName')} required/>
                {fieldErrMsg['lastNameErr'] !== '' &&
                  <span id="lastNameErr" style={{'color': 'red'}}>{fieldErrMsg['lastNameErr']}</span>}
              </div>
            </div>
            <div className="form-group row">
              <h6 className="col-sm-4 col-form-label"><b>{t('email')} *</b></h6>
              <div className="col-sm-8">
                <input type="email" style={fieldErrMsg['emailErr'] ? {'border': '1px solid red'} : {}} onBlur={this.validateField} value={this.state.rowData['email']} onChange={this.eventHandler.bind(this)} className="form-control" id="email" placeholder={t('userForm.inputEmail')}  required/>
                {fieldErrMsg['emailErr'] !== '' &&
                  <span id="emailErr" style={{'color': 'red'}}>{fieldErrMsg['emailErr']}</span>}
              </div>
            </div>

            {this.props.formtype !== 'edit' && <div className="form-group row">
              <h6 className="col-sm-4 col-form-label"><b>{t('password')} *</b></h6>
              <div className="col-sm-8">
                <input type="password" style={fieldErrMsg['passwordErr'] ? {'border': '1px solid red'} : {}} onBlur={this.validateField} value={this.state.rowData['password']} onChange={this.eventHandler.bind(this)} className="form-control" id="password" placeholder={t('userForm.inputPassword')} autoComplete="new-password" required/>
                {fieldErrMsg['passwordErr'] !== '' &&
                  <span id="passwordErr" style={{'color': 'red'}}>{fieldErrMsg['passwordErr']}</span>}
              </div>
            </div>}
            <hr />
            <div className="form-group row">
              <div className="col-sm-12" style={{'textAlignLast':'end'}}>
              <Button color="link" onClick={this.props.closeModal}>{t('cancel')}</Button>
              <Button color="primary" type="submit">{t('save')}</Button>
              </div>
            </div>
        </form>
          </Fragment>
      )}</Translation>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({

});

const mapDispatchToProps = dispatch => ({
  editUserList: (user) => Actions.admin.editUserList(dispatch, user),
  createUser: (user) => Actions.admin.createUser(dispatch, user)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
