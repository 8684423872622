import React, { Fragment, useState } from 'react';
import * as ReactDOM from "react-dom";
import { Translation  } from 'react-i18next';
import CloseIcon from 'react-ionicons/lib/MdClose';

export const DocumentInput = (props) => {
  return (
    <Translation>{t => (<div id="continentMap" style={{width: '100%', cursor:'pointer'}}>
       <div className="buttonIn">
           <input type="text" style={{'marginBottom': '5px'}} className="form-control input-field" defaultValue= {props.value} onChange={props.change}/>
          <CloseIcon className="closeButton" onClick = {props.click}/>
        </div>
      </div>)}</Translation>
  );
}
