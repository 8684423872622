import React, { Fragment, useState } from 'react';

import * as ReactDOM from "react-dom";
import { Translate } from './../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import {VectorMap} from 'react-jvectormap';
import Actions from './../../internal/modules/Actions';
import './map.css';
import $  from 'jquery';
import { AppState } from './../../internal/modules/Reducers';
import { withRouter } from 'react-router-dom';
import {Button} from 'reactstrap';
import ModalComp from './../../Layout/Modal/index';
import world from './countries.json';
import { connect } from 'react-redux';
import {VectorComponent} from './VectorMap';
import RegulationCount from './../../model/RegulationCount';
import RegulationList from './../../model/RegulationList';
import UserTableComp from './../Table/UserTable';
import {toast} from 'react-toastify';
import TranslationService from '../../utils/TranslationService';
import {FileExportPdf, FileExportExcel} from './../Table/FileExport';
import {PDF, EXCEL} from './../../utils/Constants';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';
import MarketProfileUser from './UserMarketProfile';
import EditModalComp from '../Table/EditModal';

var qs = require('qs');
const t = TranslationService.t;

interface Props extends Translate{
  getRegulationDetails: (any) => {},
  RegulationCountList?: any,
  RegulationDataList ?: any,
  MarketList?: any,
  activeKey?: any,
  regulationFetched: boolean,
  logout: () => {},
  loading: boolean,
  filterSaved: boolean,
  getMarketProfile: (any) => {};
  getMarketList: () => {};
  history ?: any;
  location ?: any;
  searchFilter ?: any;
  isLoading:() => {};
  finishLoading:() => {}
}

interface State{
  selectedContinent: string,
  showMap: boolean,
  modal: boolean,
  regulationCount: RegulationCount[],
  regulationList: {},
  loading: boolean,
  errorMessage: string,
  activeKey?: any,
  mapFlag:boolean,
  selectedMarket: string,
  errorMessageFilter: string,
  modelFlag : boolean,
  showModal : string,
  latitude?: any,
  resultType?: any
}
class MapComponent extends React.Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        selectedContinent: '',
        showMap:true,
        modal:false,
        regulationCount: props.RegulationCountList,
        regulationList: {},
        loading: false,
        errorMessage: null,
        activeKey: null,
        mapFlag: false,
        selectedMarket:null,
        errorMessageFilter: null,
        modelFlag : false,
        showModal : 'show',
        latitude : [],
        resultType : ''
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      return {
        loading: nextProps.loading,
        success: nextProps.success,
        errorMessage: nextProps.errorMessage,
        errorMessageFilter: nextProps.errorMessageFilter,
      };
    }
   componentDidMount(){
      this.props.getMarketList()
   }
    componentDidUpdate(prevProps, prevState){
      //Fetch regulations on language change
      if (this.props.t !== prevProps.t) {
        if (!this.state.showMap && this.props.activeKey == 1) {
          let language= localStorage.getItem('i18nextLng')
          const queryParam = qs.parse(this.state.regulationList);
          const newQueryParam = {...queryParam,ln: language}
          this.props.getRegulationDetails(newQueryParam);
        }
      }
      if (this.props.regulationFetched && prevProps.regulationFetched !== this.props.regulationFetched) {
        this.setState({showMap: false});
      }
      if (prevProps.activeKey !== this.props.activeKey) {
          this.setState({showMap: true});
      }
      if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
        const t = TranslationService.t;
        toast['error'](t(this.state.errorMessage), { position: 'top-right' });
      }
      if (this.props.MarketList && prevProps.MarketList !== this.props.MarketList) {
        this.setMarker()
      }
    }

    //close continent select modal.
    closeModal = () =>{
      this.setState({
          modal: false, modelFlag: false
      });
    }

    //remove tooltip on click of continent.
    removeTooltip = () =>{
      Array.from(document.getElementsByClassName("jvectormap-tip") as HTMLCollectionOf<HTMLElement>).forEach((el, idx, arr) => {
          el.style.display = "none";
      });
    }
    // Setting markers if market profile added
    setMarker = () =>{
      const arr = this.state.latitude.slice()
      this.props.MarketList && this.props.MarketList.map(market =>{
        if(market.isMarketProfile === true){
          if(market.latitude !== null && market.longitude !== null){
            const obj = {'latLng' : [market.latitude, market.longitude], 'name': market.value === 'ECE' || market.value === 'EU' ? `${t(`map.markerTooltip`) + ' ' + t('map.EU')}` : `${t(`map.markerTooltip`) + ' ' + t(`map.countries.`+market.value)}` , 'market': market.label, 'id':market.id , 'type' :   market.value === 'ECE' || market.value === 'EU'  ? 'EU' : '' }
            arr.push(obj)
          }
        }
        this.setState({latitude : arr})
      })
    }

    //call continent select modal on click of show result button and display the regulation details.
    showResult = (code) =>{
       this.setState({
          modal: false ,mapFlag:true
      });
      let {regulationList} = this.state;
      let marketCode = [0];
      this.props.MarketList && [...this.props.MarketList].map(item =>{
        if (item.label === code) {
          marketCode = [item.id];
          this.setState({selectedMarket: item.label});
        }
        if(code === "UNECE+EU27"){
          marketCode = [1,2]
        }
      });
      regulationList['markets'] = marketCode;
      let language= localStorage.getItem('i18nextLng')
      const queryParam = qs.parse(regulationList);
      const newQueryParam = {...queryParam,ln: language}
      this.props.getRegulationDetails(newQueryParam);
    }

    //call on country click and display regulation details of that country.
    handleCountryClick = (event, code) =>{
     let {regulationList} = this.state;
      let marketCode = 0;
      this.props.MarketList && [...this.props.MarketList].map(item =>{
        if (item.value === code) {
          marketCode = item.id;
          this.setState({selectedMarket: item.label});
        }
      });
      regulationList['markets'] = [marketCode];
      this.setState({mapFlag:true});
      let language= localStorage.getItem('i18nextLng')
      const queryParam = qs.parse(regulationList);
      const newQueryParam = {...queryParam,ln: language}
      this.props.getRegulationDetails(newQueryParam);
    }

    //call on continent click and open continent select modal if continent name is EU.
    handleContinentClick = (event, code) =>{
      this.setState({selectedContinent: code});
      if (code === 'EU') {
        this.setState({modal: true});
      }
    }

    //call on PDF and EXCEL image click and download files.
    handleFileExport = (filetype) =>{
      if(this.props.RegulationDataList && this.props.RegulationDataList.length !== 0){
        let {regulationList} = this.state;
        var xhr = new XMLHttpRequest();
        let params = '?ln='+localStorage.getItem('i18nextLng')+'&timeZone='+Intl.DateTimeFormat().resolvedOptions().timeZone+'&'+
        qs.stringify(regulationList, {arrayFormat: 'repeat'});

        let filename = i18next.t('regulationFilename')+'_'+this.state.selectedMarket.toUpperCase();
        filename += '_'+moment(new Date()).format('MMM DD YYYY h-mm a');
        if (filetype === 'PDF') {
          let apiUrl = process.env.REACT_APP_BASE_URL+'/'+PDF+params;
          FileExportPdf(apiUrl, filename, this.props.logout,this.props.isLoading, this.props.finishLoading);
        }
        else{
          let apiUrl = process.env.REACT_APP_BASE_URL+'/'+EXCEL+params;
          FileExportExcel(apiUrl, filename, this.props.logout,this.props.isLoading,this.props.finishLoading);
        }
      }
      else{
        toast(t('advancedFilters.infoToastMsg'), { position: 'top-right', type: 'info'});
      }
    }

    //call onclick of back to map or back button and reset the map page.
    resetMap = () =>{
      this.setState({selectedContinent: '',
      showMap:true,
      modal:false});
    }
    //call on marker click
    markerClick = (e, index) =>{
      if(this.state.latitude[index].type === 'EU'){
        this.setState({selectedContinent: 'EU',modal: true , resultType: 'markerResult'});
      }
      else{
        this.props.getMarketProfile(this.state.latitude[index].id)
        this.setState({modelFlag : true, resultType: ''})
      }
    }
    //call on click of euroupe marker
    markerClickEU = (code) =>{
     this.setState({modal : false})
     let flag ;
     this.props.MarketList && this.props.MarketList.map(market =>{
      if(market.label === code && market.isMarketProfile === true){
       flag = market.isMarketProfile
       this.props.getMarketProfile(market.id)
       this.setState({modelFlag : true, resultType: ''})
      }
     })
     if(!flag){
        toast(i18next.t('map.infoToast'), { position: 'top-right', type: 'info'});
     }
    
    }
    render() {
      // localStorage.setItem('tab','1')
      let formTemplate, modalTitle = null;
      const spinner4 = <LoaderAnim active type="ball-beat"/>;
      if(this.state.showModal === 'show' ) {
       formTemplate = <MarketProfileUser {...this.props}  closeModal={this.closeModal}/>
        modalTitle = 'Market profile';
      }
      this.removeTooltip();
        return (
          <Translation>{t => (<Fragment>
        { this.state.modelFlag &&
                   <EditModalComp  closeModal={this.closeModal} modalID={'MarketProfile'} modalBody={formTemplate} modalTitle={modalTitle} />
                 }
        {this.state.selectedContinent === 'EU' &&
          <ModalComp
            closeModal={this.closeModal}
            handleClick={this.state.resultType !== 'markerResult' ? this.showResult : this.markerClickEU}
            modalFlag={this.state.modal}
            modalType={this.state.resultType}
            className="mapModalCss"
            modalHeader={t('map.continentSelected')+t(`map.${this.state.selectedContinent}`)}
            cancelBtnText={t('cancel')}
            successBtnText={t('map.showResult')}/>}

        {this.state.showMap && this.state.selectedContinent !== '' && this.state.selectedContinent !== 'EU' &&
          <Loader message={spinner4} show={this.props.loading}>
            <VectorComponent
            mapLabel={t(`map.${this.state.selectedContinent}`)}
            mapProp={world[this.state.selectedContinent]}
            countProp={this.props.RegulationCountList}
            handleClick={this.handleCountryClick}
            markerClick={this.markerClick}
            markers={this.state.latitude}/>

            <Button color="primary" className="mapBtn mb-2 mr-2" onClick={this.resetMap}>{t('back')}</Button>
          </Loader>}

        {this.state.showMap && (this.state.selectedContinent === '' || this.state.selectedContinent === 'EU') &&
          <Loader message={spinner4} show={this.props.loading}>
            <VectorComponent
            mapLabel={t(`map.world`)}
            mapProp={'continents_mill'}
            countProp={this.props.RegulationCountList}
            handleClick={this.handleContinentClick}
            markerClick={this.markerClick}
            markers={this.state.latitude}/>
          </Loader>
        }

      {!this.state.showMap  &&
        <Fragment>
        <div id="mapTable">
          <UserTableComp {...this.props} marketSelected={this.state.regulationList} handleFileExport={this.handleFileExport.bind(this)} resetMap={this.resetMap}/>
       </div>
       </Fragment>
        }
         </Fragment>)}</Translation>
        )
    }
  }

  const mapStateToProps = (appState: AppState) => ({
    RegulationCountList: appState.user.RegulationCountList,
    RegulationDataList: appState.user.RegulationDataList,
    MarketList: appState.user.MarketList,
    loading: appState.loading.loading,
    success: appState.user.RegulationDataListRequest.$success,
    errorMessage: appState.user.RegulationDataListRequest.$errorMessage,
    activeKey : appState.user.activeKey,
    regulationFetched: appState.user.regulationFetched,
    errorMessageFilter: appState.user.saveFilterRequest.$errorMessage,
    filterSaved: appState.user.filterSaved
  });

  const mapDispatchToProps = dispatch => ({
      getRegulationDetails: (filter) => Actions.user.getRegulationDetails(filter, dispatch),
      getMarketProfile: (marketID) => Actions.user.getMarketProfile(marketID, dispatch),
      getMarketList: () => Actions.user.getMarketList(dispatch),
      logout: () => Actions.user.logout(dispatch),
      isLoading:() => Actions.user.isLoading(dispatch),
      finishLoading:() => Actions.user.finishLoading(dispatch),
  });

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MapComponent));
