import React, { Component, Fragment } from "react";
import "./chat.css";
import ChatBot from "../../assets/utils/images/avatars/chatbotAvatar.png";
import UserImg from "../../assets/utils/images/avatars/dummyUser.png";
import { AppState } from "./../../internal/modules/Reducers";
import { connect } from "react-redux";
import { Translation, withTranslation } from "react-i18next";
import Actions from '../../internal/modules/Actions';
import axios from "axios";
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';
import {Row, CustomInput, Button} from 'reactstrap';
import i18next from "i18next";
import { Translate } from "../../model/interfaces/translate.interface";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DeleteIcon from 'react-ionicons/lib/MdClose';
import ChatBotTableComp from './ChatBotTable';


interface Props extends Translate{
  closeModal ?: any;
  getRecentGPTQuestions : () => {};
  RecentGPTQuestionsList ?: any;
  removeRecentGPTQ : (any) => {};
  classes ?: any;
}

interface State {
  messages: any;
  answer ?: any;
  loading : boolean;
  radioButtonFlag ?: any;
  selectedQuestion ?: any;
  responseTime : number;
  errorMsgFlag : boolean;
  questionList ?: any;
}

class Chatbot extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      answer : [],
      loading : false,
      radioButtonFlag : 1,
      selectedQuestion : '',
      responseTime : 0,
      errorMsgFlag : false,
      questionList : []
    };
  }

  componentDidMount() {
    if(!this.props.RecentGPTQuestionsList){
      this.props.getRecentGPTQuestions();
      this.setState({questionList: this.props.RecentGPTQuestionsList})
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.RecentGPTQuestionsList && prevProps.RecentGPTQuestionsList !== this.props.RecentGPTQuestionsList) {
      this.setState({questionList: this.props.RecentGPTQuestionsList})
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {radioButtonFlag, selectedQuestion} = this.state;
    this.setState({loading: true})
    if(selectedQuestion !== undefined){
      const obj = {
        "question": selectedQuestion,
        "language_code" : localStorage.getItem('i18nextLng'),
        "user_id": JSON.parse(localStorage.getItem('user'))?.id
      }
      if(radioButtonFlag === 1){
        var start = Date.now();
        axios.post('https://gpt.meconit.in/api/v1/grm/queryOpenAI', obj)
        .then(response => {
          var millis = Date.now() - start;
          let time = millis / 1000.0;
          var formatNum = Math.floor(time*100)/100;
          this.setState({answer: response.data, loading: false , responseTime : formatNum, errorMsgFlag: false})
          if(selectedQuestion !== ''){
            this.props.getRecentGPTQuestions();
          }
        })
        .catch(error => {
            this.setState({loading: false, answer : i18next.t('chatBot.errorMsg'), errorMsgFlag: true})
        });
      }
      else if(radioButtonFlag === 2){
        var start = Date.now();
        axios.post('https://gpt.meconit.in/api/v1/grm/queryZephyr', obj)
        .then(response => {
          var millis = Date.now() - start;
          let time = millis / 1000.0;
          var formatNum = Math.floor(time*100)/100;
          this.setState({answer: response.data, loading: false, responseTime: formatNum,errorMsgFlag: false})
          if(selectedQuestion !== ''){
            this.props.getRecentGPTQuestions();
          }
        })
        .catch(error => {
          this.setState({loading: false, answer :  i18next.t('chatBot.errorMsg'),errorMsgFlag : true})
        });
      }
      else{
        var start = Date.now();
        axios.post('https://gpt.meconit.in/api/v1/grm/queryGemini', obj)
        .then(response => {
          var millis = Date.now() - start;
          let time = millis / 1000.0;
          var formatNum = Math.floor(time*100)/100;
          this.setState({answer: response.data, loading: false, responseTime: formatNum,errorMsgFlag: false})
          if(selectedQuestion !== ''){
            this.props.getRecentGPTQuestions();
          }
        })
        .catch(error => {
          this.setState({loading: false, answer :  i18next.t('chatBot.errorMsg'),errorMsgFlag : true})
        });
      }
      // else{
      //   // var start = Date.now();
      //   // axios.post('https://gpt.meconit.in/api/v1/grm/queryOpenAIKB', obj)
      //   // .then(response => {
      //   //   var millis = Date.now() - start;
      //   //   let time = millis / 1000.0;
      //   //   var formatNum = Math.floor(time*100)/100;
      //   //   this.setState({answer: response.data, loading: false, responseTime: formatNum,errorMsgFlag: false})
      //   //   if(selectedQuestion !== ''){
      //   //     this.props.getRecentGPTQuestions();
      //   //   }
      //   // })
      //   // .catch(error => {
      //   //   this.setState({loading: false, answer :  i18next.t('chatBot.errorMsg'),errorMsgFlag : true})
      //   // });
      // }
      }
  };

  removeQue = (id,props) =>{
   let arr = [];
    this.state.questionList.filter((element) => {
      if(element.question === id ) {
      
       arr.push(element.id)
      }
    })
    this.props.removeRecentGPTQ(arr)
    const removeList = this.state.questionList.filter((item) => item.question !== id);
    this.setState({questionList: removeList})
  }
  render() {
    const { answer,selectedQuestion} = this.state;
    const spinner4 = <LoaderAnim active type="ball-beat"/>;
    return (
      <div className="chatbot">
          <Translation>{t => (
            <Fragment>
              
        {/* <div className="chat-log">
          {messages.length !== 0 ? (
            <>
             {
              messages.map((message, index) => (
                <div key={index} className={`chatBotmessage ${message.user ? 'user' : 'bot'}`}>
                  {message.text}
                </div>
              ))
            }
            </>
          ) : (
            <p style={{textAlign:'center', justifySelf:'center',alignItems:'center'}}>Ask me anything related, I'm here to help</p>
          ) }
        </div>
        <form onSubmit={this.handleSubmit} className='chatBotForm'>
          <input
          className='chatBotInput'
            type="text"
            value={input}
            onChange={this.handleChange}
            placeholder="Type your message..."
          />
          <button className='chatbotSend' type="submit">Send</button>
        </form> */}
        <form onSubmit={this.handleSubmit} className="chatBotForm mb-3">
              <Row className="col-12" style={{paddingBottom : '15px', paddingLeft : '22px'}}>
                    <CustomInput
                      checked={this.state.radioButtonFlag === 1}
                      onChange={e => {this.setState({radioButtonFlag : 1 , answer: []}) 
                      this.props.getRecentGPTQuestions();}}
                      className="reportRadioBtn1"
                      type="radio" id="exampleCustomRadio" name="customRadio"
                      label={t('chatBot.chatGPTLabel')}/>
                    <CustomInput
                      checked={this.state.radioButtonFlag === 2}
                      onChange={e => {this.setState({ radioButtonFlag : 2, answer : []})
                      this.props.getRecentGPTQuestions();}}
                      className="reportRadioBtn2"
                      type="radio" id="exampleCustomRadio2" name="customRadio"
                      label={t('chatBot.openLLMLabel')}/>
                    <CustomInput
                      checked={this.state.radioButtonFlag === 3}
                      onChange={e => {this.setState({ radioButtonFlag : 3, answer : []})
                      this.props.getRecentGPTQuestions();}}
                      className="reportRadioBtn2"
                      type="radio" id="exampleCustomRadio3" name="customRadio"
                      label={t('chatBot.openLLMLabel1')}/>
                    {/* <CustomInput
                      checked={this.state.radioButtonFlag === 4}
                      onChange={e => this.setState({ radioButtonFlag : 4, answer : []})}
                      className="reportRadioBtn2"
                      type="radio" id="exampleCustomRadio4" name="customRadio"
                      label={t('chatBot.openLLMLabel1')}/> */}
              </Row>

             <div className="col-sm-12 mb-2" style={{flex: 1, display: 'flex'}}>
              <Autocomplete
                freeSolo
                id="free-solo-demo"
                options={this.state.questionList?.map((option) => option.question)}
                onInputChange={(event, newInputValue) => {
                  this.setState({selectedQuestion:newInputValue, answer: []})
                }}
                style={{width: '94%'}}
                renderOption={(props: any, option: any) => (
                  <div className="col-sm-12 form-inline listRow" style={{padding: 0}} key={props.id}>
                    <div className="col-sm-11" style={{padding: 0}}>
                  <li {...props} key={props.tabIndex} >{`${option}`}</li></div>
                  <div className="col-sm-1" style={{padding: 0}}>
                  <a {...props} key={props.key}  id="deleteIcon" onClick={() => this.removeQue(option,props)}><DeleteIcon style={{ fill: '#666666'}} /></a>
                  </div>
                  </div>
                )}
               
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('chatBot.inputPlaceholder')}
                    InputProps={{
                      ...params.InputProps,
                      style: { height: 45, fontSize: '14px'}
                    }} 
                    
                  />
                )}
              />
              <Button color="primary" disabled={!this.state.selectedQuestion} className="chatbotSend" type="submit">{t('sendBtn')}</Button>
            </div>
        </form>
        <Loader message={spinner4} show={this.state.loading}>
        <div className="chat-log">
          {answer.length !== 0 ? (
            <>
                <>
                  <div className="chatBotmessage ">
                    <img src={UserImg} className="avatarImg"/>
                    <div className={"user"}>
                     {selectedQuestion}
                    </div>
                  </div>
                </>
                     <>
                       <div className={'chatBotmessage botMessage'}>
                         <img src={ChatBot} className="avatarImg"/>
                          <div className={"bot"}>
                           { !this.state.errorMsgFlag && <p>{t('chatBot.responseTimeText')} {this.state.responseTime} {t('chatBot.secondsText')}</p>}
                          { !this.state.errorMsgFlag ? <ChatBotTableComp data={answer}/> : <p>{answer}</p>}
                           {/* <div dangerouslySetInnerHTML={{ __html: this.state.answer}}></div> */}
                         
                         </div>
                       </div>
                 </>
                 </>
          ) : (
            // {/* </div> */}
            <p
              style={{
                textAlign: "center",
                justifySelf: "center",
                alignItems: "center",
              }}
            >
             {t('chatBot.initialText')}
            </p>
          )}
        </div>
        </Loader>
        </Fragment>
        )}</Translation>
      </div>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({
  RecentGPTQuestionsList: appState.user.RecentGPTQuestionsList,
  });

const mapDispatchToProps = dispatch => ({
  getRecentGPTQuestions : () => Actions.user.getRecentGPTQuestions(dispatch),
  removeRecentGPTQ : (id) => Actions.user.removeRecentGPTQ(id,dispatch)
  });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Chatbot));

