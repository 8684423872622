import React from "react";
import Actions from "./internal/modules/Actions";
import logo from "./logo.svg";
import "./App.css";
import { withTranslation } from "react-i18next";
import { Translate } from "./model/interfaces/translate.interface";
import MainApp from "./pages/Main";
import NavigationService from "./utils/NavigationService";
import TranslationService from "./utils/TranslationService";
import { connect } from "react-redux";
// import firebase from './Firebase';

interface Props extends Translate {
  history?: any;
  logout: () => {};
  getUserById: (id: number) => {};
  editUserList: (any) => {};
}

interface State {
  value: string;
  fcmToken?: any;
}

export class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: "en",
      fcmToken: "",
    };
  }

  componentDidMount() {
    // const messaging = firebase.messaging();
    // Notification.requestPermission()
    // .then(()=>{
    //   return messaging.getToken();
    // })
    // .then(token=>{
    //   this.setState({fcmToken: token})
    //   console.log("fcmToken", token)
    // })
    // .catch((err) => {
    //   console.log('error',err);
    // })
    // NavigationService.setTopLevelNavigator(this.props.history)

    window.addEventListener("unload", this.handleWindowClose);
    TranslationService.setTranslation(this.props.t);
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ""
    let selectedRole = sessionStorage.getItem("selectedRole");
    if (
      selectedRole !== "admin" &&
      selectedRole !== "user" &&
      user &&
      user.id
    ) {
      this.props.getUserById(user.id);
    }
    //TODO: call languageHandle to change language at runtime
    // this.onLanguageHandle('de')
  }
  handleWindowClose = () => {
    const dateUTC = new Date().toISOString();
    let obj = {
      "recentTab": localStorage.getItem('tab'),
      "recentSearch": localStorage.getItem('recentSearch'),
      "lastLogin": dateUTC
    }
    this.props.editUserList(obj)
    localStorage.setItem("iThomos", "");
    localStorage.setItem('lastLogin', dateUTC);         
  };
  onLanguageHandle = (newLang) => {
    // let newLang = event.target.value;
    this.setState({ value: newLang });
    this.props.i18n.changeLanguage(newLang);
  };

  render() {
    const { t } = this.props;
    return <MainApp fcmToken={this.state.fcmToken} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => Actions.user.logout(dispatch),
  getUserById: (userId) => Actions.user.getUserById(userId, dispatch),
  editUserList: (user) => Actions.user.editUserList(dispatch, user)
});

export default connect(null, mapDispatchToProps)(withTranslation()(App));
