import React, {Fragment, Component} from 'react'
import FileSaver from 'file-saver';
import i18next from 'i18next';
import {toast} from 'react-toastify';

//handle PDF file download.
export const FileExportPdf = (apiUrl, filename, logout,isLoading,finishLoading) => {
  isLoading();
  // ModuleActions.isLoading(dispatch)
  var qs = require('qs');
  var xhr = new XMLHttpRequest();
  xhr.open('GET', apiUrl, true);
  xhr.setRequestHeader('Cache-Control','no-cache');
  xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  xhr.setRequestHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('token'))}`);
  xhr.responseType = 'blob';
  xhr.onload = async function (dispatch) {
   if(xhr.status === 200){
     FileSaver.saveAs(xhr.response, filename+'.pdf');
     finishLoading();
     toast(i18next.t('advancedFilters.pdfSuccess'), { position: 'top-right', type: 'success'});
    }
   else if(xhr.status === 500){
     finishLoading();
     toast(i18next.t('advancedFilters.pdfError'), { position: 'top-right', type: 'error'});
    }
   else if (xhr.status === 401) {
    finishLoading();
      logout();
    }
   }.bind(this);
   xhr.send();
}

//handle Excel file download.
export const FileExportExcel = (apiUrl, filename, logout,isLoading,finishLoading ) => {
  isLoading();
  var qs = require('qs');
  var xhr = new XMLHttpRequest();
  xhr.open('GET', apiUrl, true);
  xhr.setRequestHeader('Cache-Control','no-cache');
  xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  xhr.setRequestHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('token'))}`);
  xhr.responseType = 'blob';
  xhr.onload = async function (dispatch) {
   if(xhr.status === 200){
     await  FileSaver.saveAs(xhr.response, filename+'.xlsx');
    finishLoading();
     toast(i18next.t('advancedFilters.excelSuccess'), { position: 'top-right', type: 'success'});
   }
   else if(xhr.status === 500){
    toast(i18next.t('advancedFilters.excelError'), { position: 'top-right', type: 'error'});
    finishLoading();
   }
   else if (xhr.status === 401){
    finishLoading();
    logout();
   }
  }.bind(this);
  xhr.send();
}
