import {ModuleEvents} from './Actions';
import Actions from '../../internal/modules/Actions';
import NavigationService from '../../utils/NavigationService';
import { format } from 'date-fns';
import moment from 'moment';

//Resetting states after api success and failure
export default {

    [ModuleEvents.LOGIN]: ({ dispatch, payload, appState }) => {
        //localStorage token updated after login
        localStorage.setItem('token', JSON.stringify(appState.user.user.token));
        localStorage.setItem('user', JSON.stringify(appState.user.user));
        localStorage.setItem('tab', (appState.user.user.recentTab));
        localStorage.setItem('recentSearch', (appState.user.user.recentSearch));
        const dateUTC = appState.user.user.lastLogin;
        localStorage.setItem('lastLogin', dateUTC);
        //Redirecting to favorites route if the address bar contains string else show dashboard after successful login
        if (window.location.href.includes('/app/favorites')) {
          window.location.reload();
        }
        else{
          NavigationService.replace('/app/home', {});
        }
    },

    [ModuleEvents.SAVE_FILTER]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_SAVE_FILTER_STATUS })
    },

    [ModuleEvents.ERROR_SAVE_FILTER]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_SAVE_FILTER_STATUS });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },

    [ModuleEvents.REGULATION_LIST]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_REGULATION_LIST_STATUS })
    },

    [ModuleEvents.ERROR_REGULATION_LIST]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_REGULATION_LIST_STATUS });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.FAVORITES_LIST]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_FAVORITES_LIST })
    },
    [ModuleEvents.ERROR_FAVORITES_LIST]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_FAVORITES_LIST });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.UPDATE_FAVORITES]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_UPDATE_FAVORITES_LIST })
    },
    [ModuleEvents.ERROR_UPDATE_FAVORITES]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_UPDATE_FAVORITES_LIST });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.DELETE_FAVORITES]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_FAVORITES_LIST })
    },
    [ModuleEvents.ALL_LANG_DATA]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_ALL_LANG_DATA })
    },
    [ModuleEvents.ERROR_ALL_LANG_DATA]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_ALL_LANG_DATA })
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.SHARE_FILTER]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_SHARE_FILTER })
    },
    [ModuleEvents.ERROR_SHARE_FILTER]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_SHARE_FILTER })
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.GET_TIMELINE_DATA]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_GET_TIMELINE_DATA })
    },
    [ModuleEvents.ERROR_GET_TIMELINE_DATA]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_GET_TIMELINE_DATA });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.RESET_PASSWORD]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_RESET_PASSWORD })
    },
    [ModuleEvents.ERROR_RESET_PASSWORD]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_RESET_PASSWORD });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.FORGOT_PASSWORD]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_FORGOT_PASSWORD })
    },
    [ModuleEvents.ERROR_FORGOT_PASSWORD]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_FORGOT_PASSWORD });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.GET_USER_BY_ID]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_GET_USER_BY_ID });
    },
    [ModuleEvents.ERROR_GET_USER_BY_ID]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_GET_USER_BY_ID });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.GET_REG_DETAIL_BY_ID]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_GET_REG_DETAIL_BY_ID });
    },
    [ModuleEvents.ERROR_GET_REG_DETAIL_BY_ID]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_GET_REG_DETAIL_BY_ID });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.SCOPE_LIST]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_SCOPE_LIST_STATUS });
    },
    [ModuleEvents.ERROR_SCOPE_LIST]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_SCOPE_LIST_STATUS });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.TIME_MATRIX_LIST]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_TIME_MATRIX_LIST_STATUS })
    },
    [ModuleEvents.ERROR_TIME_MATRIX_LIST]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_TIME_MATRIX_LIST_STATUS });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.GET_REF_REG_DETAIL_LIST]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_GET_REF_REG_DETAIL_LIST });
    },
    [ModuleEvents.ERROR_GET_REF_REG_DETAIL_LIST]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_GET_REF_REG_DETAIL_LIST });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.GET_REF_REG_DETAIL]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_GET_REF_REG_DETAIL });
    },
    [ModuleEvents.ERROR_GET_REF_REG_DETAIL]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_GET_REF_REG_DETAIL });
      dispatch({ type: ModuleEvents.VERIFY_SESSION });
    },
    [ModuleEvents.GET_MARKET_PROFILE]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_GET_MARKET_PROFILE })
    },
    [ModuleEvents.ADD_SUBSCRIPTION]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_ADD_SUBSCRIPTION })
    },
}
