import React, {Fragment, Component} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Typeahead} from 'react-bootstrap-typeahead';
import i18n from '../../i18n/I18n';
import i18next from 'i18next';
import {toast} from 'react-toastify';
import './style.css';
import {
    Row, Col,
    Card, CardBody,
    CardTitle,
    Button
} from 'reactstrap';

const t = TranslationService.t;

interface Props extends Translate{
    closeModal?: any,
    getUserList: () => {},
    shareFilter: (any) =>{},
    UserList ?: any,
    URL ?: any,
    favoritesShare : boolean,
    FilterName?: any,
    loading: boolean;
  }

interface State{
    userSelected?: any,
    ErrMessage : string
  }

class ShareForm extends Component<Props, State> {

    constructor(props){
      super(props)
      this.state={
        userSelected : [],
        ErrMessage : ''
      }
    }

    componentDidMount(){
      this.props.getUserList();
    }

    componentDidUpdate(prevProps, prevState){
      if (this.props.favoritesShare && prevProps.favoritesShare !== this.props.favoritesShare) {
          this.props.closeModal();
      }
    }

    //select user from dropdown and set value.
    userHandler = (data) =>{
      this.setState({userSelected: data ,ErrMessage: '' })
    }

   //call on click of share button and share filter with selected user.
    handleShare = () =>{
      let errFlag = false;
      let {userSelected} = this.state;
      let senderId = JSON.parse(localStorage.getItem('user')).id;
      const arr = []
      userSelected.map((item)=>{
        arr.push(item.id)
      })
      let url = this.props.URL
      let filterName = this.props.FilterName
      if(userSelected.length === 0){
       errFlag = true;
       this.setState({ErrMessage: 'favorites.selectUser'})
      }
      if (!errFlag){
        let filter ={url,receiverIds : arr, senderId, filterName: filterName}
        this.props.shareFilter(filter)
      }

    }

    render() {
        return (
            <Translation>{t => (<Fragment>
                       <form>
                        <Col style={{'height':'70%'}}>
                          <h6><b>{t('advancedFilters.selectUser')} *</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              clearButton
                              emptyLabel={t('noMatchFound')}
                              labelKey={option => `${option.firstName} ${option.lastName}`}
                              multiple
                              selected={this.state.userSelected}
                              onChange={(e) => this.userHandler(e)}
                              options={this.props.UserList ? this.props.UserList : []}
                              placeholder={t('advancedFilters.selectUser')}
                              renderMenuItemChildren={(option) => (
                                <div>
                                  {option.firstName} {option.lastName}
                                  <div style={{'fontSize':'xx-small', 'fontWeight':'bolder'}}>
                                    {i18next.t('emailShare')}: {i18next.t(option.email)}
                                  </div>
                                </div>
                              )}
                          />
                          {this.state.ErrMessage !== '' && <span className="errClass">{i18next.t(this.state.ErrMessage)}</span>}
                          <hr />
                        </Col>
                        <div className="shareBtn">
                            <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
                            <Button color="primary" onClick={this.handleShare}  disabled={this.props.loading}>{this.props.loading ? i18next.t('sharingData') : i18next.t('share')}</Button>
                         </div>
                      </form>
              </Fragment>)}
             </Translation>

        )
    }
}

const mapStateToProps = (appState: AppState) => ({
  UserList: appState.user.UserList,
  loading: appState.loading.loading
 });

const mapDispatchToProps = dispatch => ({
  getUserList: () => Actions.user.getUserList(dispatch),
  shareFilter: (filter) => Actions.user.shareFilter(dispatch,filter)
 });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShareForm));
