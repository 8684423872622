import React, {Fragment, Component} from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import {toast} from 'react-toastify';
import { AvForm,  AvGroup, AvInput} from 'availity-reactstrap-validation';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import TranslationService from '../../utils/TranslationService';
import UserTableComp from './../Table/UserTable';
import Favorites from '../../model/Favorites';
import FavoritesListComp from '../FavoritesComp/favoritesList';
import {Button,Row,Col} from 'reactstrap';
import './favoritesData.css';
import { serialize } from 'v8';
import { withTranslation } from 'react-i18next';
import {FileExportPdf, FileExportExcel} from './../Table/FileExport';
import {PDF, EXCEL} from './../../utils/Constants';
import mockData from './../../utils/StaticData.json';
import i18next from 'i18next';
import ToggleFilter from '../AdvancedFilterComp/ToggleFilter';
import moment from 'moment';

var qs = require('qs');
const t = TranslationService.t;

interface Props extends Translate{
getRegulationDetails : (any) => {};
history ?: any;
location ?: any;
favorites ?: any;
item ?: any;
RegulationDataList?: any;
getTopicList: () => {};
getMarketList: () => {};
getVehicleList: () => {};
getFavoritesList: (any) => {};
MarketList?: any;
TopicList?: any;
VehicleList?: any;
GroupList?: any,
ScopeList?: any,
VehicleFuelTypeList?: any,
activeKey: string;
SearchTagList?: any;
id: number;
favoritesShare: boolean;
logout: () => {};
closeModal: () => any;
filterUpdated: boolean;
reloadTemplate: (any) => {};
DriveTypeList?: any;
filterSaved?: boolean;
isLoading:() => {};
finishLoading:() => {}
}

interface State{
  favorites: Favorites[],
  filterDataID ?: any,
  tagData ?: any,
  marketData ?: any,
  vehicleData ?: any,
  groupData?: any,
  subGroupData?: any,
  scopeData?: any,
  subScopeData?: any,
  vehicleFuelTypeData?: any,
  topicData?: any,
  topicTypeData?: any,
  statusData?: any,
  enfDateFromArr?: any,
  enfDateToArr?: any,
  expDateFromArr?: any,
  expDateToArr?: any,
  saveFlag: boolean,
  functionType : string,
  ID?: any,
  active: string,
  filterName: string,
  URL ?: any,
  shareFlag?: any,
  imgFlag : boolean,
  shareBtnFlag: boolean,
  errorMessageUpdate: string,
  filterChanged: boolean,
  regulationFilterList: {},
  driveTypeData?: any,
  errorMessageAdded?: string
}

class FavoritesDataComp extends Component<Props, State> {
  constructor(props){
    super(props)
    this.state={
      favorites : [],
      filterDataID:{},
      marketData: [],
      tagData : [],
      vehicleData: [],
      topicData: [],
      topicTypeData: [],
      subGroupData: [],
      groupData:[],
      subScopeData: [],
      scopeData:[],
      vehicleFuelTypeData:[],
      statusData: [],
      enfDateFromArr: null,
      enfDateToArr: null,
      expDateFromArr: null,
      expDateToArr: null,
      saveFlag: false,
      functionType: 'edit',
      ID: localStorage.getItem('FavoritesID'),
      active: "3",
      filterName: localStorage.getItem('filterName'),
      URL : this.props.location.search.replace("?" , ""),
      shareFlag : null,
      imgFlag: false,
      shareBtnFlag : false,
      errorMessageUpdate: null,
      filterChanged:false,
      regulationFilterList: {},
      driveTypeData: [],
      errorMessageAdded: null
     }
  }

  componentDidMount(){
    this.props.reloadTemplate('user');
    localStorage.setItem('shareURL', 'false');
    if (localStorage.getItem('filterName') !== null && localStorage.getItem('filterName') !== 'null') {
       this.setState({filterName: localStorage.getItem('filterName').replace(/^"(.*)"$/, '$1')});
    }
    const searchParam = this.QueryStringToJSON();
    let language= localStorage.getItem('i18nextLng')
    const queryParam = {...searchParam,ln: language}
    this.props.getRegulationDetails(queryParam);
    this.displayLabelHandler();
  }

  static getDerivedStateFromProps(newState, prevState) {
    return {
       errorMessageUpdate: newState.errorMessageUpdate,
       errorMessageAdded: newState.errorMessageAdded
      };
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.filterUpdated && prevProps.filterUpdated !== this.props.filterUpdated) {
      toast['success'](i18next.t('favorites.update'), { position: 'top-right' });
    }
    if(this.state.errorMessageUpdate && this.state.errorMessageUpdate !== prevState.errorMessageUpdate){
      toast['error'](i18next.t(this.state.errorMessageUpdate), { position: 'top-right'});
    }
    if (this.props.favoritesShare && prevProps.favoritesShare !== this.props.favoritesShare) {
      toast['success'](i18next.t('favorites.shareSuccess'), { position: 'top-right' });
    }
    if (this.props.filterSaved && prevProps.filterSaved !== this.props.filterSaved) {
      toast['success'](i18next.t('favorites.saved'), { position: 'top-right' });
    }
    if(this.state.errorMessageAdded && this.state.errorMessageAdded !== prevState.errorMessageAdded){
      toast['error'](i18next.t(this.state.errorMessageAdded), { position: 'top-right'});
    }
    if (this.props.VehicleList !== prevProps.VehicleList || this.props.MarketList !== prevProps.MarketList ||
        this.props.TopicList !== prevProps.TopicList || this.props.GroupList !== prevProps.GroupList || this.props.ScopeList !== prevProps.ScopeList ||
        this.props.DriveTypeList !== prevProps.DriveTypeList || this.props.VehicleFuelTypeList !== prevProps.VehicleFuelTypeList ) {
          if (this.state.filterChanged === false) {
            this.displayLabelHandler();
          }
    }
    // if (this.props.t !== prevProps.t) {
    //   const searchParam = this.QueryStringToJSON()
    //   let language= localStorage.getItem('i18nextLng')
    //   const newQueryParam = {...searchParam,ln: language}
    //   this.props.getRegulationDetails(newQueryParam);
    //   }
    }

    //display the selected filter value.
    displayLabelHandler = () =>{
      const searchParam = this.QueryStringToJSON();
      searchParam && Object.keys({...searchParam}).map((item, index) =>{
        if (item === 'tags') {
          let tagArr = [];
          [ ...searchParam['tags']].filter(elem =>{
             tagArr.push(elem)
              });
              this.setState({tagData: tagArr})
        }
        if(item === 'filterName'){
          let name ;
          [...searchParam.filterName].filter(elem =>{
            name = elem
          });
          this.setState({filterName: name})
        }
        if(item === 'share'){
        let shareFlag;
         [...searchParam.share].filter(elem =>{
           shareFlag = elem
         });
          this.setState({shareFlag: shareFlag})
        }
        if (item === 'markets') {
          let marketArr = [];
          let arr = [];
          [...searchParam.markets].filter(elem =>{
            this.props.MarketList && this.props.MarketList.map(market =>{
              if (elem == market.id) {
                arr.push(market.label);
                marketArr.push(market)
              }
            })
          });
          this.setState({marketData: marketArr});
        }
        if (item === 'groups') {
          let arr = [];
          let groupArr = [];
          [...searchParam.groups].filter(elem =>{
            this.props.GroupList && this.props.GroupList.map(group =>{
              if (elem == group.id) {
                arr.push(group.name);
                groupArr.push(group)
              }
            })
          });
          this.setState({groupData: groupArr})
        }
        if (item === 'scopes') {
          let arr = [];
          let scopeArr = [];
          [...searchParam.scopes].filter(elem =>{
            this.props.ScopeList && this.props.ScopeList.map(scope =>{
              if (elem == scope.id) {
                arr.push(scope.name);
                scopeArr.push(scope)
              }
            })
          });
          this.setState({scopeData: scopeArr})
        }
        if (item === 'subGroups') {
          let arr = [];
          let subGroupArr = [];
         [...searchParam.subGroups].filter(elem =>{
            this.props.GroupList && this.props.GroupList.map(item =>{
              item.subgroup && item.subgroup.map(subgroup =>{
               if (elem == subgroup.id) {
                arr.push(subgroup.name);
                subGroupArr.push(subgroup)
              }
            })
          })
          });
          this.setState({subGroupData: subGroupArr})
        }
        if (item === 'subScopes') {
          let arr = [];
          let subScopeArr = [];
         [...searchParam.subScopes].filter(elem =>{
            this.props.ScopeList && this.props.ScopeList.map(item =>{
              item.subScope && item.subScope.map(subScope =>{
               if (elem == subScope.id) {
                arr.push(subScope.name);
                subScopeArr.push(subScope)
              }
            })
          })
          });
          this.setState({subScopeData: subScopeArr})
        }
        if (item === 'driveTypes') {
          let arr = [];
          let driveTypeArr = [];
          [...searchParam.driveTypes].filter(elem =>{
            this.props.DriveTypeList && this.props.DriveTypeList.map(driveType =>{
              if (elem == driveType.id) {
                arr.push(driveType.driveType);
                driveTypeArr.push(driveType)
              }
            })
          });
          this.setState({driveTypeData: driveTypeArr})
        }
        if (item === 'fuelTypes') {
          let arr = [];
          let vehicleFuelTypeArr = [];
          [...searchParam.fuelTypes].filter(elem =>{
            this.props.VehicleFuelTypeList && this.props.VehicleFuelTypeList.map(vehicleFuelType =>{
              if (elem == vehicleFuelType.id) {
                arr.push(vehicleFuelType.type);
                vehicleFuelTypeArr.push(vehicleFuelType)
              }
            })
          });
          this.setState({vehicleFuelTypeData: vehicleFuelTypeArr})
        }
        if (item === 'vehicles') {
          let arr = [];
          let vehicleArr = [];
          [...searchParam.vehicles].filter(elem =>{
            this.props.VehicleList && this.props.VehicleList.map(vehicle =>{
              if (elem == vehicle.id) {
                arr.push(vehicle.category);
                vehicleArr.push(vehicle)
              }
            })
          });
          this.setState({vehicleData: vehicleArr})
        }
        if (item === 'topics') {
          let arr = [];
          let topicArr = [];
          [...searchParam.topics].filter(elem =>{
            this.props.TopicList && this.props.TopicList.map(topic =>{
              if (elem == topic.id) {
                arr.push(topic.name);
                topicArr.push(topic)
              }
            })
          });
          this.setState({topicData: topicArr})
        }
        if (item === 'topicType') {
          let arr = [];
          [...searchParam.topicType].filter(elem =>{
            [...mockData['topicTypeArrUser'][localStorage.getItem('i18nextLng')]].map(topicType =>{
              if (elem == topicType.type) {
                arr.push(topicType.type);
              }
            })
          });
          this.setState({topicTypeData: arr})
        }
        if (item === 'enforcementDateFrom') {
          [ ...searchParam['enforcementDateFrom']].filter(elem =>{
           this.setState({enfDateFromArr: new Date(elem)})
             }
          )
        }
        if (item === 'enforcementDateTo') {
             [ ...searchParam['enforcementDateTo']].filter(elem =>{
               let date =  moment(elem, "DD/MM/YYYY");
               let enfTo = date.toDate();
               this.setState({enfDateToArr: enfTo})
             }
          )
        }
        if (item === 'expiryDateFrom') {
          [ ...searchParam['expiryDateFrom']].filter(elem =>{
            let date = moment(elem, "DD/MM/YYYY")
            let expFrom = date.toDate();
            this.setState({expDateFromArr: expFrom})
              }
           )
        }
        if (item === 'expiryDateTo') {
          [ ...searchParam['expiryDateTo']].filter(elem =>{
            let date = moment(elem, "DD/MM/YYYY")
            let expTo = date.toDate();
            this.setState({expDateToArr: expTo})
              }
           )
        }
        if (item === 'status') {
          let arr = [];
          let statusArr = [];
          [...searchParam.status].filter(elem =>{
            [...mockData.statusUser[localStorage.getItem('i18nextLng')]].map(status =>{
              if (elem == status.id) {
                arr.push(status.name);
                statusArr.push(status)
              }
            })
          });
          this.setState({statusData: statusArr})
        }
      });
    }
   //handle query params and convert that in array.
    QueryStringToJSON = () => {
       const pairs = this.props.location.search.slice(1).split('&');
       const result = {};
       pairs.forEach(function(pair) {
          pair = pair.split('=');
          if (!result[pair[0]]) {
            result[pair[0]] = [];
          }
           result[pair[0]].push(decodeURIComponent(pair[1]));
       });
       this.setState({filterDataID: {...result}});
      return JSON.parse(JSON.stringify(result));
     }

    handleBack = () =>{
      this.props.history.goBack()
    }

    //download PDF and EXCEL file.
    handleFileExport = (filetype) =>{
      if(this.state.saveFlag === false){
      let searchParam = this.QueryStringToJSON();
      let {regulationFilterList} = this.state;
      var xhr = new XMLHttpRequest();
      let params = '?ln='+localStorage.getItem('i18nextLng')+'&timeZone='+Intl.DateTimeFormat().resolvedOptions().timeZone+'&'+
      qs.stringify(searchParam, {arrayFormat: 'repeat'});
      if (this.state.filterChanged === true) {
        params = '?ln='+localStorage.getItem('i18nextLng')+'&timeZone='+Intl.DateTimeFormat().resolvedOptions().timeZone+'&'+
        qs.stringify(regulationFilterList, {arrayFormat: 'repeat'});
      }

      let filename = i18next.t('regulationFilename');
      [...this.state.marketData].map(item =>{
            filename += '_'+item.label.toUpperCase();
      })
      filename += '_'+moment(new Date()).format('MMM DD YYYY h-mm a');
      if (filetype === 'PDF') {
        let apiUrl = process.env.REACT_APP_BASE_URL+'/'+PDF+params;
        FileExportPdf(apiUrl, filename, this.props.logout,this.props.isLoading,this.props.finishLoading);
      }
      else{
        let apiUrl = process.env.REACT_APP_BASE_URL+'/'+EXCEL+params;
        FileExportExcel(apiUrl, filename, this.props.logout,this.props.isLoading,this.props.finishLoading);
      }
    }
  }

   /* pass the image flag value to toggle filter component
    for apply style to PDF and EXCEL image. */
    imgFlag = (val) =>{
      this.setState({imgFlag: val});
      if (val === true) {
        this.setState({filterChanged:true});
      }
    }

    //pass regulation filter list to toggle filter component.
    toggleFilter=(val) =>{
      this.setState({regulationFilterList: val})
    }

    render() {
      return (
        <Fragment>
          <div className="favoritesData">
          <ToggleFilter
              tagData = {this.state.tagData}
              marketData={this.state.marketData}
              groupData={this.state.groupData}
              subGroupData={this.state.subGroupData}
              scopeData={this.state.scopeData}
              subScopeData={this.state.subScopeData}
              vehicleFuelTypeData={this.state.vehicleFuelTypeData}
              vehicleData={this.state.vehicleData}
              topicData={this.state.topicData}
              topicTypeData={this.state.topicTypeData}
              statusData={this.state.statusData}
              enfDtFromData={this.state.enfDateFromArr}
              enfDtToData={this.state.enfDateToArr}
              expDtFromData={this.state.expDateFromArr}
              expDtToData={this.state.expDateToArr}
              saveFlag={this.state.saveFlag}
              handlerType={this.state.functionType}
              FavoritesID={this.state.ID}
              AdvancedfilterName ={this.state.filterName}
              imgflag={this.imgFlag}
              filterData={this.toggleFilter}
              shareFlag={this.state.shareFlag}
              shareBtnFlag={true}
              URL={this.state.URL}
              driveTypeData={this.state.driveTypeData}/>

          <div id="userFavoritesTblPage">
            <UserTableComp {...this.props} imgflag={this.state.imgFlag} handleFileExport={this.handleFileExport.bind(this)} goBack/>
          </div>
          </div>
        </Fragment>
      );
     }
    }

  const mapStateToProps = (appState: AppState) => ({
    RegulationDataList: appState.user.RegulationDataList,
    MarketList: appState.user.MarketList,
    TopicList: appState.user.TopicList,
    VehicleList: appState.user.VehicleList,
    SearchTagList: appState.user.SearchTagList,
    activeKey: appState.user.activeKey,
    favoritesShare: appState.user.favoritesShare,
    filterUpdated: appState.user.filterUpdated,
    errorMessageUpdate: appState.user.UpdateFavoritesListRequest.$errorMessage,
    DriveTypeList: appState.user.DriveTypeList,
    VehicleFuelTypeList: appState.user.VehicleFuelTypeList,
    GroupList: appState.user.GroupList,
    filterSaved: appState.user.filterSaved,
    errorMessageAdded: appState.user.saveFilterRequest.$errorMessage,
    ScopeList: appState.user.ScopeList
  });

  const mapDispatchToProps = dispatch => ({
      getRegulationDetails: (filter) => Actions.user.getRegulationDetails(filter, dispatch),
      getTopicList: () => Actions.user.getTopicList(dispatch),
      getVehicleList: () => Actions.user.getVehicleList(dispatch),
      getMarketList: () => Actions.user.getMarketList(dispatch),
      getFavoritesList: (user) => Actions.user.getFavoritesList(dispatch,user),
      logout: () => Actions.user.logout(dispatch),
      reloadTemplate: (role) => Actions.user.reloadTemplate(role, dispatch),
      isLoading:() => Actions.user.isLoading(dispatch),
      finishLoading:() => Actions.user.finishLoading(dispatch),
  });

  export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FavoritesDataComp));
