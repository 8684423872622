import React, {Component,Fragment} from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import i18n from '../../i18n/I18n';
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter,Button} from 'reactstrap';
import ListGroup from 'react-bootstrap/ListGroup'
import Favorites from '../../model/Favorites';
import {Route, Redirect } from "react-router-dom";
import {toast} from 'react-toastify';
import './favorites.css';
import { url } from 'inspector';
import EditModalComp from '../Table/EditModal';
import ShareForm from '../AdvancedFilterComp/ShareForm';


interface Props extends Translate{
    multiple : boolean,
    favorites ?: any,
    deleteFavoritesList : (any) => {};
    history ?: any,
    location ?: any,
    url : string,
    errorMessage: null,
    favoritesDeleted: boolean,
    favoritesShare: boolean
  }

interface State{
   multiple: boolean,
   favorites: Favorites[],
   id : number,
   activeIndex : number,
   modal: boolean,
   errorMessage: string,
   modelFlag: boolean,
   formName: string,
   favoritesURL?: any,
   filterName?: any
  }

export class FavoritesListComp extends Component<Props, State> {
    constructor(props){
      super(props)
      this.state={
        multiple: false,
        favorites : [],
        id : null,
        activeIndex: -1 ,
        modal : false,
        errorMessage: null,
        modelFlag: false,
        formName: 'Share Filter',
        favoritesURL: '',
        filterName: ''
      }
      this.changeValue = this.changeValue.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (prevState.favorites !== nextProps.favorites) {
       return {
        favorites : nextProps.favorites
        };
      }
      return {
        errorMessage: nextProps.errorMessage
      }
    }

    componentDidUpdate(prevProps, prevState){
      if(prevProps.favorites !== this.props.favorites){
        this.setState({favorites : this.props.favorites})
        }

      if (this.props.favoritesDeleted && prevProps.favoritesDeleted !== this.props.favoritesDeleted) {
        toast['success'](i18next.t('favorites.deleteFavoritesSuccess'), { position: 'top-right' });
      }
      if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
        toast['error'](i18next.t(this.state.errorMessage), { position: 'top-right' });
      }
      if (this.props.favoritesShare && prevProps.favoritesShare !== this.props.favoritesShare) {
        toast['success'](i18next.t('favorites.shareSuccess'), { position: 'top-right' });
      }

    }

    //close delete modal.
    closeModal = () =>{
        this.setState({modal: false, modelFlag: false});
      }

    //show delete modal.
    showModal = (id : number) =>{
         this.setState({modal: true , id : id})
     }

     //call on click of favorite item and used to open the favorites data page.
    changeValue = (index: number ,url : string , id: number, name: string) => {
      this.setState({activeIndex: index, id:id});
      localStorage.setItem("FavoritesID", JSON.stringify(id));
      localStorage.setItem("filterName", JSON.stringify(name));
      this.props.history.push({
        pathname : '/app/favorites',
        search : url,
      })
    }
    //call on click of delete modal ok button and call delete api.
    delete = () =>{
      const {id} = this.state;
      this.props.deleteFavoritesList(id);
      this.setState({modal: false, activeIndex : -1})
     }

     //open a share modal.
    showShareModel= (url, name) =>{
      this.setState({modelFlag: true, favoritesURL: url , filterName: name})
    }
    render() {
        const {multiple} = this.state;
        const {favorites} = this.props;
        let formTemplate, modalTitle = null;
        if(this.state.formName === 'Share Filter') {
          formTemplate = <ShareForm {...this.props}  closeModal={this.closeModal} URL={this.state.favoritesURL} FilterName={this.state.filterName}/>
          modalTitle = i18next.t('shareFormTitle');
        }
        return (
          <Fragment>
            <Translation>{t => (
                <ReactCSSTransitionGroup
                    component="div"
                    className="favListMainDiv"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>

                    {/* Favorites delete confirmation modal */}
                    <Modal isOpen={this.state.modal} centered={true}>
                       <ModalHeader>{t('cnfDeleteLabel')}</ModalHeader>
                       <ModalBody>
                           {t('cnfDelete')}
                       </ModalBody>
                       <ModalFooter>
                           <Button color="primary" onClick={this.delete.bind(this)}>{t('yes')}</Button>
                           <Button color="primary" onClick={this.closeModal}>{t('no')}</Button>
                       </ModalFooter>
                    </Modal>

                  {this.state.favorites && this.state.favorites.length > 0 ?
                    <Row>
                        { this.state.modelFlag &&
                         <EditModalComp modalID="shareModal" closeModal={this.closeModal} modalBody={formTemplate} modalTitle={modalTitle} />
                          }
                       <div className="col-12">
                         <ListGroup as="ul">
                            {this.state.favorites && this.state.favorites.map((item: any,index) => {
                             const className = this.state.activeIndex === index ? 'list active' : 'list';
                            return(
                                <ListGroup.Item className={className} key={index} action variant="light" as="li">
                                    <a onClick={(Event) => this.changeValue(index,item.url,item.id, item.name)}>{item.name}</a>
                                    <i className="pe-7s-trash icon" onClick={(Event) => this.showModal(item.id)}></i>
                                    <i className="pe-7s-share icon" onClick={(Event) => this.showShareModel(item.url,item.name)}></i>
                                </ListGroup.Item>)
                            })}
                         </ListGroup>
                        </div>
                     </Row>:
                   <h5 style={{'textAlign':'center'}}>{i18next.t('favorites.noFavoritesMsg')}</h5>}
                </ReactCSSTransitionGroup>
            )}</Translation>

          </Fragment>

        )
    }
}

const mapStateToProps = (appState: AppState) => ({
       favorites : appState.user.favorites,
       favoritesDeleted: appState.user.favoritesDeleted,
       favoritesShare: appState.user.favoritesShare,
       errorMessage: appState.user.favoritesListRequest.$errorMessage
       });

const mapDispatchToProps = dispatch => ({
       deleteFavoritesList : (favorites) => Actions.user.deleteFavoritesList(favorites, dispatch)
       });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FavoritesListComp));
