import React, { Component } from "react";
import { connect } from "react-redux";
import { Translate } from "../../model/interfaces/translate.interface";
import { Translation } from "react-i18next";
import { Button } from "reactstrap";
import "./manageFaq.css";
import Actions from "../../internal/modules/Actions";
import { languageArr } from "./FaqColData";

interface Props extends Translate {
  rowData?: any;
  formtype?: any;
  closeModal?: any;
  editFaq: (any) => {};
  addFaq: (any) => {};
}

interface State {
  rowData?: any;
  errFieldMsg?: any;
}

class FaqForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      rowData: this.props.rowData,
      errFieldMsg: {
        questionen: false,
        questionde: false,
        answeren: false,
        answerde: false,
      },
    };
  }

  //check mandatory field are filled or not and display error message.
  validateField = (lang, event) => {
    let { errFieldMsg } = this.state;
    if (event.target.type === "text" && event.target.value.trim() === "") {
      event.target.value = "";
      errFieldMsg[event.target.id] = true;
    } else {
      errFieldMsg[event.target.id] = false;
    }
    this.setState(errFieldMsg);
  };

  //setting input field value of vehicle name on change.
  inputHandler = (id, lang, event) => {
    let arr =
      Object.keys(this.state.rowData).length < 1
        ? { data: {} }
        : { ...this.state.rowData };
    if (!arr.data[lang]) {
      arr.data[lang] = {};
    }
    arr.data[lang][id] = event.target.value;
    this.setState({ rowData: arr });
  };

  // call on form submit and create and edit vehicle api call.
  SaveFormHandler = () => {
    let postArr = [];
    [...languageArr].map((lang) => {
      let obj =
        this.state.rowData.data[lang.code] !== undefined
          ? this.state.rowData.data[lang.code]
          : { [lang.code]: {} };
      obj["languageCode"] = lang.code;
      obj["id"] = this.state.rowData.id;
      obj['question'] = obj['question'].trim()
      obj['answer'] = obj['answer'].trim()
      postArr.push(obj);
    });
    if (this.props.formtype === "edit") {
      this.props.editFaq(postArr);
    } else {
      this.props.addFaq(postArr);
    }    
    this.props.closeModal();
  };

  render() {
    let { rowData } = this.state;

    return (
      <Translation>
        {(t) => (
          <>
            <form onSubmit={this.SaveFormHandler} id="faqForm">
              <div className="form-inline col-12">
                <div className="col-2">
                  <h6 className="col-form-label">
                    <b>{t("mngFaqs.attribute")}</b>
                  </h6>
                </div>
                {[...languageArr].map((lang) => {
                  return (
                    <div className="col-5">
                      <h6 className="col-form-label">
                        <b>{t("language." + lang.code)} *</b>
                      </h6>
                    </div>
                  );
                })}
              </div>
              <div className="form-inline col-12 mb-2">
                <div className="col-2">
                  <h6>
                    <b>{t("mngFaqs.questionLabel")} *</b>
                  </h6>
                </div>
                {[...languageArr].map((lang) => {
                  return (
                    <div className="col-5 labelTextCss">
                      <h6 className="vehicleContainer">
                        <textarea
                          rows={2}
                          onBlur={this.validateField.bind(this, lang.code)}
                          title= {
                            rowData.data &&
                            rowData.data[lang.code] &&
                            rowData.data[lang.code].question
                          }
                          id={"question" + lang.code}
                          value={
                            rowData.data &&
                            rowData.data[lang.code] &&
                            rowData.data[lang.code].question
                          }
                          onChange={this.inputHandler.bind(
                            this,
                            "question",
                            lang.code
                          )}
                          className="form-control textareaInput"
                          placeholder={t("mngFaqs.questionPlaceholder")}
                          required
                        />
                      </h6>
                    </div>
                  );
                })}
              </div>
              <div className="form-inline col-12">
                <div className="col-2">
                  <h6>
                    <b>{t("mngFaqs.answerLabel")} *</b>
                  </h6>
                </div>
                {[...languageArr].map((lang) => {
                  return (
                    <div className="col-5 labelTextCss">
                      <h6 className="vehicleContainer">
                        <textarea
                          rows={2}
                          onBlur={this.validateField.bind(this, lang.code)}
                          title={
                            rowData.data &&
                            rowData.data[lang.code] &&
                            rowData.data[lang.code].answer
                          }
                          id={"answer" + lang.code}
                          value={
                            rowData.data &&
                            rowData.data[lang.code] &&
                            rowData.data[lang.code].answer
                          }
                          onChange={this.inputHandler.bind(
                            this,
                            "answer",
                            lang.code
                          )}
                          className="form-control textareaInput"
                          placeholder={t("mngFaqs.answerPlaceholder")}
                          required
                        />
                      </h6>
                    </div>
                  );
                })}
              </div>
              <hr />
              <div className="form-group row">
                <div className="col-12" style={{ textAlignLast: "end" }}>
                  <Button color="link" onClick={this.props.closeModal}>
                    {t("cancel")}
                  </Button>
                  <Button color="primary" type="submit">
                    {t("save")}
                  </Button>
                </div>
              </div>
            </form>
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  editFaq: (faq) => Actions.admin.editFaq(dispatch, faq),
  addFaq:(faq) => Actions.admin.addFaq(dispatch,faq)
})

export default connect(mapStateToProps, mapDispatchToProps)(FaqForm);
