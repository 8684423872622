import React, { Component } from "react";
import "./landingPage.css";
import { Translation } from "react-i18next";
import { Collapse, Card, CardBody } from "reactstrap";
import { BiChevronRight } from "react-icons/bi";

interface Props {
  question: string;
  answer: string;
  closeModal ?: any;
}

interface State {
  collapse: boolean;
}

class Faq extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }
  toggleCollapse() {
    this.setState({ collapse: !this.state.collapse });
  }
  render() {
    const { question, answer } = this.props;
    return (
      <>
        <Translation>
          {(t) => (
            <> 
              <p onClick={this.toggleCollapse} className="faqQuestion">
                <BiChevronRight
                  size={21}
                  className={`${this.state.collapse && "open"}`}
                />{" "}
                {question}
              </p>
              <Collapse isOpen={this.state.collapse}>
                <p className="faqAnswer">{answer}</p>
              </Collapse>
            </>
          )}
        </Translation>
      </>
    );
  }
}

export default Faq;
