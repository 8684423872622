import {createAction} from "../../utils/AppUtils";
import { ModuleEvents as ModuleEventsLoading } from '../loading/Actions';
import { ModuleEvents as ModuleEventsCommon } from '../common/Actions';
import CommonService from '../../internal/common/CommonService';

export const ModuleEvents = {
  NOTIFICATION_LIST:'NOTIFICATION_LIST',
  ERROR_NOTIFICATION_LIST: 'ERROR_NOTIFICATION_LIST',
  RESET_NOTIFICATION_LIST: 'RESET_NOTIFICATION_LIST',

  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
  ERROR_DELETE_NOTIFICATION: 'ERROR_DELETE_NOTIFICATION',
  RESET_DELETE_NOTIFICATION: 'RESET_DELETE_NOTIFICATION',

  DELETE_ALL_NOTIFICATION: 'DELETE_ALL_NOTIFICATION',
  ERROR_DELETE_ALL_NOTIFICATION: 'ERROR_DELETE_ALL_NOTIFICATION',
  RESET_DELETE_ALL_NOTIFICATION: 'RESET_DELETE_ALL_NOTIFICATION',

  MARK_AS_READ: 'MARK_AS_READ',
  ERROR_MARK_AS_READ: 'ERROR_MARK_AS_READ',
  RESET_MARK_AS_READ: 'RESET_MARK_AS_READ',

  MARK_AS_READ_ALL: 'MARK_AS_READ_ALL',
  ERROR_MARK_AS_READ_ALL: 'ERROR_MARK_AS_READ_ALL',
  RESET_MARK_AS_READ_ALL: 'RESET_MARK_AS_READ_ALL'
}

export default {

  //Fetching notifications list for the user
  getNotificationList: (dispatch) => {
      dispatch({type: ModuleEventsLoading.LOADING});
      return CommonService.getNotificationList().then(response => {
         dispatch({type: ModuleEventsLoading.FINISHLOADING});
         dispatch({type: ModuleEvents.NOTIFICATION_LIST, payload: response.data})
      }, error => {
         dispatch({type: ModuleEventsLoading.FINISHLOADING});
         dispatch({type: ModuleEvents.ERROR_NOTIFICATION_LIST, payload: error})
     })
   },

   //Deleting single notification by ID
   deleteNotification: (notificationId, dispatch) => {
       return CommonService.deleteNotification(notificationId).then(response => {
          dispatch({type: ModuleEvents.DELETE_NOTIFICATION, payload: notificationId})
       }, error => {
          dispatch({type: ModuleEvents.ERROR_DELETE_NOTIFICATION, payload: error})
      })
    },

    //Deleting all notifications for the user
    deleteAllNotification: (dispatch) => {
        return CommonService.deleteAllNotification().then(response => {
           dispatch({type: ModuleEvents.DELETE_ALL_NOTIFICATION, payload: response.data})
        }, error => {
           dispatch({type: ModuleEvents.ERROR_DELETE_ALL_NOTIFICATION, payload: error})
       })
     },

     //Mark single notification as read
     markAsReadNotification: (notificationId, dispatch) => {
         return CommonService.markAsReadNotification(notificationId).then(response => {
            dispatch({type: ModuleEvents.MARK_AS_READ, payload: notificationId})
         }, error => {
            dispatch({type: ModuleEvents.ERROR_MARK_AS_READ, payload: error})
        })
      },

      //Mark all notifications as read
      markAsReadAllNotification: (dispatch) => {
          return CommonService.markAsReadAllNotification().then(response => {
             dispatch({type: ModuleEvents.MARK_AS_READ_ALL, payload: response.data})
          }, error => {
             dispatch({type: ModuleEvents.ERROR_MARK_AS_READ_ALL, payload: error})
         })
       }
};
