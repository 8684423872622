import React, {Component,Fragment, useState} from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import i18next from 'i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import RegulationList from './../../model/RegulationList';
import AllLangDataList from './../../model/AllLangDataList';
import i18n from '../../i18n/I18n';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import DeleteIcon from 'react-ionicons/lib/MdTrash';
import EditIcon from 'react-ionicons/lib/MdCreate';
import ViewIcon from 'react-ionicons/lib/IosEye';
import UndoIcon from 'react-ionicons/lib/MdUndo';
import 'bootstrap/dist/css/bootstrap.min.css';
import './table.css';
import ReactTooltip from 'react-tooltip';
import {
    Row, Col, Button
} from 'reactstrap';
import moment from "moment";


interface Props extends Translate{
  documentsData?: any;
  dataType: string;
  onDocEdit: (row) => {};
  onDocDelete: (row, market) => {};
  openDeleteModal:(row,market) => {};
  downloadFile: (id, name) => any;
  formtype?: string;
  marketCode: string;
  deleteRowIdArr?: any;
  undoDeleteChanges: (row) => {};
}

interface State{
  tableData?: any;
}

class AdminDocumentTableComp extends Component<Props, State> {
    constructor(props){
      super(props)
      this.state={
          tableData: []
      }
    }

    componentDidMount(){
      this.setTableData();
    }

   componentDidUpdate(prevProps, prevState){
     if(prevProps.documentsData !== this.props.documentsData){
       this.setTableData();
      }
   }

   //setting table data.
   setTableData = () =>{
     if(this.props.dataType === 'marketProfile'){
       this.setState({tableData: this.props.documentsData})
     }
     else{
      const tableArr = this.props.documentsData?.filter(item => item.category === this.props.dataType);
      this.setState({tableData: tableArr})
     }
    
   }

   //setting document name add anchor tag to document name.
   documentName = (cell,row) => {
     return(<div><a data-tip={this.props.deleteRowIdArr.indexOf(row.id) === -1 ? row.shortDescription: ''} style={{'pointerEvents': this.props.deleteRowIdArr.indexOf(row.id) === -1 ? 'auto': 'none', color: '#545cd8', cursor: 'pointer'}} data-for={`${row.id+'fileName'}`} onClick={() => this.props.downloadFile(row.id, row.name)}>{cell}</a>
     <ReactTooltip id={`${row.id+'fileName'}`} place="bottom" type="info" effect="float"/></div>)
    }

    //convert date in utc format.
    modifiedDate = (cell,row) =>{
      if (cell) {
        return moment.utc(cell, 'DD-MM-YYYY HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss');
      } else {
        return '';
      }
    }

    //add style to deleted row.
    rowClassNameFormat = (row, rowIdx) => {
      return this.props.deleteRowIdArr.indexOf(row.id) !== -1 ? 'highlighted' : '';
    }

    //format action column, display action icons.
    actionColFormatter = (cell, row) => {
      return (
        <Row>
        <Row style={{marginLeft:'10px'}}>
        <Col md="3"style={{paddingLeft:'1px'}}>
        <a data-tip={this.props.deleteRowIdArr.indexOf(row.id) === -1 ? i18next.t('table.editDocumntTooltip'): ''} data-for="editHandler">
         <EditIcon className={this.props.deleteRowIdArr.indexOf(row.id) !== -1 ? 'deleteCss' : 'undoCss'} onClick={() => this.props.onDocEdit(row)} />
        </a>
        <ReactTooltip id="editHandler" place="right" type="info" effect="float"/>
        </Col></Row>
        <Col md="3"style={{paddingLeft:'1px'}}>
        <a data-tip={this.props.deleteRowIdArr.indexOf(row.id) === -1 ? i18next.t('table.deleteDocumntTooltip') : ''} data-for={`${row.id+'deleteHandler'}`}>
         <DeleteIcon className={this.props.deleteRowIdArr.indexOf(row.id) !== -1 ? 'deleteCss' : 'undoCss'} onClick={() => this.props.openDeleteModal(row, this.props.marketCode)} />
        </a>
        <ReactTooltip id={`${row.id+'deleteHandler'}`} place="right" type="info" effect="float"/>
        </Col>
        {this.props.deleteRowIdArr.indexOf(row.id) !== -1 &&
        <Col md="3"style={{paddingLeft:'1px'}} >
        <a data-tip={i18next.t('table.undoDocumntTooltip')} data-for={`${row.id+'undoHandler'}`}>
         <UndoIcon style={{'cursor':'pointer'}} onClick={() => this.props.undoDeleteChanges(row)}/>
        </a>
        <ReactTooltip id={`${row.id+'undoHandler'}`} place="right" type="info" effect="float"/>
        </Col>}
        </Row>
      );
    };
  render() {
    const options = {
      noDataText: i18next.t('table.noDataMsg')
    };
     return(
        <div id="adminDocumentTable">
          <div id={this.props.formtype}>
          <Translation>{t => (
            <Fragment>
             <BootstrapTable data={this.state.tableData} options={options} trClassName={this.rowClassNameFormat}>
               <TableHeaderColumn dataField='name' dataFormat={this.documentName}isKey>{i18next.t('table.name')}</TableHeaderColumn>
               <TableHeaderColumn dataField='modifiedOn' dataFormat={this.modifiedDate}>{i18next.t('table.date')}</TableHeaderColumn>
               <TableHeaderColumn dataField='size'>{i18next.t('table.size')}</TableHeaderColumn>
               {
                 (this.props.formtype === 'edit' || this.props.formtype === 'confirm') &&
                 <TableHeaderColumn dataField='action' dataFormat={this.actionColFormatter}>{i18next.t('table.action')}</TableHeaderColumn>
               }
                {
                 (this.props.formtype === 'add' && this.props.dataType === 'marketProfile') &&
                 <TableHeaderColumn dataField='action' dataFormat={this.actionColFormatter}>{i18next.t('table.action')}</TableHeaderColumn>
               }
             </BootstrapTable>
             </Fragment>
      )}</Translation>
      </div>
      </div>
    );
  }
}
const mapStateToProps = (appState: AppState) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminDocumentTableComp));
