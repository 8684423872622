import React, {Component,Fragment} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import i18next from 'i18next';

interface Props extends Translate{
  closeModal: () => {};
  eventHandlerDelete: () => {};
  modalMsg: string;
}

interface State{
    modal: boolean
}

class DeleteModalComp extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            modal: true
        };
    }

    render() {
        return (
          <Translation>{t => (
              <Modal isOpen={this.state.modal} toggle={this.props.closeModal} centered={true}>
                  <ModalHeader toggle={this.props.closeModal}>{i18next.t('confirm')}</ModalHeader>
                  <ModalBody>
                      <b><h6>{this.props.modalMsg}</h6></b>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="link" onClick={this.props.closeModal}>{i18next.t('no')}</Button>
                    <Button color="primary" onClick={this.props.eventHandlerDelete}>{i18next.t('yes')}</Button>
                  </ModalFooter>
              </Modal>)}</Translation>
    );
}
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteModalComp));
