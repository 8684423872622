import React, { Fragment, Component, useState } from "react";
import { Translate } from "../../model/interfaces/translate.interface";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Actions from "../../internal/modules/Actions";
import { Redirect, withRouter } from "react-router-dom";
import { AppState } from "../../internal/modules/Reducers";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { toast } from "react-toastify";
import i18next from "i18next";
import ListGroup from 'react-bootstrap/ListGroup';
import './SubscribeComp.css';
import SearchField from 'react-search-field';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Button} from 'reactstrap';
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';


interface Props extends Translate {
  TopicList?: any;
  getTopicList: () => {};
  subscibeTopics: (any) => {};
  subscriptionAdded?: any;
  getSubscibedTopics : () => {};
  SubscribedTopicList ?: any;
  loading : boolean;
}

interface State {
  topicList?: any,
  dropdownOpen: boolean,
  filteredArr ?: any,
  checkedID ?:any,
  checked?: any;
}

class SubscribeComp extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      topicList: this.props.SubscribedTopicList,
      dropdownOpen: false,
      filteredArr : '',
      checkedID : [],
      checked : false
    };
  }
  componentDidMount(){
    //If props data is undefined then only calling its api
     if(!this.props.SubscribedTopicList){
      this.props.getSubscibedTopics();
     }
     if(this.props.SubscribedTopicList?.length !== 0){
      let idArr = [];
      this.props.SubscribedTopicList?.filter(a => a.subscribed === true).map(x => x.subscribeIds?.map(i => idArr.push(i)));
      this.setState({checkedID: idArr})
     }
    }
   
    static getDerivedStateFromProps(nextProps, prevState) {
      return {
        loading: nextProps.loading,
        success: nextProps.success,
        errorMessage: nextProps.errorMessage,
        errorMessageFilter: nextProps.errorMessageFilter,
      };
    } 
componentDidUpdate(prevProps, prevState){
  if(prevProps.SubscribedTopicList !== this.props.SubscribedTopicList){
      let idArr = [];
      this.props.SubscribedTopicList.filter(a => a.subscribed=== true).map(x => x.subscribeIds?.map(i => idArr.push(i)));
      this.setState({topicList : this.props.SubscribedTopicList,checkedID: idArr})
    }
    if (this.props.subscriptionAdded && prevProps.subscriptionAdded !== this.props.subscriptionAdded) {
      toast['success'](i18next.t('subscribe.subAddSuccess'), { position: 'top-right' });
    }
}
handleCheckboxChange = (item, event) => {
 event.persist();
  const { checkedID } = this.state;
  let newArr = [];
  let idArr = [];
  item?.subscribeIds?.map(x => idArr.push(x));
  item?.subscribeIds?.map(x =>{
    if (!checkedID.includes(x)) {
          newArr = checkedID.concat(idArr);
      } else {
        newArr = checkedID.filter(a =>!idArr.includes( a ));
      }
    })
   this.state.topicList.map(topic => {
     if (topic.id === item.id) {
       topic.subscribed = event.target.checked
      }
  })
 this.setState({ checkedID: newArr })
}

onSearchChange = (e) =>{
  let arr = []
  this.props.SubscribedTopicList && this.props.SubscribedTopicList.filter(post => {
    if (e == '') {
      arr = this.props.SubscribedTopicList
      return post;
    } else if (post.name.toLowerCase().includes(e.toLowerCase())) {
      arr.push(post)
      return post;
    }
  }).map((post, index) => {
    if(e == ''){
      arr = this.props.SubscribedTopicList
    }
    else{
      arr.push(post)
    }
   })
  this.setState({topicList: arr})
}

addSubcription = () =>{
  this.props.subscibeTopics(this.state.checkedID)  
}
  render() {
    const secondColumnStart = Math.ceil(this.state.topicList && this.state.topicList.length / 2);
    const spinner4 = <LoaderAnim active type="ball-beat"/>;
    
    return <Translation>{(t) => 
      (<Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
         <div className="subscribe">
            <Row className="topDiv">
              <Col>
               <SearchField  placeholder={i18next.t('subscribe.searchPlaceholder')}onChange={this.onSearchChange}/>
              </Col>
              <Col style={{textAlign: 'end',padding: 0}}>
                <Button id="applyFilter" className="btn btn-success" onClick={this.addSubcription}>{i18next.t('save')}</Button>
              </Col>
            </Row>
            <Loader message={spinner4} show={this.props.loading}>
              <Row className="subTabRow">
                     <Col>
                        <ListGroup as="ul">
                          {this.state.topicList && this.state.topicList.slice(0, secondColumnStart).map((item: any,index) => {
                              return(
                                  <ListGroup.Item  key={index} action variant="light" as="li">
                                      <Row className="form-inline">
                                        <input style={{'marginLeft': '15px'}} checked={item.subscribed} type="checkbox" onChange={this.handleCheckboxChange.bind(this,item)}></input>
                                        <span className={'readMoreCss'}> {item.name} </span>
                                      </Row>
                                  </ListGroup.Item>)
                              })}
                        </ListGroup>
                      </Col>
                      <Col style={{padding: 0}}>
                          <ListGroup as="ul">
                            {this.state.topicList && this.state.topicList.slice(secondColumnStart).map((item: any,index) => {
                                return(
                                    <ListGroup.Item  key={index} action variant="light" as="li">
                                        <Row className="form-inline">
                                            <input style={{'marginLeft': '15px'}} checked={item.subscribed} type="checkbox" onChange={this.handleCheckboxChange.bind(this,item)}></input>
                                            <span className='readMoreCss'> {item.name} </span>
                                        </Row>
                                    </ListGroup.Item>)
                                })}
                           </ListGroup>
                      </Col>
              </Row>
            </Loader>    
         </div>
       </ReactCSSTransitionGroup>
      </Fragment>)}
      </Translation>;
  }
}

const mapStateToProps = (appState: AppState) => ({
  SubscribedTopicList: appState.user.SubscribedTopicList,
  subscriptionAdded: appState.user.subscriptionAdded,
  loading: appState.loading.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getTopicList: () => Actions.user.getTopicList(dispatch),
  subscibeTopics: (id) => Actions.user.subscibeTopics(id, dispatch),
  getSubscibedTopics : () => Actions.user.getSubscibedTopics(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeComp);
