import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import './GRM.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button} from 'reactstrap';
import i18next from 'i18next';
import {languageArr} from './RegulationsColData';

interface Props extends Translate{
  docRowData?: any;
  formtype: string;
  closeModal: () => any;
  selectedDocID: string;
  marketCode: string;
  MarketList?: any;
  appendDocInToJson: (array, market, file) => any;
  rowData?: any;
  newDocuments?: any;
  allDocsForMarket?: any;
  handleDocumentChange?: any;
}

interface State{
  showErrFlag:boolean;
  selectedFile?: any;
  docRowData?: any;
  docDescEN: string;
  docDescDE: string;
  fileName: string;
  docType: string;
  fileSize: boolean;
  uniqueFileErrFlag: boolean;
  totalFileSizeLmtFlag: boolean;
}

class DocumentForm extends Component<Props, State> {
  constructor(props) {
    super(props)
      this.state = {
        showErrFlag:false,
        selectedFile: null,
        docRowData:this.props.docRowData,
        docDescEN: "",
        docDescDE: "",
        fileName: "",
        docType: "Add",
        fileSize: false,
        uniqueFileErrFlag: false,
        totalFileSizeLmtFlag: false
      }
  }

  componentDidMount(){
    if (this.props.docRowData) {
      const {docRowData} = this.props;
      let code = 'docDesc'+localStorage.getItem('i18nextLng').toUpperCase();
      this.state[code] = docRowData.shortDescription;
      this.setState({fileName: docRowData?.name, docType: 'Edit'});
      if(this.props.selectedDocID === 'marketProfile'){
        for(const property in this.props.allDocsForMarket){
         if (property != localStorage.getItem('i18nextLng')) {
           let array = this.props.allDocsForMarket?.[property];
           for (let index = 0; index < array.length; index++) {
            if (docRowData.id === array[index].id) {
              let code = 'docDesc'+property.toUpperCase();
              this.state[code] = array[index].shortDescription;
              break;
            }
          }
         }
        }
      }
      for (const property in this.props.allDocsForMarket[this.props.marketCode]) {
        if (property != localStorage.getItem('i18nextLng')) {
          let array = this.props.allDocsForMarket?.[this.props.marketCode][property];
          for (let index = 0; index < array.length; index++) {
            if (docRowData.id === array[index].id) {
              let code = 'docDesc'+property.toUpperCase();
              this.state[code] = array[index].shortDescription;
              break;
            }
          }
        }
      }
    }
  }

  addFiles = (event) =>{
    event.preventDefault();
    let {allDocsForMarket} = this.props;
    let {selectedFile, fileName,fileSize, totalFileSizeLmtFlag} = this.state;
    let isFileNameUnique = true;
    if(this.props.selectedDocID === 'marketProfile'){
      if (allDocsForMarket?.en) {
        const allDocuments = allDocsForMarket?.en;
         for (let i = 0; i < allDocuments.length; i++) {
           if ((allDocuments[i].name == fileName) || (allDocuments[i].name == this.props.docRowData?.name)) {
             if (this.state.docType == 'Add') {
               isFileNameUnique = false;
               break;
             } else if (allDocuments[i].id !== this.props.docRowData?.id){
               isFileNameUnique = false;
               break;
             }
           }
         }
        }
        
    }
    else{
     if (allDocsForMarket?.[this.props.marketCode]?.en) {
     const allDocuments = allDocsForMarket?.[this.props.marketCode]?.en;
      for (let i = 0; i < allDocuments.length; i++) {
        if (allDocuments[i].category == this.props.selectedDocID && ((allDocuments[i].name == fileName) || (allDocuments[i].name == this.props.docRowData?.name))) {
          if (this.state.docType == 'Add') {
            isFileNameUnique = false;
            break;
          } else if (allDocuments[i].id !== this.props.docRowData?.id){
            isFileNameUnique = false;
            break;
          }
        }
      }
     }
    }

    if (!isFileNameUnique) {
      this.setState({showErrFlag: true, uniqueFileErrFlag: true});
    } else if (!fileName || !this.state.docDescEN.trim()) {
      this.setState({showErrFlag: true, uniqueFileErrFlag: false});
    } else if (!fileSize && !totalFileSizeLmtFlag){

      const size = selectedFile ? this.formatFileSize(selectedFile.size) : this.props.docRowData?.size;
      let rowID = Math.floor((Math.random() * 1000000) + 1);
      const arr = [
      {
      "market": this.props.marketCode,
			"languageCode": "en",
			"name": fileName ? fileName : this.props.docRowData?.name,
			"size": size,
			"shortDescription": this.state.docDescEN,
			"category": this.props.selectedDocID,
      "id": this.state.docType == 'Add' ? rowID : this.props.docRowData?.id,
      "regulation":{
        "id": this.state.docType == 'Add' ? rowID : this.props.docRowData?.id,
        "languageCode": "en"
      },
      "modifiedOn": "",
      "docType": this.state.docType
		}, {
      "market": this.props.marketCode,
			"languageCode": "de",
			"name": fileName ? fileName : this.props.docRowData?.name,
			"size": size,
			"shortDescription": this.state.docDescDE,
			"category": this.props.selectedDocID,
			"id": this.state.docType == 'Add' ? rowID : this.props.docRowData?.id,
      "regulation":{
        "id": this.state.docType == 'Add' ? rowID : this.props.docRowData?.id,
        "languageCode": "de"
      },
      "modifiedOn": "",
      "docType": this.state.docType
		}
  ];
  this.props.appendDocInToJson(arr, this.props.marketCode, selectedFile)
  this.props.handleDocumentChange(true)
    }
  }

  formatFileSize(bytes) {
   if(bytes == 0) return '0 Bytes';
   var k = 1000,
       dm = 2,
       sizes = ['Bytes', 'KB', 'MB', 'GB'],
       i = Math.floor(Math.log(bytes) / Math.log(k));
   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

  fileChangeHandler = event =>{
    let {newDocuments} = this.props;
    this.setState({fileSize: false, selectedFile: event.target.files[0], fileName: event.target.files[0]?.name});
    if(event.target.files[0].size !== null && event.target.files[0].size > 52428800){
       this.setState({fileSize: true});
    }

    if (newDocuments?.documentList) {
      const array = newDocuments?.documentList;
      let totalDocSize = event.target.files[0].size !== null ? event.target.files[0].size : 0;

      //Loop through all the documents added or edited and update total size of all newly added files
      for (let i = 0; i < array.length; i++) {
        if (array[i][0].docType == 'Add') {
          if (array[i][0].size.includes("KB")) {
            totalDocSize += parseInt(array[i][0].size.replace("KB","").trim())*1000;
          } else if (array[i][0].size.includes("MB")) {
            totalDocSize += parseInt(array[i][0].size.replace("MB","").trim())*1000000;
          }
        }
      }

      /* Calculating files size of all newly added files in all markets not to be greater than 200MB else
         displaying max file size per request message */
      if (totalDocSize > 200000000) {
        this.setState({totalFileSizeLmtFlag: true});
      } else {
        this.setState({totalFileSizeLmtFlag: false});
      }
    }
  }

  inputHandler = (langCode, event) =>{
    if (langCode == 'en') {
      this.setState({docDescEN: event.target.value});
    } else {
      this.setState({docDescDE: event.target.value});
    }
  }

  render() {
    let {docRowData} = this.props;
    let {showErrFlag, fileName, docDescEN, fileSize, uniqueFileErrFlag, totalFileSizeLmtFlag} = this.state;

    return(
      <Translation>{t => (
        <div className="form-inline col-sm-12">
        <label htmlFor="file-upload" className="uploadDocBtnCss btn btn-success">
            {t('mngRegulations.uploadFile')}
        </label>
        <div className="col-sm-6" ><h6 style={{fontSize: "11px"}}><i>({t('mngRegulations.fileSizeMsg')})</i></h6></div>

          <input id="file-upload" style={{'display':'none'}} accept=".pdf, .xls, .xlsx, .csv, .jpg, .png" type="file" onChange={this.fileChangeHandler}></input>

          <div className="form-inline col-sm-12" id="uploadFileNameLabel">
          {fileName &&
          <p>File: {fileName}</p>}
          </div>

          <div className="form-inline col-sm-12 GrmheaderRow">
            <div className="col-4"><h6 className="col-form-label"><b>{i18next.t('mngRegulations.attribute')}</b></h6></div>
            {
              [...languageArr].map(lang =>{
                return (
                  <div className="col-4"><h6 className="col-form-label"><b>{i18next.t('language.'+lang.code)} {lang.code === 'en' && '*' }</b></h6></div>
                )
              })
            }
          </div>
          <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
            <div className="col-2"><h6><b>{i18next.t('description')} *</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-5 labelTextCss">
                      <h6>
                        <textarea
                          placeholder={i18next.t('docDescPlaceholder')}
                          value={this.state['docDesc'+lang.code.toUpperCase()]}
                          onBlur={() => null}
                          className={`form-control`}
                          rows={3}
                          id={'marketDoc'+lang.code}
                          onChange={this.inputHandler.bind(this, lang.code)}
                          name={'marketDoc'}
                          required={lang.code === 'en' ? true : false}>
                      </textarea>
                      </h6>
                    </div>
                  )})
              }
          </div>
          <div className="form-inline col-sm-12">
            { showErrFlag &&
              <div className="col-sm-12" style={{display:"grid", padding:0}}>
                {!fileName && <li style={{color: 'red', fontSize:'smaller'}}><span >{t('fileRequiredMsg')}</span></li>}
                {!docDescEN && <li style={{color: 'red', fontSize:'smaller'}}><span>{t('docDescRequiredMsg')}</span></li>}
                {uniqueFileErrFlag && <li style={{color: 'red', fontSize:'smaller'}}><span>{t('uniqueFileNameRequiredMsg')}</span></li>}
              </div>
            }
            {fileSize && <li style={{color: 'red', fontSize:'smaller'}}><span>{t('fileSizeExceed')}</span></li>}
            {totalFileSizeLmtFlag && <li style={{color: 'red', fontSize:'smaller'}}><span>{t('totalFileSizeLmtMsg')}</span></li>}

            <div className="col-sm-12 grmFormBtn">
              <Button color="primary" onClick={this.addFiles.bind(this)}>{t('save')}</Button>
            </div>
          </div>
        </div>
      )}</Translation>
    )
  }
}


  const mapStateToProps = (appState: AppState) => ({
    MarketList: appState.admin.MarketList
  });

  const mapDispatchToProps = dispatch => ({
  });

  export default connect(mapStateToProps, mapDispatchToProps)(DocumentForm);
