import React, {Fragment, Component} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Typeahead} from 'react-bootstrap-typeahead';
import i18n from '../../i18n/I18n';
import i18next from 'i18next';
import {toast} from 'react-toastify';
import avatar1 from '../../assets/utils/images/avatars/dummyUser.png';
import './style.css';
import EditModalComp from '../Table/EditModal';
import EditProfileForm from './EditUserDetails';
import EditPasswordForm from './EditPassword';
import {
    Row, Col,
    Card, CardBody,
    CardTitle,
    Button
} from 'reactstrap';
import UserBox from '../../Layout/AppHeader/Components/UserBox';
import {ProfileImage} from '../ManageUser/UserColData';

const t = TranslationService.t;

interface Props extends Translate{
    closeModal?: any,
    loading?: any,
   formName?: any,
   passwordUpdated?: any,
   getUserList: () => {},
   userUpdated?: any,
   UserList?: any,
   logout: () => {};
  }

interface State{
   formName: string,
   editFlag: boolean,
   passFlag: boolean,
   setImages?: any,
   id?: any,
   errorMessage: string
  }

class UserProfileForm extends Component<Props, State> {

    constructor(props){
      super(props)
      this.state={
        formName: 'Edit User',
       editFlag: false,
       passFlag: false,
       setImages: {data_url: ''},
       id: JSON.parse(localStorage.getItem('user')).id,
       errorMessage : null
      }
    }

    static getDerivedStateFromProps(newState, prevState) {
      return {
        loading: newState.loading,
        success: newState.success,
        errorMessage: newState.errorMessage,
      };
    }
    componentDidMount(){
       this.props.getUserList()
      let user = JSON.parse(localStorage.getItem('user'))
      if(user.profileImage === null) {
          this.setState({setImages : {'data_url' : ProfileImage}})
      }
      else{
          this.setState({setImages : {'data_url' : user.profileImage}})
      }
    }

    componentDidUpdate(prevProps, prevState){
      if (this.props.passwordUpdated && prevProps.passwordUpdated !== this.props.passwordUpdated) {
        toast['success'](i18next.t('updatePasswordSuccess'), { position: 'top-right' });
        setTimeout(() => {this.props.logout()},3000)
      }
      if (this.props.userUpdated && prevProps.userUpdated !== this.props.userUpdated) {
        toast['success'](i18next.t('updateProfileSuccess'), { position: 'top-right' });
        this.props.closeModal()
      }
      if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
        toast['error'](t(this.state.errorMessage), { position: 'top-right' });
      }
    }

    // call on edit profile button click and open edit profile modal.
    handleEdit = () =>{
        this.setState({editFlag: true})
    }

    //call on change profile button click and open reset password modal.
    handlePassword = () =>{
        this.setState({passFlag: true, formName: 'Edit Password'})
    }

    //close edit profile and reset password modal.
    closeModal = () => {
        this.setState({editFlag: false , passFlag: false})
    }
    
    render() {
        let user = JSON.parse(localStorage.getItem('user'));
        let formTemplate, modalTitle = null;
        if(this.state.editFlag === true) {
            formTemplate = <EditProfileForm {...this.props}  closeModal={this.closeModal}/>
            modalTitle = i18next.t('editProfileModalTitle');
          }
          if(this.state.passFlag === true) {
            formTemplate = <EditPasswordForm {...this.props}  closeModal={this.closeModal}/>
            modalTitle = i18next.t('editPwdModalTitle');
          }
        return (
            <Translation>{t => (<Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                         { (this.state.editFlag || this.state.passFlag ) &&
                         <EditModalComp  closeModal={this.closeModal} formName ={this.props.formName} modalBody={formTemplate} modalTitle={modalTitle} />
                          }
                      <form>
                       <div className="userProfile">
                           <img  className="rounded-circle" src={this.state.setImages.data_url} alt="" width={100} height={100} />
                           <h5 style={{marginTop: '20px'}}><b>{`${user && user.firstName}`+' '+`${user && user.lastName}`}</b></h5>
                           <h5>{`${user && user.email}`}</h5>
                           <div className="editBtn">
                              <Button   className="btn-pill btn-shadow btn-shine" color="focus" style={{marginRight: '10px'}} onClick={this.handlePassword}>{i18next.t('passChange')}</Button>
                              <Button  className="btn-pill btn-shadow btn-shine" color="focus" style={{width: '125px'}} onClick={this.handleEdit}>{i18next.t('profileChange')}</Button>
                           </div>
                       </div>
                      </form>
                 </ReactCSSTransitionGroup>
              </Fragment>)}
             </Translation>

        )
    }
}

const mapStateToProps = (appState: AppState) => ({
  passwordUpdated: appState.user.passwordUpdated,
  loading: appState.loading.loading,
  userUpdated: appState.admin.userUpdated,
  errorMessage: appState.admin.UserListRequest.$errorMessage,
 });

const mapDispatchToProps = dispatch => ({
  getUserList: () => Actions.user.getUserList(dispatch),
 });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserProfileForm));
