import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PageTitle from '../../Layout/AppMain/PageTitle';
import { UserColData } from './UserColData';
import { AppState } from '../../internal/modules/Reducers';
import Actions from './../../internal/modules/Actions';
import AdminTableComp from './../Table/AdminTable';
import UserList from '../../model/UserList';
import {toast} from 'react-toastify';
import TranslationService from '../../utils/TranslationService';
import i18next from 'i18next';

interface Props extends Translate{
  userList ?: any,
  getUserList: () => {},
}

interface State{
  userList: UserList[],
  editModalFlag: boolean,
  confirmModalFlag: boolean,
  formtype: string,
  loading: boolean,
  errorMessage: string
}

class ShowAllUsers extends Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        userList: [],
        editModalFlag: false,
        confirmModalFlag: false,
        formtype:'add',
        loading: false,
        errorMessage: null
      }
  }

  componentDidMount(){
    this.props.getUserList();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.userList !== nextProps.userList) {
      return {
      userList : nextProps.userList
      };
    }
    return {
      loading: nextProps.loading,
      success: nextProps.success,
      errorMessage: nextProps.errorMessage
    };
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
      const t = TranslationService.t;
      toast['error'](t(this.state.errorMessage), { position: 'top-right' });
    }
  }

  //close edit modal.
  closeModal = () =>{
    this.setState({editModalFlag: false});
  }

  //close delete modal.
  closeConfirmModal = () =>{
    this.setState({confirmModalFlag: false});
  }

  //open edit and delete modal.
  showModal = (type) =>{
    if (type === 'delete') {
      this.setState({ confirmModalFlag: true, formtype:type});
    }
    else{
      this.setState({ editModalFlag: true, formtype:type});
    }
  }
  render() {
    return (
      <Translation>{t => (
        <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}
                >
                <PageTitle
                {...this.props}
                heading={t('sidebar.manageUser')}
                subheading={t('sidebar.userSubHeading')}
                buttonText={t('mngUser.addUserlabel')}
                buttonCallback={() => this.showModal('add')}
                />
                <AdminTableComp
                editTooltip={i18next.t('mngUser.editTooltip')}
                deleteTooltip={i18next.t('mngUser.deleteTooltip')}
                formtype={this.state.formtype}
                closeModalFlag={this.closeModal}
                closeConfirmModal={this.closeConfirmModal}
                editModalFlag={this.state.editModalFlag}
                confirmModalFlag={this.state.confirmModalFlag}
                onClickDelete={() => this.showModal('delete')}
                onClickEdit={() => this.showModal('edit')}
                colData={UserColData()}
                formName="user"
                tableData={this.props.userList}
                modalID="userModal"/>
                </ReactCSSTransitionGroup>
          </Fragment>
      )}</Translation>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({
  userList : appState.admin.UserList,
  loading: appState.loading.loading,
  success: appState.admin.UserListRequest.$success,
  errorMessage: appState.admin.UserListRequest.$errorMessage
});

const mapDispatchToProps = dispatch => ({
  getUserList: (filter) => Actions.admin.getUserList(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllUsers);
