import React, {Fragment, Component} from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { AppState } from './../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import Actions from './../../internal/modules/Actions';
import {toast} from 'react-toastify';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import TranslationService from '../../utils/TranslationService';
import { connect } from 'react-redux';
import TimelineMatrixFilter from './TimelineMatrixFilter';
import TimelineMatrixTable from './../Table/TimelineMatrixTable';

const t = TranslationService.t;

interface Props extends Translate{
  timeMatrixRegFetched: boolean,
  RegulationTimeMatrixList?: any
 }

interface State{
  flag ?:any;
}

class TimelineMatrixComp extends Component<Props, State> {
  constructor(props: Props){
    super(props);
    this.state = {
      flag : false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.timeMatrixRegFetched && prevProps.timeMatrixRegFetched !== this.props.timeMatrixRegFetched) {
      if (this.props.RegulationTimeMatrixList.length < 1) {
        toast['info'](i18next.t('table.infoMsg'), { position: 'top-right' });
      }
    }
  }

  setFlag = (data) =>{
   this.setState({flag: data})
  }
  render() {
    const{t} = this.props
    return (
        <Translation>{t => (
          <Fragment>
            {/* Timeline matrix filter toggle component */}
            <TimelineMatrixFilter changeFilterFlag = {this.setFlag}/>

            {/* Timeline matrix table component */}
            <TimelineMatrixTable filterFlag= {this.state.flag}/>
          </Fragment>
        )}</Translation>
      )
  }
}

const mapStateToProps = (appState: AppState) => ({
  timeMatrixRegFetched: appState.user.timeMatrixRegFetched,
  RegulationTimeMatrixList: appState.user.RegulationTimeMatrixList
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimelineMatrixComp));
