import React, {Fragment, Component} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import i18n from '../../i18n/I18n';
import {Button} from 'reactstrap';
import i18next from 'i18next';
import {toast} from 'react-toastify';
import './style.css';
import { AvForm,  AvGroup, AvInput} from 'availity-reactstrap-validation';

const t = TranslationService.t;

interface Props extends Translate{
    match ?: any;
    closeModal ?: any;
    forgotPassword : (any) =>{};
    ForgotPassword: boolean;
    loading?: any;
  }
  
interface State{
   email?: any,
   errorMessage: string,
   errors : string
  }

class ForgotPassForm extends Component<Props, State> {

   constructor(props){
      super(props)
      this.state={
         email : '',
         errorMessage: null,
         errors : ''
      }
    } 
  static getDerivedStateFromProps(newState, prevState) {
      return {
        loading: newState.loading,
        success: newState.success,
        errorMessage: newState.errorMessage,
      };
  }
  componentDidUpdate(prevProps, prevState){
    if (this.props.ForgotPassword && prevProps.ForgotPassword !== this.props.ForgotPassword) {
      toast['success'](i18next.t('forgotPasswordSucess'), { position: 'top-right' });
      this.props.closeModal()
    }
    if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
      toast['error'](t(this.state.errorMessage), { position: 'bottom-center' });
      this.props.closeModal()
    }
  }

  //call on send button click and call forgot password api, also check mandatory fields.
  handleSubmit = () =>{
    let errFlag = false;
    let errors = '';
    let {email} = this.state;
    let emailFormat = /\S+@\S+\.\S+/;
    if(!email) {
      errFlag= true;
      errors= i18next.t('ErrorMessage');
    }
    else if(!emailFormat.test(email)){
      errFlag = true;
      errors = i18next.t('emailError');
    }
    this.setState({errors: errors});
    if(!errFlag){
    const obj = {email}
    this.props.forgotPassword(obj);
    }
 }
 render() {
    return (
      <Translation>{t => (<Fragment>
           <ReactCSSTransitionGroup
               component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                   <div className="form-group forgotPass">
                     <h5><b>{t('forgotLabel')}</b></h5>
                       <p style={{color: 'gray'}}>{t('forgotText')}</p>
                        <div className="col-sm-9" style={{marginLeft: '4rem'}}>
                            <AvForm >
                              <AvGroup>
                                <AvInput
                                  type="email"
                                  name="email"
                                  id="email"
                                  placeholder={t('login.inputEmail')}
                                  value={this.state.email}
                                  required
                                  onChangeCapture={(e) => this.setState({ email: e.target.value })}
                                />
                                 <div className="errEmail">{this.state.errors}</div>
                              </AvGroup>
                           </AvForm>
                        </div>
                   </div>
                   <hr style={{marginBottom: '0.3rem'}} />
                    <div className="sendBtn">
                      <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
                      <Button color="primary" onClick={this.handleSubmit}  disabled={this.props.loading}>{this.props.loading ? i18next.t('emailSending') : t('sendBtn') }</Button>
                    </div>
             </ReactCSSTransitionGroup>
             </Fragment>)}
      </Translation>
    )
 }
}
const mapStateToProps = (appState: AppState) => ({
  ForgotPassword: appState.user.ForgotPassword,
  errorMessage: appState.user.ForgotPasswordRequest.$errorMessage,
  loading: appState.loading.loading,
 });
    
const mapDispatchToProps = dispatch => ({
  forgotPassword : (email) => Actions.user.forgotPassword(dispatch,email)
 });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassForm));
    
  