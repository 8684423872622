import i18next from 'i18next';

export const AdminDashboardNav = () => {
  return [
    {
        icon: 'pe-7s-rocket',
        label: i18next.t('sidebar.dashboard'),
        to: '#/app/dashboard',
    }
  ]
}

//Admin sidebar tabs navigation routes
export const AdminManagementNav = () => {
  return [
      {
          icon: 'pe-7s-users',
          label: i18next.t('sidebar.manageUser'),
          to: '#/app/users',
      },
      {
          icon: 'pe-7s-display2',
          label: i18next.t('sidebar.manageReg'),
          to: '#/app/manage-regulations',
      },
      {
          icon: 'pe-7s-global',
          label: i18next.t('sidebar.manageMarkets'),
          to: '#/app/manage-markets',
      },
      {
          icon: 'pe-7s-news-paper',
          label: i18next.t('sidebar.mngMarketProfileValue'),
          to: '#/app/manage-value',
      },
      {
          icon: 'pe-7s-car',
          label: i18next.t('sidebar.manageVehicles'),
          to: '#/app/manage-vehicles',
      },
      {
          icon: 'pe-7s-display2',
          label: i18next.t('sidebar.manageTopics'),
          to: '#/app/manage-topics',
      },
      {
        icon: 'pe-7s-car',
        label: i18next.t('sidebar.mngDriveType'),
        to: '#/app/manage-drive-type',
      },
      {
          icon: 'pe-7s-note2',
          label: i18next.t('sidebar.manageGroups'),
          to: '#/app/manage-groups',
      },
      {
          icon: 'pe-7s-drop',
          label: i18next.t('sidebar.manageFuelType'),
          to: '#/app/manage-fuel-type',
      },
      {
          icon: 'pe-7s-menu',
          label: i18next.t('sidebar.manageScopeSubscope'),
          to: '#/app/manage-scopes',
      },
      {
        icon: 'pe-7s-help1',
        label:  i18next.t('sidebar.manageFaqs'),
        to: '#/app/manage-faqs'
      },
      {
        icon: 'pe-7s-display2',
        label:  i18next.t('sidebar.mngRevHistory'),
        to: '#/app/manage-revision-history'
      }
  ]
}

//Active sidebar tab css
export const ActiveElemCss =
{
    'background-color': '#fff',
    'color': '#343a40',
    'font-weight': 400
  };

//In-active sidebar tab css
export const InactiveElemCss = {
  'background-color': '#e0f3ff',
  'color': '#545cd8',
  'font-weight': 500
  };
