import React, {Fragment, Component} from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { AppState } from './../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import Actions from './../../internal/modules/Actions';
import {toast} from 'react-toastify';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import TranslationService from '../../utils/TranslationService';
import { connect } from 'react-redux';
import RevisionHistoryTable from '../Table/RevisionHistoryTable';
import './TimelineMatrix.css';

const t = TranslationService.t;

interface Props extends Translate{
 rowData ?: any;
 startDate ?: any;
 endDate ?: any;
 market ?: any;
 }

interface State{
    selectedSeries : any;
}

class TimelineRevisionTableModal extends Component<Props, State> {
  constructor(props: Props){
    super(props);
    this.state = {
        selectedSeries : {},
    };
  }
componentDidMount(){
    this.setState({selectedSeries: this.props.rowData[0]})
}
  componentDidUpdate(prevProps, prevState) {
    if(this.props.rowData && this.props.rowData !== prevProps.rowData) {
        this.setState({selectedSeries: this.props.rowData[0]})
    }
  }

  seriesSelected = (series) => {
    this.setState({selectedSeries: series})
}
  render() {
    const{t} = this.props
    let {rowData} = this.props;
    return (
        <Translation>{t => (
          <Fragment>
            {
                this.state.selectedSeries && <>
                <div className='col-sm-12 mb-2' style={{padding: 0}}>
                    <div className="row col-sm-12 form-inline revTableModal">
                        <div style={{width: '20%', paddingRight: '10px'}}>
                            <div className={`w-100 seriesSidebarView`}>
                                {
                                    this.state.selectedSeries && Object.keys(this.state.selectedSeries && this.state.selectedSeries)?.length !== 0 && rowData && rowData.map((item) => {
                                        if(this.state.selectedSeries.series === item.series) {
                                            return <p className="seriesName selectedSeries" style={{margin: 0}}>Series {item.series}</p>
                                            }
                                            else {
                                            return <p className="seriesName" style={{margin: 0}} onClick={() => this.seriesSelected(item)}>Series {item.series}</p>
                                            }
                                    })
                                }
                            </div>
                        </div>
                    <div  style={{minHeight:'15rem',width: '80%'}}>
                        {
                        this.state.selectedSeries && Object.keys(this.state.selectedSeries).length !== 0  && (
                            <div className="revTableContainer">
                                <div className="seriesTitleBox">
                                <h6 className='labelCss1'><b>Series {this.state.selectedSeries.series}</b></h6>
                                </div>
                                <div className="revTableBox">
                                    <RevisionHistoryTable market={this.props.market} data={this.state.selectedSeries.data} formType={"viewType"}  cssType="timelineMatrix"/>
                                </div>
                            <div>
                            </div>
                        </div>
                        )
                        }
                    </div>
                    </div>
                </div>
                </>
            }
          </Fragment>
        )}</Translation>
      )
  }
}

const mapStateToProps = (appState: AppState) => ({
  timeMatrixRegFetched: appState.user.timeMatrixRegFetched,
  RegulationTimeMatrixList: appState.user.RegulationTimeMatrixList
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimelineRevisionTableModal));
