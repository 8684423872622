import {ModuleEvents} from './Actions';
import { Action } from '../../model/Action';
export interface InitialLoadingState {
    loading: boolean
}

const initState: InitialLoadingState = {
    loading: false
};

export const Reducer = (state = initState, action: Action) => {
    const {type} = action;
    switch (type) {
        case ModuleEvents.LOADING:{
            return {
                ...state,
                loading: true
            }
        }
        case ModuleEvents.FINISHLOADING:{
            return {
                ...state,
                loading: false
            }
        }
        default:
            return {...state};

    }
};
