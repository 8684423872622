import React, {Component,Fragment} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import filterFactory, { textFilter, numberFilter  } from 'react-bootstrap-table2-filter';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18next from 'i18next';
import DeleteIcon from 'react-ionicons/lib/MdTrash';
import EditIcon from 'react-ionicons/lib/MdCreate';
import EditModalComp from './EditModal';
import ViewIcon from 'react-ionicons/lib/IosEye';
import DeleteModalComp from './DeleteModal';
import UserForm from './../ManageUser/UserForm';
import VehicleForm from './../ManageVehicles/VehicleForm';
import GRMForm from './../ManageRegulations/GRMForm';
import TopicForm from './../ManageTopics/TopicForm';
import GroupForm from './../ManageGroups/GroupForm';
import ScopeForm from './../ManageScopes/ScopeForm';
import FuelTypeForm from './../ManageFuelTypes/FuelTypeForm';
import {toast} from 'react-toastify';
import Checkicon from 'react-ionicons/lib/IosCheckmarkCircle';
import NotCheckicon from 'react-ionicons/lib/IosCloseCircle';
import $ from 'jquery';
import {
    Row, Col, Button
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';
import DriveTypeForm from './../ManageDriveType/DriveTypeForm';
import CustomSearchFilter from './CustomSearchFilter';
import FaqForm from '../ManageFaqs/FaqForm';
import RevisionHistoryForm from '../ManageRevisionHistory/RevisionHistoryForm';
var qs = require('qs');

let eventFlag = false;

function getCustomFilter(handleFilter, customFilterParameters) {
  return (
    <CustomSearchFilter handleFilter={ handleFilter } dataField={customFilterParameters.dataField} placeholder={customFilterParameters.placeholder}/>
  );
}

  interface Props extends Translate{
    colData?: any;
    tableData?: any;
    formName?: string;
    onClickEdit?: any;
    onCloseConfirm?: any;
    onClickDelete?: any;
    onClickView?: any;
    editModalFlag?: boolean;
    confirmModalFlag?: boolean;
    closeModalFlag?: any;
    closeConfirmModal?: any;
    formtype?: any;
    deleteUser: (any) => {};
    deleteVehicle: (any) => {};
    deleteTopic: (any) => {};
    userAdded: boolean;
    userUpdated: boolean;
    userDeleted: boolean;
    vehicleAdded: boolean;
    vehicleUpdated: boolean;
    vehicleDeleted: boolean;
    grmListFetched: boolean;
    modalID: string;
    getGRMList : (any) => {};
    GRMList?: any;
    deleteGRM: (any) => {};
    topicAdded: boolean;
    topicUpdated: boolean;
    topicDeleted: boolean;
    grmAdded: boolean;
    grmDeleted: boolean;
    viewTooltip: string;
    editTooltip: string;
    deleteTooltip: string;
    getTopicList: () => {};
    resendVerificationLink: (any) => {};
    resendMail : boolean;
    loading: boolean;
    groupAdded: boolean;
    groupUpdated: boolean;
    groupDeleted: boolean;
    deleteGroup: (id, obj) => {};
    deleteDriveType: (any) =>{};
    driveTypeDeleted: boolean;
    driveTypeAdded: boolean;
    driveTypeUpdated: boolean;
    fuelTypeAdded: boolean;
    fuelTypeUpdated: boolean;
    fuelTypeDeleted: boolean;
    deleteFuelType: (any) => {};
    deleteScope: (id, obj) => {};
    scopeAdded: boolean;
    scopeUpdated: boolean;
    scopeDeleted: boolean;
    faqListUpdated: boolean,
    getFaqs: () => {};
    deleteFaq:(id) => {};
    faqDeleted: boolean;
    faqAdded: boolean;
    getRevisionHistoryData: (regulationNumber) => {};
  }

  interface State{
    tableDataArr?: any;
    rowData?: any;
    grmListFetched: boolean;
    showConfirmModal: boolean;
    resizeListener: boolean;
  }

  class AdminTableComp extends Component<Props, State> {
    constructor(props){
      super(props)
      this.state={
        tableDataArr: [],
        rowData: {},
        grmListFetched: false,
        showConfirmModal: false,
        resizeListener:false
      };
    }

    componentDidMount(){
      //Prevent page refresh on size per page selection
      $(document).ready(function(){
        $(".react-bs-table-sizePerPage-dropdown").find("a").click(function(event){
          event.preventDefault();
        });
      });
    }


    static getDerivedStateFromProps(props, state) {
      if (props.tableData !== state.tableData) {
        let tableData = [];
        let index = 1;
        props.tableData && [...props.tableData].map(elem =>{
          //Not showing "All" option (id == 0) to admin for vehicle, fuel and drive type in table.
          if ((props.formName === 'vehicle' && elem.id !== 0) ||
              (props.formName === 'fuelType' && elem.id !== 0) ||
              (props.formName === 'driveType' && elem.id !== 0)) {
            tableData.push({...elem, rowId: index});
            index += 1;
          }
          //Insert all the table data for all sidebar tabs
          if (props.formName !== 'vehicle' && props.formName !== 'fuelType' && props.formName !== 'driveType') {
            tableData.push({...elem, rowId: index});
            index += 1;
          }
        });
        return {
          tableDataArr: tableData
        };
      }
      return null;
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.grmListFetched !== prevProps.grmListFetched) {
        if (this.props.grmListFetched === true) {
          this.setState({grmListFetched: true});
        }
      }
      //Showing success toast popup after api call is success
      if (this.props.userAdded && prevProps.userAdded !== this.props.userAdded) {
        toast['success'](i18next.t('mngUser.addUserSuccess'), { position: 'top-right' });
      }
      else if(this.props.userUpdated && prevProps.userUpdated !== this.props.userUpdated){
        toast['success'](i18next.t('mngUser.editUserSuccess'), { position: 'top-right' });
      }
      else if(this.props.userDeleted && prevProps.userDeleted !== this.props.userDeleted){
        toast['success'](i18next.t('mngUser.deleteUserSuccess'), { position: 'top-right' });
      }
      else if(this.props.vehicleAdded && prevProps.vehicleAdded !== this.props.vehicleAdded) {
        toast['success'](i18next.t('mngVehicles.addVehicleSuccess'), { position: 'top-right' });
      }
      else if(this.props.vehicleUpdated && prevProps.vehicleUpdated !== this.props.vehicleUpdated){
        toast['success'](i18next.t('mngVehicles.editVehicleSuccess'), { position: 'top-right' });
      }
      else if(this.props.vehicleDeleted && prevProps.vehicleDeleted !== this.props.vehicleDeleted){
        toast['success'](i18next.t('mngVehicles.deleteVehicleSuccess'), { position: 'top-right' });
      }
      else if(this.props.topicAdded && prevProps.topicAdded !== this.props.topicAdded) {
        toast['success'](i18next.t('mngTopic.addTopicSuccess'), { position: 'top-right' });
      }
      else if(this.props.topicUpdated && prevProps.topicUpdated !== this.props.topicUpdated){
        toast['success'](i18next.t('mngTopic.editTopicSuccess'), { position: 'top-right' });
        this.props.getTopicList();
      }
      else if(this.props.topicDeleted && prevProps.topicDeleted !== this.props.topicDeleted){
        toast['success'](i18next.t('mngTopic.deleteTopicSuccess'), { position: 'top-right' });
      }
      else if(this.props.grmDeleted && prevProps.grmDeleted !== this.props.grmDeleted){
        toast['success'](i18next.t('mngGRM.deleteGrmSuccess'), { position: 'top-right' });
      }
      else if(this.props.resendMail && prevProps.resendMail !== this.props.resendMail){
        toast['success'](i18next.t('mngUser.resendMailSuccess'), { position: 'top-right' });
      }
      else if(this.props.driveTypeAdded && prevProps.driveTypeAdded !== this.props.driveTypeAdded) {
        toast['success'](i18next.t('mngDriveType.addDriveTypeSuccess'), { position: 'top-right' });
      }
      else if(this.props.driveTypeUpdated && prevProps.driveTypeUpdated !== this.props.driveTypeUpdated){
        toast['success'](i18next.t('mngDriveType.editDriveTypeSuccess'), { position: 'top-right' });
      }
      else if(this.props.driveTypeDeleted && prevProps.driveTypeDeleted !== this.props.driveTypeDeleted){
        toast['success'](i18next.t('mngDriveType.deleteDriveTypeSuccess'), { position: 'top-right' });
      }
      else if(this.props.groupAdded && prevProps.groupAdded !== this.props.groupAdded) {
        toast['success'](i18next.t('mngGroup.addGroupSuccess'), { position: 'top-right' });
      }
      else if(this.props.groupUpdated && prevProps.groupUpdated !== this.props.groupUpdated){
        toast['success'](i18next.t('mngGroup.editGroupSuccess'), { position: 'top-right' });
      }
      else if(this.props.groupDeleted && prevProps.groupDeleted !== this.props.groupDeleted){
        toast['success'](i18next.t('mngGroup.deleteGroupSuccess'), { position: 'top-right' });
      }
      else if(this.props.fuelTypeAdded && prevProps.fuelTypeAdded !== this.props.fuelTypeAdded) {
        toast['success'](i18next.t('mngFuelType.addFuelTypeSuccess'), { position: 'top-right' });
      }
      else if(this.props.fuelTypeUpdated && prevProps.fuelTypeUpdated !== this.props.fuelTypeUpdated){
        toast['success'](i18next.t('mngFuelType.editFuelTypeSuccess'), { position: 'top-right' });
      }
      else if(this.props.fuelTypeDeleted && prevProps.fuelTypeDeleted !== this.props.fuelTypeDeleted){
        toast['success'](i18next.t('mngFuelType.deleteFuelTypeSuccess'), { position: 'top-right' });
      }
      else if(this.props.scopeAdded && prevProps.scopeAdded !== this.props.scopeAdded) {
        toast['success'](i18next.t('mngScope.addScopeSuccess'), { position: 'top-right' });
      }
      else if(this.props.scopeUpdated && prevProps.scopeUpdated !== this.props.scopeUpdated){
        toast['success'](i18next.t('mngScope.editScopeSuccess'), { position: 'top-right' });
      }
      else if(this.props.scopeDeleted && prevProps.scopeDeleted !== this.props.scopeDeleted){
        toast['success'](i18next.t('mngScope.deleteScopeSuccess'), { position: 'top-right' });
      }
      else if(this.props.faqAdded && prevProps.faqAdded !== this.props.faqAdded) {
        toast['success'](i18next.t('mngFaqs.addFaqSuccess'), { position: 'top-right' });
        this.props.getFaqs();        
      }
      else if(this.props.faqListUpdated && prevProps.faqListUpdated !== this.props.faqListUpdated){
        toast['success'](i18next.t('mngFaqs.editFaqSuccess'), { position: 'top-right' });
        this.props.getFaqs();        
      }
      else if(this.props.faqDeleted && prevProps.faqDeleted !== this.props.faqDeleted) {
        toast['success'](i18next.t('mngFaqs.deleteFaqSuccess'), { position: 'top-right' });
        this.props.getFaqs();        
      }
    }

    //call on click of view icon
    viewHandler = (row) =>{
      this.setState({rowData: row});
      if (this.props.formName === 'GRM') {
        this.setState({grmListFetched: false});
        this.props.getGRMList(row.id);
      }
      this.props.onClickView();
    }

    //call on click of edit icon.
    editHandler = (row) =>{
      if (this.props.formName === 'GRM') {
        this.setState({grmListFetched: false});
        this.props.getGRMList(row.id);
      }
      if (this.props.formName === 'revisionHistory') {
        this.props.getRevisionHistoryData(row.regulationNumber)
      }
      eventFlag = true;
      this.setState({rowData: JSON.parse(JSON.stringify(row))});
      this.props.onClickEdit();
    }

    //call on click of delete icon in all admin side tables.
    deleteHandler = (row) =>{
      this.setState({rowData: row});
      this.props.onClickDelete();
    }
    //call on resend icon in user table if user is not verify.
    resendMailHandler =(row) =>{
        let obj = {
          email: row.email
        }
        this.props.resendVerificationLink(obj)
    }
    // format the action column and add customize style.
    actionColFormatter = (cell, row) => {
      return (
        <Row>
        {this.props.formName === 'GRM' &&
        <Row style={{marginLeft:'10px'}}>
        <Col md="3"style={{paddingLeft:'1px'}}>
        <a data-tip={i18next.t(this.props.viewTooltip)} data-for="viewHandler">
        <ViewIcon style={{'cursor':'pointer'}} onClick={() => this.viewHandler(row)} />
        </a>
        <ReactTooltip id="viewHandler" place="right" type="info" effect="float"/>
        </Col></Row>}
        <Col md="3"style={{paddingLeft: this.props.formName !== 'revisionHistory' ? '1px' : '50px'}}>
        <a data-tip={i18next.t(this.props.editTooltip)} data-for="editHandler">
        <EditIcon style={{'cursor':'pointer'}} onClick={() => this.editHandler(row)} />
        </a>
        <ReactTooltip id="editHandler" place="right" type="info" effect="float"/>
        </Col>
        {this.props.formName !== 'revisionHistory' &&
        <Col md="3"style={{paddingLeft:'1px'}}>
        <a data-tip={i18next.t(this.props.deleteTooltip)} data-for="deleteHandler">
        <DeleteIcon style={{'cursor':'pointer'}} onClick={() => this.deleteHandler(row)} />
        </a>
        <ReactTooltip id="deleteHandler" place="right" type="info" effect="float"/>
        </Col>}
        </Row>
      );
    };

    //format email verification column in Manage user tab
    emailVerifyStatusColFormatter = (cell, row) => {
      let verifyColValue;
      let verifyFlag= false;
      if(row.isVerified === true){
        verifyColValue = <Row><Checkicon style={{'margin-left': "10px"}} fontSize="24px" color="green"/><Col>{i18next.t('verified')}</Col></Row>
      }
      else{
       verifyColValue = <Row><NotCheckicon style={{'margin-left': "10px"}} fontSize="24px" color="red"/><Col>{i18next.t('not-verified')}</Col>
         <a data-tip={i18next.t('resendMailTooltip')} data-for="resendMailHandler">
           <img src={require("./resend.png")} id="resendImg" onClick={() => this.resendMailHandler(row)} />
         </a>
         <ReactTooltip id="resendMailHandler" place="left" type="info" effect="float"/>
      </Row>
      }
     return verifyColValue
    };

    //call on delete modal yes button click and call delete api.
    eventHandlerDelete = () =>{
      if (this.props.formName === 'user') {
        this.props.deleteUser(this.state.rowData.id);
        this.setState({rowData: {}});
        this.closeDeleteModal();
      }
      else if (this.props.formName === 'vehicle') {
        this.props.deleteVehicle(this.state.rowData.id);
        this.setState({rowData: {}});
        this.closeDeleteModal();
      }
      else if (this.props.formName === 'topic') {
        this.props.deleteTopic(this.state.rowData.id);
        this.setState({rowData: {}});
        this.closeDeleteModal();
      }
      else if (this.props.formName === 'GRM') {
        if (this.props.formtype === 'confirm') {
          this.setState({rowData: {}});
          this.closeDeleteModal();
          this.props.closeModalFlag();
        }
        else{
          this.props.deleteGRM(this.state.rowData.id);
          this.setState({rowData: {}});
          this.closeDeleteModal();
        }
      }
      else if (this.props.formName === 'driveType') {
        this.props.deleteDriveType(this.state.rowData.id);
        this.setState({rowData: {}});
        this.closeDeleteModal();
      }
      else if (this.props.formName === 'group') {
        let obj = {
          group: this.state.rowData['data']['en']['type'] === 'Group' ? true : false
        }
        this.props.deleteGroup(this.state.rowData.id, obj);
        this.setState({rowData: {}});
        this.closeDeleteModal();
      }
      else if (this.props.formName === 'fuelType') {
        this.props.deleteFuelType(this.state.rowData.id);
        this.setState({rowData: {}});
        this.closeDeleteModal();
      }
      else if (this.props.formName === 'scope') {
        let obj = {
          scope: this.state.rowData['data']['en']['type'] === 'Scope' ? true : false
        }
        this.props.deleteScope(this.state.rowData.id, obj);
        this.setState({rowData: {}});
        this.closeDeleteModal();
      }
      else if(this.props.formName === 'faqTable') {
        this.props.deleteFaq(this.state.rowData.id);
        this.setState({rowData: {}});
        this.closeDeleteModal();
      }
    }

    //close edit modal.
    closeModal = () =>{
      if (this.props.formName === 'GRM' && this.props.formtype !== 'view' && this.state.showConfirmModal) {
        this.props.onCloseConfirm();
      }
      else{
        this.setState({rowData: {}});
        this.props.closeModalFlag();
      }
    }

    //close delete modal.
    closeDeleteModal = () =>{
      this.props.closeConfirmModal();
    }

    closeConfirmModal = () =>{
      this.setState({rowData: {}});
      this.props.closeConfirmModal();
    }

    //call on select market from dropdown in edit grm form.
    marketSelected = (market) =>{
      if (market.length > 0) {
        this.setState({showConfirmModal: true});
      }
      else{
        this.setState({showConfirmModal: false});
      }
    }

    // display total number of rows in table.
    renderShowsTotal(start, to, total){
      return (
        <p className="totalRows">
          {i18next.t('table.totalRecords')} { total }
        </p>
      );
    }

    render() {
    //Table loader
    const tableSpinner = <LoaderAnim active type="ball-beat"/>;

   let { tableDataArr } = this.state;
   //Assigning form template to modal body by checking formName props
   let formTemplate, modalTitle = null;
   if (this.props.formName === 'user') {
     formTemplate = <UserForm {...this.props} closeModal={this.closeModal} rowData={this.props.formtype === 'edit' ? this.state.rowData : {}} formtype={this.props.formtype}/>
     modalTitle = i18next.t('userFormTitle');
   }
   else if(this.props.formName === 'vehicle') {
     formTemplate = <VehicleForm {...this.props} closeModal={this.closeModal} rowData={this.props.formtype === 'edit' ? this.state.rowData : {}} formtype={this.props.formtype}/>
     modalTitle = i18next.t('vehicleFormTitle');
   }
   else if(this.props.formName === 'GRM' && this.state.grmListFetched) {
     formTemplate = <GRMForm {...this.props} marketSelected={this.marketSelected.bind(this)} marketData={JSON.parse(JSON.stringify(this.props.GRMList))} closeModal={this.props.closeModalFlag} rowData={this.state.rowData} formtype={this.props.formtype}/>
     modalTitle = i18next.t('grmFormTitle');
   }
   else if(this.props.formName === 'topic') {
     formTemplate = <TopicForm {...this.props} closeModal={this.closeModal} rowData={eventFlag ? this.state.rowData : {}} formtype={this.props.formtype}/>
     modalTitle = i18next.t('topicFormTitle');
   }
   else if(this.props.formName === 'driveType') {
    formTemplate = <DriveTypeForm {...this.props} closeModal={this.closeModal} rowData={eventFlag ? this.state.rowData : {}} formtype={this.props.formtype}/>
    modalTitle = i18next.t('driveTypeFormTitle');
  }
   else if(this.props.formName === 'group') {
     formTemplate = <GroupForm {...this.props} closeModal={this.closeModal} rowData={eventFlag ? this.state.rowData : {}} formtype={this.props.formtype}/>
     modalTitle = i18next.t('mngGroup.groupFormTitle');
   }
   else if(this.props.formName === 'fuelType') {
     formTemplate = <FuelTypeForm {...this.props} closeModal={this.closeModal} rowData={this.props.formtype === 'edit' ? this.state.rowData : {}} formtype={this.props.formtype}/>
     modalTitle = i18next.t('mngFuelType.fuelTypeFormTitle');
   }
   else if(this.props.formName === 'scope') {
     formTemplate = <ScopeForm {...this.props} closeModal={this.closeModal} rowData={eventFlag ? this.state.rowData : {}} formtype={this.props.formtype}/>
     modalTitle = i18next.t('mngScope.scopeFormTitle');
   }
   else if(this.props.formName === 'faqTable') {
    formTemplate = <FaqForm {...this.props} closeModal={this.closeModal} rowData={eventFlag ? this.state.rowData : {}} formtype={this.props.formtype}/>
    modalTitle = i18next.t('mngFaqs.faqFormTitle');    
   }
   else if(this.props.formName === 'revisionHistory') {
    formTemplate = <RevisionHistoryForm {...this.props} closeModal={this.closeModal} rowData={eventFlag ? this.state.rowData : {}} formtype={this.props.formtype}/>
    modalTitle = "Manage revision history";    
   }

   const options = {
    noDataText: i18next.t('table.noDataMsg'),
    paginationShowsTotal:this.renderShowsTotal,
    paginationPosition:'both',
  };
    
   return(
     <div id="adminTable" className={`${this.props.formName}`}>
          <Translation>{t => (
            <Fragment>
              {/* EditModalComp is the common modal component we are using to show add/edit template */}
              { this.props.editModalFlag && this.props.formtype !== 'delete' && (this.props.formName === 'GRM' ? this.state.grmListFetched : true) &&
                <EditModalComp modalID={this.props.modalID} closeModal={this.closeModal} modalBody={formTemplate} modalTitle={modalTitle} />
              }
              {/* DeleteModalComp is the common modal we are using for delete confirmation template */}
              { this.props.confirmModalFlag && (this.props.formtype === 'delete' || this.props.formtype === 'confirm') &&
                <DeleteModalComp modalMsg={this.props.formtype === 'delete' ? i18next.t('deleteConfirm') : i18next.t('closeConfirmModal')} closeModal={this.closeDeleteModal} eventHandlerDelete={this.eventHandlerDelete}/>
              }
              <Loader message={tableSpinner} show={this.props.loading}>
                <BootstrapTable striped hover options={options} data={tableDataArr && tableDataArr.length > 0 ? tableDataArr : []} pagination>
                  {
                    this.props.colData && this.props.colData.map((item, index) =>{
                      if (item.label === 'Action') {
                        return(
                          <TableHeaderColumn hidden={item.hidden} isKey={item.isKey}  dataFormat={this.actionColFormatter}>{i18next.t(item.label)}</TableHeaderColumn>
                        )
                      }
                      if(item.label === 'Email Verification'){
                        return(
                          <TableHeaderColumn hidden={item.hidden} isKey={item.isKey}  dataFormat={this.emailVerifyStatusColFormatter}>{i18next.t(item.label)}</TableHeaderColumn>
                        )
                      }
                      if(item.label === i18next.t('id')){
                        return(
                          <TableHeaderColumn hidden={item.hidden} filterFormatted  isKey={item.isKey} dataField={item.dataField} dataFormat={item.dataFormat}>{item.label}</TableHeaderColumn>
                        )
                      }
                      else{
                        return(
                          <TableHeaderColumn
                            hidden={item.hidden}
                            filterFormatted
                            filter={{
                              type: (this.props.formName == 'GRM' || this.props.formName == 'topic') ? 'CustomFilter' : 'TextFilter',
                              placeholder: item.placeholder,
                              getElement: (this.props.formName == 'GRM' || this.props.formName == 'topic') ? getCustomFilter : null,
                              customFilterParameters: (this.props.formName == 'GRM' || this.props.formName == 'topic') ? { dataField: item.dataField,placeholder: item.placeholder } : null
                            }}
                            isKey={item.isKey}
                            dataField={item.dataField}
                            dataFormat={item.dataFormat}>{item.label}</TableHeaderColumn>
                        )
                      }
                    }
                  )}
                </BootstrapTable>
              </Loader>
            </Fragment>)}</Translation>
     </div>
        );
       }
    }

  const mapStateToProps = (appState: AppState) => ({
    userAdded: appState.admin.userAdded,
    userUpdated: appState.admin.userUpdated,
    userDeleted: appState.admin.userDeleted,
    vehicleAdded: appState.admin.vehicleAdded,
    vehicleUpdated: appState.admin.vehicleUpdated,
    vehicleDeleted: appState.admin.vehicleDeleted,
    grmListFetched: appState.admin.grmListFetched,
    GRMList: appState.admin.GRMList,
    topicAdded: appState.admin.topicAdded,
    topicUpdated: appState.admin.topicUpdated,
    topicDeleted: appState.admin.topicDeleted,
    grmAdded: appState.admin.grmAdded,
    grmDeleted: appState.admin.grmDeleted,
    resendMail : appState.admin.resendMail,
    loading: appState.loading.loading,
    driveTypeDeleted: appState.admin.driveTypeDeleted,
    driveTypeAdded: appState.admin.driveTypeAdded,
    driveTypeUpdated: appState.admin.driveTypeUpdated,
    groupAdded: appState.admin.groupAdded,
    groupUpdated: appState.admin.groupUpdated,
    groupDeleted: appState.admin.groupDeleted,
    fuelTypeAdded: appState.admin.fuelTypeAdded,
    fuelTypeUpdated: appState.admin.fuelTypeUpdated,
    fuelTypeDeleted: appState.admin.fuelTypeDeleted,
    scopeAdded: appState.admin.scopeAdded,
    scopeUpdated: appState.admin.scopeUpdated,
    scopeDeleted: appState.admin.scopeDeleted,
    faqDeleted: appState.admin.faqDeleted,
    faqListUpdated: appState.admin.faqListUpdated,
    faqAdded: appState.admin.faqAdded,
   });

  const mapDispatchToProps = dispatch => ({
    deleteUser: (user) => Actions.admin.deleteUser(dispatch, user),
    deleteVehicle: (vehicle) => Actions.admin.deleteVehicle(dispatch, vehicle),
    deleteTopic: (topic) => Actions.admin.deleteTopic(dispatch, topic),
    getGRMList: (topicID) => Actions.admin.getGRMList(dispatch, topicID),
    deleteGRM: (grmID) => Actions.admin.deleteGRM(dispatch, grmID),
    getTopicList: () => Actions.admin.getTopicList(dispatch),
    resendVerificationLink: (email) => Actions.admin.resendVerificationLink(dispatch, email),
    deleteDriveType: (driveType) => Actions.admin.deleteDriveType(dispatch, driveType),
    deleteGroup: (id, obj) => Actions.admin.deleteGroup(dispatch, id, obj),
    deleteFuelType: (fuelType) => Actions.admin.deleteFuelType(dispatch, fuelType),
    deleteScope: (id, obj) => Actions.admin.deleteScope(dispatch, id, obj),
    getFaqs: () => Actions.admin.getFaqs(dispatch),
    deleteFaq:(faqId) => Actions.admin.deleteFaq(dispatch, faqId),
    getRevisionHistoryData: (regulationNumber) => Actions.admin.getRevisionHistoryData(dispatch,regulationNumber)
  });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminTableComp));
