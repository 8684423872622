export function revHistory() {
    var revHist = [];
    for (let i = 0; i <= 50; i++) {
      revHist.push(`Rev ${i}`);
      revHist.push(`Amend ${i}`);
      revHist.push(`Corr ${i}`);
    }
    
    return revHist;
}
