import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import './GRM.css';
import {Typeahead} from 'react-bootstrap-typeahead';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button} from 'reactstrap';
import i18next from 'i18next';
import {typeOfDriveArr, languageArr} from './RegulationsColData';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Flag from 'react-flagkit';
import ActualReg from './ActualRegForm';
import FutureReg from './FutureRegForm';
import EquivalentReg from './EquivalentRegForm';
import ReferenceReg from './ReferenceRegForm';
import uneceFlag from './../../assets/utils/images/unece.png';
import $ from 'jquery';
import AlertIonicon from 'react-ionicons/lib/MdAlert';
import ReactTooltip from 'react-tooltip';
import EditModalComp from './../Table/EditModal';
import DocumentForm from './DocumentForm';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminDocumentTableComp from './../Table/AdminDocumentTable';
import DeleteModalComp from './../Table/DeleteModal';

interface Props extends Translate{
  rowData?: any;
  MarketList?: any;
  VehicleList?: any;
  formtype?: string;
  marketSelected: (any) => {};
  DriveTypeList?: any;
  FuelTypeList?: any;
  allMarketSelectedHandler: (any) => {};
  appendDocInToJsonHandler: (array, market) => any;
  updateDocID: (id) => any;
  docDeleteHandler: (row) => any;
  fileHandler: (file, id) => any;
  downloadFile: (id, name) => any;
  undoDeleteHandler: (row) => any;
  newDocuments?: any;
  marketArr?: any;
  removedMarket?: any;
}

interface State{
  vehicleList?: any;
  rowData?: any;
  marketSelected?: any;
  marketErrIcon?:any;
  driveTypeList?: any;
  fuelTypeList?: any;
  docModalFlag: boolean;
  tableData?: any;
  selectedDoc: string;
  activeMarket: string;
  docRow?: any;
  confirmModalFlag?: boolean
  delMarket?: any;
  data?: any;
  deleteRowIdArr?:any;
  allDocsForMarket?:any;
  marketFlag : boolean;
  marketArr ?: any;
  marketValue ?: any;
}

class MarketForm extends Component<Props, State> {
  constructor(props) {
    super(props)
      this.state = {
        vehicleList:{},
        rowData: this.props.rowData,
        marketSelected:[],
        marketErrIcon:{},
        driveTypeList: [],
        fuelTypeList:{},
        docModalFlag: false,
        tableData: [],
        selectedDoc:null,
        activeMarket:null,
        docRow:null,
        confirmModalFlag: false,
        delMarket: '',
        data: [],
        deleteRowIdArr: [],
        allDocsForMarket:{},
        marketFlag : false,
        marketArr : [],
        marketValue : ''
      }
  }

  componentDidMount(){
    let {marketSelected, marketErrIcon} = this.state;
    let data = {...this.state.rowData};
    let vehicleClassArr = {};
    let driveTypeArr = {};
    let fuelTypeArr = {};
    let marketRegAddedArr = [];
    let docObj = {};

    //Assigning blank values to vehicles, drivetypes and fueltypes
    this.props.MarketList && [...this.props.MarketList].map((market, index) =>{


      if (!data.regulations[market.value]) {
        marketErrIcon[market.value] = true;
        data['regulations'][market.value] = {};
        [...languageArr].map(lang =>{
          data['regulations'][market.value][lang.code] = {vehicles:[]};
        });
      }
      else{
        marketErrIcon[market.value] = false;
        marketRegAddedArr.push(market);
        [...languageArr].map(lang =>{
          if (!data.regulations[market.value][lang.code]) {
            data['regulations'][market.value][lang.code] = {vehicles:[]};
          }
          if (data.regulations[market.value][lang.code]['driveTypes']?.find(element => element.id == 0)) {
            data.regulations[market.value][lang.code]['driveTypes'] = [];
          }
          if (data.regulations[market.value][lang.code]['vehicles']?.find(element => element.id == 0)) {
            data.regulations[market.value][lang.code]['vehicles'] = [];
          }
          if (data.regulations[market.value][lang.code]['fuelTypes']?.find(element => element.id == 0)) {
            data.regulations[market.value][lang.code]['fuelTypes'] = [];
          }
        });
      }

      docObj[market.value] = {en: data.regulations[market.value]['en']?.documents, de:data.regulations[market.value]['de']?.documents};

    });

    this.props.marketSelected(marketRegAddedArr);
    this.props.allMarketSelectedHandler(marketRegAddedArr);
    this.setState({marketSelected: marketRegAddedArr});

    //Looping through language and updating state for vehicles, driveTypes and fuelTypes dropdown list
    [...languageArr].filter((lang) =>{
      vehicleClassArr[lang.code] = [];
      driveTypeArr[lang.code] = [];
      fuelTypeArr[lang.code] = [];
      this.props.VehicleList && [...this.props.VehicleList].map(item =>{
        let obj = {};
        obj['id'] = item.id;
        obj['languageCode'] = lang.code;
        obj['category'] = item.data[lang.code].category;
        vehicleClassArr[lang.code].push(obj);
      });

     this.props.DriveTypeList && [...this.props.DriveTypeList].map(item =>{
      let obj = {};
      obj['id'] = item.id;
      obj['languageCode'] = lang.code;
      obj['driveType'] = item.data[lang.code].driveType;
      driveTypeArr[lang.code].push(obj);
     });

      this.props.FuelTypeList && [...this.props.FuelTypeList].map(item =>{
        let obj = {};
        obj['id'] = item.id;
        obj['languageCode'] = lang.code;
        obj['type'] = item.data[lang.code].type;
        fuelTypeArr[lang.code].push(obj);
      });
    });
    let arr = [];
    arr.push(marketRegAddedArr[0]?.value)
    this.setState({vehicleList: vehicleClassArr, fuelTypeList: fuelTypeArr,driveTypeList: driveTypeArr});
    this.setState({rowData: data,  activeMarket: marketRegAddedArr[0]?.value, allDocsForMarket: docObj});
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.marketFlag !== prevState.marketFlag){
        if(this.state.marketFlag === true){
          this.handleMarketArr(this.state.activeMarket)
      }
    
    }
    if(this.state.marketSelected !== prevState.marketSelected){
      const result = prevState.marketSelected.map((el1) => ({
        name: el1,
        match: this.state.marketSelected.some((el2) => el2.label === el1.label),
      }))
      const removedArr = result.filter((item) => item.match === false)
      this.props.removedMarket(removedArr)
    }
    if (this.state.rowData !== prevState.rowData) {
      let {marketErrIcon} = this.state;
      /* Looping through market and setting error icon for market if title, shortDesc,
      actual regulations or future regulations has errors */
      this.props.MarketList && [...this.props.MarketList].map(item =>{
        let marketErrFlag = false;
        //setting marketErrFlag true, if title has error icon visible
        if ($(`p[id=${'title'+item.value+'en'}]`).css('display') === 'block') {
          marketErrFlag = true;
        }
        //setting marketErrFlag true, if shortDesc has error icon visible
        else if ($(`p[id=${'shortDesc'+item.value+'en'}]`).css('display') === 'block') {
          marketErrFlag = true;
        }
        //setting marketErrFlag true, if actual regulations has error icon visible
        else if ($(`p[id=${'actualRegulationSection'+item.value}]`).css('display') === 'block') {
          marketErrFlag = true;
        }
        //setting marketErrFlag true, if future regulations has error icon visible
        else if ($(`p[id=${'futureRegulationSection'+item.value}]`).css('display') === 'block') {
          marketErrFlag = true;
        }
        //Setting error icon for the market
        if (marketErrFlag) {
          marketErrIcon[item.value] = true;
        }
      });
    }
  }

  //Common all input fields onBlur handler. Checking if any mandatory fields has errors.
  inputOnBlurHandler = (market) =>{
    let {marketErrIcon} = this.state;
    marketErrIcon[market] = false;

    if ($(`p[id=${'title'+market+'en'}]`).css('display') === 'block') {
      marketErrIcon[market] = true;
    }
    if ($(`p[id=${'shortDesc'+market+'en'}]`).css('display') === 'block') {
      marketErrIcon[market] = true;
    }
    $(`p[id=${'actualRegulationSection'+market}]`).css({'display': 'none'});
    $(`p[id=${'futureRegulationSection'+market}]`).css({'display': 'none'});
    for (let index = 0; index < languageArr.length; index++) {
      if ($(`p[id=${'actualLinkToRevision'+market+languageArr[index].code}]`).css('display') === 'block') {
        $(`p[id=${'actualRegulationSection'+market}]`).css({'display': 'block'});
        marketErrIcon[market] = true;
      }
      else if ($(`p[id=${'actualLinkTOAmendment'+market+languageArr[index].code}]`).css('display') === 'block') {
        $(`p[id=${'actualRegulationSection'+market}]`).css({'display': 'block'});
        marketErrIcon[market] = true;
      }
      else if ($(`p[id=${'actualRegregulationNumber'+market+'en'}]`).css('display') === 'block') {
        $(`p[id=${'actualRegulationSection'+market}]`).css({'display': 'block'});
        marketErrIcon[market] = true;
      }

      if ($(`p[id=${'futureLinkToRevision'+market+languageArr[index].code}]`).css('display') === 'block') {
        $(`p[id=${'futureRegulationSection'+market}]`).css({'display': 'block'});
        marketErrIcon[market] = true;
      }
      else if ($(`p[id=${'futureLinkTOAmendment'+market+languageArr[index].code}]`).css('display') === 'block') {
        $(`p[id=${'futureRegulationSection'+market}]`).css({'display': 'block'});
        marketErrIcon[market] = true;
      }
    }

    this.setState(marketErrIcon);
  }
  handleMarketArr = (marketCode) =>{
    let arr = this.state.marketArr
    arr.push(marketCode)
    this.setState({marketArr: arr})
    this.props.marketArr(this.state.marketArr)
  }
  //Input fields onChange event handler
  inputHandler = (id, lang, marketCode, objKey, event) =>{
    event.preventDefault();
      let data = {...this.state.rowData};
      if (objKey !== '') {
        data['regulations'][marketCode][lang][objKey][id] = event.target.value;
      }
      else{
        data['regulations'][marketCode][lang][id] = event.target.value;
      }
      this.setState({rowData: data, marketFlag: true,marketValue: marketCode});
      }

  //Common dropdown onChange event handler
  eventHandler = (id, type, lang, marketCode, event) =>{
    let dataProps = [];
    let arr = this.state.marketArr
      if(id === 'vehicles'){
        dataProps = this.state.vehicleList;
      }
      else if(id === 'driveTypes'){
        dataProps = this.state.driveTypeList;
      }
      else if (id === 'fuelTypes') {
        dataProps = this.state.fuelTypeList;
      }
      let data = {...this.state.rowData};
      let autoUpdateArr = [];
      if (id === 'status') {
        [...languageArr].map(lang =>{
            Object.keys(data['regulations'][marketCode]).map(key =>{
              if (key === lang.code) {
                  data['regulations'][marketCode][key]['statusID'] = event.id;
              }
            })
        })
      }
      else{
        //Setting selected value from the dropdown
        data['regulations'][marketCode][lang][id] = event;

        //Auto-filling other language dropdown fields by ID
        Object.keys(data['regulations'][marketCode]).map((key, index) => {
          if (key !== lang) {
            if (type !== 'object') {
              [...event].filter(elem => {
                 let newArr = dataProps[key].filter(item => {
                   if (item.id === elem.id) {
                     return item;
                   }
                 })
                 autoUpdateArr.push(newArr[0]);
             });
             data['regulations'][marketCode][key][id] = autoUpdateArr;
            }
            else{
              dataProps[key].filter(item => {
                if (item.id === event.id) {
                  data['regulations'][marketCode][key][id] = item[id];
                }
              });
            }
          }
        });
      }
      this.setState({rowData: data, marketFlag: true });
  }

  //Market accordion section toggle
  marketCardToggle = (event) =>{
    event.preventDefault();
  }

  //Market dropdown onChange handler
  marketHandler = data =>{
    this.setState({marketSelected: data});
    var activeMarket = null;
    for (let i = 0; i < data.length; i++) {
      if (this.state.activeMarket == data[i].value) {
        activeMarket = data[i].value;
        break;
      }
    }
    this.setState({activeMarket: activeMarket == null ? data[0]?.value : activeMarket});
    this.props.marketSelected(data);
  }

  //Documents add button click handler
  openAddDocModal = (docID) =>{
    this.setState({docModalFlag: true, selectedDoc: docID, marketFlag: true});
  }

  //Documents modal cancel handler
  closeDocModal = () =>{
     this.setState({docModalFlag: false, docRow: null});
  }

  //Close delete document confirmation modal
  closeDeleteModal = () =>{
    this.setState({confirmModalFlag: false})
  }

  //Market tab onSelect event handler
  setActiveMarket = (activeMarketKey) =>{
    this.setState({marketFlag: false,activeMarket: activeMarketKey})
  }

  //Add document in table and update state
  addFileInTable = (arr, market, file) =>{
    this.props.fileHandler(file, arr[0]?.id);
    this.props.appendDocInToJsonHandler(arr, market);
    let data = {...this.state.allDocsForMarket};

    if (arr[0]?.docType == 'Add') {
      if (!data[market]) {
        data[market] = {en:[], de:[]};
      } else if (!data[market]['en'] || !data[market]['de']) {
        data[market]['en'] = [];
        data[market]['de'] = [];
      }

      data[market]['en'].push(arr[0]);
      data[market]['de'].push(arr[1]);
    } else {
      const language = localStorage.getItem('i18nextLng');
      const docArr = data[market][language];
      for (let index = 0; index < docArr.length; index++) {
        if (docArr[index]?.id == arr[0]?.id) {
          if (file) {
            data[market][language][index].name = arr[0]?.name;
          }
          data[market][language][index].shortDescription = language == 'de' ? arr[1]?.shortDescription : arr[0]?.shortDescription;
          data[market][language][index]['market'] = arr[0]?.market;
          break;
        }
      }
    }

    this.props.updateDocID(arr[0]?.id);
    this.setState({allDocsForMarket: JSON.parse(JSON.stringify(data))});
    this.closeDocModal();
  }

  //enable edit document modal flag
  docEditHandler = (row) =>{
    this.setState({docRow: row, docModalFlag: true, selectedDoc: row?.category});
  }

  //Update deleted document state arr
  addDeletedDocID = (id) =>{
    let arr = this.state.deleteRowIdArr;
    arr.push(id)
     this.setState({deleteRowIdArr: arr})
  }

  //Delete document after confirmation
  onDeleteDoc = (row, market) =>{
    let deletedRowdata = {...this.state.data}
    this.addDeletedDocID(deletedRowdata.id)
    this.props.docDeleteHandler(this.state.data)
    this.setState({ confirmModalFlag: false});
    this.setState({marketFlag: true})
  }

  //Undo deleted documents before saving
  undoDeleteChanges = (row) =>{
    let {deleteRowIdArr} = this.state;
    deleteRowIdArr.splice(deleteRowIdArr.indexOf(row?.id),1)
    this.setState(deleteRowIdArr);
    this.props.undoDeleteHandler(row)
    this.setState({marketFlag: false})
  }

  //Open delete document confirmation modal
  openDeleteModal = (row, market) =>{
    this.setState({confirmModalFlag: true, delMarket: market , data: row})
  }
  setActualFlag = (data) =>{
   this.setState({marketFlag: data})
  }
  setEquivalentFlag = (data) =>{
    this.setState({marketFlag: data})
   }
  setFutureFlag = (data) =>{
    this.setState({marketFlag: data})
   }
   handleDocumentChange = (data) =>{
    this.setState({marketFlag: data})
   }
   
  render() {
    let {rowData, marketErrIcon, selectedDoc, activeMarket, docRow} = this.state;
    let marketList = [...this.state.marketSelected];
    let docFormTemplate = <DocumentForm {...this.props} handleDocumentChange={this.handleDocumentChange} allDocsForMarket={this.state.allDocsForMarket} newDocuments={this.props.newDocuments} rowData={rowData} appendDocInToJson={(arr, market, file) => this.addFileInTable(arr, market, file)} marketCode={activeMarket} selectedDocID={selectedDoc} closeModal={this.closeDocModal} docRowData={docRow} formtype={this.props.formtype}/>;
    return(
      <Translation>{t => (
        <div id="accordionMarket">
        {/* Add and edit document modal */}
        { this.state.docModalFlag &&
          <EditModalComp modalID="docID" closeModal={this.closeDocModal} modalBody={docFormTemplate} modalTitle={t('mngRegulations.docModalTitle')} />
        }
        {/* Delete document confirmation modal */}
        { this.state.confirmModalFlag  &&
          <DeleteModalComp modalMsg={i18next.t('closeDeleteModal')} closeModal={this.closeDeleteModal} eventHandlerDelete={(row,market) => this.onDeleteDoc(row, market)} />
        }
          <fieldset className="scheduler-border">
            <legend className="scheduler-border">
              <div id="headingAccordionMarket">
                <h5 className="mb-0">
                  <button onClick={this.marketCardToggle} className="btn btn-link" data-toggle="collapse" data-target="#collapseMarketCard" aria-expanded="true" aria-controls="collapseMarketCard">
                    {i18next.t('mngRegulations.market')}
                  </button>
                </h5>
              </div>
            </legend>
            <div id="collapseMarketCard" className="collapse show" aria-labelledby="headingAccordionMarket" data-parent="#accordionMarket">

            {
              this.props.formtype === 'view' && marketList.length < 1 &&
                <h6 className="noMarketCss">{i18next.t('mngRegulations.noMarketsAdded')}</h6>
            }

            {/* Markets dropdown visible in edit mode */}
            {this.props.formtype !== 'view' &&
            <div className="col-sm-12" style={{'padding':'10px'}} id="marketSelectedDiv">
            <b style={{'fontSize':'larger'}}>{i18next.t('advancedFilters.selectMkt')}</b>
            <Typeahead
                id="basic-typeahead-multiple"
                emptyLabel={t('noMatchFound')}
                clearButton
                labelKey="label"
                multiple
                selected={this.state.marketSelected}
                onChange={(e) => this.marketHandler(e)}
                options={this.props.MarketList ? this.props.MarketList : []}
                placeholder={t('advancedFilters.selectMkt')}
            />
            </div>}

            <Tabs
                defaultActiveKey={marketList[0]?.value}
                id="uncontrolled-tab-example"
                className="grmTab"
                onSelect={(activeMarket) => this.setActiveMarket(activeMarket)}
            >
            {
              marketList && [...marketList].map((market, index) =>{

                  return(
                    <Tab  tabClassName="" title={
                        <div className="form-inline">
                          {market.label}
                          <div className="flag">{market.value !== 'ECE' ? <Flag className="mr-3 opacity-8" country={market.value} size={20}/> : <img src={uneceFlag}/>}</div>
                          {marketErrIcon[market.value] && <p id={'Tab'+market.value} style={{'margin':'0', 'cursor':'pointer'}}><AlertIonicon color="#d92550" fontSize="18px" icon="md-notifications-outline"/></p>}


                      </div>} eventKey={`${market.value}`}>

                        <div className="form-inline col-sm-12">
                        <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                          <div className="col-4"><h6><b>{i18next.t('mngRegulations.typeOfDrive')}</b></h6></div>
                          {/* Dropdown field visible in edit mode else read only text is visible */}
                            {
                              [...languageArr].map(lang =>{
                                return(
                                  <div className="col-4 labelTextCss">
                                    <h6>
                                      {
                                        this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                        <Typeahead
                                        id="basic-typeahead-multiple"
                                        emptyLabel={t('noMatchFound')}
                                        clearButton
                                        multiple
                                        labelKey="driveType"
                                        onChange={this.eventHandler.bind(rowData.regulations[lang.code], 'driveTypes', 'array', lang.code, market.value)}
                                        options={this.state.driveTypeList[lang.code]}
                                        selected={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] && rowData.regulations[market.value][lang.code].driveTypes}
                                        placeholder={t('mngRegulations.defaultAllPlaceholder')}/> :
                                          rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                          rowData.regulations[market.value][lang.code].driveTypeNames
                                      }
                                    </h6>
                                  </div>
                                )
                              })
                            }
                        </div>
                        <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                          <div className="col-4"><h6><b>{i18next.t('mngRegulations.vehicleClass')}</b></h6></div>
                          {/* Dropdown field visible in edit mode else read only text is visible */}
                            {
                              [...languageArr].map(lang =>{
                                return(
                                  <div className="col-4 labelTextCss">
                                    <h6>
                                      {
                                        this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                        <Typeahead
                                        id="basic-typeahead-multiple"
                                        emptyLabel={t('noMatchFound')}
                                        clearButton
                                        multiple
                                        labelKey="category"
                                        onChange={this.eventHandler.bind(rowData.regulations[lang.code], 'vehicles', 'array', lang.code, market.value)}
                                        options={this.state.vehicleList[lang.code]}
                                        selected={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] && rowData.regulations[market.value][lang.code].vehicles}
                                        placeholder={t('mngRegulations.defaultAllPlaceholder')}/> :
                                          rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                          rowData.regulations[market.value][lang.code].vehicleNames
                                        }
                                    </h6>
                                  </div>
                                )})
                            }
                        </div>

                        <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                          <div className="col-4"><h6><b>{i18next.t('mngRegulations.fuelTypeClass')}</b></h6></div>
                          {/* Dropdown field visible in edit mode else read only text is visible */}
                            {
                              [...languageArr].map(lang =>{
                                return(
                                  <div className="col-4 labelTextCss">
                                    <h6>
                                      {
                                        this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                        <Typeahead
                                        id="basic-typeahead-multiple"
                                        emptyLabel={t('noMatchFound')}
                                        clearButton
                                        multiple
                                        labelKey="type"
                                        onChange={this.eventHandler.bind(rowData.regulations[lang.code], 'fuelTypes', 'array', lang.code, market.value)}
                                        options={this.state.fuelTypeList[lang.code]}
                                        selected={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] && rowData.regulations[market.value][lang.code].fuelTypes}
                                        placeholder={t('mngRegulations.defaultAllPlaceholder')}/> :
                                          rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                          rowData.regulations[market.value][lang.code].fuelTypeNames
                                        }
                                    </h6>
                                  </div>
                                )})
                            }
                        </div>

                        <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                          <div className="col-4 form-inline">
                          <h6><b>{i18next.t('mngRegulations.title')} *</b></h6>
                          {/* Error icon for title */}
                            {
                              (rowData['regulations'][market.value] && rowData['regulations'][market.value]['en'] &&
                              !rowData['regulations'][market.value]['en'].title) || (rowData['regulations'][market.value] &&
                              rowData['regulations'][market.value]['en'] && (rowData['regulations'][market.value]['en'].title === '')) ?
                              <p id={'title'+market.value+'en'} style={{'margin':'0', 'cursor':'pointer'}}>
                                <a data-tip={t('mngRegulations.titleRequired')} data-for={'title'+market.value}>
                                  <AlertIonicon beat={true} color="#d92550" fontSize="18px" icon="md-notifications-outline"/>
                                </a>
                                <ReactTooltip id={'title'+market.value} place="right" type="error" effect="float"/>
                              </p>
                              : null
                            }
                            </div>
                            {
                              [...languageArr].map(lang =>{
                                return(
                                  <div className="col-4 labelTextCss">
                                    <h6 id={'title'+market.value+lang.code}>
                                    {/* Textarea field visible in edit mode else read only text is visible */}
                                      {
                                        this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                        <textarea
                                        placeholder={i18next.t('mngRegulations.titlePlaceholder')}
                                        onBlur={() => this.inputOnBlurHandler(market.value)}
                                        title={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] && rowData.regulations[market.value][lang.code].title}
                                        className={`${'title'+market.value+lang.code} form-control`}
                                        rows={3}
                                        id={'title'+market.value+lang.code}
                                        value={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] && rowData.regulations[market.value][lang.code].title}
                                        onChange={this.inputHandler.bind(this, 'title', lang.code, market.value, '')}
                                        name={'title'+market.value}
                                        required={lang.code === 'en' ? true : false}>
                                      </textarea> : rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                      rowData.regulations[market.value][lang.code].title}

                                    </h6>
                                  </div>
                                )})
                            }
                        </div>
                        <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                        <div className="col-4 form-inline"><h6><b>{i18next.t('mngRegulations.shortDesc')} *</b></h6>
                        {/* Error icon for short description */}
                        {
                          (rowData['regulations'][market.value] && rowData['regulations'][market.value]['en'] &&
                          !rowData['regulations'][market.value]['en'].shortDescription) || (rowData['regulations'][market.value] &&
                          rowData['regulations'][market.value]['en'] && (rowData['regulations'][market.value]['en'].shortDescription === '')) ?
                          <p id={'shortDesc'+market.value+'en'} style={{'margin':'0', 'cursor':'pointer'}}>
                            <a data-tip={t('mngRegulations.shortDescRequired')} data-for={'shortDesc'+market.value}>
                              <AlertIonicon beat={true} color="#d92550" fontSize="18px" icon="md-notifications-outline"/>
                            </a>
                            <ReactTooltip id={'shortDesc'+market.value} place="right" type="error" effect="float"/>
                          </p>
                          : null
                        }
                          </div>
                            {
                              [...languageArr].map(lang =>{
                                return(
                                  <div className="col-4 labelTextCss">
                                    <h6>
                                    {/* Textarea field visible in edit mode else read only text is visible */}
                                      {
                                        this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                        <textarea
                                        placeholder={i18next.t('mngRegulations.shortDescPlaceholder')}
                                        onBlur={() => this.inputOnBlurHandler(market.value)}
                                        title={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                               rowData.regulations[market.value][lang.code].shortDescription}
                                        className="form-control"
                                        rows={2}
                                        id={'shortDesc'+market.value+lang.code}
                                        value={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                               rowData.regulations[market.value][lang.code].shortDescription}
                                        onChange={this.inputHandler.bind(this, 'shortDescription', lang.code, market.value, '')}
                                        name={'shortDesc'+market.value}
                                        required={lang.code === 'en' ? true : false}>
                                      </textarea> : rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                        rowData.regulations[market.value][lang.code].shortDescription}
                                    </h6>
                                  </div>
                                )})
                            }
                        </div>
                       
                        {/* Actual regulations component */}
                        <ActualReg {...this.props} downloadFile={(id, name) => this.props.downloadFile(id, name)} undoDeleteChanges={(row) => this.undoDeleteChanges(row)} toggleDocModal={() => this.openAddDocModal('Actual')}   deleteRowIdArr={this.state.deleteRowIdArr}  openDeleteModal={(row, market)=> this.openDeleteModal(row,market)} onDocDelete={(row, market) => this.onDeleteDoc(row, market)} onDocEdit={(row) => this.docEditHandler(row)} inputOnBlurHandler={this.inputOnBlurHandler.bind(this)} marketCode={market.value} rowData={rowData} allDocsForMarket={this.state.allDocsForMarket} actualFlag={this.setActualFlag}/>

                        {/* Future regulations component */}
                        {/* <FutureReg {...this.props} downloadFile={(id, name) => this.props.downloadFile(id, name)} undoDeleteChanges={(row) => this.undoDeleteChanges(row)} toggleDocModal={() => this.openAddDocModal('Future')} deleteRowIdArr={this.state.deleteRowIdArr}  openDeleteModal={(row, market)=> this.openDeleteModal(row,market)}  onDocDelete={(row, market) => this.onDeleteDoc(row, market)} onDocEdit={(row) => this.docEditHandler(row)} inputOnBlurHandler={this.inputOnBlurHandler.bind(this)} marketCode={market.value} rowData={rowData} allDocsForMarket={this.state.allDocsForMarket} futureFlag={this.setFutureFlag} /> */}


                        {/* Equivalent regulations component not visible for UNECE market */}
                        {market.value !== 'ECE' && <EquivalentReg {...this.props} marketCode={market.value} rowData={rowData} euivalentFlag={this.setEquivalentFlag} />}

                        {/* Reference regulations component */}
                        <ReferenceReg {...this.props} inputOnBlurHandler={this.inputOnBlurHandler.bind(this)} marketCode={market.value} rowData={rowData} />

                        <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                          <div className="col-4"><h6><b>{i18next.t('mngRegulations.linktoAuthority')}</b></h6></div>
                            {
                              [...languageArr].map(lang =>{
                                return(
                                  <div className="col-4 labelTextCss">
                                    <h6 className="linkToAuthorityContainer">
                                    {/* Input field visible in edit mode else hyperlink text is visible */}
                                      {
                                        this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                        <input title={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                          rowData.regulations[market.value][lang.code].linkToAuthority} type="text" id={'linktoAuthority'+market.value} value={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                            rowData.regulations[market.value][lang.code].linkToAuthority} onChange={this.inputHandler.bind(this, 'linkToAuthority', lang.code, market.value, '')} className="form-control" placeholder={t('marketSectionForm.labelLink')} />
                                        :
                                        <a href={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                          rowData.regulations[market.value][lang.code].linkToAuthority} title={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                            rowData.regulations[market.value][lang.code].linkToAuthority} target="_blank">
                                          {rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                            rowData.regulations[market.value][lang.code].linkToAuthority && i18next.t('mngRegulations.linktoAuthority'+[lang.code])}
                                        </a>
                                      }
                                    </h6>
                                  </div>
                                )})
                            }
                        </div>
                        <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                          <div className="col-4"><h6><b>{i18next.t('mngRegulations.linkToAddInfo')}</b></h6></div>
                            {
                              [...languageArr].map(lang =>{
                                return(
                                  <div className="col-4 labelTextCss">
                                    <h6>
                                    {/* Input field visible in edit mode else hyperlink text is visible */}
                                      {
                                        this.props.formtype === 'edit' || this.props.formtype === 'confirm' ? <input title={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                          rowData.regulations[market.value][lang.code].linkToAdditionalInfo} type="text" id={'linkToAddInfo'+market.value} value={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                            rowData.regulations[market.value][lang.code].linkToAdditionalInfo} onChange={this.inputHandler.bind(this, 'linkToAdditionalInfo', lang.code, market.value, '')} className="form-control" placeholder={t('marketSectionForm.labelInfoLink')} />
                                        :
                                        <a title={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                          rowData.regulations[market.value][lang.code].linkToAdditionalInfo} href={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                            rowData.regulations[market.value][lang.code].linkToAdditionalInfo} target="_blank">
                                            {rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                              rowData.regulations[market.value][lang.code].linkToAdditionalInfo && i18next.t('mngRegulations.linkToAdditionalInfo')}
                                        </a>
                                      }
                                    </h6>
                                  </div>
                                )})
                            }
                        </div>
                        <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                          <div className="col-4"><h6><b>{i18next.t('mngRegulations.searchTags')}</b></h6></div>
                            {
                              [...languageArr].map(lang =>{
                                return(
                                  <div className="col-4 labelTextCss">
                                    <h6>
                                    {/* Input field visible in edit mode else read only text is visible */}
                                      {
                                        this.props.formtype === 'edit' || this.props.formtype === 'confirm' ? <input title={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                          rowData.regulations[market.value][lang.code].tags} type="text" id={'searchTags'+market.value} value={rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                            rowData.regulations[market.value][lang.code].tags} onChange={this.inputHandler.bind(this, 'tags', lang.code, market.value, '')} className="form-control" placeholder={t('marketSectionForm.labelSearch')} />
                                        : rowData.regulations[market.value] && rowData.regulations[market.value][lang.code] &&
                                          rowData.regulations[market.value][lang.code].tags
                                      }
                                    </h6>
                                  </div>
                                )})
                            }
                        </div>
                           <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                               <div className="col-4" style={{marginBottom: 'auto'}}><h6><b>{i18next.t('mngRegulations.documents')}</b></h6></div>
                               {/* Showing single table as per the language of the app */}
                               {
                                 [...languageArr].map(lang =>{
                                 if (lang.code === localStorage.getItem('i18nextLng')) {
                               return (
                               <div className="col-8">
                                  {(this.props.formtype === 'edit' || this.props.formtype === 'confirm') &&
                                  <div className="labelTextCss addDocBtn">
                                      <h6>
                                       <Button outline color="secondary" onClick={() => this.openAddDocModal('Topic')}>{i18next.t('add')}</Button>
                                      </h6>
                                   </div>}
                                   {/* Topics document table component */}
                                 <AdminDocumentTableComp deleteRowIdArr={this.state.deleteRowIdArr} undoDeleteChanges={(row) => this.undoDeleteChanges(row)}marketCode={market.value} formtype={this.props.formtype}  downloadFile={(id, name) => this.props.downloadFile(id, name)} dataType="Topic" openDeleteModal={(row, market)=> this.openDeleteModal(row,market)} onDocDelete={(row, market) => this.onDeleteDoc(row, market)} onDocEdit={(row) => this.docEditHandler(row)} documentsData={this.state.allDocsForMarket?.[market.value]?.[lang.code]} />
                               </div>
                             )
                           }
                         })
                       }
                           </div>
                        </div>
                      </Tab>)


              })
                  }
                </Tabs>
              </div>
            </fieldset>
          </div>
      )}</Translation>
    )
  }
}
  const mapStateToProps = (appState: AppState) => ({
    MarketList: appState.admin.MarketList,
    VehicleList: appState.admin.VehicleList,
    DriveTypeList: appState.admin.DriveTypeList,
    FuelTypeList: appState.admin.FuelTypeList
  });

  const mapDispatchToProps = dispatch => ({
  });

  export default connect(mapStateToProps, mapDispatchToProps)(MarketForm);
