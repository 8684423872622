import ModuleSet from "./AppModules";
import { InitialUserState } from "../../network/user/Reducers";
import { InitialLoadingState } from "../../network/loading/Reducers";
import { InitialCommonState } from "../../network/common/Reducers";
import { InitialAdminState } from "../../network/admin/Reducers";

export interface AppState {
  common: InitialCommonState;
  user: InitialUserState;
  loading: InitialLoadingState;
  admin: InitialAdminState;
}

const _ = require("lodash");
const reducerModule = {
  common: require("../../network/common/Reducers"),
  user: require("../../network/user/Reducers"),
  loading: require("../../network/loading/Reducers"),
  admin: require("../../network/admin/Reducers"),
};

const Reducers = _(ModuleSet)
  .keyBy((module) => module)
  .mapValues((module) => {
    return reducerModule[module];
  })
  .mapValues((module) => module.Reducer)
  .value();

export default Reducers;
