import i18next from 'i18next';

export const VehiclesColData = () => {
  return [
    {
        dataField: 'rowId',
        isKey: true,
        label: i18next.t('id'),
        hidden: true,
        placeholder: i18next.t('idPlaceholder')
    },
    {
        isKey: false,
        label: 'Action',
        hidden: false
    },
    {
        dataField: 'category',
        isKey: false,
        label: i18next.t('category'),
        dataFormat: (cell, row) =>{
          return row.data && row.data[localStorage.getItem('i18nextLng')].category;
        },
        hidden: false,
        placeholder: i18next.t('vehiclePlaceholder')
    }
  ]
};

export const languageArr = [
  {
    id:1,
    code:"en"
  },
  {
    id:2,
    code:"de"
  }
];
