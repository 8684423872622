import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import {Typeahead} from 'react-bootstrap-typeahead';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button} from 'reactstrap';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import RevisionHistoryTable from "../Table/RevisionHistoryTable";
import DeleteIcon from 'react-ionicons/lib/MdTrash';
import DownIcon from './../../down-icon.png';
import ReactTooltip from 'react-tooltip';
import RightIcon from './../../right-icon.png';
import './RevisionHistoryForm.css';
import AddActualRegForm from "../ManageRegulations/AddActualRegForm";
import EditModalComp from '../Table/EditModal';
import DeleteModalComp from './../Table/DeleteModal';
import Actions from "../../internal/modules/Actions";
import { toast } from "react-toastify";
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';

import TranslationService from "../../utils/TranslationService";

const t = TranslationService.t;


interface Props extends Translate{
    closeModal?: any;
    rowData ?: any;
    formtype?: any;
    marketCode ?: any;
    loading: boolean;
    delSerIds: string[] | [];
    getRevisionHistoryData: (regulationNumber) => {};
    revisionList ?: any;
    addRevisionHistory : (rev) => {};
    deleteSeries: (serData) => {},
    revSuccessMsg : boolean;
    seriesDeleted: boolean;
  }
  
  interface State{
    open ?: any;
    rowData ?: any;
    editModalFlag : boolean;
    formType ?: any;
    revData ?: any;
    revHistoryData ?: any;
    addRevArr ?: any;
    deletedRow ?: any;
    confirmModalFlag : boolean;
    delSerId:string;
    delSerIdArr?: any;
    data ?: any;
    allAddedRevArr ?: any;
    selectedSeries?: any;
    revisionErrMsg?: any;
  }
  
  class RevisionHistoryForm extends Component<Props, State> {
    constructor(props) {
      super(props)
        this.state = {
            open : '',
            rowData: this.props.rowData,
            editModalFlag : false,
            formType : '',
            revData : {},
            revHistoryData: [],
            addRevArr : [],
            deletedRow :[],
            confirmModalFlag : false,
            delSerId: '',
            delSerIdArr : [],
            data : this.props.revisionList,
            allAddedRevArr: [],
            selectedSeries : {},
            revisionErrMsg : ''
        }
    }
    static getDerivedStateFromProps(newState, prevState) {
      return {
        revisionErrMsg: newState.revisionErrMsg
       };
     }
    componentDidMount(){
        let {data} = this.state;
        if(this.props.revisionList !== undefined){
            this.setState({revHistoryData : data})
        }
    }
    componentDidUpdate(prevProps, prevState){
        if (this.props.revisionList && prevProps.revisionList !== this.props.revisionList) {
           this.setState({data : this.props.revisionList , revHistoryData: this.props.revisionList, selectedSeries: this.props.revisionList[0] })
        }
        if(this.props.revSuccessMsg && prevProps.revSuccessMsg !== this.props.revSuccessMsg){
            toast['success'](i18next.t('mngGRM.addGrmSuccess'), { position: 'top-right' });
            this.props.closeModal();
        }
        if(this.props.seriesDeleted && prevProps.seriesDeleted !== this.props.seriesDeleted){
            toast['success'](i18next.t('mngGRM.addGrmSuccess'), { position: 'top-right' });
            this.props.closeModal();
        }
        if(this.state.revisionErrMsg && this.state.revisionErrMsg !== prevState.revisionErrMsg){
          const t = TranslationService.t;
          toast['error'](t(this.state.revisionErrMsg), { position: 'top-right' });
        }
    }
    toggleSeriesDiv = (row) =>{
         this.state.revHistoryData?.map(item => {
            if(item.series === row){
                this.setState(prevState => ({open: prevState.open === row ? '' : row}));
            }
          });
      }
      adddeleteSerId = (serNum) => {
      const regId = 1
      let arr = this.state.delSerIdArr
      arr.push(serNum)
       this.setState({delSerId: serNum, delSerIdArr: arr,confirmModalFlag: true})
     }
     cancelRemSerId = () => {
        const { delSerId } = this.state;
        this.setState({delSerId: '', delSerIdArr: [],confirmModalFlag: false })
      }
      deleteSeriesFromState = () => { 
        const { delSerId, rowData} = this.state;
        let selectedSeries = this.state.data && this.state.data[1]
        const filterRow = this.state.data.filter(revHist => revHist.series !== delSerId)
        this.setState({data: filterRow,revHistoryData:filterRow,selectedSeries})
        this.setState({confirmModalFlag: false})
      }
     onRevEdit = (row) =>{
        this.setState({formType: 'edit', editModalFlag: true, revData: row})
      }
      closeDeleteModal = () =>{
        this.setState({confirmModalFlag: false})
      }
      onDeleteDoc = (row) =>{
        let {deletedRow, revHistoryData,rowData} = this.state;
        let arr = []
        deletedRow.map((item) => {
          revHistoryData.map((ele) =>{
            item['removed'] = true;
            for (let index = 0; index < ele.data.length; index++) {
              if (ele.data[index].id === item.id) {
                ele.data.splice(index,1);
              }
            }
          }) 
        })
     // let regulation = { regulationNumber: rowData?.regulationNumber, languageCode : 'en'}
      let revArr = deletedRow.map((rev) => ({...rev,regulationNumber: rowData?.regulationNumber}));
      revArr.map((item) =>{
        this.state.allAddedRevArr.push(item)
       })
     // this.setState({allAddedRevArr: revArr})
      this.setState({confirmModalFlag: false})
      }
      onRevDelete = (row) =>{
        let {deletedRow} = this.state;
        deletedRow.push(row)      
        this.setState({confirmModalFlag: true, deletedRow: deletedRow })
      }
      toggleAddSeries = () => {
        this.setState({editModalFlag: true , formType: 'add'})
      }
      closeModal = () =>{
        this.setState({formType : '', editModalFlag: false})
      }
      handleAddRev = (data) =>{
        let {revHistoryData, rowData} = this.state;
        let arr = [...this.state.addRevArr]
              if(data.length !== 0){
                   data.map(ele => {
                      let flag ="id" in ele.data[0]
                      if((flag === false)){
                        const revArr = revHistoryData.find((element) => {
                                    if(element.series === ele.series && this.state.formType === 'add')
                                    {
                                     return element.data.unshift(ele.data[0])
                                     }
                                  })
                          if(revArr !== undefined && this.state.formType === 'add'){
                            arr.push(ele.data[0])
                           // this.setState({addRevArr: arr})
                          }
                          if(revArr === undefined && this.state.formType === 'add' ){
                            revHistoryData.unshift(ele)
                            arr.push(ele.data[0])
                            //this.setState({addRevArr: arr})
                          }
                          else if(revArr === undefined && this.state.formType === 'edit' ){
                           let arrData = arr.findIndex(el =>  JSON.stringify(el) === JSON.stringify(this.state.revData) )
                           let newArrData = [...arr]
                           newArrData[arrData] = ele.data[0]
                           arr = newArrData
                            revHistoryData.map(item =>{ 
                              const elementsIndex = item.data.findIndex(element => JSON.stringify(element) === JSON.stringify(this.state.revData))
                                let newArr = [...item.data]
                                  newArr[elementsIndex] = ele.data[0]
                                  item.data = newArr
                                })
                           }
                          this.setState({addRevArr: arr})
                        }
                      else{
                        revHistoryData.map(item =>{ 
                          const elementsIndex = item.data.findIndex(element => element.id == ele.data[0].id  )
                              let newArr = [...item.data]
                              newArr[elementsIndex] = ele.data[0]
                              item.data = newArr
                          })
                          arr.push(ele.data[0])
                          this.setState({addRevArr: arr})
                      }
                     })
                }
          this.state.deletedRow.map((item) =>{arr.push(item)})
          let mkt = ''
          if(rowData?.market === 'ECE'){
              mkt = 'UNECE'
          }
          else if(rowData?.market === 'EU'){
            mkt = 'EU27'
          }
          else{
            mkt = 'India'
          }
       // let regulation = { regulationNumber: rowData?.regulationNumber, languageCode : 'en'}
        let revArr = arr.map((rev) => ({...rev,regulationNumber:rowData?.regulationNumber,market: mkt}));
        this.setState({allAddedRevArr: revArr})
      }
      SaveFormHandler = () =>{
        let {allAddedRevArr} = this.state;
        if(allAddedRevArr.length > 0){
            let arr = [];
            allAddedRevArr.map(item =>{
                arr.push(item)
            })
            this.props.addRevisionHistory(arr)
        }
       if(this.state.delSerIdArr.length > 0) {
        const delSerApiData = {
            regulationNumber: this.state.rowData?.regulationNumber,
            series: this.state.delSerIdArr
        }          
         this.props.deleteSeries(delSerApiData)
        }
      }
      seriesSelected = (series) => {
        this.setState({selectedSeries: series})
      }
    render() {               
        const spinner4 = <LoaderAnim active type="ball-beat"/>;  
        let {rowData} = this.state;
        let mkt = ''
        if(rowData?.market === 'ECE'){
            mkt = 'UNECE'
        }
        else if(rowData?.market === 'EU'){
          mkt = 'EU27'
        }
        else{
          mkt = 'India'
        }    
        let formTemplate, modalTitle = null;
        if (this.state.formType === 'edit') {
          formTemplate = <AddActualRegForm data={this.state.revData} market={rowData?.market} rowData={null} marketCode={this.props.marketCode} handleAddRev={this.handleAddRev} toggleAddSeries={this.toggleAddSeries} closeModal={this.closeModal} />
          modalTitle = i18next.t('revHistory.revModalHeading');
        }
        else{
          formTemplate =  <AddActualRegForm market={rowData?.market} closeModal={this.closeModal}  marketCode={this.props.marketCode} handleAddRev={this.handleAddRev} toggleAddSeries={this.toggleAddSeries} />
          modalTitle = i18next.t('revHistory.revModalHeading');
        }
      
        return( 
          <Translation>{t => (
            <div id="revisionFormDiv">  
              { this.state.editModalFlag &&
        <EditModalComp modalID="addRevModal" closeModal={this.closeModal} modalBody={formTemplate} modalTitle={modalTitle} />
      }
       { this.state.confirmModalFlag  &&
          <DeleteModalComp modalMsg={i18next.t('revHistory.deleteRevMsg')} closeModal={this.closeDeleteModal} eventHandlerDelete={(row) => this.onDeleteDoc(row)} />
        }
       { this.state.confirmModalFlag  && this.state.delSerId &&
          <DeleteModalComp modalMsg={t('closeDeleteSeriesModal')} closeModal={this.cancelRemSerId} eventHandlerDelete={() => this.deleteSeriesFromState()} />
        }
                 <div className="form-inline col-sm-12" style={{paddingBottom : '3px'}}>
                    <div className="form-inline col-sm-2" >
                        <h6 ><b>{t('table.regulationNo')}:</b></h6>
                    </div>
                    <div className="form-inline col-sm-10" style={{padding: 0}}>
                    <h6>{rowData.regulationNumber}</h6>
                    </div>
                 </div>
                 <div className="form-inline col-sm-12" style={{paddingBottom : '3px'}}>
                    <div className="form-inline col-sm-2" >
                        <h6><b>{t('table.market')}:</b></h6>
                    </div>
                    <div className="form-inline col-sm-10" style={{padding: 0}}>
                    <h6>{mkt}</h6>
                    </div>
                 </div>
                <div className="form-inline col-sm-12">
                  <div className="form-inline col-sm-1" >
                    <h6 className="addseries" style={{}}><b>{t('mngRegulations.seriesLabel')}</b></h6>
                  </div>
                 <div className="form-inline col-sm-11">
                   <a data-tip={t('revHistory.addSeriesTooltip')} data-for="addSerieTooltip">
                     <i className="pe-7s-plus addSeries" style={{'cursor':'pointer'}} onClick={this.toggleAddSeries} />
                   </a>
                   <ReactTooltip id="addSerieTooltip" place="right" type="info" effect="float"/></div>
                </div>
                <Loader message={spinner4} show={this.props.loading} style={{height:'100%'}}>
                  <div className="col-sm-12 row mt-1">
                      <div className={`form-inline col-sm-2`}>
                        <div className='w-100 seriesSideBar'>
                              { this.state.selectedSeries && Object.keys(this.state.selectedSeries).length !== 0 && this.state.data?.map((item,i) => {
                                if(this.state.selectedSeries && this.state.selectedSeries.series === item.series) {
                                  return <p className="seriesName selectedSeries" style={{margin: 0}}>Series {item.series}</p>
                                }
                                else {
                                  return <p className="seriesName" style={{margin: 0}} onClick={() => this.seriesSelected(item)}>Series {item.series}</p>
                                }
                                      {/* {this.state.open == item?.series ?
                                              <>
                                                  <div className="col-12 form-inline">
                                                    <a style={{fontSize: '12px'}}><b>Series-{item.series}</b></a> <img src={DownIcon} style={{height : '10px',marginLeft: '5px'}} onClick={() => this.toggleSeriesDiv(item.series)}/>
                                                    {this.props.formtype !== 'view' &&
                                                    <div> <a data-tip={t('mngRegulations.deleteSeriesTooltip')}data-for="deleteIconSeriesTooltip">
                                                              <DeleteIcon style={{'cursor':'pointer',height: '1rem', margin: '0 8px'}} onClick={() => this.adddeleteSerId(item.series)} />
                                                            </a>
                                                          <ReactTooltip id="deleteIconSeriesTooltip" place="right" type="info" effect="float"/>
                                                    </div>}
                                                  </div>
                                                  <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`} style={{marginTop:'5px', marginBottom:'10px'}}>
                                                  <RevisionHistoryTable onRevEdit={(row) => this.onRevEdit(row)}  onRevDelete={(row) => this.onRevDelete(row)} data={item.data} formType={`${this.props.formtype === 'view' ? "viewType" : "editType"}`}/>
                                                  </div>
                                              </>
                                          :
                                          <div>
                                          <div className="col-12 form-inline labelCss w-100"><a style={{fontSize: '12px'}}><b>Series-{item.series}</b></a> <img src={RightIcon} style={{height : '10px', marginLeft: '5px'}} onClick={() => this.toggleSeriesDiv(item.series)}/>
                                          {this.props.formtype !== 'view' &&
                                          <div>
                                          <a data-tip={t('mngRegulations.deleteSeriesTooltip')}data-for="deleteIconSeriesTooltip">
                                            <DeleteIcon style={{'cursor':'pointer',height: '1rem',margin: '0 8px'}} onClick={() => this.adddeleteSerId(item.series)} />
                                          </a>
                                          <ReactTooltip id="deleteIconSeriesTooltip" place="right" type="info" effect="float"/> </div>}
                                          </div>
                                          </div>} */}
                              } 
                                  )}
                        </div>
                      </div>
                      <div className={`form-inline col-sm-10`}>
                        {
                          this.state.selectedSeries && Object.keys(this.state.selectedSeries).length !== 0  && (
                          <div className="revTableContainer">
                            <div className="seriesTitleBox">
                                <h6 className="seriesTitle">Series {this.state.selectedSeries.series}</h6>
                                  {
                                    this.props.formtype !== 'view' && (
                                      <>
                                        <a data-tip={t('mngRegulations.deleteSeriesTooltip')}data-for="deleteIconSeriesTooltip" style={{marginBottom: '0.3rem'}}>
                                          <DeleteIcon style={{'cursor':'pointer',height: '0.8rem', margin: '0 6px'}} onClick={() => this.adddeleteSerId(this.state.selectedSeries.series)} />
                                        </a>
                                          <ReactTooltip id="deleteIconSeriesTooltip" place="right" type="info" effect="float"/>
                                      </>
                                    )
                                  }
                            </div>
                            <div className="revTableBox">
                              <RevisionHistoryTable onRevEdit={(row) => this.onRevEdit(row)} market={rowData.market}  onRevDelete={(row) => this.onRevDelete(row)} data={this.state.selectedSeries.data} formType={`${this.props.formtype === 'view' ? "viewType" : "editType"}`}/>
                            </div>
                            <div>

                            </div>
                          </div>
                          )
                        }
                      </div>  
                  </div>
                </Loader>

               <hr />
              <div className="col-sm-12" style={{'textAlignLast':'end'}}>
                <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
                <Button color="primary" onClick={this.SaveFormHandler} disabled={this.props.loading}>{(this.props.loading) ? i18next.t('savingData') : i18next.t('save')}</Button>
              </div>
        
            </div>
    )}</Translation>
    )
  }
}
  const mapStateToProps = (appState: AppState) => ({
    loading: appState.loading.loading,
    delSerIds: appState.admin.delSerIds,
    revisionList: appState.admin.revisionList,
    revSuccessMsg : appState.admin.revSuccessMsg,
    seriesDeleted: appState.admin.seriesDeleted,
    revisionErrMsg: appState.admin.addRevisionHistoryRequest.$errorMessage
  });

  const mapDispatchToProps = dispatch => ({
    getRevisionHistoryData: (regulationNumber) => Actions.admin.getRevisionHistoryData(dispatch,regulationNumber),
    addRevisionHistory: (rev) => Actions.admin.addRevisionHistory(dispatch,rev),
    deleteSeries:(serData) => Actions.admin.deleteSeries(dispatch,serData)
  });

  export default connect(mapStateToProps, mapDispatchToProps)(RevisionHistoryForm);  