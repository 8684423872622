import React, {Fragment, Component} from 'react';
import {Route, Redirect,Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import i18n from '../../i18n/I18n';
import { Spinner,Button } from 'reactstrap';
import './Confirm.css';
import Checkicon from 'react-ionicons/lib/IosCheckmarkCircle';
import { threadId } from 'worker_threads';
import {toast} from 'react-toastify';

const t = TranslationService.t;

interface Props extends Translate{
    match ?: any;
    userVerification : (any) =>{} ;
    history?: any;
    location?: any;
    confirmFlag : boolean;
  }

interface State{
  errorMessage: string,
  confirmFlag: boolean
  }

class ConfirmComp extends Component<Props, State> {

    constructor(props){
      super(props)
      this.state={
        errorMessage: null,
        confirmFlag: false
      }
    }
    static getDerivedStateFromProps(newState, prevState) {
      return {
         loading: newState.loading,
         success: newState.success,
         errorMessage: newState.errorMessage,
      };
   }
  componentDidMount(){
    const { uniqueId } = this.props.match.params;
    let obj = {
      uniqueId: uniqueId
    }
    //verifying user with unique id
    this.props.userVerification(obj)
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.confirmFlag && prevProps.confirmFlag !== this.props.confirmFlag) {
       this.setState({confirmFlag: true})
    }
    if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
      toast['error'](t(this.state.errorMessage), { position: 'top-right' });
       this.setState({confirmFlag: true})
    }
  }
  
  //redirect to login page after user confirmation.
  handleLogin =()=>{
    this.props.history.push('/login')
  }
  render() {
     return (
      <Translation>{t => (<Fragment>
            <ReactCSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                    <div className="confirm">
                      {this.state.confirmFlag === false
                        ? <div className="spinner-border spinner-border-sm loader" role="status">
                           <span className="sr-only">{t('loader')}</span>
                          </div>
                          : this.props.confirmFlag === true ?
                            <div>
                              <Checkicon fontSize="100px" color="green"/>
                              <h6 className="label"> <b>{t('verifySuccess')}</b></h6>
                              <Button color="primary" onClick={this.handleLogin}>{t('loginBack')}</Button>
                            </div>:
                            <Button color="primary" style={{marginTop: '8%'}} onClick={this.handleLogin}>{t('loginBack')}</Button>
                       }
                     </div>
             </ReactCSSTransitionGroup>
             </Fragment>)}
      </Translation>
       )
    }
}

const mapStateToProps = (appState: AppState) => ({
       confirmFlag: appState.user.confirmFlag,
       errorMessage: appState.user.UserVerificationRequest.$errorMessage
       });

const mapDispatchToProps = dispatch => ({
    userVerification : (uniqueID) => Actions.user.userVerification(dispatch,uniqueID)
       });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmComp));
