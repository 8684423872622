import React, {Component} from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { AppState } from './../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import Actions from './../../internal/modules/Actions';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import TranslationService from '../../utils/TranslationService';
import './TimelineMatrix.css';
import moment from 'moment';
import i18n from '../../i18n/I18n';
import {Row, Button} from 'reactstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Typeahead} from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import mockData from './../../utils/StaticData.json';
import ReactTooltip from 'react-tooltip';
import { id } from 'date-fns/esm/locale';
import ToggleIconPlus from 'react-ionicons/lib/MdAddCircle';
import ToggleIconMinus from 'react-ionicons/lib/MdRemoveCircle';

var qs = require('qs');
const t = TranslationService.t;
interface Props extends Translate{
  MarketList?: any,
  VehicleList?: any,
  GroupList?: any,
  DriveTypeList?:any,
  VehicleFuelTypeList?: any,
  activeKey?: any,
  getMarketList: () => {},
  getVehicleList: () => {},
  getGroupList:() =>{},
  getVehicleFuelTypeList: () =>{},
  getDriveTypeList:() =>{},
  getTimeMatrixList: (any) => {},
  timeMatrixRegFetched: boolean,
  changeFilterFlag ?: any;
 }

interface State{
  regulationFilterList?: any,
  groupSelected?: any,
  vehicleFuelTypeSelected?:any,
  marketSelected?: any,
  vehicleSelected?: any,
  activeKey: string,
  initialToggle: boolean,
  marketOption?: any,
  typeOfDriveSelected?:any,
  valiationErrMsg: boolean,
  callApiFlag: boolean,
  disableBtn: boolean
}

class TimelineMatrixFilter extends Component<Props, State> {
  constructor(props: Props){
    super(props);
    this.state = {
        groupSelected:[],
        typeOfDriveSelected:[],
        vehicleFuelTypeSelected:[],
        marketSelected:[],
        vehicleSelected:[],
        activeKey: "5",
        initialToggle: false,
        marketOption: this.props.MarketList,
        regulationFilterList: {},
        valiationErrMsg: false,
        callApiFlag: false,
        disableBtn: false
    };
  }
  componentDidMount(){
    //Call api if any of the filter listing is undefined
    if (!this.props.MarketList) {
      this.props.getMarketList();
    }
    if (!this.props.VehicleList) {
      this.props.getVehicleList();
    }
    if (!this.props.GroupList) {
      this.props.getGroupList();
    }
    if (!this.props.DriveTypeList) {
      this.props.getDriveTypeList();
    }
    if (!this.props.VehicleFuelTypeList) {
      this.props.getVehicleFuelTypeList();
    }
  }

    componentDidUpdate(prevProps, prevState){
      //Enable apply filter button after regulations fetched
      if (this.props.timeMatrixRegFetched && prevProps.timeMatrixRegFetched !== this.props.timeMatrixRegFetched) {
        this.setState({disableBtn: false});
      }

      //Update market list
      if (this.props.MarketList !== prevProps.MarketList) {
        this.setState({marketOption: this.props.MarketList});
      }

      //Update vehicle category list
      if (this.props.VehicleList !== prevProps.VehicleList) {
        this.setVehicleClass();
      }

      //Update group list
      if (this.props.GroupList !== prevProps.GroupList) {
        this.setGroups();
      }

      //Update drive type list
      if (this.props.DriveTypeList !== prevProps.DriveTypeList) {
        this.setDriveTypes();
      }

      //Update fuel type list
      if (this.props.VehicleFuelTypeList !== prevProps.VehicleFuelTypeList) {
        this.setFuelTypes();
      }

      //Reset all filters after tab change
      if (prevProps.activeKey !== this.props.activeKey) {
        this.setState({
          regulationFilterList: {}, groupSelected:[], typeOfDriveSelected:[],
          vehicleFuelTypeSelected:[], marketSelected:[], vehicleSelected:[],
          initialToggle: false, valiationErrMsg:false, callApiFlag:false});
      }

      //Fetch regulations after app language changes
      if (this.props.t !== prevProps.t) {
        if (this.state.callApiFlag) {
          this.applyFilter();
        }
      }

    }

    //Group dropdown onChange event handler
    groupHandler = data =>{
      this.setState({groupSelected: data});
      let {regulationFilterList} = this.state;
      let arr = [];
      data.forEach(element => {
        arr.push(element.id);
      });
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('groups')) {
        delete regulationFilterList['groups'];
      }
      else {
        regulationFilterList['groups'] = arr;
      }
      this.setState(regulationFilterList);
      this.props.changeFilterFlag(true)
    }

    //Drive type dropdown onChange event handler
    typeOfDriveHandler = data =>{
      this.setState({typeOfDriveSelected: data});
      let arr = [];
      data.forEach(element => {arr.push(element.id)});
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('driveTypes')) {
        delete regulationFilterList['driveTypes'];
      }
      else {
        regulationFilterList['driveTypes'] = arr;
      }
      this.setState(regulationFilterList);
      this.props.changeFilterFlag(true)
    }

    //Fuel type dropdown onChange event handler
    vehicleFuelTypeHandler = data =>{
      this.setState({vehicleFuelTypeSelected: data});
      let arr = [];
      data.forEach(element => {arr.push(element.id)});
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('fuelTypes')) {
        delete regulationFilterList['fuelTypes'];
      }
      else {
        regulationFilterList['fuelTypes'] = arr;
      }
      this.setState(regulationFilterList);
      this.props.changeFilterFlag(true)
    }

    //Market dropdown onChange event handler
    marketHandler = data =>{
      this.setState({marketSelected: data})
      let arr = [];
      data.forEach(element => {arr.push(element.id)});
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('markets')) {
        delete regulationFilterList['markets'];
      }
      else {
        regulationFilterList['markets'] = arr;
      }
      this.setState(regulationFilterList);
      this.props.changeFilterFlag(true)
    }

    //Vehicle category dropdown onChange event handler
    vehicleHandler = data =>{
      this.setState({vehicleSelected: data});
      let arr = [];
      data.forEach(element => {arr.push(element.id)});
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('vehicles')) {
        delete regulationFilterList['vehicles'];
      }
      else {
        regulationFilterList['vehicles'] = arr;
      }
      this.setState(regulationFilterList);
      this.props.changeFilterFlag(true)
    }

    //Market dropdown input onChange event handler
    handleMarketInputChange = (event) =>{
      let country = [];
      [...this.props.MarketList].forEach(elem => {
        //Filtering markets to show list starting with string entered by user
        if (elem.label.toUpperCase().startsWith(event.toUpperCase())) {
          country.push(elem);
        }
      });
      this.setState({marketOption: country});
    }

    //Fetch regulations based on filters selected
    applyFilter = () =>{
      let {regulationFilterList} = this.state;
      let filterErrFlag = false;
      if (!regulationFilterList.markets) {
        filterErrFlag = true;
      }
      if (!filterErrFlag) {
        this.setState({valiationErrMsg: false, callApiFlag: true, disableBtn:true});
        let language= localStorage.getItem('i18nextLng')
        const queryParam = qs.parse(regulationFilterList);
        const newQueryParam = {...queryParam,ln: language};
        this.props.getTimeMatrixList(newQueryParam);
      }
      else{
        this.setState({valiationErrMsg: true});
      }
      this.props.changeFilterFlag(false)
    }

    //Filter card toggle handler
    handleToggle = () =>{
      this.setState({initialToggle: !this.state.initialToggle})
    }

    //Update vehicle list
    setVehicleClass = () =>{
      let vehicleSelectedArr = [];
      if (this.state.vehicleSelected.length > 0) {
        [...this.props.VehicleList].map(item =>{
          [...this.state.vehicleSelected].map(elem =>{
            if (elem.id === item.id) {
              vehicleSelectedArr.push(item);
            }
          });
        });
        this.setState({vehicleSelected: vehicleSelectedArr});
      }
    }

    //Update group list
    setGroups = () =>{
      if (this.state.groupSelected.length > 0) {
        let groupListSelectedArr = [];
        [...this.props.GroupList].map(item =>{
          [...this.state.groupSelected].map(elem =>{
            if (elem.id === item.id) {
              groupListSelectedArr.push(item);
            }
          });
        });
        this.setState({groupSelected: groupListSelectedArr});
      }
    }

    //Update fuel types list
    setFuelTypes = () =>{
      if (this.state.vehicleFuelTypeSelected.length > 0) {
        let fuelTypeListSelectedArr = [];
        [...this.props.VehicleFuelTypeList].map(item =>{
          [...this.state.vehicleFuelTypeSelected].map(elem =>{
            if (elem.id === item.id) {
              fuelTypeListSelectedArr.push(item);
            }
          });
        });
        this.setState({vehicleFuelTypeSelected: fuelTypeListSelectedArr});
      }
    }

    //Update drive types list
    setDriveTypes = () =>{
      if (this.state.typeOfDriveSelected.length > 0) {
        let driveTypeListSelectedArr = [];
        [...this.props.DriveTypeList].map(item =>{
          [...this.state.typeOfDriveSelected].map(elem =>{
            if (elem.id === item.id) {
              driveTypeListSelectedArr.push(item);
            }
          });
        });
        this.setState({typeOfDriveSelected: driveTypeListSelectedArr});
      }
    }

    render() {
        const{t, i18n} = this.props
        const {regulationFilterList, valiationErrMsg, disableBtn} = this.state;

        return (
          <Translation>{t => (
            <div id="TimelineMatrixFilterPage">
              <div id="accordionOneTimeMtrx">
                <div className="card">
                  <div className="card-header" id="headingOneTimeMtrx">
                    <h5 className="mb-0">
                      <button style={{'cursor':'default'}} className="btn">{t('advancedFilters.toogleFilter')}</button>
                      {
                        !this.state.initialToggle ?
                        <ToggleIconMinus style={{'cursor':'pointer'}} onClick={this.handleToggle} color="#545cd8" fontSize="18px" icon="md-notifications-outline"/>
                        :
                        <ToggleIconPlus style={{'cursor':'pointer'}} onClick={this.handleToggle} color="#545cd8" fontSize="18px" icon="md-notifications-outline"/>
                      }
                    </h5>
                  </div>
                  <div id="collapseOneTimeMtrx" className={!this.state.initialToggle ? 'show col-12' : 'collapse col-12'}>
                    <div className="card-body col-12">
                      <Row>

                        <div id="groupHandlerTimeMtrx" className="divPaddingTimeMtrx">
                          <h6><b>{t('advancedFilters.group')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="name"
                              multiple
                              selected={this.state.groupSelected}
                              onChange={(e) => this.groupHandler(e)}
                              options={this.props.GroupList ? this.props.GroupList : []}
                              placeholder={t('advancedFilters.selectGrp')}
                          />
                        </div>

                        <div id="marketHandlerTimeMtrx" className="divPaddingTimeMtrx">
                          <h6><b>{t('advancedFilters.market')} *</b></h6>
                          <Typeahead
                              id="basic-typeahead-single"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="label"
                              selected={this.state.marketSelected}
                              onChange={(e) => this.marketHandler(e)}
                              onInputChange={this.handleMarketInputChange.bind(this)}
                              options={this.state.marketOption ? this.state.marketOption : []}
                              placeholder={t('advancedFilters.selectMkt')}
                          />
                        </div>

                        <div id="vehicleClassHandlerTimeMtrx" className="divPaddingTimeMtrx">
                          <h6><b>{t('advancedFilters.vehicle')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="category"
                              multiple
                              selected={this.state.vehicleSelected}
                              onChange={(e) => this.vehicleHandler(e)}
                              options={this.props.VehicleList ? this.props.VehicleList : []}
                              placeholder={t('timeline.defaultAllPlaceholder')}
                          />
                        </div>

                        <div id="fuelTypeHandlerTimeMtrx" className="divPaddingTimeMtrx">
                          <h6><b>{t('advancedFilters.fuelType')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="type"
                              multiple
                              selected={this.state.vehicleFuelTypeSelected}
                              onChange={(e) => this.vehicleFuelTypeHandler(e)}
                              options={this.props.VehicleFuelTypeList ? this.props.VehicleFuelTypeList : []}
                              placeholder={t('timeline.defaultAllPlaceholder')}
                          />
                        </div>

                        <div id="typeOfDriveHandlerTimeMtrx" className="divPaddingTimeMtrx">
                          <h6><b>{t('advancedFilters.typeOfDrive')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="driveType"
                              multiple
                              selected={this.state.typeOfDriveSelected}
                              onChange={(e) => this.typeOfDriveHandler(e)}
                              options={this.props.DriveTypeList ? this.props.DriveTypeList: []}
                              placeholder={t('timeline.defaultAllPlaceholder')}
                          />
                        </div>
                      </Row>
                      { valiationErrMsg &&
                        <span className="timelineMtrxValidErrCss">{t('timelineMtrxValidErrMsg')}</span>
                      }
                      <Row className="filterBtnRowTimeMtrx">
                        <div id="accordionTwoTimeMtrx">
                          <div id="headingTwoTimeMtrx">
                            <Button id="applyFilterTimeMtrx" className="btn btn-success" onClick={this.applyFilter} disabled={disableBtn}>
                              {disableBtn ? t('fetchingData') : t('advancedFilters.applyFilter')}
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}</Translation>
        )
    }
}

    const mapStateToProps = (appState: AppState) => ({
      MarketList: appState.user.MarketList,
      VehicleList: appState.user.VehicleList,
      DriveTypeList: appState.user.DriveTypeList,
      GroupList: appState.user.GroupList,
      VehicleFuelTypeList: appState.user.VehicleFuelTypeList,
      activeKey : appState.user.activeKey,
      timeMatrixRegFetched: appState.user.timeMatrixRegFetched
    });

    const mapDispatchToProps = dispatch => ({
      getVehicleList: () => Actions.user.getVehicleList(dispatch),
      getMarketList: () => Actions.user.getMarketList(dispatch),
      getGroupList: () => Actions.user.getGroupList(dispatch),
      getVehicleFuelTypeList: () => Actions.user.getVehicleFuelTypeList(dispatch),
      getDriveTypeList: () => Actions.user.getDriveTypeList(dispatch),
      getTimeMatrixList: (filter) => Actions.user.getTimeMatrixList(filter, dispatch)
    });

    export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimelineMatrixFilter));
