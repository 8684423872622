import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import Actions from "../../internal/modules/Actions";
import { Translate } from "../../model/interfaces/translate.interface";
import PageTitle from "../../Layout/AppMain/PageTitle";
import AdminTable from "../Table/AdminTable";
import { FaqColData } from "./FaqColData";
import TranslationService from '../../utils/TranslationService';
import {toast} from 'react-toastify';


interface Props extends Translate {
  FaqList: any;
  getFaqList: () => {};
  errorMessage: string
}

interface State {
  faqList: [];
  editModalFlag: boolean;
  confirmModalFlag: boolean;
  loading: boolean;
  formtype: string;
  errorMessage: string

}

class ManageFaqs extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      faqList: [],
      editModalFlag: false,
      confirmModalFlag: false,
      loading: false,
      formtype: "add",
      errorMessage: null

    };
  }

  componentDidUpdate(prevProps,prevState) {
    if (prevProps.FaqList !== this.props.FaqList) {
      this.setState({
        faqList: this.props.FaqList,
      });
    }
    if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
      const t = TranslationService.t;
      toast['error'](t(this.state.errorMessage), { position: 'top-right' });
    }
  }

  componentDidMount() {
    this.props.getFaqList();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.faqList !== nextProps.FaqList) {
      return {
        FaqList: nextProps.FaqList,
      };
    }
    return {
      loading: nextProps.loading,
      success: nextProps.success,
      errorMessage: nextProps.errorMessage
    }
  }

  //open edit and delete modal.
  showModal(type) {
    if (type === "delete") {
      this.setState({ confirmModalFlag: true, formtype: type });
    } else {
      this.setState({ editModalFlag: true, formtype: type });
    }
  }

  //close edit modal.
  closeModal = () => {
    this.setState({ editModalFlag: false });
  };

  //close delete modal.
  closeConfirmModal = () => {
    this.setState({ confirmModalFlag: false });
  };  

  render() {    
    return (
      <Translation>
        {(t) => (
          <>
            <PageTitle
              {...this.props}
              heading={t("sidebar.manageFaqs")}
              subheading={t("sidebar.manageFaqsSubHeading")}
              buttonText={t("mngFaqs.addFaqslabel")}
              buttonCallback={() => this.showModal("add")}
            />
            <AdminTable
              editTooltip={t("mngFaqs.editTooltip")}
              deleteTooltip={t("mngFaqs.deleteTooltip")}
              formtype={this.state.formtype}
              colData={FaqColData()}
              formName="faqTable"
              tableData={this.props.FaqList}
              onClickDelete={() => this.showModal("delete")}
              onClickEdit={() => this.showModal("edit")}
              confirmModalFlag={this.state.confirmModalFlag}
              editModalFlag={this.state.editModalFlag}
              closeConfirmModal={this.closeConfirmModal}
              closeModalFlag={this.closeModal}
              modalID="faqModal"
            />
          </>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state) => ({
  FaqList: state.user.FaqList,
  success: state.admin.FaqListRequest.$success,
  errorMessage: state.admin.FaqListRequest.$errorMessage
});

const mapDispatchToProps = (dispatch) => ({
  getFaqList: () => Actions.admin.getFaqs(dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ManageFaqs));
