import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap";
import { Translation } from "react-i18next";
import "./RegDetails.css";
import DocumentTableComp from "./DocumentTable";
import DownArrow from "./../../chevron-sign-down.png";
import RightArrow from "./../../chevron-sign-right.png";
import RightIcon from "./../../right-icon.png";
import DownIcon from "./../../down-icon.png";
import mockData from "./../../utils/StaticData.json";
import ReactTooltip from "react-tooltip";
import infoImage from "./../../InfoImage.png";
import Actions from "../../internal/modules/Actions";
import { AppState } from "../../internal/modules/Reducers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import EditModalComp from "../Table/EditModal";
import TranslationService from "../../utils/TranslationService";
import RevisionHistoryTable from "./RevisionHistoryTable";

const t = TranslationService.t;

interface Props {
  rowData?: any;
  getReferenceRegulationDetails: (regulationNumber) => {};
  referenceRegFetched: boolean;
  RefRegulationDetailsList?: any;
  flag?: any;
}

interface State {
  toggleActualDiv: boolean;
  toggleSeriesDiv: boolean;
  //isToggleFutureDiv: boolean;
  showDetailRegulation: boolean;
  isToggleEquiDiv: boolean;
  isToggleRefDiv: boolean;
  isToggleDocumentDiv: boolean;
  open?: any;
  isToggleActualDocument: boolean;
  // isToggleFutureDocument: boolean;
  rowData?: any;
  selectedSeries: any;
  topicTooltip: any;
  actualDocument ?: any;
  topicDocument ?: any;
}

class RefRegDetails extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      toggleActualDiv: false,
      toggleSeriesDiv: false,
      //isToggleFutureDiv: false,
      isToggleDocumentDiv: false,
      showDetailRegulation: false,
      isToggleEquiDiv: false,
      isToggleRefDiv: false,
      open: "",
      isToggleActualDocument: false,
      // isToggleFutureDocument: false,
      rowData: this.props.rowData,
      selectedSeries: {},
      topicTooltip: <></>,
      actualDocument : [],
      topicDocument : []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.referenceRegFetched &&
      prevProps.referenceRegFetched !== this.props.referenceRegFetched
    ) {
      if (this.props.RefRegulationDetailsList.id !== null) {
        this.setState({
          showDetailRegulation: true,
          rowData: this.props.RefRegulationDetailsList,
        });
      } else {
        toast["info"](t("refRegDetailed.infoMsg"), { position: "top-right" });
      }
    }

    if (this.props.rowData && this.props.rowData !== prevProps.rowData) {
      this.setState({ selectedSeries: this.props.rowData.revisionHistory[0] });
    }
  }

  componentDidMount() {
    this.setState({ selectedSeries: this.props.rowData.revisionHistory[0] });
  }

  toggleActual = () => {
    let { toggleActualDiv } = this.state;
    this.setState({ toggleActualDiv: !toggleActualDiv });
    let actualArr = [];
    let topicDocumentArr = [];
    this.props.rowData?.documents?.filter(el => el.category === 'Actual').map((elem) =>{
      actualArr.push(elem)
    })
    this.props.rowData?.documents?.filter(el => el.category === 'Topic').map((elem) =>{
      topicDocumentArr.push(elem)
    })
    this.setState({actualDocument: actualArr, topicDocument: topicDocumentArr});
  };

  // toggleFutureDiv = () => {
  //   let { isToggleFutureDiv } = this.state;
  //   this.setState({ isToggleFutureDiv: !isToggleFutureDiv });
  // };
  toggleEquiDiv = () => {
    let { isToggleEquiDiv } = this.state;
    this.setState({ isToggleEquiDiv: !isToggleEquiDiv });
  };
  toggleRefDiv = () => {
    let { isToggleRefDiv } = this.state;
    this.setState({ isToggleRefDiv: !isToggleRefDiv });
  };
  toggleDocumentDiv = () => {
    let { isToggleDocumentDiv } = this.state;
    this.setState({ isToggleDocumentDiv: !isToggleDocumentDiv });
  };
  toggleAcutalDocumentDiv = () => {
    let { isToggleActualDocument } = this.state;
    this.setState({ isToggleActualDocument: !isToggleActualDocument });
  };
  // toggleFutureDocumentDiv = () => {
  //   let { isToggleFutureDocument } = this.state;
  //   this.setState({ isToggleFutureDocument: !isToggleFutureDocument });
  // };
  toggleSeriesDiv = (row) => {
    let { toggleSeriesDiv } = this.state;
    this.props.rowData.revisionHistory.map((item) => {
      if (item.series === row) {
        this.setState((prevState) => ({
          open: prevState.open === row ? "" : row,
        }));
      }
    });
  };
  refRegulationClick = (e) => {
    e.preventDefault();
    let regNo = e.currentTarget.textContent.replace(/[,]/g, "");
    this.props.getReferenceRegulationDetails(regNo);
  };

  closeModalRegDetail = () => {
    this.setState({ showDetailRegulation: false });
  };
  seriesSelected = (series) => {
    this.setState({ selectedSeries: series });
  };

  render() {
    let { rowData } = this.props;
    let actualStatus,
      futureStatus = "";

    return (
      <Translation>
        {(t) => (
          <Fragment>
            <Row id="refRegDetailsForm">
              <Col>
                {/* Reg Number */}
                <div className="form-inline col-12">
                  <div className="col-sm-2" style={{ padding: "0" }}>
                    <h6 className="labelCss1">
                      <b>{t("advancedFilters.actualRegNo")}:</b>
                    </h6>
                  </div>
                  <div className="col-sm-10" style={{ paddingLeft: "47px" }}>
                    <h6 className="labelCss1">
                      {rowData?.actualRegulationNumber}
                    </h6>
                  </div>
                </div>
                {/* Title */}
                <div
                  className="form-inline col-12"
                  style={{ alignItems: "baseline" }}
                >
                  <div className="col-sm-2" style={{ padding: "0" }}>
                    <h6 className="labelCss1">
                      <b>{t("advancedFilters.title")}:</b>
                    </h6>
                  </div>
                  <div className="col-sm-10" style={{ paddingLeft: "47px" }}>
                    <h6 className="labelCss1">{rowData?.title}</h6>
                  </div>
                </div>
                {/* Short desc */}
                <div className="form-inline col-12">
                  <div className="col-sm-2" style={{ padding: "0" }}>
                    <h6 className="labelCss1">
                      <b>{t("advancedFilters.shortDesc")}:</b>
                    </h6>
                  </div>
                  <div className="col-sm-10" style={{ paddingLeft: "47px" }}>
                    <h6 className="labelCss1">{rowData?.shortDescription}</h6>
                  </div>
                </div>
                {/* Drive type */}
                <div className="form-inline col-12">
                  <div className="col-sm-2" style={{ padding: "0" }}>
                    <h6 className="labelCss1">
                      <b>{t("advancedFilters.typeOfDrive")}:</b>
                    </h6>
                  </div>
                  <div className="col-sm-10" style={{ paddingLeft: "47px" }}>
                    <h6 className="labelCss1">{rowData?.driveTypeNames}</h6>
                  </div>
                </div>
                {/* Vehicle category */}
                <div className="form-inline col-12">
                  <div className="col-sm-2" style={{ padding: "0" }}>
                    <h6 className="labelCss1">
                      <b>{t("advancedFilters.vehicleCategory")}:</b>
                    </h6>
                  </div>
                  <div className="col-sm-10" style={{ paddingLeft: "47px" }}>
                    <h6 className="labelCss1">{rowData.vehicleNames}</h6>
                  </div>
                </div>
                {/* Fuel type */}
                <div className="form-inline col-12">
                  <div className="col-sm-2" style={{ padding: "0" }}>
                    <h6 className="labelCss1">
                      <b>{t("advancedFilters.fuelType")}:</b>
                    </h6>
                  </div>
                  <div className="col-sm-10" style={{ paddingLeft: "47px" }}>
                    <h6 className="labelCss1">{rowData.fuelTypeNames}</h6>
                  </div>
                </div>
                {/* Topic */}
                {this.props.flag !== false && (
                  <div className="form-inline col-12">
                    <div className="col-sm-2" style={{ padding: "0" }}>
                      <h6 className="labelCss1">
                        <b>{t("advancedFilters.topic")}:</b>
                      </h6>
                    </div>
                    <div
                      className="col-sm-10 row"
                      style={{ paddingLeft: "56px" }}
                    >
                      {/* {
                        rowData.topics.map((topic, index) => {
                          if (index === rowData.topics.length - 1) {
                            return (
                              <>
                                <h6
                                  className="labelCss1 topicName"
                                  data-for="topicNameLast"
                                  data-tip=""
                                >
                                  {topic.name}
                                </h6>
                                <ReactTooltip
                                  id="topicNameLast"
                                  place="bottom"
                                  type="info"
                                  effect="float"
                                >
                                  <>
                                    <p className="refRegDetails">
                                      <b>{t("advancedFilters.topicType")}</b>:
                                      &nbsp;{topic?.type}
                                    </p>
                                    <p className="refRegDetails">
                                      <b>{t("advancedFilters.group")}</b> :
                                      &nbsp;{topic?.group?.name}
                                    </p>
                                    <p className="refRegDetails">
                                      <b>{t("advancedFilters.sub-group")}</b> :
                                      &nbsp;{topic?.subGroup?.name}
                                    </p>
                                    <p className="refRegDetails">
                                      <b>{t("advancedFilters.scope")}</b> :
                                      &nbsp;{topic?.scope?.name}
                                    </p>
                                    <p className="refRegDetails">
                                      <b>{t("advancedFilters.subScope")}</b> :
                                      &nbsp;{topic?.subScope?.name}
                                    </p>
                                  </>
                                </ReactTooltip>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <h6
                                  className="labelCss1 topicName"
                                  data-for="topicName"
                                  data-tip=""
                                >
                                  {topic.name},&nbsp;
                                </h6>
                                <ReactTooltip
                                  id="topicName"
                                  place="bottom"
                                  type="info"
                                  effect="float"
                                >
                                  <>
                                    <p className="refRegDetails">
                                      <b>{t("advancedFilters.topicType")}</b> :
                                      &nbsp;{topic?.type}
                                    </p>
                                    <p className="refRegDetails">
                                      <b>{t("advancedFilters.group")}</b> :
                                      &nbsp;{topic?.group?.name}
                                    </p>
                                    <p className="refRegDetails">
                                      <b>{t("advancedFilters.sub-group")}</b> :
                                      &nbsp;{topic?.subGroup?.name}
                                    </p>
                                    <p className="refRegDetails">
                                      <b>{t("advancedFilters.scope")}</b> :
                                      &nbsp;{topic?.scope?.name}
                                    </p>
                                    <p className="refRegDetails">
                                      <b>{t("advancedFilters.subScope")}</b> :
                                      &nbsp;{topic?.subScope?.name}
                                    </p>
                                  </>
                                </ReactTooltip>
                              </>
                            );
                          }
                        })} */}
                      {rowData.topics &&
                        rowData.topics.map((topic, index) => {
                          var tooltipData = (
                            <>
                              <p className="refRegDetails">
                                <b>{t("advancedFilters.topicType")}</b> : &nbsp;
                                {topic?.type}
                              </p>
                              <p className="refRegDetails">
                                <b>{t("mngGroup.Group")}</b> : &nbsp;
                                {topic?.group?.name}
                              </p>
                              <p className="refRegDetails">
                                <b>{t("mngGroup.Sub-group")}</b> : &nbsp;
                                {topic?.subGroup?.name}
                              </p>
                              <p className="refRegDetails">
                                <b>{t("mngScope.Scope")}</b> : &nbsp;
                                {topic?.scope?.name}
                              </p>
                              <p className="refRegDetails">
                                <b>{t("mngScope.Sub-scope")}</b> : &nbsp;
                                {topic?.subScope?.name}
                              </p>
                            </>
                          );
                          const lastIndex = rowData.topics.length - 1;
                          const isLastIndex = index === lastIndex;
                          return (
                            <>
                              <h6
                                className="labelCss1 topicName"
                                data-for="topicName"
                                data-tip=""
                                onMouseOver={() => this.setState({ topicTooltip: tooltipData })}
                              >
                                {topic.name}{isLastIndex ? '' : ','}&nbsp;
                              </h6>
                            </>
                          );
                        })}
                      <ReactTooltip
                        id="topicName"
                        place="bottom"
                        type="info"
                        effect="float"
                      >
                        <>{this.state.topicTooltip}</>
                      </ReactTooltip>
                      {/* <h6 className="labelCss1">{filtered}</h6> */}
                    </div>
                  </div>
                )}

                {/* Actual Regulation  */}
                <div className="form-inline col-sm-12">
                  <div
                    className="col-4 form-inline collapseRow"
                    style={{ padding: 0 }}
                  >
                    <div className="col-7 form-inline" style={{ padding: 0 }}>
                      <h6 className="labelCss1">
                        <b>{t("advancedFilters.actualReg")}</b>
                      </h6>
                    </div>
                    <div className="col-5">
                      <div id={"accordionActualReg"}>
                        <div id={"headingAccordionActualReg"}>
                          <h5 className="mb-0">
                            <button
                              onClick={this.toggleActual}
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target={"#collapseRefRegDetailActualRegCard"}
                              aria-expanded="true"
                              aria-controls={
                                "collapseRefRegDetailActualRegCard"
                              }
                            >
                              {this.state.toggleActualDiv ? (
                                <img
                                  style={{ height: "15px" }}
                                  src={DownArrow}
                                />
                              ) : (
                                <img
                                  style={{ height: "15px" }}
                                  src={RightArrow}
                                />
                              )}
                            </button>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id={"collapseRefRegDetailActualRegCard"}
                  className="card collapse col-sm-12 noPadding"
                  aria-labelledby={"headingAccordionActualReg"}
                  data-parent={"#accordionActualReg"}
                >
                  <div className="card-body" style={{ paddingLeft: "0" }}>
                    {this.state.selectedSeries && (
                      <>
                        <div className="col-sm-12 mb-2" style={{ padding: 0 }}>
                          <div
                            className="row col-sm-12 form-inline"
                            style={{
                              backgroundColor: "white",
                              padding: "1rem 0",
                              marginLeft: "10px",
                              alignItems: "flex-start",
                            }}
                          >
                            <div
                              style={{
                                width: "20%",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                            >
                              <div className={`w-100 seriesSidebarView`}>
                                {Object.keys(
                                  this.state.selectedSeries &&
                                    this.state.selectedSeries
                                )?.length !== 0 &&
                                  rowData.revisionHistory &&
                                  rowData.revisionHistory.map((item) => {
                                    if (
                                      this.state.selectedSeries.series ===
                                      item.series
                                    ) {
                                      return (
                                        <p
                                          className="seriesName selectedSeries"
                                          style={{ margin: 0 }}
                                        >
                                          Series {item.series}
                                        </p>
                                      );
                                    } else {
                                      return (
                                        <p
                                          className="seriesName"
                                          style={{ margin: 0 }}
                                          onClick={() =>
                                            this.seriesSelected(item)
                                          }
                                        >
                                          Series {item.series}
                                        </p>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                            <div
                              style={{
                                minHeight: "15rem",
                                paddingLeft: "18px",
                                paddingRight: "14px",
                                width: "80%",
                              }}
                            >
                              {this.state.selectedSeries &&
                                Object.keys(this.state.selectedSeries)
                                  .length !== 0 && (
                                  <div className="revTableContainer">
                                    <div className="seriesTitleBox">
                                      <h6 className="labelCss1">
                                        <b>
                                          Series{" "}
                                          {this.state.selectedSeries.series}
                                        </b>
                                      </h6>
                                      {/* {
                                                this.props.formtype !== 'view' && (
                                                    <>
                                                    <a data-tip={t('mngRegulations.deleteSeriesTooltip')}data-for="deleteIconSeriesTooltip" style={{marginBottom: '0.3rem'}}>
                                                        <DeleteIcon style={{'cursor':'pointer',height: '0.8rem', margin: '0 6px'}} onClick={() => this.adddeleteSerId(this.state.selectedSeries.series)} />
                                                    </a>
                                                        <ReactTooltip id="deleteIconSeriesTooltip" place="right" type="info" effect="float"/>
                                                    </>
                                                )
                                                } */}
                                    </div>
                                    <div className="revTableBox">
                                      <RevisionHistoryTable
                                        data={this.state.selectedSeries.data}
                                        formType={"viewType"}
                                      />
                                    </div>
                                    <div></div>
                                  </div>
                                )}
                            </div>
                          </div>

                          {/* {this.state.open === item.series ?
                                        <div>
                                            <div>
                                            <div className="col-12 labelCss"><a><b>Series-{item.series}</b></a> <img src={DownIcon} style={{height : '10px',marginLeft: '5px'}} onClick={() => this.toggleSeriesDiv(item.series)}/></div>
                                            </div>
                                            <div className='form-inline col-sm-12' style={{marginTop:'5px', marginBottom: '12px'}}>
                                            <RevisionHistoryTable formType="userView" data={item.data}/>
                                            </div>
                                        </div>
                                    :
                                    <div>
                                    <div className="col-12 labelCss"><a><b>Series-{item.series}</b></a> <img src={RightIcon} style={{height : '10px', marginLeft: '5px'}} onClick={() => this.toggleSeriesDiv(item.series)}/></div><div className="col-6"><span></span></div> 
                                    </div>} */}
                        </div>
                      </>
                    )}

                    {this.state.isToggleActualDocument && (
                      <div style={{ paddingRight: "0" }}>
                        <div className="col-12 labelCss">
                          <a>
                            <b>Document</b>
                          </a>{" "}
                          <img
                            src={DownIcon}
                            style={{ height: "10px" }}
                            onClick={() => this.toggleAcutalDocumentDiv()}
                          />
                        </div>
                        <div
                          className="col-12 form-inline labelCss"
                          style={{ paddingRight: "0" }}
                        >
                          <div className="col-2"></div>
                          <div
                            className="col-10"
                            style={{ paddingLeft: "50px", paddingRight: "0" }}
                          >
                            <DocumentTableComp data={this.state.actualDocument} />
                          </div>
                        </div>
                      </div>
                    )}
                    {!this.state.isToggleActualDocument && (
                      <div>
                        <div className="col-12 labelCss">
                          <a>
                            <b>Document</b>
                          </a>{" "}
                          <img
                            src={RightIcon}
                            style={{ height: "10px" }}
                            onClick={() => this.toggleAcutalDocumentDiv()}
                          />
                        </div>
                        <div className="col-6">
                          <span></span>
                        </div>
                      </div>
                    )}
                    <div
                      className="col-12 form-inline labelCss"
                      style={{
                        padding: "0",
                        marginTop: "5px",
                        alignItems: "baseline",
                      }}
                    >
                      <div className="col-2" style={{ paddingRight: 0 }}>
                        <b>{t("advancedFilters.actualComments")}:</b>
                      </div>
                      <div
                        className="col-10"
                        style={{ paddingLeft: "58px", paddingRight: "0" }}
                      >
                        <span>{rowData?.actualRegulationComments}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Future Regulation */}
                {/* <div className="form-inline col-sm-12">
                  <div
                    className="col-4 form-inline collapseRow"
                    style={{ padding: 0 }}
                  >
                    <div className="col-7 form-inline" style={{ padding: 0 }}>
                      {" "}
                      <h6 className="labelCss1">
                        <b>{t("advancedFilters.futureReg")}</b>
                      </h6>
                    </div>
                    <div className="col-5">
                      <div id={"accordionActualReg"}>
                        <div id={"headingAccordionActualReg"}>
                          <h5 className="mb-0">
                            <button
                              onClick={this.toggleFutureDiv}
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target={"#collapseRefRegDetailFutureRegCard"}
                              aria-expanded="true"
                              aria-controls={
                                "collapseRefRegDetailFutureRegCard"
                              }
                            >
                              {this.state.isToggleFutureDiv ? (
                                <img
                                  style={{ height: "15px" }}
                                  src={DownArrow}
                                />
                              ) : (
                                <img
                                  style={{ height: "15px" }}
                                  src={RightArrow}
                                />
                              )}
                            </button>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id={"collapseRefRegDetailFutureRegCard"}
                  className="card collapse col-sm-12 noPadding"
                  aria-labelledby={"headingAccordionActualReg"}
                  data-parent={"#accordionActualReg"}
                >
                  <div className="card-body" style={{ paddingLeft: "0" }}>
                    <div className="form-inline col-sm-12  labelCss">
                      <div className="col-2" style={{ padding: "0" }}>
                        <b>{t("advancedFilters.futureRegNo")}:</b>
                      </div>
                      <div className="col-10" style={{ paddingLeft: "48px" }}>
                        <span>{rowData?.futureRegulationNumber}</span>
                      </div>
                    </div>
                    <div
                      className="col-12 form-inline labelCss"
                      style={{ padding: "0" }}
                    >
                      <div className="col-2">
                        <b>{t("advancedFilters.documentStatus")}:</b>
                      </div>
                      <div className="col-10" style={{ paddingLeft: "55px" }}>
                        <span>{rowData?.futureStatusOfDocument}</span>
                      </div>
                    </div>
                    <div
                      className="col-12 form-inline labelCss"
                      style={{ padding: "0" }}
                    >
                      <div className="col-2">
                        <b>{t("advancedFilters.status")}:</b>
                      </div>
                      <div className="col-10" style={{ paddingLeft: "54px" }}>
                        <span>{futureStatus}</span>
                      </div>
                    </div>
                    <div
                      className="col-12 form-inline labelCss"
                      style={{ padding: "0" }}
                    >
                      <div className="col-2">
                        <b>{t("advancedFilters.softwareRelTitle")}:</b>
                      </div>
                      <div className="col-10" style={{ paddingLeft: "54px" }}>
                        <span>{rowData?.futureSoftwareRelevant}</span>
                      </div>
                    </div>
                    <div
                      className="col-12 form-inline labelCss"
                      style={{ padding: "0", alignItems: "baseline" }}
                    >
                      <div className="col-2" style={{ paddingLeft: "10px" }}>
                        <b>
                          {t("advancedFilters.futureEnforcementDateAllModel")}:
                        </b>
                      </div>
                      <div className="col-10" style={{ paddingLeft: "54px" }}>
                        <span>{rowData?.futureEnforcementDateAllModel}</span>
                      </div>
                    </div>
                    <div
                      className="col-12 form-inline labelCss"
                      style={{ padding: "0", alignItems: "baseline" }}
                    >
                      <div className="col-2" style={{ paddingLeft: "10px" }}>
                        <b>
                          {t("advancedFilters.futureEnforcementDateNewModel")}:
                        </b>
                      </div>
                      <div className="col-10" style={{ paddingLeft: "54px" }}>
                        <span>{rowData?.futureEnforcementDateNewModel}</span>
                      </div>
                    </div>
                    <div
                      className="col-12 form-inline labelCss"
                      style={{ padding: "0" }}
                    >
                      <div className="col-2">
                        <b>{t("advancedFilters.futureForecastClass")}:</b>
                      </div>
                      <div className="col-10" style={{ paddingLeft: "54px" }}>
                        <span>{rowData?.futureForecastClass}</span>
                      </div>
                    </div>
                    <div
                      className="col-12 form-inline labelCss"
                      style={{ padding: "0" }}
                    >
                      <div className="col-2">
                        <b>{t("advancedFilters.futureComments")}:</b>
                      </div>
                      <div className="col-10" style={{ paddingLeft: "54px" }}>
                        <span>{rowData?.futureComments}</span>
                      </div>
                    </div>
                    {this.state.isToggleFutureDocument && (
                      <div style={{ paddingRight: "0" }}>
                        <div className="col-12 labelCss">
                          <a>
                            <b>Document</b>
                          </a>{" "}
                          <img
                            src={DownIcon}
                            style={{ height: "10px", marginLeft: "5px" }}
                            onClick={() => this.toggleFutureDocumentDiv()}
                          />
                        </div>
                        <div className="col-6">
                          <span></span>
                        </div>
                        <div
                          className="col-12 form-inline labelCss"
                          style={{ paddingRight: "0" }}
                        >
                          <div className="col-2"></div>
                          <div
                            className="col-10"
                            style={{ paddingLeft: "46px", paddingRight: "0" }}
                          >
                            <DocumentTableComp data={rowData.futureData} />
                          </div>
                        </div>{" "}
                      </div>
                    )}
                    {!this.state.isToggleFutureDocument && (
                      <div>
                        <div className="col-12 labelCss">
                          <a>
                            <b>Document</b>
                          </a>{" "}
                          <img
                            src={RightIcon}
                            style={{ height: "10px", marginLeft: "5px" }}
                            onClick={() => this.toggleFutureDocumentDiv()}
                          />
                        </div>
                        <div className="col-6">
                          <span></span>
                        </div>
                      </div>
                    )}
                  </div>
                </div> */}

                {/* Equivalent Regulation  */}
                {rowData.market.label !== "UNECE" && (
                  <div>
                    <div className="form-inline col-sm-12">
                      <div
                        className="col-4 form-inline collapseRow"
                        style={{ padding: 0 }}
                      >
                        <div
                          className="col-7 form-inline"
                          style={{ padding: 0 }}
                        >
                          <h6 className="labelCss1">
                            <b>{t("advancedFilters.equivalentReg")}</b>
                          </h6>
                        </div>
                        <div className="col-5">
                          <div id={"accordionActualReg"}>
                            <div id={"headingAccordionActualReg"}>
                              <h5 className="mb-0">
                                <button
                                  onClick={this.toggleEquiDiv}
                                  className="btn btn-link"
                                  data-toggle="collapse"
                                  data-target={
                                    "#collapseRefRegDetailEquivalentRegCard"
                                  }
                                  aria-expanded="true"
                                  aria-controls={
                                    "collapseRefRegDetailEquivalentRegCard"
                                  }
                                >
                                  {this.state.isToggleEquiDiv ? (
                                    <img
                                      style={{ height: "15px" }}
                                      src={DownArrow}
                                    />
                                  ) : (
                                    <img
                                      style={{ height: "15px" }}
                                      src={RightArrow}
                                    />
                                  )}
                                </button>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id={"collapseRefRegDetailEquivalentRegCard"}
                      className="card collapse col-sm-12 noPadding"
                      aria-labelledby={"headingAccordionActualReg"}
                      data-parent={"#accordionActualReg"}
                    >
                      <div className="card-body" style={{ paddingLeft: "0" }}>
                        <div
                          className="col-12 form-inline labelCss"
                          style={{ padding: "0", alignItems: "baseline" }}
                        >
                          <div
                            className="col-2"
                            style={{ paddingLeft: "10px" }}
                          >
                            <b>{t("advancedFilters.equivalentRegECE")}:</b>
                          </div>
                          <div
                            className="col-10"
                            style={{ paddingLeft: "59px" }}
                          >
                            <span>{rowData?.equivalentECERegulation}</span>
                          </div>
                        </div>
                        <div
                          className="col-12 form-inline labelCss"
                          style={{ padding: "0", alignItems: "baseline" }}
                        >
                          <div
                            className="col-2"
                            style={{ paddingLeft: "10px" }}
                          >
                            <b>{t("advancedFilters.equivalentRegEU27")}:</b>
                          </div>
                          <div
                            className="col-10"
                            style={{ paddingLeft: "59px" }}
                          >
                            <span>{rowData?.equivalentEU27Regulation}</span>
                          </div>
                        </div>
                        <div
                          className="col-12 form-inline labelCss"
                          style={{ padding: "0", alignItems: "baseline" }}
                        >
                          <div
                            className="col-2"
                            style={{ paddingLeft: "10px" }}
                          >
                            <b>{t("advancedFilters.equivalentRegFMVSS")}:</b>
                          </div>
                          <div
                            className="col-10"
                            style={{ paddingLeft: "59px" }}
                          >
                            <span>{rowData?.equivalentEU27Regulation}</span>
                          </div>
                        </div>
                        <div
                          className="col-12 form-inline labelCss"
                          style={{ padding: "0", alignItems: "baseline" }}
                        >
                          <div
                            className="col-2"
                            style={{ paddingLeft: "10px" }}
                          >
                            <b>{t("advancedFilters.equivalentRegOthers")}:</b>
                          </div>
                          <div
                            className="col-10"
                            style={{ paddingLeft: "59px" }}
                          >
                            <span>{rowData?.equivalentRegulationOther}</span>
                          </div>
                        </div>
                        <div
                          className="col-12 form-inline labelCss"
                          style={{ padding: "0", alignItems: "baseline" }}
                        >
                          <div className="col-2">
                            <b>{t("advancedFilters.equivalentRegSource")}:</b>
                          </div>
                          <div
                            className="col-10"
                            style={{ paddingLeft: "59px" }}
                          >
                            <span>{rowData?.equivalentRegulationSource}</span>
                          </div>
                        </div>
                        <div
                          className="col-12 form-inline labelCss"
                          style={{ padding: "0", alignItems: "baseline" }}
                        >
                          <div className="col-2">
                            <b>{t("advancedFilters.equivalentRegComments")}:</b>
                          </div>
                          <div
                            className="col-10"
                            style={{ paddingLeft: "59px" }}
                          >
                            <span>{rowData?.equivalentRegulationComments}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Reference Regulation */}
                <div className="form-inline col-sm-12">
                  <div
                    className="col-4 form-inline collapseRow"
                    style={{ padding: 0 }}
                  >
                    <div className="col-7 form-inline" style={{ padding: 0 }}>
                      <h6 className="labelCss1">
                        <b>{t("advancedFilters.referenceReg")}</b>
                      </h6>
                    </div>
                    <div className="col-5">
                      <div id={"accordionActualReg"}>
                        <div id={"headingAccordionActualReg"}>
                          <h5 className="mb-0">
                            <button
                              onClick={this.toggleRefDiv}
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target={
                                "#collapseRefRegDetailReferenceRegCard"
                              }
                              aria-expanded="true"
                              aria-controls={
                                "collapseRefRegDetailReferenceRegCard"
                              }
                            >
                              {this.state.isToggleRefDiv ? (
                                <img
                                  style={{ height: "15px" }}
                                  src={DownArrow}
                                />
                              ) : (
                                <img
                                  style={{ height: "15px" }}
                                  src={RightArrow}
                                />
                              )}
                            </button>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id={"collapseRefRegDetailReferenceRegCard"}
                  className="card collapse col-sm-12 noPadding"
                  style={{ paddingLeft: "0" }}
                  aria-labelledby={"headingAccordionActualReg"}
                  data-parent={"#accordionActualReg"}
                >
                  <div className="card-body" style={{ paddingLeft: "0" }}>
                    <div
                      className="col-12 form-inline labelCss"
                      style={{
                        paddingLeft: 0,
                        paddingBottom: "10px",
                        alignItems: "flex-start",
                      }}
                    >
                      <div className="col-2">
                        <b>{t("advancedFilters.directlyAffects")}:</b>
                      </div>
                      <div className="col-10" style={{ paddingLeft: "59px" }}>
                        <span className="form-inline">
                        
                            <a
                              data-tip={t("mngRegulations.referenceDirectlyAffectsTooltip")}
                              data-for="resendMailHandler"
                            >
                              <img
                                src={infoImage}
                                style={{ cursor: "pointer" }}
                                height="15px"
                                alt="Info"
                              />
                            </a>
                            <ReactTooltip
                              id="resendMailHandler"
                              place="left"
                              type="info"
                              effect="float"
                            />
                        
                        
                        {rowData?.referenceDirectlyAffectsNames ? (
                          <div className="col-11">
                            {rowData.referenceDirectlyAffects.map((item, index) => (
                              <span key={item.regulationNumber}>
                                {index > 0 && ","} {item.regulationNumber}
                              </span>
                            ))}
                          </div>
                        ) : (
                          <span></span>
                        )}
                      </span>
                      </div>
                    </div>
                    <div
                      className="col-12 form-inline labelCss"
                      style={{
                        paddingLeft: 0,
                        paddingBottom: "10px",
                        alignItems: "flex-start",
                      }}
                    >
                      <div className="col-2">
                        <b>{t("advancedFilters.affectedBy")}:</b>
                      </div>
                      <div className="col-10" style={{ paddingLeft: "59px" }}>
                        <span className="form-inline">
                          
                            <div>
                              <a
                                data-tip={t("mngRegulations.referenceAffectedByTooltip")}
                                data-for="resendMailHandler"
                              >
                                <img
                                  src={infoImage}
                                  style={{ cursor: "pointer" }}
                                  height="15px"
                                />
                              </a>
                              <ReactTooltip
                                id="resendMailHandler"
                                place="left"
                                type="info"
                                effect="float"
                              />
                            </div>
                          
                          {rowData.referenceAffectedByNames &&
                          rowData.referenceAffectedByNames !== "" ? (
                            <div className="col-11">
                              {rowData["referenceAffectedBy"].map(
                                (item, index) => (
                                  <span>
                                    {(index ? "," : "") + item.regulationNumber}
                                  </span>
                                )
                              )}
                            </div>
                          ) : (
                            <span>{""}</span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      className="col-12 form-inline labelCss"
                      style={{
                        paddingLeft: 0,
                        paddingBottom: "10px",
                        alignItems: "flex-start",
                      }}
                    >
                      <div className="col-2">
                        <b>{t("advancedFilters.references")}:</b>
                      </div>
                      <div className="col-10" style={{ paddingLeft: "59px" }}>
                        <span className="form-inline">
                          
                            <div>
                              <a
                                data-tip={t("mngRegulations.referencesTooltip")}
                                data-for="resendMailHandler"
                              >
                                <img
                                  src={infoImage}
                                  style={{ cursor: "pointer" }}
                                  height="15px"
                                />
                              </a>
                              <ReactTooltip
                                id="resendMailHandler"
                                place="left"
                                type="info"
                                effect="float"
                              />
                            </div>
                          
                          {rowData.referenceNames &&
                          rowData.referenceNames !== "" ? (
                            <div className="col-11">
                              {rowData["references"].map((item, index) => (
                                <span>
                                  {(index ? "," : "") + item.regulationNumber}
                                </span>
                              ))}
                            </div>
                          ) : (
                            <span>{""}</span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      className="col-12 form-inline labelCss"
                      style={{
                        paddingLeft: 0,
                        paddingBottom: "10px",
                        alignItems: "flex-start",
                      }}
                    >
                      <div className="col-2">
                        <b>{t("advancedFilters.mentionedExpAnnex")}:</b>
                      </div>
                      <div className="col-10" style={{ paddingLeft: "59px" }}>
                        <span className="form-inline">
                          
                            <div>
                              <a
                                data-tip={t("mngRegulations.referencementionedExpAnnexTooltip")}
                                data-for="resendMailHandler"
                              >
                                <img
                                  src={infoImage}
                                  style={{ cursor: "pointer" }}
                                  height="15px"
                                />
                              </a>
                              <ReactTooltip
                                id="resendMailHandler"
                                place="left"
                                type="info"
                                effect="float"
                              />
                            </div>
                          
                          {rowData.referenceMentionedExampleAnnexNames &&
                          rowData.referenceMentionedExampleAnnexNames !== "" ? (
                            <div className="col-11">
                              {rowData["referenceMentionedExampleAnnex"].map(
                                (item, index) => (
                                  <span>
                                    {(index ? "," : "") + item.regulationNumber}
                                  </span>
                                )
                              )}
                            </div>
                          ) : (
                            <span>{""}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Document */}
                <div className="form-inline col-sm-12">
                  <div
                    className="col-4 form-inline collapseRow"
                    style={{ padding: 0 }}
                  >
                    <div className="col-7 form-inline" style={{ padding: 0 }}>
                      <h6 className="labelCss1">
                        <b>{t("advancedFilters.document")}</b>
                      </h6>
                    </div>
                    <div className="col-5">
                      <div id={"accordionActualReg"}>
                        <div id={"headingAccordionActualReg"}>
                          <h5 className="mb-0">
                            <button
                              onClick={this.toggleDocumentDiv}
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target={"#collapseRefRegDetailDocumentCard"}
                              aria-expanded="true"
                              aria-controls={"collapseRefRegDetailDocumentCard"}
                            >
                              {this.state.isToggleDocumentDiv ? (
                                <img
                                  style={{ height: "15px" }}
                                  src={DownArrow}
                                />
                              ) : (
                                <img
                                  style={{ height: "15px" }}
                                  src={RightArrow}
                                />
                              )}
                            </button>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id={"collapseRefRegDetailDocumentCard"}
                  style={{ marginBottom: "15px" }}
                  className="card collapse col-sm-12 noPadding"
                  aria-labelledby={"headingAccordionActualReg"}
                  data-parent={"#accordionActualReg"}
                >
                  <div className="card-body" style={{ paddingRight: "11px" }}>
                    <div
                      className="col-12 labelCss"
                      style={{ padding: "10px", paddingRight: "19px" }}
                    >
                      <DocumentTableComp data={this.state.topicDocument} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Fragment>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({
  referenceRegFetched: appState.user.referenceRegFetched,
  RefRegulationDetailsList: appState.user.RefRegulationDetailsList,
});

const mapDispatchToProps = (dispatch) => ({
  getReferenceRegulationDetails: (regulationNumber) =>
    Actions.user.getReferenceRegulationDetails(regulationNumber, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RefRegDetails));
