import React, { Component, useState }  from 'react';
import i18next from 'i18next';
import moment from 'moment';
import $ from 'jquery';

export const RegulationsColDataMoreLessEvents = (row, attr) => {
  $(document).ready(function(){
      $(`tr`).hover(function(){
      $(this).find(`span[id=${row.id+'toggle'+attr}]`).removeClass('truncateCss');
      }, function(){
      $(this).find(`span[id=${row.id+'toggle'+attr}]`).addClass('truncateCss readMoreCss');
    });
  });
};

//Custom dataField component to hide/show table cell data
const RenderDataComponent = ({row, id, title}) => {
  const [hovered, setHovered] = useState(false)
  return (
      <span
        id={row.id+id}
        className={hovered ? 'readMoreCss' : 'truncateCss readMoreCss'}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        >
        {title}
      </span>
  )
}

//Manage GRM table columns
export const RegulationsColData = () => {

  return [
    {
        dataField: 'rowId',
        isKey: true,
        label: i18next.t('id'),
        hidden: true,
        placeholder: i18next.t('idPlaceholder')
    },
    {
        isKey: false,
        label: 'Action',
        hidden: false
    },
    {
        dataField: 'name',
        isKey: false,
        label: i18next.t('table.topic'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'togglename'} title={row.data && row.data[localStorage.getItem('i18nextLng')]?.name} />
        },
        hidden: false,
        placeholder: i18next.t('topicPlaceholder')
    },
    {
        dataField: 'type',
        isKey: false,
        label: i18next.t('table.topicType'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'toggletype'} title={i18next.t(row.data && row.data[localStorage.getItem('i18nextLng')]?.type)} />
        },
        hidden: false,
        placeholder: i18next.t('topicTypePlaceholder')
    },
    {
        dataField: 'parentTopicName',
        isKey: false,
        label: i18next.t('table.correspondingSystem'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'toggleparentTopicName'} title={row.data && row.data[localStorage.getItem('i18nextLng')]?.parentTopicName}/>
        },
        hidden: false,
        placeholder: i18next.t('correspondingSystemPlaceholder')
    },
    {
        dataField: 'regulationNumbers',
        isKey: false,
        label: i18next.t('table.regulationNo'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'toggleregulationNumbers'} title= {row?.data && row?.data[localStorage.getItem('i18nextLng')]?.regulationNumbers} />
        },
        hidden: false,
        placeholder: i18next.t('regNoPlaceholder')
    },
    {
        dataField: 'scope',
        isKey: false,
        label: i18next.t('table.scope'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'togglescope'} title={row.data && row?.data[localStorage.getItem('i18nextLng')]?.scope?.name} />
        },
        hidden: false,
        placeholder: i18next.t('scopePlaceholder')
    },
    {
        dataField: 'subScope',
        isKey: false,
        label: i18next.t('table.subScope'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'togglesubScope'} title={row.data && row?.data[localStorage.getItem('i18nextLng')]?.subScope?.name} />
        },
        hidden: false,
        placeholder: i18next.t('subscopePlaceholder')
    },
    {
        dataField: 'group',
        isKey: false,
        label: i18next.t('table.group'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'togglegroup'} title={row.data && row?.data[localStorage.getItem('i18nextLng')]?.group?.name} />
        },
        hidden: false,
        placeholder: i18next.t('table.groupPlaceholder')
    },
    {
        dataField: 'subGroup',
        isKey: false,
        label: i18next.t('table.subGroup'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'togglesubGroup'} title={row.data && row?.data[localStorage.getItem('i18nextLng')]?.subGroup?.name} />
        },
        hidden: false,
        placeholder: i18next.t('table.subGroupPlaceholder')
    }
  ]
}

export const countryLocale = {
    en:"en-US",
    de:"de"
};

//Forecast class dropdown lang specific values
export const forecastClassArr = {
  en:[
    {
      id:0,
      futureForecastClass: ''
    },
    {
      id:1,
      futureForecastClass:'Secure'
    },
    {
      id:2,
      futureForecastClass:'Predictable'
    }
  ],
  de:[
    {
      id:0,
      futureForecastClass: ''
    },
    {
      id:1,
      futureForecastClass:'Sicher'
    },
    {
      id:2,
      futureForecastClass:'Predictable'
    }
  ]
};

export const typeOfDriveArr = [
  {
    id:1,
    en:{
      typeOfDrive:'All'
    },
    de:{
      typeOfDrive:'Alle'
    }
  }
];

export const languageArr = [
  {
    id:1,
    code:"en"
  },
  {
    id:2,
    code:"de"
  }
];

//GRM post request obj JSON. 2 post objs are created(one for english and one for german) for each market
export const postObjGrm = (marketData, rowData, market, lang, allLanguageTags, marketRemoved) =>{
  let postObj = marketData.regulations[market.value][lang];
  let obj = {};
  //Send ID in request object if editing regulations
  if (postObj.id) {
    obj['id'] = postObj.id;
  }
  obj['languageCode'] = lang;
  obj['market'] = {id:market.id, value: market.value};
  obj['topic'] = {id:rowData.id, languageCode:lang};
  obj['driveTypes'] = postObj.driveTypes?.length > 0 ? postObj.driveTypes: [{id:0, languageCode:lang}];
  obj['title'] = postObj.title ? postObj.title : '';
  obj['shortDescription'] = postObj.shortDescription ? postObj.shortDescription: '';
  obj['linkToAuthority'] = postObj.linkToAuthority ? postObj.linkToAuthority : '';
  obj['tags'] = postObj.tags ? postObj.tags : '';
  obj['linkToAdditionalInfo'] = postObj.linkToAdditionalInfo ? postObj.linkToAdditionalInfo : '';
  // obj['status'] = postObj.statusID ? postObj.statusID : null;
  obj['vehicles'] = postObj.vehicles?.length > 0 ? postObj.vehicles : [{id:0, languageCode:lang}];
  obj['actualRegulationNumber'] = postObj.actualRegulationNumber ? postObj.actualRegulationNumber : '';
  // obj['actualRevision'] = postObj.actualRevision ? postObj.actualRevision : '';
  // obj['actualStatus'] = postObj.actualStatusID ? postObj.actualStatusID : 0;
  // obj['actualLinkToRevision'] = postObj.actualLinkToRevision ? postObj.actualLinkToRevision : '';
  // obj['actualAmendment'] = postObj.actualAmendment ? postObj.actualAmendment : '';
  // obj['actualLinkTOAmendment'] = postObj.actualLinkTOAmendment ? postObj.actualLinkTOAmendment : '';
  // obj['actualEnforcementDateAllModel'] = '';
  // obj['actualEnforcementDateNewModel'] = '';
  // obj['actualExpirationDate'] = '';
  // obj['actualLastChange'] = '';
  obj['actualRegulationComments'] = postObj.actualRegulationComments ? postObj.actualRegulationComments : '';
  // obj['futureEnforcementDateAllModel'] = '';
  // obj['futureEnforcementDateNewModel'] = '';
  // obj['actualSoftwareRelevant'] =  obj['actualSoftwareRelevant'] = postObj.actualSoftwareRelevant?.length > 0 ? postObj.actualSoftwareRelevant : '' ;
  // obj['futureSoftwareRelevant'] =  obj['futureSoftwareRelevant'] = postObj.futureSoftwareRelevant?.length > 0 ? postObj.futureSoftwareRelevant : '' ;
  // obj['actualStatusOfDocument'] = postObj.actualStatusOfDocument ? postObj.actualStatusOfDocument : '';
  // obj['futureStatusOfDocument'] = postObj.futureStatusOfDocument ? postObj.futureStatusOfDocument : '';
  // obj['futureAddendum'] = postObj.futureAddendum ? postObj.futureAddendum : '';
  // obj['actualAddendum'] = postObj.actualAddendum ? postObj.actualAddendum : '';

  var datePattern =/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
  // if (postObj.actualEnforcementDateAllModel !== '' && postObj.actualEnforcementDateAllModel !== null &&
  //   postObj.actualEnforcementDateAllModel !== undefined) {
  //   obj['actualEnforcementDateAllModel'] = datePattern.test(postObj.actualEnforcementDateAllModel) ? postObj.actualEnforcementDateAllModel: moment(postObj.actualEnforcementDateAllModel).format('DD/MM/YYYY');
  // }
  // if (postObj.actualEnforcementDateNewModel !== '' && postObj.actualEnforcementDateNewModel !== null &&
  //   postObj.actualEnforcementDateNewModel !== undefined) {
  //   obj['actualEnforcementDateNewModel'] = datePattern.test(postObj.actualEnforcementDateNewModel) ? postObj.actualEnforcementDateNewModel: moment(postObj.actualEnforcementDateNewModel).format('DD/MM/YYYY');
  // }
  // if (postObj.actualExpirationDate !== '' && postObj.actualExpirationDate !== null &&
  //   postObj.actualExpirationDate !== undefined) {
  //   obj['actualExpirationDate'] = datePattern.test(postObj.actualExpirationDate) ? postObj.actualExpirationDate: moment(postObj.actualExpirationDate).format('DD/MM/YYYY');
  // }
  // if (postObj.actualLastChange !== '' && postObj.actualLastChange !== null &&
  //   postObj.actualLastChange !== undefined) {
  //   obj['actualLastChange'] = datePattern.test(postObj.actualLastChange) ? postObj.actualLastChange: moment(postObj.actualLastChange).format('DD/MM/YYYY');
  // }
  // if (postObj.futureEnforcementDateAllModel !== '' && postObj.futureEnforcementDateAllModel !== null &&
  //   postObj.futureEnforcementDateAllModel !== undefined) {
  //   obj['futureEnforcementDateAllModel'] = datePattern.test(postObj.futureEnforcementDateAllModel) ? postObj.futureEnforcementDateAllModel: moment(postObj.futureEnforcementDateAllModel).format('DD/MM/YYYY');
  // }
  // if (postObj.futureEnforcementDateNewModel !== '' && postObj.futureEnforcementDateNewModel !== null &&
  //   postObj.futureEnforcementDateNewModel !== undefined) {
  //   obj['futureEnforcementDateNewModel'] = datePattern.test(postObj.futureEnforcementDateNewModel) ? postObj.futureEnforcementDateNewModel: moment(postObj.futureEnforcementDateNewModel).format('DD/MM/YYYY');
  // }
  // obj['futureRegulationNumber'] = postObj.futureRegulationNumber ? postObj.futureRegulationNumber : '';
  // obj['futureStatus'] = postObj.futureStatusID ? postObj.futureStatusID : 0;
  // obj['futureRevision'] = postObj.futureRevision ? postObj.futureRevision : '';
  // obj['futureAmendment'] = postObj.futureAmendment ? postObj.futureAmendment : '';
  // obj['futureForecastClass'] = postObj.futureForecastClass ? postObj.futureForecastClass : '';
  // obj['futureComments'] = postObj.futureComments ? postObj.futureComments : '';
  // obj['futureLinkToRevision'] = postObj.futureLinkToRevision ? postObj.futureLinkToRevision : '';
  // obj['futureLinkTOAmendment'] = postObj.futureLinkTOAmendment ? postObj.futureLinkTOAmendment : '';
  obj['equivalentECERegulation'] = postObj.equivalentECERegulation ? postObj.equivalentECERegulation : '';
  obj['equivalentEU27Regulation'] = postObj.equivalentEU27Regulation ? postObj.equivalentEU27Regulation : '';
  obj['equivalentFMVSSRegulation'] = postObj.equivalentFMVSSRegulation ? postObj.equivalentFMVSSRegulation : '';
  obj['equivalentRegulationOther'] = postObj.equivalentRegulationOther ? postObj.equivalentRegulationOther : '';
  obj['equivalentRegulationSource'] = postObj.equivalentRegulationSource ? postObj.equivalentRegulationSource : '';
  obj['equivalentRegulationComments'] = postObj.equivalentRegulationComments ? postObj.equivalentRegulationComments : '';
  obj['referenceDirectlyAffects'] = postObj.referenceDirectlyAffects ? postObj.referenceDirectlyAffects : [];
  obj['referenceAffectedBy'] = postObj.referenceAffectedBy ? postObj.referenceAffectedBy : [];
  obj['references'] = postObj.references ? postObj.references : [];
  obj['referenceMentionedExampleAnnex'] = postObj.referenceMentionedExampleAnnex ? postObj.referenceMentionedExampleAnnex : [];
  obj['allLanguageTags'] = allLanguageTags[market.value]['allLanguageTags'].slice(0, -1) !== '' ? allLanguageTags[market.value]['allLanguageTags'].slice(0, -1) : '';
  obj['fuelTypes'] = postObj.fuelTypes?.length > 0 ? postObj.fuelTypes : [{id:0, languageCode:lang}];
  if (marketRemoved === 'removed') {
    obj['removed'] = null;
  }
  return obj;
}

export const softRelOption = {
  en:[{
    id:1,
    name: 'Yes'
  },{
    id:2,
    name: 'No'
  }],
  de:[{
    id:1,
    name: 'Ja'
  },{
    id:2,
    name: 'Nein'
  }]
}
