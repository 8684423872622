import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import { Button, Row, Col } from 'reactstrap';
import './MarketForm.css';
import Actions from './../../internal/modules/Actions';
import i18next from 'i18next';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import './MarketProfile.css';
import { languageArr } from '../ManageDriveType/DriveTypeColData';
import DownArrow from './../../chevron-sign-down.png';
import RightArrow from './../../chevron-sign-right.png';
import AdminDocumentTableComp from './../Table/AdminDocumentTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import EditModalComp from './../Table/EditModal';
import DocumentForm from '../ManageRegulations/DocumentForm';
import DeleteModalComp from './../Table/DeleteModal';
import {toast} from 'react-toastify';
import {GET_FILE_MARKET_PROFILE} from './../../utils/Constants';
import FileSaver from 'file-saver';
import {DocumentInput} from './DocumentInput';
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';

  export const continentArr = {
    en:[{
      "AS": "Asia",
      "SA": "South America",
      "NA": "North America",
      "AF": "Africa",
      "EU": "Europe",
      "OC": "Oceania",
    }],
    de:[{
      "AS": "Asien",
      "SA": "Südamerika",
      "NA": "Nordamerika",
      "AF": "Afrika",
      "EU": "Europa",
      "OC": "Ozeanien",
    }]
  };
  
interface Props extends Translate {
    rowData?: any,
    formtype?: any;
    eventHandler?: any;
    closeModal?: any;
    onSaveFormHandler?: any;
    options: string,
    createMarket: (any) => {},
    MarketList?: any;
    iconCSS?: any;
    rowIDAdd?: any;
    RoadConditionList?: any;
    AvgTempList?: any;
    AirConditionList?: any;
    EnvConditionList?: any;
    CertificationList?: any;
    HumidityList?: any;
    HandDriveList?: any;
    createMarketProfile: (marketProfile,isFileAdded) => {};
    marketData?: any;
    marketProfileData?: any; 
    uploadMarketProfileFiles: (files, filename) => {};
    editMarketProfileFiles: (files, id, filename) => {};
    deleteMarketProfileFiles: (id, filename) => {}; 
    marketProfileAdded: boolean;
    marketProfileFileUploaded: boolean;
    MarketProfileList ?: any;
    marketProfileID?: any;
    editMarketProfile: (marketProfile,isFileAdded) => {};
    deleteMarketProfile: (marketId,marketProfileId) => {};
    RegulationCountList?: any;
    loading?: any;
}

interface State {
    standards?: any,
    nrOfElements?: any,
    standardsData?: any,
    standardsLink?: any,
    isoState?: any,
    rowData?: any,
    authorities?: any,
    ECEbasedEN?: any,
    ECEbasedDE?: any,
    futherInfoEN?: any,
    futherInfoDE?: any,
    authoritiesData?: any,
    authoritiesLink?: any,
    rowIDArr?: any,
    RoadConditionList?: any,
    AvgTempList?: any,
    AirConditionList?: any,
    EnvConditionList?: any,
    CertificationList?: any,
    HumidityList?: any,
    HandDriveList?: any,
    fieldMsg?: any;
    market?: any;
    toggleIconDocuments?: any;
    deleteRowIdArr ?: any;
    confirmModalFlag?: any;
    docModalFlag?: any;
    allDocs?: any;
    toggleIconComments?: any;
    selectedDoc?: any;
    filesDataArr?: any;
    docRowData?: any;
    originalIdArr?: any;
    deleteDocIdArr?: any;
    allFilesObj?: any;
    documentIdArr?: any;
    docRow?: any;
    deleteProfile ?: any;
    regCount ?: any;
    uploadErrorMessage: string;
    editErrorMessage: string;
    deleteErrorMessage: string;
    stdLink?: any;
    authLinkKey?: any;
    authKey?: any;
    stdLinkKey?: any;
}
let totalLength = 0;
let newFilesAddedLength = 0;
class MarketProfileForm extends Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            standards: [],
            authorities: [],
            nrOfElements: 0,
            standardsData : {},
            standardsLink : {},
            rowData: {},
            ECEbasedEN : '',
            ECEbasedDE : '',
            futherInfoEN : '',
            futherInfoDE : '',
            authoritiesData : {},
            authoritiesLink : {},
            rowIDArr : [],
            RoadConditionList : [],
            AvgTempList : [],
            AirConditionList : [],
            EnvConditionList :  [],
            CertificationList : [],
            HumidityList : [],
            HandDriveList : [],
            fieldMsg:{
              iso:'',
              officialLang:'',
              documentLang:'',
              economicRegion:'',
              handDrive:'',
              certification:'',
              standardsMsg:'',
              authoritiesMsg: '',
              ECE_based: '',
              stdName: '',
              authName: ''
            },
            market : {},
            toggleIconDocuments: false,
            deleteRowIdArr: [],
            confirmModalFlag: false,
            docModalFlag: false,
            allDocs : {},
            toggleIconComments : false,
            selectedDoc : null,
            filesDataArr: {documentList:[]},
            docRowData :[],
            originalIdArr:[],
            deleteDocIdArr:[],
            allFilesObj:{},
            documentIdArr:[],
            docRow : null,
            deleteProfile : false,
            regCount : null,
            uploadErrorMessage: null,
            editErrorMessage: null,
            deleteErrorMessage: null,
            stdLink: [],
            authLinkKey: [],
            authKey: [],
            stdLinkKey: []
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
      return {
        uploadErrorMessage: nextProps.uploadErrorMessage,
        editErrorMessage: nextProps.editErrorMessage,
        deleteErrorMessage: nextProps.deleteErrorMessage
      };
    }
 
    componentDidMount() {
        this.setAllDocumentID();
        let roadConditionArr = {};
        let avgTempArr = {};
        let handDriveArr = {};
        let airConditionArr = {};
        let humidityArr = {};
        let envConditionArr = {};
        let certificationArr = {};
        let docObj = {};
        let {rowData, authoritiesData, standardsData, standardsLink,authoritiesLink} = this.state;
        [...languageArr].map(lang =>{
            if (!rowData['data']) {
              rowData['data'] = {};
            }
            if (!standardsData['data']) {
              standardsData['data'] = {};
            }
            if (!standardsData['data'][lang.code]) {
              standardsData['data'][lang.code] = {};
            }
            if (!standardsLink['data']) {
              standardsLink['data'] = {};
            }
            if (!standardsLink['data'][lang.code]) {
              standardsLink['data'][lang.code] = {};
            }
            if (!authoritiesLink['data']) {
              authoritiesLink['data'] = {};
            }
            if (!authoritiesLink['data'][lang.code]) {
              authoritiesLink['data'][lang.code] = {};
            }
            if (!authoritiesData['data']) {
              authoritiesData['data'] = {};
            }
            if (!authoritiesData['data'][lang.code]) {
              authoritiesData['data'][lang.code] = {};
            }
            if (!rowData['data'][lang.code]) {
              rowData['data'][lang.code] = {};
            }
            if (!rowData['data'][lang.code]) {
              rowData['data'][lang.code] = {airCondition : [], humidity: [] , envCondition: []};
            }
          });
        
        [...languageArr].filter((lang) => {
          roadConditionArr[lang.code] = [];
          avgTempArr[lang.code] = [];
          handDriveArr[lang.code] = [];
          airConditionArr[lang.code] = [];
          humidityArr[lang.code] = [];
          envConditionArr[lang.code] = [];
          certificationArr[lang.code] = [];

         this.props.RoadConditionList && [...this.props.RoadConditionList].map(item =>{
            let obj = {};
            obj['id'] = item.id;
            obj['languageCode'] = lang.code;
            obj['label'] = item.data[lang.code].label;
            roadConditionArr[lang.code].push(obj);
          });
          this.props.AvgTempList && [...this.props.AvgTempList].map(item =>{
            let obj = {};
            obj['id'] = item.id;
            obj['languageCode'] = lang.code;
            obj['label'] = item.data[lang.code].label;
            avgTempArr[lang.code].push(obj);
          });
          this.props.HandDriveList && [...this.props.HandDriveList].map(item =>{
            let obj = {};
            obj['id'] = item.id;
            obj['languageCode'] = lang.code;
            obj['label'] = item.data[lang.code].label;
            handDriveArr[lang.code] && handDriveArr[lang.code].push(obj);
          });
          this.props.AirConditionList && [...this.props.AirConditionList].map(item =>{
            let obj = {};
            obj['id'] = item.id;
            obj['languageCode'] = lang.code;
            obj['label'] = item.data[lang.code].label;
            airConditionArr[lang.code] && airConditionArr[lang.code].push(obj);
          });
          this.props.HumidityList && [...this.props.HumidityList].map(item =>{
            let obj = {};
            obj['id'] = item.id;
            obj['languageCode'] = lang.code;
            obj['label'] = item.data[lang.code].label;
            humidityArr[lang.code] && humidityArr[lang.code].push(obj);
          });
          this.props.EnvConditionList && [...this.props.EnvConditionList].map(item =>{
            let obj = {};
            obj['id'] = item.id;
            obj['languageCode'] = lang.code;
            obj['label'] = item.data[lang.code].label;
            envConditionArr[lang.code] && envConditionArr[lang.code].push(obj);
          });
          this.props.CertificationList && [...this.props.CertificationList].map(item =>{
            let obj = {};
            obj['id'] = item.id;
            obj['languageCode'] = lang.code;
            obj['label'] = item.data[lang.code].label;
            certificationArr[lang.code] && certificationArr[lang.code].push(obj);
          });
         });
      
         if(this.props.marketData !== null){
          [...languageArr].filter((lang) => {
             continentArr[lang.code].map((item) =>{
                Object.entries(item).map(([key,val]) =>{
                  if(key === this.props.marketData.continent){
                    rowData['data'][lang.code]['continent'] = val
                  }
                })
               this.setState(rowData)
              })
            })
            this.setState({market: this.props.marketData})
          }
        this.setState({RoadConditionList: roadConditionArr ,AvgTempList: avgTempArr, HandDriveList: handDriveArr, AirConditionList: airConditionArr, HumidityList: humidityArr, EnvConditionList: envConditionArr, CertificationList:certificationArr,rowData});
     }
    componentDidUpdate(prevProps, prevState) {
      if(this.state.uploadErrorMessage && this.state.uploadErrorMessage !== prevState.uploadErrorMessage){
        toast['error'](this.state.uploadErrorMessage, { position: 'top-right' });
      }
      if(this.state.editErrorMessage && this.state.editErrorMessage !== prevState.editErrorMessage){
        toast['error'](this.state.editErrorMessage, { position: 'top-right' });
      }
      if(this.state.deleteErrorMessage && this.state.deleteErrorMessage !== prevState.deleteErrorMessage){
        toast['error'](this.state.deleteErrorMessage, { position: 'top-right' });
      }
      if(this.props.marketProfileAdded && prevProps.marketProfileAdded !== this.props.marketProfileAdded){
        //Calculating douments length to see if any document need to be uploaded else close GRM modal
        let documentListLength = this.state.filesDataArr.documentList.length + this.state.deleteDocIdArr.length;
        //documentListLength < 1 indicates no document is added/edited/deleted from the table.In this case close the modal
        if (documentListLength < 1) {
          if(this.props.formtype === 'add'){
            toast['success'](i18next.t('marketProfile.addMarketProfileSuccess'), { position: 'top-right' });
            this.props.closeModal();
          }
          else{
            toast['success'](i18next.t('marketProfile.editMarketProfileSuccess'), { position: 'top-right' });
            this.props.closeModal();
          }
         }
        if(!this.props.marketProfileFileUploaded && prevProps.marketProfileFileUploaded !== this.props.marketProfileFileUploaded){
          if(this.props.formtype === 'add'){
            const marketProfile = this.props.MarketProfileList && this.props.MarketProfileList.map(item => item);
            const marketProfileId = marketProfile[0]?.map(el => el.id)
              this.docApiHandler(marketProfileId[0])
          }
          else{
            let id = this.state.rowData && this.state.rowData['data'] && this.state.rowData['data']['en'].id
            this.docApiHandler(id)
          }
        }
      }
      if(this.props.RegulationCountList && prevProps.RegulationCountList !== this.props.RegulationCountList){
        this.props.RegulationCountList && this.props.RegulationCountList.map(regCount =>{
          if(regCount.market === this.props.marketData.label){
            this.setState({regCount: regCount.regulationCount})
          }
        })
      }
      if(this.props.MarketProfileList && prevProps.MarketProfileList !== this.props.MarketProfileList){
        let {rowData,authoritiesData,standardsData, standardsLink, authoritiesLink} = this.state;
        let docObj = {}
        this.props.MarketProfileList && this.props.MarketProfileList.map(item =>{
          [...languageArr].filter((lang) => {
            if(item.languageCode === lang.code){
              rowData['data'][lang.code] = item;
              docObj[lang.code] = item.documents;
              authoritiesData['data'][lang.code]['authorities'] = item.authorities;
              standardsData['data'][lang.code]['standards'] = item.standards;
              standardsLink['data'][lang.code]['standardsLinkUrl'] = item.standardsLinkUrl;
              authoritiesLink['data'][lang.code]['authoritiesLinkUrl'] = item.authoritiesLinkUrl;
                if(item.authorities !== null){
                  let authorities = item.authorities
                  let authoritiesItem = authorities.split(',')
                  let authUrl =  item.authoritiesLinkUrl
                  let authoritiesLinkItem = item.authoritiesLinkUrl !== null ? authUrl.split(',') : ''
                  let data = []; 
                  // remove input if values are empty for both authorities and authorities link
                  authoritiesItem && authoritiesItem.forEach((element, i) => {
                    if((element === '') && (authoritiesLinkItem[i] === '')){
                      authoritiesLinkItem.splice(i,1)
                      authoritiesItem.splice(i,1)
                    }
                  });
                  //adding input fields for authorities on edit
                  for(let i = 0 ; i < authoritiesItem?.length; i++){
                      data.push(DocumentInput)
                      this.setState({ authorities: data});
                    }
                }
               if(item.standards !== null){
                let standards = item.standards
                let standardsItem = standards.split(',')
                let stdUrl = item.standardsLinkUrl
                let standardsLinkItem =  item.standardsLinkUrl !== null ? stdUrl.split(',') : ''
                let data = []; 
                // remove input if values are empty for both standards and standards link
                standardsItem && standardsItem.forEach((element, i) => {
                  if((element === '') && (standardsLinkItem[i] === '')){
                    standardsLinkItem.splice(i,1)
                    standardsItem.splice(i,1)
                  }
                });
                //adding input fields for standards on edit
                for(let i = 0 ; i < standardsItem?.length; i++){
                    data.push(DocumentInput)
                    this.setState({ standards: data});
                  }
              }
            }
            //setting values for authorities and standards
            let dataAuthLink = rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')]?.authoritiesLinkUrl !== null ?  rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')]?.authoritiesLinkUrl?.split(',') : '' ;
            let dataAuth =  rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')]?.authorities?.split(',') ;
            let dataStd =  rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')]?.standards?.split(',') ;
            let dataStdLink = rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')]?.standardsLinkUrl !== null ?  rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')]?.standardsLinkUrl?.split(',') : '';
            dataAuth && dataAuth.forEach((element, i) => {
              if((element === '') && (dataAuthLink[i] === '')){
                dataAuth.splice(i,1)
                dataAuthLink.splice(i,1)
              }
            });
            dataStd && dataStd.forEach((element, i) => {
              if((element === '') && (dataStdLink[i] === '')){
                dataStd.splice(i,1)
                dataStdLink.splice(i,1)
              }
            });
            this.setState({authLinkKey: dataAuthLink, authKey: dataAuth})
            this.setState({stdLink: dataStd, stdLinkKey: dataStdLink,})
            
            continentArr[lang.code].map((item) =>{
              Object.entries(item).map(([key,val]) =>{
                if(key === this.props.marketData.continent){
                  rowData['data'][lang.code]['continent'] = val
                }
              })
             this.setState(rowData)
            })
          })
        })
        this.setAllDocumentID()
        this.setState({rowData, allDocs:docObj})
     }
    }
    setAllDocumentID = () =>{
      let propsIdArr = [];
      let arr =  this.props.MarketProfileList && [...this.props.MarketProfileList].map((market, index) => market)
           if(arr && arr.length > 0){
          for (let index = 0; index < arr[0]?.documents?.length; index++) {
              propsIdArr.push(arr[0]?.documents[index]?.id)
          }
        }
      this.setState({originalIdArr: propsIdArr});
    }
    fileHandler = (file, id) =>{
      //Setting files in state on selecting files to upload
      let allFilesLocalObj = {...this.state.allFilesObj};
      if (!allFilesLocalObj[id]) {
        allFilesLocalObj[id] = file;
      }
      this.setState({allFilesObj: allFilesLocalObj});
    }
    //api call for save data 
    SaveFormHandler = (e) => {
      e.preventDefault();
      let {filesDataArr,authoritiesData , authorities, rowData,standards} = this.state;
      let documentListLength = filesDataArr.documentList.length + this.state.deleteDocIdArr.length;
      let {fieldMsg} = this.state;
      fieldMsg['iso'] = '';
      fieldMsg['officialLang'] = '';
      fieldMsg['documentLang'] = '';
      fieldMsg['economicRegion'] = '';
      fieldMsg['handDrive'] = '';
      fieldMsg['certification'] = '';
      fieldMsg['standardsMsg'] = '';
      fieldMsg['authoritiesMsg'] = '';
      fieldMsg['ECE_based'] = '';
      fieldMsg['authName'] = '';
      fieldMsg['stdName'] = '';
      let flag = false;
      [...languageArr].map(lang =>{
        if (!this.state.rowData.data) {
          fieldMsg['noData'] = i18next.t('mngTopic.noData');
          flag = true;
        }
        else{
          fieldMsg['noData'] = '';
           if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].standards ||
               (this.state.rowData.data[lang.code].standards && this.state.rowData.data[lang.code].standards.trim()) !== ''){
              let std = rowData['data'][localStorage.getItem('i18nextLng')].standards?.split(',').map(item =>(item))
              let standardsData = rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')].standards?.split(',').filter(item =>(item !== ''))
              let standardsLink = rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')].standardsLinkUrl?.split(',').filter(item =>(item !== ''))
              if(std !== undefined){
                   if(standardsData?.length < standardsLink?.length){
                      fieldMsg['stdName'] = i18next.t('marketProfile.enterStandardsName');
                      flag = true;    
                    }
               }
              else{
                if(standards?.length === standardsLink?.length){
                  fieldMsg['stdName'] = i18next.t('marketProfile.enterStandardsName');
                  flag = true; 
                }
              }
            }
            if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].standards ||
              (this.state.rowData.data[lang.code].standards && this.state.rowData.data[lang.code].standards.trim()) === ''){
                fieldMsg['stdName'] = i18next.t('marketProfile.enterStandardsName');
              flag = true; 
            }
           if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].authorities ||
             (this.state.rowData.data[lang.code].authorities && this.state.rowData.data[lang.code].authorities.trim()) !== ''){
              let auth = rowData['data'][localStorage.getItem('i18nextLng')].authorities?.split(',').map(item =>(item))
              let authoritiesLink = rowData['data'][localStorage.getItem('i18nextLng')].authoritiesLinkUrl?.split(',').filter(item =>(item !== ''))
              let authoritiesData = rowData['data'][localStorage.getItem('i18nextLng')].authorities?.split(',').filter(item =>(item !== ''))
              if(auth !== undefined){
                if(authoritiesData?.length < authoritiesLink?.length){
                    fieldMsg['authName'] = i18next.t('marketProfile.enterAuthoritiesName');
                    flag = true;    
                  }
              }
              else{
                if(authorities?.length === authoritiesLink?.length){
                  fieldMsg['authName'] = i18next.t('marketProfile.enterAuthoritiesName');
                  flag = true; 
                }
              }
           }
           if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].authorities ||
            (this.state.rowData.data[lang.code].authorities && this.state.rowData.data[lang.code].authorities.trim()) === ''){
            fieldMsg['authName'] = i18next.t('marketProfile.enterAuthoritiesName');
            flag = true; 
           }
           if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].iso ||
              (this.state.rowData.data[lang.code].iso && this.state.rowData.data[lang.code].iso.trim()) === ''){
                fieldMsg['iso'] = i18next.t('marketProfile.enterISO');
                flag = true;
            }
            if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].officialLanguage ||
              (this.state.rowData.data[lang.code].officialLanguage && this.state.rowData.data[lang.code].officialLanguage.trim()) === ''){
              fieldMsg['officialLang'] = i18next.t('marketProfile.enterOfficialLang');
              flag = true;
            }
  
            if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].documentLanguage ||
              (this.state.rowData.data[lang.code].documentLanguage && this.state.rowData.data[lang.code].documentLanguage.trim()) === ''){
              fieldMsg['documentLang'] = i18next.t('marketProfile.enterDocumentLang');
              flag = true;
            }

            if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].economicRegion ||
              (this.state.rowData.data[lang.code].economicRegion && this.state.rowData.data[lang.code].economicRegion.trim()) === ''){
              fieldMsg['economicRegion'] = i18next.t('marketProfile.enterEconomicRegion');
              flag = true;
            }

            if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].handDrive ||
              (this.state.rowData.data[lang.code].handDrive && (!this.state.rowData.data[lang.code].handDrive.label ||
                this.state.rowData.data[lang.code].handDrive.label.trim()) === '')){
                fieldMsg['handDrive'] = i18next.t('marketProfile.selectHandDrive');
                flag = true;
            }
            if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].certification ||
              (this.state.rowData.data[lang.code].certification && (!this.state.rowData.data[lang.code].certification.label ||
                this.state.rowData.data[lang.code].certification.label.trim()) === '')){
                fieldMsg['certification'] = i18next.t('marketProfile.selectCertification');
                flag = true;
            }
            if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].ece_based ||
              (this.state.rowData.data[lang.code].ece_based && this.state.rowData.data[lang.code].ece_based.trim()) === ''){
              fieldMsg['ECE_based'] = i18next.t('marketProfile.enterECE_based');
              flag = true;
            }
        }
  
      });
      this.setState(fieldMsg);
        this.props.iconCSS('save', this.props.rowIDAdd)
         if (!flag) {
         let postArr = [];
         [...languageArr].map(lang =>{
           let obj = this.state.rowData.data[lang.code] !== undefined ? this.state.rowData.data[lang.code] : {"type":''};
           obj['languageCode'] = lang.code;
           obj['market'] = {id : this.props.marketData.id , value: this.props.marketData.value}
           postArr.push(obj);
         });
         if(this.props.formtype === 'add'){
          this.props.createMarketProfile(postArr,documentListLength < 1)
         }
       if(this.props.formtype === 'edit'){
        [...languageArr].map(lang =>{
          let obj = this.state.rowData.data[lang.code] !== undefined ? this.state.rowData.data[lang.code] : {"type":''};
          obj['languageCode'] = lang.code;
          obj['market'] = {id : this.props.marketData.id , value: this.props.marketData.value}
          delete (obj.documents)
          postArr.push(obj);
        });
      this.props.editMarketProfile(postArr,documentListLength < 1)
       }
      }
  }
    //adding one input field for standards
    addStd = (e) => {
       e.preventDefault();
        const documents = this.state.standards.concat(<DocumentInput />);
        this.setState({ standards: documents});
    }
    //adding one input field for authorities
    addAut = (e) => {
         e.preventDefault();
        const data = this.state.authorities.concat(<DocumentInput />);
        this.setState({ authorities: data});
    }
    //Input fields onChange event handler for standards
    standardsInputChange = (index,lang,id) => (e) =>{
      e.preventDefault()
      let data = {...this.state.rowData};
      let arr = [];
      let {standardsData, standardsLink} = this.state;
      if(this.props.formtype === 'edit'){
        if(id === 'name'){
           Object.entries(standardsLink).map(([key,val]) =>{
              let arr = [...Object.values(val && val[lang])] ;
              let newArr = arr.join();
              data['data'][lang]['standardsLinkUrl']= newArr
            })
          let std = standardsData['data'][lang]['standards'].split(',');
          std[index] = e.target.value
          standardsData['data'][lang]['standards'] = std.toString()
          data['data'][lang]['standards']= standardsData['data'][lang]['standards']
        }
        else{
          let stdUrl = standardsLink['data'][lang]['standardsLinkUrl'] !== null ? standardsLink['data'][lang]['standardsLinkUrl']?.split(',') : [];
          stdUrl[index] = e.target.value
          standardsLink['data'][lang]['standardsLinkUrl'] = stdUrl.toString()
          data['data'][lang]['standardsLinkUrl'] = standardsLink['data'][lang]['standardsLinkUrl']
        }
        this.setState({rowData: data});
      }
       else{
        if(id === 'name'){
          //setting empty link value 
          if(standardsLink['data'][lang]['url'+index] === undefined){
            standardsLink['data'][lang]['url'+index] = ''
          }
          else{
            standardsLink['data'][lang]['url'+index] = ''
            Object.entries(standardsLink).map(([key,val]) =>{
              let arr = [...Object.values(val && val[lang])] ;
              let newArr = arr.join();
              data['data'][lang]['standardsLinkUrl']= newArr
            })
          }
          standardsData['data'][lang][id+index]= e.target.value
              Object.entries(standardsData).map(([key,val]) =>{
                let arr = [...Object.values(val && val[lang])] ;
                let newArr = arr.join();
                data['data'][lang]['standards']= newArr
              })
         }
         if(id === 'url'){
           standardsLink['data'][lang][id+index]= e.target.value
           Object.entries(standardsLink).map(([key,val]) =>{
            arr = [...Object.values(val && val[lang])] ;
            let newArr = arr.join();
            data['data'][lang]['standardsLinkUrl']= newArr
          }) 
         }
       }   
       if(localStorage.getItem('i18nextLng') === 'en'){
        data['data']['de']['standards'] =  data['data']['en']['standards'] 
        data['data']['de']['standardsLinkUrl'] =  data['data']['en']['standardsLinkUrl'] 
       }
       else{
        data['data']['en']['standards'] =  data['data']['de']['standards'] 
        data['data']['en']['standardsLinkUrl'] =  data['data']['de']['standardsLinkUrl']  
       }
        this.setState({rowData: data});
     }
    //Input fields onChange event handler for authorities
    autInputChange = (index,lang, id) => (e) =>{
      e.preventDefault()
      let data = {...this.state.rowData};
      let {authoritiesData, authoritiesLink} = this.state;
      if(this.props.formtype === 'edit'){
          if(id === 'name'){
              Object.entries(authoritiesLink).map(([key,val]) =>{
                let arr = [...Object.values(val && val[lang])] ;
                let newArr = arr.join();
                data['data'][lang]['authoritiesLinkUrl']= newArr
              })
            let auth = authoritiesData['data'][lang]['authorities'].split(',');
            auth[index] = e.target.value
            authoritiesData['data'][lang]['authorities'] = auth.toString()
            data['data'][lang]['authorities']= authoritiesData['data'][lang]['authorities']
          }
          else{
            let authUrl =  authoritiesLink['data'][lang]['authoritiesLinkUrl'] !== null ? authoritiesLink['data'][lang]['authoritiesLinkUrl']?.split(',') : [];
            authUrl[index] = e.target.value
            authoritiesLink['data'][lang]['authoritiesLinkUrl'] = authUrl.toString()
            data['data'][lang]['authoritiesLinkUrl'] = authoritiesLink['data'][lang]['authoritiesLinkUrl']
          }
          this.setState({rowData: data});
        }
       else{
        if(id === 'name'){
          if(authoritiesLink['data'][lang]['url'+index] === undefined){
           authoritiesLink['data'][lang]['url'+index] = ''
          }
          else{
           authoritiesLink['data'][lang]['url'+index] = ''
           Object.entries(authoritiesLink).map(([key,val]) =>{
             let arr = [...Object.values(val && val[lang])] ;
             let newArr = arr.join();
             data['data'][lang]['authoritiesLinkUrl']= newArr
           })
          }
          authoritiesData['data'][lang][id+index]= e.target.value
          Object.entries(authoritiesData).map(([key,val]) =>{
              let arr = [...Object.values(val && val[lang])] ;
              let newArr = arr.join();
              data['data'][lang]['authorities']= newArr
           })
        }
        if(id === 'url'){
           authoritiesLink['data'][lang][id+index]= e.target.value
              Object.entries(authoritiesLink).map(([key,val]) =>{
                let arr = [...Object.values(val && val[lang])] ;
                let newArr = arr.join();
                data['data'][lang]['authoritiesLinkUrl']= newArr
              })
          }
      }
      if(localStorage.getItem('i18nextLng') === 'en'){
        data['data']['de']['authorities'] =  data['data']['en']['authorities'] 
        data['data']['de']['authoritiesLinkUrl'] =  data['data']['en']['authoritiesLinkUrl'] 
       }
       else{
        data['data']['en']['authorities'] =  data['data']['de']['authorities'] 
        data['data']['en']['authoritiesLinkUrl'] =  data['data']['de']['authoritiesLinkUrl']  
       }
     this.setState({rowData: data});
    }
     //Input fields onChange event handler for all input fields
    multiInputChange = (lang,id) => (event) =>{
      let data = {...this.state.rowData};
      if(id === 'comments'){
        data['data']['de']['comments'] = event.target.value;
      }
      data['data'][lang][id] = event.target.value;
      this.setState({rowData: data});
    }
   //Certification dropdown onchange event handler
    certificationHandler = (lang,id) => (event) =>{
        let {rowData} = this.state;
        if (event[0]?.id) {
           rowData.data[lang]['certification'] = event[0];
          for (let index = 0; index < languageArr.length; index++) {
            if (languageArr[index].code !== lang) {
              let itemIndex = this.state.CertificationList[languageArr[index].code].findIndex(obj => obj.id === event[0]['id']);
              rowData['data'][languageArr[index].code]['certification'] = this.state.CertificationList[languageArr[index].code][itemIndex];
              break;
            }
          }
          this.setState({rowData:rowData})
        }
        else{
            this.resetHandlerCertificate();
        }
    }
    //Resetting Certification
    resetHandlerCertificate = () =>{
        let {rowData} = this.state;
        rowData['data']['en']['certification'] = null;
        rowData['data']['de']['certification'] = null;
        this.setState({rowData: rowData});
      }
    //Road condition dropdown onchange event handler
    roadConditionHandler = (lang,id ,type) => (event) =>{
        let {rowData} = this.state;
        if (event[0]?.id) {
           rowData.data[lang]['roadCondition'] = event[0];
          for (let index = 0; index < languageArr.length; index++) {
            if (languageArr[index].code !== lang) {
              let itemIndex = this.state.RoadConditionList[languageArr[index].code].findIndex(obj => obj.id === event[0]['id']);
              rowData['data'][languageArr[index].code]['roadCondition'] = this.state.RoadConditionList[languageArr[index].code][itemIndex];
              break;
            }
          }
          this.setState({rowData:rowData})
        }
        else{
            this.resetHandlerRoadCondition();
          }
      }
    //Resetting Road condition
    resetHandlerRoadCondition = () =>{
     let {rowData} = this.state;
        rowData['data']['en']['roadCondition']= null;
        rowData['data']['de']['roadCondition'] = null;
        this.setState({rowData: rowData});
    }
    //hand drive type dropdown onchange event handler
    driveTypeHandler = (lang,id) => (event) =>{
        let {rowData} = this.state;
        if (event[0]?.id) {
           rowData.data[lang]['handDrive'] = event[0];
          for (let index = 0; index < languageArr.length; index++) {
            if (languageArr[index].code !== lang) {
              let itemIndex = this.state.HandDriveList && this.state.HandDriveList[languageArr[index].code].findIndex(obj => obj.id === event[0]['id']);
              rowData['data'][languageArr[index].code]['handDrive'] = this.state.HandDriveList[languageArr[index].code][itemIndex];
              break;
            }
          }
          this.setState({rowData:rowData})
        }
        else{
            this.resetHandlerDriveType();
        }
    }
    //Resetting hand drive
    resetHandlerDriveType = () =>{
        let {rowData} = this.state;
        rowData['data']['en']['handDrive'] = null;
        rowData['data']['de']['handDrive'] = null;
        this.setState({rowData: rowData});
    }
    //average temperature dropdown onchange event handler
    tempHandler = (lang,id) => (event) =>{
        let {rowData} = this.state;
        if (event[0]?.id) {
           rowData.data[lang]['avgTemp'] = event[0];
          for (let index = 0; index < languageArr.length; index++) {
            if (languageArr[index].code !== lang) {
              let itemIndex = this.state.AvgTempList[languageArr[index].code].findIndex(obj => obj.id === event[0]['id']);
              rowData['data'][languageArr[index].code]['avgTemp'] = this.state.AvgTempList[languageArr[index].code][itemIndex];
              break;
            }
          }
          this.setState({rowData:rowData})
        }
        else{
            this.resetHandlerTemp();
        }
    }
    //Resetting average temperature
    resetHandlerTemp = () =>{
        let {rowData} = this.state;
        rowData['data']['en']['avgTemp'] = null;
        rowData['data']['de']['avgTemp'] = null;
        this.setState({rowData: rowData});
    }
    //OnChange handler for all multiple dropdowns
    multiSelectionHandler = (lang,id,type) => (event) =>{
        let {rowData} = this.state;
        let dataProps = {};
        if(id === 'airConditions'){
            dataProps = this.state.AirConditionList
          }
        else if(id === 'humidity'){
            dataProps = this.state.HumidityList
        }
        else if(id === 'envConditions'){
            dataProps = this.state.EnvConditionList
        }
        let data = {...this.state.rowData};
        let autoUpdateArr = [];

        data['data'][lang][id] = event;
        Object.keys(data['data']).map((key, index) => {
          if (key !== lang) {
            if (type !== 'object') {
              [...event].filter(elem => {
                 let newArr = dataProps[key].filter(item => {
                   if (item.id === elem.id) {
                     return item;
                   }
                 })
                 autoUpdateArr.push(newArr[0]);
             });
             data['data'][key][id] = autoUpdateArr;
            }
            else{
                dataProps[key].filter(item => {
                  if (item.id === event.id) {
                    data['data'][key][id] = item[id];
                  }
                });
              }
            }
        
        })
        this.setState({rowData: data});
    }
    //Documents toggle icon handler
    toggleIconDocuments = event =>{
      event.preventDefault()
      let {toggleIconDocuments} = this.state;
      toggleIconDocuments = !toggleIconDocuments;
      this.setState({toggleIconDocuments});
    }
    //Comments toggle icon handler
    toggleIconComments = event =>{
      event.preventDefault()
      let {toggleIconComments} = this.state;
      toggleIconComments = !toggleIconComments;
      this.setState({toggleIconComments});
    }
    //Documents add button click handler
    openAddDocModal = () =>{
      this.setState({docModalFlag: true});
    }
    //Undo deleted documents before saving
    undoDeleteChanges = (row) =>{
      let {deleteRowIdArr,deleteDocIdArr} = this.state;
      deleteRowIdArr.splice(deleteRowIdArr.indexOf(row?.id),1)
      deleteDocIdArr.splice(deleteDocIdArr.indexOf(row?.id),1)
      this.setState(deleteDocIdArr)
      this.setState(deleteRowIdArr)
    }
    //Open delete document confirmation modal
    openDeleteModal = (row, market) =>{
      this.setState({confirmModalFlag: true,  docRowData: row})
    }
    //Documents modal cancel handler
    closeDocModal = () =>{
      this.setState({docModalFlag: false,docRow: null});
    }
    //Setting files shortDescription after adding files in doc table 
   appendDocInToJsonHandler = (array, market) =>{
    let filesDataArrLocalObj = {...this.state.filesDataArr};
    let docFlag = false;

    if (array[0]?.docType == 'Add' || (filesDataArrLocalObj['documentList'].length < 1)) {
      filesDataArrLocalObj['documentList'].push([array[0], array[1]]);
    } else {
      for (let index = 0; index < filesDataArrLocalObj['documentList'].length; index++) {
        if (filesDataArrLocalObj['documentList'][index][0]?.id == array[0]?.id) {
          docFlag = true;
          filesDataArrLocalObj['documentList'][index][0].shortDescription = array[0]?.shortDescription;
          filesDataArrLocalObj['documentList'][index][1].shortDescription = array[1]?.shortDescription;
        }
      }
      if (!docFlag) {
        filesDataArrLocalObj['documentList'].push([array[0], array[1]]);
      }
    }
    this.setState({filesDataArr: filesDataArrLocalObj});
  }
  //Add document in table and update state
   addFileInTable = (arr, market, file) =>{
    this.fileHandler(file, arr[0]?.id);
    this.appendDocInToJsonHandler(arr, market);
       let data = {...this.state.allDocs};
       if (arr[0]?.docType == 'Add') {
        if (!data) {
          data= {en:[], de:[]};
        } else if (!data['en'] || !data['de']) {
          data['en'] = [];
          data['de'] = [];
        }
        data['en'].push(arr[0]);
        data['de'].push(arr[1]);
      } else {
        const language = localStorage.getItem('i18nextLng');
        const docArr = data[language];
        for (let index = 0; index < docArr.length; index++) {
          if (docArr[index]?.id == arr[0]?.id) {
            if (file) {
              data[language][index].name = arr[0]?.name;
            }
            data[language][index].shortDescription = language == 'de' ? arr[1]?.shortDescription : arr[0]?.shortDescription;
            data[language][index]['market'] = arr[0]?.market;
            break;
          }
        }
      }
  
     this.updateDocID(arr[0]?.id);
      this.setState({allDocs:  JSON.parse(JSON.stringify(data))});
      this.closeDocModal();
   }
   updateDocID = (id) =>{
    let {documentIdArr} = this.state;
    if (documentIdArr.indexOf(id) < 0) {
      documentIdArr.push(id);
      this.setState(documentIdArr);
    }
  }
  //enable edit document modal flag
  docEditHandler = (row) =>{
    this.setState({docModalFlag: true, selectedDoc: row?.category, docRow: row});
  }
  //Delete document after confirmation
  onDeleteDoc = (row, market) =>{
   let {docRowData,originalIdArr, filesDataArr, deleteDocIdArr} = this.state
    let arr = this.state.deleteRowIdArr;
    arr.push(docRowData.id)
    
    if (originalIdArr.indexOf(docRowData?.id) > -1) {
      deleteDocIdArr.push(docRowData?.id);
      this.setState(deleteDocIdArr);
    }
   //removing deleted document from the add/edit state obj
    for (let index = 0; index < filesDataArr['documentList'].length; index++) {
      if (filesDataArr['documentList'][index][0]?.id == docRowData?.id) {
        filesDataArr['documentList'].splice(index)
        this.setState(filesDataArr);
      }
    }
    this.setState({ confirmModalFlag: false,deleteRowIdArr: arr});
  }
  //Close delete document confirmation modal
  closeDeleteModal = () =>{
    this.setState({confirmModalFlag: false})
  }
  //Document add/edit/delete api handler
  docApiHandler = (id) =>{
    let {originalIdArr, deleteDocIdArr} = this.state;
    //Total length of documents
    totalLength = this.state.filesDataArr['documentList'].length + deleteDocIdArr.length;
    newFilesAddedLength = 0;

    var addFormData = new FormData();
    var editFormData = new FormData();

    var documentJSONlist = [];
    var documentFiles = [];
    let addDocFlag = false;

    for (let index = 0; index < this.state.filesDataArr['documentList'].length; index++) {
      editFormData = new FormData();

      //English data obj for the file
      const fileJson1 = {
        "languageCode": "en",
        "name": this.state.filesDataArr['documentList'][index][0]?.name,
        "size": this.state.filesDataArr['documentList'][index][0]?.size,
        "shortDescription": this.state.filesDataArr['documentList'][index][0]?.shortDescription,
        // "category": this.state.filesDataArr['documentList'][index][0]?.category,
        "marketProfile": {
          "id" : id,
          "languageCode": "en"
        }
      };

      //German data obj for the file
      const fileJson2 = {
        "languageCode": "de",
        "name": this.state.filesDataArr['documentList'][index][1]?.name,
        "size": this.state.filesDataArr['documentList'][index][1]?.size,
        "shortDescription": this.state.filesDataArr['documentList'][index][1]?.shortDescription,
        // "category": this.state.filesDataArr['documentList'][index][1]?.category,
        "marketProfile": {
          "id" : id,
          "languageCode": "de"
        }

      };
     if (originalIdArr.indexOf(this.state.filesDataArr['documentList'][index][0]?.id) < 0) {
        if (this.state.allFilesObj?.[this.state.filesDataArr['documentList'][index][0]?.id]) {
          //Upload new files
          addDocFlag = true;
          newFilesAddedLength += 1;
          documentJSONlist.push(fileJson1, fileJson2);
          documentFiles.push(this.state.allFilesObj?.[this.state.filesDataArr['documentList'][index][0]?.id]);
          addFormData.append('files', this.state.allFilesObj?.[this.state.filesDataArr['documentList'][index][0]?.id]);
        }
       } else {
         //pushing english and german data obj in edit form data
         editFormData.append('marketProfileDocumentJson', JSON.stringify([fileJson1, fileJson2]));
        if (this.state.allFilesObj?.[this.state.filesDataArr['documentList'][index][0]?.id]) {
          editFormData.append('file', this.state.allFilesObj?.[this.state.filesDataArr['documentList'][index][0]?.id]);
        }
        //Edit files api call
        Promise.all([this.props.editMarketProfileFiles(editFormData, this.state.filesDataArr['documentList'][index][0]?.id, this.state.filesDataArr['documentList'][index][0]?.name)])
        .then((responses) => {
          //Decrementing totalLength by 1 to check if length becomes 0 means all docs have added/edited or deleted
          totalLength = totalLength - 1;
          if (totalLength == 0) {
            toast['success'](i18next.t('marketProfile.editMarketProfileSuccess'), { position: 'top-right' });
            this.props.closeModal();
          }
        });
      }
    }

    for (let index = 0; index < deleteDocIdArr.length; index++) {
      //Delete files api call
      Promise.all([this.props.deleteMarketProfileFiles(deleteDocIdArr[index], this.state.allFilesObj?.[deleteDocIdArr[index]])])
      .then((responses) => {
        //Decrementing totalLength by 1 to check if length becomes 0 means all docs have added/edited or deleted then close GRM modal
        totalLength = totalLength - 1;
        if (totalLength == 0) {
          toast['success'](i18next.t('marketProfile.editMarketProfileSuccess'), { position: 'top-right' });
          this.props.closeModal();
        }
      });
    }
  addFormData.append('marketProfileDocumentJson', JSON.stringify(documentJSONlist));
  if (addDocFlag) {
    //Add files api call
    Promise.all([this.props.uploadMarketProfileFiles(addFormData, "")])
    .then((responses) => {
      totalLength = totalLength - newFilesAddedLength;
      //If totalLength becomes 0 then close GRM modal and show success popup
      if (totalLength == 0) {
        if(this.props.formtype === 'edit'){
          toast['success'](i18next.t('marketProfile.editMarketProfileSuccess'), { position: 'top-right' });
        }
        else{
          toast['success'](i18next.t('marketProfile.addMarketProfileSuccess'), { position: 'top-right' });
        }
     
        this.props.closeModal();
      }
    });
  }
}
//Document download when document is in view mode(read only) by clicking on file name
downloadfileHandler = (id, name) =>{
  if (this.state.originalIdArr.indexOf(id) > -1) {
    var xhr = new XMLHttpRequest();
    let apiUrl =  process.env.REACT_APP_BASE_URL+'/'+GET_FILE_MARKET_PROFILE+'/'+id;
    xhr.open('GET', apiUrl, true);
    xhr.setRequestHeader('Cache-Control','no-cache');
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.setRequestHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('token'))}`);
    xhr.responseType = 'blob';
    xhr.onload = async function (dispatch) {
     if(xhr.status === 200){
       FileSaver.saveAs(xhr.response, name);
       toast(i18next.t('table.downloadSuccess'), { position: 'top-right', type: 'success'});
      }
      else if(xhr.status === 404){
        toast(i18next.t('table.fileNotFoundError'), { position: 'top-right', type: 'error'});
      }
      else if(xhr.status === 500){
        toast(i18next.t('table.downloadError'), { position: 'top-right', type: 'error'});
      }
     }.bind(this);
     xhr.send();
  }
}
//Open delete moarket profile confirmation model
DeleteHandler = () =>{
  this.setState({confirmModalFlag: true, deleteProfile: true})
}
//Delete api call for market profile
onDeleteMarketProfile = () =>{
  let {rowData} = this.state
  this.props.deleteMarketProfile(this.props.marketData.id ,rowData['data']['en'].id)
  this.props.iconCSS('delete', this.props.marketData.id)
  this.closeDeleteModal()
  this.closeDocModal()
}
//Resetting input for standards and standards links
resetStandards = (index,type) => (e)=>{
  e.preventDefault();
  let {rowData,standardsLink,stdLinkKey,standardsData,stdLink} = this.state;
  if(type === 'url'){
    rowData['data'][localStorage.getItem('i18nextLng')]?.standardsLinkUrl?.split(',').map((item,itemIndex) =>{
        if(itemIndex === index){
              let data =  rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')] &&  rowData['data'][localStorage.getItem('i18nextLng')]?.standardsLinkUrl?.split(',') 
              let linkData = standardsLink['data'] && standardsLink['data'][localStorage.getItem('i18nextLng')] && standardsLink['data'][localStorage.getItem('i18nextLng')][type+index]
              data[itemIndex] = this.props.formtype === 'edit' ? undefined : ' '
              rowData['data'][localStorage.getItem('i18nextLng')]['standardsLinkUrl']= data.toString()
              this.setState({stdLinkKey: data})
              Object.entries(standardsLink['data'][localStorage.getItem('i18nextLng')]).map(([key,val]) =>{ 
                if(linkData === val){
                  standardsLink['data'][localStorage.getItem('i18nextLng')][key] = ''
                  stdLinkKey[index] = ''
                  }
              })
            this.setState(standardsLink)
          }
     })
   this.setState(rowData)
  }else{
      rowData['data'][localStorage.getItem('i18nextLng')]?.standards?.split(',').map((item,itemIndex) =>{
     if(itemIndex === index){
          let data =  rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')] &&  rowData['data'][localStorage.getItem('i18nextLng')]?.standards?.split(',') 
          let nameData = standardsData['data'] && standardsData['data'][localStorage.getItem('i18nextLng')] && standardsData['data'][localStorage.getItem('i18nextLng')][type+index]
          data[itemIndex] = this.props.formtype === 'edit' ? undefined : ' '
          this.setState({stdLink: data})
          rowData['data'][localStorage.getItem('i18nextLng')]['standards']= data.toString()
          Object.entries(standardsData['data'][localStorage.getItem('i18nextLng')]).map(([key,val]) =>{ 
            if(nameData === val){
                standardsData['data'][localStorage.getItem('i18nextLng')][key] = undefined
                stdLink[index] = ''
            }
          })
          this.setState(standardsData)
        }
   })
   this.setState(rowData)
  }
}
//Resetting input for authorities and authorities links
resetAuthorities = (index,type) => (e)=>{
  e.preventDefault();
  let {rowData, authoritiesLink, authoritiesData, authLinkKey,authKey} = this.state;
  if(type === 'url'){
    rowData['data'][localStorage.getItem('i18nextLng')]?.authoritiesLinkUrl?.split(',').map((item,itemIndex) =>{
      if(itemIndex === index){
            let data =  rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')]&&  rowData['data'][localStorage.getItem('i18nextLng')]?.authoritiesLinkUrl?.split(',') 
            let linkData = authoritiesLink['data'] && authoritiesLink['data'][localStorage.getItem('i18nextLng')] && authoritiesLink['data'][localStorage.getItem('i18nextLng')][type+index] 
            data[itemIndex] = this.props.formtype === 'edit' ? undefined : ' '
            this.setState({authLinkKey: data})
            rowData['data'][localStorage.getItem('i18nextLng')]['authoritiesLinkUrl']= data.toString()
            Object.entries(authoritiesLink['data'][localStorage.getItem('i18nextLng')]).map(([key,val]) =>{ 
              if(linkData === val){
                  authoritiesLink['data'][localStorage.getItem('i18nextLng')][key] = ''
                  authLinkKey[index] = ''
                }
            })
        this.setState(authoritiesLink)
      }
    })
     this.setState(rowData)
  }else{
    rowData['data'][localStorage.getItem('i18nextLng')]?.authorities?.split(',').map((item,itemIndex) =>{
     if(itemIndex === index){
          let data =  rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')]&&  rowData['data'][localStorage.getItem('i18nextLng')]?.authorities?.split(',') 
          let nameData = authoritiesData['data'] && authoritiesData['data'][localStorage.getItem('i18nextLng')] && authoritiesData['data'][localStorage.getItem('i18nextLng')][type+index]
          data[itemIndex] = this.props.formtype === 'edit' ? undefined : ' '
          this.setState({authKey: data})
          rowData['data'][localStorage.getItem('i18nextLng')]['authorities']= data.toString()
          Object.entries(authoritiesData['data'][localStorage.getItem('i18nextLng')]).map(([key,val]) =>{ 
              if(nameData === val){
                authoritiesData['data'][localStorage.getItem('i18nextLng')][key] = undefined
                authKey[index] = ''
              }
           })
           this.setState(authoritiesData)
       }
   })
   this.setState(rowData)
  }
}
    render() {
       const tableSpinner = <LoaderAnim active type="ball-beat"/>;
        let {rowData, market,docRow,deleteProfile} = this.state;
        let docFormTemplate = <DocumentForm {...this.props}  docRowData={docRow} allDocsForMarket={this.state.allDocs} newDocuments={this.state.filesDataArr} rowData={rowData} appendDocInToJson={(arr, market, file)=> this.addFileInTable(arr, market, file)} marketCode={this.state.market.value} selectedDocID={'marketProfile'} closeModal={this.closeDocModal} formtype={this.props.formtype}/>;
        return (
            <Translation>{t => (
                <Fragment>
                    <form id="marketProfileForm">
                    { this.state.docModalFlag &&
                       <EditModalComp modalID="docID" closeModal={this.closeDocModal} modalBody={docFormTemplate} modalTitle={t('mngRegulations.docModalTitle')} />
                    }
                     { this.state.confirmModalFlag  &&
                        <DeleteModalComp modalMsg={!deleteProfile ? i18next.t('closeDeleteModal') : i18next.t('closeDeleteMarketProfileModal')} closeModal={this.closeDeleteModal} eventHandlerDelete={!deleteProfile ? (row,market) => this.onDeleteDoc(row, market) : () => this.onDeleteMarketProfile()} />
                      }
                       <Loader message={tableSpinner} show={this.props.loading}>
                        <div className="col-sm-12">
                            <h5 style={{ 'fontSize': 'medium' }}><b style={{'marginLeft': '10px'}}>{this.state.market?.value !== 'ECE' ? t(`map.countries.`+this.state.market?.value) :'UNECE'}</b></h5>
                            <Row>
                              <Col>
                                <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCssNotMandatory' : null}`}>
                                    <div className="col-sm-4 "  style={{ 'padding': '0' }}>
                                        <h6 style={{ 'fontSize': 'small' }}><b>{t('marketProfile.generalLable')} :</b></h6>
                                    </div>
                                    { 
                                        [...languageArr].map(lang => {
                                            return (
                                            <div className="col-4 "><h6 style={{ 'fontSize': 'small' ,'marginBottom': '4px'}}><b>{i18next.t('language.' + lang.code)}</b></h6></div>
                                            )
                                        })
                                    }
                                </div>
                               <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                                  <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                      <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.isoLable')} :<b style={{'fontSize': 'small'}}>*</b></h6>
                                  </div>
                                  {
                                    [...languageArr].map(lang =>{
                                       return(
                                         <div className="col-sm-4">
                                            <h6 style={{ 'fontSize': 'smaller' }}>
                                              {this.props.formtype !== 'view' ?
                                               <input type="text" id={'iso'+lang.code} className="form-control" placeholder={t('marketProfile.isoPlaceholder')} onChange={this.multiInputChange(lang.code,'iso')} value={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].iso}/>
                                              : rowData.data && rowData.data[lang.code] && rowData.data[lang.code].iso
                                              }
                                              </h6>
                                          </div>
                                        )})
                                   }
                                </div>
                                    <div className="form-inline col-sm-12 labelText">
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.continantLable')} :</h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                         return(
                                          <div className="form-inline col-sm-4" style={{ 'paddingBottom': '5px' }}>
                                            <h6 style={{ 'fontSize': 'smaller'}}>{rowData.data && rowData.data[lang.code] && rowData.data[lang.code].continent}</h6>
                                        </div>
                                          )})
                                        }
                                    </div>
                                    <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.officialLangLable')} :<b style={{'fontSize': 'small'}}>*</b></h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                         return(
                                         <div className="col-sm-4">
                                           <h6 style={{ 'fontSize': 'smaller' }}>
                                              {this.props.formtype !== 'view' ?
                                               <input type="text" className="form-control" id={'officialLanguage'+lang.code} placeholder={t('marketProfile.offLangPlaceholder')}  onChange={this.multiInputChange(lang.code,'officialLanguage')} value={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].officialLanguage}/>
                                              :rowData.data && rowData.data[lang.code] && rowData.data[lang.code].officialLanguage
                                              }
                                              </h6>
                                           </div>
                                         )})
                                       }
                                    </div>
                                    <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.docLangLable')} :<b style={{'fontSize': 'small'}}>*</b></h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                         return(
                                          <div className="col-sm-4">
                                           <h6 style={{ 'fontSize': 'smaller' }}>
                                            { this.props.formtype !== 'view' ?
                                             <input type="text" id={'documentLanguage'+lang.code} className="form-control" placeholder={t('marketProfile.docLangPlaceholder')} value={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].documentLanguage} onChange={this.multiInputChange(lang.code,'documentLanguage')}/>
                                             : rowData.data && rowData.data[lang.code] && rowData.data[lang.code].documentLanguage
                                            }
                                            </h6>
                                          </div>
                                         )})
                                        }
                                    </div>
                                    <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCssNotMandatory' : null}`}>
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.economicLangLable')} :<b style={{'fontSize': 'small'}}>*</b></h6>
                                        </div>
                                        {
                                          [...languageArr].map(lang =>{
                                         return(
                                           <div className="col-sm-4">
                                               <h6 style={{ 'fontSize': 'smaller' }}>
                                            { this.props.formtype !== 'view' ?
                                             <input type="text" className="form-control" id={'economicRegion'+lang.code} placeholder={t('marketProfile.economicRegionPlaceholder')} value={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].economicRegion} onChange={this.multiInputChange(lang.code,'economicRegion')}/>
                                             : rowData.data && rowData.data[lang.code] && rowData.data[lang.code].economicRegion
                                            }
                                            </h6>
                                          </div>
                                         )})
                                        }
                                    </div>
                                    <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCssNotMandatory' : null}`} >
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller'}}>{t('marketProfile.furtherInfoLable')} :</h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                          return(
                                            <div className="col-sm-4">
                                            <h6 style={{ 'fontSize': 'smaller' }}>
                                             { this.props.formtype !== 'view' ?
                                              <input type="text" className="form-control" id={'generalFurtherInformation'+lang.code} placeholder={t('marketProfile.infoLinkPlaceholder')} value={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].generalFurtherInformation} onChange={this.multiInputChange(lang.code,'generalFurtherInformation')} />
                                              : <a href={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].generalFurtherInformation} target="_blank"> {rowData.data && rowData.data[lang.code] && rowData.data[lang.code].generalFurtherInformation}</a>
                                             }
                                          </h6>
                                          </div>
                                          )})
                                        }
                                    </div>
                                   
                                    <div className="form-inline col-sm-4 labelText" style={{'marginBottom': '12px'}}>
                                        <h6 style={{ 'fontSize': 'small', 'marginTop': '5px' }}><b>{t('marketProfile.climateLabel')} :</b></h6>
                                    </div>
                                    <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCssNotMandatory' : null}`}>
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }} >
                                            <h6 style={{ 'fontSize': 'smaller'}}>{t('marketProfile.roadCondLable')} :</h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                          return(
                                         <div className="col-sm-4">
                                             <h6 style={{ 'fontSize': 'smaller' }}>
                                             { this.props.formtype && this.props.formtype !== 'view' ?
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                emptyLabel={t('noMatchFound')}
                                                clearButton
                                                labelKey="label"
                                                selected={rowData['data'] && rowData['data'][lang.code] && rowData['data'][lang.code].roadCondition ? [rowData['data'][lang.code].roadCondition.label] : []}
                                                options={this.state.RoadConditionList[lang.code]}
                                                onChange={this.roadConditionHandler(lang.code ,'roadCondition','array')}
                                                placeholder={t('marketProfile.roadConditionPlaceholder')}
                                            />
                                            : rowData['data'] && rowData['data'][lang.code] && rowData['data'][lang.code]?.roadCondition?.label
                                          }
                                          </h6>
                                         </div>)})
                                        } 
                                    </div> 
                                    <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }} >
                                            <h6 style={{ 'fontSize': 'smaller'}}>{t('marketProfile.driveTypeLable')} :<b style={{'fontSize': 'small'}}>*</b></h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                          return(
                                         <div className="col-sm-4">
                                            <h6 style={{ 'fontSize': 'smaller' }}>
                                             { this.props.formtype !== 'view' ?
                                               <Typeahead
                                                 id="basic-typeahead-single"
                                                 emptyLabel={t('noMatchFound')}
                                                 clearButton
                                                 labelKey="label"
                                                 selected={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].handDrive ? [rowData['data'][lang.code].handDrive.label] : []}
                                                 options={this.state.HandDriveList[lang.code]}
                                                 onChange={this.driveTypeHandler(lang.code ,'handDrive')}
                                                 placeholder={t('marketProfile.handDrivePlaceholder')}
                                                />
                                               :  rowData['data'] && rowData['data'][lang.code] && rowData['data'][lang.code]?.handDrive?.label
                                              }
                                            </h6>
                                          </div>)})
                                        } 
                                    </div> 
                                    <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCssNotMandatory' : null}`} style={{'marginTop': '5px'}}>
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.avgTempLable')} :</h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                          return(
                                          <div className="col-sm-4">
                                              <h6 style={{ 'fontSize': 'smaller' }}>
                                               { this.props.formtype !== 'view' ?
                                                <Typeahead
                                                 id="basic-typeahead-single"
                                                 emptyLabel={t('noMatchFound')}
                                                 clearButton
                                                 labelKey="label"
                                                 selected={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].avgTemp ? [rowData.data[lang.code].avgTemp.label] : []}
                                                 options={this.state.AvgTempList[lang.code]}
                                                 onChange={this.tempHandler(lang.code ,'avgTemp')}
                                                 placeholder={t('marketProfile.avgTempPlaceholder')}
                                                />
                                               :  rowData['data'] && rowData['data'][lang.code] && rowData['data'][lang.code]?.avgTemp?.label
                                              }
                                             </h6>
                                          </div>
                                          )})
                                        }
                                    </div>
                                    <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCssNotMandatory' : null}`} style={{'marginTop': '5px'}}>
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller',}}>{t('marketProfile.airCondLable')} :</h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                          return(
                                          <div className="col-sm-4">
                                             <h6 style={{'marginBottom': '5px' ,'fontSize': 'smaller'}}>
                                               { this.props.formtype !== 'view' ?
                                                <Typeahead
                                                 id="basic-typeahead-multiple"
                                                 emptyLabel={t('noMatchFound')}
                                                 clearButton
                                                 multiple
                                                 labelKey="label"
                                                 selected={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].airConditions}
                                                 options={this.state.AirConditionList[lang.code]  || []}
                                                 onChange={this.multiSelectionHandler(lang.code ,'airConditions', 'array')}
                                                 placeholder={t('marketProfile.airConditionPlaceholder')}
                                                />
                                                :  rowData['data'] && rowData['data'][lang.code] && rowData['data'][lang.code]?.airConditions?.map((item,index) => (index ? ', ' : '')+item.label)
                                               }
                                               </h6>  
                                          </div>
                                          )})
                                        }
                                    </div>
                                    <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCssNotMandatory' : null}`}>
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.humidityLable')} :</h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                          return(
                                          <div className="col-sm-4">
                                            <h6 style={{'marginBottom': '5px','fontSize': 'smaller' }}>
                                               { this.props.formtype !== 'view' ?
                                                <Typeahead
                                                 id="basic-typeahead-multiple"
                                                 emptyLabel={t('noMatchFound')}
                                                 clearButton
                                                 labelKey="label"
                                                 multiple
                                                 selected={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].humidity}
                                                 options={this.state.HumidityList[lang.code] || []}
                                                 onChange={this.multiSelectionHandler(lang.code ,'humidity','array')}
                                                 placeholder={t('marketProfile.humidityPlaceholder')}
                                               />
                                               :  rowData['data'] && rowData['data'][lang.code] && rowData['data'][lang.code]?.humidity?.map((item,index) => (index ? ', ' : '')+item.label)
                                               }
                                              </h6>
                                          </div>
                                          )})
                                        }
                                    </div>
                                    <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCssNotMandatory' : null}`}  style={{'marginBottom': '5px'}}>
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.envCondLable')} :</h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                          return(
                                          <div className="col-sm-4">
                                             <h6 style={{'marginBottom': '5px','fontSize': 'smaller' }}>
                                               { this.props.formtype !== 'view' ?
                                                <Typeahead
                                                 id="basic-typeahead-multiple"
                                                 emptyLabel={t('noMatchFound')}
                                                 clearButton
                                                 multiple
                                                 labelKey="label"
                                                 selected={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].envConditions}
                                                 options={this.state.EnvConditionList[lang.code] || []}
                                                 onChange={this.multiSelectionHandler(lang.code ,'envConditions', 'array')}
                                                 placeholder={t('marketProfile.extrEnvCondPlaceholder')}
                                                />
                                                :  rowData['data'] && rowData['data'][lang.code] && rowData['data'][lang.code]?.envConditions?.map((item,index) => (index ? ', ' : '')+item.label)
                                               }
                                              </h6>
                                          </div>
                                          )})
                                        }
                                    </div>
                                    <div className="form-inline col-sm-4 labelText" style={{'marginBottom': '12px'}}>
                                        <h6 style={{ 'fontSize': 'small','marginBottom': '8px', 'marginTop': '5px'}}><b>{t('marketProfile.typeApprovalLable')} :</b></h6>
                                    </div>
                                    <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.certificationLabel')} :<b style={{'fontSize': 'small'}}>*</b></h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                          return(
                                          <div className="col-sm-4">
                                            <h6 style={{ 'fontSize': 'smaller' }}>
                                               { this.props.formtype !== 'view' ?
                                                <Typeahead
                                                  id="basic-typeahead-single"
                                                  emptyLabel={t('noMatchFound')}
                                                  clearButton
                                                  labelKey="label"
                                                  selected={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].certification ? [rowData.data[lang.code].certification.label] : []}
                                                  options={this.state.CertificationList[lang.code] || []}
                                                  onChange={this.certificationHandler(lang.code ,'certification')}
                                                 placeholder={t('marketProfile.certificationPlaceholder')}
                                               />
                                               :  rowData['data'] && rowData['data'][lang.code] && rowData['data'][lang.code]?.certification?.label
                                               }
                                             </h6>
                                          </div>
                                          )})
                                        }
                                  </div>
                                  <div className="form-inline col-sm-12 labelText" style={{'marginBottom' : '5px'}}>
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.regLable')} :</h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                          return(
                                          <div className="form-inline col-sm-4">
                                          <h6 style={{ 'fontSize': 'smaller','marginBottom': '4px', 'marginTop': '4px' }}>{this.state.regCount ? this.state.regCount : ''}</h6> 
                                          </div>
                                          )})
                                        }
                                  </div>
                                  <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCss' : null}`} >
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.stdLabel')} :<b style={{'fontSize': 'small'}}>*</b></h6>
                                        </div>
                                        <div className="col-sm-8">
                                        { this.props.formtype !== 'view' ?
                                                <h6 style={{ 'fontSize': 'smaller' }}>
                                          <div className="inputs">
                                                <div className="form-inline col-sm-12" style={{ 'padding': '0' }}>
                                                  <div className="form-inline col-sm-5">
                                                    <h6 style={{ 'fontSize': 'x-small' }}><b>{t('marketProfile.nameLabel')} </b><b style={{'fontSize': 'small'}}>*</b></h6>  
                                                  </div>
                                                  <div className="form-inline col-sm-5" style={{ 'paddingLeft': '55px' }}>
                                                      <h6 style={{ 'fontSize': 'x-small' }}><b>{t('marketProfile.urlLable')}</b></h6>
                                                  </div>
                                                  <div className="form-inline col-sm-2" style={{'paddingLeft' : '54px'}}>
                                                    <i className="pe-7s-plus addInput" style={{'cursor':'pointer'}}  onClick={this.addStd}></i>
                                                  </div>
                                               </div>
                                               {
                                             [...languageArr].map(lang =>{
                                              if (lang.code === localStorage.getItem('i18nextLng')) {
                                                return (
                                              <div className="form-inline col-sm-12" style={{ 'padding': '0' }} key={this.state.stdLink}>
                                                <div className="form-inline col-sm-6"  key={this.state.stdLink}>
                                                    {this.state.standards.map((Element, index) => { 
                                                            {  let data =  this.props.formtype === 'edit' ? rowData['data'] && rowData['data'][lang.code] &&  rowData['data'][lang.code]?.standards?.split(',') : ''
                                                                return <DocumentInput
                                                                    key={index}
                                                                    change= {this.standardsInputChange(index,lang.code,'name')}
                                                                    click = {this.resetStandards(index,'name')}
                                                                    value={this.state.stdLink[index]}
                                                              />}
                                                          })
                                                      }
                                                  </div>
                                                  <div className="form-inline col-sm-6" key={this.state.stdLinkKey}>
                                                      { this.state.standards.map((Element, index) => { 
                                                        let data =  this.props.formtype === 'edit' ? rowData['data'] && rowData['data'][lang.code] &&  rowData['data'][lang.code]?.standardsLinkUrl?.split(',') : ''
                                                              { return <DocumentInput
                                                                        key={index}
                                                                        change= {this.standardsInputChange(index,lang.code, 'url')}
                                                                        click = {this.resetStandards(index, 'url')}
                                                                        value={this.state.stdLinkKey ? this.state.stdLinkKey[index] : ''}
                                                                  />}
                                                        })}
                                                  </div>
                                              </div>)}})}
                                           </div>
                                           </h6>
                                           :rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')] &&  rowData['data'][localStorage.getItem('i18nextLng')]?.standards?.split(',').map((item,index) =>(this.state.stdLinkKey[index] ?
                                            <h6 style={{ 'fontSize': 'smaller' }}>
                                              <a href={this.state.stdLinkKey ? this.state.stdLinkKey[index] : ''} target="_blank"><span style={{cursor: 'pointer', color: '#545cd8'}}>{item}</span></a>
                                            </h6>
                                            :
                                            <h6 style={{ 'fontSize': 'smaller' }}>
                                            <span style={{color: 'black'}}>{item}</span></h6>
                                           )) 

                                    }
                                        </div>
                                  </div>
                                  <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCss' : null}`} style={{'marginTop': '4px'}} >
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.authoritiesLable')} :<b style={{'fontSize': 'small'}}>*</b></h6>
                                        </div>
                                        <div className="col-sm-8">
                                            { this.props.formtype !== 'view' ?
                                                    <h6 style={{ 'fontSize': 'smaller' }}>
                                              <div className="inputs">
                                                    <div className="form-inline col-sm-12" style={{ 'padding': '0' }}>
                                                      <div className="form-inline col-sm-5">
                                                        <h6 style={{ 'fontSize': 'x-small' }}><b>{t('marketProfile.nameLabel')} </b><b style={{'fontSize': 'small'}}>*</b></h6>  
                                                      </div>
                                                      <div className="form-inline col-sm-5" style={{ 'paddingLeft': '55px' }}>
                                                          <h6 style={{ 'fontSize': 'x-small' }}><b>{t('marketProfile.urlLable')}</b></h6>
                                                      </div>
                                                      <div className="form-inline col-sm-2" style={{'paddingLeft' : '54px'}}>
                                                        <i className="pe-7s-plus addInput" style={{'cursor':'pointer'}}  onClick={this.addAut}></i>
                                                      </div>
                                                  </div>
                                                  {
                                                [...languageArr].map(lang =>{
                                                  if (lang.code === localStorage.getItem('i18nextLng')) {
                                                    return (
                                                  <div className="form-inline col-sm-12" style={{ 'padding': '0' }} key={this.state.authKey}>
                                                    <div className="form-inline col-sm-6" key={this.state.authKey}>
                                                      {this.state.authorities.map((Element, index) => { 
                                                              { 
                                                                return <DocumentInput
                                                                      key={index}
                                                                      change= {this.autInputChange(index,lang.code,'name')}
                                                                      click = {this.resetAuthorities(index,'name')}
                                                                      value={this.state.authKey[index]}
                                                                />}
                                                            })
                                                        }
                                                    </div>
                                                    <div className="form-inline col-sm-6" key={this.state.authLinkKey}>
                                                      { this.state.authorities.map((Element, index) => { 
                                                              { 
                                                                return <DocumentInput
                                                                        key={index}
                                                                        change= {this.autInputChange(index, lang.code,'url')}
                                                                        click = {this.resetAuthorities(index, 'url')}
                                                                        value={this.state.authLinkKey ? this.state.authLinkKey[index] : ''}
                                                                  />}
                                                        })}
                                                    </div>
                                                  </div>)}})}
                                              </div>
                                              </h6>
                                              :rowData['data'] && rowData['data'][localStorage.getItem('i18nextLng')] &&  rowData['data'][localStorage.getItem('i18nextLng')]?.authorities?.split(',').map((item,index) =>( this.state.authLinkKey[index] ?
                                                <h6 style={{ 'fontSize': 'smaller' }}>
                                                  <a href={this.state.authLinkKey ? this.state.authLinkKey[index] : ''} target="_blank"><span style={{cursor: 'pointer', color: '#545cd8'}}>{item}</span></a>
                                                </h6>
                                                :
                                                <h6 style={{ 'fontSize': 'smaller' }}>
                                                <span style={{color: 'black'}}>{item}</span></h6>
                                              )) 
                                              }
                                        </div>
                                       </div>
                                  <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCss' : null}`} style={{'marginTop': '4px'}} >
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.eceBasedLabel')} :<b style={{'fontSize': 'small'}}>*</b></h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                          return(
                                          <div className="col-sm-4">
                                              <h6 style={{'marginBottom': '5px', 'fontSize': 'smaller' }}>
                                               { this.props.formtype !== 'view' ?
                                                <textarea 
                                                  placeholder={i18next.t('marketProfile.ECE_BasedPlaceholder')}
                                                  className="form-control"
                                                  id={'ece_based'+lang.code}
                                                  value={rowData['data'] && rowData['data'][lang.code] &&  rowData['data'][lang.code].ece_based}
                                                  onChange={this.multiInputChange(lang.code, 'ece_based')}>
                                                </textarea>
                                                :  rowData['data'] && rowData['data'][lang.code] &&  rowData['data'][lang.code].ece_based
                                                }
                                              </h6>
                                          </div>
                                          )})
                                        }
                                  </div>
                                  <div className={`form-inline col-sm-12 labelText ${this.props.formtype === 'view' ? 'viewCss' : null}`} style={{'marginTop': '4px'}} >
                                        <div className="form-inline col-sm-4" style={{ 'padding': '0' }}>
                                            <h6 style={{ 'fontSize': 'smaller' }}>{t('marketProfile.furtherInfoLable')} :</h6>
                                        </div>
                                        {
                                         [...languageArr].map(lang =>{
                                          return(
                                          <div className="col-sm-4">
                                             <h6 style={{'marginBottom': '5px' , 'fontSize': 'smaller'}}>
                                               { this.props.formtype !== 'view' ?
                                                <textarea 
                                                 placeholder={i18next.t('marketProfile.futherInfoPlaceholder')}
                                                 id={'typeApprovalFurtherInformation'+lang.code}
                                                 value={rowData['data'] && rowData['data'][lang.code] &&  rowData['data'][lang.code].typeApprovalFurtherInformation}
                                                 onChange={this.multiInputChange(lang.code, 'typeApprovalFurtherInformation')}
                                                 className="form-control">
                                                </textarea>
                                                : rowData['data'] && rowData['data'][lang.code] &&  rowData['data'][lang.code].typeApprovalFurtherInformation
                                                }
                                              </h6>
                                            </div>
                                          )})
                                        }
                                  </div>
                                  <div className="form-inline col-sm-12">
                                        <div className="col-2 form-inline" style={{ 'padding': '0' }}>
                                          <h6  style={{ 'fontSize': 'small','marginBottom': '5px', 'marginTop': '5px' }}><b>{t('marketProfile.documents')}</b></h6>
                                        </div>
                                       <div className="col-10">
                                         <div id={'accordionActualReg'}>
                                            <div id={'headingAccordionActualReg'}>
                                              <h5 className="mb-0">
                                                 <button onClick={this.toggleIconDocuments.bind(this)} className="btn btn-link" data-toggle="collapse" data-target={'#collapseDocumentCard'} aria-expanded="true" aria-controls={'collapseDocumentCard'}>
                                                   {
                                                     this.state.toggleIconDocuments === false ?
                                                     <img src={RightArrow} style={{'height': '20px'}} />
                                                      :
                                                      <img src={DownArrow} style={{'height': '20px'}} />
                                                   }
                                                  </button>
                                               </h5>
                                            </div>
                                         </div>
                                      </div>
                                       <div id={'collapseDocumentCard'} className="card collapse col-sm-12 noPadding" aria-labelledby={'headingAccordionActualReg'} data-parent={'#accordionActualReg'}>
                                         <div className="card-body">
                                         {
                                             [...languageArr].map(lang =>{
                                              if (lang.code === localStorage.getItem('i18nextLng')) {
                                                return (
                                                   <div className="form-inline col-sm-12">
                                                     { this.props.formtype !== 'view' &&
                                                      <div className="labelTextCss addDocBtn">
                                                        <h6>
                                                          <Button outline color="secondary" onClick={() => this.openAddDocModal()}>{i18next.t('add')}</Button>
                                                        </h6>
                                                     </div>
                                                      }
                                                    <AdminDocumentTableComp  deleteRowIdArr={this.state.deleteDocIdArr} undoDeleteChanges={(row) => this.undoDeleteChanges(row)} downloadFile={(id, name) => this.downloadfileHandler(id,name)} marketCode={market.value} formtype={this.props.formtype} selectedDoc={this.state.selectedDoc}  dataType="marketProfile" openDeleteModal={(row, market)=> this.openDeleteModal(row,market)} onDocDelete={(row, market) => this.onDeleteDoc(row, market)} onDocEdit={(row) => this.docEditHandler(row)} documentsData={this.state.allDocs?.[lang.code]} />
                                                  </div>
                                                )
                                              }
                                            })
                                          }
                                         </div>
                                       </div>
                                   </div>
                                   <div className="form-inline col-sm-12">
                                       <div className="col-2 form-inline" style={{ 'padding': '0' }}>
                                         <h6  style={{ 'fontSize': 'small','marginBottom': '5px', 'marginTop': '5px' }}><b>{t('marketProfile.comments')}</b></h6>
                                        </div>
                                       <div className="col-10">
                                         <div id={'accordionComments'}>
                                            <div id={'headingAccordionActualReg'}>
                                              <h5 className="mb-0">
                                                 <button onClick={this.toggleIconComments.bind(this)} className="btn btn-link" data-toggle="collapse" data-target={'#collapseCommentsCard'} aria-expanded="true" aria-controls={'collapseCommentsCard'}>
                                                   {
                                                     this.state.toggleIconComments === false ?
                                                     <img src={RightArrow} style={{'height': '20px'}} />
                                                      :
                                                      <img src={DownArrow} style={{'height': '20px'}} />
                                                   }
                                                  </button>
                                               </h5>
                                            </div>
                                         </div>
                                      </div>
                                       <div id={'collapseCommentsCard'} className="card collapse col-sm-12 noPadding" aria-labelledby={'headingAccordionActualReg'} data-parent={'#accordionComments'}>
                                         <div className="card-body">
                                         {
                                             [...languageArr].map(lang =>{
                                              if (lang.code === localStorage.getItem('i18nextLng')) {
                                                return (
                                                   <div className="form-inline col-sm-12">
                                                          <div className="col-sm-8">
                                                              <h6 style={{'marginBottom': '5px' , 'fontSize': 'smaller'}}>
                                                                { this.props.formtype !== 'view' ?
                                                                <textarea 
                                                                  placeholder={i18next.t('mngRegulations.equivalentRegCommentsPlaceholder')}
                                                                  id={'typeApprovalFurtherInformation'+lang.code}
                                                                  onChange={this.multiInputChange(lang.code, 'comments')}
                                                                  value={rowData['data'] && rowData['data'][lang.code] &&  rowData['data'][lang.code].comments}
                                                                  className="form-control">
                                                                </textarea>
                                                                : rowData['data'] && rowData['data'][lang.code] &&  rowData['data'][lang.code].comments
                                                                }
                                                              </h6>
                                                            </div>
                                                    </div>
                                                 )
                                              }
                                            })
                                          }
                                         </div>
                                       </div>
                                   </div>
            
                                </Col>
                            </Row>
                        </div>
                        {
                         <ul>
                           {Object.keys(this.state.fieldMsg).map(errKey =>{
                              if (this.state.fieldMsg[errKey] !== '') {
                                return(
                                 <li id={errKey+'Err'} style={{'color':'red'}}>{this.state.fieldMsg[errKey]}</li>
                                )
                              }
                           })}
                         </ul>
                        }
                      <hr />
                        <div className="form-group row" id="addMarketFormBtnDiv">
                            <div className="col-12">
                                { this.props.formtype !== 'view'  &&
                                <div style={{'marginBottom' : '10px'}}>
                                <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
                                <Button color="primary" onClick={this.SaveFormHandler.bind(this)} disabled={this.props.loading}>{this.props.loading  ? i18next.t('savingData') : i18next.t('save')}</Button>
                                { this.props.formtype === 'edit'  &&
                                <Button color="danger" style={{'float': 'left', 'backgroundColor': 'red'}} onClick={this.DeleteHandler.bind(this)}>{i18next.t('delete')}</Button>
                                }
                                </div>
                                }
                               
                            </div>
                        </div>
                        </Loader>
                    </form>
                </Fragment>
            )}</Translation>
        );
    }
}

const mapStateToProps = (appState: AppState) => ({
  RoadConditionList : appState.admin.RoadConditionList,
  AvgTempList: appState.admin.AvgTempList,
  HandDriveList: appState.admin.HandDriveList,
  HumidityList: appState.admin.HumidityList,
  CertificationList: appState.admin.CertificationList,
  EnvConditionList: appState.admin.EnvConditionList, 
  AirConditionList: appState.admin.AirConditionList,
  marketProfileAdded: appState.admin.marketProfileAdded,
  marketProfileFileUploaded: appState.admin.marketProfileFileUploaded,
  MarketProfileList: appState.admin.MarketProfileList,
  marketProfileID: appState.admin.marketProfileID,
  RegulationCountList : appState.user.RegulationCountList,
  loading: appState.loading.loading,
  uploadErrorMessage: appState.admin.MarketProfileDocUploadRequest.$errorMessage,
  editErrorMessage: appState.admin.MarketProfileDocEditRequest.$errorMessage,
  deleteErrorMessage: appState.admin.MarketProfileDocDeleteRequest.$errorMessage,
});

const mapDispatchToProps = dispatch => ({
  createMarketProfile : (marketProfile,isFileAdded) => Actions.admin.createMarketProfile(dispatch,marketProfile,isFileAdded),
  uploadMarketProfileFiles: (files, filename) => Actions.admin.uploadMarketProfileFiles(dispatch, files, filename),
  editMarketProfileFiles: (files, id, filename) => Actions.admin.editMarketProfileFiles(dispatch, files, id, filename),
  deleteMarketProfileFiles: (id, filename) => Actions.admin.deleteMarketProfileFiles(dispatch, id, filename),
  editMarketProfile: (marketProfile,isFileAdded) => Actions.admin.editMarketProfile(dispatch, marketProfile,isFileAdded),
  deleteMarketProfile: (marketId,marketProfileId) => Actions.admin.deleteMarketProfile(dispatch, marketId,marketProfileId),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketProfileForm);
