import i18next from 'i18next';
import {RegulationsColDataMoreLessEvents} from './../ManageRegulations/RegulationsColData';

//Manage scopes table columns
export const ScopesColData = () => {
  return [
    {
        dataField: 'rowId',
        isKey: true,
        label: i18next.t('id'),
        hidden: true,
        placeholder: i18next.t('idPlaceholder')
    },
    {
        isKey: false,
        label: 'Action',
        hidden: false
    },
    {
        dataField: 'name',
        isKey: false,
        label: i18next.t('table.scopeSubscope'),
        dataFormat: (cell, row) =>{
          RegulationsColDataMoreLessEvents(row, 'scopeName');
          return row.data && row.data[localStorage.getItem('i18nextLng')] && row.data[localStorage.getItem('i18nextLng')].name ? `<span id=${row.id+'togglescopeName'} class="truncateCss">${row.data[localStorage.getItem('i18nextLng')].name}</span>` : null
        },
        hidden: false,
        placeholder: i18next.t('mngScope.scopePlaceholder')
    },
    {
        dataField: 'type',
        isKey: false,
        label: i18next.t('table.scopeSubscopeType'),
        dataFormat: (cell, row) =>{
          RegulationsColDataMoreLessEvents(row, 'scopeType');
          let rowSubScopeData = row.data && row.data[localStorage.getItem('i18nextLng')] && row.data[localStorage.getItem('i18nextLng')].type ? (row.data[localStorage.getItem('i18nextLng')].parentScopeName ? row.data[localStorage.getItem('i18nextLng')].type + ` ( ${row.data[localStorage.getItem('i18nextLng')].parentScopeName} )`  : row.data[localStorage.getItem('i18nextLng')].type) : '';
          return `<span id=${row.id+'togglescopeType'} class="truncateCss">${rowSubScopeData}</span>`
        },
        hidden: false,
        placeholder: i18next.t('mngScope.scopeTypePlaceholder')
    }
  ]
}

export const languageArr = [
  {
    id:1,
    code:"en"
  },
  {
    id:2,
    code:"de"
  }
];
