import React, { Fragment, Component } from "react";
import { withRouter } from "react-router-dom";
import Actions from "./../../internal/modules/Actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import i18next from "i18next";
import UserDashboard from "./UserDashboard";
import { Translate } from "../../model/interfaces/translate.interface";
import { Translation } from "react-i18next";
import { AppState } from "../../internal/modules/Reducers";
import AdminDashboard from "./AdminDashboard";

interface Props extends Translate {
  history?: any;
  reloadState: number;
  logout: () => {};
  getUserById: (id: number) => {};
  user?: any;
  getNotificationList: () => {};
}

interface State {
  user: {};
  errorMessageUserId: string;
}
class Dashboards extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      errorMessageUserId: null,
    };
  }

  componentDidMount() {
    if (this.props.user) {
      /* Resetting localStorage content if user try to edit manually to avoid data manipulation.
           The storage event is triggered if there is any change in the localStorage content */
      window.addEventListener("storage", (e) => {
        let user = JSON.parse(localStorage.getItem("user"));
        let selectedRole = sessionStorage.getItem("selectedRole");
        if (user !== null && user.id) {
          this.props.getUserById(user.id);
        } else {
          this.props.logout();
        }
      });
    }
    this.props.getNotificationList();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      errorMessageUserId: nextProps.errorMessageUserId,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.reloadState !== prevProps.reloadState) {
      this.setState({ user: JSON.parse(localStorage.getItem("user")) });
      this.renderDashboardBasedOnRole();
    }
    if (
      this.state.errorMessageUserId &&
      this.state.errorMessageUserId !== prevState.errorMessageUserId
    ) {
      toast["error"](i18next.t(this.state.errorMessageUserId), {
        position: "top-right",
      });
    }
  }

  renderDashboardBasedOnRole = () => {
    let selectedRole = sessionStorage.getItem("selectedRole");
    let user = JSON.parse(localStorage.getItem("user"));

    //Loading dashboard based on user role
    switch (selectedRole) {
      case "admin":
        return <AdminDashboard {...this.props} />;
      case "user":
        return <UserDashboard {...this.props} />;
      default:
        return null;
    }
  };

  render() {
    return this.renderDashboardBasedOnRole();
  }
}

const mapStateToProps = (appState: AppState) => ({
  reloadState: appState.user.reloadState,
  user: appState.user.user,
  errorMessageUserId: appState.user.UserByIdRequest.$errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => Actions.user.logout(dispatch),
  getUserById: (userId) => Actions.user.getUserById(userId, dispatch),
  getNotificationList: () => Actions.common.getNotificationList(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboards));
