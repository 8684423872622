import React, {Fragment} from 'react';
import cx from 'classnames';

import {connect} from 'react-redux';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import HeaderLogo from './../AppLogo';

import UserBox from './Components/UserBox';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import HeaderDots from "./Components/HeaderDots";

interface Props extends Translate{
  headerBackgroundColor: string,
  enableMobileMenuSmall: string,
  enableHeaderShadow: string
}

interface State{
  data : string
}
class Header extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = {
           data : ''
        };
    }
    render() {
        let {
            headerBackgroundColor,
            enableMobileMenuSmall,
            enableHeaderShadow
        } = this.props;
        
        const handleHomeIcon = (data) =>{
            this.setState({data: data})
        }
        return (
            <Fragment>
                <Translation>{t => (<ReactCSSTransitionGroup
                    component="div"
                    className={cx("app-header", headerBackgroundColor, "header-shadow")}
                    transitionName="HeaderAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}>

                    <HeaderLogo {...this.props}/>

                    <div className={cx(
                        "app-header__content",
                        {'header-mobile-open': enableMobileMenuSmall},
                    )}>
                        <div className="app-header-right">
                            <HeaderDots data={this.state.data}/>
                            <UserBox callBack={handleHomeIcon} data={this.state.data}/>
                        </div>
                    </div>
                </ReactCSSTransitionGroup>)}</Translation>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
