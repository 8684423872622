import ModuleSet from "./AppModules";
const _ = require("lodash");

const handlerModule = {
    common: require('../../network/common/Handlers'),
    user: require('../../network/user/Handlers'),
    admin: require('../../network/admin/Handlers'),
    loading: require('../../network/loading/Handlers'),
};
export const Handlers = _(ModuleSet)
    .keyBy(module => module)
    .mapValues(module => {
        try {
            const moduleHandler = handlerModule[module];
            return moduleHandler.default;
        } catch (e) {
            return {default: {}};
        }
    })
    .value();
export const AllHandlers = _(Handlers)
    .transform((result, handler) => {
        _.mapKeys(handler, (fn, key) => {
            if (key === "default") {
                return;
            }
            result[key] = result[key] ? _.concat(result[key], fn) : [fn];
        });
        return result;
    }, {})
    .value();
export default Handlers;
