import { ModuleEvents as ModuleEventsLoading } from '../loading/Actions';
import { ModuleEvents as ModuleEventsCommon } from '../common/Actions';
import UserService from '../../internal/common/UserService';


export const ModuleEvents = {
    LOGIN: 'LOGIN',
    ERRORLOGIN: 'ERRORLOGIN',
    RESET_LOGIN: 'RESET_LOGIN',

    REGULATION_LIST: 'REGULATION_LIST',
    ERROR_REGULATION_LIST: 'ERROR_REGULATION_LIST',
    RESET_REGULATION_LIST_STATUS: 'RESET_REGULATION_LIST_STATUS',

    REGULATION_COUNT: 'REGULATION_COUNT',
    ERROR_REGULATION_COUNT: 'ERROR_REGULATION_COUNT',

    FAVORITES_LIST : 'FAVORITES_LIST',
    ERROR_FAVORITES_LIST : 'ERROR_FAVORITES_LIST',
    RESET_FAVORITES_LIST : 'RESET_FAVORITES_LIST',

    DELETE_FAVORITES : 'DELETE_FAVORITES',
    ERROR_DELETE_FAVORITES : 'ERROR_DELETE_FAVORITES',

    VEHICLE_LIST: 'VEHICLE_LIST',
    ERROR_VEHICLE_LIST: 'ERROR_VEHICLE_LIST',
    RESET_VEHICLE_STATUS: 'RESET_VEHICLE_STATUS',

    MARKET_LIST:'MARKET_LIST',
    ERROR_MARKET_LIST: 'ERROR_MARKET_LIST',
    RESET_MARKET_STATUS: 'RESET_MARKET_STATUS',

    SAVE_FILTER: 'SAVE_FILTER',
    ERROR_SAVE_FILTER: 'ERROR_SAVE_FILTER',
    RESET_SAVE_FILTER_STATUS: 'RESET_SAVE_FILTER_STATUS',

    TOPIC_LIST: 'TOPIC_LIST',
    ERROR_TOPIC_LIST: 'ERROR_TOPIC_LIST',

    TAB_CHANGE : 'TAB_CHANGE',

    LOGOUT: 'LOGOUT',
    ERROR_LOGOUT: 'ERROR_LOGOUT',

    UPDATE_FAVORITES: 'UPDATE_FAVORITES',
    ERROR_UPDATE_FAVORITES: 'ERROR_UPDATE_FAVORITES',
    RESET_UPDATE_FAVORITES_LIST: 'RESET_UPDATE_FAVORITES_LIST',

    ALL_LANG_DATA: 'ALL_LANG_DATA',
    ERROR_ALL_LANG_DATA: 'ERROR_ALL_LANG_DATA',
    RESET_ALL_LANG_DATA: 'RESET_ALL_LANG_DATA',

    USERS: 'USERS',
    ERROR_USERS: 'ERROR_USERS',

    SEARCH_TAG_LIST: 'SEARCH_TAG_LIST',
    ERROR_SEARCH_TAG_LIST: 'ERROR_SEARCH_TAG_LIST',

    SHARE_FILTER: 'SHARE_FILTER',
    ERROR_SHARE_FILTER: 'ERROR_SHARE_FILTER',
    RESET_SHARE_FILTER: 'RESET_SHARE_FILTER',

    VERIFY_USER: 'VERIFY_USER',
    ERROR_VERIFY_USER: 'ERROR_VERIFY_USER',

    GET_TIMELINE_DATA: 'GET_TIMELINE_DATA',
    ERROR_GET_TIMELINE_DATA: 'ERROR_GET_TIMELINE_DATA',
    RESET_GET_TIMELINE_DATA: 'RESET_GET_TIMELINE_DATA',

    UPDATE_PASSWORD: 'UPDATE_PASSWORD',
    ERROR_UPDATE_PASSWORD: 'ERROR_UPDATE_PASSWORD',

    VERIFY_SESSION: 'VERIFY_SESSION',

    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    ERROR_FORGOT_PASSWORD: 'ERROR_FORGOT_PASSWORD',
    RESET_FORGOT_PASSWORD: 'RESET_FORGOT_PASSWORD',

    RESET_PASSWORD: 'RESET_PASSWORD',
    ERROR_RESET_PASSWORD: 'ERROR_RESET_PASSWORD',
    RESET_RESET_PASSWORD: 'RESET_RESET_PASSWORD',

    RELOAD_UI: 'RELOAD_UI',

    GET_USER_BY_ID: 'GET_USER_BY_ID',
    ERROR_GET_USER_BY_ID: 'ERROR_GET_USER_BY_ID',
    RESET_GET_USER_BY_ID: 'RESET_GET_USER_BY_ID',

    GET_REG_DETAIL_BY_ID: 'GET_REG_DETAIL_BY_ID',
    ERROR_GET_REG_DETAIL_BY_ID: 'ERROR_GET_REG_DETAIL_BY_ID',
    RESET_GET_REG_DETAIL_BY_ID: 'RESET_GET_REG_DETAIL_BY_ID',

    GROUP_LIST: 'GROUP_LIST',
    ERROR_GROUP_LIST : 'ERROR_GROUP_LIST',
    RESET_GROUP_STATUS: 'RESET_GROUP_STATUS',

    VEHICLE_FUEL_TYPE_LIST: 'VEHICLE_FUEL_TYPE_LIST',
    ERROR_VEHICLE_FUEL_TYPE_LIST: 'ERROR_VEHICLE_FUEL_TYPE_LIST',
    RESET_VEHICLE_FUEL_TYPE_LIST_STATUS: 'RESET_VEHICLE_FUEL_TYPE_LIST_STATUS',

    DRIVE_TYPE_LIST: 'DRIVE_TYPE_LIST',
    ERROR_DRIVE_TYPE_LIST: 'ERROR_DRIVE_TYPE_LIST',

    SCOPE_LIST: 'SCOPE_LIST',
    ERROR_SCOPE_LIST : 'ERROR_SCOPE_LIST',
    RESET_SCOPE_LIST_STATUS: 'RESET_SCOPE_LIST_STATUS',

    TIME_MATRIX_LIST: 'TIME_MATRIX_LIST',
    ERROR_TIME_MATRIX_LIST: 'ERROR_TIME_MATRIX_LIST',
    RESET_TIME_MATRIX_LIST_STATUS: 'RESET_TIME_MATRIX_LIST_STATUS',

    GET_REF_REG_DETAIL_LIST: 'GET_REF_REG_DETAIL_LIST',
    ERROR_GET_REF_REG_DETAIL_LIST: 'ERROR_GET_REF_REG_DETAIL_LIST',
    RESET_GET_REF_REG_DETAIL_LIST: 'RESET_GET_REF_REG_DETAIL_LIST',

    GET_REF_REG_DETAIL: 'GET_REF_REG_DETAIL',
    ERROR_GET_REF_REG_DETAIL: 'ERROR_GET_REF_REG_DETAIL',
    RESET_GET_REF_REG_DETAIL: 'RESET_GET_REF_REG_DETAIL',

    GET_MARKET_PROFILE: 'GET_MARKET_PROFILE',
    ERROR_GET_MARKET_PROFILE: 'ERROR_GET_MARKET_PROFILE',
    RESET_GET_MARKET_PROFILE: 'RESET_GET_MARKET_PROFILE',

    ADD_SUBSCRIPTION: 'ADD_SUBSCRIPTION',
    ERROR_ADD_SUBSCRIPTION: 'ERROR_ADD_SUBSCRIPTION',
    RESET_ADD_SUBSCRIPTION: 'RESET_ADD_SUBSCRIPTION',

    GET_SUBSCRIBED_TOPICS : 'GET_SUBSCRIBED_TOPICS',
    ERROR_GET_SUBSCRIBED_TOPICS : 'ERROR_GET_SUBSCRIBED_TOPICS',

    GET_REGULATION_UPDATES:'GET_REGULATION_UPDATES',
    ERROR_GET_REGULATION_UPDATES:'ERROR_GET_REGULATION_UPDATES',

    GET_SUBSCRIPTIONS:'GET_SUBSCRIPTIONS',
    ERROR_GET_SUBSCRIPTIONS:'ERROR_GET_SUBSCRIPTIONS',

    GET_FAQ:'GET_FAQ',
    ERROR_GET_FAQ:'ERROR_GET_FAQ',

    UPDATE_RECENT_ACTIVITY : 'UPDATE_RECENT_ACTIVITY',
    ERROR_UPDATE_RECENT_ACTIVITY : 'ERROR_UPDATE_RECENT_ACTIVITY',

    GET_RECENT_GPT_QUESTIONS: 'GET_RECENT_GPT_QUESTIONS',
    ERROR_GET_RECENT_GPT_QUESTIONS: 'ERROR_GET_RECENT_GPT_QUESTIONS',

    REMOVE_RECENT_GPT_QUESTIONS: 'REMOVE_RECENT_GPT_QUESTIONS',
    ERROR_REMOVE_RECENT_GPT_QUESTIONS: 'ERROR_REMOVE_RECENT_GPT_QUESTIONS'
};

export default {

    //Setting admin role(admin or user) and showing UI as per selected role from the user profile tab
    reloadTemplate: (role, dispatch) =>{
      dispatch({type: ModuleEvents.RELOAD_UI, payload: role});
    },

    //Login api
    login: (user, dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        UserService.login(user).then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.LOGIN, payload: response.data});
        }, (error) => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERRORLOGIN, payload: error})
        })
    },

    /* Fetching regulations attribute data in all the languages entered by admin.
       This api is called only if the attribute data is missing for the app lang which the user has selected. */
    getAllLangData: (regulation, paramObj, dispatch) => {
      dispatch({type: ModuleEventsLoading.LOADING});
      UserService.getAllLangData(regulation, paramObj).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ALL_LANG_DATA, payload: response.data})
      }, error => {
         dispatch({type: ModuleEventsLoading.FINISHLOADING});
         dispatch({type: ModuleEvents.ERROR_ALL_LANG_DATA, payload: error})
      })
    },

    //Clearing token and user data from localStorage and redirecting to login page.
    logout: (dispatch) => {
       dispatch({type: ModuleEvents.LOGOUT})
    },

    //Fetching regulations data shown to user in a table
    getRegulationDetails: (filter, dispatch) => {
      dispatch({type: ModuleEventsLoading.LOADING});
      UserService.getRegulationDetails(filter).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.REGULATION_LIST, payload: response.data})
      }, error => {
         dispatch({type: ModuleEventsLoading.FINISHLOADING});
         dispatch({type: ModuleEvents.ERROR_REGULATION_LIST, payload: error})
      })
    },

    //Fetching vehicle category list added by admin shown in toggle filter dropdown
    getVehicleList: (dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        return UserService.getVehicleList().then(response => {
           dispatch({type: ModuleEventsLoading.FINISHLOADING});
           dispatch({type: ModuleEvents.VEHICLE_LIST, payload: response.data})
        }, error => {
           dispatch({type: ModuleEventsLoading.FINISHLOADING});
           dispatch({type: ModuleEvents.ERROR_VEHICLE_LIST, payload: error})
       })
      },

      //Fetching favorites list marked by user
      getFavoritesList: (dispatch,user) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        return UserService.getFavoritesList(user).then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.FAVORITES_LIST, payload: response.data})
        }, (error) => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_FAVORITES_LIST, payload: error})
        })
    },

    //Deleting favorites from the list
    deleteFavoritesList: (favorites,dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        UserService.deleteFavoritesList(favorites).then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.DELETE_FAVORITES, payload:{id:favorites}})
        }, (error) => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_DELETE_FAVORITES, payload: error})
        })
    },

    //Fetching total regulations added by admin market wise visible while hover on map tab in user dashboard
     getRegulationCount: (dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        UserService.getRegulationCount().then(response => {
           dispatch({type: ModuleEventsLoading.FINISHLOADING});
           dispatch({type: ModuleEvents.REGULATION_COUNT, payload: response.data})
        }, error => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_REGULATION_COUNT, payload: error})
        })
     },

     //Fetching group list
     getGroupList: (dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        UserService.getGroupList().then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.GROUP_LIST, payload: response.data})
         }, error => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_GROUP_LIST, payload: error})
         })
    },

    //Fetching drive types added by admin
    getDriveTypeList: (dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        UserService.getDriveTypeList().then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.DRIVE_TYPE_LIST, payload: response.data})
         }, error => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_DRIVE_TYPE_LIST, payload: error})
         })
    },

    //Fetching vehicle fuel types added by admin
    getVehicleFuelTypeList: (dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        UserService.getVehicleFuelTypeList().then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.VEHICLE_FUEL_TYPE_LIST, payload: response.data})
         }, error => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_VEHICLE_FUEL_TYPE_LIST, payload: error})
         })
    },

     //Fetching markets added by admin
     getMarketList: (dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        UserService.getMarketList().then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.MARKET_LIST, payload: response.data})
         }, error => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_MARKET_LIST, payload: error})
         })
      },

      //Fetching topics added by admin
      getTopicList: (dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        UserService.getTopicList().then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.TOPIC_LIST, payload: response.data})
         }, error => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_TOPIC_LIST, payload: error})
         })
      },

     //Save search criteria as favorites with a name and can be later found in the favorites tab in user dashboard
     saveSearchFilter: (filter, dispatch) => {
      dispatch({type: ModuleEventsLoading.LOADING});
      UserService.saveSearchFilter(filter).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.SAVE_FILTER, payload: response.data})
       }, error => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_SAVE_FILTER, payload: error})
       })
     },

     //Setting active tab key
     getTabChange: (tab,dispatch) => {
      dispatch({type: ModuleEvents.TAB_CHANGE, payload: tab})
     },

    //Edit already saved favorites
    editSaveFilter: (dispatch, favorites) => {
        dispatch({type: ModuleEventsLoading.LOADING})
        return UserService.editSaveFilter(favorites).then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.UPDATE_FAVORITES, payload: response.data})
        }, (error) => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_UPDATE_FAVORITES, payload: error})
        })
    },

    //Fetching users list added by admin to share favorites with other users
    getUserList: dispatch => {
        dispatch({type: ModuleEventsLoading.LOADING})
        return UserService.getUserList().then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.USERS, payload: response.data})
        }, (error) => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_USERS, payload: error})
        })
    },

    //Fetching tags list added by admin from GRM template
    getSearchTagList: (dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        return UserService.getSearchTagList().then(response => {
           dispatch({type: ModuleEventsLoading.FINISHLOADING});
           dispatch({type: ModuleEvents.SEARCH_TAG_LIST, payload: response.data})
        }, error => {
           dispatch({type: ModuleEventsLoading.FINISHLOADING});
           dispatch({type: ModuleEvents.ERROR_SEARCH_TAG_LIST, payload: error})
       })
      },

    //Api to share favorites with other users via email
    shareFilter: (dispatch,filter) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        UserService.shareFilter(filter).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.SHARE_FILTER, payload: response.data})
         }, error => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_SHARE_FILTER, payload: error})
         })
       },

    //Verifiying user on click of the email verification link sent by the admin via email
    userVerification: (dispatch, uniqueID) => {
        dispatch({type: ModuleEventsLoading.LOADING})
        return UserService.userVerification(uniqueID).then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.VERIFY_USER, payload: response.data})
        }, (error) => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_VERIFY_USER, payload: error})
        })
    },

    //Fetching regulations timeline data shown in Reports tab in user dashboard
    getTimelineData: (timeline, dispatch) => {
         dispatch({type: ModuleEventsLoading.LOADING});
         UserService.getTimelineData(timeline).then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.GET_TIMELINE_DATA, payload: response.data})
         }, error => {
             dispatch({type: ModuleEventsLoading.FINISHLOADING});
             dispatch({type: ModuleEvents.ERROR_GET_TIMELINE_DATA, payload: error})
         })
      },

    //Edit password api
    editPassword: (dispatch, password) => {
        dispatch({type: ModuleEventsLoading.LOADING})
        return UserService.editPassword(password).then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.UPDATE_PASSWORD, payload: response.data})
        }, (error) => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_UPDATE_PASSWORD, payload: error})
        })
    },

    //Reset password link sent to the email entered
    forgotPassword: (dispatch,email) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        UserService.forgotPassword(email).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.FORGOT_PASSWORD, payload: response.data})
         }, error => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_FORGOT_PASSWORD, payload: error})
         })
     },

     //Reset new password api
     resetPassword: (dispatch,password) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        UserService.resetPassword(password).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.RESET_PASSWORD, payload: response.data})
         }, error => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_RESET_PASSWORD, payload: error})
         })
    },

    //Fetch user details by ID
    getUserById: (userId, dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        UserService.getUserById(userId).then(response => {
           dispatch({type: ModuleEventsLoading.FINISHLOADING});
           dispatch({type: ModuleEvents.GET_USER_BY_ID, payload: response.data})
        }, error => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_GET_USER_BY_ID, payload: error})
        })
    },

    //Fetching regulation number details
    getRegulationDetailsById: (regulationID, dispatch) => {
      dispatch({type: ModuleEventsLoading.LOADING});
      UserService.getRegulationDetailsById(regulationID).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.GET_REG_DETAIL_BY_ID, payload: response.data})
      }, error => {
         dispatch({type: ModuleEventsLoading.FINISHLOADING});
         dispatch({type: ModuleEvents.ERROR_GET_REG_DETAIL_BY_ID, payload: error})
      })
    },

    //Fetching scopes added by admin
    getScopeList: (dispatch) => {
       dispatch({type: ModuleEventsLoading.LOADING});
       UserService.getScopeList().then(response => {
           dispatch({type: ModuleEventsLoading.FINISHLOADING});
           dispatch({type: ModuleEvents.SCOPE_LIST, payload: response.data})
        }, error => {
           dispatch({type: ModuleEventsLoading.FINISHLOADING});
           dispatch({type: ModuleEvents.ERROR_SCOPE_LIST, payload: error})
        })
   },

   //Fetching timeline matrix regulations shown in Timeline Matrix tab
   getTimeMatrixList: (filter, dispatch) => {
     dispatch({type: ModuleEventsLoading.LOADING});
     UserService.getTimeMatrixList(filter).then(response => {
         dispatch({type: ModuleEventsLoading.FINISHLOADING});
         dispatch({type: ModuleEvents.TIME_MATRIX_LIST, payload: response.data})
     }, error => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_TIME_MATRIX_LIST, payload: error})
     })
   },

   getReferenceRegulationDetails: (regulationNumber, dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    UserService.getReferenceRegulationDetails(regulationNumber).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GET_REF_REG_DETAIL_LIST, payload: response.data})
    }, error => {
       dispatch({type: ModuleEventsLoading.FINISHLOADING});
       dispatch({type: ModuleEvents.ERROR_GET_REF_REG_DETAIL_LIST, payload: error})
    })
  },
  getReferenceRegulation: (regulationNumber, dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    UserService.getReferenceRegulationDetails(regulationNumber).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GET_REF_REG_DETAIL, payload: response.data})
    }, error => {
       dispatch({type: ModuleEventsLoading.FINISHLOADING});
       dispatch({type: ModuleEvents.ERROR_GET_REF_REG_DETAIL, payload: error})
    })
  },
  getMarketProfile: (marketID, dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    UserService.getMarketProfile(marketID).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GET_MARKET_PROFILE, payload: response.data})
    }, error => {
       dispatch({type: ModuleEventsLoading.FINISHLOADING});
       dispatch({type: ModuleEvents.ERROR_GET_MARKET_PROFILE, payload: error})
    })
  },
  subscibeTopics: (id, dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    UserService.subscibeTopics(id).then(response => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ADD_SUBSCRIPTION, payload: response.data})
     }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_ADD_SUBSCRIPTION, payload: error})
     })
 },
 getSubscibedTopics: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    UserService.getSubscibedTopics().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GET_SUBSCRIBED_TOPICS, payload: response.data})        
    }, error => {
       dispatch({type: ModuleEventsLoading.FINISHLOADING});
       dispatch({type: ModuleEvents.ERROR_GET_SUBSCRIBED_TOPICS, payload: error})
    })
  },
  getSubscriptions: (lastLogin, dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    UserService.getSubscriptions(lastLogin)
    .then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GET_SUBSCRIPTIONS, payload: response.data })
    },error => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_GET_SUBSCRIPTIONS,payload: error})
    })
},

  getRegulationUpdates: (lastLogin,dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    UserService.getRegulationUpdates(lastLogin)
    .then(response =>{
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GET_REGULATION_UPDATES, payload: response.data})
    },error => {
       dispatch({type: ModuleEventsLoading.FINISHLOADING});
       dispatch({type: ModuleEvents.ERROR_GET_REGULATION_UPDATES, payload: error})
    })
  },

  getFaqList:(dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    UserService.getFaqList()
    .then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GET_FAQ,payload: response.data})
    }, error => {
       dispatch({type: ModuleEventsLoading.FINISHLOADING});
       dispatch({type: ModuleEvents.ERROR_GET_FAQ, payload: error})
    })
  },
  editUserList: (dispatch, user) => {
    dispatch({type: ModuleEventsLoading.LOADING})
    return UserService.editUserList(user).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.UPDATE_RECENT_ACTIVITY, payload: response.data})
    }, (error) => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_UPDATE_RECENT_ACTIVITY, payload: error})
    })
},
getRecentGPTQuestions: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    UserService.getRecentGPTQuestions().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GET_RECENT_GPT_QUESTIONS, payload: response.data})        
    }, error => {
       dispatch({type: ModuleEventsLoading.FINISHLOADING});
       dispatch({type: ModuleEvents.ERROR_GET_RECENT_GPT_QUESTIONS, payload: error})
    })
  },

 //loading
 isLoading: (dispatch) => {
    return dispatch({type: ModuleEventsLoading.LOADING })
 },

 removeRecentGPTQ: (id, dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    UserService.removeRecentGPTQ(id).then(response => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.REMOVE_RECENT_GPT_QUESTIONS, payload: response.data})
     }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_REMOVE_RECENT_GPT_QUESTIONS, payload: error})
     })
 },

 //finish loading
 finishLoading: (dispatch) => {
    return dispatch({type: ModuleEventsLoading.FINISHLOADING })
 }
  };
