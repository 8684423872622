import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import Actions from '../../internal/modules/Actions';
import {Button} from 'reactstrap';
import Form from "react-bootstrap/Form";
import $ from 'jquery';
import {languageArr} from './GroupsColData';
import GroupList from './../../model/GroupList';
import i18next from 'i18next';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './group.css';

interface Props extends Translate{
  rowData?: any,
  formtype?: any;
  closeModal?: any;
  createGroup: (any) => {};
  editGroup: (any) => {};
  GroupList: GroupList[];
}

interface State {
  rowData?: any;
  fieldMsg?: any;
  isSubgroupChecked: boolean;
  selectedGroup?: any;
  optionsGroupList?: any;
}

class GroupForm extends Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        rowData: this.props.rowData,
        fieldMsg:{
          name:'',
          group:''
        },
        isSubgroupChecked:false,
        selectedGroup:[],
        optionsGroupList:[]
      }
  }

   componentDidMount(){
     let {rowData} = this.state;
     //Storing row data in state when formtype is edit
     if (this.props.formtype === 'edit') {
       let selectedGroupObj = {};
       let noGroupFlag = true;
       if (this.props.rowData.data && this.props.rowData.data['en'].type &&
           (this.props.rowData.data['en'].type === 'Sub-group')) {
         this.setState({isSubgroupChecked: true});
         [...languageArr].filter((lang) =>{
           if (!selectedGroupObj[lang.code]) {
             selectedGroupObj[lang.code] = [];
           }
           this.props.GroupList && [...this.props.GroupList].map(item =>{
               if ((this.props.rowData['data'][lang.code]['parentGroupId'] > 0) &&
                  (item.id === this.props.rowData['data'][lang.code]['parentGroupId'])) {
               let obj = {
                 id: item['id'],
                 name: item['data'][lang.code]['name'],
                 parentGroupName: item['data'][lang.code]['parentGroupName'],
                 parentGroupId: item['data'][lang.code]['parentGroupId'],
                 type: item['data'][lang.code]['type'],
                 languageCode: lang.code
               }
               selectedGroupObj[lang.code].push(obj);
               noGroupFlag = false;
             }
           });
           if (this.props.rowData.data[lang.code].type === 'Sub-group' && noGroupFlag) {
              rowData['data'][lang.code]['parentGroupName'] = null;
              rowData['data'][lang.code]['parentGroupId'] = 0;
              rowData['data'][lang.code]['type'] = 'Sub-group';
           }
         });
       }
       this.setState({selectedGroup: selectedGroupObj});
     }
     let groupListArr = {};
     if (this.props.formtype === 'add') {
       rowData['data'] = {};
     }
     //Setting blank rowdata value in state when formtype is add
     [...languageArr].filter((lang) =>{
       if (this.props.formtype === 'add') {
         rowData['data'][lang.code] = {};
         rowData['data'][lang.code]['parentGroupName'] = null;
         rowData['data'][lang.code]['parentGroupId'] = 0;
         rowData['data'][lang.code]['name'] = '';
         rowData['data'][lang.code]['type'] = 'Group';
       }
       if (!groupListArr[lang.code]) {
         groupListArr[lang.code] = [];
       }
       //Restructuring groups data in lang specific format => {en:{...}, de:{...}}
       this.props.GroupList && [...this.props.GroupList].map(item =>{
         if (item['data'] && item['data'][lang.code] && item['data'][lang.code]['type'] === 'Group') {
           let obj = {};
           obj['id'] = item['id'];
           obj['name'] = item['data'][lang.code].name;
           obj['parentGroupName'] = item['data'][lang.code].parentGroupName;
           obj['parentGroupId'] = item['data'][lang.code].parentGroupId;
           obj['type'] = item['data'][lang.code].type;
           obj['languageCode'] = lang.code;
           groupListArr[lang.code].push(obj);
         }
       });
     });
     this.setState({optionsGroupList: groupListArr, rowData: rowData});
   }

   // setting the group name value on change.
   inputHandler = (id, lang, event) =>{
     let arr = Object.keys(this.state.rowData).length < 1 ? {data:{}} : {...this.state.rowData};
     if (!arr.data[lang]) {
       arr.data[lang] = {};
     }
     arr.data[lang][id] = event.target.value;
     this.setState({rowData: arr});
   }

   // select group if type is sub-group.
   eventHandler = (id, lang, event) =>{
     let dataObj = {...this.state.rowData};
     let dataProps = this.state.optionsGroupList;
     let selectedGroupObj = {};
     if (!selectedGroupObj[lang]) {
       selectedGroupObj[lang] = [];
     }
     selectedGroupObj[lang] = event;
     if (!dataObj['data'][lang]) {
       dataObj['data'][lang] = {};
     }
     dataObj['data'][lang][id] = event.length > 0 ? event[0].name : null;
     if (id === 'parentGroupName') {
       dataObj['data'][lang]['parentGroupId'] = event.length > 0 ? event[0].id : null;
     }

     //Looping through languages and auto-filling respective dropdown value of other languages
     [...languageArr].map(allLang =>{
       if (allLang.code !== lang) {
         dataProps[allLang.code] && dataProps[allLang.code].filter(item => {
           if (event.length > 0 && (item.id === event[0].id)) {
             if (!selectedGroupObj[allLang.code]) {
               selectedGroupObj[allLang.code] = [];
             }
             selectedGroupObj[allLang.code].push(item);
             if (!dataObj['data'][allLang.code]) {
               dataObj['data'][allLang.code] = {};
             }
             dataObj['data'][allLang.code][id] = item.name;
             if (id === 'parentGroupName') {
               dataObj['data'][allLang.code]['parentGroupId'] = item.id;
             }
           }
           else if(event.length === 0){
             selectedGroupObj[allLang.code] = event;
           }
         });
       }
     });
     this.setState({selectedGroup: selectedGroupObj});
   }

   //call on form save button ,used to call create and edit drive type api and save changes.
   SaveFormHandler = (event) =>{
     event.preventDefault();
     let {fieldMsg, isSubgroupChecked} = this.state;
     fieldMsg['name'] = '';
     fieldMsg['group'] = '';
     let flag = false;

     //set error msg if name is blank or group name is not selected for sub-group else call api to save data
     [...languageArr].map(lang =>{
         if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].name ||
           (this.state.rowData.data[lang.code].name && this.state.rowData.data[lang.code].name.trim()) === ''){
           fieldMsg['name'] = i18next.t('mngGroup.inputNameErr');
           flag = true;
         }
         if(this.state.rowData.data[lang.code] && this.state.rowData.data[lang.code].type === 'Sub-group' &&
                !this.state.rowData.data[lang.code].parentGroupName){
           fieldMsg['group'] = i18next.t('mngGroup.groupSelectErr');
           flag = true;
         }
     });
     this.setState(fieldMsg);
      if (!flag) {
        let postArr = [];
        [...languageArr].map(lang =>{
          let obj = {
            id: this.state.rowData.id,
            name: this.state.rowData.data[lang.code]['name'],
            parentGroupId: this.state.rowData.data[lang.code]['parentGroupId'],
            parentGroupName: this.state.rowData.data[lang.code]['parentGroupName'],
            type: this.state.rowData.data[lang.code]['type'],
            languageCode: lang.code
          };
          postArr.push(obj);
        });
        if (this.props.formtype === 'edit') {
          this.props.editGroup(postArr);
        }
        else{
          this.props.createGroup(postArr);
        }
        this.props.closeModal();
      }
   }

   // Is sup-group checked enabled select group input field.
   isSubgroupHandler = (e) => {
     this.setState({isSubgroupChecked: e.target.checked});
     let dataObj = {...this.state.rowData};
     let selectedGroupObj = {};
     [...languageArr].map(lang =>{
       if (dataObj['data'] && dataObj['data'][lang.code]) {
         if (!e.target.checked) {
           dataObj['data'][lang.code]['parentGroupName'] = null;
           dataObj['data'][lang.code]['parentGroupId'] = 0;
           dataObj['data'][lang.code]['type'] = 'Group';
           selectedGroupObj[lang.code] = [];
         }
         else{
           dataObj['data'][lang.code]['type'] = 'Sub-group';
         }
       }
     });
     this.setState({rowData: dataObj, selectedGroup: selectedGroupObj});
   }

  render() {
    let {rowData, fieldMsg, isSubgroupChecked, selectedGroup} = this.state;

    return (
      <Translation>{t => (
        <Fragment>
          <form id="groupForm">
            <div className="form-inline col-12">
              <div className="col-2"><h6 className="col-form-label"><b>{i18next.t('mngGroup.attribute')}</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-5"><h6 className="col-form-label"><b>{i18next.t('language.'+lang.code)} *</b></h6></div>
                  )
                })
              }
            </div>
            <div className="form-inline col-12" style={{'paddingTop':'5px'}}>
              <div className="col-12"><h6><b>
              <Form.Group>
                <Form.Check
                  id="isSubgroupKey"
                  inline
                  key="isSubgroupKey"
                  onChange={this.isSubgroupHandler}
                  type="checkbox"
                  checked={isSubgroupChecked}
                  label={i18next.t('mngGroup.isSubgroupLabel')}
                />
              </Form.Group></b></h6></div>
            </div>
            <div className="form-inline col-12" style={{'paddingTop':'5px'}}>
              <div className="col-2"><h6><b>{i18next.t('mngGroup.name')} *</b></h6></div>
                {
                  [...languageArr].map(lang =>{
                    return(
                      <div className="col-5 labelTextCss">
                        <h6 className="groupContainer">
                          <input title={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].name && rowData.data[lang.code].name} type="text" id={'groupContainer'+lang.code} value={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].name && rowData.data[lang.code].name} onChange={this.inputHandler.bind(this, 'name', lang.code)} className="form-control" placeholder={t('mngGroup.inputName')} required/>
                        </h6>
                      </div>
                    )
                  })
                }
            </div>
            <div className="form-inline col-12" style={{'paddingTop':'5px'}} id="selectGroupToggleDiv">
              <div className="col-2"><h6><b>{i18next.t('mngGroup.selectGroup')}</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-5 labelTextCss">
                      <h6>
                        <Typeahead
                            id="basic-typeahead-single"
                            emptyLabel={t('noMatchFound')}
                            clearButton
                            disabled={!isSubgroupChecked}
                            labelKey="name"
                            selected={selectedGroup[lang.code]}
                            options={this.state.optionsGroupList[lang.code] ? this.state.optionsGroupList[lang.code] : []}
                            onChange={this.eventHandler.bind(this, 'parentGroupName', lang.code)}
                            placeholder={t('mngGroup.selectGroup')}
                        />
                      </h6>
                    </div>
                  )
                })
              }
            </div>
            {
              <ul>
              {Object.keys(this.state.fieldMsg).map(errKey =>{
                if (this.state.fieldMsg[errKey] !== '') {
                  return(
                    <li style={{'color':'red'}}>{this.state.fieldMsg[errKey]}</li>
                  )
                }
              })}
              </ul>
            }
            <hr />
            <div className="form-group row">
              <div className="col-12" style={{'textAlignLast':'end'}}>
                <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
                <Button color="primary" onClick={this.SaveFormHandler}>{i18next.t('save')}</Button>
              </div>
            </div>
          </form>
          </Fragment>
      )}</Translation>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({
  GroupList: appState.admin.GroupList
});

const mapDispatchToProps = dispatch => ({
  createGroup: (group) => Actions.admin.createGroup(dispatch, group),
  editGroup: (group) => Actions.admin.editGroup(dispatch, group)
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupForm);
