import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import {withRouter} from 'react-router-dom';
import NavigationService from '../../utils/NavigationService';
import AppMain from '../../Layout/AppMain/index';


interface Props extends Translate{
  history?: any,
  colorScheme?: any,
  enableFixedHeader?: any,
  enableFixedSidebar?: any,
  enableFixedFooter?: any,
  enableClosedSidebar?: any,
  closedSmallerSidebar?: any,
  enableMobileMenu?: any,
  enablePageTabsAlt?: any,
  fcmToken?: any,
}


interface State{
  closedSmallerSidebar: boolean,
  width: number,
  height?: number
}
class MainApp extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            closedSmallerSidebar: false,
            width: 1250
        };

    }

    updateDimensions = () => {
        this.setState({ width: window.outerWidth , height: window.innerHeight });
    };
    componentDidMount() {
        NavigationService.setTopLevelNavigator(this.props.history)
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
        } = this.props;


        let { width } = this.state;

        return (
            <Fragment>
                <div className={cx(
                    "app-container app-theme-" + colorScheme,
                    { 'fixed-header': enableFixedHeader },
                    { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                    { 'fixed-footer': enableFixedFooter },
                    { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                    { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                    { 'sidebar-mobile-open': enableMobileMenu },
                    { 'body-tabs-shadow-btn': enablePageTabsAlt }
                )}>
                    <AppMain fcmToken= {this.props.fcmToken} />
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

});

export default connect(mapStateToProps)(withRouter(MainApp));
