import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PageTitle from '../../Layout/AppMain/PageTitle';
import { RegulationsColData } from './RegulationsColData';
import AdminTableComp from './../Table/AdminTable';
import RegulationList from '../../model/RegulationList';
import TopicList from './../../model/TopicList';
import {toast} from 'react-toastify';
import TranslationService from '../../utils/TranslationService';
import moment from 'moment';
import i18next from 'i18next';

interface Props extends Translate{
  getMarketList: () => {};
  getVehicleList: () => {};
  getTopicList: () => {};
  TopicList: TopicList[];
  getDriveTypeList: () => {}
  getFuelTypeList: () => {};
}

interface State{
  regulationDataList: RegulationList[],
  editModalFlag: boolean,
  confirmModalFlag: boolean,
  formtype: string,
  loading: boolean,
  errorMessage: string,
  regNumberListErrMsg: string
}

class ShowAllRegulations extends Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        regulationDataList: [],
        editModalFlag: false,
        confirmModalFlag: false,
        formtype:'add',
        loading: false,
        errorMessage: null,
        regNumberListErrMsg: null
      }
  }

  componentDidMount(){
    //Api call after component is mounted
    this.props.getMarketList();
    this.props.getVehicleList();
    this.props.getTopicList();
    this.props.getDriveTypeList();
    this.props.getFuelTypeList();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.regulationDataList !== nextProps.regulationDataList) {
      return {
      regulationDataList : nextProps.regulationDataList
      };
    }
    return {
      loading: nextProps.loading,
      success: nextProps.success,
      errorMessage: nextProps.errorMessage,
      regNumberListErrMsg: nextProps.regNumberListErrMsg
    };
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
      const t = TranslationService.t;
      toast['error'](t(this.state.errorMessage), { position: 'top-right' });
    }
    if(this.state.regNumberListErrMsg && this.state.regNumberListErrMsg !== prevState.regNumberListErrMsg){
      const t = TranslationService.t;
      toast['error'](t(this.state.regNumberListErrMsg), { position: 'top-right' });
    }
  }

  //Close add/edit GRM modal
  closeModal = () =>{
    this.setState({editModalFlag: false});
  }

  //Close confirmation modal
  closeConfirmModal = () =>{
    this.setState({confirmModalFlag: false});
  }

  //Enable GRM add/edit and confirmation modal based on type
  showModal = (type) =>{
    if (type === 'delete' || type === 'confirm') {
      this.setState({ confirmModalFlag: true, formtype:type});
    }
    else{
      this.setState({ editModalFlag: true, formtype:type});
    }
  }
  render() {

    return (
      <Translation>{t => (
        <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}
                >
                <PageTitle
                {...this.props}
                heading={t('globalRegMatrix')}
                subheading={t('sidebar.regulationSubHeading')}
                buttonText={null}
                buttonCallback={null}
                />

                <AdminTableComp
                editTooltip={i18next.t('mngGRM.editTooltip')}
                deleteTooltip={i18next.t('mngGRM.deleteTooltip')}
                viewTooltip={i18next.t('mngGRM.viewTooltip')}
                formtype={this.state.formtype}
                closeModalFlag={this.closeModal}
                closeConfirmModal={this.closeConfirmModal}
                editModalFlag={this.state.editModalFlag}
                confirmModalFlag={this.state.confirmModalFlag}
                onClickDelete={() => this.showModal('delete')}
                onClickEdit={() => this.showModal('edit')}
                onClickView={() => this.showModal('view')}
                onCloseConfirm={() => this.showModal('confirm')}
                colData={RegulationsColData()}
                formName="GRM"
                tableData={this.props.TopicList}
                modalID="grmModal"/>

                </ReactCSSTransitionGroup>
          </Fragment>
      )}</Translation>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({
  TopicList: appState.admin.TopicList,
  loading: appState.loading.loading,
  success: appState.admin.GRMListRequest.$success,
  errorMessage: appState.admin.GRMListRequest.$errorMessage,
  regNumberListErrMsg: appState.admin.RegulationNumberListRequest.$errorMessage
});

const mapDispatchToProps = dispatch => ({
  getTopicList: () => Actions.admin.getTopicList(dispatch),
  getMarketList: () => Actions.admin.getMarketList(dispatch),
  getVehicleList: () => Actions.admin.getVehicleList(dispatch),
  getDriveTypeList: (filter) => Actions.admin.getDriveTypeList(dispatch),
  getFuelTypeList: () => Actions.admin.getFuelTypeList(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllRegulations);
