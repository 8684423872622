import React, { Fragment } from 'react';
import * as ReactDOM from "react-dom";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import i18next from 'i18next';

export default class ModalComp extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        dropdownOpen: false,
        value:'UNECE'
      }
      this.toggle = this.toggle.bind(this);
      this.setDropdownValue = this.setDropdownValue.bind(this);
    }

    toggle = () =>{
      this.setState({
        dropdownOpen: !this.state.dropdownOpen
      })
    }

    //Selected market set for Europe (ECE or EU27)
    setDropdownValue = (data) =>{
      this.setState({value: data});
    }

    render(){
      return (
        <Fragment>
            <Modal isOpen={this.props.modalFlag} centered={true} className={this.props.className}>
              <ModalHeader>{this.props.modalHeader}</ModalHeader>
              <ModalBody>
                <div>
                  <b>{i18next.t('map.selectMkt')} *</b>
                  <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                      {this.state.value}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => this.setDropdownValue('UNECE')}>UNECE</DropdownItem>
                      <DropdownItem onClick={() => this.setDropdownValue('EU27')}>EU27</DropdownItem>
                      {this.props.modalType !== 'markerResult' &&
                      <DropdownItem onClick={() => this.setDropdownValue('UNECE+EU27')}>UNECE+EU27</DropdownItem>
                       }
                      </DropdownMenu>
                  </Dropdown>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="link" onClick={this.props.closeModal}>{this.props.cancelBtnText}</Button>
                <Button color="primary" onClick={() => this.props.handleClick(this.state.value)}>{this.props.successBtnText}</Button>
              </ModalFooter>
            </Modal>
      </Fragment>
    )
  }
}
