import request from './request';
import * as Constants from '../../utils/Constants';
import LanguageForm from '../../pages/Setting/LanguageForm';

  function getUserList() {
      return request({
          url: Constants.USERS_LIST_ADMIN,
          method: 'GET',
      }, false)
  };

  function getVehicleList(){
      return request({
          url: Constants.VEHICLE_LIST_ADMIN,
          method: 'GET'
      }, false)
  };

  function getLanguageList(){
      return request({
          url: Constants.LANGUAGE_LIST_ADMIN,
          method: 'GET'
      }, false)
  };

  function getMarketList() {
    return request({
        url: Constants.MARKET_LIST_USER,
        method: 'GET',
    }, false)
  };

  function getDriveTypeList() {
    return request({
        url: Constants.DRIVE_TYPE_LIST_ADMIN,
        method: 'GET',
    }, false)
  };

  function editUserList(user) {
      return request({
          url: Constants.USERS_LIST_ADMIN + '/' +user.id,
          method: 'PUT',
          data: user
      }, false)
  };

  function createUser(user) {
      return request({
          url: Constants.USERS_LIST_ADMIN,
          method: 'POST',
          data: user
      }, false)
  };

  function deleteUser(userID) {
      return request({
          url: Constants.USERS_LIST_ADMIN + '/' +userID,
          method: 'DELETE'
      }, false)
  };

  function editVehicleList(vehicle) {
      return request({
          url: Constants.VEHICLE_LIST_ADMIN + '/' +vehicle[0].id,
          method: 'PUT',
          data: vehicle
      }, false)
  };

  function createVehicle(vehicle) {
      return request({
          url: Constants.VEHICLE_LIST_ADMIN,
          method: 'POST',
          data: vehicle
      }, false)
  };

  function deleteVehicle(vehicleID) {
      return request({
          url: Constants.VEHICLE_LIST_ADMIN + '/' +vehicleID,
          method: 'DELETE'
      }, false)
  };


  function createMarket(market) {
      return request({
          url: Constants.MARKET_LIST_ADMIN,
          method: 'POST',
          data: market
      }, false)
  };

  function deleteMarket(market) {
      return request({
          url: Constants.MARKET_LIST_ADMIN + '/' +market,
          method: 'DELETE'
      }, false)
  };

  function createLanguage(language) {
      return request({
          url: Constants.LANGUAGE_LIST_ADMIN,
          method: 'POST',
          data: language
      }, false)
  };

  function deleteLanguage(language) {
      return request({
          url: Constants.LANGUAGE_LIST_ADMIN + '/' +language,
          method: 'DELETE'
      }, false)
  };

  function createTopic(topic) {
      return request({
          url: Constants.TOPIC_LIST_ADMIN,
          method: 'POST',
          data: topic
      }, false)
  };

  function editTopic(topic) {
      return request({
          url: Constants.TOPIC_LIST_ADMIN + '/' +topic[0].id,
          method: 'PUT',
          data: topic
      }, false)
  };

  function deleteTopic(topicID) {
      return request({
          url: Constants.TOPIC_LIST_ADMIN + '/' +topicID,
          method: 'DELETE'
      }, false)
  };

  function addGRM(grm) {
      return request({
          url: Constants.GRM_LIST_ADMIN,
          method: 'POST',
          data: grm
      }, false)
  };

  function deleteGRM(grmID) {
      return request({
          url: Constants.GRM_LIST_ADMIN + '/' +grmID,
          method: 'DELETE'
      }, false)
  };

  function getTopicList(){
      return request({
          url: Constants.TOPIC_LIST_ADMIN,
          method: 'GET'
      }, false)
  };

  function getGRM(id){
      return request({
          url: Constants.GRM_LIST_ADMIN + '/' +id,
          method: 'GET'
      }, false)
  };

  function resendVerificationLink(email) {
    return request({
        url: Constants.RESENDE_MAIL,
        method: 'POST',
        data: email
    }, false)
   };

   function getRegulationNumberList(){
       return request({
           url: Constants.REGULATION_NUMBER_LIST_ADMIN,
           method: 'GET'
       }, false)
   };

   function createDriveType(driveType) {
    return request({
        url: Constants.DRIVE_TYPE_LIST_ADMIN,
        method: 'POST',
        data: driveType
    }, false)
  };

   function deleteDriveType(driveTypeID) {
    return request({
        url: Constants.DRIVE_TYPE_LIST_ADMIN + '/' +driveTypeID,
        method: 'DELETE'
    }, false)
 };

  function editDriveTypeList(driveType) {
    return request({
        url: Constants.DRIVE_TYPE_LIST_ADMIN + '/' +driveType[0].id,
        method: 'PUT',
        data: driveType
    }, false)
  };

   function getGroupList(){
       return request({
           url: Constants.GROUP_LIST_ADMIN,
           method: 'GET'
       }, false)
   };

   function createGroup(group) {
       return request({
           url: Constants.GROUP_LIST_ADMIN,
           method: 'POST',
           data: group
       }, false)
   };

   function editGroup(group) {
       return request({
           url: Constants.GROUP_LIST_ADMIN + '/' +group[0].id,
           method: 'PUT',
           data: group
       }, false)
   };

   function deleteGroup(groupID, obj) {
       return request({
           url: Constants.GROUP_LIST_ADMIN + '/' +groupID,
           method: 'DELETE',
           data: obj
       }, false)
   };

   function getGroupSubgroupList(){
       return request({
           url: Constants.GROUP_SUBGROUP_RELATION_LIST_ADMIN,
           method: 'GET'
       }, false)
   };

   function getFuelTypeList(){
       return request({
           url: Constants.FUEL_TYPE_LIST_ADMIN,
           method: 'GET'
       }, false)
   };

   function createFuelType(fuelType) {
       return request({
           url: Constants.FUEL_TYPE_LIST_ADMIN,
           method: 'POST',
           data: fuelType
       }, false)
   };

   function editFuelType(fuelType) {
       return request({
           url: Constants.FUEL_TYPE_LIST_ADMIN + '/' +fuelType[0].id,
           method: 'PUT',
           data: fuelType
       }, false)
   };

   function deleteFuelType(fuelTypeID) {
       return request({
           url: Constants.FUEL_TYPE_LIST_ADMIN + '/' +fuelTypeID,
           method: 'DELETE'
       }, false)
   };

   function getScopeList(){
       return request({
           url: Constants.SCOPE_LIST_ADMIN,
           method: 'GET'
       }, false)
   };

   function createScope(scope) {
       return request({
           url: Constants.SCOPE_LIST_ADMIN,
           method: 'POST',
           data: scope
       }, false)
   };

   function editScope(scope) {
       return request({
           url: Constants.SCOPE_LIST_ADMIN + '/' +scope[0].id,
           method: 'PUT',
           data: scope
       }, false)
   };

   function deleteScope(scopeID, obj) {
       return request({
           url: Constants.SCOPE_LIST_ADMIN + '/' +scopeID,
           method: 'DELETE',
           data: obj
       }, false)
   };

   function getScopeSubscopeList(){
       return request({
           url: Constants.SCOPE_SUBSCOPE_RELATION_LIST_ADMIN,
           method: 'GET'
       }, false)
   };

   function uploadFiles(files) {
       return request({
           url: Constants.UPLOAD_FILES_ADMIN,
           method: 'POST',
           data: files
       }, false)
   };

   function editFiles(files, id) {
       return request({
           url: Constants.EDIT_FILES_ADMIN+id,
           method: 'PUT',
           data: files
       }, false)
   };

   function deleteFiles(id) {
       return request({
           url: Constants.EDIT_FILES_ADMIN+id,
           method: 'DELETE'
       }, false)
   };

   function getMarketProfileValue(){
     return request({
         url: Constants.MARKET_VALUE,
         method: 'GET'
      },  false)
   };
   function createMarketValue(data,id) {
     return request({
         url: id+'/'+Constants.ADD_MARKET_VALUE,
         method: 'POST',
         data: data
      }, false)
   };

   function editMarketValue(marketValue, type) {
       return request({
         url: type +'/'+Constants.ADD_MARKET_VALUE + '/' +marketValue[0].id,
          method: 'PUT',
         data: marketValue
      }, false)
   };
  function deleteMarketValue(marketValueID, type) {
     return request({
        url: type +'/'+Constants.ADD_MARKET_VALUE + '/' +marketValueID,
        method: 'DELETE'
     }, false)
   };
   function getRoadCondition(){
    return request({
        url: Constants.GET_ROAD_CONDITION,
        method: 'GET'
     },  false)
   };
   function getAirCondition(){
    return request({
        url: Constants.GET_AIR_CONDITION,
        method: 'GET'
     },  false)
   };
   function getAvgTemp(){
    return request({
        url: Constants.GET_AVG_TEMP,
        method: 'GET'
     },  false)
   };
   function getCertification(){
    return request({
        url: Constants.GET_CERTIFICATION,
        method: 'GET'
     },  false)
   };
   function getEnvCondition(){
    return request({
        url: Constants.GET_ENV_CONDITION,
        method: 'GET'
     },  false)
   };
   function getHandDrive(){
    return request({
        url: Constants.GET_HAND_DRIVE,
        method: 'GET'
     },  false)
   };
   function getHumidity(){
    return request({
        url: Constants.GET_HUMIDITY,
        method: 'GET'
     },  false)
   };
   function createMarketProfile(marketProfile) {
    return request({
        url: Constants.CREATE_MARKET_PROFILE,
        method: 'POST',
        data: marketProfile
    }, false)
  };
  function getMarketProfile(marketId){
    return request({
        url: Constants.GET_MARKET_PROFILE+ '/' +marketId,
        method: 'GET'
     },  false)
   };
   function uploadMarketProfileFiles(files) {
    return request({
        url: Constants.UPLOAD_FILE_MARKET_PROFILE,
        method: 'POST',
        data: files
    }, false)
  };

    function editMarketProfileFiles(files, id) {
        return request({
            url: Constants.EDIT_FILE_MARKET_PROFILE + '/' +id,
            method: 'PUT',
            data: files
        }, false)
    };

    function deleteMarketProfileFiles(id) {
        return request({
            url: Constants.EDIT_FILE_MARKET_PROFILE +'/' +id,
            method: 'DELETE'
        }, false)
    };
    function editMarketProfile(marketProfile) {
        return request({
            url: Constants.EDIT_MARKET_PROFILE+ '/' +marketProfile[0].id,
            method: 'PUT',
            data: marketProfile
        }, false)
    };
    function deleteMarketProfile(marketId,marketProfileId) {
        return request({
            url: Constants.EDIT_MARKET_PROFILE + '/' +marketId+ '/' +marketProfileId,
            method: 'DELETE'
        }, false)
    };
    function getFaqs() {
        return request({
            url: Constants.FAQ_ADMIN,
            method:'GET',
        }, false)
    }
    function editFaq(faq) {
        return request({
            url: Constants.FAQ_ADMIN+'/'+ faq[0].id,
            method: 'PUT',
            data: faq
        },false)
    }

    function addfaq(faq) {
        return request({
            url: Constants.FAQ_ADMIN,
            method: 'POST',
            data: faq
        }, false)
    };

    function deleteFaq(faqId) {
        return request({
            url: Constants.FAQ_ADMIN + '/'+ faqId,
            method: 'DELETE'
        },false)
    }

    function addRevisionHistory(rev) {
        return request({
            url: Constants.REVISION_HISTORY,
            method: 'POST',
            data: rev
        }, false)
    };

    function deleteSeries (serData) {
        return request({
            url: Constants.SERIES_REV_HISTORY,
            method:'DELETE',
            data: serData,
        }, false)
    };
    function getRegulationList(){
        return request({
            url: Constants.GET_REGULATION_LIST,
            method: 'GET'
        }, false)
    };
    function getRevisionHistoryData(regulationNumber){
        let obj = {regulationNumber: regulationNumber}
        return request({
            url: Constants.GET_REVISION_HISTORY,
            method: 'GET',
            params: obj
        }, false)
    };

const AdminService = {
  getUserList,
  getVehicleList,
  getLanguageList,
  getMarketList,
  editUserList,
  createUser,
  deleteUser,
  editVehicleList,
  createVehicle,
  deleteVehicle,
  createMarket,
  deleteMarket,
  createLanguage,
  deleteLanguage,
  createTopic,
  editTopic,
  deleteTopic,
  addGRM,
  deleteGRM,
  getGRM,
  getTopicList,
  resendVerificationLink,
  getRegulationNumberList,
  getDriveTypeList,
  createDriveType,
  deleteDriveType,
  editDriveTypeList,
  getGroupList,
  createGroup,
  editGroup,
  deleteGroup,
  getGroupSubgroupList,
  getFuelTypeList,
  createFuelType,
  editFuelType,
  deleteFuelType,
  getScopeList,
  createScope,
  editScope,
  deleteScope,
  getScopeSubscopeList,
  uploadFiles,
  editFiles,
  deleteFiles,
  getMarketProfileValue,
  createMarketValue,
  editMarketValue,
  deleteMarketValue,
  getRoadCondition,
  getAirCondition,
  getAvgTemp,
  getCertification,
  getEnvCondition,
  getHandDrive,
  getHumidity,
  createMarketProfile,
  getMarketProfile,
  uploadMarketProfileFiles,
  editMarketProfileFiles,
  deleteMarketProfileFiles,
  editMarketProfile,
  deleteMarketProfile,
  getFaqs,
  editFaq,
  addfaq,
  deleteFaq,
  addRevisionHistory,
  deleteSeries,
  getRegulationList,
  getRevisionHistoryData
}

export default AdminService;
