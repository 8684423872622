import React, {Component,Fragment, useState} from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import i18next from 'i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import i18n from '../../i18n/I18n';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import DeleteIcon from 'react-ionicons/lib/MdTrash';
import EditIcon from 'react-ionicons/lib/MdCreate';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MarketProfile.css';
import ReactTooltip from 'react-tooltip';
import {
    Row, Col, Button
} from 'reactstrap';



interface Props extends Translate{
    roadConditionData ?: any;
    onEdit : (row) =>{};
    onDelete : (row) => {};
  }
  
  interface State{
    tableData?: any;
  }
  
  class MarketValueTableComp extends Component<Props, State> {
      constructor(props){
        super(props)
        this.state={
            tableData: []
        }
      }
      componentDidMount(){
        this.setTableData();
      }

      componentDidUpdate(prevProps, prevState){
        if(prevProps.roadConditionData !== this.props.roadConditionData){
          this.setTableData();
         }
      }
   
      //setting table data.
      setTableData = () =>{
        const tableArr = this.props.roadConditionData?.filter(item => item.type === 'road_condition');
        this.setState({tableData: tableArr})
    
      }
      //setting value name in english language.
      englishName = (cell,row) => {
          return(<div><a>{row?.data?.en?.label}</a>
          <ReactTooltip id={`${row.id+'fileName'}`} place="bottom" type="info" effect="float"/></div>)
       }
       //setting value name in german language.
       germanName = (cell,row) => {
        return(<div><a>{row?.data?.de?.label}</a>
          <ReactTooltip id={`${row.id+'fileName'}`} place="bottom" type="info" effect="float"/></div>)
       }
        //format action column, display action icons.
      actionColFormatter = (cell, row) => {
         return (
          <Row>
          <Row style={{marginLeft:'15px'}}>
          <Col md="3"style={{paddingLeft:'1px'}}>
          <a>
           <EditIcon onClick={() => this.props.onEdit(row)} />
          </a>
          <ReactTooltip id="editHandler" place="right" type="info" effect="float"/>
          </Col></Row>
          <Col md="3"style={{paddingLeft:'1px'}}>
          <a >
           <DeleteIcon onClick={() => this.props.onDelete(row)}/>
          </a>
          <ReactTooltip id={`${row.id+'deleteHandler'}`} place="right" type="info" effect="float"/>
          </Col>
          </Row>
        );
      };
      render() {
        const options = {
          noDataText: i18next.t('table.noDataMsg')
        };
         return(
            <div id="marketValueTable">
              <Translation>{t => (
                <Fragment>
                    <BootstrapTable data={this.props.roadConditionData} options={options}>
                         <TableHeaderColumn dataField='ENlang' dataFormat={this.englishName} isKey>English</TableHeaderColumn>
                         <TableHeaderColumn dataField='DElang' dataFormat={this.germanName}>German</TableHeaderColumn>
                         <TableHeaderColumn dataField='action' dataFormat={this.actionColFormatter}>{i18next.t('table.action')}</TableHeaderColumn>
                    </BootstrapTable>
                  </Fragment>
          )}</Translation>
          </div>
        );
      }
    }
    const mapStateToProps = (appState: AppState) => ({
    
    });
    
    const mapDispatchToProps = dispatch => ({
    
    });
    
    export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MarketValueTableComp));
    