import i18next from 'i18next';
import {RegulationsColDataMoreLessEvents} from './../ManageRegulations/RegulationsColData';

//Manage groups table columns
export const GroupsColData = () => {
  return [
    {
        dataField: 'rowId',
        isKey: true,
        label: i18next.t('id'),
        hidden: true,
        placeholder: i18next.t('idPlaceholder')
    },
    {
        isKey: false,
        label: 'Action',
        hidden: false
    },
    {
        dataField: 'name',
        isKey: false,
        label: i18next.t('table.groupSubgroup'),
        dataFormat: (cell, row) =>{
          RegulationsColDataMoreLessEvents(row, 'groupName');
          return row.data && row.data[localStorage.getItem('i18nextLng')] && row.data[localStorage.getItem('i18nextLng')].name ? `<span id=${row.id+'togglegroupName'} class="truncateCss">${row.data[localStorage.getItem('i18nextLng')].name}</span>` : null
        },
        hidden: false,
        placeholder: i18next.t('mngGroup.groupPlaceholder')
    },
    {
        dataField: 'type',
        isKey: false,
        label: i18next.t('table.groupSubgroupType'),
        dataFormat: (cell, row) =>{
          RegulationsColDataMoreLessEvents(row, 'groupType');
          let rowSubGroupData = row.data && row.data[localStorage.getItem('i18nextLng')] && row.data[localStorage.getItem('i18nextLng')].type ? (row.data[localStorage.getItem('i18nextLng')].parentGroupName ? row.data[localStorage.getItem('i18nextLng')].type + ` ( ${row.data[localStorage.getItem('i18nextLng')].parentGroupName} )`  : row.data[localStorage.getItem('i18nextLng')].type) : '';
          return `<span id=${row.id+'togglegroupType'} class="truncateCss">${rowSubGroupData}</span>`
        },
        hidden: false,
        placeholder: i18next.t('mngGroup.groupTypePlaceholder')
    }
  ]
}

export const languageArr = [
  {
    id:1,
    code:"en"
  },
  {
    id:2,
    code:"de"
  }
];
