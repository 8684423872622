import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import {Button,Row,Col} from 'reactstrap';
import $ from 'jquery';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import './MarketProfile.css';
import Flag from 'react-flagkit';
import Actions from './../../internal/modules/Actions';
import i18next from 'i18next';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {languageArr} from '../ManageDriveType/DriveTypeColData';

interface Props extends Translate{
    closeModal?: any,
    SaveFormHandler?: any,
    parentCallback?: any,
    type?: any;
    createMarketValue: (data, id) => {};
    editMarketValue : (marketValue, id) => {};
    formType ?: any;
    rowData ?: any;
    loading?: any;
}

interface State{
roadConditionList?:any;
editFlag: boolean;
rowData?: any;
fieldMsg?: any;
errFieldMsg?: any;

}

class MarketValueForm extends Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
          roadConditionList : [],
          editFlag : false,
          rowData : this.props.rowData,
          fieldMsg : '',
          errFieldMsg: {
            marketValueContaineren:false,
            marketValueContainerde:false
          }
      }
  }

  validateField = (lang, event) =>{
    let {errFieldMsg} = this.state;
      if (event.target.type === 'text' && event.target.value.trim() === '') {
        event.target.value = '';
        errFieldMsg[event.target.id] = true;
      }
      else{
        errFieldMsg[event.target.id] = false;
      }
      this.setState(errFieldMsg);
  }
  inputHandler = (id, lang, event) =>{
    let arr = Object.keys(this.state.rowData).length < 1 ? {data:{}} : {...this.state.rowData};
    if (!arr.data[lang]) {
      arr.data[lang] = {};
    }
    arr.data[lang]['label'] = event.target.value;
    this.setState({rowData: arr});
  }
  SaveFormHandler = () =>{
    let postArr = [];
    [...languageArr].map(lang =>{
      let obj = this.state.rowData.data[lang.code] !== undefined ? this.state.rowData.data[lang.code] : {"label":''};
      obj['languageCode'] = lang.code;
      obj['id'] = this.state.rowData.id;
      postArr.push(obj);
    });
    if (this.props.formType === 'edit') {
      this.props.editMarketValue(postArr,this.props.type);
    }
    else{
      this.props.createMarketValue(postArr,this.props.type);
    }
    this.props.closeModal();
  }
render() {
    let {rowData , errFieldMsg} = this.state;
 return (
    <Translation>{t => (
      <Fragment>
            <form id="marketValueForm" onSubmit={this.SaveFormHandler}>
            <div className="form-inline col-12">
              <div className="col-4"><h6 className="col-form-label"><b>{i18next.t('mngDriveType.attribute')}</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-4"><h6 className="col-form-label"><b>{i18next.t('language.'+lang.code)} *</b></h6></div>
                  )
                })
              }
            </div>
            <div className="form-inline col-12">
              <div className="col-4"><h6><b>{i18next.t('marketValue.name')} *</b></h6></div>
                {
                  [...languageArr].map(lang =>{
                    return(
                      <div className="col-4 labelTextCss">
                        <h6 className="marketValueContainer">
                          <input onBlur={this.validateField.bind(this, lang.code)}  title={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].label} type="text" id={'marketValueContainer'+lang.code} value={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].label} onChange={this.inputHandler.bind(this, 'roadCondition', lang.code)} className={errFieldMsg['marketValueContainer'+lang.code] ? "errFieldCss form-control" :  "form-control"}  placeholder={t('marketValue.namePlaceholder')} required/>
                        </h6>
                      </div>
                    )
                  })
                }
            </div>
            <hr />
            <div className="form-group row">
              <div className="col-12" style={{'textAlignLast':'end'}}>
              <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
              <Button color="primary" type="submit" disabled={this.props.loading}>{this.props.loading  ? i18next.t('savingData') : i18next.t('save')}</Button>
              </div>
            </div>
        </form>
      </Fragment>
      )}</Translation>
    );
  }
}

  const mapStateToProps = (appState: AppState) => ({
    loading: appState.loading.loading,
  });

  const mapDispatchToProps = dispatch => ({
    createMarketValue: (data,id) => Actions.admin.createMarketValue(dispatch, data ,id),
    editMarketValue: (marketValue, id) => Actions.admin.editMarketValue(dispatch, marketValue, id)
  });

  export default connect(mapStateToProps, mapDispatchToProps)(MarketValueForm);
