import React, {Fragment} from 'react';
import {connect} from 'react-redux';

import Hamburger from 'react-hamburgers';
import './index.css';

import AppMobileMenu from '../AppMobileMenu';
import {
    setEnableClosedSidebar,
    setEnableMobileMenu,
    setEnableMobileMenuSmall,
} from '../../reducers/ThemeOptions';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import companyLogo from './../../Vetaca-GRM.png';
interface Props extends Translate{
  enableClosedSidebar?: any;
  setEnableClosedSidebar:(any) =>{};
}

interface State{
  active: boolean,
  mobile: boolean,
  activeSecondaryMenuMobile: boolean,
  openLeft: boolean,
  openRight: boolean,
  relativeWidth: boolean,
  width: number,
  noTouchOpen: boolean,
  noTouchClose: boolean
 }
class HeaderLogo extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false,
            openLeft: false,
            openRight: false,
            relativeWidth: false,
            width: 280,
            noTouchOpen: false,
            noTouchClose: false,
         };

    }

    toggleEnableClosedSidebar = () => {
        let {enableClosedSidebar, setEnableClosedSidebar} = this.props;
        setEnableClosedSidebar(!enableClosedSidebar);
    }


    render() {
        let {
            enableClosedSidebar,
        } = this.props;
        let userRole  = sessionStorage.getItem('selectedRole') != 'null' ? sessionStorage.getItem('selectedRole') : null;
        return (
            <Fragment>
                <div className="app-header__logo">
                  {!this.state.active && <img style={{'height':'75%'}} src={companyLogo} alt="No Image"/>}
                    {userRole && (userRole === 'admin') &&
                      <Fragment>
                        <h5 className="logo-text"></h5>
                        <div className="header__pane ml-auto">
                            <div onClick={this.toggleEnableClosedSidebar}>
                                <Hamburger
                                    active={enableClosedSidebar}
                                    type="elastic"
                                    onClick={() => this.setState({active: !this.state.active})}
                                    id="hamburger"
                                />
                            </div>
                        </div>
                      </Fragment>}
                </div>
                <AppMobileMenu {...this.props}/>
            </Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
        enableMobileMenu: state.ThemeOptions.enableMobileMenu,
        enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
        reloadState: state.user.reloadState
    }
}

const mapDispatchToProps = dispatch => ({
    setEnableClosedSidebar: enable => dispatch(setEnableClosedSidebar(enable)),
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
