import React, {Component,Fragment, useState} from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import i18next from 'i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import i18n from '../../i18n/I18n';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import './table.css';
import ReactTooltip from 'react-tooltip';
import FileSaver from 'file-saver';
import {DOCUMENT} from './../../utils/Constants';
import {toast} from 'react-toastify';
import moment from "moment";
import {GET_FILE_MARKET_PROFILE} from './../../utils/Constants';

interface Props extends Translate{
    data?: any;
    type?: any;
}

interface State{
}

class DocumentTableComp extends Component<Props, State> {
    constructor(props){
      super(props)
      this.state={

      }
    }
    // call on click of document name and download that document.
    downloadDocument = (row) =>{
      var xhr = new XMLHttpRequest();
      let apiUrl =  this.props.type && this.props.type === 'marketProfile' ?  process.env.REACT_APP_BASE_URL+'/'+GET_FILE_MARKET_PROFILE+'/'+row.id : process.env.REACT_APP_BASE_URL+'/'+DOCUMENT+'/'+row.id;
      xhr.open('GET',apiUrl, true);
      xhr.setRequestHeader('Cache-Control','no-cache');
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.setRequestHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('token'))}`);
      xhr.responseType = 'blob';
      xhr.onload = async function (dispatch) {
       if(xhr.status === 200){
         FileSaver.saveAs(xhr.response, row.name);
         toast(i18next.t('table.downloadSuccess'), { position: 'top-right', type: 'success'});
        }
        else if(xhr.status === 404){
          toast(i18next.t('table.fileNotFoundError'), { position: 'top-right', type: 'error'});
        }
        else if(xhr.status === 500){
          toast(i18next.t('table.downloadError'), { position: 'top-right', type: 'error'});
        }
       }.bind(this);
       xhr.send();
    }

    //setting document name add anchor tag to document name.
   documentName = (cell,row) => {
     return(<div><a style={{cursor: 'pointer', color: '#545cd8'}} data-tip={row.shortDescription} data-for="fileName" onClick={() => this.downloadDocument(row)}>{cell}</a>
     <ReactTooltip id="fileName" place="bottom" type="info" effect="float"/></div>)
    }

      //convert date in utc format.
    modifiedDate = (cell,row) =>{
      if (cell) {
        return moment.utc(cell, 'DD-MM-YYYY HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss');
      } else {
        return '';
      }
    }

  render() {
    const options = {
      noDataText: i18next.t('table.noDataMsg')
    };
     return(
        <div  id="documentTable">
          <Translation>{t => (
            <Fragment>
             <BootstrapTable data={this.props.data} bordered={true} options={options}>
               <TableHeaderColumn dataField='name' dataFormat={this.documentName}isKey>{i18next.t('advancedFilters.documentName')}</TableHeaderColumn>
               <TableHeaderColumn dataField='modifiedOn' dataFormat={this.modifiedDate}>{i18next.t('advancedFilters.documentDate')}</TableHeaderColumn>
               <TableHeaderColumn dataField='size'>{i18next.t('advancedFilters.documentSize')}</TableHeaderColumn>
             </BootstrapTable>
             </Fragment>
         )}</Translation>
      </div>
    );
  }
}
const mapStateToProps = (appState: AppState) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentTableComp));
