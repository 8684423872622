

	
export class ApiResponseStatus{
  private success: boolean;
  private errorMessage: string;

  constructor($success: boolean, $errorMessage: string) {
    this.success = $success;
    this.errorMessage = $errorMessage;
  }
    
  /**
   * Getter $success
   * @return {boolean}
   */
	public get $success(): boolean {
		return this.success;
	}

    /**
     * Getter $error
     * @return {string}
     */
  public get $errorMessage(): string {
    return this.errorMessage;
	}

    /**
     * Setter $success
     * @param {boolean} value
     */
	public set $success(value: boolean) {
		this.success = value;
	}

    /**
     * Setter $error
     * @param {string} value
     */
  public set $errorMessage(value: string) {
    this.errorMessage = value;
	}

}