import i18next from 'i18next';

//Manage drive types table columns
export const DriveTypeColData = () => {
  return [
    {
        dataField: 'rowId',
        isKey: true,
        label: i18next.t('id'),
        hidden: true,
        placeholder: i18next.t('idPlaceholder')
    },
    {
        isKey: false,
        label: 'Action',
        hidden: false
    },
    {
        dataField: 'driveType',
        isKey: false,
        label: i18next.t('driveType'),
        dataFormat: (cell, row) =>{
          return row.data && row.data[localStorage.getItem('i18nextLng')].driveType;
        },
        hidden: false,
        placeholder: i18next.t('driveTypePlaceholder')
    }
  ]
};

export const languageArr = [
  {
    id:1,
    code:"en"
  },
  {
    id:2,
    code:"de"
  }
];
