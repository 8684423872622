import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PageTitle from '../../Layout/AppMain/PageTitle';
import ListGroup from 'react-bootstrap/ListGroup';
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter,Button} from 'reactstrap';
import './ShowMarkets.css';
import { AppState } from '../../internal/modules/Reducers';
import MarketList from "../../model/MarketList";
import Flag from 'react-flagkit';
import EditModalComp from '../Table/EditModal';
import DeleteModalComp from '../Table/DeleteModal';
import MarketForm from './MarketForm';
import Actions from './../../internal/modules/Actions';
import {toast} from 'react-toastify';
import i18next from 'i18next';
import uneceFlag from './../../assets/utils/images/unece.png';
import ReactTooltip from 'react-tooltip';
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';
import MarketProfileForm from './MarketProfile';
import TranslationService from '../../utils/TranslationService';
import DeleteIcon from 'react-ionicons/lib/MdTrash';
import EditIcon from 'react-ionicons/lib/MdCreate';
import ViewIcon from 'react-ionicons/lib/IosEye';
import AddIcon from 'react-ionicons/lib/MdAddCircle';

const t = TranslationService.t;

let eventFlag = false;

interface Props extends Translate{
    getMarketList: () => {};
    MarketList?: any;
    modalID: string;
    editModalFlag?: boolean;
    formName?: string;
    options : string,
    deleteMarket: (any) => {}
    createMarket: (any) => {},
    getRoadCondition : () => {};
    marketAdded: boolean,
    marketDeleted: boolean,
    loading: boolean,
    getAvgTemp : () => {};
    getAirCondition : () => {};
    getCertification : () => {};
    getEnvCondition : () => {};
    getHandDrive : () => {};
    getHumidity : () => {};
    getMarketProfile : (marketId) => {};
    marketProfileFetched: boolean;
    MarketProfileList ?: any;
    getRegulationCount : () => {};
    marketProfileDeleted ?: any;
}

interface State{
   MarketList : MarketList [],
   activeIndex : number,
   editModalFlag: boolean,
   formName: string,
   id : number,
   modalDelete : boolean,
   errorMessage: string,
   addMarketProfileFlag: boolean,
   formtype: string,
   modalID ?:any,
   rowID ?: any,
   rowIDAdd ?: any,
   marketData ?: any,
   marketProfileData ?: any,
   marketProfileFetched : boolean,
   rowData?: any
}

class MarketComp extends Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        MarketList : [],
        activeIndex: -1 ,
        editModalFlag: false,
        formName :"",
        id : null,
        modalDelete : false,
        errorMessage: null,
        addMarketProfileFlag: false,
        formtype: 'add',
        modalID: '',
        rowID : [],
        rowIDAdd : null,
        marketData : {},
        marketProfileData : [],
        marketProfileFetched : false,
        rowData : []
      }
  }

  componentDidMount(){
    this.props.getMarketList()
    this.props.getRoadCondition();
    this.props.getAvgTemp();
    this.props.getAirCondition();
    this.props.getCertification();
    this.props.getEnvCondition();
    this.props.getHandDrive();
    this.props.getHumidity();
    this.props.MarketList && this.props.MarketList.map((market,index) =>{
      if(market.isMarketProfile === true){
        this.props.getMarketProfile(market.id)
      }
     
    })
    if(this.props.MarketProfileList !== null){
    this.props.MarketProfileList && this.props.MarketProfileList.filter(item =>{
      let arr = [];
      this.props.MarketList && this.props.MarketList.map((market,index) =>{
      if(item?.market?.id === market?.id){
        arr.push(item)
      }
    });
    });
  }
    
   
  }

  static getDerivedStateFromProps(newState, prevState) {
      return {
        errorMessage: newState.errorMessage,
      };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.marketAdded && prevProps.marketAdded !== this.props.marketAdded) {
      this.props.getMarketList()
      toast['success'](i18next.t('mngMarkets.addMarketSuccess'), { position: 'top-right' });
    }
    if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
      toast['error'](i18next.t(this.state.errorMessage), { position: 'top-right' });
    }
    if (this.props.marketDeleted && prevProps.marketDeleted !== this.props.marketDeleted) {
      toast['success'](i18next.t('mngMarkets.deleteMarketSuccess'), { position: 'top-right' });
    }
    if (this.props.marketProfileFetched !== prevProps.marketProfileFetched) {
      if (this.props.marketProfileFetched === true) {
        this.setState({marketProfileFetched: true});
      }
    }
    if(this.props.MarketList && prevProps.MarketList !== this.props.MarketList){
      this.props.MarketList && this.props.MarketList.map((market,index) =>{
      if(market.isMarketProfile === true){
        let arrId = this.state.rowID
        arrId.push(market.id)
         this.setState({rowID: arrId})
      }
    })
  }
  if(this.props.marketProfileDeleted && prevProps.marketProfileDeleted !== this.props.marketProfileDeleted){
     toast['success'](i18next.t('marketProfile.deleteMarketProfileSuccess'), { position: 'top-right' });
     this.closeModal()
   }
  }

  //set active row index.
  changeValue = (index: number) => {
    this.setState({activeIndex: index});
  }

  //close delete modal.
  closeModal = () =>{
    this.setState({editModalFlag: false, modalDelete: false});
  }

  //show edit modal.
  showModal = (type) =>{
    this.setState({ editModalFlag: true , formName: 'market'});
  }

  //open delete modal.
  showModalDelete = (id : number) =>{
   this.setState({modalDelete: true , id : id})
  }

  showAddModal = (id, market) =>{
    this.props.getRegulationCount()
     this.setState({addMarketProfileFlag: true, rowIDAdd: id, marketData: market, modalID: 'marketProfileForm',editModalFlag: true ,formName: 'marketProfile', formtype: 'add'});
  }

  showViewModal = (market) =>{
    let arr = [];
    this.props.getMarketProfile(market.id)
    this.props.getRegulationCount()
    this.setState({addMarketProfileFlag: true, rowData: arr, marketData: market, formName: 'marketProfile', formtype: 'view',modalID: 'marketProfileForm', editModalFlag: true});
  }
  showEditModal = (id,market) =>{
    this.props.getMarketProfile(id)
    this.props.getRegulationCount()
    this.setState({editModalFlag: true,addMarketProfileFlag: true, marketData: market, modalID: 'marketProfileForm',formName: 'marketProfile', formtype: 'edit'});
  }


  //call on delete modal yes button click and call delete market api.
  eventHandlerDelete = () =>{
    const {id} = this.state
    this.props.deleteMarket(id);
    this.setState({editModalFlag: false, activeIndex : -1})
    this.closeModal();
  }
  changeIcon = (data, id) =>{
    let arr = this.state.rowID;
     if (data === 'delete'){
        let index = arr.indexOf(id)
        arr.splice(index, 1)
     }
     else{
      let arr = this.state.rowID;
      arr.push(id)
     }
     this.setState({rowID: arr})
  }

  render() {
    const spinner4 = <LoaderAnim active type="ball-beat"/>;
    let formTemplate, modalTitle = null;
    if(this.state.formName === 'market') {
      formTemplate = <MarketForm {...this.props}  closeModal={this.closeModal}/>
      modalTitle = i18next.t('marketFormTitle');
    }
    if(this.state.formName === 'marketProfile') {
      formTemplate = <MarketProfileForm {...this.props} rowData={this.state.rowData} rowIDAdd = {this.state.rowIDAdd} marketData={this.state.marketData} marketProfileData={this.state.marketProfileData} formtype={this.state.formtype}  iconCSS = {this.changeIcon} closeModal={this.closeModal}/>
      modalTitle = i18next.t('marketProfile.marketProfileFormTitle');
    }
    return (
      <Translation>{t => (
        <Fragment>
          <ReactCSSTransitionGroup
              component="div"
              transitionName="TabsAnimation"
              transitionAppear={true}
              transitionAppearTimeout={0}
              transitionEnter={false}
              transitionLeave={false}>

              <PageTitle
                {...this.props}
                heading={t('sidebar.manageMarket')}
                subheading={t('sidebar.mngMarketSubHeading')}
                buttonText={t('mngMarkets.addMarketslabel')}
                buttonCallback={() => this.showModal('add')}
              />

              <div className="col-12" id="marketListDiv">
                { this.state.editModalFlag &&
                  <EditModalComp modalID={this.state.formName !== 'marketProfile'  ? this.props.modalID : this.state.modalID } closeModal={this.closeModal} modalBody={formTemplate} modalTitle={modalTitle} />
                }
                { this.state.modalDelete &&
                  <DeleteModalComp modalMsg={i18next.t('deleteConfirm')} closeModal={this.closeModal} eventHandlerDelete={this.eventHandlerDelete}/>
                }
                <Loader message={spinner4} show={this.props.loading}>
                  <p className="totalRows">
                    {t('table.totalRecords')} { this.props.MarketList && this.props.MarketList.length }
                  </p>
                  <div className='marketList'>
                  <ListGroup as="ul" >
                     {this.props.MarketList && this.props.MarketList.map((market,index) => {
                       const className = this.state.activeIndex === index ? 'Marketlist active' : 'Marketlist';
                       return(
                        <ListGroup.Item  className={className} key={index} action variant="light" as="li" onClick={(Event) => this.changeValue(index)}>
                            {  this.state.rowID.indexOf(market.id) === -1 ?
                            <div style={{'display': 'initial'}}>
                              
                              <a data-tip={i18next.t('marketProfile.addMarketTip')} data-for="addHandler">
                              <AddIcon className="add" style={{'cursor':'pointer'}} onClick={(Event) => this.showAddModal(market.id,market)} />
                             </a> <ReactTooltip id="addHandler" place="right" type="info" effect="float"/> </div>:
                             <div style={{'display': 'initial'}}>
                                <a data-tip={i18next.t('marketProfile.viewMarketTip')} data-for="viewHandler">
                                <ViewIcon className="view" style={{'cursor':'pointer'}} onClick={(Event) => this.showViewModal(market)}/>
                                </a> <ReactTooltip id="viewHandler" place="right" type="info" effect="float"/> 
                                <a data-tip={i18next.t('marketProfile.editMarketTip')} data-for="editHandler">
                                <EditIcon className="edit" style={{'cursor':'pointer'}} onClick={(Event) => this.showEditModal(market.id,market)}/>
                                </a> <ReactTooltip id="editHandler" place="right" type="info" effect="float"/> 
                             </div>
                             }
                             {market.label}
                             <a data-tip={i18next.t('mngMarkets.deleteTooltip')} data-for="marketDeleteTooltip">
                              <DeleteIcon className="delete" style={{'cursor':'pointer'}} onClick={(Event) => this.showModalDelete(market.id)} />
                             </a>
                             <ReactTooltip id="marketDeleteTooltip" place="left" type="info" effect="float"/>
                             {market.value !== 'ECE' ? <Flag className="mr-3 opacity-8 flag" country={market.value} size={25}/> :
                             <img src={uneceFlag} style={{'height':'25px', 'width':'25px', 'float':'right', 'marginRight':'1rem'}}/>}
                        </ListGroup.Item>)
                     })}
                  </ListGroup>
                  </div>
                </Loader>
              </div>
            </ReactCSSTransitionGroup>
          </Fragment>
      )}</Translation>
      );
    }
  }

  const mapStateToProps = (appState: AppState) => ({
      MarketList: appState.admin.MarketList,
      marketAdded: appState.admin.marketAdded,
      marketDeleted: appState.admin.marketDeleted,
      errorMessage: appState.admin.MarketListRequest.$errorMessage,
      loading: appState.loading.loading,
      marketProfileFetched: appState.admin.marketProfileFetched,
      MarketProfileList: appState.admin.MarketProfileList,
      marketProfileDeleted: appState.admin.marketProfileDeleted
  });

  const mapDispatchToProps = dispatch => ({
      getMarketList: () => Actions.admin.getMarketList(dispatch),
      deleteMarket: (market) => Actions.admin.deleteMarket(dispatch,market),
      createMarket: (market) => Actions.admin.createMarket(dispatch,market),
      getRoadCondition: () => Actions.admin.getRoadCondition(dispatch),
      getAvgTemp: () => Actions.admin.getAvgTemp(dispatch),
      getAirCondition: () => Actions.admin.getAirCondition(dispatch),
      getCertification: () => Actions.admin.getCertification(dispatch),
      getEnvCondition: () => Actions.admin.getEnvCondition(dispatch),
      getHandDrive: () => Actions.admin.getHandDrive(dispatch),
      getHumidity: () => Actions.admin.getHumidity(dispatch),
      getMarketProfile: (marketId) => Actions.admin.getMarketProfile(dispatch,marketId),
      getRegulationCount: () => Actions.user.getRegulationCount(dispatch),
  });

  export default connect(mapStateToProps, mapDispatchToProps)(MarketComp);
