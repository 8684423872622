import React, {Component} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import { Translate } from '../../model/interfaces/translate.interface';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
interface Props extends Translate {
  heading:string,
  subheading:string,
  buttonText: string,
  buttonCallback?: any,
}

interface State{

}

class PageTitle extends Component<Props, State> {
    render() {
        let {
            buttonText,
            heading,
            subheading,
            buttonCallback
        } = this.props;

        return (

            <div className="app-page-title">
                <div className="page-title-wrapper">
                    <div className="page-title-heading">
                        <div>
                            {heading}
                            <div
                                className={cx("page-title-subheading")}>
                                {subheading}
                            </div>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            {buttonText && <Button className="mb-2 mr-2 btn-icon btn-pill" outline color="primary" onClick={() => this.props.buttonCallback ? this.props.buttonCallback() : null}>
                                <i className={' btn-icon-wrapper'}> </i>
                                {buttonText}
                            </Button>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export default PageTitle;
