import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import languageEN from './locales/en/translate.json';
import languageDE from './locales/de/translate.json';

const countryLocale = {
    "en-US":'en',
    "en-IN":'en',
    "en":'en',
    "de":'de',
    "de-DE":'de'
  };
  
   
  
  let defLang = 'en';
  if ((localStorage.getItem('i18nextLng') == null) || (localStorage.getItem('i18nextLng') == 'null')) {
    if (countryLocale[navigator.language]) {
      defLang = countryLocale[navigator.language];
    }
  }
  else{
    if (countryLocale[localStorage.getItem('i18nextLng')]) {
      defLang = countryLocale[localStorage.getItem('i18nextLng')];
    }
  }

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: languageEN,
            de: languageDE
        },
        /* default language when load the website in browser */
        lng: defLang,
        /* When react i18next not finding any language to as default in borwser */
        fallbackLng: "en",
        /* debugger For Development environment */
        debug: true,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default',
            useSuspense: false
        }
    })

export default i18n;
