import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import ReactDOM from 'react-dom';
import MetisMenu from 'react-metismenu';
import { connect } from "react-redux";
import './nav.css';
import {
    setEnableMobileMenu,
    setEnableMobileMenuSmall,
} from '../../reducers/ThemeOptions';
import TranslationService from './../../utils/TranslationService';
import { AdminDashboardNav, AdminManagementNav, ActiveElemCss, InactiveElemCss } from './NavItems';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import $ from 'jquery';

interface Props extends Translate{
  location?: any;
}

interface State{

}

class Nav extends Component<Props, State>{
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){
    this.activeSidebarItem();
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.location !== prevProps.location) {
      this.activeSidebarItem();
    }
  }

  activeSidebarItem = () =>{
    const thisComponent = document.getElementsByClassName("metismenu-link");
    for (let index = 0; index < thisComponent.length; index++) {
      let locationVal = thisComponent[index].getAttribute("href");
      let pathname = '#'+this.props.location.pathname;
      let elem = thisComponent[index];
      if (locationVal === pathname) {
        //Setting active css to active tab and inactive css to all
        $(document).ready(function() {
            $('.metismenu-item').find('.metismenu-link').css(ActiveElemCss);
            $(elem).css(InactiveElemCss);
        });
      }
    }
  }


  render() {
      return (
        <Translation>{t => (<Fragment>
          <h5 className="app-sidebar__heading">{t('sidebar.home')}</h5>
          <MetisMenu
            content={AdminDashboardNav()}
            activeLinkFromLocation
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
          />
          <h5 className="app-sidebar__heading">{t('sidebar.management')}</h5>
          <MetisMenu
            content={AdminManagementNav()}
            activeLinkFromLocation
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
          />
        </Fragment>)}</Translation>
      );
  }

}

export default withRouter(Nav);
