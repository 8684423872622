import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import './GRM.css';
import i18next from 'i18next';
import {languageArr} from './RegulationsColData';

interface Props extends Translate{
  rowData?: any;
}

interface State{
  toggleIconFuture?: any;
}

class GeneralForm extends Component<Props, State> {
  constructor(props) {
    super(props)
      this.state = {}
  }

  preventModal = (event) =>{
    event.preventDefault();
  }

  render() {
    let {rowData} = this.props;
    return(
      <Translation>{t => (
        <div id="accordionGeneral">
          <fieldset className="scheduler-border">
            <legend className="scheduler-border">
              <div id="headingAccordionGeneral">
                <h5 className="mb-0">
                  <button onClick={this.preventModal} className="btn btn-link" data-toggle="collapse" data-target="#collapseGeneralCard" aria-expanded="true" aria-controls="collapseGeneralCard">
                    {i18next.t('mngRegulations.general')}
                  </button>
                </h5>
              </div>
            </legend>
            <div id="collapseGeneralCard" className="collapse show" aria-labelledby="headingAccordionGeneral" data-parent="#accordionGeneral">
              <div className="form-inline col-sm-12 GrmheaderRow">
                <div className="col-4"><h6 className="col-form-label"><b>{i18next.t('mngRegulations.attribute')}</b></h6></div>
                {
                  [...languageArr].map(lang =>{
                    return (
                      <div className="col-4"><h6 className="col-form-label"><b>{i18next.t('language.'+lang.code)} {lang.code === 'en' && '*' }</b></h6></div>
                    )
                  })
                }
              </div>
              <div className="form-inline col-sm-12">
                <div className="col-4"><h6><b>{i18next.t('mngRegulations.topicLabel')}</b></h6></div>
                  {
                    [...languageArr].map(lang =>{
                      return(
                        <div className="col-4 labelTextCss labelBadge">
                          <h6 className="form-inline topicLabel">
                            <div>
                              {rowData.data && rowData.data[lang.code] && rowData.data[lang.code].name}
                            </div>
                            <div className="badgeLabel">
                              <span className="badge badge-pill badge-light">
                                {rowData.data && rowData.data[lang.code] && rowData.data[lang.code].type === 'System' ? 'S' : 'C'}
                              </span>
                            </div>
                          </h6>
                        </div>
                      )})
                  }
              </div>
              <div className="form-inline col-sm-12">
                <div className="col-4"><h6><b>{i18next.t('mngRegulations.correspondingSystem')}</b></h6></div>
                  {
                    [...languageArr].map(lang =>{
                      return(
                        <div className="col-4 labelTextCss">
                          <h6>{rowData.data && rowData.data[lang.code] && rowData.data[lang.code].parentTopicName}</h6>
                        </div>
                      )
                      })
                  }
              </div>
              <div className="form-inline col-sm-12">
                <div className="col-4"><h6><b>{i18next.t('mngRegulations.scope')}</b></h6></div>
                  {
                    [...languageArr].map(lang =>{
                      return(
                        <div className="col-4 labelTextCss">
                          <h6>{rowData.data && rowData.data[lang.code] && rowData.data[lang.code].scope &&
                            rowData.data[lang.code].scope.name ? rowData.data[lang.code].scope.name : null}</h6>
                        </div>
                      )})
                  }
              </div>
              <div className="form-inline col-sm-12">
                <div className="col-4"><h6><b>{i18next.t('mngRegulations.subScope')}</b></h6></div>
                  {
                    [...languageArr].map(lang =>{
                      return(
                        <div className="col-4 labelTextCss">
                        <h6>{rowData.data && rowData.data[lang.code] && rowData.data[lang.code].subScope &&
                          rowData.data[lang.code].subScope.name ? rowData.data[lang.code].subScope.name : null}</h6>
                        </div>
                      )})
                  }
              </div>

              <div className="form-inline col-sm-12">
                <div className="col-4"><h6><b>{i18next.t('mngGroup.Group')}</b></h6></div>
                  {
                    [...languageArr].map(lang =>{
                      return(
                        <div className="col-4 labelTextCss">
                          <h6>{rowData.data && rowData.data[lang.code] && rowData.data[lang.code].group &&
                            rowData.data[lang.code].group.name ? rowData.data[lang.code].group.name : null}</h6>
                        </div>
                      )})
                  }
              </div>
              <div className="form-inline col-sm-12">
                <div className="col-4"><h6><b>{i18next.t('mngGroup.Sub-group')}</b></h6></div>
                  {
                    [...languageArr].map(lang =>{
                      return(
                        <div className="col-4 labelTextCss">
                        <h6>{rowData.data && rowData.data[lang.code] && rowData.data[lang.code].subGroup &&
                          rowData.data[lang.code].subGroup.name ? rowData.data[lang.code].subGroup.name : null}</h6>
                        </div>
                      )})
                  }
              </div>
            </div>
          </fieldset>
        </div>
      )}</Translation>
    )
  }
}
  const mapStateToProps = (appState: AppState) => ({
  });

  const mapDispatchToProps = dispatch => ({
  });

  export default connect(mapStateToProps, mapDispatchToProps)(GeneralForm);
