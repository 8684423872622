import React, {Component,Fragment, useState} from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import mockData from './../../utils/StaticData.json';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as locale from 'date-fns/locale';
import { withTranslation } from 'react-i18next';
import {toast} from 'react-toastify';
import i18next from 'i18next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Button} from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';
import {RenderDataComponent, getCustomFilter} from './UserTable';
import EditModalComp from './EditModal';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import './TimelineMatrixTable.css';
import TimelineRevisionTableModal from '../TimelineMatrixComp/TimelineRevisionTableModal';

const dataFieldArr = ['group', 'topic', 'actualFutureRegNo', 'vehicleNames', 'fuelTypeNames', 'driveTypeNames'];

interface Props extends Translate{
  loading: boolean,
  timeMatrixRegFetched: boolean,
  RegulationTimeMatrixList?: any,
  activeKey?: any,
  getRegulationDetailsById: (id: number) =>{},
  regulationIdFetched: boolean,
  filterFlag ?: any
}

interface State{
  regDataArrTimeMatrix?: any,
  selectedHideShowCol?: any,
  startDate?: string,
  endDate?: string,
  showDetailRegulation: boolean,
  hideShowListState?: any,
  showRegulationType: string,
  rowData ?: any,
  showRevisionHistory : boolean,
  revisionHistoryData ?: any,
  market ?: any
 
}

class TimelineMatrixTable extends Component<Props, State> {
  private formRef: React.RefObject<HTMLFormElement>;
  constructor(props: Props){
    super(props);
    this.state = {
      selectedHideShowCol:[],
      regDataArrTimeMatrix:[],
      startDate:null,
      endDate: null,
      showDetailRegulation: false,
      rowData : [],
      hideShowListState:{
        groupHidden: false,
        topicHidden: false,
        actualFutureRegNoHidden: false,
        actualFutureRevisionHidden: false,
        actualFutureAmendmentHidden: false,
        actualFutureStatusNamesHidden: false,
        futureForecastClassHidden: false,
        vehicleNamesHidden: false,
        fuelTypeNamesHidden: false,
        driveTypeNamesHidden: false
       },
      showRegulationType: 'actual',
      showRevisionHistory: false,
      revisionHistoryData: [],
      market : ''
      
    };
    this.formRef = React.createRef();
  }

  componentDidMount(): void {
    if(this.props.filterFlag === true){
      this.setState({endDate: null, startDate: null})
    }
  }
  componentDidUpdate(prevProps, prevState) {
    //Setting hideshow column listing language text when language changes
    if (this.props.t !== prevProps.t) {
      this.state.selectedHideShowCol.map(item =>{
        item['name'] = i18next.t('hideShowCol.'+item.id);
      });
    }
    if (this.props.filterFlag !== prevProps.filterFlag) {
          this.setState({endDate: null, startDate: null})
       }
    if (this.props.timeMatrixRegFetched && prevProps.timeMatrixRegFetched !== this.props.timeMatrixRegFetched) {
      let filterData = this.props.RegulationTimeMatrixList ? [...this.props.RegulationTimeMatrixList] : [];
        filterData && filterData.map((item, index) =>{
        let categoryTypes = '';
        item['btnId'] = (index*10);
        item['rowId'] = index+1;
        // item.revisionHistory.map(el =>{
        //   el.data.map(date =>{
        //     let startDateTimestamp = moment(this.state.startDate).startOf('month').valueOf();
        //     let xyz = date.enforcementDateNewModel ? moment(date.enforcementDateNewModel, 'DD/MM/YYYY').valueOf() : null;
        //     let endDateTimestamp = moment(this.state.endDate).endOf('month').valueOf();
        //     date['isActualRegValid'] = xyz && ((xyz >= startDateTimestamp) && (xyz<= endDateTimestamp)) ? true : false;
        //   })
        // })
        // item['actualStatusNames'] = item.actualStatus == 1 ? i18next.t('table.approved') : item.actualStatus == 2 ? i18next.t('table.draft') : item.actualStatus == 3 ? i18next.t('table.invalid') : '';
        // item['futureStatusNames'] = item.futureStatus == 1 ? i18next.t('table.approved') : item.futureStatus == 2 ? i18next.t('table.draft') : item.futureStatus == 3 ? i18next.t('table.invalid') : '';
        // item['isActualRegValid'] = true;
        // item['isFutureRegValid'] = true;
        //Applying valid regulation criteria if date is already selected
        // if (this.state.startDate && this.state.endDate) {
        //   let expiryDateActual = item['actualExpirationDate'] ? moment(item['actualExpirationDate'], 'DD/MM/YYYY').valueOf() : null;
        //   let enfDateNewModelFuture = item['futureEnforcementDateNewModel'] ? moment(item['futureEnforcementDateNewModel'], 'DD/MM/YYYY').valueOf() : null;
        //   let startDateTimestamp = moment(this.state.startDate).startOf('month').valueOf();
        //   let endDateTimestamp = moment(this.state.endDate).endOf('month').valueOf();
        //   item['isActualRegValid'] = expiryDateActual && (expiryDateActual >= startDateTimestamp) ? true : false;
        //   item['isFutureRegValid'] = enfDateNewModelFuture && (enfDateNewModelFuture <= endDateTimestamp) ? true : false;
        // }
      });
      this.setState({regDataArrTimeMatrix: filterData});
    }
    //Resetting all states to blank when tab changes
    if (prevProps.activeKey !== this.props.activeKey) {
      this.setState({startDate:null, endDate:null, regDataArrTimeMatrix:[]});
    }
    //Enable regulation detail modal flag after api call is success
    if ((localStorage.getItem('tab') == '5') && (this.state.rowData.length > 0) ) {
    //  this.setState({showDetailRegulation: true});
    }
  }

  //hideShowCol dropdown onChange event handler
  // hideShowColHandler = (data) =>{
  //   let {hideShowListState} = this.state;

  //     hideShowListState['groupHidden']=false;
  //     hideShowListState['topicHidden']=false;
  //     hideShowListState['actualFutureRegNoHidden']=false;
  //     hideShowListState['actualFutureRevisionHidden']=false;
  //     hideShowListState['actualFutureAmendmentHidden']=false;
  //     hideShowListState['actualFutureStatusNamesHidden']=false;
  //     hideShowListState['futureForecastClassHidden']=false;
  //     hideShowListState['vehicleNamesHidden']=false;
  //     hideShowListState['fuelTypeNamesHidden']=false;
  //     hideShowListState['driveTypeNamesHidden']=false;

  //     for (let index = 0; index < data.length; index++) {
  //         hideShowListState[data[index].id] = true;
  //   }
  //   this.setState(hideShowListState);
  //   this.setState({selectedHideShowCol: data});
  // }

  //Group column data formatter
  groupFormatter(cell,row){
    return <RenderDataComponent className={''} row={row} id={'togglegroup'} title={!row.topic.group ? '' : row.topic.group.name} attr='togglegroup'/>
  }

  //Topic column data formatter
  topicFormatter(cell,row){
    return <RenderDataComponent className={row?.topic?.type+'TextColor'} row={row} id={'toggletopic'} title={!row.topic.name ? '' : row.topic.name} attr='toggletopic'/>
  }

  //Regulation number column data formatter
  actualFutureRegNoFormatter = (cell,row) =>{
        return<RenderDataComponent className={''} row={row} id={'toggleactualFutureRegNo'} title={!row.actualRegulationNumber ? ''  : row.actualRegulationNumber} attr='toggleactualFutureRegNo'/>
      //   return <span id="toggleactualFutureRegNo">
      //   <RenderDataComponent className={row.isActualRegValid+'ActualRegTextCss'} row={row} id={'toggleactualFutureRegNo'} title={
      //     !row.actualRegulationNumber ? '' : <a href="javascript:void(0);" onClick={() =>this.regulationClick(row, 'actual')} title={row.actualRegulationNumber}>{row.actualRegulationNumber}</a>
      //   } attr='toggleactualFutureRegNo'/>
      //   <RenderDataComponent className={row.isFutureRegValid+'FutureRegTextCss'} row={row} id={'toggleactualFutureRegNo'} title={
      //     !row.futureRegulationNumber ? '' : <a href="javascript:void(0);" onClick={() =>this.regulationClick(row, 'future')} title={row.futureRegulationNumber}>{row.futureRegulationNumber}</a>
      //   } attr='toggleactualFutureRegNo'/>
      // </span>
  }

  // //Revision number column data formatter
  // actualFutureRevisionFormatter(cell,row){
  //   return <span id="toggleactualFutureRevision">
  //           <RenderDataComponent className={row.isActualRegValid+'ActualRegTextCss'} row={row} id={'toggleactualFutureRevision'} title={!row.actualRevision ? '' : row.actualLinkToRevision ?
  //             <a href={row.actualLinkToRevision} title={row.actualLinkToRevision} target="_blank">{row.actualRevision}</a> : row.actualRevision} attr='toggleactualFutureRevision'/>
  //           <RenderDataComponent className={row.isFutureRegValid+'FutureRegTextCss'} row={row} id={'toggleactualFutureRevision'} title={!row.futureRevision ? '' : row.futureLinkToRevision ?
  //             <a href={row.futureLinkToRevision} title={row.futureLinkToRevision} target="_blank">{row.futureRevision}</a> : row.futureRevision} attr='toggleactualFutureRevision'/>
  //         </span>
  // }

  // //Amendment column data formatter
  // actualFutureAmendmentFormatter(cell,row){
  //   return <span id="toggleactualFutureAmendment">
  //           <RenderDataComponent className={row.isActualRegValid+'ActualRegTextCss'} row={row} id={'toggleactualFutureAmendment'} title={!row.actualAmendment ? '' : row.actualLinkTOAmendment ?
  //             <a href={row.actualLinkTOAmendment} title={row.actualLinkTOAmendment} target="_blank">{row.actualAmendment}</a> : row.actualAmendment} attr='toggleactualFutureAmendment'/>
  //           <RenderDataComponent className={row.isFutureRegValid+'FutureRegTextCss'} row={row} id={'toggleactualFutureAmendment'} title={!row.futureAmendment ? '' : row.futureLinkTOAmendment ?
  //             <a href={row.futureLinkTOAmendment} title={row.futureLinkTOAmendment} target="_blank">{row.futureAmendment}</a> : row.futureAmendment} attr='toggleactualFutureAmendment'/>
  //         </span>
  // }

  // //Status column data formatter
  // actualFutureStatusNamesFormatter(cell,row){
  //   return <span id="toggleactualFutureStatusNames">
  //           <RenderDataComponent className={row.isActualRegValid+'ActualRegTextCss'} row={row} id={'toggleactualFutureStatusNames'} title={!row.actualStatusNames ? '' : row.actualStatusNames} attr='toggleactualFutureStatusNames'/>
  //           <RenderDataComponent className={row.isFutureRegValid+'FutureRegTextCss'} row={row} id={'toggleactualFutureStatusNames'} title={!row.futureStatusNames ? '' : row.futureStatusNames} attr='toggleactualFutureStatusNames'/>
  //         </span>
  // }

  // //Forecast class column data formatter
  // futureForecastClassFormatter(cell,row){
  //   return <RenderDataComponent className={''} row={row} id={'togglefutureForecastClass'} title={!row.futureForecastClass ? '' : row.futureForecastClass} attr='togglefutureForecastClass'/>
  // }

  //Vehicle categories column data formatter
  vehicleNamesFormatter(cell,row){
    return <RenderDataComponent className={''} row={row} id={'togglevehicleNames'} title={!row.vehicleNames ? '' : row.vehicleNames} attr='togglevehicleNames'/>
  }

  //Fuel types column data formatter
  fuelTypeNamesFormatter(cell,row){
    return <RenderDataComponent className={''} row={row} id={'togglefuelTypeNames'} title={!row.fuelTypeNames ? '' : row.fuelTypeNames} attr='togglefuelTypeNames'/>
  }

  //Drive types column data formatter
  driveTypeNamesFormatter(cell,row){
    return <RenderDataComponent className={''} row={row} id={'toggledriveTypeNames'} title={!row.driveTypeNames ? '' : row.driveTypeNames} attr='toggledriveTypeNames'/>
  }

  //Total record rows html
  renderShowsTotal(start, to, total){
    return (
      <p className="totalRows form-inline">
        {i18next.t('table.totalRecords')} { total }
        {/* <span className="label1">{i18next.t('timeline.actualLabel')}</span>
        <span className="label2">{i18next.t('timeline.futureLabel')}</span> */}
        <span className="circle1"></span>
        <span>{i18next.t('System')}</span>
        <span className="circle2"></span>
        <span>{i18next.t('Component')}</span>
      </p>
    );
  }

  //Set timeline start date
  handleStartDate = date => {
    this.setState({startDate: date, endDate:null});
  }

  //Set timeline end date
  handleEndDate = date => {
    this.setState({endDate: date});
  }

  //regulation detail api call onClick of regulation number
  regulationClick = (rowId, regType) =>{
    this.setState({showDetailRegulation: true,rowData: rowId});
    //this.props.getRegulationDetailsById(rowId);
  }

  //close detailed regulation modal
  closeModalRegDetail = () =>{
    this.setState({showDetailRegulation: false, showRevisionHistory: false});
  }

  //Applying valid regulation filter onClick of show button.
  applyDateFilter = (e) =>{
   e.preventDefault();
    let {regDataArrTimeMatrix} = this.state;
    regDataArrTimeMatrix.map(item =>{
      item.revisionHistory.map(el =>{
        el.data.map(date =>{
          let startDateTimestamp = moment(this.state.startDate).startOf('month').valueOf();
          let filteredDate = date.enforcementDateNewModel ? moment(date.enforcementDateNewModel, 'DD/MM/YYYY').valueOf() : null;
          let endDateTimestamp = moment(this.state.endDate).endOf('month').valueOf();
          date['isActualRegValid'] = filteredDate && ((filteredDate >= startDateTimestamp) && (filteredDate<= endDateTimestamp)) ? true : false;
          if(date.enforcementDateNewModel === null){
            date['isActualRegValid'] = true;
          }
        })
      })
      // let expiryDateActual = item['revisionHistory'] ? moment(item['actualExpirationDate'], 'DD/MM/YYYY').valueOf() : null;
      // let enfDateNewModelFuture = item['futureEnforcementDateNewModel'] ? moment(item['futureEnforcementDateNewModel'], 'DD/MM/YYYY').valueOf() : null;
      // let startDateTimestamp = moment(this.state.startDate).startOf('month').valueOf();
      // let endDateTimestamp = moment(this.state.endDate).endOf('month').valueOf();
      // item['isActualRegValid'] = expiryDateActual && (expiryDateActual >= startDateTimestamp) ? true : false;
      // item['isFutureRegValid'] = enfDateNewModelFuture && (enfDateNewModelFuture <= endDateTimestamp) ? true : false;
    });
    this.setState(regDataArrTimeMatrix);
    toast['success'](i18next.t('table.validRegMsg'), { position: 'top-right' });
  }
  moreinfoDetails = (row) =>{
    if(row?.revisionHistory.length !== 0){
      this.setState({showRevisionHistory: true, market: row.market.value ,revisionHistoryData: row?.revisionHistory})
     }
    else{
      toast['info'](i18next.t('timeline.timelineMatrixInfoTooltip'), { position: 'top-right' });
    }
    }
  actionColFormatter = (cell, row) => {
    return (
      <b><a href={"javascript:void(0)"} onClick={() => this.moreinfoDetails(row)}>{i18next.t('timeline.timelineDetailsHeader')}</a></b>
    );
  };
  render() {
    const spinner4 = <LoaderAnim active type="ball-beat"/>;
    const{t, i18n} = this.props
    const options = {
      noDataText: i18next.t('table.noDataMsg'),
      paginationShowsTotal:this.renderShowsTotal,
      paginationPosition:'both'
    };
    const {hideShowListState} = this.state;  
    return (
        <Translation>{t => (
          <div id="timelineMatrixDataTable">
              {this.state.showRevisionHistory && <EditModalComp modalID="regulationDetails" closeModal={this.closeModalRegDetail} modalBody={<TimelineRevisionTableModal market={this.state.market} rowData={this.state.revisionHistoryData} startDate={this.state.startDate} endDate={this.state.endDate}/>} modalTitle={t('timeline.timelineModal')} />}
          <div id="hideShowColTimeMtrx" className="form-inline col-12">
          <form onSubmit={this.applyDateFilter} ref={this.formRef} className="form-inline">
            <div id="timeMtrxStartDateDiv">
            <h6><b>{t('startDate')}</b><i> ( MMM YYYY ) </i></h6>
              <DatePicker
                id="startDate"
                disabled={this.state.regDataArrTimeMatrix.length < 1 || this.props.filterFlag === true}
                required={true}
                autoComplete="off"
                placeholderText={t('selectStartDate')}
                dateFormat="MMM yyyy"
                showMonthYearPicker
                locale={locale[mockData['countryLocale'][localStorage.getItem('i18nextLng')]]}
                isClearable={this.state.regDataArrTimeMatrix.length > 0}
                selected={this.state.startDate}
                onChange={this.handleStartDate}
                className="form-control" />
            </div>

            <div id="timeMtrxEndDateDiv">
            <h6><b>{t('endDate')}</b><i> ( MMM YYYY ) </i></h6>
                <DatePicker
                  id="endDate"
                  disabled={this.state.regDataArrTimeMatrix.length < 1 || this.props.filterFlag === true}
                  required={true}
                  minDate={this.state.startDate}
                  autoComplete="off"
                  placeholderText={t('selectEndDate')}
                  dateFormat="MMM yyyy"
                  showMonthYearPicker
                  locale={locale[mockData['countryLocale'][localStorage.getItem('i18nextLng')]]}
                  isClearable={this.state.regDataArrTimeMatrix.length > 0}
                  selected={this.state.endDate}
                  onChange={this.handleEndDate}
                  className="form-control" />
            </div>
            <a data-tip={t('table.applyDateFilterTooltip')} data-for="applyDateFilterBtnTooltip">
            <Button id="applyDateFilterBtnCss" type="submit" className="btn btn-success" disabled={this.state.regDataArrTimeMatrix.length < 1 || this.props.filterFlag === true}>
              {t('show')}
            </Button>
            </a>
            <ReactTooltip id="applyDateFilterBtnTooltip" type="info" place="bottom" effect="float"/>
          </form>

          {/* <div id="timeMtrxHideColDiv">
          <div className="timeMtrxHideChildDiv">
          <Typeahead
            id="basic-typeahead-multiple"
            emptyLabel={t('noMatchFound')}
            clearButton
            labelKey={option =>`${t(option.name)}`}
            multiple
            onChange={this.hideShowColHandler}
            options={HideColListTimeMtrx()}
            selected={this.state.selectedHideShowCol}
            placeholder={t('table.hideColText')}
          />
          </div>
          </div> */}
          </div>
            <Loader message={spinner4} show={this.props.loading}>
              <BootstrapTable striped hover options={options} data={ this.state.regDataArrTimeMatrix ? this.state.regDataArrTimeMatrix : [] } pagination={true}>
                <TableHeaderColumn dataField='rowId' isKey={true}>{t('id')}</TableHeaderColumn>
                    {
                      dataFieldArr.map(item =>{
                        return(
                          <TableHeaderColumn
                           filterFormatted
                           hidden={hideShowListState[item+'Hidden']}
                           dataField={item}
                           dataFormat={this[item+'Formatter']}
                           filter={
                             {
                              type: 'CustomFilter',
                              placeholder:i18next.t('table.'+item+'Placeholder'),
                              getElement: getCustomFilter,
                              customFilterParameters:  { dataField: item,placeholder: i18next.t('table.'+item+'Placeholder') }
                             }
                           } >{t('table.'+item)}</TableHeaderColumn>
                        )
                      })
                    }
                    <TableHeaderColumn dataField='action' dataFormat={this.actionColFormatter} >{t('timeline.moreInfo')}</TableHeaderColumn>
                    
              </BootstrapTable>
            </Loader>
          </div>
        )}</Translation>
      )
  }
}

const mapStateToProps = (appState: AppState) => ({
  loading: appState.loading.loading,
  activeKey : appState.user.activeKey,
  timeMatrixRegFetched: appState.user.timeMatrixRegFetched,
  RegulationTimeMatrixList: appState.user.RegulationTimeMatrixList,
  regulationIdFetched: appState.user.regulationIdFetched
});

const mapDispatchToProps = dispatch => ({
  getRegulationDetailsById: (id) => Actions.user.getRegulationDetailsById(id, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimelineMatrixTable));
