import React, {Fragment, Component} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Col, Row, Button, Form, FormGroup, Label, Input} from 'reactstrap';
import {toast} from 'react-toastify';
import i18n from '../../i18n/I18n';
import i18next from 'i18next';

const t = TranslationService.t;

interface Props extends Translate{
    match ?: any;
    userVerification : (any) =>{} ;
    history?: any;
    location?: any;
    confirmFlag : boolean;
    closeModal ?: any;
    resetPassword : (any) =>{};
    ResetPassword : boolean,
    logout: () => {};
    loading?: any;
  }
  
interface State{
   newPassword?: any,
   errors?: any,
   resetFlag: boolean,
   errorMessage: string
  }

class ResetPasswordComp extends Component<Props, State> {

   constructor(props){
      super(props)
      this.state={
         newPassword: {},
         errors : {},
         resetFlag: false,
         errorMessage: null
      }
    }

static getDerivedStateFromProps(newState, prevState) {
   return {
      loading: newState.loading,
      success: newState.success,
      errorMessage: newState.errorMessage,
   };
}

componentDidUpdate(prevProps, prevState){
   if (this.props.ResetPassword && prevProps.ResetPassword !== this.props.ResetPassword) {
     toast['success'](i18next.t('resetPasswordSuccess'), { position: 'top-right' });
     this.setState({resetFlag: true})
   }
   if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
     toast['error'](t(this.state.errorMessage), { position: 'bottom-center' });
     this.setState({resetFlag: true})
   }
 }

 //setting input field value of new password and cofirm new password on change.
handleChange = (e) =>{
   let data = {...this.state.newPassword};
   data[e.target.id] = e.target.value;
   this.setState({newPassword: data});
} 

// call on reset password button call reset password api.
handleReset = () =>{
   let errFlag = false;
   let errors ={}
   let {newPassword} = this.state;
   if(!newPassword['newPass']){
      errFlag= true;
     errors['newPass'] = i18next.t('newPasswordErr');
    }
    if(!newPassword['confirmNewPass']){
      errFlag= true;
     errors['confirmNewPass'] = i18next.t('confirmNewPassword');
    }
    if(newPassword['newPass'] !== newPassword['confirmNewPass']){
      errFlag= true;
      errors['confirmNewPass'] =i18next.t('confirmPasswordError');
    }
    this.setState({
      errors: errors
    });
    if(!errFlag){
       let {newPassword} = this.state;
       let obj = {
         uniqueId : this.props.match.params.ID,
         newPassword : newPassword['newPass']
       }
       this.props.resetPassword(obj)
    }
}

// go to login page.
handleBack = () => {
   this.props.history.push('/login')
}
 render() {
    return (
      <Translation>{t => (<Fragment>
           <ReactCSSTransitionGroup
               component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                     <div className="d-flex h-100 justify-content-center align-items-center">
                        <Col md="8" className="mx-auto app-login-box">
                           <div className="modal-dialog w-100 mx-auto">
                              <div className="modal-content">
                                 <div className="modal-body">
                                    <div className="h5 modal-title text-center">
                                       <h4 className="mt-2">
                                         <img src={require("./reset-password-icon-8.jpg")}></img>
                                         <div>{t('resetLabel')}</div>
                                       </h4>
                                    </div>
                                    {this.state.resetFlag === false ?
                                    <div>
                                    <div className="form-group row">
                                      <h6 className="col-sm-4 col-form-label"><b>{t('newPassLabel')}</b></h6>
                                       <div className="col-sm-7">
                                          <input type="password" id="newPass" className="form-control" value={this.state.newPassword['newPass']} onChange={this.handleChange} required/>
                                          <div className="errPassword">{this.state.errors.newPass}</div>
                                       </div>
                                    </div>
                                    <div className="form-group row">
                                      <h6 className="col-sm-4 col-form-label"><b>{t('confPassLabel')}</b></h6>
                                       <div className="col-sm-7">
                                         <input type="password" id="confirmNewPass" className="form-control" value={this.state.newPassword['confirmNewPass']}onChange={this.handleChange} required/>
                                         <div className="errPassword">{this.state.errors.confirmNewPass}</div>
                                       </div>
                                    </div>
                                    <hr />
                                    <div className="editUserBtn">
                                      <Button color="primary" onClick={this.handleReset} disabled={this.props.loading}>{this.props.loading ? t('resetingPass') : t('resetLabel')}</Button>
                                    </div>
                                    </div> :
                                    <div className="backLogin">
                                     <Button color="primary" onClick={this.handleBack}>{t('loginBack')}</Button></div>
                                    }
                                 </div>
                              </div>
                           </div>
                        </Col>
                      </div>
               </ReactCSSTransitionGroup>
         </Fragment>)}
      </Translation>
    )
  }
}
const mapStateToProps = (appState: AppState) => ({
   ResetPassword: appState.user.ResetPassword ,
   loading: appState.loading.loading,
   errorMessage: appState.user.ResetPasswordRequest.$errorMessage
 });
    
const mapDispatchToProps = dispatch => ({
   resetPassword : (password) => Actions.user.resetPassword(dispatch,password),
   logout: () => Actions.user.logout(dispatch)
 });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPasswordComp));
    
  