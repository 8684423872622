import { ModuleEvents } from './Actions';
import { ApiResponseStatus } from '../../model/ApiResponseStatus';
import NotificationList from '../../model/NotificationList';
import { Action } from '../../model/Action';

export interface InitialCommonState {
  NotificationList?: NotificationList[],
  NotificationListRequest?: ApiResponseStatus,
  notificationListUpdated: boolean
}

const initState: InitialCommonState = {
  NotificationListRequest: new ApiResponseStatus(false, null),
  notificationListUpdated: false
};

export const Reducer = (state = initState, action: Action) => {
    const {payload, type} = action;
    switch (type) {

        case ModuleEvents.NOTIFICATION_LIST: {
            return {
                ...state,
                NotificationList: payload,
                notificationListUpdated: true
            }
        }

        case ModuleEvents.ERROR_NOTIFICATION_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                NotificationListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_NOTIFICATION_LIST: {
            return {
                ...state,
                notificationListUpdated: false,
                NotificationListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.DELETE_NOTIFICATION: {
            let notifyListArr = [...state.NotificationList];
            const filterArr = notifyListArr.filter(item => item.notificationId !== payload);
            return {
                ...state,
                NotificationList: filterArr,
                notificationListUpdated: true
            }
        }

        case ModuleEvents.ERROR_DELETE_NOTIFICATION:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                NotificationListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_DELETE_NOTIFICATION: {
            return {
                ...state,
                notificationListUpdated: false,
                NotificationListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.DELETE_ALL_NOTIFICATION: {
            return {
                ...state,
                NotificationList: [],
                notificationListUpdated: true
            }
        }

        case ModuleEvents.ERROR_DELETE_ALL_NOTIFICATION:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                NotificationListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_DELETE_ALL_NOTIFICATION: {
            return {
                ...state,
                notificationListUpdated: false,
                NotificationListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.MARK_AS_READ: {
            let notifyListArr = [...state.NotificationList];
            const filterArr = notifyListArr.filter(item => {
              if (item.notificationId == payload) {
                item['isRead'] = true;
              }
              return item;
            });
            return {
                ...state,
                NotificationList: filterArr,
                notificationListUpdated: true
            }
        }

        case ModuleEvents.ERROR_MARK_AS_READ:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                NotificationListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_MARK_AS_READ: {
            return {
                ...state,
                notificationListUpdated: false,
                NotificationListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.MARK_AS_READ_ALL: {
            let notifyListArr = [...state.NotificationList];
            const filterArr = notifyListArr.filter(item => {
              item['isRead'] = true;
              return item;
            });
            return {
                ...state,
                NotificationList: filterArr,
                notificationListUpdated: true
            }
        }

        case ModuleEvents.ERROR_MARK_AS_READ_ALL:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                NotificationListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_MARK_AS_READ_ALL: {
            return {
                ...state,
                notificationListUpdated: false,
                NotificationListRequest: new ApiResponseStatus(false, null)
            }
        }

        default:
            return {...state};
    }
};
