import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import {Button,Row,Col} from 'reactstrap';
import $ from 'jquery';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import './MarketForm.css';
import Flag from 'react-flagkit';
import Actions from './../../internal/modules/Actions';
import i18next from 'i18next';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Typeahead} from 'react-bootstrap-typeahead';
import coords from './marketCoordinates.json';

const lookup = require('country-code-lookup');


const customSingleValue = ({ data }) => (
  <div className="input-select">
      <div className="input-select__single-value">
       <span>{ data.label }</span>
      </div>
  </div>
);

const countryArr = [
  {name:'Asia', code:'AS'},
  {name:'North America', code:'NA'},
  {name:'South America', code:'SA'},
  {name:'Africa', code:'AF'},
  {name:'Oceania', code:'OC'},
  {name:'Europe', code:'EU'}
];

interface Props extends Translate{
  rowData?: any,
  formtype?: any;
  eventHandler?: any;
  closeModal?: any;
  onSaveFormHandler?: any;
  options : string,
  createMarket: (any) => {},
  MarketList?: any;
}

interface State{
  formData?: any;
  options?: any;
  country?: any;
  error : string;
  Isbtn : boolean;
  allCountry?: any;
  latitude?: any;
  longitude?: any;
}

class MarketForm extends Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        options: countryList().getData(),
        country: [],
        error : '',
        Isbtn : false,
        allCountry: countryList().getData(),
        latitude: '',
        longitude: '',
      }
  }
  componentDidMount(){
    let data = [...this.state.options];
    data.unshift({value:'ECE', label:'UNECE'}, {value:'EU', label:'EU27'});
    this.setState({options: data, allCountry:data});
  }

  // select market from list and setting input field.
  changeHandler = country => {
    this.setState({country});
    let IsValid = false;
    let error = this.state.error;
    if (country.length > 0) {
      this.props.MarketList.map((item) => {
        if(item.label === country[0].label){
           this.setState({Isbtn: false})
           IsValid = true;
           error = i18next.t('error.marketAlreadyAdded');
        }
        else{
          this.setState({Isbtn: true});
        }
       
      });
      coords.filter((el) =>{
        if(el.Code === country[0].value){
          this.setState({longitude: el.Longitude, latitude:el.Latitude})
        }
      })
    }
    else{
      this.setState({Isbtn: false, options: [...this.state.allCountry]});
    }

  this.setState({error : error})
   if(IsValid === false){
      this.setState({error : ''})
    }
    else{
      this.setState({Isbtn: false})
    }
  }

  // call on form submit and create and edit market api call.
  handleMarket=() =>{
   let market = {...this.state.country[0]};
    if (market.label !== 'UNECE' && market.label !== 'EU27') {
      [...countryArr].map(item =>{
        if (item.name === lookup.byInternet(market.value).continent) {
          market = {...market, continent:item.code,latitude: this.state.latitude,longitude: this.state.longitude};
        }
      });
    }
    else{
      market = {...market, continent:'EU',latitude: this.state.latitude,longitude: this.state.longitude};
    }
    this.props.createMarket(market);
    this.props.closeModal();
  }


  // search for market.
  handleInputChange = (event) =>{
    let country = [];
    [...this.state.allCountry].forEach(elem => {
      if (elem.label.toUpperCase().startsWith(event.toUpperCase())) {
        country.push(elem);
      }
    });
    this.setState({options: country});
  }

  render() {
      return (
        <Translation>{t => (
          <Fragment>
            <form onSubmit={this.handleMarket} id="marketForm">
              <div className="form-group row" style={{'height':'80%', 'display':'block'}}>
               <div className="col-12 form-inline">
                  <h6 className="col-4 col-form-label"><b>{i18next.t('advancedFilters.selectMkt')} *</b></h6>
                  <Typeahead
                      className="col-8"
                      id="basic-typeahead-single"
                      emptyLabel={t('noMatchFound')}
                      clearButton
                      labelKey="label"
                      selected={this.state.country}
                      onChange={(e) => this.changeHandler(e)}
                      onInputChange={this.handleInputChange.bind(this)}
                      options={this.state.options}
                      placeholder={t('advancedFilters.selectMkt')}
                  />
               </div>
               {this.state.error && <div className="errorMsg">{this.state.error}</div>}
               <hr />
              </div>

              <div className="form-group row" id="addMarketFormBtnDiv">
                <div className="col-12">
                  <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
                  <Button color="primary" disabled = {!this.state.Isbtn} type="submit">{i18next.t('add')}</Button>
                </div>
              </div>
           </form>
          </Fragment>
        )}</Translation>
      );
    }
  }

  const mapStateToProps = (appState: AppState) => ({
    MarketList: appState.admin.MarketList
  });

  const mapDispatchToProps = dispatch => ({
    createMarket: (market) => Actions.admin.createMarket(dispatch,market)
  });

  export default connect(mapStateToProps, mapDispatchToProps)(MarketForm);
