import React, {Fragment, Component} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import i18n from '../../i18n/I18n';
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter,Button} from 'reactstrap';
import countryList from 'react-select-country-list';
import MarketComp from './ShowMarkets';

interface Props extends Translate{
    options : string,
    modalID: string;
  }

interface State{
  options ?: any;
  value ?: any;
  }

class SettingComp extends Component<Props, State> {

    constructor(props){
      super(props)
      this.state={
        options: countryList().getData(),
        value: null,
      }
    }
    componentDidMount() {
    
    }
   
    render() {
        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>

                  <MarketComp {...this.props} modalID="addMarketList"/>
                </ReactCSSTransitionGroup>
             </Fragment>
         )
      }
}

const mapStateToProps = (appState: AppState) => ({

       });

const mapDispatchToProps = dispatch => ({

       });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingComp));
