import React, {Fragment, Component} from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { AppState } from './../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import Actions from './../../internal/modules/Actions';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import {toast} from 'react-toastify';
import * as locale from 'date-fns/locale';
import TranslationService from '../../utils/TranslationService';
import DatePicker from "react-datepicker";
import './style.css';
import UserTableComp from './../Table/UserTable';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import i18n from '../../i18n/I18n';
import EditModalComp from '../Table/EditModal';
import {
    Row, Col,
    Card, CardBody,
    CardTitle,
    Button
} from 'reactstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Typeahead} from 'react-bootstrap-typeahead';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { connect } from 'react-redux';
import RegulationList from './../../model/RegulationList';
import mockData from './../../utils/StaticData.json';
import ReactTooltip from 'react-tooltip';
import Favorites from '../../model/Favorites';
import { id } from 'date-fns/esm/locale';
import ToggleIconPlus from 'react-ionicons/lib/MdAddCircle';
import ToggleIconMinus from 'react-ionicons/lib/MdRemoveCircle';
import ShareForm from './ShareForm';
import SearchTagList  from '../../model/SearchTagList';
var qs = require('qs');
const t = TranslationService.t;


interface Props extends Translate{
  MarketList?: any,
  TopicList?: any,
  VehicleList?: any,
  SearchTagList?: any,
  GroupList?: any,
  SubGroupList?: any,
  ScopeList?: any,
  SubScopeList?: any,
  DriveTypeList?:any,
  VehicleFuelTypeList?: any,
  getRegulationDetails: (any) => {},
  saveSearchFilter: (any) => {},
  editSaveFilter: (any) => {},
  favorites ?: any,
  RegulationDataList ?: any,
  filterSaved: boolean,
  location?: any,
  activeKey?: any;
  modalID?: any;
  regulationFetched: boolean,
  tagData?: any,
  marketData ?: any,
  vehicleData?: any,
  subGroupData?: any,
  subScopeData?: any,
  vehicleFuelTypeData?: any,
  groupData?:any,
  scopeData?:any,
  topicData?: any,
  topicTypeData?:any,
  statusData?: any,
  enfDtFromData?: any,
  enfDtToData?: any,
  expDtFromData?: any,
  expDtToData?: any,
  saveFlag: boolean,
  handlerType: string,
  FavoritesID?: any,
  filterUpdated: boolean,
  filterData?: any,
  AdvancedfilterName : string,
  URL?:any,
  shareFlag?: any,
  imgflag?: any,
  loading: boolean,
  shareBtnFlag: boolean,
  closeModal ?: any,
  favoritesShare?: any,
  getSearchTagList: () =>{},
  getTopicList: () => {},
  getMarketList: () => {},
  getVehicleList: () => {},
  getFavoritesList: (any) => {},
  getGroupList:() =>{},
  getVehicleFuelTypeList: () =>{},
  getDriveTypeList:() =>{},
  driveTypeData?: any,
  getScopeList: () => {},
  history ?: any,
  searchFilter ?:any
  editUserList: (any) => {};
 }

interface State{
  multiple: boolean,
  favorites: Favorites[],
  fromDateEnf?: any,
  toDateEnf?: any,
  fromDateExp: any,
  toDateExp: any,
  regulationFilterList: {},
  filterName: string,
  success: string,
  errorMessage: string,
  tagSelected?: any,
  groupSelected?: any,
  subGroupSelected?:any,
  scopeSelected?: any,
  subScopeSelected?:any,
  vehicleFuelTypeSelected?:any,
  marketSelected?: any,
  vehicleSelected?: any,
  topicSelected?: any,
  statusSelected?: any,
  activeKey: string,
  dataFlag: boolean,
  saveFlag: boolean,
  inputFlag:boolean,
  marketData ?: any,
  btnFlag: boolean,
  id ?: any,
  topicTypeSelected?: any,
  TopicList?: any,
  errorMessageUpdate: string,
  initialToggle: boolean,
  ModalFlag: boolean,
  formName: string,
  favoritesURL?: any,
  shareFlag: boolean,
  type?: any,
  applyBtnDisable: boolean,
  shareBtnFlag: boolean,
  marketOption?: any,
  subGroupList?: any,
  SubGroup?: any,
  subScopeList?: any,
  SubScope?: any,
  typeOfDriveSelected?:any,
  GroupList?: any,
  ScopeList?: any;
  DriveTypeList?:any,
  VehicleFuelTypeList?: any,
  subGroupDataList?: any,
  subScopeDataList?: any,
  errorMessageScope: string,
  searchFlag: boolean
  }

class ToggleFilter extends Component<Props, State> {
  constructor(props: Props){
    super(props);
    this.state = {
        multiple: false,
        favorites : [],
        fromDateEnf:this.props.enfDtFromData,
        toDateEnf: this.props.enfDtToData,
        fromDateExp:this.props.expDtFromData,
        toDateExp: this.props.expDtToData,
        regulationFilterList: {},
        filterName: this.props.AdvancedfilterName,
        errorMessage: null,
        success: null,
        tagSelected:this.props.tagData,
        groupSelected:[],
        subGroupSelected:[],
        scopeSelected:[],
        subScopeSelected:[],
        typeOfDriveSelected: [],
        vehicleFuelTypeSelected:[],
        marketSelected:[],
        vehicleSelected:[],
        topicTypeSelected:[],
        topicSelected:[],
        statusSelected:[],
        activeKey: "1",
        dataFlag: false,
        saveFlag: false,
        inputFlag: false,
        btnFlag: false,
        id: localStorage.getItem('FavoritesID'),
        TopicList: this.props.TopicList,
        subGroupList: [],
        subScopeList: [],
        errorMessageUpdate: null,
        initialToggle: false,
        ModalFlag: false,
        formName: "Share Filter",
        favoritesURL: this.props.URL,
        shareFlag: false,
        type: this.props.handlerType,
        applyBtnDisable: false,
        shareBtnFlag: this.props.shareBtnFlag,
        marketOption: [],
        SubGroup: [],
        GroupList: this.props.GroupList,
        SubScope: [],
        ScopeList: this.props.ScopeList,
        DriveTypeList: this.props.DriveTypeList,
        VehicleFuelTypeList: this.props.VehicleFuelTypeList,
        subGroupDataList: [],
        subScopeDataList: [],
        errorMessageScope: null,
        searchFlag: false
    };
  }
  componentDidMount(){
   //If props data is undefined then only calling its api
    if (!this.props.TopicList) {
      this.props.getTopicList();
    }
    if (!this.props.MarketList) {
      this.props.getMarketList();
    }
    if (!this.props.VehicleList) {
      this.props.getVehicleList();
    }
    if (!this.props.favorites) {
      let userID = JSON.parse(localStorage.getItem('user')).id
      this.props.getFavoritesList(userID);
    }
    if (!this.props.SearchTagList) {
      this.props.getSearchTagList();
    }
    if (!this.props.GroupList) {
      this.props.getGroupList();
    }
    if (!this.props.DriveTypeList) {
      this.props.getDriveTypeList();
    }
    if (!this.props.VehicleFuelTypeList) {
      this.props.getVehicleFuelTypeList();
    }
    if (!this.props.ScopeList) {
      this.props.getScopeList();
    }
    this.setState({saveFlag: this.props.saveFlag});
    this.setSubGroupList();
    this.setSubScopeList();
    if((localStorage.getItem('recentSearch') !== '') && (this.props.searchFilter === true)){
     this.handleSearchFilter()
    }
  }
  
  static getDerivedStateFromProps(newState, prevState) {
     return {
        success: newState.success,
        errorMessage: newState.errorMessage,
        errorMessageUpdate: newState.errorMessageUpdate,
        errorMessageScope: newState.errorMessageScope
      };
    }
    handleSearchFilter = () =>{
      let data = localStorage.getItem('recentSearch');
      const pairs = data.slice(0).split('&');
      const result = {};
      pairs.forEach(function(pair) {
        let obj = pair.split('=');
        if (!result[obj[0]]) {
          result[obj[0]] = [];
        }
         result[obj[0]].push(decodeURIComponent(obj[1]));
      });
          let params = JSON.parse(JSON.stringify(result))
          params && Object.keys({...params}).map((item, index) =>{
            if (item === 'markets') {
              let marketArr = [];
              let arr = [];
              [...params.markets].filter(elem =>{
                this.props.MarketList && this.props.MarketList.map(market =>{
                  if (elem == market.id) {
                    arr.push(market.label);
                    marketArr.push(market)
                  }
                })
              });
              this.setState({marketSelected: marketArr});
              let arr1 = []
              let {regulationFilterList} = this.state
              if (arr1.length < 1 && regulationFilterList.hasOwnProperty('markets')) {
                delete regulationFilterList['markets'];
              }
              else {
                regulationFilterList['markets'] = arr1;
              }
              marketArr && marketArr.forEach(element => {
              arr1.push(element.id)
              });
              regulationFilterList['markets'] = arr1
                    this.setState({marketSelected: marketArr});
            }
              if (item === 'tags') {
                let tagArr = [];
                [ ...params['tags']].filter(elem =>{
                   tagArr.push(elem)
                    });
                    let arr = []
                    let {regulationFilterList} = this.state
                    if(arr.length < 1 && regulationFilterList.hasOwnProperty('tags')){
                      delete regulationFilterList['tags'];
                    }
                    else{
                      regulationFilterList['tags']= arr
                    }
                    tagArr && tagArr.forEach(element => {
                      arr.push(element)
                    });
                    regulationFilterList['tags'] = arr
                    this.setState({tagSelected: tagArr})
              }

              if (item === 'groups') {
                let arr = [];
                let groupArr = [];
                [...params.groups].filter(elem =>{
                  this.props.GroupList && this.props.GroupList.map(group =>{
                    if (elem == group.id) {
                      arr.push(group.name);
                      groupArr.push(group)
                    }
                  })
                });
                let arr1 = [];
                let subGroupArrList = [];
                let {regulationFilterList} = this.state
                if (arr1.length < 1 && regulationFilterList.hasOwnProperty('groups')) {
                  delete regulationFilterList['groups'];
                }
                else {
                  regulationFilterList['groups'] = arr1;
                }
                groupArr && groupArr.forEach(element => {
                  arr1.push(element.id);
                  //Looping through group list and updating subgroup state
                  this.props.GroupList && this.props.GroupList.map(item =>{
                      if (item.id === element.id) {
                        item.subgroup.map(el =>{
                          subGroupArrList.push(el);
                        })
                       }
                    });
                });
                regulationFilterList['groups'] = arr1;
                this.setState({groupSelected: groupArr, subGroupList: subGroupArrList})
              }
              if (item === 'scopes') {
                let arr = [];
                let scopeArr = [];
                [...params.scopes].filter(elem =>{
                  this.props.ScopeList && this.props.ScopeList.map(scope =>{
                    if (elem == scope.id) {
                      arr.push(scope.name);
                      scopeArr.push(scope)
                    }
                  })
                });
                let arr1 = [];
                let subScopeArrList = [];
                let {regulationFilterList} = this.state
                if (arr1.length < 1 && regulationFilterList.hasOwnProperty('scopes')) {
                  delete regulationFilterList['scopes'];
                }
                else {
                  regulationFilterList['scopes'] = arr1;
                }
                scopeArr && scopeArr.forEach(element => {
                  arr.push(element.id);
                  //Looping through scopes list and updating subscope state
                  this.props.ScopeList && this.props.ScopeList.map(item =>{
                      if (item.id === element.id) {
                        item.subScope.map(el =>{
                          subScopeArrList.push(el);
                        })
                       }
                    });
                });
        
                regulationFilterList['scopes'] = arr1;
                this.setState({scopeSelected: scopeArr, subScopeList: subScopeArrList});
              }
              if (item === 'subGroups') {
                let arr = [];
                let subGroupArr = [];
               [...params.subGroups].filter(elem =>{
                  this.props.GroupList && this.props.GroupList.map(item =>{
                    item.subgroup && item.subgroup.map(subgroup =>{
                     if (elem == subgroup.id) {
                      arr.push(subgroup.name);
                      subGroupArr.push(subgroup)
                    }
                  })
                })
                });
                let arr1 = []
                let {regulationFilterList} = this.state
                if (arr1.length < 1 && regulationFilterList.hasOwnProperty('subGroups')) {
                  delete regulationFilterList['subGroups'];
                }
                else {
                  regulationFilterList['subGroups'] = arr1;
                }
                subGroupArr && subGroupArr.forEach(element => {
                  arr1.push(element.id)
                });
                regulationFilterList['subGroups'] = arr1;
                this.setState({subGroupSelected: subGroupArr})
              }
              if (item === 'subScopes') {
                let arr = [];
                let subScopeArr = [];
               [...params.subScopes].filter(elem =>{
                  this.props.ScopeList && this.props.ScopeList.map(item =>{
                    item.subScope && item.subScope.map(subScope =>{
                     if (elem == subScope.id) {
                      arr.push(subScope.name);
                      subScopeArr.push(subScope)
                    }
                  })
                })
                });
                let arr1 = []
                let {regulationFilterList} = this.state
                if (arr1.length < 1 && regulationFilterList.hasOwnProperty('subScopes')) {
                  delete regulationFilterList['subScopes'];
                }
                else {
                  regulationFilterList['subScopes'] = arr1;
                }
                subScopeArr && subScopeArr.forEach(element => {
                  arr1.push(element.id)
                });
                regulationFilterList['subScopes'] = arr1;
                this.setState({subScopeSelected: subScopeArr})
              }
              if (item === 'driveTypes') {
                let arr = [];
                let driveTypeArr = [];
                [...params.driveTypes].filter(elem =>{
                  this.props.DriveTypeList && this.props.DriveTypeList.map(driveType =>{
                    if (elem == driveType.id) {
                      arr.push(driveType.driveType);
                      driveTypeArr.push(driveType)
                    }
                  })
                });
                let arr1 = []
                let {regulationFilterList} = this.state
                if (arr1.length < 1 && regulationFilterList.hasOwnProperty('driveTypes')) {
                  delete regulationFilterList['driveTypes'];
                }
                else {
                  regulationFilterList['driveTypes'] = arr1;
                }
                driveTypeArr && driveTypeArr.forEach(element => {
                  arr1.push(element.id)
                });
                regulationFilterList['driveTypes'] = arr1;
                this.setState({typeOfDriveSelected: driveTypeArr})
              }
              if (item === 'fuelTypes') {
                let arr = [];
                let vehicleFuelTypeArr = [];
                [...params.fuelTypes].filter(elem =>{
                  this.props.VehicleFuelTypeList && this.props.VehicleFuelTypeList.map(vehicleFuelType =>{
                    if (elem == vehicleFuelType.id) {
                      arr.push(vehicleFuelType.type);
                      vehicleFuelTypeArr.push(vehicleFuelType)
                    }
                  })
                });
                let arr1 = []
                let {regulationFilterList} = this.state
                if (arr1.length < 1 && regulationFilterList.hasOwnProperty('fuelTypes')) {
                  delete regulationFilterList['fuelTypes'];
                }
                else {
                  regulationFilterList['fuelTypes'] = arr1;
                }
                vehicleFuelTypeArr&& vehicleFuelTypeArr.forEach(element => {
                  arr1.push(element.id)
                });
                regulationFilterList['fuelTypes'] = arr1;
                this.setState({vehicleFuelTypeSelected: vehicleFuelTypeArr})
              }
              if (item === 'vehicles') {
                let arr = [];
                let vehicleArr = [];
                [...params.vehicles].filter(elem =>{
                  this.props.VehicleList && this.props.VehicleList.map(vehicle =>{
                    if (elem == vehicle.id) {
                      arr.push(vehicle.category);
                      vehicleArr.push(vehicle)
                    }
                  })
                });
                let arr1 = []
                let {regulationFilterList} = this.state
                if (arr1.length < 1 && regulationFilterList.hasOwnProperty('vehicles')) {
                  delete regulationFilterList['vehicles'];
                }
                else {
                  regulationFilterList['vehicles'] = arr1;
                }
                vehicleArr && vehicleArr.forEach(element => {
                  arr1.push(element.id)
                });
                regulationFilterList['vehicles'] = arr1;
                this.setState({vehicleSelected: vehicleArr})
              }
              if (item === 'topics') {
                let arr = [];
                let topicArr = [];
                [...params.topics].filter(elem =>{
                  this.props.TopicList && this.props.TopicList.map(topic =>{
                    if (elem == topic.id) {
                      arr.push(topic.name);
                      topicArr.push(topic)
                    }
                  })
                });
                let arr1 = []
                let {regulationFilterList} = this.state
                if (arr1.length < 1 && regulationFilterList.hasOwnProperty('topics')) {
                  delete regulationFilterList['topics'];
                }
                else {
                  regulationFilterList['topics'] = arr1;
                }
                topicArr && topicArr.forEach(element => {
                  arr1.push(element.id)
                });
                regulationFilterList['topics'] = arr1
                this.setState({topicSelected: topicArr})
              }
              if (item === 'topicType') {
                let arr = [];
                [...params.topicType].filter(elem =>{
                  [...mockData['topicTypeArrUser'][localStorage.getItem('i18nextLng')]].map(topicType =>{
                    if (elem == topicType.type) {
                      arr.push(topicType.type);
                    }
                  })
                });
                let arr1 = [];
                arr && arr.forEach(element => {arr.push(element.id)});
                let {regulationFilterList} = this.state;
                if (arr1.length < 1 && regulationFilterList.hasOwnProperty('status')) {
                  delete regulationFilterList['topicType'];
                }
                else {
                regulationFilterList['topicType'] = arr1;
                }
                this.setState(regulationFilterList);
                if (arr.length > 0) {
                  //Setting lang specific topic type name
                  [...mockData['topicTypeArrUser'][localStorage.getItem('i18nextLng')]].map(item =>{
                    if (arr[0] === item.name) {
                      this.setState({topicTypeSelected: [item]});
                    }
                  });
                }
              }
              if (item === 'enforcementDateFrom') {
                [ ...params['enforcementDateFrom']].filter(elem =>{
                  const date = new Date(elem);
                   this.setState({fromDateEnf: date})
                    let {regulationFilterList} = this.state
                    regulationFilterList['enforcementDateFrom'] = moment(new Date(elem)).startOf('month').format('DD/MM/YYYY');
                    this.setState(regulationFilterList)
                   }
                )
              }
              if (item === 'enforcementDateTo') {
                   [ ...params['enforcementDateTo']].filter(elem =>{
                     let date =  moment(elem, "DD/MM/YYYY");
                     let enfTo = date.toDate();
                     this.setState({toDateEnf:enfTo})
                      let {regulationFilterList} = this.state
                      regulationFilterList['enforcementDateTo'] = moment(enfTo).endOf('month').format('DD/MM/YYYY');
                      this.setState(regulationFilterList);
                   }
                )
              }
              if (item === 'expiryDateFrom') {
                [ ...params['expiryDateFrom']].filter(elem =>{
                  let date = moment(elem, "DD/MM/YYYY")
                  let expFrom = date.toDate();
                  this.setState({fromDateExp: expFrom})
                  let {regulationFilterList} = this.state
                  regulationFilterList['expiryDateFrom'] = moment(expFrom).endOf('month').format('DD/MM/YYYY');
                    }
                 )
              }
              if (item === 'expiryDateTo') {
                [ ...params['expiryDateTo']].filter(elem =>{
                      let date = moment(elem, "DD/MM/YYYY")
                      let expTo = date.toDate();
                      this.setState({toDateExp: expTo})
                      let {regulationFilterList} = this.state
                      regulationFilterList['expiryDateTo'] = moment(expTo).endOf('month').format('DD/MM/YYYY');
                    }
                 )
              }
              if (item === 'status') {
                let arr = [];
                let statusArr = [];
                [...params.status].filter(elem =>{
                  [...mockData.statusUser[localStorage.getItem('i18nextLng')]].map(status =>{
                    if (elem == status.id) {
                      arr.push(status.name);
                      statusArr.push(status)
                    }
                  })
                });
                let arr1 = []
                let {regulationFilterList} = this.state
                  if (arr1.length < 1 && regulationFilterList.hasOwnProperty('status')) {
                    delete regulationFilterList['status'];
                  }
                  else {
                   regulationFilterList['status'] = arr1;
                  }
                  statusArr && statusArr.forEach(element => {
                  arr1.push(element.id)
                });
                regulationFilterList['status'] = arr1
                this.setState({statusSelected: statusArr})
              }
            })
               let {regulationFilterList} = this.state;
        let language= localStorage.getItem('i18nextLng')
        const queryParam = qs.parse(regulationFilterList);
        const newQueryParam = {...queryParam,ln: language}
        this.props.getRegulationDetails(newQueryParam);
        this.setState({searchFlag: false})
        
    }
    componentDidUpdate(prevProps, prevState){
     // let tabData = localStorage.getItem('tab') !== null ? localStorage.getItem('tab') : '';
      //const lastTab = tabData?.filter((id) => id.userId == JSON.parse(localStorage.getItem('user'))?.id); 
      if((prevProps.searchFilter !== this.props.searchFilter)){
        if(this.props.searchFilter === true){
          this.handleSearchFilter()
        }
      }
      if(this.state.errorMessageScope && this.state.errorMessageScope !== prevState.errorMessageScope){
        const t = TranslationService.t;
        toast['error'](t(this.state.errorMessageScope), { position: 'top-right' });
      }

      if(prevProps.shareFlag !== this.props.shareFlag){
         this.setState({type: 'add'})
      }

      if (this.state.marketOption !== this.props.MarketList) {
        this.setState({marketOption: this.props.MarketList});
      }

      if (this.props.VehicleList !== prevProps.VehicleList) {
        let vehicleSelectedArr = [];
        if (this.state.vehicleSelected.length > 0) {
          //Looping through vehicle list and pushing selected vehicles id's in state
          [...this.props.VehicleList].map(item =>{
            [...this.state.vehicleSelected].map(elem =>{
              if (elem.id === item.id) {
                vehicleSelectedArr.push(item);
              }
            });
          });
          this.setState({vehicleSelected: vehicleSelectedArr});
        }
      }

      //Close share filter modal after api call success
      if (this.props.favoritesShare && prevProps.favoritesShare !== this.props.favoritesShare) {
        this.closeModal();
      }

      //Update filter apply button disabled property after click
      if (this.state.applyBtnDisable && (prevProps.loading !== this.props.loading)) {
        this.setState({applyBtnDisable: this.props.loading})
      }

      //Updating new favorites name into local state
      if(prevProps.AdvancedfilterName !== this.props.AdvancedfilterName){
          this.setState({filterName: this.props.AdvancedfilterName})
      }

      //Updating search query param's "tags" attribute
      if(prevProps.tagData !== this.props.tagData){
        let arr = []
        let {regulationFilterList} = this.state
        if(arr.length < 1 && regulationFilterList.hasOwnProperty('tags')){
          delete regulationFilterList['tags'];
        }
        else{
          regulationFilterList['tags']= arr
        }
       this.props.tagData && this.props.tagData.forEach(element => {
          arr.push(element)
        });
        regulationFilterList['tags'] = arr
        this.setState({tagSelected: this.props.tagData})
      }

      //Updating search query param's "groups" attribute
      if(prevProps.groupData !== this.props.groupData){
        let arr = [];
        let subGroupArrList = [];
        let {regulationFilterList} = this.state
        if (arr.length < 1 && regulationFilterList.hasOwnProperty('groups')) {
          delete regulationFilterList['groups'];
        }
        else {
          regulationFilterList['groups'] = arr;
        }
        this.props.groupData && this.props.groupData.forEach(element => {
          arr.push(element.id);
          //Looping through group list and updating subgroup state
          this.props.GroupList && this.props.GroupList.map(item =>{
              if (item.id === element.id) {
                item.subgroup.map(el =>{
                  subGroupArrList.push(el);
                })
               }
            });
        });
        regulationFilterList['groups'] = arr;
        this.setState({groupSelected: this.props.groupData, subGroupList: subGroupArrList})
      }

      //Updating search query param's "scopes" attribute
      if(prevProps.scopeData !== this.props.scopeData){
        let arr = [];
        let subScopeArrList = [];
        let {regulationFilterList} = this.state
        if (arr.length < 1 && regulationFilterList.hasOwnProperty('scopes')) {
          delete regulationFilterList['scopes'];
        }
        else {
          regulationFilterList['scopes'] = arr;
        }
        this.props.scopeData && this.props.scopeData.forEach(element => {
          arr.push(element.id);
          //Looping through scopes list and updating subscope state
          this.props.ScopeList && this.props.ScopeList.map(item =>{
              if (item.id === element.id) {
                item.subScope.map(el =>{
                  subScopeArrList.push(el);
                })
               }
            });
        });

        regulationFilterList['scopes'] = arr;
        this.setState({scopeSelected: this.props.scopeData, subScopeList: subScopeArrList});
      }

      //Updating search query param's "subgroups" attribute
      if(prevProps.subGroupData !== this.props.subGroupData){
        let arr = []
        let {regulationFilterList} = this.state
        if (arr.length < 1 && regulationFilterList.hasOwnProperty('subGroups')) {
          delete regulationFilterList['subGroups'];
        }
        else {
          regulationFilterList['subGroups'] = arr;
        }
        this.props.subGroupData && this.props.subGroupData.forEach(element => {
          arr.push(element.id)
        });
        regulationFilterList['subGroups'] = arr;
        this.setState({subGroupSelected: this.props.subGroupData})
      }

      //Updating search query param's "subscopes" attribute
      if(prevProps.subScopeData !== this.props.subScopeData){
        let arr = []
        let {regulationFilterList} = this.state
        if (arr.length < 1 && regulationFilterList.hasOwnProperty('subScopes')) {
          delete regulationFilterList['subScopes'];
        }
        else {
          regulationFilterList['subScopes'] = arr;
        }
        this.props.subScopeData && this.props.subScopeData.forEach(element => {
          arr.push(element.id)
        });
        regulationFilterList['subScopes'] = arr;

        this.setState({subScopeSelected: this.props.subScopeData})
      }

      //Updating search query param's "drive types" attribute
      if(prevProps.driveTypeData !== this.props.driveTypeData){
        let arr = []
        let {regulationFilterList} = this.state
        if (arr.length < 1 && regulationFilterList.hasOwnProperty('driveTypes')) {
          delete regulationFilterList['driveTypes'];
        }
        else {
          regulationFilterList['driveTypes'] = arr;
        }
        this.props.driveTypeData && this.props.driveTypeData.forEach(element => {
          arr.push(element.id)
        });
        regulationFilterList['driveTypes'] = arr;
        this.setState({typeOfDriveSelected: this.props.driveTypeData})
      }

      //Updating search query param's "fuel types" attribute
      if(prevProps.vehicleFuelTypeData !== this.props.vehicleFuelTypeData){
        let arr = []
        let {regulationFilterList} = this.state
        if (arr.length < 1 && regulationFilterList.hasOwnProperty('fuelTypes')) {
          delete regulationFilterList['fuelTypes'];
        }
        else {
          regulationFilterList['fuelTypes'] = arr;
        }
        this.props.vehicleFuelTypeData && this.props.vehicleFuelTypeData.forEach(element => {
          arr.push(element.id)
        });
        regulationFilterList['fuelTypes'] = arr;
        this.setState({vehicleFuelTypeSelected: this.props.vehicleFuelTypeData})
      }

      //Updating search query param's "markets" attribute
      if(prevProps.marketData !== this.props.marketData){
        let arr = []
        let {regulationFilterList} = this.state
        if (arr.length < 1 && regulationFilterList.hasOwnProperty('markets')) {
          delete regulationFilterList['markets'];
        }
        else {
          regulationFilterList['markets'] = arr;
        }
       this.props.marketData && this.props.marketData.forEach(element => {
         arr.push(element.id)
        });
        regulationFilterList['markets'] = arr
        this.setState({marketSelected:this.props.marketData})
      }

      //Updating search query param's "vehicles" attribute
      if(prevProps.vehicleData !== this.props.vehicleData){
        let arr = []
        let {regulationFilterList} = this.state
        if (arr.length < 1 && regulationFilterList.hasOwnProperty('vehicles')) {
          delete regulationFilterList['vehicles'];
        }
        else {
          regulationFilterList['vehicles'] = arr;
        }
        this.props.vehicleData && this.props.vehicleData.forEach(element => {
          arr.push(element.id)
        });
        regulationFilterList['vehicles'] = arr;
        this.setState({vehicleSelected: this.props.vehicleData})
      }

      //Updating search query param's "topic types" attribute
      if(prevProps.topicTypeData !== this.props.topicTypeData){
        let arr = [];
        this.props.topicTypeData && this.props.topicTypeData.forEach(element => {arr.push(element.id)});
        let {regulationFilterList} = this.state;
        if (arr.length < 1 && regulationFilterList.hasOwnProperty('status')) {
          delete regulationFilterList['topicType'];
        }
        else {
        regulationFilterList['topicType'] = arr;
        }
        this.setState(regulationFilterList);
        if (this.props.topicTypeData.length > 0) {
          //Setting lang specific topic type name
          [...mockData['topicTypeArrUser'][localStorage.getItem('i18nextLng')]].map(item =>{
            if (this.props.topicTypeData[0] === item.name) {
              this.setState({topicTypeSelected: [item]});
            }
          });
        }
      }

      if (this.state.topicTypeSelected !== prevState.topicTypeSelected) {
        this.setTopicType();
      }

      if(this.state.groupSelected !== prevState.groupSelected){
        this.setGroupType();
      }

      if(this.state.subGroupSelected !== prevState.subGroupSelected){
        this.setSubGroupType();
      }

      if(this.state.scopeSelected !== prevState.scopeSelected){
        this.setScopeType();
      }

      if(this.state.subScopeSelected !== prevState.subScopeSelected){
        this.setSubScopeType();
      }

      if(this.state.typeOfDriveSelected !== prevState.typeOfDriveSelected){
        this.setDriveType();
      }

      if(this.state.vehicleFuelTypeSelected !== prevState.vehicleFuelTypeSelected){
        this.setFuelType();
      }

      //Updating search query param's "topics" attribute
      if(prevProps.topicData !== this.props.topicData){
        let arr = []
        let {regulationFilterList} = this.state
        if (arr.length < 1 && regulationFilterList.hasOwnProperty('topics')) {
          delete regulationFilterList['topics'];
        }
        else {
          regulationFilterList['topics'] = arr;
        }
        this.props.topicData && this.props.topicData.forEach(element => {
          arr.push(element.id)
        });
        regulationFilterList['topics'] = arr
        this.setState({topicSelected: this.props.topicData})
      }

      //Updating search query param's "regulation status" attribute
      if(prevProps.statusData !== this.props.statusData){
        let arr = []
        let {regulationFilterList} = this.state
          if (arr.length < 1 && regulationFilterList.hasOwnProperty('status')) {
            delete regulationFilterList['status'];
          }
          else {
           regulationFilterList['status'] = arr;
          }
        this.props.statusData && this.props.statusData.forEach(element => {
          arr.push(element.id)
        });
        regulationFilterList['status'] = arr
        this.setState({statusSelected: this.props.statusData})
      }

      //Updating search query param's "Enf start date" attribute
      if(prevProps.enfDtFromData !== this.props.enfDtFromData){
        this.setState({fromDateEnf: this.props.enfDtFromData})
        let {regulationFilterList} = this.state
        regulationFilterList['enforcementDateFrom'] = moment(this.props.enfDtFromData).startOf('month').format('DD/MM/YYYY');
        this.setState(regulationFilterList)
      }

      //Updating search query param's "Enf end date" attribute
      if(prevProps.enfDtToData !== this.props.enfDtToData){
          this.setState({toDateEnf: this.props.enfDtToData})
          let {regulationFilterList} = this.state
          regulationFilterList['enforcementDateTo'] = moment(this.props.enfDtToData).endOf('month').format('DD/MM/YYYY');
          this.setState(regulationFilterList);
      }

      //Updating search query param's "Exp start date" attribute
      if(prevProps.expDtFromData !== this.props.expDtFromData){
        this.setState({fromDateExp: this.props.expDtFromData})
        let {regulationFilterList} = this.state
        regulationFilterList['expiryDateFrom'] = moment(this.props.expDtFromData).endOf('month').format('DD/MM/YYYY');
      }

      //Updating search query param's "Exp end date" attribute
      if(prevProps.expDtToData !== this.props.expDtToData){
        this.setState({toDateExp: this.props.expDtToData})
        let {regulationFilterList} = this.state
        regulationFilterList['expiryDateTo'] = moment(this.props.expDtToData).endOf('month').format('DD/MM/YYYY');
      }

      if (this.props.TopicList !== prevProps.TopicList) {
        this.setTopicType();
          if (this.state.topicSelected.length > 0) {
            let topicListSelectedArr = [];
            [...this.props.TopicList].map(item =>{
              [...this.state.topicSelected].map(elem =>{
                if (elem.id === item.id) {
                  topicListSelectedArr.push(item);
                }
              });
            });
            this.setState({topicSelected: topicListSelectedArr});
          }
      }

      if (this.props.GroupList !== prevProps.GroupList) {
        this.setGroupType();
        this.setSubGroupList();
          if (this.state.groupSelected.length > 0) {
            let groupListSelectedArr = [];
            [...this.props.GroupList].map(item =>{
              [...this.state.groupSelected].map(elem =>{
                if (elem.id === item.id) {
                  groupListSelectedArr.push(item);
                }
              });
            });
            this.setState({groupSelected: groupListSelectedArr});
          }
      }

      if ((this.state.scopeSelected !== prevState.scopeSelected) ||
          (this.state.subScopeSelected !== prevState.subScopeSelected)) {
             this.setSubScopeList();
      }

      if ((this.state.groupSelected !== prevState.groupSelected) ||
          (this.state.subGroupSelected !== prevState.subGroupSelected)) {
             this.setSubGroupList();
      }

      if (this.props.ScopeList !== prevProps.ScopeList) {
        this.setScopeType();
        this.setSubScopeList();
          if (this.state.scopeSelected.length > 0) {
            let scopeListSelectedArr = [];
            [...this.props.ScopeList].map(item =>{
              [...this.state.scopeSelected].map(elem =>{
                if (elem.id === item.id) {
                  scopeListSelectedArr.push(item);
                }
              });
            });
            this.setState({scopeSelected: scopeListSelectedArr});
          }
      }

      if(prevState.subGroupDataList === this.state.subGroupDataList){
         if (this.state.subGroupSelected.length > 0) {
            this.setSubGroupType();
            let subGroupListSelectedArr = [];
            [...this.props.GroupList].map(item =>{
              item.subgroup.map(el =>{
               [...this.state.subGroupSelected].map(elem =>{
                 if (elem.id === el.id) {
                   subGroupListSelectedArr.push(el);
                  }
                });
              });
            });
            this.setState({subGroupSelected: subGroupListSelectedArr});
         }
       }

       if(prevState.subScopeDataList === this.state.subScopeDataList){
          if (this.state.subScopeSelected.length > 0) {
             this.setSubScopeType();
             let subScopeListSelectedArr = [];
             [...this.props.ScopeList].map(item =>{
               item.subScope.map(el =>{
                [...this.state.subScopeSelected].map(elem =>{
                  if (elem.id === el.id) {
                    subScopeListSelectedArr.push(el);
                   }
                 });
               });
             });
             this.setState({subScopeSelected: subScopeListSelectedArr});
          }
      }

      if (this.props.DriveTypeList !== prevProps.DriveTypeList) {
        this.setDriveType();
          if (this.state.typeOfDriveSelected.length > 0) {
            let driveTypeListSelectedArr = [];
            [...this.props.DriveTypeList].map(item =>{
              [...this.state.typeOfDriveSelected].map(elem =>{
                if (elem.id === item.id) {
                  driveTypeListSelectedArr.push(item);
                }
              });
            });
            this.setState({typeOfDriveSelected: driveTypeListSelectedArr});
          }
      }

      if (this.props.VehicleFuelTypeList !== prevProps.VehicleFuelTypeList) {
        this.setFuelType();
          if (this.state.vehicleFuelTypeSelected.length > 0) {
            let fuelTypeListSelectedArr = [];
            [...this.props.VehicleFuelTypeList].map(item =>{
              [...this.state.vehicleFuelTypeSelected].map(elem =>{
                if (elem.id === item.id) {
                  fuelTypeListSelectedArr.push(item);
                }
              });
            });
            this.setState({vehicleFuelTypeSelected: fuelTypeListSelectedArr});
          }
      }

      //Upating all the states after app language is changed
      if (this.props.t !== prevProps.t) {
        if (this.state.topicTypeSelected.length > 0) {
          let topicTypeSelectedArr = [];
          [...mockData['topicTypeArrUser'][localStorage.getItem('i18nextLng')]].map(item =>{
            [...this.state.topicTypeSelected].map(elem =>{
              if (elem.id === item.id) {
                topicTypeSelectedArr.push(item);
              }
            });
          });
          this.setState({topicTypeSelected: topicTypeSelectedArr});
        }

        if (this.state.statusSelected.length > 0) {
          let statusSelectedArr = [];
          [...mockData['statusUser'][localStorage.getItem('i18nextLng')]].map(item =>{
            [...this.state.statusSelected].map(elem =>{
              if (elem.id === item.id) {
                statusSelectedArr.push(item);
              }
            });
          });
          this.setState({statusSelected: statusSelectedArr});
        }

        if ((this.props.activeKey == 2 && this.state.dataFlag === true)) {
          this.applyFilter();
        }

        if (window.location.href.indexOf("/favorites") > -1) {
          this.applyFilter();
        }
        this.setSubScopeList();
        this.setSubGroupList();
      }

      //Clearing all the states after navigating to other tab
      if ((prevProps.activeKey !== this.props.activeKey) && (this.props.searchFilter === false)) {
        this.props.imgflag(true);
        this.setState({
          regulationFilterList: {},tagSelected:[],groupSelected:[],subGroupSelected:[],scopeSelected:[],subScopeSelected:[],
          typeOfDriveSelected:[],vehicleFuelTypeSelected:[],marketSelected:[],vehicleSelected:[],topicSelected:[],statusSelected:[],
          fromDateEnf:null,toDateEnf:null,fromDateExp:null,toDateExp:null,dataFlag:false,saveFlag:false, initialToggle: false,
          inputFlag:false});
      }

      if (this.props.filterUpdated && prevProps.filterUpdated !== this.props.filterUpdated) {
          this.setState({inputFlag: false});
      }

      if (this.props.filterSaved && prevProps.filterSaved !== this.props.filterSaved) {
          this.setState({filterName: '',saveFlag:false, inputFlag: false,shareBtnFlag: false});
      }

      if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
          this.setState({inputFlag: true ,saveFlag: true})
      }

      if(this.state.errorMessageUpdate && this.state.errorMessageUpdate !== prevState.errorMessageUpdate){
        this.setState({inputFlag: true})
      }

      if(this.props.RegulationDataList && this.props.RegulationDataList.length === 0){
        if (prevProps.regulationFetched && prevProps.regulationFetched !== this.props.regulationFetched) {
          toast['info'](t('advancedFilters.infoMsg'), { position: 'top-right'});
        }
      }

      if(prevProps.regulationFetched && prevProps.regulationFetched !== this.props.regulationFetched){
        this.props.imgflag(false);
        if(this.props.RegulationDataList && this.props.RegulationDataList.length > 0){
          if(Object.keys(this.state.regulationFilterList).length < 1){
            this.setState({saveFlag: false})
           }
          else{
            this.setState({saveFlag : true})
          }
        }
      }
      if(!this.props.saveFlag){
         if(this.props.RegulationDataList && this.props.RegulationDataList.length > 0){
            if (prevProps.regulationFetched && prevProps.regulationFetched !== this.props.regulationFetched) {
               if(Object.keys(this.state.regulationFilterList).length < 1){
                 this.setState({saveFlag: false,inputFlag: false,shareBtnFlag: false})
                }
               else if(this.props.shareBtnFlag === true){
                this.setState({shareBtnFlag: true})
               }
               else{
                 this.setState({saveFlag: true , shareBtnFlag: false})
               }
             }
          }
      }
    }

    //setting selected topic type and showing in favorites data page.
    setTopicType = () =>{
      if (this.state.topicTypeSelected.length > 0) {
        let topicListArr = [];
        this.props.TopicList && [...this.props.TopicList].map(item =>{
          if (item.type === this.state.topicTypeSelected[0].type) {
            topicListArr.push(item);
          }
        });
        this.setState({TopicList: topicListArr});
      }
      else{
        this.setState({TopicList: this.props.TopicList});
      }
   
    }

    //setting selected group type and showing in favorites data page.
    setGroupType = () =>{
      if (this.state.groupSelected.length > 0) {
        let groupListArr = [];
        this.props.GroupList && [...this.props.GroupList].map(item =>{
          if (item.name === this.state.groupSelected[0].name) {
            groupListArr.push(item);
          }
        });
        this.setState({GroupList: groupListArr});
      }
      else{
        this.setState({GroupList: this.props.GroupList});
      }
    }

    //setting selected scope type and showing in favorites data page.
    setScopeType = () =>{
      if (this.state.scopeSelected.length > 0) {
        let scopeListArr = [];
        this.props.ScopeList && [...this.props.ScopeList].map(item =>{
          if (item.name === this.state.scopeSelected[0].name) {
            scopeListArr.push(item);
          }
        });
        this.setState({ScopeList: scopeListArr});
      }
      else{
        this.setState({ScopeList: this.props.ScopeList});
      }
    }

    //setting selected sub-group type and showing in favorites data page.
    setSubGroupType = () =>{
     if (this.state.subGroupSelected.length > 0) {
        let subGroupListArr = [];
        this.props.GroupList && [...this.props.GroupList].map(item =>{
          item.subgroup.map(el =>{
          if (el.name === this.state.subGroupSelected[0].name) {
            subGroupListArr.push(el);
          }
        })
        });
        this.setState({subGroupDataList: subGroupListArr});
      }
      else{
        let arr = [];
        this.props.GroupList && this.props.GroupList.map(item =>{
         item.subgroup.map(el =>{
            arr.push(el)
           })
        });
        this.setState({subGroupDataList: arr})
      }
    }

    //setting selected sub-scope type and showing in favorites data page.
    setSubScopeType = () =>{
     if (this.state.subScopeSelected.length > 0) {
        let subScopeListArr = [];
        this.props.ScopeList && [...this.props.ScopeList].map(item =>{
          item.subScope.map(el =>{
          if (el.name === this.state.subScopeSelected[0].name) {
            subScopeListArr.push(el);
          }
        })
        });
        this.setState({subScopeDataList: subScopeListArr});
      }
      else{
        let arr = [];
        this.props.ScopeList && this.props.ScopeList.map(item =>{
         item.subScope.map(el =>{
            arr.push(el)
           })
        });
        this.setState({subScopeDataList: arr})
      }
    }

    //setting selected drive type and showing in favorites data page.
    setDriveType = () =>{
      if (this.state.typeOfDriveSelected.length > 0) {
        let driveTypeListArr = [];
        this.props.DriveTypeList && [...this.props.DriveTypeList].map(item =>{
          if (item.type === this.state.typeOfDriveSelected[0].driveType) {
            driveTypeListArr.push(item);
          }
        });
        this.setState({DriveTypeList: driveTypeListArr});
      }
      else{
        this.setState({DriveTypeList: this.props.DriveTypeList});
      }
    }

    //setting selected fuel type and showing in favorites data page.
    setFuelType = () =>{
      if (this.state.vehicleFuelTypeSelected.length > 0) {
        let fuelTypeListArr = [];
        this.props.VehicleFuelTypeList && [...this.props.VehicleFuelTypeList].map(item =>{
          if (item.type === this.state.vehicleFuelTypeSelected[0].type) {
            fuelTypeListArr.push(item);
          }
        });
        this.setState({VehicleFuelTypeList: fuelTypeListArr});
      }
      else{
        this.setState({VehicleFuelTypeList: this.props.VehicleFuelTypeList});
      }
    }

    //setting value in from date typehead on change.
    handleFromDateEnf = date => {
      this.setState({fromDateEnf: date ,saveFlag:false, inputFlag: false , shareBtnFlag: false});
      let {regulationFilterList} = this.state;
      if (date === null && regulationFilterList.hasOwnProperty('enforcementDateFrom')) {
        delete regulationFilterList['enforcementDateFrom'];
      }
      else{
        regulationFilterList['enforcementDateFrom'] = moment(date).startOf('month').format('DD/MM/YYYY');
      }
      this.setState(regulationFilterList);
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    //setting value in to date typehead on change.
    handleToDateEnf = date => {
      this.setState({toDateEnf: date,saveFlag:false, inputFlag: false,shareBtnFlag: false});
      let {regulationFilterList} = this.state;
      if (date === null && regulationFilterList.hasOwnProperty('enforcementDateTo')) {
        delete regulationFilterList['enforcementDateTo'];
      }
      else{
        regulationFilterList['enforcementDateTo'] = moment(date).endOf('month').format('DD/MM/YYYY');
      }
      this.setState(regulationFilterList);
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    //setting value in expiry from date typehead on change.
    handleFromDateExp = date => {
      this.setState({fromDateExp: date ,saveFlag:false, inputFlag: false,shareBtnFlag: false});
      let {regulationFilterList} = this.state;
      if (date === null && regulationFilterList.hasOwnProperty('expiryDateFrom')) {
        delete regulationFilterList['expiryDateFrom'];
      }
      else{
        regulationFilterList['expiryDateFrom'] = moment(date).startOf('month').format('DD/MM/YYYY');
      }
      this.setState(regulationFilterList);
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
      }

    //setting value in expiry to date typehead on change.
    handleToDateExp = date => {
      this.setState({toDateExp: date, saveFlag:false, inputFlag: false,shareBtnFlag: false});
      let {regulationFilterList} = this.state;
      if (date === null && regulationFilterList.hasOwnProperty('expiryDateTo')) {
        delete regulationFilterList['expiryDateTo'];
      }
      else{
        regulationFilterList['expiryDateTo'] = moment(date).endOf('month').format('DD/MM/YYYY');
      }
      this.setState(regulationFilterList);
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    // setting value in tags typehead on change.
    tagHandler = data =>{
      this.setState({tagSelected: data})
      let arr = [];
      data.forEach(element => {arr.push(element)});
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('tags')) {
        delete regulationFilterList['tags'];
      }
      else {
        regulationFilterList['tags'] = arr;
      }
      this.setState(regulationFilterList);
      this.setState({saveFlag: false, inputFlag: false,shareBtnFlag: false})
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    // setting value in group typehead on change.
    groupHandler = data =>{
      this.setState({groupSelected: data});
      let {regulationFilterList} = this.state;
      let arr = [];
      data.forEach(element => {
        arr.push(element.id);
        this.state.subGroupSelected && this.state.subGroupSelected.map(item =>{
          if(element.name !== item.parentGroupName){
            delete regulationFilterList['subGroups'];
            this.setState({subGroupSelected: []})
          }
        })
      });
      let subGroupArr = [];
      if (data.length > 0) {
        data.forEach(element => {
        this.props.GroupList && this.props.GroupList.map(item =>{
            if (item.id === element.id) {
              item.subgroup.map(el =>{
                subGroupArr.push(el);
              })
             }
          });
        });
        this.setState({subGroupList: subGroupArr});
      }
      else{
        this.setSubGroupList();
      }
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('groups')) {
        delete regulationFilterList['groups'];
      }
      else {
        regulationFilterList['groups'] = arr;
      }
      this.setState(regulationFilterList);
      this.setState({saveFlag: false, inputFlag: false, shareBtnFlag: false})
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    // setting value in scope typehead on change.
    scopeHandler = data =>{
      this.setState({scopeSelected: data});
      let {regulationFilterList} = this.state;
      let arr = [];
      data.forEach(element => {
        arr.push(element.id);
        this.state.subScopeSelected && this.state.subScopeSelected.map(item =>{
          if(element.name !== item.parentScopeName){
            delete regulationFilterList['subScopes'];
            this.setState({subScopeSelected: []})
          }
        })
      });
      let subScopeArr = [];
      if (data.length > 0) {
        data.forEach(element => {
        this.props.ScopeList && this.props.ScopeList.map(item =>{
            if (item.id === element.id) {
              item.subScope.map(el =>{
                subScopeArr.push(el);
              })
             }
          });
        });
        this.setState({subScopeList: subScopeArr});
      }
      else{
        this.setSubScopeList();
      }
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('scopes')) {
        delete regulationFilterList['scopes'];
      }
      else {
        regulationFilterList['scopes'] = arr;
      }
      this.setState(regulationFilterList);
      this.setState({saveFlag: false, inputFlag: false, shareBtnFlag: false})
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    // setting value in sub-group typehead on change.
    subGroupHandler = data =>{
      this.setState({subGroupSelected: data});
      let arr = [];
      data.forEach(element => {
        arr.push(element.id)
      });
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('subGroups')) {
        delete regulationFilterList['subGroups'];
      }
      else {
        regulationFilterList['subGroups'] = arr;
      }
      this.setState(regulationFilterList);
      this.setState({saveFlag: false, inputFlag: false,shareBtnFlag: false})
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    // setting value in sub-scope typehead on change.
    subScopeHandler = data =>{
      this.setState({subScopeSelected: data});
      let arr = [];
      data.forEach(element => {
        arr.push(element.id)
      });
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('subScopes')) {
        delete regulationFilterList['subScopes'];
      }
      else {
        regulationFilterList['subScopes'] = arr;
      }
      this.setState(regulationFilterList);
      this.setState({saveFlag: false, inputFlag: false,shareBtnFlag: false})
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    // setting value in drive type typehead on change.
    typeOfDriveHandler = data =>{
      this.setState({typeOfDriveSelected: data});
      let arr = [];
      data.forEach(element => {arr.push(element.id)});
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('driveTypes')) {
        delete regulationFilterList['driveTypes'];
      }
      else {
        regulationFilterList['driveTypes'] = arr;
      }
      this.setState(regulationFilterList);
      this.setState({saveFlag: false, inputFlag: false,shareBtnFlag: false})
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    // setting value in fuel type typehead on change.
    vehicleFuelTypeHandler = data =>{
      this.setState({vehicleFuelTypeSelected: data});
      let arr = [];
      data.forEach(element => {arr.push(element.id)});
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('fuelTypes')) {
        delete regulationFilterList['fuelTypes'];
      }
      else {
        regulationFilterList['fuelTypes'] = arr;
      }
      this.setState(regulationFilterList);
      this.setState({saveFlag: false, inputFlag: false,shareBtnFlag: false})
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    // setting value in market typehead on change.
    marketHandler = data =>{
      this.setState({marketSelected: data})
      let arr = [];
      data.forEach(element => {arr.push(element.id)});
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('markets')) {
        delete regulationFilterList['markets'];
      }
      else {
        regulationFilterList['markets'] = arr;
      }
      this.setState(regulationFilterList);
      this.setState({saveFlag: false, inputFlag: false,shareBtnFlag: false})
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    // setting value in topic typehead on change.
    topicHandler = data =>{
      this.setState({topicSelected: data});
      let arr = [];
      data.forEach(element => {arr.push(element.id)});
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('topics')) {
        delete regulationFilterList['topics'];
      }
      else {
        regulationFilterList['topics'] = arr;
      }
      this.setState(regulationFilterList);
      this.setState({saveFlag: false, inputFlag: false,shareBtnFlag: false})
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    // setting value in topic type typehead on change.
    topicTypeHandler = data =>{
      this.setState({topicTypeSelected: data, topicSelected:[]});
      let topicListArr = [];
      if (data.length > 0) {
        [...this.props.TopicList].map(item =>{
          if (item.type === data[0].type) {
            topicListArr.push(item);
          }
        });
      }
      else{
        topicListArr = [...this.props.TopicList];
      }
      let arr = [];
      data.forEach(element => {arr.push(element.type)});
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('topicType')) {
        delete regulationFilterList['topicType'];
      }
      else {
        regulationFilterList['topicType'] = arr[0];
      }
      regulationFilterList['topics'] = [];
      this.setState(regulationFilterList);
      this.setState({saveFlag: false, inputFlag: false, TopicList: topicListArr,shareBtnFlag: false})
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }


    // setting value in vehicle typehead on change.
    vehicleHandler = data =>{
      this.setState({vehicleSelected: data});
      let arr = [];
      data.forEach(element => {arr.push(element.id)});
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('vehicles')) {
        delete regulationFilterList['vehicles'];
      }
      else {
        regulationFilterList['vehicles'] = arr;
      }
      this.setState(regulationFilterList);
      this.setState({saveFlag: false, inputFlag: false,shareBtnFlag: false})
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    // setting value in status typehead on change.
    statusHandler = data =>{
      this.setState({statusSelected: data});
      let arr = [];
      data.forEach(element => {arr.push(element.id)});
      let {regulationFilterList} = this.state;
      if (arr.length < 1 && regulationFilterList.hasOwnProperty('status')) {
        delete regulationFilterList['status'];
      }
      else {
        regulationFilterList['status'] = arr;
      }
      this.setState(regulationFilterList);
      this.setState({saveFlag: false, inputFlag: false,shareBtnFlag: false})
      if(this.props.AdvancedfilterName === ''){
        this.setState({filterName: ''})
      }
      else{
        this.setState({filterName: this.state.filterName})
      }
      this.props.imgflag(true)
    }

    handleMarketInputChange = (event) =>{
      let country = [];
      [...this.props.MarketList].forEach(elem => {
        if (elem.label.toUpperCase().startsWith(event.toUpperCase())) {
          country.push(elem);
        }
      });
      this.setState({marketOption: country});
    }

    //call on apply filter button click ,display the regulation details as per applied filter in table.
    applyFilter = () =>{
      let {regulationFilterList} = this.state;
      let language= localStorage.getItem('i18nextLng')
      const queryParam = qs.parse(regulationFilterList);
      const newQueryParam = {...queryParam,ln: language}
      this.props.getRegulationDetails(newQueryParam);
      this.setState({dataFlag: true, applyBtnDisable: true});
      this.props.filterData(regulationFilterList)
      const filterUrl = qs.stringify(regulationFilterList,{arrayFormat: 'repeat'});
      localStorage.setItem('recentSearch', filterUrl)
      const dateUTC = new Date().toISOString();
      let obj = {
        "recentTab": localStorage.getItem('tab'),
        "recentSearch": filterUrl,
        "lastLogin": localStorage.getItem('lastLogin')
      }
    this.props.editUserList(obj)
    }

      //call on click of save filter button and open input filed form.
    handleSaveFilter = ()=>{
      if(this.props.AdvancedfilterName === 'null'){
        this.setState({shareFlag : true})
      }
      this.setState({inputFlag:true})
    }

    //call on save button click and save the filter in favorites.
    saveFilter = (event) =>{
      event.preventDefault();
      const filterNamePattern = /^[a-zA-Z0-9_-\s]+$/;
      let {filterName, regulationFilterList} = this.state;
      if (filterName.trim() === '') {
        this.setState({filterName: ''});
        toast(t('favorites.filterBlankMsg'), { position: 'top-right', type: 'error'});
      }
      else if(!filterNamePattern.test(filterName)){
        toast(t('favorites.noSplChar'), { position: 'top-right', type: 'error'});
      }
      else{
        const filterUrl = qs.stringify(regulationFilterList,{arrayFormat: 'repeat'});
        saveFilterApi(this.state.type, this.state.id, filterName, filterUrl, this.props);
      }
    }

    //open share filter modal.
    openModel = () =>{
      this.setState({ModalFlag: true})
    }

    //close share filter modal.
    closeModal=() =>{
      this.setState({ModalFlag: false})
    }
    //setting filter name value in input field.
    handleFilterName(e){
      this.setState({filterName: e.target.value});
    }

    clearFromEnf (event) {this.setState({fromDateEnf: null}); delete this.state.regulationFilterList['enforcementDateFrom']};
    clearToEnf (event) {this.setState({toDateEnf: null}); delete this.state.regulationFilterList['enforcementDateTo']};
    clearFromExp (event) {this.setState({fromDateExp: null}); delete this.state.regulationFilterList['expiryDateFrom']};
    clearToExp (event) {this.setState({toDateExp: null}); delete this.state.regulationFilterList['expiryDateTo']};

    //open and close filter.
    handleToggle = () =>{
      this.setState({initialToggle: !this.state.initialToggle})
    }

     //setting selected sub-group and showing in favorites data page.
    setSubGroupList = () =>{
      if (this.state.groupSelected.length > 0) {
        let subGroupArr = [];
        [...this.props.GroupList].map(item =>{
          [...this.state.groupSelected].map(elem =>{
            if (elem.id === item.id) {
              item.subgroup.map(el =>{
                 subGroupArr.push(el)
                })
            }
          });
        });
        this.setState({subGroupList: subGroupArr});
      }
      else{
        let subGroupArr = [];
        this.props.GroupList && this.props.GroupList.map(item =>{
         item.subgroup?.map(el =>{
            subGroupArr.push(el)
           })
        });
        this.setState({subGroupList: subGroupArr});
      }
    }

    //setting selected sub-scope and showing in favorites data page.
    setSubScopeList = () =>{
      if (this.state.scopeSelected.length > 0) {
        let subScopeArr = [];
        [...this.props.ScopeList].map(item =>{
          [...this.state.scopeSelected].map(elem =>{
            if (elem.id === item.id) {
              item.subScope.map(el =>{
                 subScopeArr.push(el)
                })
            }
          });
        });
        this.setState({subScopeList: subScopeArr});
      }
      else{
        let subScopeArr = [];
        this.props.ScopeList && this.props.ScopeList.map(item =>{
         item.subScope?.map(el =>{
            subScopeArr.push(el)
           })
        });
        this.setState({subScopeList: subScopeArr});
      }
    }
    render() {
        const{t, i18n} = this.props
        const {multiple, regulationFilterList} = this.state;
        let formTemplate, modalTitle = null;
        if(this.state.formName === 'Share Filter') {
          formTemplate = <ShareForm {...this.props}  closeModal={this.closeModal} FilterName={this.state.filterName}/>
          modalTitle = i18next.t('shareFormTitle');
        }
     
        return (
          <Translation>{t => (<Fragment>
            <div id="AdvancedFilterPage">
              <div id="accordionOne">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button style={{'cursor':'default'}} className="btn">{t('advancedFilters.toogleFilter')}</button>
                      {
                        !this.state.initialToggle ?
                        <ToggleIconMinus style={{'cursor':'pointer'}} onClick={this.handleToggle} color="#545cd8" fontSize="18px" icon="md-notifications-outline"/>
                        :
                        <ToggleIconPlus style={{'cursor':'pointer'}} onClick={this.handleToggle} color="#545cd8" fontSize="18px" icon="md-notifications-outline"/>
                      }
                    </h5>
                  </div>
                  <div id="collapseOne" className={!this.state.initialToggle ? 'show col-12' : 'collapse col-12'}>
                    <div className="card-body col-12">
                      <Row>
                      { this.state.ModalFlag &&
                        <EditModalComp modalID="shareModal" closeModal={this.closeModal} modalBody={formTemplate} modalTitle={modalTitle} URL={this.state.favoritesURL} />
                      }
                         <div id="tagHandler" className="divPadding">
                          <h6><b>{t('advancedFilters.tag')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelkey="label"
                              multiple
                              selected={this.state.tagSelected}
                              onChange={(e) => this.tagHandler(e)}
                              options={this.props.SearchTagList ? this.props.SearchTagList : []}
                              placeholder={t('advancedFilters.searchTag')}
                          />
                        </div>
                        <div id="groupHandler" className="divPadding">
                          <h6><b>{t('advancedFilters.group')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="name"
                              multiple
                              selected={this.state.groupSelected}
                              onChange={(e) => this.groupHandler(e)}
                              options={this.props.GroupList ? this.props.GroupList : []}
                              placeholder={t('advancedFilters.selectGrp')}
                          />
                        </div>
                        <div id="subGroupHandler" className="divPadding">
                          <h6><b>{t('advancedFilters.sub-group')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="name"
                              multiple
                              selected={this.state.subGroupSelected}
                              onChange={(e) => this.subGroupHandler(e)}
                              options={this.state.subGroupList ? this.state.subGroupList : []}
                              renderMenuItemChildren={(option) => (
                                <div>
                                  {option.name}
                                  <div style={{'fontSize':'xx-small', 'fontWeight':'bolder'}}>
                                    {i18next.t('groupName')}: {i18next.t(option.parentGroupName)}
                                  </div>
                                </div>
                              )}
                              placeholder={t('advancedFilters.selectSubGrp')}
                          />
                        </div>
                        <div id="typeOfDriveHandler" className="divPadding">
                          <h6><b>{t('advancedFilters.typeOfDrive')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="driveType"
                              multiple
                              selected={this.state.typeOfDriveSelected}
                              onChange={(e) => this.typeOfDriveHandler(e)}
                              options={this.props.DriveTypeList ? this.props.DriveTypeList: []}
                              placeholder={t('advancedFilters.defaultAllPlaceholder')}
                          />
                        </div>
                        <div id="fuelTypeHandler" className="divPadding">
                          <h6><b>{t('advancedFilters.fuelType')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="type"
                              multiple
                              selected={this.state.vehicleFuelTypeSelected}
                              onChange={(e) => this.vehicleFuelTypeHandler(e)}
                              options={this.props.VehicleFuelTypeList ? this.props.VehicleFuelTypeList : []}
                              placeholder={t('advancedFilters.defaultAllPlaceholder')}
                          />
                        </div>
                        <div id="marketHandler" className="divPadding">
                          <h6><b>{t('advancedFilters.market')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="label"
                              multiple
                              selected={this.state.marketSelected}
                              onChange={(e) => this.marketHandler(e)}
                              onInputChange={this.handleMarketInputChange.bind(this)}
                              options={this.state.marketOption ? this.state.marketOption : []}
                              placeholder={t('advancedFilters.selectMkt')}
                          />
                        </div>
                        </Row>
                        <Row>
                        <div id="vehicleClassHandler" className="divPadding">
                          <h6><b>{t('advancedFilters.vehicle')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="category"
                              multiple
                              selected={this.state.vehicleSelected}
                              onChange={(e) => this.vehicleHandler(e)}
                              options={this.props.VehicleList ? this.props.VehicleList : []}
                              placeholder={t('advancedFilters.defaultAllPlaceholder')}
                          />
                        </div>

                        <div id="typeOfTopicHandler" className="divPadding">
                          <h6><b>{t('advancedFilters.topicType')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-single"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="name"
                              selected={this.state.topicTypeSelected ? this.state.topicTypeSelected : []}
                              onChange={(e) => this.topicTypeHandler(e)}
                              options={mockData['topicTypeArrUser'][localStorage.getItem('i18nextLng')]}
                              placeholder={t('advancedFilters.selectTopicType')}
                          />
                        </div>

                        <div id="topicHandler" className="divPadding">
                          <h6><b>{t('advancedFilters.topic')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="name"
                              multiple
                              selected={this.state.topicSelected}
                              onChange={(e) => this.topicHandler(e)}
                              options={this.state.TopicList ? this.state.TopicList : []}
                              renderMenuItemChildren={(option) => (
                                <div>
                                  {option.name}
                                  <div style={{'fontSize':'xx-small', 'fontWeight':'bolder'}}>
                                    {i18next.t('type')}: {i18next.t(option.type)}
                                  </div>
                                </div>
                              )}
                              placeholder={t('advancedFilters.selectTopic')}
                          />
                        </div>

                        <div id="scopeHandler" className="divPadding">
                          <h6><b>{t('advancedFilters.scope')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="name"
                              multiple
                              selected={this.state.scopeSelected ? this.state.scopeSelected : []}
                              onChange={(e) => this.scopeHandler(e)}
                              options={this.props.ScopeList ? this.props.ScopeList : []}
                              placeholder={t('advancedFilters.selectScope')}
                          />
                        </div>
                        <div id="subScopeHandler" className="divPadding">
                          <h6><b>{t('advancedFilters.subScope')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="name"
                              multiple
                              selected={this.state.subScopeSelected}
                              onChange={(e) => this.subScopeHandler(e)}
                              options={this.state.subScopeList ? this.state.subScopeList : []}
                              renderMenuItemChildren={(option) => (
                                <div>
                                  {option.name}
                                  <div style={{'fontSize':'xx-small', 'fontWeight':'bolder'}}>
                                    {i18next.t('advancedFilters.scopeName')}: {i18next.t(option.parentScopeName)}
                                  </div>
                                </div>
                              )}
                              placeholder={t('advancedFilters.selectSubScope')}
                          />
                        </div>
                        {/* <div id="statusHandler" className="divPadding">
                          <h6><b>{t('advancedFilters.status')}</b></h6>
                          <Typeahead
                              id="basic-typeahead-multiple"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="name"
                              multiple
                              selected={this.state.statusSelected}
                              onChange={(e) => this.statusHandler(e)}
                              options={mockData['statusUser'][localStorage.getItem('i18nextLng')]}
                              placeholder={t('advancedFilters.selectStatus')}
                          />
                        </div> */}
                        </Row>
                        <Row>
                        <div className="divPadding">
                          <h6><b>{t('advancedFilters.enfDatelabel')}</b><i> ( MMM YYYY ) </i></h6>
                          <DatePicker
                            id="enfFromDate"
                            placeholderText={t('advancedFilters.selectFromDate')}
                            dateFormat="MMM yyyy"
                            showMonthYearPicker
                            locale={locale[mockData['countryLocale'][localStorage.getItem('i18nextLng')]]}
                            isClearable
                            selected={this.state.fromDateEnf}
                            onChange={this.handleFromDateEnf}
                            className="form-control" />

                            <DatePicker
                              id="enfToDate"
                              placeholderText={t('advancedFilters.selectToDate')}
                              dateFormat="MMM yyyy"
                              showMonthYearPicker
                              locale={locale[mockData['countryLocale'][localStorage.getItem('i18nextLng')]]}
                              isClearable
                              selected={this.state.toDateEnf}
                              onChange={this.handleToDateEnf}
                              className="form-control" />
                        </div>

                        <div  className="divPadding">
                          <h6><b>{t('advancedFilters.expDatelabel')}</b><i> ( MMM YYYY ) </i></h6>
                          <DatePicker
                            id="expFromDate"
                            placeholderText={t('advancedFilters.selectFromDate')}
                            dateFormat="MMM yyyy"
                            showMonthYearPicker
                            locale={locale[mockData['countryLocale'][localStorage.getItem('i18nextLng')]]}
                            isClearable
                            selected={this.state.fromDateExp}
                            onChange={this.handleFromDateExp}
                            className="form-control" />

                          <DatePicker
                            id="expToDate"
                            placeholderText={t('advancedFilters.selectToDate')}
                            dateFormat="MMM yyyy"
                            showMonthYearPicker
                            locale={locale[mockData['countryLocale'][localStorage.getItem('i18nextLng')]]}
                            isClearable
                            selected={this.state.toDateExp}
                            onChange={this.handleToDateExp}
                            className="form-control" />
                        </div>
                        </Row>
                              <Row className="filterBtnRow">
                                <div id="accordionTwo">
                                    <div id="headingTwo">
                                      <Button id="applyFilter" className="btn btn-success" onClick={this.applyFilter} disabled={this.state.applyBtnDisable}>
                                        {this.state.applyBtnDisable ? t('fetchingData') : t('advancedFilters.applyFilter')}
                                      </Button>
                                      <a data-tip={t('advancedFilters.saveFilterTooltip')} data-for="saveFilter">
                                       { (this.props.saveFlag ||this.state.saveFlag) &&
                                      <div>
                                         <Button id="saveFilter" className="btn btn-success Filter" onClick={this.handleSaveFilter}>
                                           {t('advancedFilters.saveFilter')}
                                         </Button>
                                         </div>}
                                      </a>
                                      <ReactTooltip id="saveFilter" place="bottom" type="info" effect="float"/>
                                    </div>
                                    {this.state.inputFlag &&
                                      <div>
                                        <form className="form-inline" onSubmit={this.saveFilter}>
                                        <input type="text" title={[t('favorites.filterBlankMsg'),t('favorites.noSplChar')].join('')} value={this.state.filterName} className="form-control" onChange={this.handleFilterName.bind(this)} placeholder={t('advancedFilters.saveFilterInputPlaceholder')} required/>
                                        <Button type="submit" id="saveFilterHandler" className="btn btn-success">
                                          {t('save')}
                                        </Button>
                                       </form>
                                      </div>}
                                      { this.state.shareBtnFlag &&
                                       <a data-tip={t('advancedFilters.shareTooltip')} data-for="saveFilter">
                                          <Button id="shareFilterBtn" className="btn btn-icon btn-success " color="primary"  onClick={this.openModel}>
                                          {t('advancedFilters.shareBtn')}
                                        <i className="pe-7s-share shareIcon  btn-icon-wrapper"></i>
                                    </Button>
                                     </a>
                                      }

                               </div>
                               </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </Fragment>)}</Translation>
        )
    }
}

export const saveFilterApi = (type, id, filterName, filterUrl, props) =>{

    if(type === 'add'){
      if (props.favorites && props.favorites.length < 20) {
            let obj = {
              name: filterName.trim(),
              url: filterUrl
            }
         props.saveSearchFilter(obj);
        }
        else{
           toast(t('favorites.maxFilterSaveLmt'), { position: 'top-right', type: 'error'});
         }
    }
    else{
      let obj= {
        id: id,
        name: filterName.trim(),
        url: filterUrl
      }
      props.editSaveFilter(obj);
    }
}

    const mapStateToProps = (appState: AppState) => ({
      MarketList: appState.user.MarketList,
      TopicList: appState.user.TopicList,
      VehicleList: appState.user.VehicleList,
      SearchTagList: appState.user.SearchTagList,
      DriveTypeList: appState.user.DriveTypeList,
      GroupList: appState.user.GroupList,
      VehicleFuelTypeList: appState.user.VehicleFuelTypeList,
      favorites : appState.user.favorites,
      loading: appState.loading.loading,
      success: appState.user.saveFilterRequest.$success,
      errorMessage: appState.user.saveFilterRequest.$errorMessage,
      errorMessageUpdate: appState.user.UpdateFavoritesListRequest.$errorMessage,
      RegulationDataList: appState.user.RegulationDataList,
      filterSaved: appState.user.filterSaved,
      activeKey : appState.user.activeKey,
      regulationFetched: appState.user.regulationFetched,
      filterUpdated: appState.user.filterUpdated,
      favoritesShare: appState.user.favoritesShare,
      errorMessageScope: appState.user.ScopeListRequest.$errorMessage,
      ScopeList: appState.user.ScopeList
    });

    const mapDispatchToProps = dispatch => ({
      getRegulationDetails: (filter) => Actions.user.getRegulationDetails(filter, dispatch),
      saveSearchFilter: (filter) => Actions.user.saveSearchFilter(filter, dispatch),
      editSaveFilter: (filter) => Actions.user.editSaveFilter(dispatch , filter),
      getSearchTagList : () => Actions.user.getSearchTagList(dispatch),
      getTopicList: () => Actions.user.getTopicList(dispatch),
      getVehicleList: () => Actions.user.getVehicleList(dispatch),
      getMarketList: () => Actions.user.getMarketList(dispatch),
      getFavoritesList: (user) => Actions.user.getFavoritesList(dispatch,user),
      getGroupList: () => Actions.user.getGroupList(dispatch),
      getVehicleFuelTypeList: () => Actions.user.getVehicleFuelTypeList(dispatch),
      getDriveTypeList: () => Actions.user.getDriveTypeList(dispatch),
      getScopeList: () => Actions.user.getScopeList(dispatch),
      editUserList: (user) => Actions.user.editUserList(dispatch, user),
    });

    export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ToggleFilter));
