
let _t;
const setTranslation = (t) => {
    _t = t;
};
const t = (key) => {
    return _t(key)
};

// add other navigation functions that you need and export themexport default {  navigate,  setTopLevelNavigator,};
export default {
    setTranslation,
    t,
}