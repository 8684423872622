import React, {Component,Fragment} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PrintIcon from './../../printer.png';
import TimelineHeader from './../ReportComp/TimelineHeader';
interface Props extends Translate{
  closeModal: () => {};
  modalBody?: any;
  modalTitle: string;
  modalID: string;
  printTimeline: () => {};
  timelineHeaderData?: any;
}

interface State{
  modal: boolean
}

class EditModalComp extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            modal: true
        };
    }

    render() {
        return (
            <Translation>{t => (
                <Modal isOpen={this.state.modal} centered={true} toggle={this.props.closeModal} id={this.props.modalID} scrollable={true}>
                    <ModalHeader toggle={this.props.closeModal}>{this.props.modalTitle}</ModalHeader>

                    { this.props.modalID === 'reportsFormModal' &&
                      <TimelineHeader printTimeline={this.props.printTimeline} timelineHeaderData={this.props.timelineHeaderData}/>
                    }
                    <ModalBody>
                      {this.props.modalBody}
                    </ModalBody>
                </Modal>)}</Translation>
        );
    }
}

const mapStateToProps = state => ({
 });

const mapDispatchToProps = dispatch => ({
 });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditModalComp));
