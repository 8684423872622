import request from './request';
import * as Constants from '../../utils/Constants';

function getNotificationList() {
  let userID = JSON.parse(localStorage.getItem('user')).id;
    return request({
        url: Constants.NOTIFICATION_LIST+'/'+userID,
        method: 'GET',
    }, false)
};

function deleteNotification(notificationId) {
    return request({
        url: Constants.DELETE_NOTIFICATION+'/'+notificationId,
        method: 'DELETE',
    }, false)
};

function deleteAllNotification() {
  let userID = JSON.parse(localStorage.getItem('user')).id;
    return request({
        url: Constants.DELETE_ALL_NOTIFICATION+'/'+userID,
        method: 'DELETE',
    }, false)
};

function markAsReadNotification(notificationId) {
    return request({
        url: Constants.MARK_AS_READ+'/'+notificationId,
        method: 'POST',
    }, false)
};

function markAsReadAllNotification() {
  let userID = JSON.parse(localStorage.getItem('user')).id;
    return request({
        url: Constants.MARK_AS_READ_ALL+'/'+userID,
        method: 'POST',
    }, false)
};

const CommonService = {
  getNotificationList,
  deleteNotification,
  deleteAllNotification,
  markAsReadNotification,
  markAsReadAllNotification
};

export default CommonService;
