import { ModuleEvents as ModuleEventsLoading } from '../loading/Actions';
import { ModuleEvents as ModuleEventsCommon } from '../common/Actions';
import AdminService from '../../internal/common/AdminService';


export const ModuleEvents = {

  USERS: 'USERS',
  ERROR_USERS: 'ERROR_USERS',
  RESET_USER_STATUS: 'RESET_USER_STATUS',

  VEHICLE_LIST: 'VEHICLE_LIST',
  ERROR_VEHICLE_LIST: 'ERROR_VEHICLE_LIST',
  RESET_VEHICLE_STATUS: 'RESET_VEHICLE_STATUS',

  MARKET_LIST:'MARKET_LIST',
  ERROR_MARKET_LIST: 'ERROR_MARKET_LIST',
  RESET_MARKET_STATUS: 'RESET_MARKET_STATUS',

  LANGUAGE_LIST: 'LANGUAGE_LIST',
  ERROR_LANGUAGE_LIST: 'ERROR_LANGUAGE_LIST',
  RESET_LANGUAGE_STATUS: 'RESET_LANGUAGE_STATUS',

  UPDATE_USERS: 'UPDATE_USERS',
  ERROR_UPDATE_USERS: 'ERROR_UPDATE_USERS',

  CREATE_USERS: 'CREATE_USERS',
  ERROR_CREATE_USERS: 'ERROR_CREATE_USERS',

  DELETE_USERS: 'DELETE_USERS',
  ERROR_DELETE_USERS: 'ERROR_DELETE_USERS',

  UPDATE_VEHICLES: 'UPDATE_VEHICLES',
  ERROR_UPDATE_VEHICLES: 'ERROR_UPDATE_VEHICLES',

  CREATE_VEHICLES: 'CREATE_VEHICLES',
  ERROR_CREATE_VEHICLES: 'ERROR_CREATE_VEHICLES',

  DELETE_VEHICLES: 'DELETE_VEHICLES',
  ERROR_DELETE_VEHICLES: 'ERROR_DELETE_VEHICLES',

  CREATE_MARKET: 'CREATE_MARKET',
  ERROR_CREATE_MARKET: 'ERROR_CREATE_MARKET',

  DELETE_MARKET: 'DELETE_MARKET',
  ERROR_DELETE_MARKET: 'ERROR_DELETE_MARKET',

  CREATE_LANGUAGE: 'CREATE_LANGUAGE',
  ERROR_CREATE_LANGUAGE: 'ERROR_CREATE_LANGUAGE',

  DELETE_LANGUAGE: 'DELETE_LANGUAGE',
  ERROR_DELETE_LANGUAGE: 'ERROR_DELETE_LANGUAGE',

  CREATE_TOPIC: 'CREATE_TOPIC',
  ERROR_CREATE_TOPIC: 'ERROR_CREATE_TOPIC',
  RESET_TOPIC_STATUS: 'RESET_TOPIC_STATUS',

  UPDATE_TOPIC: 'UPDATE_TOPIC',
  ERROR_UPDATE_TOPIC: 'ERROR_UPDATE_TOPIC',

  DELETE_TOPIC: 'DELETE_TOPIC',
  ERROR_DELETE_TOPIC: 'ERROR_DELETE_TOPIC',

  RESET_GRM_LIST_STATUS: 'RESET_GRM_LIST_STATUS',

  CREATE_GRM: 'CREATE_GRM',
  ERROR_CREATE_GRM: 'ERROR_CREATE_GRM',

  DELETE_GRM: 'DELETE_GRM',
  ERROR_DELETE_GRM: 'ERROR_DELETE_GRM',

  GRM_LIST: 'GRM_LIST',
  ERROR_GRM_LIST: 'ERROR_GRM_LIST',

  TOPIC_LIST: 'TOPIC_LIST',
  ERROR_TOPIC_LIST: 'ERROR_TOPIC_LIST',

  RESEND_EMAIL: 'RESEND_EMAIL',
  ERROR_RESEND_EMAIL: 'ERROR_RESEND_EMAIL',
  RESET_RESEND_EMAIL: 'RESET_RESEND_EMAIL',

  VERIFY_SESSION: 'VERIFY_SESSION',

  REGULATION_NUMBER_LIST: 'REGULATION_NUMBER_LIST',
  ERROR_REGULATION_NUMBER_LIST: 'ERROR_REGULATION_NUMBER_LIST',
  RESET_REGULATION_NUMBER_LIST: 'RESET_REGULATION_NUMBER_LIST',

  DRIVE_TYPE_LIST: 'DRIVE_TYPE_LIST',
  ERROR_DRIVE_TYPE_LIST: 'ERROR_DRIVE_TYPE_LIST',
  RESET_DRIVE_TYPE_STATUS: 'RESET_DRIVE_TYPE_STATUS',

  DELETE_DRIVE_TYPE: 'DELETE_DRIVE_TYPE',
  ERROR_DELETE_DRIVE_TYPE: 'ERROR_DELETE_DRIVE_TYPE',

  CREATE_DRIVE_TYPE: 'CREATE_DRIVE_TYPE',
  ERROR_CREATE_DRIVE_TYPE: 'ERROR_CREATE_DRIVE_TYPE',

  UPDATE_DRIVE_TYPE: 'UPDATE_DRIVE_TYPE',
  ERROR_UPDATE_DRIVE_TYPE: 'ERROR_UPDATE_DRIVE_TYPE',

  GROUP_LIST: 'GROUP_LIST',
  ERROR_GROUP_LIST: 'ERROR_GROUP_LIST',
  CREATE_GROUP: 'CREATE_GROUP',
  ERROR_CREATE_GROUP: 'ERROR_CREATE_GROUP',
  UPDATE_GROUP: 'UPDATE_GROUP',
  ERROR_UPDATE_GROUP: 'ERROR_UPDATE_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  ERROR_DELETE_GROUP: 'ERROR_DELETE_GROUP',
  RESET_GROUP_STATUS: 'RESET_GROUP_STATUS',

  GROUP_SUBGROUP_RELATION_LIST: 'GROUP_SUBGROUP_RELATION_LIST',
  ERROR_GROUP_SUBGROUP_RELATION_LIST: 'ERROR_GROUP_SUBGROUP_RELATION_LIST',
  RESET_GROUP_SUBGROUP_RELATION_LIST: 'RESET_GROUP_SUBGROUP_RELATION_LIST',

  FUEL_TYPE_LIST: 'FUEL_TYPE_LIST',
  ERROR_FUEL_TYPE_LIST: 'ERROR_FUEL_TYPE_LIST',
  CREATE_FUEL_TYPE: 'CREATE_FUEL_TYPE',
  ERROR_CREATE_FUEL_TYPE: 'ERROR_CREATE_FUEL_TYPE',
  UPDATE_FUEL_TYPE: 'UPDATE_FUEL_TYPE',
  ERROR_UPDATE_FUEL_TYPE: 'ERROR_UPDATE_FUEL_TYPE',
  DELETE_FUEL_TYPE: 'DELETE_FUEL_TYPE',
  ERROR_DELETE_FUEL_TYPE: 'ERROR_DELETE_FUEL_TYPE',
  RESET_FUEL_TYPE_STATUS: 'RESET_FUEL_TYPE_STATUS',

  SCOPE_LIST: 'SCOPE_LIST',
  ERROR_SCOPE_LIST: 'ERROR_SCOPE_LIST',
  CREATE_SCOPE: 'CREATE_SCOPE',
  ERROR_CREATE_SCOPE: 'ERROR_CREATE_SCOPE',
  UPDATE_SCOPE: 'UPDATE_SCOPE',
  ERROR_UPDATE_SCOPE: 'ERROR_UPDATE_SCOPE',
  DELETE_SCOPE: 'DELETE_SCOPE',
  ERROR_DELETE_SCOPE: 'ERROR_DELETE_SCOPE',
  RESET_SCOPE_STATUS: 'RESET_SCOPE_STATUS',

  SCOPE_SUBSCOPE_RELATION_LIST: 'SCOPE_SUBSCOPE_RELATION_LIST',
  ERROR_SCOPE_SUBSCOPE_RELATION_LIST: 'ERROR_SCOPE_SUBSCOPE_RELATION_LIST',
  RESET_SCOPE_SUBSCOPE_RELATION_LIST: 'RESET_SCOPE_SUBSCOPE_RELATION_LIST',

  UPLOAD_FILES: 'UPLOAD_FILES',
  ERROR_UPLOAD_FILES: 'ERROR_UPLOAD_FILES',
  RESET_UPLOAD_FILES_STATUS: 'RESET_UPLOAD_FILES_STATUS',

  EDIT_FILES: 'EDIT_FILES',
  ERROR_EDIT_FILES: 'ERROR_EDIT_FILES',
  RESET_EDIT_FILES_STATUS: 'RESET_EDIT_FILES_STATUS',

  DELETE_FILES_ADMIN: 'DELETE_FILES_ADMIN',
  ERROR_DELETE_FILES_ADMIN: 'ERROR_DELETE_FILES_ADMIN',
  RESET_DELETE_FILES_ADMIN_STATUS: 'RESET_DELETE_FILES_ADMIN_STATUS',

  MARKET_PROFILE_VALUES: 'MARKET_PROFILE_VALUES',
  ERROR_MARKET_PROFILE_VALUES: 'ERROR_MARKET_PROFILE_VALUES',
  CREATE_MARKET_VALUE: 'CREATE_MARKET_VALUE',
  ERROR_CREATE_MARKET_VALUE: 'ERROR_CREATE_MARKET_VALUE',
  UPLOAD_MARKET_VALUE : 'UPLOAD_MARKET_VALUE',
  ERROR_UPLOAD_MARKET_VALUE : 'ERROR_UPLOAD_MARKET_VALUE',
  DELETE_MARKET_VALUE: 'DELETE_MARKET_VALUE',
  ERROR_DELETE_MARKET_VALUE: 'ERROR_DELETE_MARKET_VALUE',
  RESET_MARKET_PROFILE_VALUES: 'RESET_MARKET_PROFILE_VALUES',

  ROAD_CONDITION : 'ROAD_CONDITION',
  ERROR_ROAD_CONDITION : 'ERROR_ROAD_CONDITION',

  AIR_CONDITION : 'AIR_CONDITION',
  ERROR_AIR_CONDITION : 'ERROR_AIR_CONDITION', 

  AVG_TEMP : 'AVG_TEMP',
  ERROR_AVG_TEMP : 'ERROR_AVG_TEMP',

  CERTIFICATION : 'CERTIFICATION',
  ERROR_CERTIFICATION : 'ERROR_CERTIFICATION',

  ENV_CONDITION : 'ENV_CONDITION',
  ERROR_ENV_CONDITION : 'ERROR_ENV_CONDITION',

  HAND_DRIVE : 'HAND_DRIVE',
  ERROR_HAND_DRIVE : 'ERROR_HAND_DRIVE',

  HUMIDITY : 'HUMIDITY',
  ERROR_HUMIDITY : 'ERROR_HUMIDITY',

  CREATE_MARKET_PROFILE : 'CREATE_MARKET_PROFILE',
  ERROR_CREATE_MARKET_PROFILE : 'ERROR_CREATE_MARKET_PROFILE',
  RESET_MARKET_PROFILE_STATUS : 'RESET_MARKET_PROFILE_STATUS',

  GET_MARKET_PROFILE : 'GET_MARKET_PROFILE',
  ERROR_GET_MARKET_PROFILE : 'ERROR_GET_MARKET_PROFILE',

  UPLOAD_MARKET_PROFILE_FILES : 'UPLOAD_MARKET_PROFILE_FILES',
  ERROR_UPLOAD_MARKET_PROFILE_FILES : 'ERROR_UPLOAD_MARKET_PROFILE_FILES',
  RESET_UPLOAD_MARKET_PROFILE_FILES_STATUS : 'RESET_UPLOAD_MARKET_PROFILE_FILES_STATUS',

  EDIT_MARKET_PROFILE_FILES : 'EDIT_MARKET_PROFILE_FILES',
  ERROR_EDIT_MARKET_PROFILE_FILES : 'ERROR_EDIT_MARKET_PROFILE_FILES',
  RESET_EDIT_MARKET_PROFILE_FILES_STATUS : 'RESET_EDIT_MARKET_PROFILE_FILES_STATUS',

  DELETE_MARKET_PROFILE_ADMIN : 'DELETE_MARKET_PROFILE_ADMIN',
  ERROR_DELETE_MARKET_PROFILE_ADMIN : 'ERROR_DELETE_MARKET_PROFILE_ADMIN',
  RESET_DELETE_MARKET_PROFILE_ADMIN : 'RESET_DELETE_MARKET_PROFILE_ADMIN',

  EDIT_MARKET_PROFILE : 'EDIT_MARKET_PROFILE',
  ERROR_EDIT_MARKET_PROFILE : 'ERROR_EDIT_MARKET_PROFILE',

  DELETE_MARKET_PROFILE : 'DELETE_MARKET_PROFILE',
  ERROR_DELETE_MARKET_PROFILE : 'ERROR_DELETE_MARKET_PROFILE',
  RESET_DELETE_MARKET_PROFILE : 'RESET_DELETE_MARKET_PROFILE',
  
  GET_FAQ:'GET_FAQ',
  ERROR_GET_FAQ:'ERROR_GET_FAQ',
  UPDATE_FAQ:'UPDATE_FAQ',
  ERROR_UPDATE_FAQ:'ERROR_UPDATE_FAQ',
  ADD_FAQ:'ADD_FAQ',
  ERROR_ADD_FAQ:'ERROR_ADD_FAQ',
  DELETE_FAQ:'DELETE_FAQ',
  ERROR_DELETE_FAQ:'ERROR_DELETE_FAQ',
  RESET_FAQ_STATUS: 'RESET_GET_FAQ',

  ADD_REV_HISTORY : 'ADD_REV_HISTORY',
  ERROR_ADD_REV_HISTORY : 'ERROR_ADD_REV_HISTORY',
  RESET_ADD_REV_HISTORY_STATUS : 'RESET_ADD_REV_HISTORY_STATUS',

  DELETE_SERIES:'DELETE_SERIES',
  ERROR_DELETE_SERIES:'ERROR_DELETE_SERIES',
  RESET_DELETE_SERIES_STATUS:'RESET_DELETE_SERIES_STATUS',
  ADD_DEL_SERIES_ID:'ADD_DEL_SERIES_ID',
  REM_DEL_SERIES_ID:'REM_DEL_SERIES_ID',
  RESET_DEL_SERIES_ID:'RESET_DEL_SERIES_ID',

  REGULATION_LIST:'REGULATION_LIST',
  ERROR_REGULATION_LIST:'ERROR_REGULATION_LIST',

  REVISION_HISTORY_LIST: 'REVISION_HISTORY_LIST',
  ERROR_REVISION_HISTORY_LIST: 'ERROR_REVISION_HISTORY_LIST'

};

export default {

  //Fetching users list shown in "Manage users" tab.
  getUserList: dispatch => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.getUserList().then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.USERS, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_USERS, payload: error})
      })
  },

  //Fetching vehicles list shown in "Manage vehicle categories" tab.
  getVehicleList: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    return AdminService.getVehicleList().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.VEHICLE_LIST, payload: response.data})
      }, error => {
    dispatch({type: ModuleEventsLoading.FINISHLOADING});
    dispatch({type: ModuleEvents.ERROR_VEHICLE_LIST, payload: error})
    })
  },

  getLanguageList: (dispatch) => {
      dispatch({type: ModuleEventsLoading.LOADING});
      AdminService.getLanguageList().then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.LANGUAGE_LIST, payload: response.data})
    }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_LANGUAGE_LIST, payload: error})
    })
  },

  //Fetching markets list shown in "Manage markets" tab.
  getMarketList: (dispatch) => {
      dispatch({type: ModuleEventsLoading.LOADING});
      AdminService.getMarketList().then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.MARKET_LIST, payload: response.data})
    }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_MARKET_LIST, payload: error})
    })
  },

  //Editing user details api
  editUserList: (dispatch, user) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.editUserList(user).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.UPDATE_USERS, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_UPDATE_USERS, payload: error})
      })
  },

  //Create new user api
  createUser: (dispatch, user) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.createUser(user).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.CREATE_USERS, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_CREATE_USERS, payload: error})
      })
  },

  //Delete existing user api
  deleteUser: (dispatch, user) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.deleteUser(user).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.DELETE_USERS, payload: user})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_DELETE_USERS, payload: error})
      })
  },

  //Edit vehicle categories api
  editVehicleList: (dispatch, vehicle) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.editVehicleList(vehicle).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.UPDATE_VEHICLES, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_UPDATE_VEHICLES, payload: error})
      })
  },

  //Create new vehicle category api
  createVehicle: (dispatch, vehicle) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.createVehicle(vehicle).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.CREATE_VEHICLES, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_CREATE_VEHICLES, payload: error})
      })
  },

  //Delete existing vehicle category api
  deleteVehicle: (dispatch, vehicle) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.deleteVehicle(vehicle).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.DELETE_VEHICLES, payload: vehicle})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_DELETE_VEHICLES, payload: error})
      })
  },

  //Create new market api
  createMarket: (dispatch, market) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.createMarket(market).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.CREATE_MARKET, payload: market})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_CREATE_MARKET, payload: error})
      })
  },

  //Delete existing market api
  deleteMarket: (dispatch, market) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.deleteMarket(market).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.DELETE_MARKET, payload: market})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_DELETE_MARKET, payload: error})
      })
  },

  createLanguage: (dispatch, language) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.createLanguage(language).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.CREATE_LANGUAGE, payload: language})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_CREATE_LANGUAGE, payload: error})
      })
  },

  deleteLanguage: (dispatch, language) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.deleteLanguage(language).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.DELETE_LANGUAGE, payload: language})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_DELETE_LANGUAGE, payload: error})
      })
  },

  //Create new topic api
  createTopic: (dispatch, topic) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.createTopic(topic).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.CREATE_TOPIC, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_CREATE_TOPIC, payload: error})
      })
  },

  //Edit topic api
  editTopic: (dispatch, topic) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.editTopic(topic).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.UPDATE_TOPIC, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_UPDATE_TOPIC, payload: error})
      })
  },

  //Delete existing topic api
  deleteTopic: (dispatch, topic) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.deleteTopic(topic).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.DELETE_TOPIC, payload: topic})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_DELETE_TOPIC, payload: error})
      })
  },

  //Add regulations for topic api
  addGRM: (dispatch, grm, isFileAdded) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.addGRM(grm).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.CREATE_GRM, payload: {regulation: grm, filesAdded: isFileAdded, regID:response.data}})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_CREATE_GRM, payload: error})
      })
  },

  //Delete regulations for topic api
  deleteGRM: (dispatch, grm) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.deleteGRM(grm).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.DELETE_GRM, payload: grm})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_DELETE_GRM, payload: error})
      })
  },

  //Fetching topics list shown in "Manage topics" tab.
  getTopicList: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getTopicList().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.TOPIC_LIST, payload: response.data})
  }, error => {
    dispatch({type: ModuleEventsLoading.FINISHLOADING});
    dispatch({type: ModuleEvents.ERROR_TOPIC_LIST, payload: error})
  })
  },

  //Fetching regulations of the topic by topic ID
  getGRMList: (dispatch, id) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getGRM(id).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GRM_LIST, payload: response.data})
  }, error => {
    dispatch({type: ModuleEventsLoading.FINISHLOADING});
    dispatch({type: ModuleEvents.ERROR_GRM_LIST, payload: error})
  })
  },

  //Send user a link to verify email api
  resendVerificationLink: (dispatch, email) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.resendVerificationLink(email).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.RESEND_EMAIL, payload: email})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_RESEND_EMAIL, payload: error})
      })
  },

  //Fetching all the regulation numbers list
  getRegulationNumberList: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    return AdminService.getRegulationNumberList().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.REGULATION_NUMBER_LIST, payload: response.data})
  }, error => {
    dispatch({type: ModuleEventsLoading.FINISHLOADING});
    dispatch({type: ModuleEvents.ERROR_REGULATION_NUMBER_LIST, payload: error})
    })
  },

  //Fetching vehicle drive types list shown in "Manage drive types" tab
  getDriveTypeList: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    return AdminService.getDriveTypeList().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.DRIVE_TYPE_LIST, payload: response.data})
      }, error => {
    dispatch({type: ModuleEventsLoading.FINISHLOADING});
    dispatch({type: ModuleEvents.ERROR_DRIVE_TYPE_LIST, payload: error})
    })
  },

  //Create new vehicle drive type api
  createDriveType: (dispatch, driveType) => {
    dispatch({type: ModuleEventsLoading.LOADING})
    return AdminService.createDriveType(driveType).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.CREATE_DRIVE_TYPE, payload: response.data})
    }, (error) => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_CREATE_DRIVE_TYPE, payload: error})
    })
  },

  //Edit vehicle drive type api
  editDriveTypeList: (dispatch, driveType) => {
    dispatch({type: ModuleEventsLoading.LOADING})
    return AdminService.editDriveTypeList(driveType).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.UPDATE_DRIVE_TYPE, payload: response.data})
    }, (error) => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_UPDATE_DRIVE_TYPE, payload: error})
    })
  },

  //Delete existing vehicle drive type api
  deleteDriveType: (dispatch, driveType) => {
    dispatch({type: ModuleEventsLoading.LOADING})
    return AdminService.deleteDriveType(driveType).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.DELETE_DRIVE_TYPE, payload: driveType})
    }, (error) => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_DELETE_DRIVE_TYPE, payload: error})
    })
  },

  //Fetching groups/sub-groups list shown in "Manage groups" tab
  getGroupList: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    return AdminService.getGroupList().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GROUP_LIST, payload: response.data})
      }, error => {
    dispatch({type: ModuleEventsLoading.FINISHLOADING});
    dispatch({type: ModuleEvents.ERROR_GROUP_LIST, payload: error})
    })
  },

  //Create group/sub-group api
  createGroup: (dispatch, group) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.createGroup(group).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.CREATE_GROUP, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_CREATE_GROUP, payload: error})
      })
  },

  //Edit group/sub-group api
  editGroup: (dispatch, group) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.editGroup(group).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.UPDATE_GROUP, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_UPDATE_GROUP, payload: error})
      })
  },

  //Delete existing group/sub-group api
  deleteGroup: (dispatch, id, obj) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.deleteGroup(id, obj).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.DELETE_GROUP, payload: id})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_DELETE_GROUP, payload: error})
      })
  },

  //Fetching group-subgroup relation mapping list
  getGroupSubgroupList: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getGroupSubgroupList().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GROUP_SUBGROUP_RELATION_LIST, payload: response.data})
      }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_GROUP_SUBGROUP_RELATION_LIST, payload: error})
    })
  },

  //Fetching vehicle fuel type list shown in "Manage fuel types" tab
  getFuelTypeList: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    return AdminService.getFuelTypeList().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.FUEL_TYPE_LIST, payload: response.data})
      }, error => {
    dispatch({type: ModuleEventsLoading.FINISHLOADING});
    dispatch({type: ModuleEvents.ERROR_FUEL_TYPE_LIST, payload: error})
    })
  },

  //Create new vehicle fuel type api
  createFuelType: (dispatch, fuelType) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.createFuelType(fuelType).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.CREATE_FUEL_TYPE, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_CREATE_FUEL_TYPE, payload: error})
      })
  },

  //Edit vehicle fuel type api
  editFuelType: (dispatch, fuelType) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.editFuelType(fuelType).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.UPDATE_FUEL_TYPE, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_UPDATE_FUEL_TYPE, payload: error})
      })
  },

  //Delete existing vehicle fuel type api
  deleteFuelType: (dispatch, fuelType) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.deleteFuelType(fuelType).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.DELETE_FUEL_TYPE, payload: fuelType})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_DELETE_FUEL_TYPE, payload: error})
      })
  },

  //Fetching scope/sub-scope list shown in "Manage scopes" tab
  getScopeList: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    return AdminService.getScopeList().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.SCOPE_LIST, payload: response.data})
      }, error => {
    dispatch({type: ModuleEventsLoading.FINISHLOADING});
    dispatch({type: ModuleEvents.ERROR_SCOPE_LIST, payload: error})
    })
  },

  //Create new scope/sub-scope api
  createScope: (dispatch, scope) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.createScope(scope).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.CREATE_SCOPE, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_CREATE_SCOPE, payload: error})
      })
  },

  //Edit scope/sub-scope api
  editScope: (dispatch, scope) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.editScope(scope).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.UPDATE_SCOPE, payload: response.data})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_UPDATE_SCOPE, payload: error})
      })
  },

  //Delete existing scope/sub-scope api
  deleteScope: (dispatch, id, obj) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.deleteScope(id, obj).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.DELETE_SCOPE, payload: id})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_DELETE_SCOPE, payload: error})
      })
  },

  //Fetching scope-subscope relation mapping list
  getScopeSubscopeList: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getScopeSubscopeList().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.SCOPE_SUBSCOPE_RELATION_LIST, payload: response.data})
      }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_SCOPE_SUBSCOPE_RELATION_LIST, payload: error})
    })
  },

  //Upload new documents for market in GRM template api
  uploadFiles: (dispatch, files, filename) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.uploadFiles(files).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.UPLOAD_FILES, payload: files})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_UPLOAD_FILES, payload: {error:error, files: filename}})
      })
  },

  //Edit existing documents for market in GRM template api
  editFiles: (dispatch, files, id, filename) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.editFiles(files, id).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.EDIT_FILES, payload: files})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_EDIT_FILES, payload: {error:error, files: filename}})
      })
  },

  //Delete existing documents api
  deleteFiles: (dispatch, id, filename) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.deleteFiles(id).then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.DELETE_FILES_ADMIN, payload: {}})
      }, (error) => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_DELETE_FILES_ADMIN, payload: {error:error, files: filename}})
      })
  },

  getMarketProfileValue: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getMarketProfileValue().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.MARKET_PROFILE_VALUES, payload: response.data})
      }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_MARKET_PROFILE_VALUES, payload: error})
    })
  },

  createMarketValue: (dispatch, data ,id) => {
    dispatch({type: ModuleEventsLoading.LOADING})
    return AdminService.createMarketValue(data,id).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.CREATE_MARKET_VALUE, payload: response.data})
    }, (error) => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_CREATE_MARKET_VALUE, payload: error})
    })
 },
 editMarketValue: (dispatch, marketValue, type) => {
    dispatch({type: ModuleEventsLoading.LOADING})
    return AdminService.editMarketValue(marketValue, type).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.UPLOAD_MARKET_VALUE, payload: {data: response.data, type: type}})
    }, (error) => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_UPLOAD_MARKET_VALUE, payload: error})
    })
 },
 deleteMarketValue: (dispatch, marketValueID, type) => {
    dispatch({type: ModuleEventsLoading.LOADING})
    return AdminService.deleteMarketValue(marketValueID, type).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.DELETE_MARKET_VALUE, payload:{data: marketValueID, type: type}})
    }, (error) => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_DELETE_MARKET_VALUE, payload: error})
    })
 },
 getRoadCondition: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getRoadCondition().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ROAD_CONDITION, payload: response.data})
      }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_ROAD_CONDITION, payload: error})
    })
  },
  getAirCondition: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getAirCondition().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.AIR_CONDITION, payload: response.data})
      }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_AIR_CONDITION, payload: error})
    })
  },
  getAvgTemp: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getAvgTemp().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.AVG_TEMP, payload: response.data})
      }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_AVG_TEMP, payload: error})
    })
  },
  getCertification: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getCertification().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.CERTIFICATION, payload: response.data})
      }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_CERTIFICATION, payload: error})
    })
  },
  getEnvCondition: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getEnvCondition().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ENV_CONDITION, payload: response.data})
      }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_ENV_CONDITION, payload: error})
    })
  },
  getHandDrive: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getHandDrive().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.HAND_DRIVE, payload: response.data})
      }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_HAND_DRIVE, payload: error})
    })
  },
  getHumidity: (dispatch) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getHumidity().then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.HUMIDITY, payload: response.data})
      }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_HUMIDITY, payload: error})
    })
  },

  createMarketProfile: (dispatch, marketProfile,isFileAdded) => {
    dispatch({type: ModuleEventsLoading.LOADING})
    return AdminService.createMarketProfile(marketProfile).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.CREATE_MARKET_PROFILE, payload: {data: response.data , filesAdded: isFileAdded, marketProfileID: response.id}})
    }, (error) => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_CREATE_MARKET_PROFILE, payload: error})
    })
  },

  getMarketProfile: (dispatch, marketId) => {
    dispatch({type: ModuleEventsLoading.LOADING});
    AdminService.getMarketProfile(marketId).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GET_MARKET_PROFILE, payload: { data:response.data, id: marketId}})
      }, error => {
      dispatch({type: ModuleEventsLoading.FINISHLOADING});
      dispatch({type: ModuleEvents.ERROR_GET_MARKET_PROFILE, payload: error})
    })
  },

  uploadMarketProfileFiles: (dispatch, files, filename) => {
    dispatch({type: ModuleEventsLoading.LOADING})
    return AdminService.uploadMarketProfileFiles(files).then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.UPLOAD_MARKET_PROFILE_FILES, payload: files})
    }, (error) => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_UPLOAD_MARKET_PROFILE_FILES, payload: {error:error, files: filename}})
    })
  },

  editMarketProfileFiles: (dispatch, files, id, filename) => {
        dispatch({type: ModuleEventsLoading.LOADING})
        return AdminService.editMarketProfileFiles(files, id).then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.EDIT_MARKET_PROFILE_FILES, payload: files})
        }, (error) => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_EDIT_MARKET_PROFILE_FILES, payload: {error:error, files: filename}})
        })
    },

    deleteMarketProfileFiles: (dispatch, id, filename) => {
        dispatch({type: ModuleEventsLoading.LOADING})
        return AdminService.deleteMarketProfileFiles(id).then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.DELETE_MARKET_PROFILE_ADMIN, payload: {}})
        }, (error) => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_DELETE_MARKET_PROFILE_ADMIN, payload: {error:error, files: filename}})
        })
    },
    editMarketProfile: (dispatch, marketProfile, isFileAdded) => {
        dispatch({type: ModuleEventsLoading.LOADING})
        return AdminService.editMarketProfile(marketProfile).then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.EDIT_MARKET_PROFILE, payload: {data: response.data , filesAdded: isFileAdded}})
        }, (error) => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_EDIT_MARKET_PROFILE, payload: error})
        })
    },

    deleteMarketProfile: (dispatch, marketId,marketProfileId) => {
        dispatch({type: ModuleEventsLoading.LOADING})
        return AdminService.deleteMarketProfile(marketId,marketProfileId).then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.DELETE_MARKET_PROFILE, payload: {marketId : marketId,marketProfileId :marketProfileId}})
        }, (error) => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.ERROR_DELETE_MARKET_PROFILE, payload: error})
        })
    },

    getFaqs :(dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        AdminService.getFaqs()
        .then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.GET_FAQ, payload: response.data})
        },error => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_GET_FAQ, payload: error})
        })
    },
    editFaq:(dispatch, faq) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.editFaq(faq)
      .then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.UPDATE_FAQ, payload: response.data})
      },error => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_UPDATE_FAQ, payload: error})
      })
    },
    deleteFaq:(dispatch, faqId) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.deleteFaq(faqId)
      .then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.DELETE_FAQ, payload: faqId})
      },error => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_DELETE_FAQ, payload: error})
      })
    },
    addFaq:(dispatch, faq) => {
      dispatch({type: ModuleEventsLoading.LOADING})
      return AdminService.addfaq(faq)
      .then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ADD_FAQ, payload: response.data})
      },error => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_ADD_FAQ, payload: error})
      })
    },
    addRevisionHistory:(dispatch, rev) => {
        dispatch({type: ModuleEventsLoading.LOADING})
        return AdminService.addRevisionHistory(rev)
        .then(response => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ADD_REV_HISTORY, payload: response.data})
        },error => {
          dispatch({type: ModuleEventsLoading.FINISHLOADING});
          dispatch({type: ModuleEvents.ERROR_ADD_REV_HISTORY, payload: error})
        })
      },
    deleteSeries:(dispatch,serData) => {
     dispatch({type: ModuleEventsLoading.LOADING})
     return AdminService.deleteSeries(serData)
     .then(response => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.DELETE_SERIES, payload: response.data})
     },
     error => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_DELETE_SERIES, payload: error})
     })
    },
    getRegulationList: (dispatch) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        return AdminService.getRegulationList().then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.REGULATION_LIST, payload: response.data})
          }, error => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_REGULATION_LIST, payload: error})
        })
    },
    getRevisionHistoryData: (dispatch,regulationNumber) => {
        dispatch({type: ModuleEventsLoading.LOADING});
        return AdminService.getRevisionHistoryData(regulationNumber).then(response => {
            dispatch({type: ModuleEventsLoading.FINISHLOADING});
            dispatch({type: ModuleEvents.REVISION_HISTORY_LIST, payload: response.data})
          }, error => {
        dispatch({type: ModuleEventsLoading.FINISHLOADING});
        dispatch({type: ModuleEvents.ERROR_REVISION_HISTORY_LIST, payload: error})
        })
    },
    
};
