import { ApiResponseStatus } from '../../model/ApiResponseStatus';
import i18next from 'i18next';
import {ModuleEvents} from './Actions';
import { Action } from '../../model/Action';
import RegulationList from '../../model/RegulationList';
import UserList from '../../model/UserList';
import VehicleList from '../../model/VehicleList';
import MarketList from '../../model/MarketList';
import LanguageList from '../../model/LanguageList';
import TopicList from '../../model/TopicList';
import GRMList from '../../model/GRMList';
import DriveTypeList from '../../model/DriveTypeList';
import GroupList from '../../model/GroupList';
import FuelTypeList from '../../model/FuelTypeList';
import ScopeList from '../../model/ScopeList';
import MarketValueList from '../../model/MarketValueList';
import RoadConditionList from '../../model/RoadConditionList';
import AvgTempList from '../../model/AvgTempList';
import HandDriveList from '../../model/HandDriveList';
import HumidityList from '../../model/HumidityList';
import CertificationList from '../../model/CertificationList';
import EnvConditionList from '../../model/EnvConditionList';
import AirConditionList from '../../model/AirConditionList';
import MarketProfileList from '../../model/MarketProfileList';
import FaqList from '../../model/FaqList';
import RevisionList from '../../model/RevisionList';

export interface InitialAdminState {
  UserList?: UserList[],
  UserListRequest?: ApiResponseStatus,
  VehicleList?: VehicleList[],
  VehicleListRequest?: ApiResponseStatus,
  MarketList?: MarketList[],
  MarketListRequest?: ApiResponseStatus,
  LanguageList?: LanguageList[],
  LanguageListRequest?: ApiResponseStatus,
  TopicList?: TopicList[],
  TopicListRequest?: ApiResponseStatus,
  groupAdded: boolean,
  groupUpdated: boolean,
  groupDeleted: boolean,
  GroupList?: GroupList[],
  GroupListRequest?: ApiResponseStatus,
  ScopeList?: ScopeList[],
  ScopeListRequest?: ApiResponseStatus,
  scopeAdded: boolean,
  scopeUpdated: boolean,
  scopeDeleted: boolean,
  FuelTypeList?: FuelTypeList[],
  fuelTypeAdded: boolean,
  fuelTypeUpdated: boolean,
  fuelTypeDeleted: boolean,
  FuelTypeListRequest?: ApiResponseStatus,
  GroupSubgroupRelaList?: any,
  GroupSubgroupRelaListRequest?: ApiResponseStatus,
  ScopeSubscopeRelaList?: any,
  ScopeSubscopeRelaListRequest?: ApiResponseStatus,
  GRMList?: GRMList[],
  GRMListRequest?: ApiResponseStatus,
  resendEmailVerificationRequest?: ApiResponseStatus,
  RegulationNumberList?: any,
  RegulationNumberListRequest?: ApiResponseStatus,
  userAdded: boolean,
  userUpdated: boolean,
  userDeleted: boolean,
  vehicleAdded: boolean,
  vehicleUpdated: boolean,
  vehicleDeleted: boolean,
  marketAdded: boolean,
  marketDeleted: boolean,
  languageAdded: boolean,
  languageDeleted: boolean,
  topicAdded: boolean,
  topicUpdated: boolean,
  topicDeleted: boolean,
  grmAdded: boolean,
  grmDeleted: boolean,
  grmListFetched: boolean,
  resendMail: boolean,
  loginRandomNoAdmin: Number,
  userProfile?: any,
  DriveTypeList?: DriveTypeList[],
  DriveTypeListRequest?: ApiResponseStatus,
  driveTypeDeleted: boolean,
  driveTypeAdded: boolean,
  driveTypeUpdated: boolean,
  fileUploaded: boolean,
  regulationIdData?: any,
  DocUploadRequest?: ApiResponseStatus,
  DocEditRequest?: ApiResponseStatus,
  DocDeleteRequest?: ApiResponseStatus,
  MarketValueList ?: MarketValueList[],
  MarketValueRequest?: ApiResponseStatus,
  MarketValueFetched : boolean,
  marketValueAdded : boolean,
  marketValueUpdated : boolean,
  marketValueDeleted : boolean,
  RoadConditionList ?: RoadConditionList[],
  RoadConditionRequest ?: ApiResponseStatus,
  AvgTempList ?: AvgTempList[],
  AvgTempRequest ?: ApiResponseStatus,
  HandDriveList ?: HandDriveList[],
  HandDriveRequest ?: ApiResponseStatus,
  HumidityList ?: HumidityList[],
  HumidityRequest ?: ApiResponseStatus,
  CertificationList ?: CertificationList[],
  CertificationRequest ?: ApiResponseStatus,
  EnvConditionList ?: EnvConditionList[],
  EnvConditionRequest ?: ApiResponseStatus,
  AirConditionList ?: AirConditionList[],
  AirConditionRequest ?: ApiResponseStatus,
  MarketProfileList ?: MarketProfileList[],
  MarketProfileRequest ?: ApiResponseStatus,
  marketProfileFetched : boolean,
  marketProfileFileUploaded : boolean,
  MarketProfileDocUploadRequest : ApiResponseStatus,
  MarketProfileDocEditRequest : ApiResponseStatus,
  MarketProfileDocDeleteRequest : ApiResponseStatus,
  marketProfileAdded : boolean,
  marketProfileID?: any,
  marketProfileDeleted : boolean,
  FaqList?: FaqList[],
  FaqListRequest?: ApiResponseStatus,
  faqListUpdated : boolean,
  faqAdded: boolean,
  faqDeleted: boolean,
  addRevisionHistoryRequest: ApiResponseStatus,
  revSuccessMsg : boolean,
  delSerIds:[] | string[],
  seriesDeleted: boolean,
  seriesDeletedRequest: ApiResponseStatus,
  regId: string,
  RegulationListRequest : ApiResponseStatus,
  RegulationList ?: RegulationList[],
  RevisionListRequest : ApiResponseStatus,
  revisionList ?: RevisionList []
}

const initState: InitialAdminState = {
  UserListRequest: new ApiResponseStatus(false, null),
  VehicleListRequest: new ApiResponseStatus(false, null),
  MarketListRequest: new ApiResponseStatus(false, null),
  LanguageListRequest: new ApiResponseStatus(false, null),
  TopicListRequest: new ApiResponseStatus(false, null),
  GroupListRequest: new ApiResponseStatus(false, null),
  ScopeListRequest: new ApiResponseStatus(false, null),
  FuelTypeListRequest: new ApiResponseStatus(false, null),
  GroupSubgroupRelaListRequest: new ApiResponseStatus(false, null),
  ScopeSubscopeRelaListRequest: new ApiResponseStatus(false, null),
  GRMListRequest: new ApiResponseStatus(false, null),
  resendEmailVerificationRequest: new ApiResponseStatus(false,null),
  RegulationNumberListRequest: new ApiResponseStatus(false, null),
  DriveTypeListRequest: new ApiResponseStatus(false, null),
  DocUploadRequest: new ApiResponseStatus(false, null),
  DocEditRequest: new ApiResponseStatus(false, null),
  DocDeleteRequest: new ApiResponseStatus(false, null),
  MarketValueRequest: new ApiResponseStatus(false, null),
  RoadConditionRequest: new ApiResponseStatus(false, null),
  AvgTempRequest: new ApiResponseStatus(false, null),
  HandDriveRequest: new ApiResponseStatus(false, null),
  HumidityRequest: new ApiResponseStatus(false, null),
  CertificationRequest: new ApiResponseStatus(false, null),
  EnvConditionRequest: new ApiResponseStatus(false, null),
  AirConditionRequest: new ApiResponseStatus(false, null),
  MarketProfileRequest: new ApiResponseStatus(false, null),
  MarketProfileDocUploadRequest: new ApiResponseStatus(false, null),
  MarketProfileDocEditRequest: new ApiResponseStatus(false, null),
  MarketProfileDocDeleteRequest: new ApiResponseStatus(false, null),
  FaqListRequest: new ApiResponseStatus(false, null),
  addRevisionHistoryRequest: new ApiResponseStatus(false,null),
  seriesDeletedRequest: new ApiResponseStatus(false,null),
  RegulationListRequest: new ApiResponseStatus(false,null),
  RevisionListRequest: new ApiResponseStatus(false,null),
  userAdded: false,
  userUpdated: false,
  userDeleted: false,
  vehicleAdded: false,
  vehicleUpdated: false,
  vehicleDeleted: false,
  marketAdded: false,
  marketDeleted: false,
  languageAdded: false,
  languageDeleted: false,
  topicAdded: false,
  topicUpdated: false,
  topicDeleted: false,
  grmAdded: false,
  grmDeleted: false,
  grmListFetched: false,
  resendMail: false,
  loginRandomNoAdmin: Math.floor((Math.random() * 1000000) + 1),
  userProfile : null,
  driveTypeDeleted: false,
  driveTypeAdded: false,
  driveTypeUpdated: false,
  groupAdded: false,
  groupUpdated: false,
  groupDeleted: false,
  fuelTypeAdded: false,
  fuelTypeUpdated: false,
  fuelTypeDeleted: false,
  scopeAdded: false,
  scopeUpdated: false,
  scopeDeleted: false,
  fileUploaded: true,
  regulationIdData: null,
  MarketValueFetched: false,
  marketValueAdded : false,
  marketValueUpdated : false,
  marketValueDeleted : false,
  marketProfileFetched : false,
  marketProfileFileUploaded : true,
  marketProfileAdded : false,
  marketProfileID : null,
  marketProfileDeleted : false,
  faqListUpdated : false,
  faqAdded: false,
  faqDeleted: false,
  revSuccessMsg : false,
  delSerIds:[],
  regId: '',
  seriesDeleted: false,
}

export const Reducer = (state = initState, action: Action) => {
    const {payload, type} = action;
    switch (type) {
        case ModuleEvents.USERS: {
            const listArr = [...payload].filter(user => {
                if(user.id !== (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).id)) {
                  return user;
                }
            });
            return {
                ...state,
                UserList: listArr
            }
        }

        case ModuleEvents.ERROR_USERS:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                UserListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_USER_STATUS: {
            return {
                ...state,
                userAdded: false, userUpdated: false, userDeleted:false,
                UserListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.RESET_VEHICLE_STATUS: {
            return {
                ...state,
                vehicleAdded: false, vehicleUpdated: false, vehicleDeleted:false,
                VehicleListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.VEHICLE_LIST: {
            return {
                ...state,
                VehicleList: payload
            }
        }

        case ModuleEvents.ERROR_VEHICLE_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                VehicleListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.UPDATE_USERS: {
         if (payload.id === (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).id)) {
           localStorage.setItem('user',JSON.stringify(payload));
         }
         let listArr = [...state.UserList];
          listArr.map(item =>{
            if (item.id === payload.id) {
              item.firstName = payload.firstName;
              item.lastName = payload.lastName;
              item.email = payload.email;
              item.role = payload.role;
            }
          });

          return {
                ...state,
                UserList: listArr,
                userUpdated:true
            }
        }

        case ModuleEvents.ERROR_UPDATE_USERS:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryUser';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.updateUser';
                    break;
            }

            return {
                ...state,
                UserListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.CREATE_USERS: {
          let listArr = [...state.UserList, payload];
            return {
                ...state,
                UserList: listArr,
                userAdded: true
            }
        }

        case ModuleEvents.ERROR_CREATE_USERS:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryUser';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                UserListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.DELETE_USERS: {
            let listArr = [...state.UserList];
            const filterArr = listArr.filter(item => item.id !== payload);
            return {
                ...state,
                UserList: filterArr,
                userDeleted: true
            }
        }

        case ModuleEvents.ERROR_DELETE_USERS:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                UserListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.UPDATE_VEHICLES: {
          let listArr = [...state.VehicleList];
          listArr.map(item =>{
            if (item.id === payload.id) {
              item['data'] = payload.data;
            }
          });
            return {
                ...state,
                VehicleList: listArr,
                vehicleUpdated: true
            }
        }

        case ModuleEvents.ERROR_UPDATE_VEHICLES:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryVehicle';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.updateVehicle';
                    break;
            }

            return {
                ...state,
                VehicleListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.CREATE_VEHICLES: {
          let listArr = [...state.VehicleList, payload];
            return {
                ...state,
                VehicleList: listArr,
                vehicleAdded: true
            }
        }

        case ModuleEvents.ERROR_CREATE_VEHICLES:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryVehicle';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.createVehicle';
                    break;
            }

            return {
                ...state,
                VehicleListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.DELETE_VEHICLES: {
            let listArr = [...state.VehicleList];
            const filterArr = listArr.filter(item => item.id !== payload);
            return {
                ...state,
                VehicleList: filterArr,
                vehicleDeleted: true
            }
        }

        case ModuleEvents.ERROR_DELETE_VEHICLES:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.deleteVehicle';
                    break;
            }

            return {
                ...state,
                VehicleListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }
        case ModuleEvents.MARKET_LIST: {
            return {
                ...state,
                MarketList: payload
            }
        }

        case ModuleEvents.ERROR_MARKET_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                MarketListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.CREATE_MARKET: {
            let listArr = [...state.MarketList, payload];
              return {
                  ...state,
                  MarketList: listArr,
                  marketAdded: true
              }
          }

          case ModuleEvents.ERROR_CREATE_MARKET:{
              let errorCode;
              if (payload.status) {
                  errorCode = payload.status
              }
              let errorMessage = null;
              switch (errorCode) {
                  //handle status code and create error message accordingly
                  case 401:
                      localStorage.setItem('token', null);
                      localStorage.setItem('user', null);
                      errorMessage = 'error.unauthorized';
                      break;
                  case 200:
                      errorMessage = null;
                      break;
                  case 500:
                    errorMessage = 'error.internalError';
                        break;
                  case -1:
                      errorMessage = null;
                      break;
                  default:
                    errorMessage = 'error.addMarketError';
                      break;
              }

              return {
                  ...state,
                  MarketListRequest: new ApiResponseStatus(false, errorMessage)
              }
          }
          case ModuleEvents.RESET_MARKET_STATUS: {
            return {
                ...state,
                marketAdded: false, marketDeleted: false
            }
        }
        case ModuleEvents.DELETE_MARKET: {
            let listArr = [...state.MarketList];
            const filterArr = listArr.filter(item => item.id !== payload)
            return {
                ...state,
                MarketList: filterArr,
                marketDeleted: true
            }
        }

        case ModuleEvents.ERROR_DELETE_MARKET:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.deleteMarketError';
                    break;
            }

            return {
                ...state,
                MarketListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }
        case ModuleEvents.LANGUAGE_LIST: {
            return {
                ...state,
                LanguageList: payload
            }
        }

        case ModuleEvents.ERROR_LANGUAGE_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                LanguageListRequest: new ApiResponseStatus(false, errorMessage),
            }
        }
        case ModuleEvents.RESET_LANGUAGE_STATUS: {
            return {
                ...state,
                languageAdded: false, languageDeleted: false
            }
        }
        case ModuleEvents.CREATE_LANGUAGE: {
            let listArr = [...state.LanguageList, payload];
              return {
                  ...state,
                  languageArr: listArr,
                  languageAdded: true
              }
          }

          case ModuleEvents.ERROR_CREATE_LANGUAGE:{
              let errorCode;
              if (payload.status) {
                  errorCode = payload.status
              }
              let errorMessage = null;
              switch (errorCode) {
                  //handle status code and create error message accordingly
                  case 401:
                      localStorage.setItem('token', null);
                      localStorage.setItem('user', null);
                      errorMessage = 'error.unauthorized';
                      break;
                  case 200:
                      errorMessage = null;
                      break;
                  case 500:
                    errorMessage = 'error.internalError';
                      break;
                  case -1:
                      errorMessage = null;
                      break;
                  default:
                    errorMessage = 'error.addLanguageError';
                      break;
              }

              return {
                  ...state,

                  LanguageListRequest: new ApiResponseStatus(false, errorMessage),
              }
          }
          case ModuleEvents.DELETE_LANGUAGE: {
            let listArr = [...state.LanguageList];
            const filterArr = listArr.filter(item => item.id !== payload);
            return {
                ...state,
                LanguageList: filterArr,
                languageDeleted: true

            }
        }

        case ModuleEvents.ERROR_DELETE_LANGUAGE:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.deleteLanguageError';
                    break;
            }

            return {
                ...state,
                languageDeletedError : true,
                LanguageListRequest: new ApiResponseStatus(false, errorMessage),
            }
        }

        case ModuleEvents.RESET_TOPIC_STATUS: {
            return {
                ...state,
                topicAdded: false, topicUpdated: false, topicDeleted:false,
                TopicListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.CREATE_TOPIC: {
          let listArr = [...state.TopicList, payload];
            return {
                ...state,
                TopicList: listArr,
                topicAdded: true
            }
        }

        case ModuleEvents.ERROR_CREATE_TOPIC:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryTopic';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.createTopic';
                    break;
            }

            return {
                ...state,
                TopicListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.UPDATE_TOPIC: {
          let listArr = [...state.TopicList];
          listArr.map(item =>{
            if (item.id === payload.id) {
              item['data'] = payload.data;
            }
          });
            return {
                ...state,
                TopicList: listArr,
                topicUpdated: true
            }
        }

        case ModuleEvents.ERROR_UPDATE_TOPIC:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryTopic';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.updateTopic';
                    break;
            }

            return {
                ...state,
                TopicListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.DELETE_TOPIC: {
            let listArr = [...state.TopicList];
            const filterArr = listArr.filter(item => item.id !== payload);
            return {
                ...state,
                TopicList: filterArr,
                topicDeleted: true
            }
        }

        case ModuleEvents.ERROR_DELETE_TOPIC:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.deleteTopic';
                    break;
            }

            return {
                ...state,
                TopicListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.GRM_LIST: {
            return {
                ...state,
                GRMList: payload,
                grmListFetched: true
            }
        }

        case ModuleEvents.ERROR_GRM_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                GRMListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.CREATE_GRM: {
            let topicListArr = [...state.TopicList];
            topicListArr && topicListArr.map(item =>{
              if (payload.regulation && payload.regulation[0] && payload.regulation[0].topic && (item.id === payload.regulation[0].topic.id)) {
                item['data']['en']['regulationNumbers'] = '';
                item['data']['de']['regulationNumbers'] = '';
                payload.regulation && payload.regulation.map(elem =>{
                  if (elem['actualRegulationNumber'] && !elem.hasOwnProperty('removed')) {
                    let regNo = item['data'][elem.languageCode]['regulationNumbers'];
                    item['data'][elem.languageCode]['regulationNumbers'] = regNo ? regNo.concat(', ', elem['actualRegulationNumber']) : regNo.concat(elem['actualRegulationNumber']);
                  }
                });
              }
            });
            return {
                ...state,
                grmAdded: true,
                TopicList: topicListArr,
                regulationIdData: payload.regID,
                fileUploaded: payload.filesAdded
            }
        }

        case ModuleEvents.ERROR_CREATE_GRM:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryGRM';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.createGRM';
                    break;
            }

            return {
                ...state,
                GRMListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.DELETE_GRM: {
          let topicListArr = [...state.TopicList];
          topicListArr && topicListArr.map(item =>{
            if (payload === item.id) {
              item['data']['en']['regulationNumbers'] = '';
              item['data']['de']['regulationNumbers'] = '';
            }
          });
            return {
                ...state,
                grmDeleted: true,
                TopicList: topicListArr
            }
        }

        case ModuleEvents.ERROR_DELETE_GRM:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.deleteGRM';
                    break;
            }

            return {
                ...state,
                GRMListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_GRM_LIST_STATUS: {
            return {
                ...state,
                grmListFetched:false, grmAdded:false, grmDeleted:false,
                GRMListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.TOPIC_LIST: {
            return {
                ...state,
                TopicList: payload
            }
        }

        case ModuleEvents.ERROR_TOPIC_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                TopicListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESEND_EMAIL: {
            return {
                ...state,
                resendMail : true

            }
        }
        case ModuleEvents.ERROR_RESEND_EMAIL:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                resendEmailVerificationRequest: new ApiResponseStatus(false, errorMessage)
            }
        }
        case ModuleEvents.RESET_RESEND_EMAIL: {
            return {
                ...state,
                resendMail: false,
                resendEmailVerificationRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.VERIFY_SESSION: {
            return {
                ...state,
                loginRandomNoAdmin: Math.floor((Math.random() * 1000000) + 1)
            }
        }


        case ModuleEvents.REGULATION_NUMBER_LIST: {
            return {
                ...state,
                RegulationNumberList: payload
            }
        }

        case ModuleEvents.ERROR_REGULATION_NUMBER_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchRegNumberList';
                    break;
            }

            return {
                ...state,
                RegulationNumberListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_REGULATION_NUMBER_LIST: {
            return {
                ...state,
                RegulationNumberListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.GROUP_LIST: {
            return {
                ...state,
                GroupList: payload
            }
        }

        case ModuleEvents.ERROR_GROUP_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                GroupListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.CREATE_GROUP: {
          let listArr = [...state.GroupList, payload];
            return {
                ...state,
                GroupList: listArr,
                groupAdded: true
            }
        }

        case ModuleEvents.ERROR_CREATE_GROUP:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryGroupSubgroup';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.createGroupSubgroup';
                    break;
            }

            return {
                ...state,
                GroupListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.UPDATE_GROUP: {
          let listArr = [...state.GroupList];
          listArr.map(item =>{
            if (item.id === payload.id) {
              item['data'] = payload.data;
            }
          });
            return {
                ...state,
                GroupList: listArr,
                groupUpdated: true
            }
        }

        case ModuleEvents.ERROR_UPDATE_GROUP:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryGroupSubgroup';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.updateGroupSubgroup';
                    break;
            }

            return {
                ...state,
                GroupListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.DELETE_GROUP: {
            let listArr = [...state.GroupList];
            const filterArr = listArr.filter(item => item.id !== payload);
            return {
                ...state,
                GroupList: filterArr,
                groupDeleted: true
            }
        }

        case ModuleEvents.ERROR_DELETE_GROUP:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.deleteGroupSubgroup';
                    break;
            }

            return {
                ...state,
                GroupListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_GROUP_STATUS: {
            return {
                ...state,
                groupAdded: false, groupUpdated: false, groupDeleted:false,
                GroupListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.GROUP_SUBGROUP_RELATION_LIST: {
            return {
                ...state,
                GroupSubgroupRelaList: payload
            }
        }

        case ModuleEvents.ERROR_GROUP_SUBGROUP_RELATION_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.groupSubgrpRelaList';
                    break;
            }

            return {
                ...state,
                GroupSubgroupRelaListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_GROUP_SUBGROUP_RELATION_LIST: {
            return {
                ...state,
                GroupSubgroupRelaListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.FUEL_TYPE_LIST: {
            return {
                ...state,
                FuelTypeList: payload
            }
        }

        case ModuleEvents.ERROR_FUEL_TYPE_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                FuelTypeListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.CREATE_FUEL_TYPE: {
          let listArr = [...state.FuelTypeList, payload];
            return {
                ...state,
                FuelTypeList: listArr,
                fuelTypeAdded: true
            }
        }

        case ModuleEvents.ERROR_CREATE_FUEL_TYPE:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryFuelType';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.createFuelType';
                    break;
            }

            return {
                ...state,
                FuelTypeListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.UPDATE_FUEL_TYPE: {
          let listArr = [...state.FuelTypeList];
          listArr.map(item =>{
            if (item.id === payload.id) {
              item['data'] = payload.data;
            }
          });
            return {
                ...state,
                FuelTypeList: listArr,
                fuelTypeUpdated: true
            }
        }

        case ModuleEvents.ERROR_UPDATE_FUEL_TYPE:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryFuelType';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.updateFuelType';
                    break;
            }

            return {
                ...state,
                FuelTypeListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.DELETE_FUEL_TYPE: {
            let listArr = [...state.FuelTypeList];
            const filterArr = listArr.filter(item => item.id !== payload);
            return {
                ...state,
                FuelTypeList: filterArr,
                fuelTypeDeleted: true
            }
        }

        case ModuleEvents.ERROR_DELETE_FUEL_TYPE:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.deleteFuelType';
                    break;
            }

            return {
                ...state,
                FuelTypeListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_FUEL_TYPE_STATUS: {
            return {
                ...state,
                fuelTypeAdded: false, fuelTypeUpdated: false, fuelTypeDeleted:false,
                FuelTypeListRequest: new ApiResponseStatus(false, null)
            }
        }
        case ModuleEvents.DRIVE_TYPE_LIST: {
            return {
                ...state,
                DriveTypeList: payload
            }
        }

        case ModuleEvents.ERROR_DRIVE_TYPE_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                DriveTypeListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }
        case ModuleEvents.RESET_DRIVE_TYPE_STATUS: {
            return {
                ...state,
                driveTypeDeleted:false,
                driveTypeAdded:false,
                driveTypeUpdated: false,
                DriveTypeListRequest: new ApiResponseStatus(false, null)
            }
        }
        case ModuleEvents.CREATE_DRIVE_TYPE: {
            let listArr = [...state.DriveTypeList, payload];
              return {
                  ...state,
                  DriveTypeList: listArr,
                  driveTypeAdded: true
              }
          }

          case ModuleEvents.ERROR_CREATE_DRIVE_TYPE:{
              let errorCode;
              if (payload.status) {
                  errorCode = payload.status
              }
              let errorMessage = null;
              switch (errorCode) {
                  //handle status code and create error message accordingly
                  case 401:
                      localStorage.setItem('token', null);
                      localStorage.setItem('user', null);
                      errorMessage = 'error.unauthorized';
                      break;
                  case 200:
                      errorMessage = null;
                      break;
                  case 409:
                      errorMessage = 'error.duplicateEntryDriveType';
                      break;
                  case 500:
                      errorMessage = 'error.internalError';
                      break;
                  case 0:
                  case -1:
                      errorMessage = null;
                      break;
                  default:
                      errorMessage = 'error.createDriveType';
                      break;
              }

              return {
                  ...state,
                  DriveTypeListRequest: new ApiResponseStatus(false, errorMessage)
              }
          }
        case ModuleEvents.UPDATE_DRIVE_TYPE: {
            let listArr = [...state.DriveTypeList];
            listArr.map(item =>{
              if (item.id === payload.id) {
                item['data'] = payload.data;
              }
            });
              return {
                  ...state,
                  DriveTypeList: listArr,
                  driveTypeUpdated: true
              }
          }

        case ModuleEvents.ERROR_UPDATE_DRIVE_TYPE:{
              let errorCode;
              if (payload.status) {
                  errorCode = payload.status
              }
              let errorMessage = null;
              switch (errorCode) {
                  //handle status code and create error message accordingly
                  case 401:
                      localStorage.setItem('token', null);
                      localStorage.setItem('user', null);
                      errorMessage = 'error.unauthorized';
                      break;
                  case 200:
                      errorMessage = null;
                      break;
                  case 409:
                      errorMessage = 'error.duplicateEntryDriveType';
                      break;
                  case 500:
                      errorMessage = 'error.internalError';
                      break;
                  case 0:
                  case -1:
                      errorMessage = null;
                      break;
                  default:
                      errorMessage = 'error.updateDriveType';
                      break;
              }

              return {
                  ...state,
                  DriveTypeListRequest: new ApiResponseStatus(false, errorMessage)
              }
          }

        case ModuleEvents.DELETE_DRIVE_TYPE: {
            let listArr = [...state.DriveTypeList];
            const filterArr = listArr.filter(item => item.id !== payload);
            return {
                ...state,
                DriveTypeList: filterArr,
                driveTypeDeleted: true
            }
        }

        case ModuleEvents.ERROR_DELETE_DRIVE_TYPE:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.deleteDriveType';
                    break;
            }

            return {
                ...state,
                DriveTypeListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.SCOPE_LIST: {
            return {
                ...state,
                ScopeList: payload
            }
        }

        case ModuleEvents.ERROR_SCOPE_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                ScopeListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.CREATE_SCOPE: {
          let listArr = [...state.ScopeList, payload];
            return {
                ...state,
                ScopeList: listArr,
                scopeAdded: true
            }
        }

        case ModuleEvents.ERROR_CREATE_SCOPE:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryScopeSubscope';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.createScopeSubscope';
                    break;
            }

            return {
                ...state,
                ScopeListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.UPDATE_SCOPE: {
          let listArr = [...state.ScopeList];
          listArr.map(item =>{
            if (item.id === payload.id) {
              item['data'] = payload.data;
            }
          });
            return {
                ...state,
                ScopeList: listArr,
                scopeUpdated: true
            }
        }

        case ModuleEvents.ERROR_UPDATE_SCOPE:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryScopeSubscope';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.updateScopeSubscope';
                    break;
            }

            return {
                ...state,
                ScopeListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.DELETE_SCOPE: {
            let listArr = [...state.ScopeList];
            const filterArr = listArr.filter(item => item.id !== payload);
            return {
                ...state,
                ScopeList: filterArr,
                scopeDeleted: true
            }
        }

        case ModuleEvents.ERROR_DELETE_SCOPE:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.deleteScopeSubscope';
                    break;
            }

            return {
                ...state,
                ScopeListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_SCOPE_STATUS: {
            return {
                ...state,
                scopeAdded: false, scopeUpdated: false, scopeDeleted:false,
                ScopeListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.SCOPE_SUBSCOPE_RELATION_LIST: {
            return {
                ...state,
                ScopeSubscopeRelaList: payload
            }
        }

        case ModuleEvents.ERROR_SCOPE_SUBSCOPE_RELATION_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchListScope';
                    break;
            }

            return {
                ...state,
                ScopeSubscopeRelaListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_SCOPE_SUBSCOPE_RELATION_LIST: {
            return {
                ...state,
                ScopeSubscopeRelaListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.UPLOAD_FILES: {
            return {
                ...state,
                fileUploaded: true
            }
        }

        case ModuleEvents.ERROR_UPLOAD_FILES: {
          let errorCode;
          if (payload.error.status) {
              errorCode = payload.error.status
          }
          let errorMessage = null;
          switch (errorCode) {
              //handle status code and create error message accordingly
              case 401:
                  localStorage.setItem('token', null);
                  localStorage.setItem('user', null);
                  errorMessage = i18next.t('error.unauthorized');
                  break;
              case 409:
                  errorMessage = payload.files+' '+i18next.t('error.dublicateDocUploadErr');
                  break;
              case 404:
                  errorMessage = payload.files+' '+i18next.t('error.docUploadErr');
                  break;
              case 200:
                  errorMessage = null;
                  break;
              case 500:
                  errorMessage = payload.files+' '+i18next.t('error.internalError');
                  break;
              case 0:
              case -1:
                  errorMessage = null;
                  break;
              default:
                  errorMessage = payload.files+' '+i18next.t('error.docUploadErr');
                  break;
          }

          return {
              ...state,
              DocUploadRequest: new ApiResponseStatus(false, errorMessage)
          }
        }

        case ModuleEvents.RESET_UPLOAD_FILES_STATUS: {
            return {
                ...state,
                fileUploaded: true,
                DocUploadRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.EDIT_FILES: {
            return {
                ...state,
                fileUploaded: true
            }
        }

        case ModuleEvents.ERROR_EDIT_FILES: {
          let errorCode;
          if (payload.error.status) {
              errorCode = payload.error.status
          }
          let errorMessage = null;
          switch (errorCode) {
              //handle status code and create error message accordingly
              case 401:
                  localStorage.setItem('token', null);
                  localStorage.setItem('user', null);
                  errorMessage = i18next.t('error.unauthorized');
                  break;
              case 404:
                  errorMessage = payload.files+' '+i18next.t('error.docEditErr');
                  break;
              case 200:
                  errorMessage = null;
                  break;
              case 500:
                  errorMessage = payload.files+' '+i18next.t('error.internalError');
                  break;
              case 0:
              case -1:
                  errorMessage = null;
                  break;
              default:
                  errorMessage = payload.files+' '+i18next.t('error.docEditErr');
                  break;
          }

          return {
              ...state,
              DocEditRequest: new ApiResponseStatus(false, errorMessage)
          }
        }

        case ModuleEvents.RESET_EDIT_FILES_STATUS: {
            return {
                ...state,
                fileUploaded: true,
                DocEditRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.DELETE_FILES_ADMIN: {
            return {
                ...state,
                fileUploaded: true
            }
        }

        case ModuleEvents.ERROR_DELETE_FILES_ADMIN: {
          let errorCode;
          if (payload.error.status) {
              errorCode = payload.error.status
          }
          let errorMessage = null;
          switch (errorCode) {
              //handle status code and create error message accordingly
              case 401:
                  localStorage.setItem('token', null);
                  localStorage.setItem('user', null);
                  errorMessage = i18next.t('error.unauthorized');
                  break;
              case 404:
                  errorMessage = payload.files+' '+i18next.t('error.docDeleteErr');
                  break;
              case 200:
                  errorMessage = null;
                  break;
              case 500:
                  errorMessage = payload.files+' '+i18next.t('error.internalError');
                  break;
              case 0:
              case -1:
                  errorMessage = null;
                  break;
              default:
                  errorMessage = payload.files+' '+i18next.t('error.docDeleteErr');
                  break;
          }

          return {
              ...state,
              DocDeleteRequest: new ApiResponseStatus(false, errorMessage)
          }
        }

        case ModuleEvents.RESET_DELETE_FILES_ADMIN_STATUS: {
            return {
                ...state,
                fileUploaded: true,
                DocDeleteRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.MARKET_PROFILE_VALUES: {
            return {
                ...state,
                MarketValueList: payload,
                MarketValueFetched: true
            }
        }

        case ModuleEvents.ERROR_MARKET_PROFILE_VALUES:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                MarketValueRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.CREATE_MARKET_VALUE: {
            let listArr = [{...state.MarketValueList}, payload];
              return {
                  ...state,
                  MarketValueList: listArr,
                  marketValueAdded: true,
                 
              }
          }
  
          case ModuleEvents.ERROR_CREATE_MARKET_VALUE:{
              let errorCode;
              if (payload.status) {
                  errorCode = payload.status
              }
              let errorMessage = null;
              switch (errorCode) {
                  //handle status code and create error message accordingly
                  case 401:
                      localStorage.setItem('token', null);
                      localStorage.setItem('user', null);
                      errorMessage = 'error.unauthorized';
                      break;
                  case 200:
                      errorMessage = null;
                      break;
                  case 409:
                      errorMessage = 'error.dublicateEntryMarketValue';
                      break;
                  case 500:
                      errorMessage = 'error.internalError';
                      break;
                  case 0:
                  case -1:
                      errorMessage = null;
                      break;
                  default:
                      errorMessage = 'error.createMarketValue';
                      break;
              }
  
              return {
                  ...state,
                  MarketValueRequest: new ApiResponseStatus(false, errorMessage)
              }
          }

          case ModuleEvents. RESET_MARKET_PROFILE_VALUES: {
            return {
                ...state,
                marketValueAdded: false,
                marketValueUpdated : false,
                marketValueDeleted : false,
                MarketValueRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.UPLOAD_MARKET_VALUE: {
            let listArr = [];
            listArr.push({...state.MarketValueList})
            listArr && listArr.map((item) => {
                Object.entries(item).map(([key, val]) =>{
                     if(key === payload.type){
                       Object.values(val).map(id =>{
                          if(id === payload.data.id){
                              id['data'] = payload.data
                          }
                       })
                     }
                });
            });
              return {
                  ...state,
                  MarketValueList: listArr,
                  marketValueUpdated : true
              }
          }
  
          case ModuleEvents.ERROR_UPLOAD_MARKET_VALUE:{
              let errorCode;
              if (payload.status) {
                  errorCode = payload.status
              }
              let errorMessage = null;
              switch (errorCode) {
                  //handle status code and create error message accordingly
                  case 401:
                      localStorage.setItem('token', null);
                      localStorage.setItem('user', null);
                      errorMessage = 'error.unauthorized';
                      break;
                  case 200:
                      errorMessage = null;
                      break;
                  case 409:
                      errorMessage = 'error.dublicateEntryMarketValue';
                      break;
                  case 500:
                      errorMessage = 'error.internalError';
                      break;
                  case 0:
                  case -1:
                      errorMessage = null;
                      break;
                  default:
                      errorMessage = 'error.createMarketValue';
                      break;
              }
  
              return {
                  ...state,
                  MarketValueRequest: new ApiResponseStatus(false, errorMessage)
              }
          }
          case ModuleEvents.DELETE_MARKET_VALUE: {
            let listArr = [{...state.MarketValueList}];
            listArr && listArr.map((item) => {
                Object.entries(item).map(([key, val]) =>{
                    if(key === payload.type){
                      if (val instanceof Array) {
                       const list = val.filter(el => el.id !== payload.data)
                        item[key] = list
                     }
                    }
                });
                state.MarketValueList = item
            });
            return {
                ...state,
                 marketValueDeleted : true
            }
        }

        case ModuleEvents.ERROR_DELETE_MARKET_VALUE:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.deleteVehicle';
                    break;
            }

            return {
                ...state,
                MarketValueRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.ROAD_CONDITION: {
            return {
                ...state,
                RoadConditionList: payload,
            }
        }

        case ModuleEvents.ERROR_ROAD_CONDITION:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                RoadConditionRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.AVG_TEMP: {
            return {
                ...state,
                AvgTempList: payload,
            }
        }

        case ModuleEvents.ERROR_AVG_TEMP:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                AvgTempRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.HAND_DRIVE: {
            return {
                ...state,
                HandDriveList: payload,
            }
        }

        case ModuleEvents.ERROR_HAND_DRIVE:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                HandDriveRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.HUMIDITY: {
            return {
                ...state,
                HumidityList: payload,
            }
        }

        case ModuleEvents.ERROR_HUMIDITY:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                HumidityRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.ENV_CONDITION: {
            return {
                ...state,
                EnvConditionList: payload,
            }
        }

        case ModuleEvents.ERROR_ENV_CONDITION:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                EnvConditionRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.CERTIFICATION: {
            return {
                ...state,
                CertificationList: payload,
            }
        }

        case ModuleEvents.ERROR_CERTIFICATION:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                CertificationRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.AIR_CONDITION: {
            return {
                ...state,
                AirConditionList: payload,
            }
        }

        case ModuleEvents.ERROR_AIR_CONDITION:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                AirConditionRequest: new ApiResponseStatus(false, errorMessage)
            }
        }


        case ModuleEvents.GET_MARKET_PROFILE: {
            return {
                ...state,
                MarketProfileList: payload.data,
                marketProfileFetched: true
            }
        }

        case ModuleEvents.ERROR_GET_MARKET_PROFILE:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                MarketProfileRequest: new ApiResponseStatus(false, errorMessage)
            }
        }
        case ModuleEvents.CREATE_MARKET_PROFILE: {
            let listArr = [payload.data];
              return {
                  ...state,
                  MarketProfileList: listArr,
                  marketProfileAdded : true,
                  marketProfileFileUploaded: payload.filesAdded,
                  marketProfileID: payload.marketProfileID
              }
          }
  
          case ModuleEvents.ERROR_CREATE_MARKET_PROFILE:{
              let errorCode;
              if (payload.status) {
                  errorCode = payload.status
              }
              let errorMessage = null;
              switch (errorCode) {
                  //handle status code and create error message accordingly
                  case 401:
                      localStorage.setItem('token', null);
                      localStorage.setItem('user', null);
                      errorMessage = 'error.unauthorized';
                      break;
                  case 200:
                      errorMessage = null;
                      break;
                  case 409:
                      errorMessage = 'error.dublicateEntryMarketValue';
                      break;
                  case 500:
                      errorMessage = 'error.internalError';
                      break;
                  case 0:
                  case -1:
                      errorMessage = null;
                      break;
                  default:
                      errorMessage = 'error.createMarketValue';
                      break;
              }
  
              return {
                  ...state,
                  MarketProfileRequest: new ApiResponseStatus(false, errorMessage)
              }
          }


          case ModuleEvents. RESET_MARKET_PROFILE_STATUS: {
            return {
                ...state,
                marketProfileFetched:false,
                marketProfileAdded: false,
                MarketProfileRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.UPLOAD_MARKET_PROFILE_FILES: {
            return {
                ...state,
                marketProfileFileUploaded: true
            }
        }

        case ModuleEvents.ERROR_UPLOAD_MARKET_PROFILE_FILES: {
          let errorCode;
          if (payload.error.status) {
              errorCode = payload.error.status
          }
          let errorMessage = null;
          switch (errorCode) {
              //handle status code and create error message accordingly
              case 401:
                  localStorage.setItem('token', null);
                  localStorage.setItem('user', null);
                  errorMessage = i18next.t('error.unauthorized');
                  break;
              case 409:
                  errorMessage = payload.files+' '+i18next.t('error.dublicateDocUploadErr');
                  break;
              case 404:
                  errorMessage = payload.files+' '+i18next.t('error.docUploadErr');
                  break;
              case 200:
                  errorMessage = null;
                  break;
              case 500:
                  errorMessage = payload.files+' '+i18next.t('error.internalError');
                  break;
              case 0:
              case -1:
                  errorMessage = null;
                  break;
              default:
                  errorMessage = payload.files+' '+i18next.t('error.docUploadErr');
                  break;
          }

          return {
              ...state,
              MarketProfileDocUploadRequest: new ApiResponseStatus(false, errorMessage)
          }
        }

        case ModuleEvents.RESET_UPLOAD_MARKET_PROFILE_FILES_STATUS: {
            return {
                ...state,
                marketProfileFileUploaded: true,
                MarketProfileDocUploadRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.EDIT_MARKET_PROFILE_FILES: {
            return {
                ...state,
                marketProfileFileUploaded: true
            }
        }

        case ModuleEvents.ERROR_EDIT_MARKET_PROFILE_FILES: {
          let errorCode;
          if (payload.error.status) {
              errorCode = payload.error.status
          }
          let errorMessage = null;
          switch (errorCode) {
              //handle status code and create error message accordingly
              case 401:
                  localStorage.setItem('token', null);
                  localStorage.setItem('user', null);
                  errorMessage = i18next.t('error.unauthorized');
                  break;
              case 404:
                  errorMessage = payload.files+' '+i18next.t('error.docEditErr');
                  break;
              case 200:
                  errorMessage = null;
                  break;
              case 500:
                  errorMessage = payload.files+' '+i18next.t('error.internalError');
                  break;
              case 0:
              case -1:
                  errorMessage = null;
                  break;
              default:
                  errorMessage = payload.files+' '+i18next.t('error.docEditErr');
                  break;
          }

          return {
              ...state,
              MarketProfileDocEditRequest: new ApiResponseStatus(false, errorMessage)
          }
        }

        case ModuleEvents.RESET_EDIT_MARKET_PROFILE_FILES_STATUS: {
            return {
                ...state,
                marketProfileFileUploaded: true,
                MarketProfileDocEditRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.DELETE_MARKET_PROFILE_ADMIN: {
            return {
                ...state,
                marketProfileFileUploaded: true
            }
        }

        case ModuleEvents.ERROR_DELETE_MARKET_PROFILE_ADMIN: {
          let errorCode;
          if (payload.error.status) {
              errorCode = payload.error.status
          }
          let errorMessage = null;
          switch (errorCode) {
              //handle status code and create error message accordingly
              case 401:
                  localStorage.setItem('token', null);
                  localStorage.setItem('user', null);
                  errorMessage = i18next.t('error.unauthorized');
                  break;
              case 404:
                  errorMessage = payload.files+' '+i18next.t('error.docDeleteErr');
                  break;
              case 200:
                  errorMessage = null;
                  break;
              case 500:
                  errorMessage = payload.files+' '+i18next.t('error.internalError');
                  break;
              case 0:
              case -1:
                  errorMessage = null;
                  break;
              default:
                  errorMessage = payload.files+' '+i18next.t('error.docDeleteErr');
                  break;
          }

          return {
              ...state,
              MarketProfileDocDeleteRequest: new ApiResponseStatus(false, errorMessage)
          }
        }

        case ModuleEvents.RESET_DELETE_MARKET_PROFILE_ADMIN: {
            return {
                ...state,
                marketProfileFileUploaded: true,
                MarketProfileDocDeleteRequest: new ApiResponseStatus(false, null)
            }
        }

        
        case ModuleEvents.EDIT_MARKET_PROFILE: {
            let listArr = [...state.MarketProfileList];
            listArr.map(item =>{
              if (item.id === payload.data.id) {
                item = payload.data;
              }
            });
              return {
                  ...state,
                  MarketProfileList: listArr,
                  marketProfileAdded : true,
                  marketProfileFileUploaded: payload.filesAdded
              }
          }
  
          case ModuleEvents.ERROR_EDIT_MARKET_PROFILE:{
              let errorCode;
              if (payload.status) {
                  errorCode = payload.status
              }
              let errorMessage = null;
              switch (errorCode) {
                  //handle status code and create error message accordingly
                  case 401:
                      localStorage.setItem('token', null);
                      localStorage.setItem('user', null);
                      errorMessage = 'error.unauthorized';
                      break;
                  case 200:
                      errorMessage = null;
                      break;
                  case 409:
                      errorMessage = 'error.duplicateEntryVehicle';
                      break;
                  case 500:
                      errorMessage = 'error.internalError';
                      break;
                  case 0:
                  case -1:
                      errorMessage = null;
                      break;
                  default:
                      errorMessage = 'error.updateVehicle';
                      break;
              }
  
              return {
                  ...state,
                  MarketProfileRequest: new ApiResponseStatus(false, errorMessage)
              }
            }

            case ModuleEvents.DELETE_MARKET_PROFILE: {
                let listArr = [...state.MarketList];
                const filterArr = listArr.filter(item => item.id !== payload.marketProfileId);
                return {
                    ...state,
                    MarketProfileList: filterArr,
                    marketProfileDeleted: true
                }
            }
    
            case ModuleEvents.ERROR_DELETE_MARKET_PROFILE:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.deleteTopic';
                        break;
                }
    
                return {
                    ...state,
                    MarketProfileRequest: new ApiResponseStatus(false, errorMessage)
                }
            }

            case ModuleEvents.RESET_DELETE_MARKET_PROFILE: {
                return {
                    ...state,
                    marketProfileDeleted: false,
                    MarketProfileRequest: new ApiResponseStatus(false, null)
                }
            }

            case ModuleEvents.GET_FAQ: {
                return {
                    ...state,
                    FaqList: payload,
                }
            }
            case ModuleEvents.ERROR_GET_FAQ: {
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                FaqListRequest: new ApiResponseStatus(false, errorMessage)
            }
            }
            case ModuleEvents.UPDATE_FAQ:{
            let listArr = [...state.FaqList];
            listArr.map((item) =>{
                if (item.id === payload.data.id) {
                  item = payload.data;
                }
              });
              return {
                ...state,
                FaqList: listArr,
                faqListUpdated : true
            }
            }
            case ModuleEvents.ERROR_UPDATE_FAQ:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 409:
                        errorMessage = 'error.duplicateFaq';
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.updateFaq';
                        break;
                }
                return {
                    ...state,
                    FaqListRequest: new ApiResponseStatus(false, errorMessage),
                    faqListUpdated : false
                }
            }
            case ModuleEvents.ADD_FAQ: {
                let listArr = [...state.FaqList, payload];
                return {
                    ...state,
                    FaqList: listArr,
                    faqAdded: true
                }
            }

            case ModuleEvents.ERROR_ADD_FAQ:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 409:
                        errorMessage = 'error.duplicateFaqError';
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.errFetchList';
                        break;
                }
    
                return {
                    ...state,
                    FaqListRequest: new ApiResponseStatus(false, errorMessage),
                    faqAdded: false

                }
            }

            case ModuleEvents.DELETE_FAQ: {
                let listArr = [...state.FaqList];
                const filterArr = listArr.filter(item => item.id !== payload);
                return {
                    ...state,
                    FaqList: filterArr,
                    faqDeleted: true
                }
            }

            case ModuleEvents.ERROR_DELETE_FAQ:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                       //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.deleteFaq';
                        break;
                }
    
                return {
                    ...state,
                    FaqListRequest: new ApiResponseStatus(false, errorMessage),
                    faqDeleted: false
                }
            }
     
            case ModuleEvents.RESET_FAQ_STATUS: {
                return {
                    ...state,
                    faqListUpdated : false,
                    faqAdded: false,
                    faqDeleted: false,
                    FaqListRequest: new ApiResponseStatus(false, null)
                }
            }
            case ModuleEvents.ADD_REV_HISTORY: {
                return {
                    ...state,
                    revSuccessMsg : true
                }
            }
    
            case ModuleEvents.ERROR_ADD_REV_HISTORY: {
              let errorCode;
              if (payload.status) {
                  errorCode = payload.status
              }
              let errorMessage = null;
              switch (errorCode) {
                  //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 409:
                    errorMessage = 'error.dublicateRevisionError';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.deleteFaq';
                    break;
              }
    
              return {
                  ...state,
                  addRevisionHistoryRequest: new ApiResponseStatus(false, errorMessage)
              }
            }
    
            case ModuleEvents.RESET_ADD_REV_HISTORY_STATUS: {
                return {
                    ...state,
                    revSuccessMsg : false,
                    addRevisionHistoryRequest: new ApiResponseStatus(false, null)
                }
            }

            case ModuleEvents.ADD_DEL_SERIES_ID : {
                const delSerIds  = [...state.delSerIds];
                const serIds = [...delSerIds, payload.serNum]
                return {
                  ...state,
                  delSerIds: serIds,
                  regId: payload.regId
                }
            }

            case ModuleEvents.REM_DEL_SERIES_ID : {
                const delSerIds  = [...state.delSerIds];
                const serIds = delSerIds.filter(id => id !== payload)
                return {
                  ...state,
                  delSerIds: serIds
                }
            }

            case ModuleEvents.RESET_DEL_SERIES_ID : {
                return {
                    ...state,
                    delSerIds: [],
                    regId: ''
                }
            }

            case ModuleEvents.DELETE_SERIES: {
                return {
                    ...state,
                    seriesDeleted: true
                }
            }

            case ModuleEvents.ERROR_DELETE_SERIES: {
                let errorCode;
                if (payload.status) {
                  errorCode = payload.status
                }
                let errorMessage = null;
                switch(errorCode) {
                    case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.deleteSeries';
                    break;
                }

                return {
                    ...state,
                    seriesDeleted: false,
                    seriesDeletedRequest: new ApiResponseStatus(false, errorMessage)
                }
            }

            case ModuleEvents.RESET_DELETE_SERIES_STATUS: {
                return {
                    ...state,
                    seriesDeletedRequest: new ApiResponseStatus(false, null),
                    seriesDeleted: false
                }
            }

            case ModuleEvents.REGULATION_LIST: {
                return {
                    ...state,
                    RegulationList: payload
                }
            }
    
            case ModuleEvents.ERROR_REGULATION_LIST:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.errFetchList';
                        break;
                }
    
                return {
                    ...state,
                    RegulationListRequest: new ApiResponseStatus(false, errorMessage)
                }
            }
            case ModuleEvents.REVISION_HISTORY_LIST: {
                return {
                    ...state,
                    revisionList: payload
                }
            }
    
            case ModuleEvents.ERROR_REVISION_HISTORY_LIST:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.errFetchList';
                        break;
                }
    
                return {
                    ...state,
                    RevisionListRequest: new ApiResponseStatus(false, errorMessage)
                }
            }


        default:
            return {...state};
    }


};
