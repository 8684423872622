import React, {Fragment, Component} from 'react';
import { Translation  } from 'react-i18next';
import i18next from 'i18next';
import { Translate } from './../../../model/interfaces/translate.interface';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../../internal/modules/Actions';
import {
    UncontrolledDropdown, DropdownToggle, DropdownMenu,
    Nav, Col, Row, Button, NavItem, DropdownItem, CardBody, Card,
    CardText, Dropdown
} from 'reactstrap';
import city2 from '../../../assets/utils/images/dropdown-header/city2.jpg';
import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';
import Flag from 'react-flagkit';
// Dropdown Tabs Content
import Ionicon from 'react-ionicons/lib/IosNotifications';
import MailOpen from 'react-ionicons/lib/MdMailOpen';
import DeleteIcon from 'react-ionicons/lib/MdTrash';
import { AppState } from '../../../internal/modules/Reducers';
import {faClock, faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {toast} from 'react-toastify';
import {ProfileImage} from '../../../pages/ManageUser/UserColData';
import ReactTooltip from 'react-tooltip';
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';
import DeleteModalComp from './../../../pages/Table/DeleteModal';
import i18n from './../../../i18n/I18n';
import HomeIcon from 'react-ionicons/lib/IosHome';
import './style.css';

interface Props extends Translate{
    getRegulationCount: () => {};
    getTopicList: () => {};
    getMarketList: () => {};
    getVehicleList: () => {};
    getSearchTagList: () =>{};
    NotificationList?: any;
    deleteNotification: (any) => {};
    deleteAllNotification: () => {};
    markAsReadNotification: (any) => {};
    markAsReadAllNotification: () => {};
    getGroupList:() =>{};
    getVehicleFuelTypeList: () =>{};
    getDriveTypeList: () =>{};
    getScopeList: () => {};
    location?: any;
    history?: any;
    data?: any;
    getSubscibedTopics : () => {};
    getSubscriptions:(lastLogin: string) => {};
    getRegulationUpdates:(lastLogin: string) => {};
    getUserById: (id: number) => {};
    reloadTemplate: (any) => {};
   }

interface State{
  flagKey: string;
  totalList?: any;
  searchTerm: string;
  newMsgLength?: any;
  showLoaderOverlay: boolean;
  deleteCnfModal: boolean;
  notifyModal:boolean;
  errorMessage: string;
  activeLang: string;
}

//Flag key set based on language
const flagKey = {
  "en":'US',
  "de":'DE'
};

class HeaderDots extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            flagKey: flagKey[localStorage.getItem('i18nextLng')],
            totalList:[],
            searchTerm:'',
            newMsgLength:[],
            showLoaderOverlay:false,
            deleteCnfModal: false,
            notifyModal:false,
            errorMessage: null,
            activeLang: localStorage.getItem('i18nextLng')
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      return {
        errorMessage: nextProps.errorMessage
      };
    }

    componentDidUpdate(prevProps, prevState){
      if (this.props.NotificationList && prevProps.NotificationList !== this.props.NotificationList) {
        this.setState({totalList: this.props.NotificationList, showLoaderOverlay:false});
        this.countNewMsg();
      }
      if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
        toast['error'](i18next.t(this.state.errorMessage), { position: 'top-right' });
      }
    }

    //call on select language from dropdown of language and change language of app.
    onLanguageHandle = (newLang, flagKey) => {
      this.setState({flagKey: flagKey, activeLang:newLang});
      localStorage.setItem('change',newLang)
      i18n.changeLanguage(newLang);
      let userRole  = sessionStorage.getItem('selectedRole') != 'null' ? sessionStorage.getItem('selectedRole') : null;
      if(userRole === 'user')
        {
          const userId = JSON.parse(localStorage.getItem('user')).id;
          const lastLogin = localStorage.getItem('lastLogin') ? localStorage.getItem('lastLogin'): null;
          let date = moment.utc(lastLogin).local();
          const newDate = date.format('DD/MM/YYYY, HH:mm:ss')
          const splittedString = newDate.split(',')
          const d = new Date();
          const month = d.getMonth() + 1;
          const currentDate = d.getDate() + "/" + month + "/" + d.getFullYear();
          this.props.getRegulationCount();
          this.props.getTopicList();
          this.props.getMarketList();
          this.props.getVehicleList();
          this.props.getSearchTagList();
          this.props.getGroupList();
          this.props.getVehicleFuelTypeList();
          this.props.getDriveTypeList();
          this.props.getScopeList();
          this.props.getSubscibedTopics();
          this.props.getUserById(userId);
         splittedString && this.props.getSubscriptions(splittedString.length !== 0 ? splittedString[0] : currentDate);
         this.props.getRegulationUpdates(splittedString.length !== 0 ? splittedString[0] : currentDate)
        }
    }

    //notification bar input field value set on change
    startSearch = (e) =>{
      this.setState({searchTerm: e.target.value});
      let arr = [];
      if (e.target.value.trim() !== '') {
        //Filtering notifications based on input value
        arr = this.props.NotificationList && [...this.props.NotificationList].filter(message => {
          if (message.senderName.toLowerCase().includes(e.target.value.toLowerCase())) {
            return message;
          }
        });
      }
      else{
        //Showing all notifications when input is blank
        arr = this.props.NotificationList ? [...this.props.NotificationList] : [];
      }
      this.setState({totalList: arr});
    }

    //call on click of filter link in notification and "mark as read" notification.
    markReadHandler = (notification) =>{
      this.setState({showLoaderOverlay: true});
      this.props.markAsReadNotification(notification.notificationId);
    }

    //delete single notification.
    deleteHandler = (notification) =>{
      this.setState({showLoaderOverlay: true});
      this.props.deleteNotification(notification.notificationId);
    }

    //count number of new notification for display
    countNewMsg = () =>{
      let newMsg = [];
      this.props.NotificationList && [...this.props.NotificationList].map((item, index)=>{
        if (!item['isRead']) {
          newMsg.push(item.notificationId);
        }
      });
      this.setState({newMsgLength:newMsg});
    }

    //mark read all notification.
    markReadAllHandler = () =>{
      this.setState({showLoaderOverlay: true});
      this.props.markAsReadAllNotification();
    }

    //open delete all notifications confirmation modal.
    deleteAllHandler = () =>{
      this.setState({deleteCnfModal:true});
    }

    //delete all notificationSearchInput
    deleteAllHandlerConfirm = () =>{
      this.setState({deleteCnfModal:false, showLoaderOverlay: true});
      this.props.deleteAllNotification();
    }

    resetState = () =>{
      this.setState({searchTerm: ''});
      this.setState({totalList: this.props.NotificationList ? [...this.props.NotificationList] : []});
    }

    //open and close notification card.
    toggleModal = () =>{
      if (this.state.deleteCnfModal === true) {
        this.setState({notifyModal: true});
      }
      else{
        if (!this.state.notifyModal) {
          this.resetState();
        }
        this.setState({notifyModal: !this.state.notifyModal});
      }
    }

    //close delete notification modal.
    closeModal = () =>{
      this.setState({deleteCnfModal: false});
    }
     
    handleHome = () =>{
      var result= window.location.href.split('/');
      var Param = result[result.length-2];
      let lng = localStorage.getItem('i18nextLng')
      let user = JSON.parse(localStorage.getItem('user'))
      let token =(localStorage.getItem('token')).toString();
      let withoutQuotesToken = token.replace(/"/g, "");
      let queryString = `id=${user.id}`+`&token=${withoutQuotesToken}`+`&i18nextLng=${lng}`;
      
      if((Param === 'home') && (this.props.location.pathname.includes('/GRM'))){
        window.location.href = 'http://ithomas.meconit.in/home'+ '?language=' +lng ;
        localStorage.setItem('iThomos', '')
        localStorage.setItem('change','')
      }
      else if(this.props.location.pathname.includes('/GRM')){
        let userId = JSON.parse(localStorage.getItem('user'))?.id
        this.props.reloadTemplate("user");
        localStorage.setItem("token", JSON.stringify(user.token));
        this.props.getUserById(userId)
        this.props.history.push({
          pathname: '/app/home/GRM',
          search : this.props.location.search
         })
      }
      else if(this.props.data === 'xyz'){
        this.props.reloadTemplate("user");
        this.props.getUserById(user.id)
        this.props.history.push({
          pathname: '/app/home/GRM',
          search : queryString
         })
      }
      else{
        this.props.reloadTemplate("user");
        this.props.history.push({
          pathname: '/app/home'
          })
      }
    }
    render() {
      let homeIcon = localStorage.getItem('iThomos')
      const  spinner = <LoaderAnim active type="ball-beat"/>;
      const result= window.location.href.split('/');
      const Param = result[result.length-2];
        return (
            <Translation>{t => (
              <Fragment>
                <div className="header-dots" id="notificationPanelOuterDiv">
                  {
                    this.state.deleteCnfModal &&
                    <DeleteModalComp modalMsg={i18next.t('notification.deleteAllCnfMsg')} closeModal={this.closeModal} eventHandlerDelete={this.deleteAllHandlerConfirm}/>
                  }
                  { ((this.props.location?.state?.data === 'GRM') || (this.props.data !== '') || (this.props.location.pathname.includes('/GRM')) || (!this.props.location.pathname.includes('/home')) ) &&   
                  <UncontrolledDropdown id="homeIcon">
                    <DropdownToggle className="p-0 mr-2" color="link" onClick={this.handleHome}>
                      <a data-tip={Param !== 'home' ? i18next.t('backToGRMHome') : i18next.t('homeBtnTooltip') } data-for="homeHandler">
                       <div className="icon-wrapper icon-wrapper-alt rounded-circle ">
                         <div className="icon-wrapper-bg bg-focus"/>
                           <div className="language-icon" style={{'alignItems' : 'center'}}>
                              <HomeIcon  style={{'fill' : '#79a3cd', 'height' : '25px' , 'width' : '25px'}}/>
                           </div>
                       </div>
                        </a>
                      <ReactTooltip id="homeHandler" place="bottom" type="info" effect="float"/>
                    </DropdownToggle>
                  </UncontrolledDropdown>}
                  <Dropdown isOpen={this.state.notifyModal} toggle={this.toggleModal}>
                    <DropdownToggle className="p-0 mr-2" color="link">
                      <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                        <div className="icon-wrapper-bg bg-danger"/>
                        <Ionicon beat={this.state.newMsgLength.length > 0} color="#d92550" fontSize="23px" icon="md-notifications-outline"/>
                          {
                            this.state.newMsgLength.length > 0 &&
                            <div className="badge badge-danger" id="notifyUnreadMsgLengthCss">{this.state.newMsgLength.length}</div>
                          }
                      </div>
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu-xl rm-pointers" id="notifyDropdownMenu">
                      <Loader message={spinner} show={this.state.showLoaderOverlay} style={{'padding':'0'}}>
                        <div className="dropdown-menu-header mb-0">
                          <div className="dropdown-menu-header-inner bg-deep-blue">
                            <div className="menu-header-image opacity-1" style={{backgroundImage: 'url(' + city3 + ')'}}/>
                            <div className="menu-header-content text-dark">
                              <h5 className="menu-header-title">{t('header.notifications')+this.state.totalList.length}</h5>
                              <h6 style={this.state.newMsgLength.length > 0 ? {} : {'visibility':'hidden'}} className="menu-header-subtitle">{t('notification.youHave')} <b>{this.state.newMsgLength.length}</b> {t('notification.unreadNotify')}</h6>
                            </div>
                          </div>
                          <div className="row col-12" id="notifySearchBox">
                            <div className="input-group col-12">
                              <input className="form-control py-2" type="search" placeholder={t('notification.searchPlaceholder')} onChange={this.startSearch} value={this.state.searchTerm} id="notificationSearchInput" />
                            </div>
                          </div>
                          <div className="col-12 form-inline" id="markDeleteAllDiv">
                            {
                              this.state.totalList.length > 0 &&
                              <div className="col-12">
                                <Button color="primary" size="sm" onClick={this.markReadAllHandler}><MailOpen className="markReadIconCss" color="#fff" fontSize="13px" icon="md-notifications-outline"/>{t('notification.markReadAll')}</Button>
                                <Button color="primary" size="sm" onClick={this.deleteAllHandler}><DeleteIcon className="deleteIconCss" color="#fff" fontSize="13px" icon="md-notifications-outline"/>{t('notification.deleteAll')}</Button>
                              </div>
                            }
                          </div>
                          <div className="col-12" id="notificationContainer">
                            {
                              this.state.totalList.map((notification, index) =>{
                                return(
                                  <Col md={12} id="notificationMainContainer">
                                    <CardBody className="cardBodyOuter">
                                      <Card body className="msgDivHoverClass">
                                        <CardText>
                                          <div className="col-12 form-inline" id="profileImgMsgDiv">
                                            <div className="icon-wrapper icon-wrapper-alt rounded-circle col-2">
                                              <img className="rounded-circle" src={notification.senderProfileImage == null ? ProfileImage : notification.senderProfileImage} alt={t('noImgMsg')} width={45}  />
                                            </div>
                                            <div className="col-10" id="messageDiv" style={notification.isRead === false ? {'fontWeight':700} : {}}>
                                              {notification.senderName} {t('notification.haveShared')} <a href={notification.url} onClick={notification.isRead === false ? () =>this.markReadHandler(notification) : null} title={notification.url} target="_blank">{t('notification.regFilter')}</a> {t('notification.withYou')}.
                                            </div>
                                          </div>
                                          <div className="col-12 form-inline" id="dateDiv">
                                            <div className="col-4" id="markReadDeleteIcons">
                                              {
                                                notification.isRead === false &&
                                                <a data-tip={t('notification.readTooltip')} data-for={notification.notificationId+'markAsRead'}>
                                                  <MailOpen className="markReadIconCss" onClick={() =>this.markReadHandler(notification)} color="black" fontSize="18px" icon="md-notifications-outline"/>
                                                </a>
                                              }
                                              <ReactTooltip id={notification.notificationId+'markAsRead'} place="bottom" type="info" effect="float"/>
                                              <a data-tip={t('notification.deleteTooltip')} data-for={notification.notificationId+'markDelete'}>
                                                <DeleteIcon className="deleteIconCss" onClick={() =>this.deleteHandler(notification)} color="black" fontSize="18px" icon="md-notifications-outline"/>
                                              </a>
                                              <ReactTooltip id={notification.notificationId+'markDelete'} place="bottom" type="info" effect="float"/>
                                            </div>
                                            <div className="col-8">
                                              <FontAwesomeIcon id="dateIcon" icon={faClock}/>
                                              <b>{moment.utc(notification.createdOn, 'DD-MM-YYYY HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss')}</b>
                                            </div>
                                          </div>
                                        </CardText>
                                      </Card>
                                    </CardBody>
                                  </Col>)
                                })
                            }
                            {
                              this.state.totalList.length < 1 &&
                                <p className="notifyNoRecords">{t('notification.noRecords')}</p>
                            }
                          </div>
                        </div>
                      </Loader>
                    </DropdownMenu>
                  </Dropdown>
                  <UncontrolledDropdown>
                    <DropdownToggle className="p-0 mr-2" color="link">
                      <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                        <div className="icon-wrapper-bg bg-focus"/>
                          <div className="language-icon">
                              <Flag className="mr-3 opacity-8" country={this.state.flagKey} size={40}/>
                          </div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu right className="rm-pointers">
                        <div className="dropdown-menu-header">
                            <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                                <div className="menu-header-image opacity-05"
                                     style={{
                                         backgroundImage: 'url(' + city2 + ')'
                                     }}
                                />
                                <div className="menu-header-content text-center text-white">
                                    <h6 className="menu-header-subtitle mt-0">{t('header.selectLngTitle')}</h6>
                                </div>
                            </div>
                        </div>

                        <DropdownItem divider/>
                        <DropdownItem active={this.state.activeLang === 'en'} onClick={() => this.onLanguageHandle('en', 'US')}>
                            <Flag className="mr-3 opacity-8" country="US"/>
                            {t('language.en')}
                        </DropdownItem>
                        <DropdownItem active={this.state.activeLang === 'de'} onClick={() => this.onLanguageHandle('de', 'DE')}>
                            <Flag className="mr-3 opacity-8" country="DE"/>
                            {t('language.de')}
                        </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Fragment>)}</Translation>
        )
    }
}
const mapStateToProps = (appState: AppState) => ({
    NotificationList: appState.common.NotificationList,
    errorMessage: appState.common.NotificationListRequest.$errorMessage,
  });

const mapDispatchToProps = dispatch => ({
    getRegulationCount: () => Actions.user.getRegulationCount(dispatch),
    getTopicList: () => Actions.user.getTopicList(dispatch),
    getVehicleList: () => Actions.user.getVehicleList(dispatch),
    getMarketList: () => Actions.user.getMarketList(dispatch),
    getTabChange: (tab) =>Actions.user.getTabChange(tab,dispatch),
    getSearchTagList: () => Actions.user.getSearchTagList(dispatch),
    deleteNotification: (notificationId) => Actions.common.deleteNotification(notificationId, dispatch),
    deleteAllNotification: () => Actions.common.deleteAllNotification(dispatch),
    markAsReadNotification: (notificationId) => Actions.common.markAsReadNotification(notificationId, dispatch),
    markAsReadAllNotification: () => Actions.common.markAsReadAllNotification(dispatch),
    getGroupList: () => Actions.user.getGroupList(dispatch),
    getVehicleFuelTypeList: () => Actions.user.getVehicleFuelTypeList(dispatch),
    getDriveTypeList: () => Actions.user.getDriveTypeList(dispatch),
    getScopeList: () => Actions.user.getScopeList(dispatch),
    getSubscibedTopics : () => Actions.user.getSubscibedTopics(dispatch),
    getSubscriptions : (lastLogin) => Actions.user.getSubscriptions(lastLogin,dispatch),
    getRegulationUpdates:(lastLogin) => Actions.user.getRegulationUpdates(lastLogin,dispatch),
    getUserById: (userId) => Actions.user.getUserById(userId, dispatch),
    reloadTemplate: (role) => Actions.user.reloadTemplate(role, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderDots));
