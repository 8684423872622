import React, {Fragment, Component} from 'react';
import {Col, Row, Button, Form, FormGroup, Label, Input} from 'reactstrap';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import {toast} from 'react-toastify';
import { AvForm,  AvGroup, AvInput} from 'availity-reactstrap-validation';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import TranslationService from '../../utils/TranslationService';
import companyLogo from './../../Vetaca-GRM.png';
import EditModalComp from '../Table/EditModal';
import i18next from 'i18next';
import ForgotPassForm from '../ResetPassword/ForgotPassForm';
import moment from 'moment';

interface Props extends Translate{
  login: (any) => {};
  fcmToken ?: any;
  loading: boolean;
}

interface State{
  email: string,
  password: string,
  rememberMe: boolean,
  errorMessage: string,
  loginBtnDisable: boolean,
  modalFlag: boolean,
  formName: string
}
class LoginBoxed extends Component<Props, State> {

  constructor(props){
    super(props)
    this.state={
      email: '',
      password: '',
      rememberMe: false,
      errorMessage: null,
      loginBtnDisable: false,
      modalFlag: false,
      formName: 'Reset Password'
    }
  }

  componentDidMount() {
    const rememberMe = localStorage.getItem('rememberMe') === 'true';
    const login = rememberMe ? localStorage.getItem('login') : null;
    if(login){
      const data = JSON.parse(login)
      if(data !== null){
        this.setState({ email:data.email,password: data.password,  rememberMe });
      }
    }
  }

  /**
   * Define errormessages depending on error code of newState. Set loading, success and
   * errorCode variable.
   * @param newState
   * @param prevState
   */
  static getDerivedStateFromProps(newState, prevState) {
    return {
      success: newState.success,
      errorMessage: newState.errorMessage,
    };
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
      const t = TranslationService.t;
      toast['error'](t(this.state.errorMessage), { position: 'bottom-center' });
    }
    if (this.state.loginBtnDisable && (prevProps.loading !== this.props.loading)) {
      this.setState({loginBtnDisable: this.props.loading})
    }
  }
  //call on form submit and used to call login api
  handleValidSubmit = () => {
    const {email, password, rememberMe} = this.state;
    const fcmToken = this.props.fcmToken;
    const login = {email, password , fcmToken}
    let remember: string =  String(rememberMe);
    localStorage.setItem('rememberMe', remember)
    localStorage.setItem('login', rememberMe ? JSON.stringify(login) : '');
    this.setState({loginBtnDisable: true});
    this.props.login(login);
  }
  //call on click of forgot password link and used to open forgot password modal.
  handleForgotPassword = () => {
    this.setState({modalFlag: true})
  }
  
  //close forgot password modal.
  closeModal = () =>{
    this.setState({modalFlag: false})
  }

  render() {
    const { email, password, rememberMe} = this.state;
    let formTemplate, modalTitle = null;
    if(this.state.formName === 'Reset Password') {
      formTemplate = <ForgotPassForm {...this.props}  closeModal={this.closeModal} />
      modalTitle = i18next.t('login.forgotPassword');
    }
    return (
      <Fragment>
        <Translation>{t => (
          <div className="h-100 bg-plum-plate bg-animation">
               { this.state.modalFlag &&
                  <EditModalComp closeModal={this.closeModal} modalBody={formTemplate} modalTitle={modalTitle} />
                }
            <div className="d-flex h-100 justify-content-center align-items-center">
              <Col md="8" className="mx-auto app-login-box">
                <div className="text-center text-white opacity-8 mt-3 app-logo-inverse mx-auto w-100" >
                  <img style={{'height':'100%'}} src={companyLogo} alt="No Image"/>
                </div>
                <div className="modal-dialog w-100 mx-auto">
                  <div className="modal-content">
                    <AvForm onValidSubmit={this.handleValidSubmit}>
                      <div className="modal-body">
                        <div className="h5 modal-title text-center">
                          <h4 className="mt-2">
                            <div>{t('login.welcomeBack')}</div>
                            <span>{t('login.signInInfo')}</span>
                          </h4>
                        </div>
                          <Row form>
                            <Col md={12}>
                              <AvGroup>
                                <AvInput
                                  type="email"
                                  name="email"
                                  id="email"
                                  placeholder={t('login.inputEmail')}
                                  value={email}
                                  required
                                  onChangeCapture={(e) => this.setState({ email: e.target.value })}
                                />
                              </AvGroup>
                            </Col>
                            <Col md={12}>
                              <AvGroup>
                                <AvInput
                                  type="password"
                                  name="password"
                                  id="password"
                                  placeholder={t('login.inputPassword')}
                                  value = {password}
                                  required
                                  onChangeCapture={(e) => this.setState({ password: e.target.value })}
                                />
                              </AvGroup>
                            </Col>
                          </Row>
                          {/*<FormGroup check>
                          <Input type="checkbox" name="check" id="exampleCheck" checked = {rememberMe} onChange={(e) => this.setState({rememberMe: e.target.checked})}/>
                            <Label for="rememberMe" check>
                            {t('login.rememberMe')}
                            </Label>
                          </FormGroup>*/}
                      </div>
                      <div className="modal-footer clearfix">
                        <div className="float-left">
                          <a
                            onClick={this.handleForgotPassword}
                            href="javascript:void(0);"
                            className="btn-md btn btn-link"
                          >
                            {t('login.recoverPassword')}
                          </a>
                        </div>
                        <div className="float-right">
                          <Button color="primary" size="md" disabled={this.state.loginBtnDisable}>
                            {this.state.loginBtnDisable ? t('login.loggingIn') : t('login.loginToDashboard')}
                          </Button>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </div>
                <div className="text-center text-black opacity-8 mt-3">
                  {t('copyright')} &copy; {moment().year()} VETACA GmbH - {t('allRightsReserved')}
                </div>
              </Col>
            </div>
          </div>
        )}</Translation>
      </Fragment>
    );
  }
}


const mapStateToProps = (appState: AppState) => ({
  loading: appState.loading.loading,
  success: appState.user.loginRequest.$success,
  errorMessage: appState.user.loginRequest.$errorMessage,
});

const mapDispatchToProps = dispatch => ({
  login: (user) => Actions.user.login(user, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginBoxed);
