import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import './GRM.css';
import DownArrow from './../../chevron-sign-down.png';
import RightArrow from './../../chevron-sign-right.png';
import i18next from 'i18next';
import {languageArr} from './RegulationsColData';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import {Typeahead} from 'react-bootstrap-typeahead';
import MdInformationCircle from 'react-ionicons/lib/MdInformationCircle';

interface Props extends Translate{
  rowData?: any;
  MarketList?: any;
  marketCode: string;
  formtype?: string;
  inputOnBlurHandler: (market) => {};
  RegulationNumberList?: any;
}

interface State{
  toggleIconReference?: any;
  rowData?: any;
  referenceDataList?: any;
}

class ReferenceReg extends Component<Props, State> {
  constructor(props) {
    super(props)
      this.state = {
        toggleIconReference: {},
        rowData: this.props.rowData,
        referenceDataList:{}
      }
  }
  componentDidMount(){
    let {toggleIconReference} = this.state;
    [...languageArr].filter((lang) =>{
      this.props.MarketList && this.props.MarketList.map(item =>{
        toggleIconReference[item.value] = false;
      });
    });
    if (this.props.RegulationNumberList) {
      this.setListState();
    }
    this.setState(toggleIconReference);
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.RegulationNumberList && prevProps.RegulationNumberList !== this.props.RegulationNumberList) {
      //Update dropdown listing
      this.setListState();
    }
  }

  //Update dropdown list when props update
  setListState = () =>{
    let regulationsList = {};
    [...languageArr].filter((lang) =>{
      regulationsList[lang.code] = [];
      //Structuring dropdown list JSON to handle auto-selection by ID
      this.props.RegulationNumberList && [...this.props.RegulationNumberList].map(elem =>{
        if (elem.id == 0 || (elem['market'] === this.props.marketCode)) {
          let obj = {};
          obj['id'] = elem.id;
          obj['languageCode'] = lang.code;
          if (elem['data'] && elem['data'][lang.code] && elem['data'][lang.code]['regulationNumber']) {
            obj['regulationNumber'] = elem['data'][lang.code]['regulationNumber'];
          }
          else{
            obj['regulationNumber'] = '';
          }
          obj['market'] = elem['market'];
          regulationsList[lang.code].push(obj);
        }
      });
    });

    this.setState({referenceDataList: regulationsList});
  }

  //Reference regulations arrow icon toggle
  toggleIconReference = (market, e) =>{
    e.preventDefault();
    let {toggleIconReference} = this.state;
    toggleIconReference[market] = !toggleIconReference[market];
    this.setState(toggleIconReference);
  }

  //Dropdown onChange event handler
  eventHandler = (id, type, lang, marketCode, event) =>{
    let dataProps = this.state.referenceDataList;
      let data = {...this.state.rowData};
      let autoUpdateArr = [];
        //Setting selected value from the dropdown
        data['regulations'][marketCode][lang][id] = event;

        //Auto-filling other language dropdown fields by ID
        Object.keys(data['regulations'][marketCode]).map((key, index) => {
          if (key !== lang) {
            if (type !== 'object') {
              [...event].filter(elem => {
                 let newArr = dataProps[key].filter(item => {
                   if (item.id === elem.id) {
                     return item;
                   }
                 })
                 autoUpdateArr.push(newArr[0]);
             });
             data['regulations'][marketCode][key][id] = autoUpdateArr;
            }
            else{
              dataProps[key].filter(item => {
                if (item.id === event.id) {
                  data['regulations'][marketCode][key][id] = item[id];
                }
              });
            }
          }
        });
      this.setState({rowData: data});
  }

  render() {
    let {marketCode} = this.props;
    let {rowData} = this.state;
    return(
      <Translation>{t => (
      <div className="form-inline col-sm-12">
        <div className="col-4 form-inline collapseRow">
          <div className="col-8 form-inline"><h6><b>{i18next.t('mngRegulations.referenceReg')}</b></h6></div>
          <div className="col-4">
            <div id={'accordionReferenceReg'+marketCode}>
              <div id={'headingAccordionReferenceReg'+marketCode}>
                <h5 className="mb-0">
                  <button onClick={this.toggleIconReference.bind(this, marketCode)} className="btn btn-link" data-toggle="collapse" data-target={'#collapseReferenceRegCard'+marketCode} aria-expanded="true" aria-controls={'collapseReferenceRegCard'+marketCode}>
                    {
                      this.state.toggleIconReference[marketCode] ?
                      <img src={DownArrow} />
                      :
                      <img src={RightArrow} />
                    }
                  </button>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div id={'collapseReferenceRegCard'+marketCode} className="card collapse col-sm-12 noPadding" aria-labelledby={'headingAccordionReferenceReg'+marketCode} data-parent={'#accordionReferenceReg'+marketCode}>
          <div className="card-body">
            <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
              <div className="col-4 form-inline" style={{'paddingRight':'0px'}}>
                <h6 style={{'width':'90%'}}><b>{i18next.t('mngRegulations.directlyAffects')}</b></h6>
                {/* Reference regulations - Directly affects info icon */}
                <a data-tip={t('mngRegulations.referenceDirectlyAffectsTooltip')} data-for={'referenceDirectlyAffects'}>
                  <MdInformationCircle color="#545cd8" fontSize="18px" icon="md-notifications-outline"/>
                </a>
                <ReactTooltip id={'referenceDirectlyAffects'} place="bottom" type="info" effect="float"/>
              </div>
                {
                  [...languageArr].map(lang =>{
                    return(
                      <div className="col-4 labelTextCss" id="referenceDirectlyAffectsDropdown">
                        <h6>
                        {/* Dropdown field visible in edit mode else read only text is visible */}
                          {
                            this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                            <Typeahead
                            id="basic-typeahead-multiple"
                            emptyLabel={t('noMatchFound')}
                            clearButton
                            multiple
                            labelKey="regulationNumber"
                            onChange={this.eventHandler.bind(rowData.regulations[lang.code], 'referenceDirectlyAffects', 'array', lang.code, marketCode)}
                            options={this.state.referenceDataList[lang.code] ? this.state.referenceDataList[lang.code] : []}
                            selected={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] && rowData.regulations[marketCode][lang.code].referenceDirectlyAffects}
                            placeholder={t('mngRegulations.refRegPlaceholder')}/>
                            : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                              rowData.regulations[marketCode][lang.code].referenceDirectlyAffectsNames
                          }
                        </h6>
                      </div>
                    )})
                }
            </div>
            <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
              <div className="col-4 form-inline" style={{'paddingRight':'0px'}}>
                <h6 style={{'width':'90%'}}><b>{i18next.t('mngRegulations.affectedBy')}</b></h6>
                {/* Reference regulations - AffectedBy info icon */}
                <a data-tip={t('mngRegulations.referenceAffectedByTooltip')} data-for={'referenceAffectedBy'}>
                  <MdInformationCircle color="#545cd8" fontSize="18px" icon="md-notifications-outline"/>
                </a>
                <ReactTooltip id={'referenceAffectedBy'} place="bottom" type="info" effect="float"/>
              </div>
                {
                  [...languageArr].map(lang =>{
                    return(
                      <div className="col-4 labelTextCss" id="referenceAffectedByDropdown">
                        <h6>
                        {/* Dropdown field visible in edit mode else read only text is visible */}
                          {
                            this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                            <Typeahead
                            id="basic-typeahead-multiple"
                            emptyLabel={t('noMatchFound')}
                            clearButton
                            multiple
                            labelKey="regulationNumber"
                            onChange={this.eventHandler.bind(rowData.regulations[lang.code], 'referenceAffectedBy', 'array', lang.code, marketCode)}
                            options={this.state.referenceDataList[lang.code] ? this.state.referenceDataList[lang.code] : []}
                            selected={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] && rowData.regulations[marketCode][lang.code].referenceAffectedBy}
                            placeholder={t('mngRegulations.refRegPlaceholder')}/>
                            : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                              rowData.regulations[marketCode][lang.code].referenceAffectedByNames
                          }
                        </h6>
                      </div>
                    )})
                }
            </div>
            <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
              <div className="col-4 form-inline" style={{'paddingRight':'0px'}}>
                <h6 style={{'width':'90%'}}><b>{i18next.t('mngRegulations.references')}</b></h6>
                {/* Reference regulations - References info icon */}
                <a data-tip={t('mngRegulations.referencesTooltip')} data-for={'references'}>
                  <MdInformationCircle color="#545cd8" fontSize="18px" icon="md-notifications-outline"/>
                </a>
                <ReactTooltip id={'references'} place="bottom" type="info" effect="float"/>
              </div>
                {
                  [...languageArr].map(lang =>{
                    return(
                      <div className="col-4 labelTextCss" id="referencesDropdown">
                        <h6>
                        {/* Dropdown field visible in edit mode else read only text is visible */}
                          {
                            this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                            <Typeahead
                            id="basic-typeahead-multiple"
                            emptyLabel={t('noMatchFound')}
                            clearButton
                            multiple
                            labelKey="regulationNumber"
                            onChange={this.eventHandler.bind(rowData.regulations[lang.code], 'references', 'array', lang.code, marketCode)}
                            options={this.state.referenceDataList[lang.code] ? this.state.referenceDataList[lang.code] : []}
                            selected={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] && rowData.regulations[marketCode][lang.code].references}
                            placeholder={t('mngRegulations.refRegPlaceholder')}/>
                            : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                              rowData.regulations[marketCode][lang.code].referenceNames
                          }
                        </h6>
                      </div>
                    )})
                }
            </div>
            <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
              <div className="col-4 form-inline" style={{'paddingRight':'0px'}}>
                <h6 style={{'width':'90%'}}><b>{i18next.t('mngRegulations.mentionedExpAnnex')}</b></h6>
                {/* Reference regulations - Mentioned in example/annex info icon */}
                <a data-tip={t('mngRegulations.referencementionedExpAnnexTooltip')} data-for={'referenceMentionedExampleAnnex'}>
                  <MdInformationCircle color="#545cd8" fontSize="18px" icon="md-notifications-outline"/>
                </a>
                <ReactTooltip id={'referenceMentionedExampleAnnex'} place="bottom" type="info" effect="float"/>
              </div>
                {
                  [...languageArr].map(lang =>{
                    return(
                      <div className="col-4 labelTextCss" id="referenceMentionedExampleAnnexDropdown">
                        <h6>
                        {/* Dropdown field visible in edit mode else read only text is visible */}
                          {
                            this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                            <Typeahead
                            id="basic-typeahead-multiple"
                            emptyLabel={t('noMatchFound')}
                            clearButton
                            multiple
                            labelKey="regulationNumber"
                            onChange={this.eventHandler.bind(rowData.regulations[lang.code], 'referenceMentionedExampleAnnex', 'array', lang.code, marketCode)}
                            options={this.state.referenceDataList[lang.code] ? this.state.referenceDataList[lang.code] : []}
                            selected={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] && rowData.regulations[marketCode][lang.code].referenceMentionedExampleAnnex}
                            placeholder={t('mngRegulations.refRegPlaceholder')}/>
                            : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                              rowData.regulations[marketCode][lang.code].referenceMentionedExampleAnnexNames
                          }
                        </h6>
                      </div>
                    )})
                }
            </div>
          </div>
        </div>
      </div>)}</Translation>
    )
  }
}
  const mapStateToProps = (appState: AppState) => ({
    MarketList: appState.admin.MarketList,
    RegulationNumberList: appState.admin.RegulationNumberList
  });

  const mapDispatchToProps = dispatch => ({
  });

  export default connect(mapStateToProps, mapDispatchToProps)(ReferenceReg);
