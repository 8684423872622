import React, { Component } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import {
  InputGroup,
  InputGroupAddon,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import "./addActualReg.css";
import moment from "moment";
import { languageArr } from "./RegulationsColData";
import i18next from "i18next";
import mockData from './../../utils/StaticData.json';
import {revHistory} from '../../utils/MockDataFiles/RevisionHistory';
import { toast } from "react-toastify";

interface Props {
  toggleAddSeries: () => any;
  handleAddRev: (any) => any;
  data ?: any;
  marketCode ?: any;
  closeModal ?: any;
  rowData ?: any;
  market ?: any;
}

interface State {
  series: string;
  addendumName: string;
  regLink: string;
  status?: any;
  statusDropDown: boolean;
  softRelDropDown: boolean;
  enfAll: any;
  enfNew: any;
  expiry: any;
  name: any;
  softRel?: any;
  rowData: any;
  forecastClass?: any;
  isValLink: boolean;
  market?: any;
}


class AddActualRegForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      series: "",
      addendumName: "",
      regLink: "",
      status: [],
      statusDropDown: false,
      softRelDropDown: false,
      enfAll: "",
      enfNew: "",
      expiry: "",
      name: [],
      softRel: [],
      forecastClass: [],
      rowData: this.props.data,
      isValLink: true,
      market : ''
    };
  }

  componentDidMount=() => {
    let {rowData} = this.state;    
    if(this.props.data !== undefined){
      let softRevArr = [];
      let statusArr = [];
      let forecastClassArr = [];
      let enfNew = null;
      let enfAll = null;
      let expDate = null;
      [...mockData.statusUser[localStorage.getItem('i18nextLng')]].map(item =>{
        if (item.id === rowData?.status) {
          statusArr.push(item.name)
        }
      });
      let nameArr = rowData?.name?.split('/')

      let addendum = nameArr && nameArr[0]?.split('.')
      
      // filtering addendum from name
      let fNameArr = nameArr && nameArr.filter((name,i) => name[i][0] !== 'A' && name[i][1] !== 'd' && name[i][2] !== 'd' )
      
      if(rowData?.softwareRelevant !== ''){
        softRevArr.push(rowData?.softwareRelevant)
      }

      if(rowData?.forecastClass !== ''){ 
        rowData.forecastClass && forecastClassArr.push(rowData.forecastClass)
      }
      
      if(rowData?.enforcementDateAllModel !== null) {
        if(rowData.enforcementDateAllModel) {
          enfAll = rowData.enforcementDateAllModel
        }
      }

      if(rowData?.enforcementDateNewModel !== null) {
        if(rowData.enforcementDateNewModel) {
          enfNew = rowData.enforcementDateNewModel
        }
      }

      if(rowData?.enforcementDateNewModel !== null) {
        if(rowData.enforcementDateNewModel) {
          enfNew = rowData.enforcementDateNewModel
        }
      }

      if(rowData?.expirationDate !== null) {
        if(rowData.expirationDate) {
          expDate = rowData.expirationDate
        }
      }
      
      // adding addendum to rowData as we are not getting addendum from api 
      if(this.props.market === 'EU'){
        let finalObj = {...this.props.data,enforcementDateAllModel: enfAll, enforcementDateNewModel: enfNew, expirationDate: expDate}
        this.setState({ softRel: softRevArr, status: statusArr, forecastClass: forecastClassArr, rowData: finalObj, enfAll: rowData?.enforcementDateAllModel && rowData.enforcementDateAllModel, enfNew: rowData?.enforcementDateNewModel && rowData.enforcementDateNewModel, expiry: rowData?.expirationDate && rowData.expirationDate })
      } 
      else{
        let finalObj = {...this.props.data, addendum: addendum[1],enforcementDateAllModel: enfAll, enforcementDateNewModel: enfNew, expirationDate: expDate}
       this.setState({name : fNameArr , softRel: softRevArr, status: statusArr, forecastClass: forecastClassArr, rowData: finalObj, enfAll: rowData?.enforcementDateAllModel && rowData.enforcementDateAllModel, enfNew: rowData?.enforcementDateNewModel && rowData.enforcementDateNewModel, expiry: rowData?.expirationDate && rowData.expirationDate })   
      }
    }else{
      let obj = rowData!== undefined ? this.props.data : {};
      this.setState({rowData: obj})
    }
  }
  componentDidUpdate(prevProps, prevState){
    if (this.props.market && prevProps.market !== this.props.market) {
      this.setState({market : this.props.market})
   }
  }
  resetForm = () => {
    this.setState({
      addendumName: "",
      regLink: "",
      status: "",
      statusDropDown: false,
      enfAll: "",
      enfNew: "",
      expiry: "",
      softRel: "",
    });
  };

  nameHandler = (names) => {
    this.setState({name: names});
    this.resetForm();
  }

  toggleSoftRel = () => {
    this.setState((prevState) => ({
      softRelDropDown: !prevState.softRelDropDown,
    }));
  };

  handleSoftRel = (rel) => {
    if (rel[0]?.id) {
      rel.map(item =>{
        this.state.rowData['softwareRelevant'] = item.name;
        this.setState({ softRel: rel });
      })
     }
    else{
      this.state.rowData['softwareRelevant'] = '';
      this.setState({ softRel: '' });
    }
  };

  handleForeCast = (fclass) => {    
    if (fclass[0]?.id) {
      fclass.map(item =>{
        this.state.rowData['forecastClass'] = item.name
      })
      this.setState({ forecastClass: fclass });
     }
    else {
      this.state.rowData['forecastClass'] = '';
      this.setState({ forecastClass: '' });
    }
  };

  handleStatus = (status) => {
    if (status[0]?.id) {
    status.map(item =>{
      this.state.rowData.status = item.id
    })
    this.setState({ status: status });
   }
   else{
    this.state.rowData.status = ''
     this.setState({status: ''}) 
   }
  };

  urlPatternValidation = URL => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
    return regex.test(URL);
  };

  inputHandler = (id,e) => {
    let {rowData} = this.state;
    const {value} = e.target;
    rowData[id] = value
    this.setState({rowData});
  }

  dateHandler(date, label) {
    let {rowData} = this.state;
    if(date) {
      let data = new Date(date)
      let newDate = data && data.toLocaleDateString('en-GB')
      rowData[label] = newDate;
    }
    else {
      rowData[label] = null;
    }
    this.setState({rowData });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const isValLink = !this.state.rowData['link'] || this.urlPatternValidation(this.state.rowData['link']);
    if(isValLink) {
      let name = this.state.name.join("/")
      let withAddendumName;
          if(name.length !== 0 ) {
            withAddendumName = this.state.rowData?.addendum && 'Add.' + this.state.rowData?.addendum + `/` + name
          }
          else {
            withAddendumName = this.state.rowData?.addendum && 'Add.' + this.state.rowData?.addendum
          }
      let arr = [];
      let mainArr = [];
      let obj;
      if(this.props.market == 'EU' ) {
         obj = {
          "series" : this.state.rowData?.series,
          "euRegulation" :this.state.rowData?.euRegulation,
          "link": this.state.rowData?.link ? this.state.rowData?.link : '',
          "status": this.state.rowData.status ? this.state.rowData.status: '',
          "softwareRelevant": this.state.rowData.softwareRelevant ? this.state.rowData.softwareRelevant: '',
          "forecastClass": this.state.rowData.forecastClass ? this.state.rowData.forecastClass: '',
          "expirationDate": this.state.rowData?.expirationDate ? this.state.rowData?.expirationDate : '',
          "enforcementDateAllModel": this.state.rowData?.enforcementDateAllModel ? this.state.rowData?.enforcementDateAllModel : '',
          "enforcementDateNewModel": this.state.rowData?.enforcementDateNewModel ? this.state.rowData?.enforcementDateNewModel :  '',
          "removed" : false,
          "languageCode" : 'en'
          }
      }
      else{
        obj = {
          "series" : this.state.rowData?.series,
          "addendum" : this.state.rowData?.addendum,
          "name":withAddendumName ? withAddendumName : name ? name : '',
          "link": this.state.rowData?.link ? this.state.rowData?.link : '',
          "status": this.state.rowData.status ? this.state.rowData.status: '',
          "softwareRelevant": this.state.rowData.softwareRelevant ? this.state.rowData.softwareRelevant: '',
          "forecastClass": this.state.rowData.forecastClass ? this.state.rowData.forecastClass: '',
          "expirationDate": this.state.rowData?.expirationDate ? this.state.rowData?.expirationDate : '',
          "enforcementDateAllModel": this.state.rowData?.enforcementDateAllModel ? this.state.rowData?.enforcementDateAllModel : '',
          "enforcementDateNewModel": this.state.rowData?.enforcementDateNewModel ? this.state.rowData?.enforcementDateNewModel :  '',
          "removed" : false,
          "languageCode" : 'en'
          }
      }
     
        if( this.state.rowData?.id === undefined ){
           obj = obj;
        }
        else{
           obj['id'] = this.state.rowData.id;
        }
        arr.push(obj)
        let mainObj = {
          series : this.state.rowData?.series,
          data : arr
        }
        mainArr.push(mainObj)
      this.props.handleAddRev(mainArr)
      this.props.closeModal()
    }
    else {
      this.setState({isValLink})
    }
  }

  render() {    
   let {rowData} = this.state;
    return (
      <Translation>
        {(t) => (
              <div id="addRevForm">
            <div className="form-inline col-sm-12">
              <div className="col-4">
                  <h6>
                    <b>{t("mngRegulations.seriesLabel")}</b>
                  </h6>
              </div>
              <div className="col-8 labelTextCss" >
                <h6>
                  <input
                   style={{width : '47%'}}
                    name="series"
                    title={rowData?.series}
                    type="text"
                    id="seriesNameInput"
                    value={rowData?.series}
                    onChange={this.inputHandler.bind(this,'series')}
                    className="form-control"
                    placeholder="Series name" />
                </h6>
              </div>
            </div>
          { this.props.market !== 'EU' &&
            <div className="form-inline col-sm-12">
              <div className="col-4">
                  <h6>
                    <b>{t("mngRegulations.addendumLabel")}</b>
                  </h6>
              </div>
              <div className="col-8 labelTextCss" >
                <h6>
                  <input
                   style={{width : '47%'}}
                    name="addendum"
                    title={rowData?.addendum}
                    type="text"
                    id="seriesNameInput"
                    value={rowData?.addendum}
                    onChange={this.inputHandler.bind(this,'addendum')}
                    className="form-control"
                    placeholder="Addendum name" />
                </h6>
              </div>
            </div>
           }
            {/* Addedum input commented */}
            {/* <div className="form-inline col-sm-12">
        <div className="col-4 form-inline" id="actualRevisionLabel">
          <div className="form-inline col-sm-12">
            <h6>
              <b>{t("mngRegulations.addendum")}</b>
            </h6>
          </div>
        </div>
        <div className="col-4 labelTextCss">
          <h6>
            <input
              // onBlur={this.inputOnBlurHandler.bind(this, marketCode)}
              // title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
              //   rowData.regulations[marketCode][lang.code].actualAddendum}
              name="addendumName"
              title="addendumName"
              type="text"
              id="addendumNameInput"
              value={this.state.addendumName}
              onChange={(e) => this.inputHandler(e)}
              // onChange={this.inputHandler.bind(this, 'actualAddendum', lang.code, marketCode, '')}
              className="form-control"
              placeholder="Enter name"
            />
          </h6>
        </div>
      </div> */}
        {this.props.market == 'EU' ?
             <div className="form-inline col-sm-12">
             <div className="col-4">
                 <h6>
                   <b>{t('advancedFilters.actualRegNo')}</b>
                 </h6>
             </div>
             <div className="col-4">
             <h6>
                  <input
                    name="regulation"
                    title={rowData?.euRegulation}
                    type="text"
                    id="seriesNameInput"
                    value={rowData?.euRegulation}
                    onChange={this.inputHandler.bind(this,'euRegulation')}
                    className="form-control"
                    placeholder="Regualtion number" />
                </h6>
             </div>
             <div className="col-4">
                <h6>
                  <input
                    name="regLink"
                    title="addendumLink"
                    type="text"
                    id="addendumLink"
                    value={rowData?.link}
                    onChange={this.inputHandler.bind(this,'link')}
                      // onChange={this.inputHandler.bind(this, 'actualAddendum', lang.code, marketCode, '')}
                    className="form-control"
                    placeholder="Enter link" />
                </h6>
              </div>
             </div>
            :
            <div className="form-inline col-sm-12">
              <div className="col-4">
                  <h6>
                    <b>{t("mngRegulations.revHistLabel")}</b>
                  </h6>
              </div>
              <div className="col-4">
                  <Typeahead
                    id='revTypeahead'
                    clearButton
                    multiple
                    selected={this.state.name}
                    onChange={(selected) => this.nameHandler(selected)}
                    options={revHistory()} />
              </div>
              <div className="col-4">
                <h6>
                  <input
                    name="regLink"
                    title="addendumLink"
                    type="text"
                    id="addendumLink"
                    value={rowData?.link}
                    onChange={this.inputHandler.bind(this,'link')}
                      // onChange={this.inputHandler.bind(this, 'actualAddendum', lang.code, marketCode, '')}
                    className="form-control"
                    placeholder="Enter link" />
                </h6>
              </div>
            </div>
          }
           
            
            <div className="form-inline col-sm-12">
              <div className="col-4">
                  <h6>
                    <b>{t("mngRegulations.actualEnfDate")}</b>
                  </h6>
                  <i style={{ fontSize: "x-small" }}>( DD/MM/YYYY )</i>
              </div>
              {/* <div
        className="col-8  labelTextCss "
        style={{ margin: 0 }}
      > */}
              <div className="col-4 labelTextCss">
                <div className="datePickerContainerRight ">
                  <div style={{ fontSize: "x-small" }}>
                    {t("mngRegulations.actualEnforcementDateAllModel")}
                  </div>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </div>
                    </InputGroupAddon>
                    <DatePicker
                      name="enforcementDateAllModel"
                      isClearable
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      popperProps={{
                        positionFixed: true // use this to make the popper position: fixed
                      }}
                      selected={rowData?.enforcementDateAllModel  && new Date(Date.parse(moment(rowData.enforcementDateAllModel, 'DD/MM/YYYY').format()))}
                      onChange={(date) => this.dateHandler(date, "enforcementDateAllModel")} />
                  </InputGroup>
                </div>
              </div>
              <div className="col-4 labelTextCss">
                <div className="datePickerContainerRight">
                  <div style={{ fontSize: "x-small" }}>
                    {t("mngRegulations.actualEnforcementDateNewModel")}
                  </div>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </div>
                    </InputGroupAddon>
                    <DatePicker
                      name="enforcementDateNewModel"
                      isClearable
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      popperProps={{
                        positionFixed: true // use this to make the popper position: fixed
                      }}
                      showYearDropdown
                      selected={rowData?.enforcementDateNewModel && new Date(Date.parse(moment(rowData.enforcementDateNewModel, 'DD/MM/YYYY').format())) }
                      onChange={(date) => this.dateHandler(date, "enforcementDateNewModel")} />
                  </InputGroup>
                </div>
              </div>
          </div><div className="form-inline col-sm-12" style={{paddingBottom: '5px'}}>
              <div className="col-4">
                  <h6>
                    <b>{t("mngRegulations.actualExpirationDate")}</b>
                  </h6>
                  <i style={{ fontSize: "x-small" }}>( DD/MM/YYYY )</i>
              </div>
              <div className="col-4 labelTextCss ">
                <div className="datePickerContainerRight">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </div>
                    </InputGroupAddon>
                    <DatePicker
                      name="expirationDate"
                      isClearable
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      popperProps={{
                        positionFixed: true // use this to make the popper position: fixed
                      }}
                      selected={rowData?.expirationDate && new Date(Date.parse(moment(rowData.expirationDate, 'DD/MM/YYYY').format())) }
                      onChange={(date) => this.dateHandler(date, "expirationDate")} />
                  </InputGroup>
                </div>
              </div>
            </div><div className="form-inline col-sm-12" style={{paddingBottom: '5px'}}>
              <div className="col-4">
                  <h6>
                    <b>{t("status")}</b>
                  </h6>
              </div>
              <div className="col-8">
              <Typeahead
                style={{width : '47%'}}
                id="basic-typeahead-single"
                emptyLabel={t('noMatchFound')}
                clearButton
                labelKey="name"
                selected={this.state.status}
                onChange={this.handleStatus}
                options={ [...mockData.statusUser[localStorage.getItem('i18nextLng')]]}
                placeholder={t('advancedFilters.selectStatus')}
              />
              </div>
            </div>
            <div className="form-inline col-sm-12" style={{paddingBottom: '5px'}}>
              <div className="col-4">
                  <h6>
                    <b>{t("mngRegulations.softwareRelTitle")}</b>
                  </h6>
              </div>
              <div className="col-8">
                          <Typeahead
                              style={{width : '47%'}}
                              id="basic-typeahead-single"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="name"
                              selected={this.state.softRel}
                              onChange={this.handleSoftRel}
                              options={[{id:1,name:"Yes"},{id:2,name:"No"}]}
                              placeholder={t('advancedFilters.selectStatus')}
                          />
              </div>
            </div>
            <div className="form-inline col-sm-12">
              <div className="col-4">
                  <h6>
                    <b>{t('mngRegulations.futureForecastClass')}</b>
                  </h6>
              </div>
              <div className="col-8">
                          <Typeahead
                              style={{width : '47%'}}
                              id="basic-typeahead-single"
                              emptyLabel={t('noMatchFound')}
                              clearButton
                              labelKey="name"
                              selected={this.state.forecastClass}
                              onChange={this.handleForeCast}
                              options={[{id:1,name:"Secure"},{id:2,name:"Predictable"}]}
                              placeholder={t('mngRegulations.futureForecastClassPlaceholder')}
                          />
              </div>
            </div>
            <ul>
              {
                !this.state.isValLink && <li style={{'color':'red'}}>{t('mngRegulations.inValidLinkError')}</li>
              }
            </ul>
              {/* </div> */}
              <hr />
            <div className="form-group row">
              <div className="col-12" style={{'textAlignLast':'end'}}>
                <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
                <Button color="primary" onClick={this.handleSubmit.bind(this)}>{i18next.t('save')}</Button>
              </div>
            </div>
              </div>
        )}
      
      </Translation>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddActualRegForm);
