
let _navigator;
const setTopLevelNavigator = (navigatorRef) => {
    _navigator = navigatorRef;
};
const navigate = (routeName, params) => {
    _navigator.push(routeName, params);
};

const replace = (routeName, params) => {
    _navigator.replace(routeName, params);
};

// add other navigation functions that you need and export themexport default {  navigate,  setTopLevelNavigator,};
export default {
    navigate,
    replace,
    setTopLevelNavigator,
}
