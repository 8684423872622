import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import {InputGroup, InputGroupAddon} from 'reactstrap';
import './GRM.css';
import DownArrow from './../../chevron-sign-down.png';
import RightArrow from './../../chevron-sign-right.png';
import i18next from 'i18next';
import * as locale from 'date-fns/locale';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {countryLocale, typeOfDriveArr, forecastClassArr, languageArr} from './RegulationsColData';

interface Props extends Translate{
  rowData?: any;
  MarketList?: any;
  marketCode: string;
  formtype?: string;
  euivalentFlag?: any;
}

interface State{
  toggleIconEquivalent?: any;
  rowData?: any;
}

class EquivalentReg extends Component<Props, State> {
  constructor(props) {
    super(props)
      this.state = {
        toggleIconEquivalent: {},
        rowData: this.props.rowData
      }
  }
  componentDidMount(){
    let {toggleIconEquivalent} = this.state;
    this.props.MarketList && this.props.MarketList.map(item =>{
      toggleIconEquivalent[item.value] = false;
    });
    this.setState(toggleIconEquivalent);
  }

  //Equivalent reg arrow icon handler
  toggleIconEquivalent = (market, e) =>{
    e.preventDefault();
    let {toggleIconEquivalent} = this.state;
    toggleIconEquivalent[market] = !toggleIconEquivalent[market];
    this.setState(toggleIconEquivalent);
  }

  //input field onChange handler
  inputHandler = (id, lang, marketCode, objKey, event) =>{
    let data = {...this.state.rowData};
    if (objKey !== '') {
      data['regulations'][marketCode][lang][objKey][id] = event.target.value;
    }
    else{
      data['regulations'][marketCode][lang][id] = event.target.value;
    }
    this.setState({rowData: data});
    this.props.euivalentFlag(true)
  }

  render() {
    let {marketCode} = this.props;
    let {rowData} = this.state;
    return(
      <Translation>{t => (
        <Fragment>
        {
            <div className="form-inline col-sm-12">
              <div className="col-4 form-inline collapseRow">
                <div className="col-8"><h6><b>{i18next.t('mngRegulations.equivalentReg')}</b></h6></div>
                <div className="col-4">
                  <div id={'accordionEquivalentReg'+marketCode}>
                    <div id={'headingAccordionEquivalentReg'+marketCode}>
                      <h5 className="mb-0">
                        <button onClick={this.toggleIconEquivalent.bind(this, marketCode)} className="btn btn-link" data-toggle="collapse" data-target={'#collapseEquivalentRegCard'+marketCode} aria-expanded="true" aria-controls={'collapseEquivalentRegCard'+marketCode}>
                          {
                            this.state.toggleIconEquivalent[marketCode] ?
                            <img src={DownArrow} />
                            :
                            <img src={RightArrow} />
                          }
                        </button>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div id={'collapseEquivalentRegCard'+marketCode} className="card collapse col-sm-12 noPadding" aria-labelledby={'headingAccordionEquivalentReg'+marketCode} data-parent={'#accordionEquivalentReg'+marketCode}>
                <div className="card-body">
                  <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                    <div className="col-4"><h6><b>{i18next.t('mngRegulations.equivalentRegECE')}</b></h6></div>
                      {
                        [...languageArr].map(lang =>{
                          return(
                            <div className="col-4 labelTextCss">
                              <h6>
                                {
                                  this.props.formtype === 'edit' || this.props.formtype === 'confirm' ? <input title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].equivalentECERegulation} type="text" id={'equivalentRegECE'+marketCode} value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].equivalentECERegulation} onChange={this.inputHandler.bind(this, 'equivalentECERegulation', lang.code, marketCode, '')} className="form-control" placeholder={t('equivalentForm.inputRegNo')} />
                                  : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].equivalentECERegulation
                                }
                              </h6>
                            </div>
                          )})
                      }
                  </div>
                  <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                    <div className="col-4"><h6><b>{i18next.t('mngRegulations.equivalentRegEU27')}</b></h6></div>
                      {
                        [...languageArr].map(lang =>{
                          return(
                            <div className="col-4 labelTextCss">
                              <h6>
                                {
                                  this.props.formtype === 'edit' || this.props.formtype === 'confirm' ? <input title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].equivalentEU27Regulation} type="text" id={'equivalentRegEU27'+marketCode} value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].equivalentEU27Regulation} onChange={this.inputHandler.bind(this, 'equivalentEU27Regulation', lang.code, marketCode, '')} className="form-control" placeholder={t('equivalentForm.labelEU-27')} />
                                  : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].equivalentEU27Regulation
                                }
                              </h6>
                            </div>
                          )})
                      }
                  </div>
                  <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                    <div className="col-4"><h6><b>{i18next.t('mngRegulations.equivalentRegFMVSS')}</b></h6></div>
                      {
                        [...languageArr].map(lang =>{
                          return(
                            <div className="col-4 labelTextCss">
                              <h6>
                                {
                                  this.props.formtype === 'edit' || this.props.formtype === 'confirm' ? <input title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].equivalentFMVSSRegulation} type="text" id={'equivalentRegFMVSS'+marketCode} value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].equivalentFMVSSRegulation} onChange={this.inputHandler.bind(this, 'equivalentFMVSSRegulation', lang.code, marketCode, '')} className="form-control" placeholder={t('equivalentForm.labelFMVSS')} />
                                  : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].equivalentFMVSSRegulation
                                }
                              </h6>
                            </div>
                          )})
                      }
                  </div>
                  <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                    <div className="col-4"><h6><b>{i18next.t('mngRegulations.equivalentRegOthers')}</b></h6></div>
                      {
                        [...languageArr].map(lang =>{
                          return(
                            <div className="col-4 labelTextCss">
                              <h6>
                                {
                                  this.props.formtype === 'edit' || this.props.formtype === 'confirm' ? <input title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].equivalentRegulationOther} type="text" id={'equivalentRegOthers'+marketCode} value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].equivalentRegulationOther} onChange={this.inputHandler.bind(this, 'equivalentRegulationOther', lang.code, marketCode, '')} className="form-control" placeholder={t('equivalentForm.labelReg')}  />
                                  : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].equivalentRegulationOther
                                }
                              </h6>
                            </div>
                          )})
                      }
                  </div>
                  <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                    <div className="col-4"><h6><b>{i18next.t('mngRegulations.equivalentRegSource')}</b></h6></div>
                      {
                        [...languageArr].map(lang =>{
                          return(
                            <div className="col-4 labelTextCss">
                              <h6>
                                {
                                  this.props.formtype === 'edit' || this.props.formtype === 'confirm' ? <input title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].equivalentRegulationSource} type="text" id={'equivalentRegSource'+marketCode} value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].equivalentRegulationSource} onChange={this.inputHandler.bind(this, 'equivalentRegulationSource', lang.code, marketCode, '')} className="form-control" placeholder={t('equivalentForm.labelSource')} />
                                  : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].equivalentRegulationSource
                                }
                              </h6>
                            </div>
                          )})
                      }
                  </div>
                  <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                    <div className="col-4"><h6><b>{i18next.t('mngRegulations.equivalentRegComments')}</b></h6></div>
                      {
                        [...languageArr].map(lang =>{
                          return(
                            <div className="col-4 labelTextCss">
                              <h6>
                                {
                                  this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                  <textarea
                                  placeholder={i18next.t('mngRegulations.equivalentRegCommentsPlaceholder')}
                                  title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].equivalentRegulationComments}
                                  className="form-control"
                                  rows={3}
                                  id={'equivalentRegComments'+marketCode}
                                  value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                        rowData.regulations[marketCode][lang.code].equivalentRegulationComments}
                                  onChange={this.inputHandler.bind(this, 'equivalentRegulationComments', lang.code, marketCode, '')}
                                  name={'equivalentRegComments'+marketCode}>
                                </textarea>
                                : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].equivalentRegulationComments
                                }
                              </h6>
                            </div>
                          )})
                      }
                  </div>
                </div>
              </div>
            </div>}
        </Fragment>
      )}</Translation>
    )
  }
}
  const mapStateToProps = (appState: AppState) => ({
    MarketList: appState.admin.MarketList
  });

  const mapDispatchToProps = dispatch => ({
  });

  export default connect(mapStateToProps, mapDispatchToProps)(EquivalentReg);
