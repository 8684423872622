import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import Actions from '../../internal/modules/Actions';
import {Button} from 'reactstrap';
import $ from 'jquery';
import {languageArr} from './VehiclesColData';
import i18next from 'i18next';

interface Props extends Translate{
  rowData?: any,
  formtype?: any;
  eventHandler?: any;
  closeModal?: any;
  createVehicle: (any) => {};
  editVehicleList: (any) => {};
}

interface State{
  formData?: any;
  rowData?: any;
  errFieldMsg?: any;
}

class VehicleForm extends Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        rowData: this.props.rowData,
        errFieldMsg: {
          vehicleContaineren:false,
          vehicleContainerde:false
        }
      }
  }

  //check mandatory field are filled or not and display error message.
  validateField = (lang, event) =>{
    let {errFieldMsg} = this.state;
      if (event.target.type === 'text' && event.target.value.trim() === '') {
        event.target.value = '';
        errFieldMsg[event.target.id] = true;
      }
      else{
        errFieldMsg[event.target.id] = false;
      }
      this.setState(errFieldMsg);
  }

  //setting input field value of vehicle name on change.
  inputHandler = (id, lang, event) =>{
    let arr = Object.keys(this.state.rowData).length < 1 ? {data:{}} : {...this.state.rowData};
    if (!arr.data[lang]) {
      arr.data[lang] = {};
    }
    arr.data[lang][id] = event.target.value;
    this.setState({rowData: arr});
  }

  // call on form submit and create and edit vehicle api call.
  SaveFormHandler = () =>{
    let postArr = [];
    [...languageArr].map(lang =>{
      let obj = this.state.rowData.data[lang.code] !== undefined ? this.state.rowData.data[lang.code] : {"category":''};
      obj['languageCode'] = lang.code;
      obj['id'] = this.state.rowData.id;
      postArr.push(obj);
    });
    if (this.props.formtype === 'edit') {
      this.props.editVehicleList(postArr);
    }
    else{
      this.props.createVehicle(postArr);
    }
    this.props.closeModal();
  }

  render() {
    let {rowData, errFieldMsg} = this.state;
    return (
      <Translation>{t => (
        <Fragment>
          <form onSubmit={this.SaveFormHandler} id="vehicleForm">
            <div className="form-inline col-12">
              <div className="col-4"><h6 className="col-form-label"><b>{i18next.t('mngVehicles.attribute')}</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-4"><h6 className="col-form-label"><b>{i18next.t('language.'+lang.code)} *</b></h6></div>
                  )
                })
              }
            </div>
            <div className="form-inline col-12">
              <div className="col-4"><h6><b>{i18next.t('mngVehicles.vehicleCategory')} *</b></h6></div>
                {
                  [...languageArr].map(lang =>{
                    return(
                      <div className="col-4 labelTextCss">
                        <h6 className="vehicleContainer">
                          <input onBlur={this.validateField.bind(this, lang.code)} title={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].category } type="text" id={'vehicleContainer'+lang.code} value={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].category} onChange={this.inputHandler.bind(this, 'category', lang.code)} className={errFieldMsg['vehicleContainer'+lang.code] ? "errFieldCss form-control" : "form-control"} placeholder={t('vehicleForm.inputvehicle')} required/>

                        </h6>
                      </div>
                    )
                  })
                }
            </div>
            <hr />
            <div className="form-group row">
              <div className="col-12" style={{'textAlignLast':'end'}}>
              <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
              <Button color="primary" type="submit">{i18next.t('save')}</Button>
              </div>
            </div>
        </form>
          </Fragment>
      )}</Translation>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({

});

const mapDispatchToProps = dispatch => ({
  createVehicle: (vehicle) => Actions.admin.createVehicle(dispatch, vehicle),
  editVehicleList: (vehicle) => Actions.admin.editVehicleList(dispatch, vehicle)
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleForm);
