import React, { Fragment, Component, useState } from 'react';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { connect } from 'react-redux';
import {Button, Form, FormGroup, Label, Input , Row} from 'reactstrap';
import Hamburger from 'react-hamburgers';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Drawer from 'react-motion-drawer';
import ReactTooltip from "react-tooltip";
import Actions from '../../internal/modules/Actions';
import { Redirect, withRouter } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import MapComponent from "./../MapComp/Map";
import AdvancedFilterComp from "./../AdvancedFilterComp";
import TimelineMatrixComp from "./../TimelineMatrixComp";
import Tabs, {TabPane} from 'rc-tabs';
import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import PageTitle from '../../Layout/AppMain/PageTitle';
import FavoritesListComp from '../FavoritesComp/favoritesList';
import ReportComp from './../ReportComp';
import { AppState } from '../../internal/modules/Reducers';
import './dashboard.css';
import {toast} from 'react-toastify';
import i18next from 'i18next';
import SubscribeComp from '../Subscribe/SubscribeComp';

interface Props extends Translate{
  getRegulationCount: () => {};
  getTopicList: () => {};
  getMarketList: () => {};
  getVehicleList: () => {};
  getFavoritesList : (any) => {};
  getTabChange : (any) => {};
  getSearchTagList: () => {};
  getGroupList:() =>{};
  getVehicleFuelTypeList: () =>{};
  getDriveTypeList: () =>{};
  activeKey : string;
  filterSaved: boolean;
  logout: () => {};
  getScopeList: () => {};
  history?: any;
  location?: any;
  getSubscibedTopics : () => {};
  editUserList: (any) => {};
  getUserById: (id: number) => {};
}

interface State{
  user: {},
  active: boolean,
  openLeft: boolean,
  openRight: boolean,
  relativeWidth: boolean,
  width: number,
  noTouchOpen: boolean,
  noTouchClose: boolean,
  activeKey: string,
  value?: any,
  regulationFilterList ?: any,
  errorMessage: string,
  success: string,
  searchUrlFlag : boolean
}

class UserDashboard extends Component<Props, State> {

    constructor(props) {
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            active: false,
            openLeft: false,
            openRight: false,
            relativeWidth: false,
            width: 450,
            noTouchOpen: false,
            noTouchClose: false,
            activeKey: "",
            value: '',
            regulationFilterList: {},
            errorMessage: null,
            success: null,
            searchUrlFlag : false
        }
    }

    //call on change of tabs and set active tab key
    onChange = (activeKey) => {
      if(this.props.location.pathname.includes('/GRM')){
        this.props.history.push({
          pathname : `/app/dashboard/${activeKey}/GRM`,
          search : this.props.location.search
        })
      }
      else{
        this.props.history.push({
          pathname : `/app/dashboard/${activeKey}`
        })
      }
     
      this.setState({
        activeKey
      });
  
      let tabName = i18next.t(`dashboard.tabTitle${activeKey}`)
      const dateUTC = new Date().toISOString();
      let obj = {
        "recentTab": activeKey,
        "recentSearch": localStorage.getItem('recentSearch'),
        "lastLogin": localStorage.getItem('lastLogin')
      }
    this.props.editUserList(obj)

      this.props.getTabChange(activeKey)
      if (activeKey === "3") {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user != null && user.id) {
          this.props.getFavoritesList(user.id);
        }
        else{
          this.props.logout();
        }
      }
    }

    componentDidMount() {
        //Calling all the api's after component is mounted
        this.props.getRegulationCount();
        this.props.getTopicList();
        this.props.getMarketList();
        this.props.getVehicleList();
        this.props.getSearchTagList();
        this.props.getGroupList();
        this.props.getVehicleFuelTypeList();
        this.props.getDriveTypeList();
        let userID = JSON.parse(localStorage.getItem('user')).id
        this.props.getFavoritesList(userID);
        this.props.getScopeList();
        if(this.props.location?.state?.data !== undefined){
            this.setState({searchUrlFlag: true})
        }
        this.props.getSubscibedTopics();
        let user = JSON.parse(localStorage.getItem("user"));
        this.props.getUserById(user.id);
    }

    static getDerivedStateFromProps(newState, prevState) {
       return {
          success: newState.success,
          errorMessage: newState.errorMessage
        };
      }

      componentDidUpdate(prevProps, prevState){
        if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
            toast['error'](i18next.t(this.state.errorMessage), { position: 'top-right'});
        }
        if (this.props.filterSaved && prevProps.filterSaved !== this.props.filterSaved) {
            toast['success'](i18next.t('favorites.saved'), { position: 'top-right' });
        }
        if(this.props.location !== prevProps.location){
          if(this.props.location?.state?.data === 'searchFilter'){
            this.setState({searchUrlFlag: true})
          }
          else{
            this.setState({searchUrlFlag: false})
          }
        }
      }
      
   render() {
    var result= window.location.href.split('/');
    var Param = result[result.length-2];
    const activekey = this.props.location.pathname.includes('/GRM') ? Param :  window.location.href.substring(window.location.href.lastIndexOf('/')+1)
   
    if(activekey == 'dashboard'){
      localStorage.setItem('tab','1')
    }
      const {
            openRight,
        } = this.state;
        return (

          <Translation>{t => (
            <div id={`activeTab${activekey == 'dashboard' ? '1' : activekey}`} >
              {/*<PageTitle
              {...this.props}
              heading={t('dashboard.pageHeading')}
              subheading={t('dashboard.pageSubHeading')}
              />*/}
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Tabs
                        defaultActiveKey={activekey === 'dashboard' ? "1" : activekey}
                        renderTabBar={() => <ScrollableInkTabBar/>}
                        renderTabContent={() => <TabContent/>}
                        activeKey={activekey === 'dashboard' ? "1" : activekey}
                        onChange={this.onChange}
                    >
                        {/* User dashboard tabs */}
                        <TabPane tab={t('dashboard.tabTitle1')} key="1"> <MapComponent {...this.props} /> </TabPane>
                        <TabPane tab={t('dashboard.tabTitle2')} key="2"> <AdvancedFilterComp searchFilter = {this.state.searchUrlFlag}{...this.props}/> </TabPane>
                        <TabPane tab={t('dashboard.tabTitle3')} key="3"> <FavoritesListComp multiple={false} url={''} {...this.props}/> </TabPane>
                        <TabPane tab={t('dashboard.tabTitle4')} key="4"> <ReportComp {...this.props}/> </TabPane>
                        <TabPane tab={t('dashboard.tabTitle5')} key="5"> <TimelineMatrixComp {...this.props}/> </TabPane>
                        <TabPane tab={t('dashboard.tabTitle6')} key="6"> <SubscribeComp  {...this.props} /> </TabPane>
                    </Tabs>

                </ReactCSSTransitionGroup></div>)}</Translation>

        );
    }
}

const mapStateToProps = (appState: AppState) => ({
  activeKey : appState.user.activeKey,
  errorMessage: appState.user.saveFilterRequest.$errorMessage,

  success: appState.user.saveFilterRequest.$success,
  filterSaved: appState.user.filterSaved

});

const mapDispatchToProps = dispatch => ({
    getRegulationCount: () => Actions.user.getRegulationCount(dispatch),
    getTopicList: () => Actions.user.getTopicList(dispatch),
    getVehicleList: () => Actions.user.getVehicleList(dispatch),
    getMarketList: () => Actions.user.getMarketList(dispatch),
    getFavoritesList: (user) => Actions.user.getFavoritesList(dispatch,user),
    getTabChange: (tab) =>Actions.user.getTabChange(tab,dispatch),
    getSearchTagList: () => Actions.user.getSearchTagList(dispatch),
    getGroupList: () => Actions.user.getGroupList(dispatch),
    getVehicleFuelTypeList: () => Actions.user.getVehicleFuelTypeList(dispatch),
    getDriveTypeList: () => Actions.user.getDriveTypeList(dispatch),
    logout: () => Actions.user.logout(dispatch),
    getScopeList: () => Actions.user.getScopeList(dispatch),
    getSubscibedTopics : () => Actions.user.getSubscibedTopics(dispatch),
    editUserList: (user) => Actions.user.editUserList(dispatch, user),
    getUserById: (userId) => Actions.user.getUserById(userId, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
