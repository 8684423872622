import React, { Fragment} from 'react';
import * as ReactDOM from "react-dom";
import { Translate } from './../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import Actions from './../../internal/modules/Actions';
import { AppState } from './../../internal/modules/Reducers';
import { withRouter } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    Button, Form,
    FormGroup, Label,
    Input, FormText,
    Row, Col, CustomInput,
    Card, CardBody, InputGroup, InputGroupAddon,
    CardTitle, Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { connect } from 'react-redux';
import TranslationService from '../../utils/TranslationService';
import {TIMELINE_PDF} from './../../utils/Constants';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import DatePicker from 'react-datepicker';
import * as locale from 'date-fns/locale';
import {countryLocale} from './../ManageRegulations/RegulationsColData';
import './style.css';
//import PrintIcon from 'react-ionicons/lib/IosPrintOutline';
import ReactTooltip from 'react-tooltip';
import ViewIcon from './../../file-viewer.png';
import PrintIcon from './../../printer.png';
import EditModalComp from './../Table/EditModal';
import {FileExportPdf} from './../Table/FileExport';
import { GanttComponent, Inject, ColumnsDirective, ColumnDirective, Resize  } from '@syncfusion/ej2-react-gantt';
import moment from 'moment';
import $ from 'jquery';
import {toast} from 'react-toastify';
import RegDetailsTableComp from './../Table/RegDetailsTable';
import RegDetails from '../Table/RegDetails';
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';
import { format } from 'date-fns';

var qs = require('qs');


const t = TranslationService.t;

interface Props extends Translate{
  MarketList?: any;
  activeKey?: any;
  getTimelineData: (any) => {};
  timelineDataFetched: boolean;
  TimelineData?: any;
  logout: () => {};
  getRegulationDetailsById: (id: number) =>{};
  regulationIdFetched: boolean;
  RegulationIdList ?: any;
  isLoading:() => {};
  finishLoading:() => {};
  loading: boolean;
  getReferenceRegulationDetails: (regulationNumber) => {};
  referenceRegFetched: boolean;
  RefRegulationDetailsList?: any;
}

interface State{
  isOpenMarket: boolean;
  marketSelected: string;
  fromDateSelected: string;
  toDateSelected: string;
  marketErr: string;
  dateErr: string;
  timelinePdf: boolean;
  regulationID: number;
  TimelineData?: any;
  marketValue: string;
  marketID: number;
  TimelineDataRowCount: number;
  regulationIdErrMsg: string;
  showDetailRegulation: boolean;
  rowData ?: any;
}
class ReportComp extends React.Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        regulationID: 1,
        isOpenMarket: false,
        marketSelected: '',
        fromDateSelected: null,
        toDateSelected: null,
        marketErr: '',
        dateErr: '',
        timelinePdf:false,
        TimelineData:[],
        marketValue:'',
        marketID:null,
        TimelineDataRowCount:0,
        regulationIdErrMsg:null,
        showDetailRegulation: false,
        rowData : []
      }
      this.toggleMarket = this.toggleMarket.bind(this);
    }

    static getDerivedStateFromProps(newState, prevState) {
       return {
          regulationIdErrMsg: newState.regulationIdErrMsg
        };
      }

    componentDidUpdate(prevProps, prevState){
      // if (localStorage.getItem('tab') == '4' && this.props.regulationIdFetched && prevProps.regulationIdFetched !== this.props.regulationIdFetched) {
      //   this.setState({showDetailRegulation: true, rowData: this.props.RegulationIdList});
      // }
      if (this.props.referenceRegFetched && prevProps.referenceRegFetched !== this.props.referenceRegFetched) {
        if (this.props.RefRegulationDetailsList.id !== null) {
          this.setState({showDetailRegulation: true,rowData: this.props.RefRegulationDetailsList});
        }
      }
      if(this.state.regulationIdErrMsg && this.state.regulationIdErrMsg !== prevState.regulationIdErrMsg){
        const t = TranslationService.t;
        toast['error'](t(this.state.regulationIdErrMsg), { position: 'top-right' });
      }
      //Resetting state value after tab change
      if (prevProps.activeKey !== this.props.activeKey) {
        this.setState({
         regulationID: 1, isOpenMarket: false, marketSelected: '', fromDateSelected: null, toDateSelected: null,
          marketErr: '', dateErr: ''});
      }
      if (this.props.TimelineData && prevProps.TimelineData !== this.props.TimelineData) {
        let dataArr = [];
        let rowIndex = 0;
        /* If the date is missing from the api, so inorder to prevent the bar to plot we provide date
        which is out of range of the selected time period, so that it won't plot. Because if we don't
        provide any date then it takes its default date an shows vertical bar at the start of timeline. */
        let excludeDate = moment(this.state.fromDateSelected, 'MM/DD/YYYY').subtract(7,'d').format('MM/DD/YYYY');

        [...this.props.TimelineData].map((item, index) =>{
          //obj1 is the actual regulation bar holding data
          let obj1 = item;
          //this.state.regulationID == 1 => actual, 2 => future, 3 => both
          // if (this.state.regulationID === 3) {
          //   //obj2 is the future regulation bar holding data
          //   let obj2 = item.futureRegulation[0] ? item.futureRegulation[0] : {};
          //   obj2['rowNo'] = Math.floor((Math.random() * 1000000) + 1);
          //   obj2['rowType'] = 'future';
          //   obj1['regulationID'] = obj1['id'];
          //   obj2['regulationID'] = obj1['id'];
          //   //If actual and future both have regulation number and startDate
          //   if (item.regulationNumber && item.startDate && item.futureRegulation[0] &&
          //      item.futureRegulation[0].regulationNumber && item.futureRegulation[0].startDate) {
          //     rowIndex++;
          //     obj1['rowNo'] = rowIndex;
          //     dataArr.push(obj1, obj2);
          //   }
          //   else if(item.regulationNumber && item.startDate){
          //     /* If only actual has regulation number and startDate, then assigning excludeDate to future
          //     regulation(obj2) to hide bar */
          //     rowIndex++;
          //     obj1['rowNo'] = rowIndex;
          //     obj2['startDate'] = excludeDate;
          //     obj2['endDate'] = excludeDate;
          //     obj2['revision'] = null;
          //     obj2['amendment'] = null;
          //     dataArr.push(obj1,obj2);
          //   }
          //   else{
          //     /* If only future has regulation number and startDate, then assigning excludeDate to actual
          //     regulation(obj1) to hide bar */
          //     rowIndex++;
          //     obj1['rowNo'] = rowIndex;
          //     obj1['startDate'] = excludeDate;
          //     obj1['endDate'] = excludeDate;
          //     obj1['revision'] = null;
          //     obj1['amendment'] = null;
          //     dataArr.push(obj1,obj2);
          //   }
          // }
            if (item.regulationNumber && item.startDate) {
              rowIndex++;
              obj1['rowNo'] = rowIndex;
              // obj1['regulationID'] = obj1['id'];
              dataArr.push(obj1);
            }
        });
        if (dataArr.length !== 0) {
          this.setState({TimelineData: dataArr, timelinePdf: true, TimelineDataRowCount:rowIndex});
        }
        else{
          toast['info'](i18next.t('timeline.noDataMsg'), { position: 'top-right' });
        }
      }
    }

    public taskFields: any = {
      id: 'rowNo',
      name: 'regulationNumber',
      forecastClass: 'futureForecastClass',
      startDate: 'startDate',
      endDate: 'endDate',
      progress: 'progress',
      TaskDesc: 'shortDescription',
      toolTipEnforcementDate: 'toolTipEnforcementDate',
      rowType: 'rowType'
    };

    // public labelSettings: any = {
    //   leftLabel: '',
    //   rightLabel: ''
    // };

    //open and close market dropdown
    toggleMarket() {
        this.setState({
            isOpenMarket: !this.state.isOpenMarket
        });
    }

    //display market in dropdown.
    marketHandler = (data) =>{
      this.setState({marketSelected: data.label, marketValue: data.value, marketID:data.id, marketErr:''});
    }

    //setting from date field.
    fromDateChange = date =>{
      if(date == null){
        this.setState({dateErr: 'reports.dateErr'});
      }
      else if(this.state.toDateSelected !== '' && moment(this.state.toDateSelected).year() < moment(date).year()){
        this.setState({dateErr: 'reports.minDateErr'});
      }
      else{
        this.setState({dateErr:''});
      }
      this.setState({fromDateSelected: date});
    }

    //setting to date field.
    toDateChange = date =>{
      if(date == null){
        this.setState({dateErr: 'reports.dateErr'});
      }
      else if(this.state.fromDateSelected !== '' && moment(this.state.fromDateSelected).year() > moment(date).year()){
        this.setState({dateErr: 'reports.minDateErr'});
      }
      else{
        this.setState({dateErr:''});
      }
      this.setState({toDateSelected: date});
    }

    //Generate timeline report PDF
    handlePrint = () =>{
      let {regulationID, marketSelected, fromDateSelected, toDateSelected} = this.state;
      let selectedData = {
        id: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).id,
        market: marketSelected,
        enforcementDateFrom: moment(fromDateSelected).year(),
        enforcementDateTo: moment(toDateSelected).year()
      }
      let params = '?ln='+localStorage.getItem('i18nextLng')+'&timeZone='+Intl.DateTimeFormat().resolvedOptions().timeZone+'&'+
      qs.stringify(selectedData, {arrayFormat: 'repeat'});

      let apiUrl = process.env.REACT_APP_BASE_URL+'/'+TIMELINE_PDF+params;
      let timelineFilename = i18next.t('timelineFilename')+'_';
      timelineFilename += i18next.t('timeline.actual');
      timelineFilename += '_'+this.state.marketSelected;
      timelineFilename += '_'+moment(fromDateSelected).year()+'-'+moment(toDateSelected).year();
      let generatedPdf = FileExportPdf(apiUrl, timelineFilename.toUpperCase(), this.props.logout,this.props.isLoading,this.props.finishLoading);
    }

    //Fetch timeline report data api call
    handleView = () =>{
      let errFlag = false;
      if (this.state.marketSelected === '') {
        errFlag = true;
        this.setState({marketErr: 'reports.marketErr'});
      }
      if (this.state.fromDateSelected === '' || this.state.toDateSelected === '' || this.state.fromDateSelected === null || this.state.toDateSelected === null) {
        errFlag = true;
        this.setState({dateErr: 'reports.dateErr'});
      }

      if (!errFlag) {
        //Report timeline request object passed in api
        let selectedData = {
          ln: localStorage.getItem('i18nextLng'),
          market: this.state.marketSelected,
          enforcementDateFrom: moment(this.state.fromDateSelected).year(),
          enforcementDateTo: moment(this.state.toDateSelected).year()
        }
        this.props.getTimelineData(selectedData);
      }
    }

    //close timeline report modal
    closeModal = () =>{
      this.setState({timelinePdf: false});
    }

    //close regulation number detail view modal
    closeModalRegDetail = () =>{
      this.setState({showDetailRegulation: false});
    }

    //Timeline header settings
    public timelineSettings: any = {
      topTier: {
        unit: 'Year',
        format: 'yyyy',
      },
      bottomTier: {
        unit: 'Month',
        formatter: (date) => {
          let month = date.getMonth();
          if (month == 0) {
            return 'Q1';
          }
          else if(month == 3){
            return 'Q2';
          }
          else if(month == 6){
            return 'Q3';
          }
          else if(month == 9){
            return 'Q4';
          }
          else{
            return '';
          }
        },
        count: 3  //clubbing the bottom tier unit. Since the unit is month and the count is 3, clubbing 3 months as single column.
      }
    };

    /*Splitter Settings specifies the horizontal splitter initial position.
      =>columnIndex: 2 implies the splitter after column 2.*/
    public splitterSettings: any = {
        columnIndex: 2
    };

    public templateTaskbar: any = this.taskbarTooltip;
    //Custom timeline bar tootltip template
    public taskbarTooltip(props) {   
      let enfDate = new Date(props.taskData.toolTipEnforcementDate) 
      var formattedEnfDate = format(enfDate, "dd/MM/yyyy");
      let expDate = new Date(props.taskData.expiryDate) 
      var formattedExpDate = format(expDate, "dd/MM/yyyy");
      return(
        <table style={{ width: '100%'}}>
          <tr>
            <td style={{ padding: '3px', width: '50%' }}>{i18next.t('timeline.startDate')}:</td>
            <td style={{ padding: '3px' }}>{formattedEnfDate}</td>
          </tr>
          <tr>
            <td style={{ padding: '3px', width: '50%' }}>{i18next.t('timeline.endDate')}:</td>
            <td style={{ padding: '3px' }}>{props.taskData.expiryDate ? formattedExpDate : i18next.t('timeline.expiryNotDefined')}</td>
          </tr>
          <tr>
            <td style={{ padding: '3px', width: '50%' }}>{i18next.t('timeline.regulationNumber')}:</td>
            <td style={{ padding: '3px' }}>{props.regulationNumber}</td>
          </tr>
          {/* <tr>
            <td style={{ padding: '3px', width: '50%' }}>{i18next.t('timeline.topic')}:</td>
            <td style={{ padding: '3px' }}>{props.topicName}</td>
          </tr>
          <tr>
            <td style={{ padding: '3px', width: '50%' }}>{i18next.t('timeline.topicType')}:</td>
            <td style={{ padding: '3px' }}>{props.topicType}</td>
          </tr> */}
          <tr>
            <td style={{ padding: '3px', width: '40%'}}>{i18next.t('Revision')}:</td>
            <td style={{ padding: '3px' }}>{props.name}</td>
          </tr>
          <tr>
            <td style={{ padding: '3px', width: '50%'}}>{i18next.t('advancedFilters.series')}:</td>
            <td style={{ padding: '3px' }}>{props.taskData.series}</td>
          </tr>
          <tr>
              <td style={{ padding: '3px', width: '50%'}}>{i18next.t('timeline.forecastClass')}:</td>
              <td style={{ padding: '3px' }}>{props.taskData.futureForecastClass}</td>
            </tr>
          {/* Hiding forecast class for actual regulations on tooltip */}
          {/* {this.state.regulationID === 2 &&
            <tr>
              <td style={{ padding: '3px', width: '50%'}}>{i18next.t('timeline.forecastClass')}:</td>
              <td style={{ padding: '3px' }}>{props.futureForecastClass}</td>
            </tr>
          }
          {this.state.regulationID === 3 && props.taskData.rowType &&
            <tr>
              <td style={{ padding: '3px', width: '50%'}}>{i18next.t('timeline.forecastClass')}:</td>
              <td style={{ padding: '3px' }}>{props.taskData.futureForecastClass}</td>
            </tr>
          } */}
      </table>);
    };

    public tooltipSettings: any = {
      showTooltip: true,
      taskbar: this.templateTaskbar.bind(this)
    };

    //Applying topic type cell header color
    customizeCell(args) {
        if (args.column.field == "topicName") {
          if (args.data.topicType === 'System')
              args.cell.style.color = "#212529";
          else
              args.cell.style.color = "#58cd58";
        }
    }

    //Regulation number onClick api call handler
    regulationClick = (taskData) =>{
       this.props.getReferenceRegulationDetails(taskData.regulationNumber);
      //this.props.getRegulationDetailsById(taskData.regulationID);
    }

    //Timeline report component plotting bar and column creation
    ganttChartTemplate() {
      const spinner4 = <LoaderAnim active type="ball-beat"/>;

      return(
        <Loader message={spinner4} show={this.props.loading}>
        <div id={`timelineType`+this.state.regulationID}>
          <GanttComponent id='Editing' dataSource={this.state.TimelineData}
              queryCellInfo={this.customizeCell.bind(this)}
              includeWeekend={true}
              allowResizing={true}
              projectStartDate={moment(this.state.fromDateSelected).startOf('year').format('MM/DD/YYYY')}
              projectEndDate={moment(this.state.toDateSelected).endOf('year').format('MM/DD/YYYY')}
              gridLines={'Both'}
              taskFields={this.taskFields}
              timelineSettings={this.timelineSettings}
              tooltipSettings={this.tooltipSettings}
              // labelSettings={this.state.regulationID === 3 && this.labelSettings}
              height='410px'
              splitterSettings={this.splitterSettings}
              >
                {/* Timeline report columns configuring */}
                <ColumnsDirective>
                  <ColumnDirective field='rowNo' width='70' clipMode='EllipsisWithTooltip' headerText={i18next.t('id')}></ColumnDirective>
                  <ColumnDirective template={(row) => {
                    return (
                      <a href="javascript:void(0);" onClick={() => this.regulationClick(row.taskData)}>{row.regulationNumber}</a>
                    )}} field='regulationNumber' width='400' allowResizing={true} clipMode='EllipsisWithTooltip' headerText={i18next.t('timeline.regulationNumber')}></ColumnDirective>
                  {/* <ColumnDirective headerTemplate={() => {
                    return (
                      <div id="topicTypeHeaderColor"><span className="e-headertext">{i18next.t('timeline.topic')}</span><p><span className="system">{i18next.t('System')}</span> / <span className="component">{i18next.t('Component')}</span></p></div>);
                  }} field='topicName' clipMode='EllipsisWithTooltip' width='250' allowResizing={true}></ColumnDirective> */}
                  {/* <ColumnDirective field='topicType' visible={false} headerText={i18next.t('timeline.topicType')}></ColumnDirective>
                  <ColumnDirective field='toolTipEnforcementDate' visible={false} headerText={i18next.t('timeline.startDate')}></ColumnDirective>
                  <ColumnDirective field='startDate' visible={false} headerText={i18next.t('timeline.startDate')}></ColumnDirective>
                  <ColumnDirective field='endDate' visible={false} headerText={i18next.t('timeline.endDate')}></ColumnDirective> */}
                 {this.state.marketValue !== 'EU' &&
                  <ColumnDirective 
                  template={(row) => {
                    return (
                      <div style={{display: 'contents'}} > 
                      {row.taskData.link !== '' ? 
                      <div style={{display: 'contents'}}>
                      <a style={{cursor: 'pointer', color: '#545cd8',textDecoration:'underline'}} data-tip={row.shortDescription} target="_blank" href={row.taskData.link} data-for="fileName">{row.name}</a>
                      </div> : 
                      <div style={{display: 'contents'}}>{row.name}</div>}</div>
                    )}} field='name' width='250'  headerText={i18next.t('advancedFilters.revName')}></ColumnDirective>}
                  {/* <ColumnDirective field='amendment' width='150' clipMode='EllipsisWithTooltip' headerText={i18next.t('Amendment')}></ColumnDirective> */}
                </ColumnsDirective>
              <Inject services={[Resize]} />
          </GanttComponent>
        </div>
        </Loader>
      )
    }

    render() {
      const spinner4 = <LoaderAnim active type="ball-beat"/>;
      let timelineHeaderData = {
        marketSelected: this.state.marketSelected,
        marketID: this.state.marketID,
        marketValue: this.state.marketValue,
        fromDateSelected: moment(this.state.fromDateSelected).year(),
        toDateSelected: moment(this.state.toDateSelected).year(),
        // regulationID: this.state.regulationID,
        TimelineDataRowCount:this.state.TimelineDataRowCount
      }
      let ganttChart = this.ganttChartTemplate();
      return (
          <Translation>{t => (
            <Fragment>
              <ReactCSSTransitionGroup
                  component="div"
                  transitionName="TabsAnimation"
                  transitionAppear={true}
                  transitionAppearTimeout={0}
                  transitionEnter={false}
                  transitionLeave={false}>
                  <Loader message={spinner4} show={this.props.loading}>
                  <Form id="reportsForm">
                  {this.state.timelinePdf && <EditModalComp modalID="reportsFormModal" timelineHeaderData={timelineHeaderData} printTimeline={this.handlePrint} closeModal={this.closeModal} modalBody={ganttChart} modalTitle={i18next.t('reports.printTimelineLabel')} />}
                  {this.state.showDetailRegulation && <EditModalComp modalID="regulationDetails" closeModal={this.closeModalRegDetail} modalBody={<RegDetails  rowData={this.state.rowData} market={this.state.rowData?.market?.value}  flag={true}/>} modalTitle={i18next.t('timeline.actualRegDataModalTitle')} />}
                    <Row>
                      <Col md="12">
                        <div className="col-12">
                          <b>{i18next.t('reports.printTimelineLabel')}</b>
                        </div>
                        <Card className="main-card mb-3">
                          <CardBody>
                            <Form>
                            <div className="col-12">
                                {/* <Row className="col-12">
                                  <CustomInput
                                    checked={this.state.regulationID === 1}
                                    onChange={e => this.setState({ regulationType: i18next.t('advancedFilters.actualReg'), regulationID: 1})}
                                    className="reportRadioBtn1"
                                    type="radio" id="exampleCustomRadio" name="customRadio"
                                    label={i18next.t('reports.actualReg')}/>

                                  <CustomInput
                                    checked={this.state.regulationID === 2}
                                    onChange={e => this.setState({ regulationType: i18next.t('advancedFilters.futureReg'), regulationID:2})}
                                    className="reportRadioBtn2"
                                    type="radio" id="exampleCustomRadio2" name="customRadio"
                                    label={i18next.t('reports.futureReg')}/>

                                  <CustomInput
                                    checked={this.state.regulationID === 3}
                                    onChange={e => this.setState({ regulationType: i18next.t('advancedFilters.actualFutureReg'), regulationID: 3})}
                                    className="reportRadioBtn3"
                                    type="radio" id="exampleCustomRadio3" name="customRadio"
                                    label={i18next.t('reports.actualFutureReg')} />
                                </Row> */}

                                <Row className="col-12 marketDropdown" style={{'paddingTop':'0.5rem'}}>
                                  <FormGroup className="form-inline col-12" style={{'padding':0, 'margin':0}}>
                                    <div className="reportDropdownLabel1">{i18next.t('reports.selectMkt')} *</div>
                                    <Dropdown className="reportDropdown1" isOpen={this.state.isOpenMarket} toggle={this.toggleMarket}>
                                      <DropdownToggle caret>
                                        {this.state.marketSelected.toUpperCase()}
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {
                                          this.props.MarketList && [...this.props.MarketList].map((item) => {
                                            return (
                                              <DropdownItem onClick={() =>this.marketHandler(item)}>{item.label.toUpperCase()}</DropdownItem>
                                            );
                                          })
                                        }
                                      </DropdownMenu>
                                    </Dropdown>
                                  </FormGroup>
                                </Row>
                                {this.state.marketErr !== '' && <span className="errCss">{i18next.t(this.state.marketErr)}</span>}

                                <Row className="col-12 yearSelect" style={{'paddingTop':'2rem'}}>
                                  <FormGroup className="form-inline col-12" style={{'padding':0, 'margin':0}}>
                                    <div className="reportDropdownLabel2">
                                      {i18next.t('reports.selectDate')} *
                                      <p><i>( YYYY )</i></p>
                                    </div>
                                    <div className="reportDropdown2" style={{'display':'flex'}}>
                                      <div className="reportDatepicker1">
                                        <h6>{i18next.t('reports.fromDateLabel')} *</h6>
                                        <InputGroup>
                                          <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                              <FontAwesomeIcon style={{cursor:'default'}} icon={faCalendarAlt}/>
                                            </div>
                                          </InputGroupAddon>
                                          <DatePicker
                                            required
                                            className="form-control"
                                            selected={this.state.fromDateSelected}
                                            showYearPicker
                                            isClearable
                                            dateFormat="yyyy"
                                            locale={locale[countryLocale[localStorage.getItem('i18nextLng')]]}
                                            onChange={this.fromDateChange}
                                          />
                                        </InputGroup>
                                      </div>
                                      <div className="reportDatepicker2">
                                        <h6>{i18next.t('reports.toDateLabel')} *</h6>
                                        <InputGroup>
                                          <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                              <FontAwesomeIcon style={{cursor:'default'}} icon={faCalendarAlt}/>
                                            </div>
                                          </InputGroupAddon>
                                          <DatePicker
                                            required
                                            isClearable
                                            className="form-control"
                                            selected={this.state.toDateSelected}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            locale={locale[countryLocale[localStorage.getItem('i18nextLng')]]}
                                            onChange={this.toDateChange}
                                          />
                                        </InputGroup>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </Row>
                                {this.state.dateErr !== '' && <span className="errCss">{i18next.t(this.state.dateErr)}</span>}

                                <Row className="offset-2 col-10" id="timelineIcon" style={{'paddingTop':'2rem'}}>
                                  <a data-tip={t('reports.viewTimelineTooltip')} data-for={'viewTimeline'}>
                                    {/*<PrintIcon style={{'cursor':'pointer'}} onClick={this.handlePrint} fontSize="30px" icon="md-notifications-outline"/>*/}
                                    <img src={ViewIcon} onClick={this.handleView}/>
                                  </a>
                                  {/*<a data-tip={t('reports.printTimelineTooltip')} data-for={'printTimeline'}>
                                    <PrintIcon style={{'cursor':'pointer'}} onClick={this.handlePrint} fontSize="30px" icon="md-notifications-outline"/>
                                    <img src={PrintIcon} onClick={this.handlePrint}/>
                                  </a>*/}
                                  {/*<ReactTooltip id={'printTimeline'} place="bottom" type="info" effect="float"/>*/}
                                  <ReactTooltip id={'viewTimeline'} place="bottom" type="info" effect="float"/>
                                </Row>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Form>
                  </Loader>
                </ReactCSSTransitionGroup>
            </Fragment>
          )}</Translation>
        )
    }
  }

  const mapStateToProps = (appState: AppState) => ({
    MarketList: appState.user.MarketList,
    activeKey : appState.user.activeKey,
    timelineDataFetched: appState.user.timelineDataFetched,
    TimelineData: appState.user.TimelineData,
    regulationIdFetched: appState.user.regulationIdFetched,
    regulationIdErrMsg: appState.user.RegulationIdListRequest.$errorMessage,
    RegulationIdList : appState.user.RegulationIdList,
    loading: appState.loading.loading,
    referenceRegFetched: appState.user.referenceRegFetched,
    RefRegulationDetailsList: appState.user.RefRegulationDetailsList,
  });

  const mapDispatchToProps = dispatch => ({
    getTimelineData: (timeline) => Actions.user.getTimelineData(timeline, dispatch),
    logout: () => Actions.user.logout(dispatch),
    getRegulationDetailsById: (id) => Actions.user.getRegulationDetailsById(id, dispatch),
    isLoading:() => Actions.user.isLoading(dispatch),
    finishLoading:() => Actions.user.finishLoading(dispatch),
    getReferenceRegulationDetails: (regulationNumber) =>Actions.user.getReferenceRegulationDetails(regulationNumber, dispatch)
  });

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReportComp));
