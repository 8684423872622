import request from './request';
import * as Constants from '../../utils/Constants';
// import UserAPI from '../../network/UserAPI'
// import AsyncStorage from '@react-native-community/async-storage'

function login(user) {
    return request({
        url: Constants.LOGIN,
        method: 'POST',
        data: user
    }, false)
};

function getUserList() {
    return request({
        url: Constants.USERS_LIST_ADMIN,
        method: 'GET',
    }, false)
};

function getRegulationDetails(filter){
    return request({
        url: Constants.REGULATION_LIST_USER,
        method: 'GET',
        params: filter, ln:localStorage.getItem('i18nextLng')
    }, false)
};

function getGroupList(){
    return request({
        url: Constants.GROUP_LIST_USER,
        method: 'GET',
        params:  { ln: localStorage.getItem('i18nextLng') }
    }, false)
};

function getVehicleFuelTypeList(){
    return request({
        url: Constants.VEHICLE_FUEL_TYPE_LIST_USER,
        method: 'GET',
        params:  { ln: localStorage.getItem('i18nextLng') }
    }, false)
};

function getDriveTypeList(){
    return request({
        url: Constants.TYPE_OF_DRIVE_LIST_USER,
        method: 'GET',
        params:  { ln: localStorage.getItem('i18nextLng') }
    }, false)
};

function getVehicleList(){
    return request({
        url: Constants.VEHICLE_LIST_USER,
        method: 'GET',
        params:  { ln: localStorage.getItem('i18nextLng') }
    }, false)
};

function getMarketList() {
    return request({
        url: Constants.MARKET_LIST_USER,
        method: 'GET',
        params:  { ln: localStorage.getItem('i18nextLng') }
    }, false)
};

function getFavoritesList(user){
    return request({
        url: Constants.FAVORITES_LIST_GET + '/' + user,
        method: 'GET',
    },false)
};

function deleteFavoritesList(favorites){
    return request({
        url: Constants.FAVORITES_LIST_USER + '/' + favorites,
        method: 'DELETE',
    },false)
};

function getRegulationCount(){
    return request({
        url: Constants.REGULATION_COUNT_USER,
        method: 'GET',
        params:  { ln: localStorage.getItem('i18nextLng') }
    }, false)
};

function getTopicList(){
    return request({
        url: Constants.TOPIC_LIST_USER,
        method: 'GET',
        params:  { ln: localStorage.getItem('i18nextLng') }

    }, false)
};

function saveSearchFilter(filter){
  let userID = JSON.parse(localStorage.getItem('user')).id;
    return request({
        url: Constants.SAVE_FILTER_USER + '/' +userID,
        method: 'POST',
        data: filter
    }, false)
};

function logout(){
    return request({
      url: Constants.LOGOUT,
     method: 'GET'
    },false)
};

function editSaveFilter(favorites) {
    return request({
        url: Constants.FAVORITES_LIST_USER + '/' + favorites.id,
        method: 'PUT',
        data: favorites
    }, false)
};

function getAllLangData(regulation, paramObj){
    return request({
        url: Constants.ALL_LANG_DATA+'/'+regulation.id,
        method: 'GET',
        params:  paramObj

    }, false)
};

function getSearchTagList(){
    return request({
        url: Constants.SEARCH_TAG,
        method: 'GET',
        params:  { ln: localStorage.getItem('i18nextLng') }
    }, false)
};

function shareFilter(filter){
      return request({
          url: Constants.SHARE_FAVORITES,
          method: 'POST',
          data: filter
      }, false)
};

function userVerification(uniqueID) {
    return request({
        url: Constants.USER_VERIFICATION,
        method: 'POST',
        data: uniqueID
    }, false)
};

function getTimelineData(paramObj){
    return request({
        url: Constants.TIMELINE_DATA,
        method: 'GET',
        params: paramObj
    }, false)
};

function editPassword(password) {
    return request({
        url: Constants.EDIT_PASSWORD,
        method: 'POST',
        data: password
    }, false)
};

function forgotPassword(email){
    return request({
        url: Constants.FORGOT_PASSWORD,
        method: 'POST',
        data: email
    }, false)
};

function resetPassword(password){
    return request({
        url: Constants.RESET_PASSWORD,
        method: 'POST',
        data: password
    }, false)
};

function getUserById(userId) {
    return request({
        url: Constants.USERS_LIST_ADMIN+'/'+userId,
        method: 'GET',
    }, false)
};

function getRegulationDetailsById(regulationID){
    return request({
        url: Constants.REGULATION_BY_ID+'/'+regulationID,
        method: 'GET',
        params: {ln:localStorage.getItem('i18nextLng')}
    }, false)
};

function getScopeList(){
    return request({
        url: Constants.SCOPE_LIST_USER,
        method: 'GET',
        params:  { ln: localStorage.getItem('i18nextLng') }
    }, false)
};

function getTimeMatrixList(filter){
    return request({
        url: Constants.TIMELINE_MATRIX,
        method: 'GET',
        params: filter, ln:localStorage.getItem('i18nextLng')
    }, false)
};

function getReferenceRegulationDetails(regulationNumber){
    return request({
        url: Constants.REF_REGULATION,
        method: 'GET',
        params: {regulationNumber, ln:localStorage.getItem('i18nextLng')}
    }, false)
};
function getMarketProfile(marketID){
    return request({
        url: Constants.GET_MARKET_PROFILE+'/'+marketID,
        method: 'GET',
    }, false)
};
function subscibeTopics(id){
    let userID = JSON.parse(localStorage.getItem('user'))?.id;
      return request({
          url: Constants.SUBSCRIBE_TOPICS + '/' +userID,
          method: 'POST',
          data: id
      }, false)
};
function getSubscibedTopics(){
    let userID = JSON.parse(localStorage.getItem('user'))?.id;
    return request({
        url: Constants.GET_SUBSCRIBED_TOPICS+'/'+userID,
        method: 'GET',
        params: {ln:localStorage.getItem('i18nextLng')}
    }, false)
};

function getSubscriptions(lastLoginDate) {
    let userID = JSON.parse(localStorage.getItem('user'))?.id;
    return request({
        url: Constants.GET_SUBSCRIPTIONS+'/'+userID,
        method:'GET',
        params:{lastLoginDate,ln:localStorage.getItem('i18nextLng')}
    },false)
}

function getRegulationUpdates(lastLoginDate) {
    return request({
        url: Constants.GET_REGULATION_UPDATES,
        method: 'GET',
        params: {ln:localStorage.getItem('i18nextLng'),lastLoginDate}
    })
}

function getFaqList()  {
    return request({
        url: Constants.GET_FAQ,
        method:'GET',
        params:{ln:localStorage.getItem('i18nextLng')}
    })
}
function editUserList(user) {
    let userID = JSON.parse(localStorage.getItem('user'))?.id;
      return request({
          url: Constants.USERS_LIST_ADMIN + '/' +userID,
          method: 'PUT',
          data: user
      }, false)
  };

function getRecentGPTQuestions() {
    let userID = JSON.parse(localStorage.getItem('user'))?.id;
    return request({
        url: Constants.GET_RECENT_GPT_QUESTIONS+'/'+userID,
        method:'GET'
    },false)
};
function removeRecentGPTQ(id){
    let userID = JSON.parse(localStorage.getItem('user'))?.id;
      return request({
          url: Constants.REMOVE_RECENT_GPT_QUESTIONS + '/' +userID,
          method: 'POST',
          data: id
      }, false)
};
const UserService = {
    login,
    getRegulationDetails,
    getGroupList,
    getDriveTypeList,
    getVehicleFuelTypeList,
    getVehicleList,
    getMarketList,
    getRegulationCount,
    getFavoritesList,
    deleteFavoritesList,
    getTopicList,
    saveSearchFilter,
    logout,
    editSaveFilter,
    getAllLangData,
    getUserList,
    getSearchTagList,
    shareFilter,
    userVerification,
    getTimelineData,
    editPassword,
    forgotPassword,
    resetPassword,
    getUserById,
    getRegulationDetailsById,
    getScopeList,
    getTimeMatrixList,
    getReferenceRegulationDetails,
    getMarketProfile,
    subscibeTopics,
    getSubscibedTopics,
    getSubscriptions,
    getRegulationUpdates,
    getFaqList,
    editUserList,
    getRecentGPTQuestions,
    removeRecentGPTQ
};

export default UserService;
