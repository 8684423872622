import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import {InputGroup, InputGroupAddon, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button} from 'reactstrap';
import './GRM.css';
import DownArrow from './../../chevron-sign-down.png';
import RightArrow from './../../chevron-sign-right.png';
import i18next from 'i18next';
import DatePicker from 'react-datepicker';
import * as locale from 'date-fns/locale';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {countryLocale, languageArr} from './RegulationsColData';
import moment from 'moment';
import AlertIonicon from 'react-ionicons/lib/MdAlert';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import mockData from './../../utils/StaticData.json';
import AdminDocumentTableComp from './../Table/AdminDocumentTable';
import {softRelOption} from '../ManageRegulations/RegulationsColData';
import RevisionHistoryTable from "../Table/RevisionHistoryTable";
import RightIcon from './../../right-icon.png';
import DownIcon from './../../down-icon.png';

interface Props extends Translate{
  rowData?: any;
  MarketList?: any;
  marketCode: string;
  formtype?: string;
  inputOnBlurHandler: (market) => {};
  toggleDocModal: (docID) => any;
  onDocEdit: (row) => any;
  onDocDelete: (row, market) => any;
  downloadFile: (id, name) => any;
  deleteRowIdArr ?: any;
  openDeleteModal: (row, market) => any;
  undoDeleteChanges: (row) => any;
  allDocsForMarket?: any;
  actualFlag?: any;
}

interface State{
  toggleIconActual?: any;
  rowData?: any;
  statusArr?: any;
  actualFlag?: any;
  addseries: boolean
  open ?: any;
  revHistoryData ?: any;
  formType ?: any;
  editModalFlag : boolean;
  revData ?: any;
  confirmModalFlag : boolean ;
  deletedRow ?: any;
  addRevArr ?: any;
  delSerId:string;
  selectedSeries : any
  
}

class ActualReg extends Component<Props, State> {
  constructor(props) {
    super(props)
      this.state = {
        toggleIconActual: {},
        statusArr:{},
        rowData:this.props.rowData,
        addseries: false,
        actualFlag : false,
        open : '',
        revHistoryData: [],
        formType : '',
        editModalFlag : false,
        revData : {},
        confirmModalFlag : false,
        deletedRow :[],
        addRevArr : [],
        delSerId: '',
        selectedSeries : {}
      }
  }
  componentDidMount(){
    let {toggleIconActual, statusArr, revHistoryData} = this.state;
    let data = {...this.state.rowData};
    [...languageArr].filter((lang) =>{
      this.props.MarketList && [...this.props.MarketList].map(item =>{
      statusArr[item.value] = {};
      toggleIconActual[item.value] = false;
      statusArr[item.value][lang.code] = false;
      [...mockData.statusAdmin].map((elem) => {
        if (elem.id === data['regulations'][item.value][lang.code].actualStatus) {
          data['regulations'][item.value][lang.code]['actualStatus'] = elem[lang.code]['status'];
          data['regulations'][item.value][lang.code]['actualStatusID'] = elem.id;
        }
      });

      //Initially hiding error icon for actual regulations
      $(`p[id=${'actualRegulationSection'+item.value}]`).css({'display': 'none'});

      /* Setting error icon for actual regulation using display "block" if any of the link to revision, amendement or regulation number
      has error icon display set to "block" */
      for (let index = 0; index < languageArr.length; index++) {
        if ($(`p[id=${'actualLinkToRevision'+item.value+languageArr[index].code}]`).css('display') === 'block') {
          $(`p[id=${'actualRegulationSection'+item.value}]`).css({'display': 'block'});
          break;
        }
        else if ($(`p[id=${'actualLinkTOAmendment'+item.value+languageArr[index].code}]`).css('display') === 'block') {
          $(`p[id=${'actualRegulationSection'+item.value}]`).css({'display': 'block'});
          break;
        }
        else if ($(`p[id=${'actualRegregulationNumber'+item.value+'en'}]`).css('display') === 'block') {
          $(`p[id=${'actualRegulationSection'+item.value}]`).css({'display': 'block'});
          break;
        }
      }
    });
    });
    $("#actualRevisionLabel p").show();
    $("#actualAmendLabel p").show();
    $("#actualRevisionLabel p:not(:first)").hide();
    $("#actualAmendLabel p:not(:first)").hide();
    this.setState(toggleIconActual);
    this.setState({rowData: data});
    this.setState({revHistoryData: data.regulations[this.props.marketCode]['en'].revisionHistory})
  }

  componentDidUpdate(prevProps, prevState){
    if (this.state.rowData !== prevState.rowData) {
      $("#actualRevisionLabel p").show();
      $("#actualAmendLabel p").show();
      $("#actualRevisionLabel p:not(:first)").hide();
      $("#actualAmendLabel p:not(:first)").hide();
         this.setState({revHistoryData:this.state.rowData.regulations[this.props.marketCode]['en'].revisionHistory && this.state.rowData.regulations[this.props.marketCode]['en'].revisionHistory , selectedSeries: this.state.rowData.regulations[this.props.marketCode]['en'].revisionHistory && this.state.rowData.regulations[this.props.marketCode]['en'].revisionHistory[0] })

    }
   // this.setState({revHistoryData:this.state.rowData.regulations[this.props.marketCode]['en'].revisionHistory})

  }

  // toggle add series form
  toggleAddSeries = () => {
    this.setState({editModalFlag: true , formType: 'add'})
  }

  //actual regulations toggle icon handler
  toggleIconActual = (market, e) =>{
    e.preventDefault();
    let {toggleIconActual} = this.state;
    toggleIconActual[market] = !toggleIconActual[market];
    this.setState(toggleIconActual);
  }

  //actual regulations status handler
  toggleStatusType = (marketCode, lang) =>{
    let {statusArr} = this.state;
    statusArr[marketCode][lang] = !statusArr[marketCode][lang];
    this.setState(statusArr);
  }

  //input onBlur handler
  inputOnBlurHandler = (market, event) =>{
    this.props.inputOnBlurHandler(market);
  }

  //input field onChange handler
  inputHandler = (id, lang, marketCode, objKey, event) =>{
    this.multiInputHandler(id, event, lang, marketCode, objKey);
    this.props.actualFlag(true)
  }

  //Date picker onChange handler
  dateChange = (id, lang, marketCode, objKey, event) =>{
    this.multiInputHandler(id, event, lang, marketCode, objKey);
    this.props.actualFlag(true)
  }

  //Setting status name and id
  eventHandler = (id, type, lang, marketCode, event) =>{
      let data = {...this.state.rowData};
      if (id === 'actualStatus') {
        [...languageArr].map(lang =>{
            Object.keys(data['regulations'][marketCode]).map(key =>{
              if (key === lang.code) {
                if (id === 'actualStatus') {
                  data['regulations'][marketCode][key]['actualStatus'] = event[key]['status'];
                  data['regulations'][marketCode][key]['actualStatusID'] = event.id;
                }
              }
            })
        })
      }
      this.setState({rowData: data});
      this.props.actualFlag(true)
  }

  //Setting input field value based on language specific and updating state
  multiInputHandler = (id, event, lang, marketCode, objKey) =>{
    let data = {...this.state.rowData};
    if (objKey !== '') {
      data['regulations'][marketCode][lang][objKey][id] = event.target.value;
    }
    else{
      if (id === 'actualEnforcementDateNewModel' || id === 'actualEnforcementDateAllModel' ||
          id === 'actualExpirationDate' || id === 'actualLastChange') {
            [...languageArr].map(lang =>{
              data['regulations'][marketCode][lang.code][id] = event;
            });
      }
      else{
        data['regulations'][marketCode][lang][id] = event.target.value;
      }
    }
    this.setState({rowData: data});
    this.props.actualFlag(true)
  }
  softwareRelevantHandler = (lang, market,event) =>{
     let data = {...this.state.rowData}
    if (event[0]?.id) {
     data['regulations'][market][lang]['actualSoftwareRelevant'] = event[0].name;

      for (let index = 0; index < languageArr.length; index++) {
        if (languageArr[index].code !== lang) {
          let itemIndex = softRelOption[languageArr[index].code].findIndex(obj => obj.id === event[0].id);
          data['regulations'][market][languageArr[index].code]['actualSoftwareRelevant'] = softRelOption[languageArr[index].code][itemIndex].name;
          break;
        }
      }
      this.setState({rowData: data})
    }
    else{
      data['regulations'][market]['en']['actualSoftwareRelevant'] = ''
      data['regulations'][market]['de']['actualSoftwareRelevant'] = ''
      this.setState({rowData: data})
    }
    this.props.actualFlag(true)
  }
  toggleSeriesDiv = (row) =>{

    let {rowData} = this.state;
    let {marketCode} = this.props;

      rowData.regulations[marketCode]['en'].revisionHistory.map(item => {
        if(item.series === row){
            this.setState(prevState => ({open: prevState.open === row ? '' : row}));
        }
      });
  }
  closeModal = () =>{
    this.setState({formType : '', editModalFlag: false})
  }
  closeDeleteModal = () =>{
    this.setState({confirmModalFlag: false})
  }
  seriesSelected = (series) => {
    this.setState({selectedSeries: series})
  }

  render() {
    let {marketCode} = this.props;
    let {rowData} = this.state;
    let regErrFlag = false;
    if (!rowData?.regulations?.[marketCode]?.en?.actualRegulationNumber) {
      regErrFlag = true;
    }
    else if (!rowData?.regulations?.[marketCode]?.de?.actualRegulationNumber) {
      regErrFlag = true;
    }
    return(
      <Translation>{t => (
      <div className="form-inline col-sm-12">
          <div className="col-4 form-inline collapseRow">
            <div className="col-8 form-inline">
              <h6><b>{i18next.t('mngRegulations.actualReg')} *</b></h6>
              {/* Actual regulation error icon visible only in edit mode */}
              { this.props.formtype !== 'view' &&
                <p id={'actualRegulationSection'+marketCode} style={{'margin':'0', 'cursor':'pointer'}}>
                  <a data-tip={t('mngRegulations.actualRegErr')} data-for={'accordionActualReg'+marketCode}>
                    <AlertIonicon beat={true} color="#d92550" fontSize="18px" icon="md-notifications-outline"/>
                  </a>
                  <ReactTooltip id={'accordionActualReg'+marketCode} place="right" type="error" effect="float"/>
                </p>
              }

            </div>
            <div className="col-4">
              <div id={'accordionActualReg'+marketCode}>
                <div id={'headingAccordionActualReg'+marketCode}>
                  <h5 className="mb-0">
                    <button onClick={this.toggleIconActual.bind(this, marketCode)} className="btn btn-link" data-toggle="collapse" data-target={'#collapseActualRegCard'+marketCode} aria-expanded="true" aria-controls={'collapseActualRegCard'+marketCode}>
                      {
                        this.state.toggleIconActual[marketCode] ?
                        <img src={DownArrow} />
                        :
                        <img src={RightArrow} />
                      }
                    </button>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div id={'collapseActualRegCard'+marketCode} className="card collapse col-sm-12 noPadding" aria-labelledby={'headingAccordionActualReg'+marketCode} data-parent={'#accordionActualReg'+marketCode}>
            <div className="card-body">
              <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                <div className="col-4 form-inline"><h6><b>{i18next.t('mngRegulations.actualRegNo')} *</b></h6>
                {/* Error icon for regulation number */}
                {
                  regErrFlag ?
                  <p id={'actualRegregulationNumber'+marketCode+'en'} style={{'margin':'0', 'cursor':'pointer'}}>
                    <a data-tip={t('mngRegulations.actualRegNoRequired')} data-for={'accordionActualReg'+marketCode+'en'}>
                      <AlertIonicon beat={true} color="#d92550" fontSize="18px" icon="md-notifications-outline"/>
                    </a>
                    <ReactTooltip id={'accordionActualReg'+marketCode+'en'} place="right" type="error" effect="float"/>
                  </p>
                  : null
                }</div>
                  {
                    [...languageArr].map(lang =>{
                      return(
                        <div className="col-4 labelTextCss">
                          <h6>
                            {/* Input field visible in edit mode else read only text is visible */}
                            {
                              this.props.formtype === 'edit' || this.props.formtype === 'confirm'? <input onBlur={this.inputOnBlurHandler.bind(this, marketCode)} title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                rowData.regulations[marketCode][lang.code].actualRegulationNumber} type="text" id={'actualRegregulationNumber'+marketCode+lang.code} value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                  rowData.regulations[marketCode][lang.code].actualRegulationNumber} onChange={this.inputHandler.bind(this, 'actualRegulationNumber', lang.code, marketCode, '')} className="form-control" placeholder={t('actualRegForm.inputRegNo')} required={true}/>
                              : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                rowData.regulations[marketCode][lang.code].actualRegulationNumber
                            }
                          </h6>
                        </div>
                      )
                      })
                  }
              </div>
              {/* <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                  <div className="col-4 form-inline" id="actualRevisionLabel"><h6><b>{i18next.t('mngRegulations.addendum')}</b></h6></div>
                    {
                      [...languageArr].map(lang =>{
                        return(
                          <div className="col-4 labelTextCss">
                            <h6>
                            Input field visible in edit mode else hyperlink text is visible 
                              {
                                this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                <input
                                    onBlur={this.inputOnBlurHandler.bind(this, marketCode)}
                                    title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].actualAddendum}
                                    type="text" id={'actualAddendum'+marketCode}
                                    value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].actualAddendum}
                                    onChange={this.inputHandler.bind(this, 'actualAddendum', lang.code, marketCode, '')}
                                    className="form-control" placeholder={t('actualRegForm.inputAddendum')}/>
                                      :
                                  rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                  rowData.regulations[marketCode][lang.code].actualAddendum
                                
                              }
                            </h6>
                          </div>
                        )
                        })
                    }
              </div>  */}
              {
                this.props.formtype === 'view' && this.state.selectedSeries && (
              <div className={`form-inline col-sm-12 mb-2 ${this.props.formtype === 'view' ? 'viewCss': null}`}>
                <div className="form-inline col-sm-12" style={{backgroundColor:'white',padding:'1rem 0',alignItems:'flex-start'}}>
                  <div className='col-sm-2'>
                    <div className={`w-100 seriesSidebarView`}>
                    {
                            this.state.selectedSeries && Object.keys(this.state.selectedSeries && this.state.selectedSeries)?.length !== 0 && rowData.regulations[marketCode] && rowData.regulations[marketCode]['en'] && rowData.regulations[marketCode]['en'].revisionHistory && rowData.regulations[marketCode]['en'].revisionHistory.map((item) =>{
                                  if(this.state.selectedSeries.series === item.series) {
                                    return <p className="seriesName selectedSeries" style={{margin: 0}}>Series {item.series}</p>
                                  }
                                  else {
                                    return <p className="seriesName" style={{margin: 0}} onClick={() => this.seriesSelected(item)}>Series {item.series}</p>
                                  }
                            {/* {
                                          this.state.open == item?.series ?          
                                              <>
                                                  <div className="col-12  form-inline  labelCss">
                                                    <a><b>Series-{item.series}</b></a> <img src={DownIcon} style={{height : '10px',marginLeft: '5px'}} onClick={() => this.toggleSeriesDiv(item.series)}/>
                                                  </div>
                                                  <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`} style={{marginTop:'5px', marginBottom:'10px'}}>
                                                  <RevisionHistoryTable  data={item.data} formType={"viewType"}/>
                                                  </div>
                                              </>
                                          :
                                          <div>
                                            <div className="col-12 form-inline labelCss w-100">
                                              <a><b>Series-{item.series}</b></a> <img src={RightIcon} style={{height : '10px', marginLeft: '5px'}} onClick={() => this.toggleSeriesDiv(item.series)}/>
                                            </div>
                                          </div>
                                          } */}
                              })
                    }
                    </div>
                  </div>
                  <div className={`col-sm-10`} style={{minHeight:'15rem'}}>
                          {
                            this.state.selectedSeries && Object.keys(this.state.selectedSeries).length !== 0  && (
                            <div className="revTableContainer">
                              <div className="seriesTitleBox">
                                  <h6 className="seriesTitle">Series {this.state.selectedSeries.series}</h6>
                                    {/* {
                                      this.props.formtype !== 'view' && (
                                        <>
                                          <a data-tip={t('mngRegulations.deleteSeriesTooltip')}data-for="deleteIconSeriesTooltip" style={{marginBottom: '0.3rem'}}>
                                            <DeleteIcon style={{'cursor':'pointer',height: '0.8rem', margin: '0 6px'}} onClick={() => this.adddeleteSerId(this.state.selectedSeries.series)} />
                                          </a>
                                            <ReactTooltip id="deleteIconSeriesTooltip" place="right" type="info" effect="float"/>
                                        </>
                                      )
                                    } */}
                              </div>
                              <div className="revTableBox">
                              <RevisionHistoryTable  data={this.state.selectedSeries.data} market={marketCode} formType={"viewType"}/>
                              </div>
                              <div>
                              </div>
                            </div>
                            )
                          }      
                  </div>
                </div>
              </div>
                )
              }
              {/* <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                  <div className="col-4 form-inline" id="actualRevisionLabel"><h6><b>{i18next.t('mngRegulations.actualRevision')}</b></h6>
                  {
                    [...languageArr].map(lang =>{
                      return(
                        <Fragment>
                        { this.props.formtype !== 'view' &&
                          (rowData['regulations'][marketCode] &&
                          rowData['regulations'][marketCode][lang.code] && rowData['regulations'][marketCode][lang.code].actualLinkToRevision && rowData['regulations'][marketCode][lang.code].actualLinkToRevision !== '' &&
                          (!rowData['regulations'][marketCode][lang.code].actualRevision || rowData['regulations'][marketCode][lang.code].actualRevision === '')) ?
                          <p id={'actualLinkToRevision'+marketCode+lang.code} style={{'margin':'0', 'cursor':'pointer'}}>
                            <a data-tip={t('mngRegulations.actualRevisionRequired')} data-for={'accordionActualLinkToRevision'+marketCode+lang.code}>
                              <AlertIonicon beat={true} color="#d92550" fontSize="18px" icon="md-notifications-outline"/>
                            </a>
                            <ReactTooltip id={'accordionActualLinkToRevision'+marketCode+lang.code} place="right" type="error" effect="float"/>
                          </p> : null
                          }
                        </Fragment>
                      )
                    })}
                  </div>
                    {
                      [...languageArr].map(lang =>{
                        return(
                          <div className="col-4 labelTextCss">
                            <h6>
                              {
                                this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                <input
                                    onBlur={this.inputOnBlurHandler.bind(this, marketCode)}
                                    title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].actualRevision}
                                    type="text" id={'actualRegrevision'+marketCode}
                                    value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].actualRevision}
                                    onChange={this.inputHandler.bind(this, 'actualRevision', lang.code, marketCode, '')}
                                    className="form-control" placeholder={t('actualRegForm.inputRevision')}
                                    required={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].actualLinkToRevision && rowData.regulations[marketCode][lang.code].actualLinkToRevision !== ''
                                      ? true : false}/>
                                      :
                                  rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                  rowData.regulations[marketCode][lang.code].actualRevision ? (rowData.regulations[marketCode][lang.code].actualLinkToRevision ?
                                  <a style={{'width':'fit-content'}} href={rowData.regulations[marketCode][lang.code].actualLinkToRevision} title={rowData.regulations[marketCode][lang.code].actualLinkToRevision} target="_blank">
                                    {rowData.regulations[marketCode][lang.code].actualRevision}
                                  </a> : rowData.regulations[marketCode][lang.code].actualRevision) : null
                              }
                            </h6>
                          </div>
                        )
                        })
                    }
              </div> */}
              {/* <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                    <div className="col-4 form-inline" id="actualAmendLabel"><h6><b>{i18next.t('mngRegulations.actualAmendCorr')}</b></h6>
                    {
                      [...languageArr].map(lang =>{
                        return(
                          <Fragment>
                          { this.props.formtype !== 'view' &&
                            (rowData['regulations'][marketCode] &&
                            rowData['regulations'][marketCode][lang.code] && rowData['regulations'][marketCode][lang.code].actualLinkTOAmendment && rowData['regulations'][marketCode][lang.code].actualLinkTOAmendment !== '' &&
                            (!rowData['regulations'][marketCode][lang.code].actualAmendment || rowData['regulations'][marketCode][lang.code].actualAmendment === '')) ?
                            <p id={'actualLinkTOAmendment'+marketCode+lang.code} style={{'margin':'0', 'cursor':'pointer'}}>
                              <a data-tip={t('mngRegulations.actualAmendCorrRequired')} data-for={'accordionActualLinkToAmendment'+marketCode+lang.code}>
                                <AlertIonicon beat={true} color="#d92550" fontSize="18px" icon="md-notifications-outline"/>
                              </a>
                              <ReactTooltip id={'accordionActualLinkToAmendment'+marketCode+lang.code} place="right" type="error" effect="float"/>
                            </p> : null
                            }
                          </Fragment>
                        )
                      })}</div>
                      {
                        [...languageArr].map(lang =>{
                          return(
                            <div className="col-4 labelTextCss">
                              <h6>
                                {
                                  this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                  <input
                                      onBlur={this.inputOnBlurHandler.bind(this, marketCode)}
                                      title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                        rowData.regulations[marketCode][lang.code].actualAmendment}
                                      type="text" id={'actualRegamendCorr'+marketCode}
                                      value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].actualAmendment}
                                      onChange={this.inputHandler.bind(this, 'actualAmendment', lang.code, marketCode, '')}
                                      className="form-control" placeholder={t('actualRegForm.inputAmendement')}
                                      required={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                        rowData.regulations[marketCode][lang.code].actualLinkTOAmendment && rowData.regulations[marketCode][lang.code].actualLinkTOAmendment !== ''
                                        ? true : false}/>
                                      :
                                      rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].actualAmendment ? (rowData.regulations[marketCode][lang.code].actualLinkTOAmendment ?
                                      <a style={{'width':'fit-content'}} href={rowData.regulations[marketCode][lang.code].actualLinkTOAmendment} title={rowData.regulations[marketCode][lang.code].actualLinkTOAmendment} target="_blank">
                                        {rowData.regulations[marketCode][lang.code].actualAmendment}
                                      </a> : rowData.regulations[marketCode][lang.code].actualAmendment) : null
                                }
                              </h6>
                            </div>
                          )
                          })
                      }
              </div> */}
              {/* <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                  <div className="col-4 form-inline" id="actualRevisionLabel"><h6><b>{i18next.t('mngRegulations.documentStatus')}</b></h6></div>
                    {
                      [...languageArr].map(lang =>{
                        return(
                          <div className="col-4 labelTextCss">
                            <h6>
                              {
                                this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                <input
                                    onBlur={this.inputOnBlurHandler.bind(this, marketCode)}
                                    title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].actualStatusOfDocument}
                                    type="text" id={'actualStatusOfDocument'+marketCode}
                                    value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].actualStatusOfDocument}
                                    onChange={this.inputHandler.bind(this, 'actualStatusOfDocument', lang.code, marketCode, '')}
                                    className="form-control" placeholder={t('actualRegForm.inputDocumentStatus')}/>
                                      :
                                  rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                  rowData.regulations[marketCode][lang.code].actualStatusOfDocument 
                                 
                              }
                            </h6>
                          </div>
                        )
                        })
                    }
              </div> */}
              {/* <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                <div className="col-4"><h6><b>{i18next.t('status')}</b></h6></div>
                  {
                    [...languageArr].map(lang =>{
                      return(
                        <div className="col-4 form-inline labelTextCss">
                          <h6 className="col-12" style={{'padding':0}}>
                            {
                              this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                              <Dropdown isOpen={this.state.statusArr[marketCode] && this.state.statusArr[marketCode][lang.code]} toggle={() => this.toggleStatusType(marketCode, lang.code)}>
                                <DropdownToggle caret>
                                  {rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] && rowData.regulations[marketCode][lang.code].actualStatus !== 0 &&
                                    rowData.regulations[marketCode][lang.code].actualStatus}
                                </DropdownToggle>
                                <DropdownMenu>
                                  {
                                    [...mockData.statusAdmin].map((item) => {
                                      if (item.id !== 0) {
                                        return (
                                          <DropdownItem onClick={() =>this.eventHandler('actualStatus', '',  lang.code, marketCode, item)}>{item[lang.code].status}</DropdownItem>
                                        );
                                      }
                                    })
                                  }
                                </DropdownMenu>
                              </Dropdown> : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                              rowData.regulations[marketCode][lang.code].actualStatus !== 0 && rowData.regulations[marketCode][lang.code].actualStatus
                            }
                          </h6>

                          {this.props.formtype !== 'view' && rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                          rowData.regulations[marketCode][lang.code].actualStatus &&
                            <button id="clearDropdownCss" type="button" onClick={() =>this.eventHandler('actualStatus', '',  lang.code, marketCode, mockData.statusAdmin[0])} className="close col-1" aria-label="Close"><span aria-hidden="true">×</span></button>
                          }
                        </div>
                      )})
                  }
              </div> */}
              {/* <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                          <div className="col-4"><h6><b>{i18next.t('mngRegulations.softwareRelTitle')}</b></h6></div>
                            {
                              [...languageArr].map(lang =>{
                                return(
                                  <div className="col-4 labelTextCss">
                                    <h6>
                                      {
                                        this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                        <Typeahead
                                        id="basic-typeahead-single"
                                        emptyLabel={t('noMatchFound')}
                                        clearButton
                                        labelKey="name"
                                        onChange={this.softwareRelevantHandler.bind(this,lang.code,marketCode)}
                                        options={softRelOption[lang.code]}
                                        selected={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] && rowData.regulations[marketCode][lang.code].actualSoftwareRelevant ? [rowData.regulations[marketCode][lang.code].actualSoftwareRelevant] : [] }
                                        /> :
                                          rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                          rowData.regulations[marketCode][lang.code].actualSoftwareRelevant
                                        }
                                    </h6>
                                  </div>
                                )})
                            }
                        </div> */}
              {/* <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                      <div className="col-4"><h6><b>{i18next.t('mngRegulations.actualEnfDate')}</b></h6><i>( DD/MM/YYYY )</i></div>
                        {
                          [...languageArr].map(lang =>{
                            if (lang.code === localStorage.getItem('i18nextLng')) {
                              return(
                                  <div className="col-4 form-inline labelTextCss">
                                    <div className="col-sm-6 datePickerContainerleft">
                                      <div style={{'fontSize':'x-small'}}>{i18next.t('mngRegulations.actualEnforcementDateNewModel')}</div>
                                      <div style={this.props.formtype === 'view' ? {'fontSize':'xx-small'} : {} }>
                                      {
                                        this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                        <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                              <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </div>
                                        </InputGroupAddon>
                                        <DatePicker
                                          className="form-control"
                                          dateFormat="dd/MM/yyyy"
                                          showMonthDropdown
                                          showYearDropdown
                                          locale={locale[countryLocale[lang.code]]}
                                          selected={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                            rowData.regulations[marketCode][lang.code].actualEnforcementDateNewModel &&
                                            new Date(Date.parse(moment(rowData.regulations[marketCode][lang.code].actualEnforcementDateNewModel, 'DD/MM/YYYY').format()))}
                                          onChange={this.dateChange.bind(this, 'actualEnforcementDateNewModel', lang.code, marketCode, '')}
                                        />
                                      </InputGroup> :
                                      rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].actualEnforcementDateNewModel ? rowData.regulations[marketCode][lang.code].actualEnforcementDateNewModel : '--'}
                                      </div>
                                    </div>
                                    <div className="col-sm-6 datePickerContainerRight">
                                      <div style={{'fontSize':'x-small'}}>{i18next.t('mngRegulations.actualEnforcementDateAllModel')}</div>
                                      <div style={this.props.formtype === 'view' ? {'fontSize':'xx-small'} : {} }>
                                      {
                                        this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                        <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                              <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </div>
                                        </InputGroupAddon>
                                        <DatePicker
                                          className="form-control"
                                          dateFormat="dd/MM/yyyy"
                                          showMonthDropdown
                                          showYearDropdown
                                          locale={locale[countryLocale[lang.code]]}
                                          selected={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                            rowData.regulations[marketCode][lang.code].actualEnforcementDateAllModel &&
                                            new Date(Date.parse(moment(rowData.regulations[marketCode][lang.code].actualEnforcementDateAllModel, 'DD/MM/YYYY').format()))}
                                          onChange={this.dateChange.bind(this, 'actualEnforcementDateAllModel', lang.code, marketCode, '')}
                                        />
                                      </InputGroup> :
                                      rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].actualEnforcementDateAllModel ? rowData.regulations[marketCode][lang.code].actualEnforcementDateAllModel : '--'}
                                      </div>
                                    </div>
                                  </div>
                              )
                            }})
                        }
              </div> */}
              {/* <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                <div className="col-4"><h6><b>{i18next.t('mngRegulations.actualExpirationDate')}</b></h6><i>( DD/MM/YYYY )</i></div>
                  {
                    [...languageArr].map(lang =>{
                      if (lang.code === localStorage.getItem('i18nextLng')) {
                        return(
                          <div className="col-4 labelTextCss">
                            <h6 className="expiryDateContainer" style={{'fontSize':'xx-small' }}>
                              {
                                this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <div className="input-group-text">
                                      <FontAwesomeIcon icon={faCalendarAlt}/>
                                    </div>
                                </InputGroupAddon>
                                <DatePicker
                                  className="form-control"
                                  dateFormat="dd/MM/yyyy"
                                  showMonthDropdown
                                  showYearDropdown
                                  locale={locale[countryLocale[lang.code]]}
                                  selected={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].actualExpirationDate &&
                                    new Date(Date.parse(moment(rowData.regulations[marketCode][lang.code].actualExpirationDate, 'DD/MM/YYYY').format()))}
                                  onChange={this.dateChange.bind(this, 'actualExpirationDate', lang.code, marketCode, '')}
                                />
                              </InputGroup> :
                              rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                              rowData.regulations[marketCode][lang.code].actualExpirationDate ? rowData.regulations[marketCode][lang.code].actualExpirationDate : '--'}
                            </h6>
                          </div>
                        )
                      }})
                  }
              </div> */}
              {/* <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                <div className="col-4"><h6><b>{i18next.t('mngRegulations.actualLastChangeDate')}</b></h6><i>( DD/MM/YYYY )</i></div>
                  {
                    [...languageArr].map(lang =>{
                      if (lang.code === localStorage.getItem('i18nextLng')) {
                        return(
                          <div className="col-4 labelTextCss">
                            <h6 style={{'fontSize':'xx-small'}} className="actualLastChangeDate">
                            {
                              this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                              <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <div className="input-group-text">
                                  <FontAwesomeIcon icon={faCalendarAlt}/>
                                </div>
                              </InputGroupAddon>
                              <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                showMonthDropdown
                                showYearDropdown
                                locale={locale[countryLocale[lang.code]]}
                                selected={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                  rowData.regulations[marketCode][lang.code].actualLastChange &&
                                  new Date(Date.parse(moment(rowData.regulations[marketCode][lang.code].actualLastChange, 'DD/MM/YYYY').format()))}
                                onChange={this.dateChange.bind(this, 'actualLastChange', lang.code, marketCode, '')}
                              />
                            </InputGroup> :
                            rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                            rowData.regulations[marketCode][lang.code].actualLastChange ? rowData.regulations[marketCode][lang.code].actualLastChange: '--'}
                          </h6>
                        </div>
                      )
                    }})
                  }
              </div> */}
              {/* Input field for adding revision link visible only in edit mode */}
              {/* {this.props.formtype !== 'view' &&
              <div className={`form-inline col-sm-12`}>
                <div className="col-4"><h6><b>{i18next.t('mngRegulations.actualLinkToRevision')}</b></h6></div>
                  {
                    [...languageArr].map(lang =>{
                      return(
                        <div className="col-4 labelTextCss">
                          <h6>
                            <input onBlur={this.inputOnBlurHandler.bind(this, marketCode)} title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                              rowData.regulations[marketCode][lang.code].actualLinkToRevision} type="text" id={'actualReglinkToRevision'+marketCode} value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                              rowData.regulations[marketCode][lang.code].actualLinkToRevision} onChange={this.inputHandler.bind(this, 'actualLinkToRevision', lang.code, marketCode, '')} className="form-control" placeholder={t('actualRegForm.inputRevisionLink')} />
                          </h6>
                        </div>
                      )})
                  }
              </div>} */}

              {/* Input field for adding amendement link visible only in edit mode */}
              {/* {this.props.formtype !== 'view' &&
              <div className={`form-inline col-sm-12`}>
                <div className="col-4"><h6><b>{i18next.t('mngRegulations.actualLinkToAmendCorr')}</b></h6></div>
                  {
                    [...languageArr].map(lang =>{
                      return(
                        <div className="col-4 labelTextCss">
                          <h6>
                            <input onBlur={this.inputOnBlurHandler.bind(this, marketCode)} title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                              rowData.regulations[marketCode][lang.code].actualLinkTOAmendment} type="text" id={'actualReglinkTOAmendment'+marketCode} value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                              rowData.regulations[marketCode][lang.code].actualLinkTOAmendment} onChange={this.inputHandler.bind(this, 'actualLinkTOAmendment', lang.code, marketCode, '')} className="form-control" placeholder={t('actualRegForm.inputAmendmentLink')}/>
                          </h6>
                        </div>
                      )})
                  }
              </div>} */}
              <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                  <div className="col-4"><h6><b>{i18next.t('mngRegulations.actualComments')}</b></h6></div>
                    {
                      [...languageArr].map(lang =>{
                        return(
                          <div className="col-4 labelTextCss">
                            <h6>
                            {/* Textarea field visible in edit mode else read only text is visible */}
                              {
                                this.props.formtype === 'edit' || this.props.formtype === 'confirm' ?
                                <textarea
                                placeholder={i18next.t('mngRegulations.actualCommentsPlaceholder')}
                                title={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                    rowData.regulations[marketCode][lang.code].actualRegulationComments}
                                className="form-control"
                                rows={3}
                                id={'actualRegulationComments'+marketCode}
                                value={rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                      rowData.regulations[marketCode][lang.code].actualRegulationComments}
                                onChange={this.inputHandler.bind(this, 'actualRegulationComments', lang.code, marketCode, '')}
                                name={'actualRegulationComments'+marketCode}>
                              </textarea>
                              : rowData.regulations[marketCode] && rowData.regulations[marketCode][lang.code] &&
                                  rowData.regulations[marketCode][lang.code].actualRegulationComments
                              }
                            </h6>
                          </div>
                        )
                        })
                    }
              </div>
              <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
                  <div className="col-4" style={{marginBottom: 'auto'}}><h6><b>{i18next.t('mngRegulations.documents')}</b></h6></div>
                  {
                    [...languageArr].map(lang =>{
                    if (lang.code === localStorage.getItem('i18nextLng')) {
                  return (
                  <div className="col-8">
                  {/* Add button to upload documents visible in edit mode else read only table is visible */}
                    {(this.props.formtype === 'edit' || this.props.formtype === 'confirm') &&
                    <div className="labelTextCss addDocBtn">
                      <h6>
                        <Button outline color="secondary" onClick={() => this.props.toggleDocModal('Actual')}>{i18next.t('add')}</Button>
                      </h6>
                    </div>}
                    {/* Documents table component */}
                   <AdminDocumentTableComp  deleteRowIdArr={this.props.deleteRowIdArr} undoDeleteChanges={(row) => this.props.undoDeleteChanges(row)} marketCode={this.props.marketCode} formtype={this.props.formtype} downloadFile={(id, name) => this.props.downloadFile(id, name)} dataType="Actual" onDocDelete={(row, market) => this.props.onDocDelete(row, market)}  openDeleteModal={(row, market)=> this.props.openDeleteModal(row,market)}  onDocEdit={(row) => this.props.onDocEdit(row)} documentsData={this.props.allDocsForMarket?.[marketCode]?.[lang.code]} />
                  </div>
                )
              }
            })
          }
               </div>
              {/* <div className={`form-inline col-sm-12 ${this.props.formtype === 'view' ? 'viewCss' : null}`}>
               <AddActualRegForm />
              </div> */}
              
             </div>
            </div>
      </div>)}</Translation>
    )
  }
}
  const mapStateToProps = (appState: AppState) => ({
    MarketList: appState.admin.MarketList,
    delSerIds: appState.admin.delSerIds
  });

  const mapDispatchToProps = dispatch => ({
  });

  export default connect(mapStateToProps, mapDispatchToProps)(ActualReg);
