import React, {Component,Fragment} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { AppState } from './../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import {Button} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PrintIcon from './../../printer.png';
import companyLogo from './../../Vetaca-GRM.png';
import Flag from 'react-flagkit';
import uneceFlag from './../../assets/utils/images/unece.png';
import i18next from 'i18next';

interface Props extends Translate{
  printTimeline: () => {};
  timelineHeaderData?: any;
}

interface State{
}

class TimelineHeader extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
        };

    }
    render() {
        return (
            <Translation>{t => (

              <div className="form-inline col-12" id='timelineDataHeaderDiv1'>
                <div className="timelineDataDiv1">
                  <img style={{'height':'40px'}} src={companyLogo} alt="No Image"/>
                </div>
                <div className="timelineDataDiv2">
                  <h6><b>{i18next.t('timeline.timelineHeaderLabel')}</b></h6>
                </div>
                <div className="timelineDataDiv3">
                  <a data-tip={t('reports.printTimelineTooltip')} data-for={'printTimeline'}>
                    <img id="printTimelineImg" alt="No image" src={PrintIcon} onClick={this.props.printTimeline} style={{'float':'right', 'margin': '0.5rem'}}/>
                  </a>
                  <ReactTooltip id={'printTimeline'} place="bottom" type="info" effect="float"/>
                </div>

                <div className="form-inline col-12">
                  <div className="timelineDataDiv4">
                    <div>
                      <b style={{paddingRight: "5px"}}>{i18next.t('timeline.selectedTimePeriod')}:</b>
                        {this.props.timelineHeaderData.fromDateSelected} - {this.props.timelineHeaderData.toDateSelected}
                    </div>
                    <p>{t('table.totalRecords')} {this.props.timelineHeaderData.TimelineDataRowCount}</p>
                  </div>
                  <div className="timelineDataDiv5" id="timelineFlagDiv">
                    {this.props.timelineHeaderData.marketValue !== 'ECE' ? <Flag className="mr-3 opacity-8 flag" country={this.props.timelineHeaderData.marketValue} size={25}/> :
                      <img src={uneceFlag} style={{'height':'25px', 'width':'30px', 'float':'left'}}/>}
                    <b style={{'padding':'10px'}}>{this.props.timelineHeaderData.marketSelected.toUpperCase()}</b>
                  </div>
                  <div className="timelineDataDiv6" id="newAllModelLabel" >
                    <div className="col-12 form-inline" id={'allNewModelBar'+this.props.timelineHeaderData.regulationID}>
                      {/* {
                        this.props.timelineHeaderData.regulationID === 3 ?
                        <Fragment>
                          <div className="barInfoDiv" id="actual">
                            <b style={{'padding':0}} className="actualBarText">{i18next.t('timeline.actual')}</b>
                            <div className="form-inline"><div className="allModelDiv"></div><b>{i18next.t('timeline.newModel')}</b></div>
                            <div className="form-inline"><div className="newModelDiv"></div><b>{i18next.t('timeline.allModel')}</b></div>
                          </div>
                          <div className="barInfoDiv" id="future">
                            <b style={{'padding':0}} className="futureBarText">{i18next.t('timeline.future')}</b>
                            <div className="form-inline"><div className="allModelDiv"></div><b>{i18next.t('timeline.newModel')}</b></div>
                            <div className="form-inline"><div className="newModelDiv"></div><b>{i18next.t('timeline.allModel')}</b></div>
                          </div>
                        </Fragment> : */}
                        <div className="col-12 barInfoDiv">
                          <div className="form-inline" style={{'paddingLeft':'55%'}}><div className="allModelDiv"></div><b>{i18next.t('timeline.newModel')}</b></div>
                          <div className="form-inline" style={{'paddingLeft':'55%'}}><div className="newModelDiv"></div><b>{i18next.t('timeline.allModel')}</b></div>
                        </div>
                      {/* } */}
                    </div>
                  </div>
                </div>
                <div className="form-inline col-12">
                    <h6 style={{fontSize: '0.8rem',fontWeight: 700, paddingBottom: '10px',color: '#5A99C4'}}><i>{i18next.t('reports.infoText')}</i></h6>
                </div>
              </div>
            )}</Translation>
        );
    }
}

export default (withRouter(TimelineHeader));
