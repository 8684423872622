import i18next from 'i18next';

//handle hide and show column in user table.
export const HideColList = () => {
  return [
    {"name": i18next.t('hideShowCol.groupHidden'), "id":"groupHidden"},
    {"name": i18next.t('hideShowCol.subGroupHidden'), "id":"subGroupHidden"},
    {"name": i18next.t('hideShowCol.regNoHidden'), "id":"regNoHidden"},
    {"name": i18next.t('hideShowCol.topicHidden'), "id":"topicHidden"},
    {"name": i18next.t('hideShowCol.topicTypeHidden'), "id":"topicTypeHidden"},
    {"name": i18next.t('hideShowCol.marketHidden'), "id":"marketHidden"},
    {"name": i18next.t('hideShowCol.titleHidden'), "id":"titleHidden"},
    {"name": i18next.t('hideShowCol.moreInfoHidden'), "id":"moreInfoHidden"}
  ]
}

//handle hide and show column in timeline matrix table.
export const HideColListTimeMtrx = () => {
  return [
    {"name": i18next.t('hideShowCol.groupHidden'), "id":"groupHidden"},
    {"name": i18next.t('hideShowCol.topicHidden'), "id":"topicHidden"},
    {"name": i18next.t('hideShowCol.actualFutureRegNoHidden'), "id":"actualFutureRegNoHidden"},
    {"name": i18next.t('hideShowCol.actualFutureRevisionHidden'), "id":"actualFutureRevisionHidden"},
    {"name": i18next.t('hideShowCol.actualFutureAmendmentHidden'), "id":"actualFutureAmendmentHidden"},
    {"name": i18next.t('hideShowCol.actualFutureStatusNamesHidden'), "id":"actualFutureStatusNamesHidden"},
    {"name": i18next.t('hideShowCol.futureForecastClassHidden'), "id":"futureForecastClassHidden"},
    {"name": i18next.t('hideShowCol.vehicleNamesHidden'), "id":"vehicleNamesHidden"},
    {"name": i18next.t('hideShowCol.fuelTypeNamesHidden'), "id":"fuelTypeNamesHidden"},
    {"name": i18next.t('hideShowCol.driveTypeNamesHidden'), "id":"driveTypeNamesHidden"}
  ]
}
