import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import Actions from '../../internal/modules/Actions';
import './GRM.css';
import {Button, InputGroup, InputGroupAddon} from 'reactstrap';
import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import DownArrow from './../../chevron-sign-down.png';
import RightArrow from './../../chevron-sign-right.png';
import i18next from 'i18next';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {
    faCalendarAlt,

} from '@fortawesome/free-solid-svg-icons';
import {DOCUMENT} from './../../utils/Constants';
import FileSaver from 'file-saver';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {forecastClassArr, typeOfDriveArr, languageArr, postObjGrm} from './RegulationsColData';
import GeneralForm from './GeneralSectionForm';
import MarketForm from './MarketSectionForm';
import {toast} from 'react-toastify';


interface Props extends Translate{
  formtype: string;
  rowData?: any;
  marketData?: any;
  closeModal?: any;
  onSaveFormHandler?: any;
  VehicleList?: any;
  addGRM: (grm, isFileAdded) => {};
  uploadFiles: (files, filename) => {};
  editFiles: (files, id, filename) => {};
  deleteFiles: (id, filename) => {};
  getTopicList: () => {};
  MarketList?: any;
  GRMList?: any;
  marketSelected: (any) => {};
  loading: boolean;
  getRegulationNumberList: () => {};
  grmAdded: boolean;
  fileUploaded: boolean;
  regulationIdData?: any;
}

interface State{
  rowData?: any;
  marketData?: any;
  marketSelected?: any;
  allMarketSelected?: any;
  filesDataArr?: any;
  documentIdArr?: any;
  originalIdArr?: any;
  deleteDocIdArr?: any;
  allFilesObj?: any;
  uploadErrorMessage: string;
  editErrorMessage: string;
  deleteErrorMessage: string;
  marketArr?: any;
  removedMarketArr?: any;
}

let totalLength = 0;
let newFilesAddedLength = 0;

class GRMForm extends Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        rowData: this.props.rowData,
        marketData: this.props.marketData,
        marketSelected:[],
        allMarketSelected:[],
        filesDataArr: {documentList:[]},
        documentIdArr:[],
        originalIdArr:[],
        deleteDocIdArr:[],
        allFilesObj:{},
        uploadErrorMessage: null,
        editErrorMessage: null,
        deleteErrorMessage: null,
        marketArr: [],
        removedMarketArr : []
      }
  }

  componentDidMount(){
    this.props.getRegulationNumberList();
    this.setAllDocumentID();
  }
   static getDerivedStateFromProps(nextProps, prevState) {
     return {
       uploadErrorMessage: nextProps.uploadErrorMessage,
       editErrorMessage: nextProps.editErrorMessage,
       deleteErrorMessage: nextProps.deleteErrorMessage
     };
   }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.uploadErrorMessage && this.state.uploadErrorMessage !== prevState.uploadErrorMessage){
      toast['error'](this.state.uploadErrorMessage, { position: 'top-right' });
    }
    if(this.state.editErrorMessage && this.state.editErrorMessage !== prevState.editErrorMessage){
      toast['error'](this.state.editErrorMessage, { position: 'top-right' });
    }
    if(this.state.deleteErrorMessage && this.state.deleteErrorMessage !== prevState.deleteErrorMessage){
      toast['error'](this.state.deleteErrorMessage, { position: 'top-right' });
    }
    if (this.props.marketData !== prevProps.marketData) {
      this.setAllDocumentID();
    }
    if(this.props.grmAdded && prevProps.grmAdded !== this.props.grmAdded){
      //Calculating douments length to see if any document need to be uploaded else close GRM modal
      let documentListLength = this.state.filesDataArr.documentList.length + this.state.deleteDocIdArr.length;

      //documentListLength < 1 indicates no document is added/edited/deleted from the table.In this case close the modal
      if (documentListLength < 1) {
        toast['success'](i18next.t('mngGRM.addGrmSuccess'), { position: 'top-right' });
        this.props.closeModal();
      }
      if(!this.props.fileUploaded && prevProps.fileUploaded !== this.props.fileUploaded){
        //Call document upload api
        this.docApiHandler();
      }
    }
 

   
  }

  setAllDocumentID = () =>{
    let {originalIdArr} = this.state;
    let propsIdArr = [];
    this.props.MarketList && [...this.props.MarketList].map((market, index) =>{
      [...languageArr].map(lang =>{
        for (let index = 0; index < this.props.marketData?.regulations?.[market.value]?.[lang.code]?.documents?.length; index++) {
          propsIdArr.push(this.props.marketData?.regulations?.[market.value]?.[lang.code]?.documents[index].id);
        }
      });
    });
    this.setState({originalIdArr: propsIdArr});
  }

  //Document add/edit/delete api handler
  docApiHandler(){
    let {originalIdArr, deleteDocIdArr} = this.state;
    //Total length of documents
    totalLength = this.state.filesDataArr['documentList'].length + deleteDocIdArr.length;
    newFilesAddedLength = 0;

    var addFormData = new FormData();
    var editFormData = new FormData();

    var documentJSONlist = [];
    var documentFiles = [];
    let addDocFlag = false;

    for (let index = 0; index < this.state.filesDataArr['documentList'].length; index++) {
      editFormData = new FormData();

      //English data obj for the file
      const fileJson1 = {
        "languageCode": "en",
        "name": this.state.filesDataArr['documentList'][index][0]?.name,
        "size": this.state.filesDataArr['documentList'][index][0]?.size,
        "shortDescription": this.state.filesDataArr['documentList'][index][0]?.shortDescription,
        "category": this.state.filesDataArr['documentList'][index][0]?.category,
        "regulation":{
          "id": this.props.regulationIdData[this.state.filesDataArr['documentList'][index][0]?.market],
          "languageCode": "en",
        }
      };

      //German data obj for the file
      const fileJson2 = {
        "languageCode": "de",
        "name": this.state.filesDataArr['documentList'][index][1]?.name,
        "size": this.state.filesDataArr['documentList'][index][1]?.size,
        "shortDescription": this.state.filesDataArr['documentList'][index][1]?.shortDescription,
        "category": this.state.filesDataArr['documentList'][index][1]?.category,
        "regulation":{
          "id": this.props.regulationIdData[this.state.filesDataArr['documentList'][index][0]?.market],
          "languageCode": "de"
        }
      };


      if (originalIdArr.indexOf(this.state.filesDataArr['documentList'][index][0]?.id) < 0) {
        if (this.state.allFilesObj?.[this.state.filesDataArr['documentList'][index][0]?.id]) {
          //Upload new files
          addDocFlag = true;
          newFilesAddedLength += 1;
          documentJSONlist.push(fileJson1, fileJson2);
          documentFiles.push(this.state.allFilesObj?.[this.state.filesDataArr['documentList'][index][0]?.id]);
          addFormData.append('files', this.state.allFilesObj?.[this.state.filesDataArr['documentList'][index][0]?.id]);
        }
       } else {
         //pushing english and german data obj in edit form data
         editFormData.append('documentJson', JSON.stringify([fileJson1, fileJson2]));
        if (this.state.allFilesObj?.[this.state.filesDataArr['documentList'][index][0]?.id]) {
          editFormData.append('file', this.state.allFilesObj?.[this.state.filesDataArr['documentList'][index][0]?.id]);
        }
        //Edit files api call
        Promise.all([this.props.editFiles(editFormData, this.state.filesDataArr['documentList'][index][0]?.id, this.state.filesDataArr['documentList'][index][0]?.name)])
        .then((responses) => {
          //Decrementing totalLength by 1 to check if length becomes 0 means all docs have added/edited or deleted
          totalLength = totalLength - 1;
          if (totalLength == 0) {
            toast['success'](i18next.t('mngGRM.addGrmSuccess'), { position: 'top-right' });
            this.props.closeModal();
          }
        });
      }
    }

    for (let index = 0; index < deleteDocIdArr.length; index++) {
      //Delete files api call
      Promise.all([this.props.deleteFiles(deleteDocIdArr[index], this.state.allFilesObj?.[deleteDocIdArr[index]])])
      .then((responses) => {
        //Decrementing totalLength by 1 to check if length becomes 0 means all docs have added/edited or deleted then close GRM modal
        totalLength = totalLength - 1;
        if (totalLength == 0) {
          toast['success'](i18next.t('mngGRM.addGrmSuccess'), { position: 'top-right' });
          this.props.closeModal();
        }
      });
    }
  addFormData.append('documentJson', JSON.stringify(documentJSONlist));

  if (addDocFlag) {
    //Add files api call
    Promise.all([this.props.uploadFiles(addFormData, "")])
    .then((responses) => {
      totalLength = totalLength - newFilesAddedLength;
      //If totalLength becomes 0 then close GRM modal and show success popup
      if (totalLength == 0) {
        toast['success'](i18next.t('mngGRM.addGrmSuccess'), { position: 'top-right' });
        this.props.closeModal();
      }
    });
  }
}
  //Save GRM data api call
  SaveFormHandler = (event) => {
    event.preventDefault();
    let {filesDataArr} = this.state;
    var form = document.getElementById('GRMForm') as HTMLInputElement;
      if (form.checkValidity()) {
        let postArr = [];
        let allLanguageTags = {};
        [...this.state.allMarketSelected].map(market =>{
          allLanguageTags[market.value] = {};
          allLanguageTags[market.value]['allLanguageTags'] = '';
          [...languageArr].map(language =>{
            if (this.state.marketData['regulations'][market.value][language.code]['tags']) {
              allLanguageTags[market.value]['allLanguageTags'] = this.state.marketData['regulations'][market.value][language.code]['tags']+','+allLanguageTags[market.value]['allLanguageTags'];
            }
          });
        });
        //Looping through all markets and creating post request body obj
       [...this.state.allMarketSelected].map(market =>{
                
             [...languageArr].map(lang =>{
                  this.state.marketArr.map((item) =>{
                    if (item === market.value) {
                      postArr.push(postObjGrm(this.state.marketData,this.state.rowData, market, lang.code, allLanguageTags, ''));
                    }
                  });
                  this.state.removedMarketArr.map((item) =>{
                    if (item.value === market.value) {
                        //If market is removed from the GRM form then passing "removed" to backend to delete regulations for that market
                      postArr.push(postObjGrm(this.state.marketData,this.state.rowData, market, lang.code, allLanguageTags, 'removed'));
                    }
                  })
                // }
              });
            });
       if (this.state.allMarketSelected.length > 0) {
         let documentListLength = filesDataArr.documentList.length + this.state.deleteDocIdArr.length;

         /* Looping through documents state to remove files whose market is removed to prevent unnecessary
            uploading of files for deleted market */
         for (let i = 0; i < this.state.filesDataArr['documentList'].length; i++) {
             const uploadFileMkt = this.state.filesDataArr['documentList'][i][0]['market'];
             var id = null;

             for (let j = 0; j < this.state.marketSelected.length; j++) {
               if (this.state.marketSelected[j].value == uploadFileMkt) {
                 id = this.state.filesDataArr['documentList'][i][0].id;
                 break;
               }
             }

             if (id == null) {
               delete this.state.allFilesObj[this.state.filesDataArr['documentList'][i][0].id];
               this.state.filesDataArr['documentList'].splice(i, 1);
             }
           }
        /* Regulations add/edit has the same api call (method type "POST"), only difference is:
        for editing the regulations, we send "ID" in post body data obj(postObjGrm) */
        if(postArr.length !== 0){
          this.props.addGRM(postArr, documentListLength < 1);
          this.props.getTopicList();
        }
        else{
          toast['info'](i18next.t('mngGRM.addGrmInfo'), { position: 'top-right' });
        }
       }
       else{
         this.props.closeModal();
       }
      }
  }

  allMarketSelectedHandler = (market) =>{
    this.setState({allMarketSelected: market});
  }

  //On market select handler
  marketSelected = (market) =>{
    this.setState({marketSelected: market});
    this.props.marketSelected(market);
    let {allMarketSelected} = this.state;

    if (market.length > 0) {
      [...market].map(mkt =>{
        let mktFound = false;
        [...allMarketSelected].map(allMkt =>{
          if (mkt.value === allMkt.value) {
            mktFound = true;
          }
        });
        if (!mktFound) {
          allMarketSelected.push(mkt);
        }
        else{
          [...allMarketSelected].map(allMkt =>{
            if (mkt.value !== allMkt.value && ((this.props.GRMList && this.props.GRMList.regulations && this.props.GRMList['regulations'][allMkt.value] === undefined))) {
              allMarketSelected.splice(allMarketSelected.findIndex(a => a.value === allMkt.value) , 1);
            }
          });
        }
      });
    }
    else{
      [...allMarketSelected].map(allMkt =>{
        if (this.props.GRMList && this.props.GRMList.regulations && (this.props.GRMList['regulations'][allMkt.value] === undefined)) {
          allMarketSelected.splice(allMarketSelected.findIndex(a => a.value === allMkt.value) , 1);
        }
      });
    }
    this.setState(allMarketSelected);
  }

  fileHandler = (file, id) =>{
    //Setting files in state on selecting files to upload
    let allFilesLocalObj = {...this.state.allFilesObj};

    if (!allFilesLocalObj[id]) {
      allFilesLocalObj[id] = file;
    }
    this.setState({allFilesObj: allFilesLocalObj});
  }

  //Setting files shortDescription after adding files in doc table
  appendDocInToJsonHandler = (array, market) =>{
    let filesDataArrLocalObj = {...this.state.filesDataArr};
    let docFlag = false;

    if (array[0]?.docType == 'Add' || (filesDataArrLocalObj['documentList'].length < 1)) {
      filesDataArrLocalObj['documentList'].push([array[0], array[1]]);
    } else {
      for (let index = 0; index < filesDataArrLocalObj['documentList'].length; index++) {
        if (filesDataArrLocalObj['documentList'][index][0]?.id == array[0]?.id) {
          docFlag = true;
          filesDataArrLocalObj['documentList'][index][0].shortDescription = array[0]?.shortDescription;
          filesDataArrLocalObj['documentList'][index][1].shortDescription = array[1]?.shortDescription;
        }
      }
      if (!docFlag) {
        filesDataArrLocalObj['documentList'].push([array[0], array[1]]);
      }
    }
    this.setState({filesDataArr: filesDataArrLocalObj});
  }

  updateDocID = (id) =>{
    let {documentIdArr} = this.state;
    if (documentIdArr.indexOf(id) < 0) {
      documentIdArr.push(id);
      this.setState(documentIdArr);
    }
  }

  //Undo deleted files
  undoDeleteHandler = (row) =>{
    let {deleteDocIdArr} = this.state;
    deleteDocIdArr.splice(deleteDocIdArr.indexOf(row?.id),1)
    this.setState(deleteDocIdArr);
  }

  docDeleteHandler = (row) =>{
    let {originalIdArr, filesDataArr, deleteDocIdArr} = this.state;
    //Pushing deleted documents id in delete state obj and removing from the add/edit documents state
    if (originalIdArr.indexOf(row?.id) > -1) {
      deleteDocIdArr.push(row?.id);
      this.setState(deleteDocIdArr);
    }

    //removing deleted document from the add/edit state obj
    for (let index = 0; index < filesDataArr['documentList'].length; index++) {
      if (filesDataArr['documentList'][index][0]?.id == row?.id) {
        filesDataArr['documentList'].splice(index)
        this.setState(filesDataArr);
      }
    }
  }

  //Document download when document is in view mode(read only) by clicking on file name
  downloadfileHandler = (id, name) =>{
    if (this.state.originalIdArr.indexOf(id) > -1) {
      var xhr = new XMLHttpRequest();
      let apiUrl =  process.env.REACT_APP_BASE_URL+'/'+DOCUMENT+'/'+id;
      xhr.open('GET', apiUrl, true);
      xhr.setRequestHeader('Cache-Control','no-cache');
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.setRequestHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('token'))}`);
      xhr.responseType = 'blob';
      xhr.onload = async function (dispatch) {
       if(xhr.status === 200){
         FileSaver.saveAs(xhr.response, name);
         toast(i18next.t('table.downloadSuccess'), { position: 'top-right', type: 'success'});
        }
        else if(xhr.status === 404){
          toast(i18next.t('table.fileNotFoundError'), { position: 'top-right', type: 'error'});
        }
        else if(xhr.status === 500){
          toast(i18next.t('table.downloadError'), { position: 'top-right', type: 'error'});
        }
       }.bind(this);
       xhr.send();
    }
  }
  setMarketArr = (data) => {
   this.setState({marketArr: data})
  }
  removedMarket = (data) =>{
    let arr = [...this.state.removedMarketArr];
    data.map(item =>{
      arr.push(item.name)
    })
    this.setState({removedMarketArr: arr})
  }
  render() {
    let {rowData, marketData} = this.props;
    return (
      <Translation>{t => (
        <Fragment>
          <form id="GRMForm">
            <GeneralForm {...this.props} rowData={rowData} />
            <MarketForm {...this.props} newDocuments={this.state.filesDataArr} downloadFile={(id, name) => this.downloadfileHandler(id, name)} undoDeleteHandler={(row) =>this.undoDeleteHandler(row)} docDeleteHandler={(row) => this.docDeleteHandler(row)} fileHandler={(file, id) => this.fileHandler(file, id)} updateDocID={(id) => this.updateDocID(id)} appendDocInToJsonHandler={(arr, market) => this.appendDocInToJsonHandler(arr, market)} marketSelected={this.marketSelected.bind(this)} allMarketSelectedHandler={this.allMarketSelectedHandler.bind(this)} rowData={marketData} marketArr={this.setMarketArr} removedMarket={this.removedMarket}/>
            <div className="form-group row">
              <div className="col-sm-12 grmFormBtn">
                {(this.props.formtype === 'edit' || this.props.formtype === 'confirm') && <Button color="primary" onClick={this.SaveFormHandler} disabled={this.props.loading || totalLength > 0}>{(this.props.loading || totalLength > 0) ? i18next.t('savingData') : i18next.t('save')}</Button>}
              </div>
            </div>
          </form>
        </Fragment>)}</Translation>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({
  TopicList: appState.user.TopicList,
  MarketList: appState.admin.MarketList,
  VehicleList: appState.admin.VehicleList,
  GRMList: appState.admin.GRMList,
  loading: appState.loading.loading,
  grmAdded: appState.admin.grmAdded,
  fileUploaded: appState.admin.fileUploaded,
  regulationIdData: appState.admin.regulationIdData,
  uploadErrorMessage: appState.admin.DocUploadRequest.$errorMessage,
  editErrorMessage: appState.admin.DocEditRequest.$errorMessage,
  deleteErrorMessage: appState.admin.DocDeleteRequest.$errorMessage
});

const mapDispatchToProps = dispatch => ({
  addGRM: (grm, isFileAdded) => Actions.admin.addGRM(dispatch, grm, isFileAdded),
  getRegulationNumberList: () => Actions.admin.getRegulationNumberList(dispatch),
  uploadFiles: (files, filename) => Actions.admin.uploadFiles(dispatch, files, filename),
  editFiles: (files, id, filename) => Actions.admin.editFiles(dispatch, files, id, filename),
  deleteFiles: (id, filename) => Actions.admin.deleteFiles(dispatch, id, filename),
  getTopicList: () => Actions.admin.getTopicList(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GRMForm);