import React from 'react'

interface Props {
    handleFilter: any,
    dataField: string,
    placeholder: string
}

interface State {
    input: string;
    placeholder: string;
}

class CustomSearchFilter extends React.Component<Props, State> {
  noRef; okRef; inputRef;
  constructor(props: Props) {
    super(props);
    this.filter = this.filter.bind(this);
    this.isFiltered = this.isFiltered.bind(this);
    this.state = {
      input: '',
      placeholder: props.placeholder,
    }
  }

  static getDerivedStateFromProps(state){
    return {
      placeholder: state.placeholder
    }
  }

  //setting input field value for search filter on change.
  filter(event) {
    this.setState({input: event.target.value}, () => {
      if(this.state.input == '' ){
        this.props.handleFilter()
      }else {
        this.props.handleFilter({ callback: this.isFiltered});
      }
    })
  }

  //add customize filter for all columns for admin and user table.
  isFiltered(targetValue) {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let selectedRole = sessionStorage.getItem('selectedRole');
    if(selectedRole === 'user' && targetValue.props.id === "toggletopic"){
      if(targetValue.props.row.topic?.name?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "toggleactualFutureStatusNames"){
      if(targetValue.props.children[0].props.row.actualStatusNames?.toLowerCase().includes(this.state.input.trim().toLowerCase()) ||
         targetValue.props.children[0].props.row.futureStatusNames?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "toggleactualFutureRevision"){
      if(targetValue.props.children[0].props.row.actualRevision?.toLowerCase().includes(this.state.input.trim().toLowerCase()) ||
         targetValue.props.children[0].props.row.futureRevision?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "toggleactualFutureAmendment"){
      if(targetValue.props.children[0].props.row.actualAmendment?.toLowerCase().includes(this.state.input.trim().toLowerCase()) ||
         targetValue.props.children[0].props.row.futureAmendment?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "togglefutureForecastClass"){
      if(targetValue.props.row.futureForecastClass?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "togglevehicleNames"){
      if(targetValue.props.row.vehicleNames?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "togglefuelTypeNames"){
      if(targetValue.props.row.fuelTypeNames?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "toggledriveTypeNames"){
      if(targetValue.props.row.driveTypeNames?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "togglegroup"){
      if(targetValue.props.row.topic.group?.name?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "togglesubGroup"){
      if(targetValue.props.row.topic.subGroup?.name?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "toggleactualFutureRegNo"){
      if(format.test(this.state.input)){
        if(targetValue?.props?.row?.actualRegulationNumber?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
          return true;
        }
      }
      else if(targetValue?.props?.row?.actualRegulationNumber?.toLowerCase().replace(/[^a-zA-Z0-9]/g,'').includes(this.state.input.trim().toLowerCase())){
          return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "toggleactualRegulationNumber"){
      if(format.test(this.state.input)){
        if(targetValue.props.row?.actualRegulationNumber?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
          return true;
        }
      }
      else if(targetValue.props.row?.actualRegulationNumber?.toLowerCase().replace(/[^a-zA-Z0-9]/g,'').includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "togglename"){
      if(targetValue.props.row.topic?.name?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "toggletopicsType"){
      if(targetValue.props.row?.topicsType?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'user' && targetValue.props.id === "toggleMoreInfo"){
      var MoreInfo = ""
       Object.keys(targetValue.props.row).map((key) =>{
        if(typeof targetValue.props.row[`${key}`] == 'string'){
          MoreInfo = MoreInfo.concat(targetValue.props.row[`${key}`].toLowerCase() + " ")
        }
      })
      if(MoreInfo.includes(this.state.input.trim().toLowerCase())){
        return true;
      }
    }
    else if(selectedRole === 'admin'){
        if(targetValue.props.id === "togglegroup" || targetValue.props.id ==="togglesubGroup" || targetValue.props.id === "togglescope" || targetValue.props.id === "togglesubScope"){
          if(targetValue.props.row?.data[localStorage.getItem('i18nextLng')][`${this.props.dataField}`]?.name?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
            return true;
          }
        }
        else if(targetValue.props.id === "togglename"){
          let germanFormat = /[äöüẞ]/;
           if(germanFormat.test(this.state.input)){
             if(targetValue.props.row?.data[localStorage.getItem('i18nextLng')][`${this.props.dataField}`]?.toLowerCase().replace(/[ä]/g,'ä').includes(this.state.input.trim().toLowerCase())){
               return true;
             } 
             else if(targetValue.props.row?.data[localStorage.getItem('i18nextLng')][`${this.props.dataField}`]?.toLowerCase().replace(/[ü]/g,'ü').includes(this.state.input.trim().toLowerCase())){
               return true;
             } 
             else if(targetValue.props.row?.data[localStorage.getItem('i18nextLng')][`${this.props.dataField}`]?.toLowerCase().replace(/[ö]/g,'ö').includes(this.state.input.trim().toLowerCase())){
               return true;
             } 
             else if(targetValue.props.row?.data[localStorage.getItem('i18nextLng')][`${this.props.dataField}`]?.toLowerCase().replace(/[ẞ]/g,'ẞ').includes(this.state.input.trim().toLowerCase())){
              return true;
             } 
            }
          else if(targetValue.props.row?.data[localStorage.getItem('i18nextLng')][`${this.props.dataField}`]?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
             return true;
          } 
        }
        else if(format.test(this.state.input)){
         if(targetValue.props.row?.data[localStorage.getItem('i18nextLng')][`${this.props.dataField}`]?.toLowerCase().includes(this.state.input.trim().toLowerCase())){
           return true;
          }
        }
        else if(targetValue.props.row?.data[localStorage.getItem('i18nextLng')][`${this.props.dataField}`]?.toLowerCase().replace(/[^a-zA-Z0-9]/g,'').includes(this.state.input.trim().toLowerCase())){
         return true;
        }
    }
   else{
      return false;
    }
  }

  render() {
    return (
      <input ref={ref => this.inputRef = ref} placeholder={this.state.placeholder} onChange={ this.filter } style={{width: '100%'}} className={'filter text-filter form-control'} />
    );
  }
}

export default CustomSearchFilter
