import { ApiResponseStatus } from '../../model/ApiResponseStatus';
import {ModuleEvents} from './Actions';
import { Action } from '../../model/Action';
import User from '../../model/User';
import RegulationCount from '../../model/RegulationCount';
import RegulationList from '../../model/RegulationList';
import Favorites from '../../model/Favorites';
import VehicleList from '../../model/VehicleList';
import MarketList from '../../model/MarketList';
import TopicList from '../../model/TopicList';
import TimelineData from '../../model/TimelineData';
import AllLangDataList from '../../model/AllLangDataList';
import SearchTagList from '../../model/SearchTagList';
import UserList from '../../model/UserList';
import GroupList from '../../model/GroupList';
import ScopeList from '../../model/ScopeList';
import VehicleFuelTypeList from '../../model/VehicleFuelTypeList';
import DriveTypeList from '../../model/DriveTypeList';
import RegulationTimeMatrixList from '../../model/RegulationTimeMatrixList';
import MarketProfileList from '../../model/MarketProfileList';
import NavigationService from '../../utils/NavigationService';
import SubscribedTopicList from '../../model/SubscribedTopicList';
import RecentGPTQuestionsList from '../../model/RecentGPTQuestionsList';

export interface InitialUserState {
    user?: User[],
    UserList?: UserList[],
    UserByIdRequest?: ApiResponseStatus,
    UserListRequest?: ApiResponseStatus,
    loginRequest: ApiResponseStatus,
    RegulationDataListRequest?: ApiResponseStatus,
    VehicleList?: VehicleList[],
    VehicleListRequest?: ApiResponseStatus,
    UpdateFavoritesListRequest?: ApiResponseStatus,
    MarketList?: MarketList[],
    MarketListRequest?: ApiResponseStatus,
    RegulationCountList?: RegulationCount,
    RegulationCountRequest?: ApiResponseStatus,
    RegulationDataList?: RegulationList,
    RegulationTimeMatrixList?: RegulationTimeMatrixList,
    RegulationTimeMatrixListRequest?: ApiResponseStatus,
    timeMatrixRegFetched: boolean,
    favorites?:  Favorites[],
    favoritesListRequest : ApiResponseStatus,
    deleteFavoritesRequest : ApiResponseStatus,
    TopicList?: TopicList[],
    TopicListRequest?: ApiResponseStatus,
    saveFilterRequest: ApiResponseStatus,
    activeKey : string,
    userLogged: boolean,
    loginRandomNo: number,
    filterSaved: boolean,
    favoritesDeleted: boolean,
    regulationFetched: boolean,
    filterUpdated: boolean,
    allLangDataArr?: AllLangDataList[],
    allLangDataArrRequest?: ApiResponseStatus,
    allLangDataFetched: boolean,
    SearchTagList?: SearchTagList[],
    SearchTagListRequest: ApiResponseStatus,
    SearchFetched : boolean,
    ShareFilterRequest: ApiResponseStatus,
    favoritesShare: boolean,
    UserVerificationRequest: ApiResponseStatus,
    confirmFlag: boolean,
    TimelineData?: TimelineData[],
    TimelineDataRequest?: ApiResponseStatus,
    timelineDataFetched: boolean,
    passwordUpdated: boolean,
    ForgotPasswordRequest?: ApiResponseStatus,
    UpdatePasswordRequest?: ApiResponseStatus,
    ResetPasswordRequest?: ApiResponseStatus,
    ResetPassword: boolean,
    ForgotPassword: boolean,
    reloadState: number,
    RegulationIdList?: RegulationList,
    RegulationIdListRequest?: ApiResponseStatus,
    regulationIdFetched: boolean,
    GroupList?: GroupList[],
    GroupListRequest?: ApiResponseStatus,
    ScopeList?: ScopeList[],
    ScopeListRequest?: ApiResponseStatus,
    VehicleFuelTypeList?: VehicleFuelTypeList[],
    VehicleFuelTypeListRequest?: ApiResponseStatus,
    DriveTypeList?: DriveTypeList[],
    DriveTypeListRequest?: ApiResponseStatus,
    RefRegulationDetailsListRequest?: ApiResponseStatus,
    RefRegulationDetailsList?: RegulationList,
    RefRegulationDetails ?: RegulationList,
    RefRegulationDetailsRequest?: ApiResponseStatus,
    referenceRegFetched: boolean,
    referenceRegDetailFetched: boolean,
    MarketProfileList?: MarketProfileList[],
    SubscribedTopicList ?: SubscribedTopicList[],
    MarketProfileListRequest?: ApiResponseStatus,
    marketProfileFetched: boolean,
    logoutFlagIthomas : boolean,
    subscribeTopicRequest?: ApiResponseStatus,
    subscriptionAdded : boolean,
    Subscriptions: [],
    SubscriptionsRequest?: ApiResponseStatus;
    RegulationUpdates: [],
    RegulationUpdatesRequest?: ApiResponseStatus,
    FaqList:[],
    FaqListRequest?: ApiResponseStatus,
    RecentGPTQuestionsList ?: RecentGPTQuestionsList[],
    recentGPTQuestionsRequest?: ApiResponseStatus,
    removeRecentGPTQuestionsRequest?: ApiResponseStatus,
    userUpdated : boolean
    }

const initState: InitialUserState = {
    loginRequest: new ApiResponseStatus(false, null),
    RegulationDataListRequest: new ApiResponseStatus(false, null),
    RegulationCountRequest: new ApiResponseStatus(false, null),
    RegulationTimeMatrixListRequest: new ApiResponseStatus(false, null),
    VehicleListRequest: new ApiResponseStatus(false, null),
    MarketListRequest: new ApiResponseStatus(false, null),
    GroupListRequest: new ApiResponseStatus(false,null),
    ScopeListRequest: new ApiResponseStatus(false,null),
    DriveTypeListRequest: new ApiResponseStatus(false,null),
    VehicleFuelTypeListRequest: new ApiResponseStatus(false,null),
    favoritesListRequest: new ApiResponseStatus(false, null),
    deleteFavoritesRequest : new ApiResponseStatus(false, null),
    TopicListRequest: new ApiResponseStatus(false, null),
    saveFilterRequest: new ApiResponseStatus(false, null),
    UpdateFavoritesListRequest: new ApiResponseStatus(false,null),
    allLangDataArrRequest: new ApiResponseStatus(false, null),
    SearchTagListRequest: new ApiResponseStatus(false,null),
    ShareFilterRequest: new ApiResponseStatus(false,null),
    UserVerificationRequest: new ApiResponseStatus(false,null),
    ForgotPasswordRequest: new ApiResponseStatus(false,null),
    UpdatePasswordRequest: new ApiResponseStatus(false,null),
    ResetPasswordRequest: new ApiResponseStatus(false,null),
    UserByIdRequest: new ApiResponseStatus(false,null),
    UserListRequest: new ApiResponseStatus(false,null),
    RefRegulationDetailsListRequest: new ApiResponseStatus(false,null),
    RefRegulationDetailsRequest: new ApiResponseStatus(false,null),
    MarketProfileListRequest: new ApiResponseStatus(false,null),
    RegulationUpdatesRequest: new ApiResponseStatus(false,null),
    activeKey : localStorage.getItem('tab'),
    loginRandomNo: Math.floor((Math.random() * 1000000) + 1),
    filterSaved: false,
    favoritesDeleted: false,
    regulationFetched: false,
    filterUpdated: false,
    allLangDataFetched: false,
    SearchFetched: false,
    favoritesShare: false,
    confirmFlag: false,
    TimelineDataRequest: new ApiResponseStatus(false, null),
    timelineDataFetched: false,
    passwordUpdated: false,
    ResetPassword: false,
    ForgotPassword: false,
    reloadState: Math.floor((Math.random() * 1000000) + 1),
    RegulationIdListRequest: new ApiResponseStatus(false, null),
    regulationIdFetched: false,
    timeMatrixRegFetched: false,
    referenceRegFetched: false,
    referenceRegDetailFetched: false,
    marketProfileFetched: false,
    logoutFlagIthomas: false,
    userLogged: false,
    subscribeTopicRequest: new ApiResponseStatus(false,null),
    subscriptionAdded: false,
    Subscriptions: [],
    SubscriptionsRequest: new ApiResponseStatus(false, null),
    RegulationUpdates: [],
    FaqList: [],
    FaqListRequest: new ApiResponseStatus(false,null),
    recentGPTQuestionsRequest: new ApiResponseStatus(false,null),
    removeRecentGPTQuestionsRequest: new ApiResponseStatus(false,null),
    userUpdated : false
};

export const Reducer = (state = initState, action: Action) => {
    const {payload, type} = action;
    switch (type) {
        case ModuleEvents.RELOAD_UI: {
          sessionStorage.setItem('selectedRole', payload);
            return {
                ...state,
                reloadState: Math.floor((Math.random() * 1000000) + 1),
            }
        }

        case ModuleEvents.LOGIN: {
          sessionStorage.setItem('selectedRole', 'user');
            return {
                ...state,
                user: payload,
              //  activeKey: "1",
                userLogged: true,
                loginRandomNo: Math.floor((Math.random() * 1000000) + 1)
            }
        }

        case ModuleEvents.ERRORLOGIN:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 406:
                    errorMessage = 'login.incorrectPwd';
                    break;
                case 400:
                    errorMessage = 'login.userNotFound';
                    break;
                case 401:
                    errorMessage = 'error.unauthorized';
                    break;
                case 404:
                    errorMessage = 'login.incorrectEmail';
                    break;
                case 403:
                    errorMessage = 'login.userNotVerified';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'login.error';
                    break;
            }

            return {
                ...state,
                loginRequest: new ApiResponseStatus(false, errorMessage),
                userLogged: false,
            }
        }

        case ModuleEvents.RESET_LOGIN:{
            return {
                ...state,
                loginRequest: new ApiResponseStatus(false, null),
            }
        }

        case ModuleEvents.LOGOUT:{
            localStorage.setItem('token', null);
            localStorage.setItem('user', null);
            sessionStorage.setItem('selectedRole', null);
           return {
              ...state,
              user:{},
              loginRandomNo: Math.floor((Math.random() * 1000000) + 1),
              logoutFlagIthomas: true
            }
        }

        case ModuleEvents.REGULATION_LIST: {
            return {
                ...state,
                RegulationDataList: payload,
                regulationFetched: true
            }
        }


        case ModuleEvents.ERROR_REGULATION_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                RegulationDataListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_REGULATION_LIST_STATUS: {
            return {
                ...state,
                regulationFetched: false,
                RegulationDataListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.VEHICLE_LIST: {
            return {
                ...state,
                VehicleList: payload
            }
        }

        case ModuleEvents.ERROR_VEHICLE_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                VehicleListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }
        case ModuleEvents.RESET_VEHICLE_STATUS: {
            return {
                ...state,
                vehicleAdded: false, vehicleUpdated: false, vehicleDeleted:false
            }
        }
        case ModuleEvents.GROUP_LIST: {
            return {
                ...state,
                GroupList: payload
            }
        }

        case ModuleEvents.ERROR_GROUP_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                GroupListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.DRIVE_TYPE_LIST: {
            return {
                ...state,
                DriveTypeList: payload
            }
        }

        case ModuleEvents.ERROR_DRIVE_TYPE_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                DriveTypeListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }
        case ModuleEvents.VEHICLE_FUEL_TYPE_LIST: {
            return {
                ...state,
                VehicleFuelTypeList: payload
            }
        }

        case ModuleEvents.ERROR_VEHICLE_FUEL_TYPE_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                VehicleFuelTypeListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.MARKET_LIST: {
            return {
                ...state,
                MarketList: payload
            }
        }

        case ModuleEvents.ERROR_MARKET_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                MarketListRequest: new ApiResponseStatus(false, errorMessage),
            }
        }
         case ModuleEvents.FAVORITES_LIST: {
            return {
                ...state,
                favorites: payload
            }
        }

        case ModuleEvents.ERROR_FAVORITES_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'favorites.error';
                    break;
            }

            return {
                ...state,
                favoritesListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }
        case ModuleEvents.RESET_FAVORITES_LIST: {
            return {
                ...state,
                favoritesDeleted: false,
                favoritesListRequest: new ApiResponseStatus(false, null)
            }
        }
        case ModuleEvents.DELETE_FAVORITES: {
            let listArr = [...state.favorites];
            const filterArr = listArr.filter(item => item.id !== payload.id)
            return {
                ...state,
                favorites: filterArr,
                favoritesDeleted: true
            }
        }

        case ModuleEvents.ERROR_DELETE_FAVORITES:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 404:
                    errorMessage = 'favorites.notFound';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'favorites.deleteError';
                    break;
            }

            return {
                ...state,
                deleteFavoritesRequest: new ApiResponseStatus(false, errorMessage),
            }
        }

        case ModuleEvents.REGULATION_COUNT: {
            return {
                ...state,
                RegulationCountList: payload
            }
        }

        case ModuleEvents.ERROR_REGULATION_COUNT:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'login.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = null;
                    break;
            }

            return {
                ...state,
                RegulationCountRequest: new ApiResponseStatus(false, errorMessage),
            }
        }

        case ModuleEvents.TOPIC_LIST: {
            return {
                ...state,
                TopicList: payload
            }
        }

        case ModuleEvents.ERROR_TOPIC_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'login.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = null;
                    break;
            }

            return {
                ...state,
                TopicListRequest: new ApiResponseStatus(false, errorMessage),
            }

        }
        case ModuleEvents.TAB_CHANGE: {
            localStorage.setItem('tab', payload)
            return {
                ...state,
                activeKey : payload,
                RegulationDataList:[]
            }
        }
        case ModuleEvents.SAVE_FILTER: {
          let newFavoriteList = state.favorites !== undefined ? [...state.favorites] : [];
          newFavoriteList.push(payload);
            return {
                ...state,
                favorites: newFavoriteList,
                filterSaved: true
            }
        }

        case ModuleEvents.ERROR_SAVE_FILTER:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 404:
                    errorMessage = 'favorites.notFound';
                    break;
                case 409:
                    errorMessage = payload.data.message;
                    if(errorMessage === 'Favorite Name already exist.'){
                        errorMessage = 'favorites.invalidName'
                    }
                    else{
                        errorMessage = 'favorites.invalidURL'
                    }
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = null;
                    break;
            }

            return {
                ...state,
                saveFilterRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_SAVE_FILTER_STATUS: {
            return {
                ...state,
                filterSaved: false,
                saveFilterRequest: new ApiResponseStatus(false, null)
            }
        }
        case ModuleEvents.UPDATE_FAVORITES: {
            let listArr = [...state.favorites];
            localStorage.setItem("filterName", JSON.stringify(payload.name));
            listArr.map(item =>{
              if (item.id === payload.id) {
              item = payload
              }
            });
              return {
                  ...state,
                  favorites: listArr,
                  filterUpdated: true
              }
          }

          case ModuleEvents.ERROR_UPDATE_FAVORITES:{
              let errorCode;
              if (payload.status) {
                  errorCode = payload.status
              }
              let errorMessage = null;
              switch (errorCode) {
                  //handle status code and create error message accordingly
                  case 401:
                      localStorage.setItem('token', null);
                      localStorage.setItem('user', null);
                      errorMessage = 'error.unauthorized';
                      break;
                  case 200:
                      errorMessage = null;
                      break;
                  case 404:
                      errorMessage = 'favorites.notFound';
                      break;
                  case 409:
                       errorMessage = payload.data.message;
                       if(errorMessage === 'Favorite Name already exist.'){
                          errorMessage = 'favorites.invalidName'
                        }
                       else{
                         errorMessage = 'favorites.invalidURL'
                        }
                       break;
                  case 500:
                      errorMessage = 'error.internalError';
                      break;
                  case 0:
                  case -1:
                      errorMessage = null;
                      break;
                  default:
                      errorMessage = 'error.updateFavorites';
                      break;
              }

              return {
                  ...state,
                  UpdateFavoritesListRequest: new ApiResponseStatus(false, errorMessage),
              }
          }
          case ModuleEvents.RESET_UPDATE_FAVORITES_LIST: {
            return {
                ...state,
                filterUpdated: false,
                UpdateFavoritesListRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.ALL_LANG_DATA: {
            return {
                ...state,
                allLangDataArr: payload,
                allLangDataFetched: true
            }
        }


        case ModuleEvents.ERROR_ALL_LANG_DATA:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                allLangDataArrRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_ALL_LANG_DATA: {
            return {
                ...state,
                allLangDataFetched: false,
                allLangDataArrRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.USERS: {
            const listArr = [...payload].filter(user => {
                if(user.id !== (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).id) && user.isVerified) return user;
            });
            return {
                ...state,
                UserList: listArr
            }
        }

        case ModuleEvents.ERROR_USERS:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
            UserListRequest: new ApiResponseStatus(false, errorMessage),
            }
        }

        case ModuleEvents.SEARCH_TAG_LIST: {
            return {
                ...state,
                SearchTagList: payload,
            }
        }

        case ModuleEvents.ERROR_SEARCH_TAG_LIST:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                SearchTagListRequest: new ApiResponseStatus(false, errorMessage)
            }
        }
        case ModuleEvents.SHARE_FILTER: {
            return {
                ...state,
                favoritesShare : true
            }
        }

        case ModuleEvents.ERROR_SHARE_FILTER:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }
            return {
                ...state,
               ShareFilterRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

       case ModuleEvents.RESET_SHARE_FILTER: {
           return {
              ...state,
              favoritesShare: false,
              ShareFilterRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.VERIFY_USER: {
            return {
                ...state,
                confirmFlag: true
            }
        }
        case ModuleEvents.ERROR_VERIFY_USER:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 410:
                    errorMessage = 'error.linkExpired';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                UserVerificationRequest: new ApiResponseStatus(false, errorMessage)
            }
        }
        case ModuleEvents.GET_TIMELINE_DATA: {
            return {
                ...state,
                TimelineData: payload,
                timelineDataFetched: true
            }
        }

        case ModuleEvents.ERROR_GET_TIMELINE_DATA:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.errFetchList';
                    break;
            }

            return {
                ...state,
                TimelineDataRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_GET_TIMELINE_DATA: {
            return {
                ...state,
                timelineDataFetched: false,
                allLangDataArrRequest: new ApiResponseStatus(false, null)
            }
        }

        case ModuleEvents.VERIFY_SESSION: {
            return {
                ...state,
                loginRandomNo: Math.floor((Math.random() * 1000000) + 1)
            }
        }

        case ModuleEvents.UPDATE_PASSWORD: {

            return {
                ...state,
                passwordUpdated:true
            }
        }
        case ModuleEvents.ERROR_UPDATE_PASSWORD:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 406:
                    errorMessage = 'error.invalidPassword';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.updatePassword';
                    break;
            }

            return {
                ...state,
                UpdatePasswordRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.FORGOT_PASSWORD: {

            return {
                ...state,
                ForgotPassword: true
            }
        }
        case ModuleEvents.ERROR_FORGOT_PASSWORD:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 403:
                    errorMessage = 'error.unverifiedUser';
                    break;
                case 404:
                    errorMessage = 'login.userNotFound';
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryUser';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.updateUser';
                    break;
            }

            return {
                ...state,
                ForgotPasswordRequest: new ApiResponseStatus(false, errorMessage)
            }
        }
        case ModuleEvents.RESET_FORGOT_PASSWORD: {
            return {
               ...state,
               ForgotPassword: false,
               ForgotPasswordRequest: new ApiResponseStatus(false, null)
             }
         }


        case ModuleEvents.RESET_PASSWORD: {

            return {
                ...state,
                ResetPassword: true
            }
        }
        case ModuleEvents.ERROR_RESET_PASSWORD:{
            let errorCode;
            if (payload.status) {
                errorCode = payload.status
            }
            let errorMessage = null;
            switch (errorCode) {
                //handle status code and create error message accordingly
                case 401:
                    localStorage.setItem('token', null);
                    localStorage.setItem('user', null);
                    errorMessage = 'error.unauthorized';
                    break;
                case 200:
                    errorMessage = null;
                    break;
                case 409:
                    errorMessage = 'error.duplicateEntryUser';
                    break;
                case 410:
                    errorMessage = 'error.linkExpired';
                    break;
                case 500:
                    errorMessage = 'error.internalError';
                    break;
                case 0:
                case -1:
                    errorMessage = null;
                    break;
                default:
                    errorMessage = 'error.updateUser';
                    break;
            }

            return {
                ...state,
                ResetPasswordRequest: new ApiResponseStatus(false, errorMessage)
            }
        }

        case ModuleEvents.RESET_RESET_PASSWORD: {
            return {
               ...state,
               ResetPassword: false,
               ResetPasswordRequest: new ApiResponseStatus(false, null)
             }
         }

         case ModuleEvents.GET_USER_BY_ID: {
           localStorage.setItem('recentSearch',payload.recentSearch)
           localStorage.setItem('tab',payload.recentTab)
           localStorage.setItem('user', JSON.stringify(payload));
           localStorage.setItem('lastLogin', payload.lastLogin);
            sessionStorage.setItem('selectedRole', 'user');
           let user = {...payload, token: JSON.stringify(localStorage.getItem('token'))}
             return {
                 ...state,
                 user: user,
                 reloadState: Math.floor((Math.random() * 1000000) + 1)
             }
         }

         case ModuleEvents.ERROR_GET_USER_BY_ID:{
             let errorCode;
             if (payload.status) {
                 errorCode = payload.status
             }
             let errorMessage = null;
             switch (errorCode) {
                 //handle status code and create error message accordingly
                 case 401:
                     localStorage.setItem('token', null);
                     localStorage.setItem('user', null);
                     errorMessage = 'error.unauthorized';
                     break;
                 case 200:
                     errorMessage = null;
                     break;
                 case 500:
                     errorMessage = 'error.internalError';
                     break;
                 case 0:
                 case -1:
                     errorMessage = null;
                     break;
                 default:
                     errorMessage = 'error.errFetchList';
                     break;
             }

             return {
                 ...state,
             UserByIdRequest: new ApiResponseStatus(false, errorMessage)
             }
         }

         case ModuleEvents.RESET_GET_USER_BY_ID: {
             return {
                ...state,
                UserByIdRequest: new ApiResponseStatus(false, null)
              }
          }

          case ModuleEvents.GET_REG_DETAIL_BY_ID: {
              return {
                  ...state,
                  RegulationIdList: payload,
                  regulationIdFetched: true
              }
          }

          case ModuleEvents.ERROR_GET_REG_DETAIL_BY_ID:{
              let errorCode;
              if (payload.status) {
                  errorCode = payload.status
              }
              let errorMessage = null;
              switch (errorCode) {
                  //handle status code and create error message accordingly
                  case 401:
                      localStorage.setItem('token', null);
                      localStorage.setItem('user', null);
                      errorMessage = 'error.unauthorized';
                      break;
                  case 200:
                      errorMessage = null;
                      break;
                  case 500:
                      errorMessage = 'error.internalError';
                      break;
                  case 0:
                  case -1:
                      errorMessage = null;
                      break;
                  default:
                      errorMessage = 'error.errRegByIdList';
                      break;
              }

              return {
                  ...state,
                  RegulationIdListRequest: new ApiResponseStatus(false, errorMessage)
              }
          }

          case ModuleEvents.RESET_GET_REG_DETAIL_BY_ID: {
              return {
                 ...state,
                 regulationIdFetched: false,
                 RegulationIdListRequest: new ApiResponseStatus(false, null)
               }
           }

           case ModuleEvents.SCOPE_LIST: {
               return {
                   ...state,
                   ScopeList: payload
               }
           }

           case ModuleEvents.ERROR_SCOPE_LIST:{
               let errorCode;
               if (payload.status) {
                   errorCode = payload.status
               }
               let errorMessage = null;
               switch (errorCode) {
                   //handle status code and create error message accordingly
                   case 401:
                       localStorage.setItem('token', null);
                       localStorage.setItem('user', null);
                       errorMessage = 'error.unauthorized';
                       break;
                   case 200:
                       errorMessage = null;
                       break;
                   case 500:
                       errorMessage = 'error.internalError';
                       break;
                   case 0:
                   case -1:
                       errorMessage = null;
                       break;
                   default:
                       errorMessage = 'error.errFetchListScope';
                       break;
               }

               return {
                   ...state,
                   ScopeListRequest: new ApiResponseStatus(false, errorMessage)
               }
           }

           case ModuleEvents.RESET_SCOPE_LIST_STATUS: {
               return {
                  ...state,
                  ScopeListRequest: new ApiResponseStatus(false, null)
                }
            }

            case ModuleEvents.TIME_MATRIX_LIST: {
                return {
                    ...state,
                    RegulationTimeMatrixList: payload,
                    timeMatrixRegFetched: true
                }
            }


            case ModuleEvents.ERROR_TIME_MATRIX_LIST:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.errFetchList';
                        break;
                }

                return {
                    ...state,
                    RegulationTimeMatrixListRequest: new ApiResponseStatus(false, errorMessage)
                }
            }

            case ModuleEvents.RESET_TIME_MATRIX_LIST_STATUS: {
                return {
                    ...state,
                    timeMatrixRegFetched: false,
                    RegulationTimeMatrixListRequest: new ApiResponseStatus(false, null)
                }
            }

            case ModuleEvents.GET_REF_REG_DETAIL_LIST: {
                return {
                    ...state,
                    RefRegulationDetailsList: payload,
                    referenceRegFetched: true
                }
            }
  
            case ModuleEvents.ERROR_GET_REF_REG_DETAIL_LIST:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.errRegByIdList';
                        break;
                }
  
                return {
                    ...state,
                    RefRegulationDetailsListRequest: new ApiResponseStatus(false, errorMessage)
                }
            }
  
            case ModuleEvents.RESET_GET_REF_REG_DETAIL_LIST: {
                return {
                   ...state,
                   referenceRegFetched: false,
                   RefRegulationDetailsListRequest: new ApiResponseStatus(false, null)
                 }
             }

            case ModuleEvents.GET_REF_REG_DETAIL: {
                return {
                    ...state,
                    RefRegulationDetails: payload,
                    referenceRegDetailFetched: true
                }
            }

            case ModuleEvents.ERROR_GET_REF_REG_DETAIL:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.errRegByIdList';
                        break;
                }
  
                return {
                    ...state,
                    RefRegulationDetailsRequest: new ApiResponseStatus(false, errorMessage)
                }
            }

            case ModuleEvents.RESET_GET_REF_REG_DETAIL: {
                return {
                   ...state,
                   referenceRegDetailFetched: false,
                   RefRegulationDetailsRequest: new ApiResponseStatus(false, null)
                 }
             }

            case ModuleEvents.GET_MARKET_PROFILE: {
                return {
                    ...state,
                    MarketProfileList: payload,
                    marketProfileFetched: true
                }
            }
  
            case ModuleEvents.ERROR_GET_MARKET_PROFILE:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.errRegByIdList';
                        break;
                }
  
                return {
                    ...state,
                    MarketProfileListRequest: new ApiResponseStatus(false, errorMessage)
                }
            }
  
            case ModuleEvents.RESET_GET_MARKET_PROFILE: {
                return {
                   ...state,
                   marketProfileFetched: false,
                   MarketProfileListRequest: new ApiResponseStatus(false, null)
                 }
             }

            case ModuleEvents.GET_SUBSCRIPTIONS: {
                return {
                    ...state,
                    Subscriptions: payload        
                }
            }

            case ModuleEvents.ERROR_GET_SUBSCRIPTIONS:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.errRegByIdList';
                        break;
            }
            return {
                ...state,
                SubscriptionsRequest: new ApiResponseStatus(false, errorMessage)
            }
        }
            case ModuleEvents.GET_REGULATION_UPDATES: {
                return {
                    ...state,
                    RegulationUpdates: payload
                }
            }
               
            case ModuleEvents.ERROR_GET_REGULATION_UPDATES: {
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.errRegByIdList';
                        break;
                }
                   return {
                    ...state,
                    RegulationUpdatesRequest: new ApiResponseStatus(false, errorMessage)
                }
            }

            case ModuleEvents.GET_FAQ: {
                return {
                    ...state,
                    FaqList: payload
                }
            }

            case ModuleEvents.ERROR_GET_FAQ: {
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.errRegByIdList';
                        break;
                }
                   return {
                    ...state,
                    FaqListRequest: new ApiResponseStatus(false, errorMessage)
                }
            }
            case ModuleEvents.ADD_SUBSCRIPTION: {
                return {
                    ...state,
                    subscriptionAdded: true
                }
              }
      
              case ModuleEvents.ERROR_ADD_SUBSCRIPTION:{
                  let errorCode;
                  if (payload.status) {
                      errorCode = payload.status
                  }
                  let errorMessage = null;
                  switch (errorCode) {
                      //handle status code and create error message accordingly
                      case 401:
                          localStorage.setItem('token', null);
                          localStorage.setItem('user', null);
                          errorMessage = 'error.unauthorized';
                          break;
                      case 200:
                          errorMessage = null;
                          break;
                      case 404:
                          errorMessage = 'favorites.notFound';
                          break;
                      case 0:
                      case -1:
                          errorMessage = null;
                          break;
                      default:
                          errorMessage = null;
                          break;
                  }
      
                  return {
                      ...state,
                      subscribeTopicRequest: new ApiResponseStatus(false, errorMessage)
                  }
              }
            case ModuleEvents.RESET_ADD_SUBSCRIPTION: {
                return {
                   ...state,
                   subscriptionAdded: false,
                   subscribeTopicRequest: new ApiResponseStatus(false, null)
                 }
            }
            case ModuleEvents.GET_SUBSCRIBED_TOPICS: {
                return {
                    ...state,
                    SubscribedTopicList: payload
                }
            }
  
            case ModuleEvents.ERROR_GET_SUBSCRIBED_TOPICS:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.errRegByIdList';
                        break;
                }
  
                return {
                    ...state,
                    subscribeTopicRequest: new ApiResponseStatus(false, errorMessage)
                }
            }
                case ModuleEvents.UPDATE_RECENT_ACTIVITY: {
                    return {
                        ...state,
                        userUpdated: true
                    }
                }
      
                case ModuleEvents.ERROR_UPDATE_RECENT_ACTIVITY:{
                    let errorCode;
                    if (payload.status) {
                        errorCode = payload.status
                    }
                    let errorMessage = null;
                    switch (errorCode) {
                        //handle status code and create error message accordingly
                        case 401:
                            localStorage.setItem('token', null);
                            localStorage.setItem('user', null);
                            errorMessage = 'error.unauthorized';
                            break;
                        case 200:
                            errorMessage = null;
                            break;
                        case 500:
                            errorMessage = 'error.internalError';
                            break;
                        case 0:
                        case -1:
                            errorMessage = null;
                            break;
                        default:
                            errorMessage = 'error.errRegByIdList';
                            break;
                    }
      
                    return {
                        ...state,
                        subscribeTopicRequest: new ApiResponseStatus(false, errorMessage)
                    }
            }
            case ModuleEvents.GET_RECENT_GPT_QUESTIONS: {
                return {
                    ...state,
                    RecentGPTQuestionsList: payload
                }
            }
  
            case ModuleEvents.ERROR_GET_RECENT_GPT_QUESTIONS:{
                let errorCode;
                if (payload.status) {
                    errorCode = payload.status
                }
                let errorMessage = null;
                switch (errorCode) {
                    //handle status code and create error message accordingly
                    case 401:
                        localStorage.setItem('token', null);
                        localStorage.setItem('user', null);
                        errorMessage = 'error.unauthorized';
                        break;
                    case 200:
                        errorMessage = null;
                        break;
                    case 500:
                        errorMessage = 'error.internalError';
                        break;
                    case 0:
                    case -1:
                        errorMessage = null;
                        break;
                    default:
                        errorMessage = 'error.errRegByIdList';
                        break;
                }
  
                return {
                    ...state,
                    recentGPTQuestionsRequest: new ApiResponseStatus(false, errorMessage)
                }
            }
            case ModuleEvents.REMOVE_RECENT_GPT_QUESTIONS: {
                return {
                    ...state,
                    removedQuestion: true
                }
              }
      
              case ModuleEvents.ERROR_REMOVE_RECENT_GPT_QUESTIONS:{
                  let errorCode;
                  if (payload.status) {
                      errorCode = payload.status
                  }
                  let errorMessage = null;
                  switch (errorCode) {
                      //handle status code and create error message accordingly
                      case 401:
                          localStorage.setItem('token', null);
                          localStorage.setItem('user', null);
                          errorMessage = 'error.unauthorized';
                          break;
                      case 200:
                          errorMessage = null;
                          break;
                      case 404:
                          errorMessage = 'favorites.notFound';
                          break;
                      case 0:
                      case -1:
                          errorMessage = null;
                          break;
                      default:
                          errorMessage = null;
                          break;
                  }
      
                  return {
                      ...state,
                      removeRecentGPTQuestionsRequest: new ApiResponseStatus(false, errorMessage)
                  }
              }
  
            default:
                return {...state};
                }        
            }

            