import React, { Fragment, useState } from 'react';
import * as ReactDOM from "react-dom";
import {VectorMap} from 'react-jvectormap';
import { Translation  } from 'react-i18next';
import './map.css';

export const VectorComponent = (props) => {
  return (
    <Translation>{t => (<div id="continentMap" style={{width: '100%', cursor:'pointer'}}>
      <h6><b>{props.mapLabel}</b></h6>
      <VectorMap
        map={props.mapProp}
        zoomMin={0.5}
        onRegionTipShow= {
            function(e, el, code){
              //called everytime onhovering the region
              let count = 0;
              props.countProp && props.countProp.forEach((item, i) => {
                if (code !== 'EU' && code === item.country) {
                  count = item.regulationCount;
                }
                else if(code === item.continent){
                  count += item.regulationCount;
                }
              });
              //Creating map tooltip html
              if (props.mapProp === 'continents_mill' && t('map.'+code)) {
                el.html(t('map.'+code) + "<br/>"+ t('map.totalRegulation')+count);
              }
              else if(props.mapProp !== 'continents_mill' && t('map.countries.'+code)) {
                el.html(t('map.countries.'+code) + "<br/>"+ t('map.totalRegulation')+count);
              }
              else{
                el.html(el.html() + "<br/>"+ t('map.totalRegulation')+count);
              }
        }}
        onRegionClick={props.handleClick}
        backgroundColor= {'#a5bfdd'}
        regionStyle={{hover: {"fill": "rgb(102 149 204)"}}}
        containerStyle={{width: '100%',height: '100%'}}
        zoomMax={400}
        //Add style for markers
        markerStyle= {{
          hover: {
            stroke: 'black',
            "stroke-width": 2,
            cursor: 'pointer'
          },
          selected: {
            fill: 'blue'
          },
          selectedHover: {
          },
        }}
        //Add markers for market profile
        markers={ 
         props.markers
        }
        //marker on click function call
        onMarkerClick={props.markerClick}
      />
    </div>)}</Translation>
  );
}
