import React, { Component } from "react";
import { Translation } from "react-i18next";
import "./Footer.css";
import LinkIcon from "../../link-xxl.png";
import EmailIcon from "../../emailIcon.png";
import PhoneIcon from "../../phoneIcon.png";
import iThomasLogo from "../../iThomas.png";
import { BiLink} from 'react-icons/bi';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
  const d = new Date();
    return (
      <Translation>
        {(t) => (
          <div className="footerContainer">
            <div className="footerMain">
              <div className="leftFooter">
                <div className="locationCard">
                  {/* <img src={LinkIcon} alt="" className="locationIcon" /> */}
                  <BiLink className="linkIcon" size={45} />
                  <div className="locationBody">
                    <p className="locationTitle bold">Website :</p>
                    <div className="locationDesc">
                      <a
                        href="http://www.vetaca.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.vetaca.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footerDivider mobileHidden"></div>
              <div className="centerFooter">
                <img src={iThomasLogo} alt="" className="ithomasLogo" />
                {/* <p className="brandTitle bold">iTHoMa§</p> */}
                <p className="copyrightTextFooter">
                  Copyright © {d.getFullYear()} VETACA GmbH - All Rights
                  reserved.
                </p>
              </div>
              <div className="footerDivider"></div>
              <div className="rightFooter">
                <a href="mailto:info@vetaca.de" className="emailBox">
                  <img src={EmailIcon} alt="" className="emailIcon" />
                  <div className="emailBody">
                    <p className="bold emailTitle">Email :</p>
                    <p className="emailId italic">info@vetaca.de</p>
                  </div>
                </a>
                <a href="tel:+49 (0) 9385980 43333​ " className="phoneBox">
                  <img src={PhoneIcon} alt="" className="phoneIcon" />
                  <div className="phoneBody">
                    <p className="phoneTitle bold">Call :</p>
                    <p className="phoneNumber italic">+49 (0) 9385980 43333​</p>
                  </div>
                </a>
              </div>
            </div>
            <p className="copyrightTextMobFooter italic">
              Copyright © {d.getFullYear()} VETACA GmbH - All Rights reserved.
            </p>
          </div>
        )}
      </Translation>
    );
  }
}

export default Footer;
