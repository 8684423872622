import React, {Fragment, Component} from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { AppState } from './../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import Actions from './../../internal/modules/Actions';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import {toast} from 'react-toastify';
import TranslationService from '../../utils/TranslationService';
import './style.css';
import UserTableComp from './../Table/UserTable';
import moment from 'moment';
import Ionicon from 'react-ionicons/lib/IosClose';
import i18n from '../../i18n/I18n';
import {
    Row, Col,
    Card, CardBody,
    CardTitle,
    Button
} from 'reactstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Typeahead} from 'react-bootstrap-typeahead';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { connect } from 'react-redux';
import RegulationList from './../../model/RegulationList';
import ReactTooltip from 'react-tooltip';
import Favorites from '../../model/Favorites';
import {FileExportPdf, FileExportExcel} from './../Table/FileExport';
import ToggleFilter from './ToggleFilter';
import {PDF, EXCEL} from './../../utils/Constants';
var qs = require('qs');
const t = TranslationService.t;

interface Props extends Translate{
  MarketList?: any,
  TopicList?: any,
  VehicleList?: any,
  SearchTagList?: any,
  saveSearchFilter: (any) => {},
  favorites ?: any,
  RegulationDataList ?: any,
  filterSaved: boolean,
  location?: any,
  activeKey?: any,
  regulationFetched: boolean,
  FilterData?: any,
  saveFlag?: any,
  logout: () => {},
  searchFilter ?: any;
  isLoading:() => {};
  finishLoading:() => {}
 }

interface State{
  multiple: boolean,
  regulationFilterList: {},
  Data: string,
  filterName: string,
  saveFlag: boolean
  }

class AdvancedFilterComp extends Component<Props, State> {
  constructor(props: Props){
    super(props);
    this.state = {
        multiple: false,
        regulationFilterList: {},
        Data: 'add',
        filterName: '',
        saveFlag: true
    };
  }


  static getDerivedStateFromProps(newState, prevState) {
    return {
      loading: newState.loading,
      success: newState.success,
      errorMessage: newState.errorMessage
    };
  }

  //Setting selected filters into state
  toggleFilter=(val) =>{
    this.setState({regulationFilterList: val})
  }

  //Pdf and excel file api call
  handleFileExport = (filetype) =>{
    if(this.state.saveFlag === false){
      if(this.props.RegulationDataList && this.props.RegulationDataList.length !== 0){
        let {regulationFilterList} = this.state;
        let params = '?ln='+localStorage.getItem('i18nextLng')+'&timeZone='+Intl.DateTimeFormat().resolvedOptions().timeZone+'&'+
        qs.stringify(regulationFilterList, {arrayFormat: 'repeat'});

        let filename = i18next.t('regulationFilename');
        regulationFilterList['markets'] && [...this.props.MarketList].map(item =>{
          for (let index = 0; index < regulationFilterList['markets'].length; index++) {
            if (regulationFilterList['markets'][index] === item.id) {
              filename += '_'+item.label.toUpperCase();
            }
          }
        })
        filename += '_'+moment(new Date()).format('MMM DD YYYY h-mm a');
        if (filetype === 'PDF') {
          let apiUrl = process.env.REACT_APP_BASE_URL+'/'+PDF+params;
          FileExportPdf(apiUrl, filename, this.props.logout,this.props.isLoading,this.props.finishLoading);
        }
        else{
          let apiUrl = process.env.REACT_APP_BASE_URL+'/'+EXCEL+params;
          FileExportExcel(apiUrl, filename, this.props.logout,this.props.isLoading,this.props.finishLoading);
        }
      }
      else{
       toast(t('advancedFilters.infoToastMsg'), { position: 'top-right', type: 'info'});
      }
    }
  }

  imgFlag = (val) =>{
    this.setState({saveFlag: val})
  }

  render() {
    let tabData = localStorage.getItem('tab') !== null ? localStorage.getItem('tab') : '';
    const{t, i18n} = this.props
      const {multiple, regulationFilterList} = this.state;
      return (
          <Translation>{t => (<Fragment>
              <ToggleFilter saveFlag={false}
              handlerType={this.state.Data}
              AdvancedfilterName ={this.state.filterName}
              filterData={this.toggleFilter}
              imgflag={this.imgFlag}
              shareBtnFlag={false}
              searchFilter ={this.props.searchFilter}
              />


           {(tabData== '2' || this.props.location.pathname.includes('/2')) && <div id="userAdvancedTbl"><UserTableComp {...this.props} imgflag={this.state.saveFlag} handleFileExport={this.handleFileExport.bind(this)} activeKey={this.props.activeKey}/></div>}</Fragment>)}</Translation>
        )
    }
}

    const mapStateToProps = (appState: AppState) => ({
      loading: appState.loading.loading,
      success: appState.user.saveFilterRequest.$success,
      errorMessage: appState.user.saveFilterRequest.$errorMessage,
      RegulationDataList: appState.user.RegulationDataList,
      filterSaved: appState.user.filterSaved,
      activeKey : appState.user.activeKey,
      regulationFetched: appState.user.regulationFetched,
      MarketList: appState.user.MarketList
    });

    const mapDispatchToProps = dispatch => ({
      saveSearchFilter: (filter) => Actions.user.saveSearchFilter(filter, dispatch),
      logout: () => Actions.user.logout(dispatch),
      isLoading:() => Actions.user.isLoading(dispatch),
      finishLoading:() => Actions.user.finishLoading(dispatch),
    });

  export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdvancedFilterComp));
