import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import Actions from '../../internal/modules/Actions';
import {Button} from 'reactstrap';
import $ from 'jquery';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {languageArr, topicTypeArr} from './TopicsColData';
import TopicList from './../../model/TopicList';
import i18next from 'i18next';
import './topic.css';

interface Props extends Translate{
  rowData?: any,
  formtype?: any;
  closeModal?: any;
  editTopic: (any) => {};
  createTopic: (any) => {};
  TopicList: TopicList[];
  GroupSubgroupRelaList?: any;
  ScopeSubscopeRelaList?: any;
}

interface State{
  subScopeArr?: any;
  subGroupArr?: any;
  disabledSystem: boolean;
  disabledScope: boolean;
  disabledSubscope: boolean;
  rowData?: any;
  fieldMsg?: any;
  topicCnfModalFlag: boolean;
  postArr?: any;
  systemList?: any;
  groupList?: any;
  scopeList?: any;
}

class TopicForm extends Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        subScopeArr:{en:[], de:[]},
        disabledSystem: false,
        disabledScope: false,
        disabledSubscope: false,
        rowData: this.props.rowData,
        fieldMsg:{
          noData:'',
          name:'',
          type:'',
          parentTopicName:'',
          scope:'',
          subScope:'',
          subGroup:''
        },
        topicCnfModalFlag: false,
        postArr:[],
        subGroupArr:{en:[], de:[]},
        systemList:{en:[], de:[]},
        groupList:{en:[], de:[]},
        scopeList:{en:[], de:[]}
      }
  }

   componentDidMount(){
     //$("#topicForm").find("span").hide();
     for (const property in {...this.props.rowData.data}) {
        if (this.props.rowData.data[property].type !== undefined && this.props.rowData.data[property].type === 'System') {
          this.setState({disabledSystem: true});
        }
        if (this.props.rowData.data[property].parentTopicId !== undefined && this.props.rowData.data[property].parentTopicId !== 0) {
          this.setState({disabledScope: true, disabledSubscope:true});
        }

          let {subGroupArr, subScopeArr} = this.state;

          [...languageArr].map(key =>{
            if (!subGroupArr[key.code]) {
              subGroupArr[key.code] = [];
            }
            if (!subScopeArr[key.code]) {
              subScopeArr[key.code] = [];
            }
            if (this.props.rowData.data[property].group && this.props.rowData.data[property].group.name) {
              this.props.GroupSubgroupRelaList && [...this.props.GroupSubgroupRelaList].map(item =>{
                if (item.data[key.code].name === this.props.rowData.data[key.code].group.name) {
                    subGroupArr[key.code] = item.data[key.code].subgroup;
                }
              });
            }
            if (this.props.rowData.data[property].scope && this.props.rowData.data[property].scope.name) {
              this.props.ScopeSubscopeRelaList && [...this.props.ScopeSubscopeRelaList].map(item =>{
                if (item.data[key.code].name === this.props.rowData.data[key.code].scope.name) {
                    subScopeArr[key.code] = item.data[key.code].subScope;
                }
              });
            }
          });
          this.setState(subGroupArr);
          this.setState(subScopeArr);
      }

     let {rowData} = this.state;

     [...languageArr].map(lang =>{
       if (!rowData['data']) {
         rowData['data'] = {};
       }
       if (!rowData['data'][lang.code]) {
         rowData['data'][lang.code] = {};
       }
     });

     if (this.props.formtype === 'edit') {
       let itemIndex = topicTypeArr['en'].findIndex(obj => obj.type === rowData['data']['en']['type']);
       rowData['data']['en']['topicTypeName'] = topicTypeArr['en'][itemIndex].name;
       rowData['data']['de']['topicTypeName'] = topicTypeArr['de'][itemIndex].name;
     }

     this.setState({rowData: rowData});

     this.topicListHandler();
     this.groupListHandler();
     this.scopeListHandler();
   }

   componentDidUpdate(prevProps, prevState){
     if (this.props.TopicList && this.props.TopicList !== prevProps.TopicList) {
       this.topicListHandler();
     }
     if (this.props.GroupSubgroupRelaList && this.props.GroupSubgroupRelaList !== prevProps.GroupSubgroupRelaList) {
       this.groupListHandler();
     }
     if (this.props.ScopeSubscopeRelaList && this.props.ScopeSubscopeRelaList !== prevProps.ScopeSubscopeRelaList) {
       this.scopeListHandler();
     }
   }

   // setting the input field value of topic on change.
  inputHandler = (id, lang, event) =>{
      let arr = Object.keys(this.state.rowData).length < 1 ? {data:{}} : {...this.state.rowData};
      if (!arr.data[lang]) {
        arr.data[lang] = {};
      }
      arr.data[lang][id] = event.target.value;
      this.setState({rowData: arr});
  }

  // Add/edit topics
  SaveFormHandler = (event) =>{
    event.preventDefault();
    //Initially setting blank error msg
    let {fieldMsg} = this.state;
    fieldMsg['type'] = '';
    fieldMsg['scope'] = '';
    fieldMsg['subScope'] = '';
    let flag, nameflag = false;
    //Looping through languages and updating error messages for all fields
    [...languageArr].map(lang =>{
      if (!this.state.rowData.data) {
        fieldMsg['noData'] = i18next.t('mngTopic.noData');
        flag = true;
      }
      else{
          fieldMsg['noData'] = '';
          if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].name ||
            (this.state.rowData.data[lang.code].name && this.state.rowData.data[lang.code].name.trim()) === ''){
              nameflag = true;
              fieldMsg['name'] = i18next.t('mngTopic.enterTopic');
              flag = true;
          }
          if (!nameflag) {
            fieldMsg['name'] = '';
          }
          if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].type ||
            (this.state.rowData.data[lang.code].type && this.state.rowData.data[lang.code].type.trim()) === ''){
            fieldMsg['type'] = i18next.t('mngTopic.selectTopicType');
            flag = true;
          }

          if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].scope ||
            (this.state.rowData.data[lang.code].scope && (!this.state.rowData.data[lang.code].scope.name ||
              this.state.rowData.data[lang.code].scope.name.trim()) === '')){
            fieldMsg['scope'] = i18next.t('mngTopic.selectScope');
            flag = true;
          }

          if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].subScope ||
            (this.state.rowData.data[lang.code].subScope && (!this.state.rowData.data[lang.code].subScope.name ||
              this.state.rowData.data[lang.code].subScope.name.trim()) === '')){
              fieldMsg['subScope'] = i18next.t('mngTopic.selectSubscope');
              flag = true;
          }
      }

    });
    this.setState(fieldMsg);
    if (!flag) {
      let postArrObj = [];
      [...languageArr].map(lang =>{
        let obj = this.state.rowData.data[lang.code];
        obj.languageCode = lang.code;
        obj.id = this.state.rowData.id;
        postArrObj.push(obj);
      });
      this.setState({postArr: postArrObj});
      if (postArrObj[0].type === 'Component' && !postArrObj[0].parentTopicName) {
        this.setState({topicCnfModalFlag: true});
      }
      else{
        if (this.props.formtype === 'edit') {
          this.props.editTopic(postArrObj);
        }
        else{
          this.props.createTopic(postArrObj);
        }
        this.props.closeModal();
      }
    }
  }

  //Close topic confirmation modal to add component without system
  closeTopicCnfModal = () =>{
    this.setState({topicCnfModalFlag: false});
  }

  //Save topic after confirmation
  SaveTopicCnfFormHandler = () =>{
    let {postArr} = this.state;
    if (this.props.formtype === 'edit') {
      this.props.editTopic(postArr);
      this.props.closeModal();
    }
    else{
      this.props.createTopic(postArr);
      this.props.closeModal();
    }
  }

  //Resetting all fields if system is blank
  resetHandlerSystem = () =>{
    let {rowData, subScopeArr} = this.state;

    rowData['data']['en']['parentTopicName'] = '';
    rowData['data']['en']['scope'] = null;
    rowData['data']['en']['subScope'] = null;
    rowData['data']['en']['parentTopicId'] = 0;

    rowData['data']['de']['parentTopicName'] = '';
    rowData['data']['de']['scope'] = null;
    rowData['data']['de']['subScope'] = null;
    rowData['data']['de']['parentTopicId'] = 0;
    subScopeArr['en'] = [];
    subScopeArr['de'] = [];

    this.setState({rowData: rowData, disabledScope: false, disabledSubscope: false});
    this.setState(subScopeArr);
  }

  //Resetting group and subgroup when group is blank
  resetHandlerGroup = () =>{
    let {rowData, subGroupArr} = this.state;

    rowData['data']['en']['group'] = null;
    rowData['data']['en']['subGroup'] = null;
    rowData['data']['de']['group'] = null;
    rowData['data']['de']['subGroup'] = null;
    subGroupArr['en'] = [];
    subGroupArr['de'] = [];

    this.setState({rowData: rowData});
    this.setState(subGroupArr);
  }

  //Resetting sub-group field
  resetHandlerSubgroup = () =>{
    let {rowData} = this.state;
    rowData['data']['en']['subGroup'] = null;
    rowData['data']['de']['subGroup'] = null;
    this.setState({rowData: rowData});
  }

  //Resetting scope and sub-scope when scope is blank
  resetHandlerScope = () =>{
    let {rowData, subScopeArr} = this.state;

    rowData['data']['en']['scope'] = null;
    rowData['data']['en']['subScope'] = null;
    rowData['data']['de']['scope'] = null;
    rowData['data']['de']['subScope'] = null;
    subScopeArr['en'] = [];
    subScopeArr['de'] = [];

    this.setState({rowData: rowData});
    this.setState(subScopeArr);
  }

  //Resetting sub-scope
  resetHandlerSubscope = () =>{
    let {rowData} = this.state;

    rowData['data']['en']['subScope'] = null;
    rowData['data']['de']['subScope'] = null;

    this.setState({rowData: rowData});
  }

  //Topic type onChange event handler
  topictypeHandler = (langCode, event) =>{
    let {rowData, fieldMsg} = this.state;
    if (event[0]?.id) {
      rowData['data'][langCode]['type'] = event[0].type;
      rowData['data'][langCode]['topicTypeName'] = event[0].name;

      for (let index = 0; index < languageArr.length; index++) {
        if (languageArr[index].code !== langCode) {
          let itemIndex = topicTypeArr[languageArr[index].code].findIndex(obj => obj.id === event[0].id);
          rowData['data'][languageArr[index].code]['type'] = topicTypeArr[languageArr[index].code][itemIndex].type;
          rowData['data'][languageArr[index].code]['topicTypeName'] = topicTypeArr[languageArr[index].code][itemIndex].name;
          break;
        }
      }

      //Setting parentTopicId and name blank when system is selected as type
      if (event[0]?.id === 1) {
        this.setState({disabledSystem: true, disabledScope: false, disabledSubscope: false});

        fieldMsg['type'] = '';
          fieldMsg['parentTopicName'] = '';
          rowData.data['en'].parentTopicName = '';
          rowData.data['en'].parentTopicId = 0;
          rowData.data['de'].parentTopicName = '';
          rowData.data['de'].parentTopicId = 0;
      }
      else{
        this.setState({disabledSystem: false});
      }
      this.setState(fieldMsg);
      this.setState({rowData: rowData});
    } else {
      rowData['data']['en']['type'] = "";
      rowData['data']['en']['topicTypeName'] = "";
      rowData['data']['de']['type'] = "";
      rowData['data']['de']['topicTypeName'] = "";
      this.setState({rowData: rowData, disabledSystem:false});
    }
  }

  //correspondingSystem list update
  topicListHandler = () =>{
    var arrList = {en:[], de:[]};
    this.props.TopicList && [...this.props.TopicList].map(item =>{
      if (item['data'] && item['data']['en'] && item['data']['de'] && item['data']['en'].type == 'System') {
        arrList['en'].push({...item['data'].en, id:item.id});
        arrList['de'].push({...item['data'].de, id:item.id});
      }
    });
    this.setState({systemList: arrList});
  }

  //correspondingSystem onChange event handler
  correspondingSystemHandler = (langCode, event) =>{
    let {rowData, fieldMsg, systemList, subScopeArr, scopeList} = this.state;
    if (event[0]?.id) {
      rowData.data[langCode].parentTopicName = event[0].name;
      this.setState({disabledScope: true, disabledSubscope: true});

      fieldMsg['parentTopicName'] = '';
      fieldMsg['scope'] = '';
      fieldMsg['subScope'] = '';
      rowData.data[langCode].parentTopicId = event[0]?.id;

      let scopeArrIndexEn = scopeList['en'].findIndex(obj => obj.id === event[0]['scope']['id']);
      let scopeArrIndexDe = scopeList['de'].findIndex(obj => obj.id === event[0]['scope']['id']);
      subScopeArr['en'] = scopeList['en'][scopeArrIndexEn].subScope;
      subScopeArr['de'] = scopeList['de'][scopeArrIndexDe].subScope;

      rowData.data[langCode].scope = event[0]['scope'];
      rowData.data[langCode].subScope = event[0]['subScope'];

      for (let index = 0; index < languageArr.length; index++) {
        if (languageArr[index].code !== langCode) {
          let itemIndex = systemList[languageArr[index].code].findIndex(obj => obj.id === event[0]['id']);
          rowData['data'][languageArr[index].code]['parentTopicName'] = systemList[languageArr[index].code][itemIndex].name;
          rowData.data[languageArr[index].code]['parentTopicId'] = systemList[languageArr[index].code][itemIndex]['id'];

          rowData.data[languageArr[index].code].scope = systemList[languageArr[index].code][itemIndex]['scope'];
          rowData.data[languageArr[index].code].subScope = systemList[languageArr[index].code][itemIndex]['subScope'];

          break;
        }
      }
      this.setState(fieldMsg);
      this.setState(subScopeArr);
      this.setState({rowData: rowData});
    } else {
      this.resetHandlerSystem();
    }
  }

  //Group dropdown list
  groupListHandler = () =>{
    var arrList = {en:[], de:[]};

    this.props.GroupSubgroupRelaList && [...this.props.GroupSubgroupRelaList].map(item =>{
      if (item['data'] && item['data']['en'] && item['data']['de']) {
        arrList['en'].push({...item['data'].en, id:item.id});
        arrList['de'].push({...item['data'].de, id:item.id});
      }
    });
    this.setState({groupList: arrList});
  }

  //Group onChange event handler
  groupEventHandler = (langCode, event) =>{
    let {rowData, fieldMsg, groupList, subGroupArr} = this.state;

    if (event[0]?.id) {
      rowData.data[langCode]['group'] = event[0];
      rowData.data[langCode]['subGroup'] = null;
      subGroupArr['en'] = [];
      subGroupArr['de'] = [];
      subGroupArr[langCode] = event[0].subgroup;

      for (let index = 0; index < languageArr.length; index++) {
        if (languageArr[index].code !== langCode) {
          let itemIndex = groupList[languageArr[index].code].findIndex(obj => obj.id === event[0]['id']);
          rowData['data'][languageArr[index].code]['group'] = groupList[languageArr[index].code][itemIndex];
          rowData['data'][languageArr[index].code]['subGroup'] = null;
          subGroupArr[languageArr[index].code] = groupList[languageArr[index].code][itemIndex].subgroup;
          break;
        }
      }
      this.setState(fieldMsg);
      this.setState(subGroupArr);
      this.setState({rowData: rowData});
    } else {
      this.resetHandlerGroup();
    }
  }

  //Sub-group onChange event handler
  subGroupEventHandler = (langCode, event) =>{
    let {rowData, fieldMsg, subGroupArr} = this.state;

    if (event[0]?.id) {
      fieldMsg['subGroup'] = '';
      rowData.data[langCode]['subGroup'] = event[0];

      for (let index = 0; index < languageArr.length; index++) {
        if (languageArr[index].code !== langCode) {
          let itemIndex = subGroupArr[languageArr[index].code].findIndex(obj => obj.id === event[0]['id']);
          rowData['data'][languageArr[index].code]['subGroup'] = subGroupArr[languageArr[index].code][itemIndex];
          break;
        }
      }

      this.setState(fieldMsg);
      this.setState({rowData: rowData});
    } else {
      this.resetHandlerSubgroup();
    }
  }

  //Scope dropdown list handler
  scopeListHandler = () =>{
    var arrList = {en:[], de:[]};

    this.props.ScopeSubscopeRelaList && [...this.props.ScopeSubscopeRelaList].map(item =>{
      arrList['en'].push({...item['data'].en, id:item.id});
      arrList['de'].push({...item['data'].de, id:item.id});
    });
    this.setState({scopeList: arrList});
  }

  //Scope onChange event handler
  scopeEventHandler = (langCode, event) =>{
    let {rowData, fieldMsg, scopeList, subScopeArr} = this.state;

    if (event[0]?.id) {
      rowData.data[langCode]['scope'] = event[0];
      rowData.data[langCode]['subScope'] = null;
      subScopeArr['en'] = [];
      subScopeArr['de'] = [];
      subScopeArr[langCode] = event[0].subScope;

      for (let index = 0; index < languageArr.length; index++) {
        if (languageArr[index].code !== langCode) {
          let itemIndex = scopeList[languageArr[index].code].findIndex(obj => obj.id === event[0]['id']);
          rowData['data'][languageArr[index].code]['scope'] = scopeList[languageArr[index].code][itemIndex];
          rowData['data'][languageArr[index].code]['subScope'] = null;
          subScopeArr[languageArr[index].code] = scopeList[languageArr[index].code][itemIndex].subScope;
          break;
        }
      }
      this.setState(fieldMsg);
      this.setState(subScopeArr);
      this.setState({rowData: rowData});
    } else {
      this.resetHandlerScope();
    }
  }

  //Sub-scope onChange event handler
  subScopeEventHandler = (langCode, event) =>{
    let {rowData, fieldMsg, subScopeArr} = this.state;

    if (event[0]?.id) {
      fieldMsg['subScope'] = '';
      rowData.data[langCode]['subScope'] = event[0];

      for (let index = 0; index < languageArr.length; index++) {
        if (languageArr[index].code !== langCode) {
          let itemIndex = subScopeArr[languageArr[index].code].findIndex(obj => obj.id === event[0]['id']);
          rowData['data'][languageArr[index].code]['subScope'] = subScopeArr[languageArr[index].code][itemIndex];
          break;
        }
      }

      this.setState(fieldMsg);
      this.setState({rowData: rowData});
    } else {
      this.resetHandlerSubscope();
    }
  }

  render() {
    let {rowData, fieldMsg, topicCnfModalFlag} = this.state;

    return (
      <Translation>{t => (
        <Fragment>
          {!topicCnfModalFlag ?
            <form id="topicForm">
            <div className="form-inline col-12">
              <div className="col-2"><h6 className="col-form-label"><b>{i18next.t('mngTopic.attribute')}</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-5"><h6 className="col-form-label"><b>{i18next.t('language.'+lang.code)} *</b></h6></div>
                  )
                })
              }
            </div>
            <div className="form-inline col-12">
              <div className="col-2"><h6><b>{i18next.t('mngTopic.topic')} *</b></h6></div>
                {
                  [...languageArr].map(lang =>{
                    return(
                      <div className="col-5 labelTextCss">
                        <h6 className="topicContainer">
                          <input autoComplete="off" title={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].name && rowData.data[lang.code].name} type="text" id={'topicContainer'+lang.code} value={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].name && rowData.data[lang.code].name} onChange={this.inputHandler.bind(this, 'name', lang.code)} className="form-control" placeholder={t('topicForm.inputTopic')} required/>
                        </h6>
                      </div>
                    )
                  })
                }
            </div>
            <div className="form-inline col-12">
              <div className="col-2"><h6><b>{i18next.t('mngTopic.topicType')} *</b></h6></div>
                {
                  [...languageArr].map(lang =>{
                    return(
                      <div className="col-5 form-inline labelTextCss">
                        <h6 className="col-12" style={{'padding':0}}>
                        <Typeahead
                            id="basic-typeahead-single"
                            emptyLabel={t('noMatchFound')}
                            clearButton
                            labelKey="name"
                            selected={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].topicTypeName ? [rowData.data[lang.code].topicTypeName] : []}
                            options={topicTypeArr[lang.code] || []}
                            onChange={this.topictypeHandler.bind(this, lang.code)}
                            placeholder={t('mngTopic.selectType')}
                        />
                        </h6>
                      </div>
                    )
                  })
                }
            </div>
            <div className="form-inline col-12" id="correspondingSystemDiv">
              <div className="col-2"><h6><b>{i18next.t('mngTopic.correspondingSystem')}</b></h6></div>
                {
                  [...languageArr].map(lang =>{
                    return(
                      <div className="col-5 form-inline labelTextCss">
                        <h6 className="col-12" style={{'padding':0}}>
                          <Typeahead
                              id="basic-typeahead-single"
                              emptyLabel={t('noMatchFound')}
                              disabled={this.state.disabledSystem}
                              clearButton
                              labelKey="name"
                              selected={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].parentTopicName ? [rowData.data[lang.code].parentTopicName] : []}
                              options={this.state.systemList[lang.code] || []}
                              onChange={this.correspondingSystemHandler.bind(this, lang.code)}
                              placeholder={t('mngTopic.selectCorrespondingSystem')}
                          />
                        </h6>
                      </div>
                    )
                  })
                }
            </div>
            <div className="form-inline col-12" id="groupToggleDiv">
              <div className="col-2"><h6><b>{i18next.t('mngGroup.Group')}</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-5 form-inline labelTextCss">
                      <h6 className="col-12" style={{'padding':0}}>
                        <Typeahead
                            id="basic-typeahead-single"
                            emptyLabel={t('noMatchFound')}
                            clearButton
                            labelKey="name"
                            selected={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].group ? [rowData.data[lang.code].group.name] : []}
                            options={this.state.groupList[lang.code] || []}
                            onChange={this.groupEventHandler.bind(this, lang.code)}
                            placeholder={t('mngTopic.selectGrp')}
                        />
                      </h6>
                    </div>
                  )
                })
              }
            </div>
            <div className="form-inline col-12" id="subGroupToggleDiv">
              <div className="col-2"><h6><b>{i18next.t('mngGroup.Sub-group')}</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-5 form-inline labelTextCss">
                      <h6 className="col-12" style={{'padding':0}}>
                        <Typeahead
                            id="basic-typeahead-single"
                            emptyLabel={t('noMatchFound')}
                            clearButton
                            labelKey="name"
                            selected={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].subGroup &&
                              rowData.data[lang.code].subGroup.name ? [rowData.data[lang.code].subGroup.name] : []}
                            options={this.state.subGroupArr[lang.code] || []}
                            onChange={this.subGroupEventHandler.bind(this, lang.code)}
                            placeholder={t('mngTopic.selectSubGrp')}
                        />
                      </h6>
                    </div>
                  )
                })
              }
            </div>
            <div className="form-inline col-12" id="scopeToggleDiv">
              <div className="col-2"><h6><b>{i18next.t('mngScope.Scope')} *</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-5 form-inline labelTextCss">
                      <h6 className="col-12" style={{'padding':0}}>
                      <Typeahead
                          id="basic-typeahead-single"
                          emptyLabel={t('noMatchFound')}
                          clearButton
                          disabled={this.state.disabledScope}
                          labelKey="name"
                          selected={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].scope ? [rowData.data[lang.code].scope.name] : []}
                          options={this.state.scopeList[lang.code] || []}
                          onChange={this.scopeEventHandler.bind(this, lang.code)}
                          placeholder={t('mngTopic.selectTopicScope')}
                      />
                      </h6>
                    </div>
                  )
                })
              }
            </div>
            <div className="form-inline col-12" id="subScopeToggleDiv">
              <div className="col-2"><h6><b>{i18next.t('mngScope.Sub-scope')} *</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-5 form-inline labelTextCss">
                      <h6 className="col-12" style={{'padding':0}}>
                        <Typeahead
                            id="basic-typeahead-single"
                            emptyLabel={t('noMatchFound')}
                            clearButton
                            disabled={this.state.disabledSubscope}
                            labelKey="name"
                            selected={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].subScope &&
                              rowData.data[lang.code].subScope.name ? [rowData.data[lang.code].subScope.name] : []}
                            options={this.state.subScopeArr[lang.code] || []}
                            onChange={this.subScopeEventHandler.bind(this, lang.code)}
                            placeholder={t('mngTopic.selectTopicSubScope')}
                        />
                      </h6>
                    </div>
                  )
                })
              }
            </div>
            {
              <ul>
              {Object.keys(this.state.fieldMsg).map(errKey =>{
                if (this.state.fieldMsg[errKey] !== '') {
                  return(
                    <li id={errKey+'Err'} style={{'color':'red'}}>{this.state.fieldMsg[errKey]}</li>
                  )
                }
              })}
              </ul>
            }
            <hr />
            <div className="form-group row">
              <div className="col-12" style={{'textAlignLast':'end'}}>
                <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
                <Button color="primary" onClick={this.SaveFormHandler}>{i18next.t('save')}</Button>
              </div>
            </div>
          </form>
          :
          <div className="form-inline col-12">
            <div className="col-12">
              <p style={{'fontSize': 'medium'}}>
                {i18next.t('mngTopic.noSystemCnfMsg')}
              </p>
            </div>
            <hr style={{width:'100%'}} />
            <div className="form-group row col-12">
              <div className="col-12" style={{'textAlignLast':'end'}}>
                <Button color="link" onClick={this.closeTopicCnfModal}>{i18next.t('cancel')}</Button>
                <Button color="primary" onClick={this.SaveTopicCnfFormHandler}>{i18next.t('save')}</Button>
              </div>
            </div>
          </div>
          }
          </Fragment>
      )}</Translation>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({
  TopicList: appState.admin.TopicList,
  GroupSubgroupRelaList: appState.admin.GroupSubgroupRelaList,
  ScopeSubscopeRelaList: appState.admin.ScopeSubscopeRelaList
});

const mapDispatchToProps = dispatch => ({
  createTopic: (topic) => Actions.admin.createTopic(dispatch, topic),
  editTopic: (topic) => Actions.admin.editTopic(dispatch, topic)
});

export default connect(mapStateToProps, mapDispatchToProps)(TopicForm);
