import React, {Component,Fragment, useState} from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import i18next from 'i18next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RevisionHistoryTable.css';
import ReactTooltip from 'react-tooltip';
import mockData from './../../utils/StaticData.json';
import { Col, Row } from 'react-bootstrap';
import DeleteIcon from 'react-ionicons/lib/MdTrash';
import EditIcon from 'react-ionicons/lib/MdCreate';


interface Props extends Translate{
    data?: any;
    type?: any;
    formType ?: any;
    onRevEdit : (any) => {};
    onRevDelete : (any) => {};
    cssType ?: any;
    market ?: any;
}

interface State{
}

class RevisionHistoryTableComp extends Component<Props, State> {
    constructor(props){
      super(props)
      this.state={

      }
    }

    documentName = (cell,row) => {
      if(this.props.cssType === 'timelineMatrix'){
        return (
          <div style={{ display: 'contents' }}>
            {row?.isActualRegValid !== false ? (
              <>
                {row?.link !== '' ? (
                    <a style={{cursor: 'pointer',color: '#545cd8',textDecoration: 'underline'}} target="_blank" href={row.link}>
                      {cell}
                    </a>
                ) : (
                  <div style={{ display: 'contents' }}>{cell}</div>
                )}
              </>
            ) : (
              <div style={{ textDecoration: 'line-through' }}>{cell}</div>
            )}
          </div>
        );
      }
      else{
        return(<div style={{display: 'contents'}} > 
        {row.link !== '' ? 
        <div style={{display: 'contents'}}>
        <a style={{cursor: 'pointer', color: '#545cd8',textDecoration:'underline'}} data-tip={row.shortDescription} target="_blank" href={row.link} data-for="fileName">{cell}</a>
        <ReactTooltip id="fileName" place="bottom" type="info" effect="float"/></div> : 
        <div style={{display: 'contents'}}>{cell}</div>}</div>)
      }
    }
   status = (cell,row) =>{
    let actualStatus;
    [...mockData.statusUser[localStorage.getItem('i18nextLng')]].forEach(item => {
      if (item.id === row.status) {
        actualStatus = item.name;
      }
    });
    
    if (this.props.cssType === 'timelineMatrix' && row?.isActualRegValid === false) {
      return <div style={{ textDecoration: 'line-through' }}>{actualStatus}</div>;
    }
   return actualStatus;
   }
   actionColFormatter = (cell, row) => {
    return (
      <Row>
      <Row style={{marginLeft:'10px'}}>
      <Col md="3"style={{paddingLeft:'1px'}}>
      <a data-tip={i18next.t('revHistory.editRevHistory')} data-for='editHandler'>
       <EditIcon onClick={() => this.props.onRevEdit(row)} />
      </a>
      <ReactTooltip id="editHandler" place="right" type="info" effect="float"/>
      </Col></Row>
      <Col md="3"style={{paddingLeft:'1px'}}>
      <a data-tip={i18next.t('revHistory.deleteRevHistory')} data-for='deleteHandler'>
       <DeleteIcon onClick={() => this.props.onRevDelete(row)}  />
      </a>
      <ReactTooltip id='deleteHandler' place="right" type="info" effect="float"/>
      </Col>
      </Row>
    );
  };
  softwareRelevant = (cell,row) =>{
    if (this.props.cssType === 'timelineMatrix' && row?.isActualRegValid === false) {
      return <div style={{ textDecoration: 'line-through' }}>{row?.softwareRelevant}</div>;
    }
   return row?.softwareRelevant;
  }
  newModalDate = (cell,row) =>{
    if (this.props.cssType === 'timelineMatrix' && row?.isActualRegValid === false) {
      return <div style={{ textDecoration: 'line-through' }}>{row?.enforcementDateNewModel}</div>;
    }
   return row?.enforcementDateNewModel; 
  }
  expiryDate = (cell,row) =>{
    if (this.props.cssType === 'timelineMatrix' && row?.isActualRegValid === false) {
      return <div style={{ textDecoration: 'line-through' }}>{row?.expirationDate}</div>;
    }
   return row?.expirationDate;  
  }
  allModalDate = (cell,row) =>{
    if (this.props.cssType === 'timelineMatrix' && row?.isActualRegValid === false) {
      return <div style={{ textDecoration: 'line-through' }}>{row?.enforcementDateAllModel}</div>;
    }
   return row?.enforcementDateAllModel;
  }
  forecastClass = (cell,row) =>{
    if (this.props.cssType === 'timelineMatrix' && row?.isActualRegValid === false) {
      return <div style={{ textDecoration: 'line-through' }}>{row?.forecastClass}</div>;
    }
   return row?.forecastClass;
  }
  render() {
    const options = {
      noDataText: i18next.t('table.noDataMsg')
    };
     return(
        <div  id="revisionHistoryTable" className={`${this.props.formType !== 'userView' ? 'admin' : ''}`}>
          <Translation>{t => (
            <Fragment>
             <BootstrapTable data={this.props.data} bordered={true} options={options}>
               {this.props.market !== 'EU' ?
                <TableHeaderColumn dataField='name' dataFormat={this.documentName}isKey>{t('revHistory.revNameLabel')}</TableHeaderColumn>
              :
              <TableHeaderColumn dataField='euRegulation' dataFormat={this.documentName} isKey>{t('table.regulationNo')}</TableHeaderColumn>
              }
               <TableHeaderColumn  dataField='enforcementDateNewModel' dataFormat={this.newModalDate}>{t('revHistory.revEnfDateLabel')} <p style={{margin: 0}}>{t('revHistory.revEnfNewModelLabel')}</p></TableHeaderColumn>
               <TableHeaderColumn   dataField='enforcementDateAllModel' dataFormat={this.allModalDate}>{t('revHistory.revEnfDateLabel')} <p style={{margin: 0}}>{t('revHistory.revEnfAllModelLabel')}</p></TableHeaderColumn>
               <TableHeaderColumn  dataField='expirationDate' dataFormat={this.expiryDate}>{t('revHistory.revExpDateLabel')}</TableHeaderColumn>
               <TableHeaderColumn  dataField='status' dataFormat={this.status}>{t('revHistory.revStatusLabel')}</TableHeaderColumn>
               <TableHeaderColumn  dataField='softwareRelevant' dataFormat={this.softwareRelevant}>{t('revHistory.revSoftWareRel')}</TableHeaderColumn>
               <TableHeaderColumn  dataField='forecastClass' dataFormat={this.forecastClass}>{t('mngRegulations.futureForecastClass')}</TableHeaderColumn>
               {
                 (this.props.formType === 'editType') &&
                 <TableHeaderColumn dataField='action' dataFormat={this.actionColFormatter}>{i18next.t('table.action')}</TableHeaderColumn>
               }
             </BootstrapTable>
             </Fragment>
         )}</Translation>
      </div>
    );
  }
}
const mapStateToProps = (appState: AppState) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RevisionHistoryTableComp));
