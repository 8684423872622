import React, { Component, useState }  from 'react';
import i18next from 'i18next';
import {RegulationsColDataMoreLessEvents} from './../ManageRegulations/RegulationsColData';

const RenderDataComponent = ({row, id, title}) => {
  const [hovered, setHovered] = useState(false)
  return (
      <span
        id={row.id+id}
        className={hovered ? 'readMoreCss' : 'truncateCss readMoreCss'}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        >
        {title}
      </span>
  )
}

//Manage topics table columns
export const TopicsColData = () => {
  return [
    {
        dataField: 'rowId',
        isKey: true,
        label: i18next.t('id'),
        hidden: true,
        placeholder: i18next.t('idPlaceholder')
    },
    {
        isKey: false,
        label: 'Action',
        hidden: false
    },
    {
        dataField: 'name',
        isKey: false,
        label: i18next.t('table.topic'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'togglename'} title={row.data && row?.data[localStorage.getItem('i18nextLng')]?.name} />
        },
        hidden: false,
        placeholder: i18next.t('topicPlaceholder')
    },
    {
        dataField: 'type',
        isKey: false,
        label: i18next.t('table.topicType'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'toggletype'} title={i18next.t(row.data && row.data[localStorage.getItem('i18nextLng')]?.type)} />
        },
        hidden: false,
        placeholder: i18next.t('topicTypePlaceholder')
    },
    {
        dataField: 'parentTopicName',
        isKey: false,
        label: i18next.t('table.correspondingSystem'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'toggleparentTopicName'} title={ row.data && row?.data[localStorage.getItem('i18nextLng')].parentTopicName} />
        },
        hidden: false,
        placeholder: i18next.t('correspondingSystemPlaceholder')
    },
    {
        dataField: 'regulationNumbers',
        isKey: false,
        label: i18next.t('table.regulationNo'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'toggleregulationNumbers'} title={ row.data && row?.data[localStorage.getItem('i18nextLng')].regulationNumbers} />

        },
        hidden: false,
        placeholder: i18next.t('regNoPlaceholder')
    },
    {
        dataField: 'scope',
        isKey: false,
        label: i18next.t('table.scope'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'togglescope'} title={row.data && row?.data[localStorage.getItem('i18nextLng')]?.scope?.name} />
        },
        hidden: false,
        placeholder: i18next.t('scopePlaceholder')
    },
    {
        dataField: 'subScope',
        isKey: false,
        label: i18next.t('table.subScope'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'togglesubScope'} title={row.data && row?.data[localStorage.getItem('i18nextLng')].subScope?.name} />
        },
        hidden: false,
        placeholder: i18next.t('subscopePlaceholder')
    },
    {
        dataField: 'group',
        isKey: false,
        label: i18next.t('table.group'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'togglegroup'} title={row.data && row?.data[localStorage.getItem('i18nextLng')]?.group?.name} />
        },
        hidden: false,
        placeholder: i18next.t('table.groupPlaceholder')
    },
    {
        dataField: 'subGroup',
        isKey: false,
        label: i18next.t('table.subGroup'),
        dataFormat: (cell, row) =>{
          return <RenderDataComponent row={row} id={'togglesubGroup'} title={row.data && row?.data[localStorage.getItem('i18nextLng')]?.subGroup?.name} />
        },
        hidden: false,
        placeholder: i18next.t('table.subGroupPlaceholder')
    }
  ]
}

export const languageArr = [
  {
    id:1,
    code:"en"
  },
  {
    id:2,
    code:"de"
  }
];

export const topicTypeArr = {
  en:[{
    id:1,
    type:'System',
    name: 'System'
  },{
    id:2,
    type:'Component',
    name: 'Component'
  }],
  de:[{
    id:1,
    type:'System',
    name: 'System'
  },{
    id:2,
    type:'Component',
    name: 'Komponente'
  }]
};
