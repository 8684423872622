import axios from 'axios';
var qs = require('qs');

/**
 * Request Wrapper with default success/error actions
 */

const request = async function (options, isHeader = true, isMock = process.env.REACT_APP_USE_MOCKS === 'true') {
    
    //let token = null;
    let header = (options.url === 'login' || options.url === 'user/verify' || options.url === 'user/forgotPassword' || options.url === 'user/resetPassword') ? null: {
        // add special header to XHR requests
        'X-Requested-With': 'XMLHttpRequest',
        // add authorization
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    };

    const get = (url, params) => axios.get(url, {
        params: params ? params : {},
        data: {},
        paramsSerializer: function(params) {
       return qs.stringify(params, {arrayFormat: 'repeat'})
    },
        baseURL: isMock ? process.env.REACT_APP_MOCK_BASE_URL : process.env.REACT_APP_BASE_URL,
        headers: header
    });

    const post = (url, data) => axios.post(url, data, {
        baseURL: isMock ? process.env.REACT_APP_MOCK_BASE_URL : process.env.REACT_APP_BASE_URL,
        headers: header
    });

    const put = (url, data) => axios.put(url, data, {
        baseURL: isMock ? process.env.REACT_APP_MOCK_BASE_URL : process.env.REACT_APP_BASE_URL,
        headers: header
    });

    const deleteRequest = (url, data) => axios.delete(url, {
        baseURL: isMock ? process.env.REACT_APP_MOCK_BASE_URL : process.env.REACT_APP_BASE_URL,
        headers: header,
        data
    })

    const onSuccess = function (response) {
        return response;
    }

    const onError = function (error) {

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx

        } else {
            // Something else happened while setting up the request
            // triggered the error
        }

        return Promise.reject(error.response || error.message);
    }

    if (options.method === 'GET') {
        return get(options.url, options.params)
            .then(onSuccess)
            .catch(onError);
    }

    if (options.method === 'POST') {
        return post(options.url, options.data)
            .then(onSuccess)
            .catch(onError);
    }

    if (options.method === 'PUT') {
        return put(options.url, options.data)
            .then(onSuccess)
            .catch(onError);
    }

    if (options.method === "DELETE") {
        return deleteRequest(options.url, options.data)
            .then(onSuccess)
            .catch(onError);
    }
}

export default request;
