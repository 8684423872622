import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import Actions from '../../internal/modules/Actions';
import {Button} from 'reactstrap';
import Form from "react-bootstrap/Form";
import $ from 'jquery';
import {languageArr} from './ScopesColData';
import ScopeList from './../../model/ScopeList';
import i18next from 'i18next';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './scope.css';

interface Props extends Translate{
  rowData?: any,
  formtype?: any;
  closeModal?: any;
  createScope: (any) => {};
  editScope: (any) => {};
  ScopeList: ScopeList[];
}

interface State{
  rowData?: any;
  fieldMsg?: any;
  isSubscopeChecked: boolean;
  selectedScope?: any;
  optionsScopeList?: any;
}

class ScopeForm extends Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        rowData: this.props.rowData,
        fieldMsg:{
          name:'',
          scope:''
        },
        isSubscopeChecked:false,
        selectedScope:[],
        optionsScopeList:[]
      }
  }

   componentDidMount(){
     //$("#scopeForm").find("span").hide();
     let {rowData} = this.state;
     //setting states when editing scopes/sub-scopes
     if (this.props.formtype === 'edit') {
       let selectedScopeObj = {};
       let noScopeFlag = true;
       if (this.props.rowData.data && this.props.rowData.data['en'].type &&
           (this.props.rowData.data['en'].type === 'Sub-scope')) {
         this.setState({isSubscopeChecked: true});
         [...languageArr].filter((lang) =>{
           if (!selectedScopeObj[lang.code]) {
             selectedScopeObj[lang.code] = [];
           }
           this.props.ScopeList && [...this.props.ScopeList].map(item =>{
               if ((this.props.rowData['data'][lang.code]['parentScopeId'] > 0) &&
                  (item.id === this.props.rowData['data'][lang.code]['parentScopeId'])) {
               let obj = {
                 id: item['id'],
                 name: item['data'][lang.code]['name'],
                 parentScopeName: item['data'][lang.code]['parentScopeName'],
                 parentScopeId: item['data'][lang.code]['parentScopeId'],
                 type: item['data'][lang.code]['type'],
                 languageCode: lang.code
               }
               selectedScopeObj[lang.code].push(obj);
               noScopeFlag = false;
             }
           });
           if (this.props.rowData.data[lang.code].type === 'Sub-scope' && noScopeFlag) {
              rowData['data'][lang.code]['parentScopeName'] = null;
              rowData['data'][lang.code]['parentScopeId'] = 0;
              rowData['data'][lang.code]['type'] = 'Sub-scope';
           }
         });
       }
       this.setState({selectedScope: selectedScopeObj});
     }
     let scopeListArr = {};
     if (this.props.formtype === 'add') {
       rowData['data'] = {};
     }
     [...languageArr].filter((lang) =>{
       //Setting blank value while adding new scope/sub-scope
       if (this.props.formtype === 'add') {
         rowData['data'][lang.code] = {};
         rowData['data'][lang.code]['parentScopeName'] = null;
         rowData['data'][lang.code]['parentScopeId'] = 0;
         rowData['data'][lang.code]['name'] = '';
         rowData['data'][lang.code]['type'] = 'Scope';
       }
       if (!scopeListArr[lang.code]) {
         scopeListArr[lang.code] = [];
       }
       //Structuring scope list for handling multiple language auto-selection
       this.props.ScopeList && [...this.props.ScopeList].map(item =>{
         if (item['data'] && item['data'][lang.code] && item['data'][lang.code]['type'] === 'Scope') {
           let obj = {};
           obj['id'] = item['id'];
           obj['name'] = item['data'][lang.code].name;
           obj['parentScopeName'] = item['data'][lang.code].parentScopeName;
           obj['parentScopeId'] = item['data'][lang.code].parentScopeId;
           obj['type'] = item['data'][lang.code].type;
           obj['languageCode'] = lang.code;
           scopeListArr[lang.code].push(obj);
         }
       });
     });
     this.setState({optionsScopeList: scopeListArr, rowData: rowData});
   }

   // setting the input field value of scope on change.
   inputHandler = (id, lang, event) =>{
     let arr = Object.keys(this.state.rowData).length < 1 ? {data:{}} : {...this.state.rowData};
     if (!arr.data[lang]) {
       arr.data[lang] = {};
     }
     arr.data[lang][id] = event.target.value;
     this.setState({rowData: arr});
   }

   // select scope if type is sub-scope.
   eventHandler = (id, lang, event) =>{
     let dataObj = {...this.state.rowData};
     let dataProps = this.state.optionsScopeList;
     let selectedScopeObj = {};
     if (!selectedScopeObj[lang]) {
       selectedScopeObj[lang] = [];
     }
     selectedScopeObj[lang] = event;
     if (!dataObj['data'][lang]) {
       dataObj['data'][lang] = {};
     }
     dataObj['data'][lang][id] = event.length > 0 ? event[0].name : null;
     if (id === 'parentScopeName') {
       dataObj['data'][lang]['parentScopeId'] = event.length > 0 ? event[0].id : null;
     }

     [...languageArr].map(allLang =>{
       if (allLang.code !== lang) {
         dataProps[allLang.code] && dataProps[allLang.code].filter(item => {
           if (event.length > 0 && (item.id === event[0].id)) {
             if (!selectedScopeObj[allLang.code]) {
               selectedScopeObj[allLang.code] = [];
             }
             selectedScopeObj[allLang.code].push(item);
             if (!dataObj['data'][allLang.code]) {
               dataObj['data'][allLang.code] = {};
             }
             dataObj['data'][allLang.code][id] = item.name;
             if (id === 'parentScopeName') {
               dataObj['data'][allLang.code]['parentScopeId'] = item.id;
             }
           }
           else if(event.length === 0){
             selectedScopeObj[allLang.code] = event;
           }
         });
       }
     });
     this.setState({selectedScope: selectedScopeObj});
   }

   // create scope and edit scope api call and check mandatory field.
   SaveFormHandler = (event) =>{
     event.preventDefault();
     let {fieldMsg, isSubscopeChecked} = this.state;
     fieldMsg['name'] = '';
     fieldMsg['scope'] = '';
     let flag = false;

     //Enabling error flag state
     [...languageArr].map(lang =>{
         if(!this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].name ||
           (this.state.rowData.data[lang.code].name && this.state.rowData.data[lang.code].name.trim()) === ''){
           fieldMsg['name'] = i18next.t('mngScope.inputNameErr');
           flag = true;
         }
         if(this.state.rowData.data[lang.code] && this.state.rowData.data[lang.code].type === 'Sub-scope' &&
                !this.state.rowData.data[lang.code].parentScopeName){
           fieldMsg['scope'] = i18next.t('mngScope.scopeSelectErr');
           flag = true;
         }
     });
     this.setState(fieldMsg);
      if (!flag) {
        let postArr = [];
        [...languageArr].map(lang =>{
          let obj = {
            id: this.state.rowData.id,
            name: this.state.rowData.data[lang.code]['name'],
            parentScopeId: this.state.rowData.data[lang.code]['parentScopeId'],
            parentScopeName: this.state.rowData.data[lang.code]['parentScopeName'],
            type: this.state.rowData.data[lang.code]['type'],
            languageCode: lang.code
          };
          postArr.push(obj);
        });
        if (this.props.formtype === 'edit') {
          this.props.editScope(postArr);
        }
        else{
          this.props.createScope(postArr);
        }
        this.props.closeModal();
      }
   }

   // Is sup-scope checked enabled select scope input field.
   isSubscopeHandler = (e) => {
     this.setState({isSubscopeChecked: e.target.checked});
     let dataObj = {...this.state.rowData};
     let selectedScopeObj = {};
     [...languageArr].map(lang =>{
       if (dataObj['data'] && dataObj['data'][lang.code]) {
         if (!e.target.checked) {
           dataObj['data'][lang.code]['parentScopeName'] = null;
           dataObj['data'][lang.code]['parentScopeId'] = 0;
           dataObj['data'][lang.code]['type'] = 'Scope';
           selectedScopeObj[lang.code] = [];
         }
         else{
           dataObj['data'][lang.code]['type'] = 'Sub-scope';
         }
       }
     });
     this.setState({rowData: dataObj, selectedScope: selectedScopeObj});
   }

  render() {
    let {rowData, fieldMsg, isSubscopeChecked, selectedScope} = this.state;

    return (
      <Translation>{t => (
        <Fragment>
          <form id="scopeForm">
            <div className="form-inline col-12">
              <div className="col-2"><h6 className="col-form-label"><b>{i18next.t('mngScope.attribute')}</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-5"><h6 className="col-form-label"><b>{i18next.t('language.'+lang.code)} *</b></h6></div>
                  )
                })
              }
            </div>
            <div className="form-inline col-12" style={{'paddingTop':'5px'}}>
              <div className="col-12"><h6><b>
              <Form.Group>
                <Form.Check
                  id="isSubscopeKey"
                  inline
                  key="isSubscopeKey"
                  onChange={this.isSubscopeHandler}
                  type="checkbox"
                  checked={isSubscopeChecked}
                  label={i18next.t('mngScope.isSubscopeLabel')}
                />
              </Form.Group></b></h6></div>
            </div>
            <div className="form-inline col-12" style={{'paddingTop':'5px'}}>
              <div className="col-2"><h6><b>{i18next.t('mngScope.name')} *</b></h6></div>
                {
                  [...languageArr].map(lang =>{
                    return(
                      <div className="col-5 labelTextCss">
                        <h6 className="scopeContainer">
                          <input title={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].name && rowData.data[lang.code].name} type="text" id={'scopeContainer'+lang.code} value={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].name && rowData.data[lang.code].name} onChange={this.inputHandler.bind(this, 'name', lang.code)} className="form-control" placeholder={t('mngScope.inputName')} required/>
                        </h6>
                      </div>
                    )
                  })
                }
            </div>
            <div className="form-inline col-12" style={{'paddingTop':'5px'}} id="selectScopeToggleDiv">
              <div className="col-2"><h6><b>{i18next.t('mngScope.selectScope')}</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-5 labelTextCss">
                      <h6>
                        <Typeahead
                            id="basic-typeahead-single"
                            emptyLabel={t('noMatchFound')}
                            clearButton
                            disabled={!isSubscopeChecked}
                            labelKey="name"
                            selected={selectedScope[lang.code]}
                            options={this.state.optionsScopeList[lang.code] ? this.state.optionsScopeList[lang.code] : []}
                            onChange={this.eventHandler.bind(this, 'parentScopeName', lang.code)}
                            placeholder={t('mngScope.selectScope')}
                        />
                      </h6>
                    </div>
                  )
                })
              }
            </div>
            {
              <ul>
              {Object.keys(this.state.fieldMsg).map(errKey =>{
                if (this.state.fieldMsg[errKey] !== '') {
                  return(
                    <li style={{'color':'red'}}>{this.state.fieldMsg[errKey]}</li>
                  )
                }
              })}
              </ul>
            }
            <hr />
            <div className="form-group row">
              <div className="col-12" style={{'textAlignLast':'end'}}>
                <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
                <Button color="primary" onClick={this.SaveFormHandler}>{i18next.t('save')}</Button>
              </div>
            </div>
          </form>
          </Fragment>
      )}</Translation>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({
  ScopeList: appState.admin.ScopeList
});

const mapDispatchToProps = dispatch => ({
  createScope: (scope) => Actions.admin.createScope(dispatch, scope),
  editScope: (scope) => Actions.admin.editScope(dispatch, scope)
});

export default connect(mapStateToProps, mapDispatchToProps)(ScopeForm);
