import i18next from 'i18next';
import React,{ useState } from 'react';

//Custom dataField component to hide/show table cell data
const RenderDataComponent = ({row, id, title}) => {
  const [hovered, setHovered] = useState(false)
  return (
      <span
        id={row.id+id}
        className={hovered ? 'readMoreCss' : 'truncateCss readMoreCss'}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        >
        {title}
      </span>
  )
}

export const FaqColData = () => {
    return [
        {
            dataField: 'rowId',
            isKey: true,
            label: i18next.t('id'),
            hidden: true,
            placeholder: i18next.t('idPlaceholder')
        },
        {
            isKey: false,
            label: 'Action',
            hidden: false
        },
        {
            dataField: 'question',
            isKey: false,
            label: i18next.t('mngFaqs.questionLabel'),
            hidden: false,
            dataFormat: (cell, row) =>{
                // return row.data && row.data[localStorage.getItem('i18nextLng')].question;
                return <RenderDataComponent row={row} id={'togglename'} title={row.data && row.data[localStorage.getItem('i18nextLng')]?.question} />
              },
        },
        {
            dataField: 'answer',
            isKey: false,
            label: i18next.t('mngFaqs.answerLabel'),
            hidden: false,
            dataFormat: (cell, row) =>{
                // return row.data && row.data[localStorage.getItem('i18nextLng')].answer;
                return <RenderDataComponent row={row} id={'togglename'} title={row.data && row.data[localStorage.getItem('i18nextLng')]?.answer} />
              },
        },
    ]
}

export const languageArr = [
    {
      id:1,
      code:"en"
    },
    {
      id:2,
      code:"de"
    }
  ];