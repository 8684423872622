import React, { Component, Fragment } from "react";
import "./chat.css";
import { toast } from "react-toastify";
import ChatBot from "../../assets/utils/images/avatars/chatbotAvatar.png";
import UserImg from "../../assets/utils/images/avatars/dummyUser.png";
import { AppState } from "./../../internal/modules/Reducers";
import { connect } from "react-redux";
import { Translation, withTranslation } from "react-i18next";
import Actions from '../../internal/modules/Actions';
import axios from "axios";
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';
import Select from 'react-select';
import {Row, CustomInput, Button} from 'reactstrap';
import i18next from "i18next";
import { Translate } from "../../model/interfaces/translate.interface";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ReactTable from "react-table-6";  
import { element } from "prop-types";

interface Props extends Translate{
 data ?: any;
}

interface State {
   
}

 export class ChatBotTableComp extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
       
    };
  }

  componentDidMount() {
   
  }
  componentDidUpdate(prevProps, prevState) {
   
  }

  render() {
      let columns = Object.keys(this.props.data && this.props.data[0]).map(key =>{

        return {
        //set header and accessor
        
        Header: key,
        accessor: key,
        Cell: (props) => {
            return(
              props.column.Header === "Link" ?
              <a target= "_blank" href={props.original.Link} style={{ cursor : "pointer"}}>
                {props.value}
              </a>:
              <a>{props.value}</a>
            )
        },
        filterMethod: (filter, row) => {
             return row[key].toLowerCase().includes(filter.value)
          }
        }
        
        })
   return (
    <div className="chatBotTable">
    <ReactTable 
    data={this.props.data.length !== 0 ? this.props.data : ''}
    columns={columns}
    defaultPageSize={5}
    filterable
   />
   </div>
   )
  }
}

const mapStateToProps = (appState: AppState) => ({
  RecentGPTQuestionsList: appState.user.RecentGPTQuestionsList,
  });

const mapDispatchToProps = dispatch => ({
  getRecentGPTQuestions : () => Actions.user.getRecentGPTQuestions(dispatch)
  });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatBotTableComp));

