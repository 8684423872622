import React, { Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Container, Row, Col, Card, CardTitle, ListGroupItem, CardBody, Button, CardText } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import './dashboard.css'

interface Props extends Translate{

}

interface State{
}

class AdminDashboard extends React.Component<Props, State> {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    renderCard = (navigateTo, text) => {
        return (
            <Col md={4}>
                <CardBody>
                    <Link to={navigateTo} style={{ textDecoration: 'none' }}>
                        <Card body className="adminCard">
                            <CardText style={{ alignSelf: 'center', margin: 20, fontSize: 18 }}>
                                {text}
                            </CardText>
                        </Card>
                    </Link>
                </CardBody>
            </Col>
        )
    }

    render() {
        return (
            <Translation>{t => (<Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Container fluid >
                        <Row>
                            {/* Admin dashboard cards */}
                            {this.renderCard('users', t('sidebar.manageUser'))}
                            {this.renderCard('manage-regulations', t('sidebar.manageReg'))}
                            {this.renderCard('manage-markets', t('sidebar.manageMarkets'))}
                            {this.renderCard('manage-value', t('sidebar.mngMarketProfileValue'))}
                            {this.renderCard('manage-vehicles', t('sidebar.manageVehicles'))}
                            {this.renderCard('manage-topics', t('sidebar.manageTopics'))}
                            {this.renderCard('manage-drive-type', t('sidebar.mngDriveType'))}
                            {this.renderCard('manage-groups', t('sidebar.manageGroups'))}
                            {this.renderCard('manage-fuel-type', t('sidebar.manageFuelType'))}
                            {this.renderCard('manage-scopes', t('sidebar.manageScopeSubscope'))}
                            {this.renderCard('manage-faqs', t('sidebar.manageFaqs'))}
                            {this.renderCard('manage-revision-history', t('sidebar.mngRevHistory'))}
                        </Row>
                    </Container>
                </ReactCSSTransitionGroup>
            </Fragment>)}</Translation>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
