import React, { Component } from "react";
import "./landingPage.css";
import { Translation} from "react-i18next";
import { withTranslation } from 'react-i18next';
import { FaQuestionCircle } from "react-icons/fa";
import { BsChatDotsFill} from 'react-icons/bs';
import SubIcon from "../../subIcon.png";
import UpdateIcon from "../../updateIcon.png";
import RecentActivityIcon from "../../recentActivityIcon.png";
import { connect } from "react-redux";
import Actions from "../../internal/modules/Actions";
import EditModalComp from "../Table/EditModal";
import Faq from "./Faq";
import Footer from "./Footer";
import ReactTooltip from "react-tooltip";
import i18next from "i18next";
import { Translate } from "../../model/interfaces/translate.interface";
import { AppState } from "./../../internal/modules/Reducers";
import Loader from "react-loader-advanced";
import LoaderAnim from "react-loaders";
import moment from "moment";
import { Row } from "reactstrap";
import UpdateDetailsTableComp from "../Table/UpdateDetailsTable";
import infoImage from "./../../InfoImage.png";
import RegDetails from "../Table/RegDetails";
import TranslationService from "../../utils/TranslationService";
import Chatbot from "../ChatGPT/chat";
import { Button } from "reactstrap";

const t = TranslationService.t;

interface Props extends Translate {
  getRegulationUpdates: (lastLogin: any) => {};
  RegulationUpdates?: any;
  RegulationIdList?: any;
  getRegulationDetailsById: (id: number) => {};
  regulationIdFetched: boolean;
  subscriptionAdded: boolean;
  activeKey?: any;
  history?: any;
  location?: any;
  searchFilter?: any;
  reloadTemplate: (any) => {};
  MarketList?: any;
  getMarketList: () => {};
  getSubscriptions: (lastLogin: string) => {};
  Subscriptions: any;
  getFaqList: () => {};
  userLogged: boolean;
  FaqList?: any;
  getTabChange: (any) => {};
  getSearchTagList: () => {};
  getTopicList: () => {};
  getVehicleList: () => {};
  getFavoritesList: (any) => {};
  getGroupList: () => {};
  getVehicleFuelTypeList: () => {};
  getDriveTypeList: () => {};
  getScopeList: () => {};
  getSubscibedTopics: () => {};
  SubscribedTopicList?: any;
  TopicList?: any;
  VehicleList?: any;
  SearchTagList?: any;
  GroupList?: any;
  SubGroupList?: any;
  ScopeList?: any;
  SubScopeList?: any;
  DriveTypeList?: any;
  VehicleFuelTypeList?: any;
  getUserById: (id) => {};
  user: any;
  loading: boolean;
  editUserList: (any) => {};
  getReferenceRegulationDetails: (regulationNumber) => {};
  referenceRegFetched: boolean;
  RefRegulationDetailsList?: any;
  getRecentGPTQuestions : () => {};
}

interface State {
  showFaq: boolean;
  lastTab?: any;
  showDetailRegulation: boolean;
  showRegulationType: string;
  subTopics: any;
  showUpdateDetailsTable?: any;
  tableData?: any;
  regTableData?: any;
  updatedAttributes?: any;
  flag?: any;
  showChatBot: boolean;
}
class LandingPage extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showFaq: false,
      lastTab: [],
      showDetailRegulation: false,
      showRegulationType: "actual",
      subTopics: null,
      showUpdateDetailsTable: false,
      tableData: [],
      updatedAttributes: [],
      regTableData: [],
      flag: false,
      showChatBot: false
    };

    this.toggle = this.toggle.bind(this);
    this.toggleChatBot = this.toggleChatBot.bind(this);
  }

  componentDidMount() {
    let tabData =
      localStorage.getItem("tab") !== null || 0
        ? localStorage.getItem("tab")
        : "";
    this.setState({ lastTab: tabData });
    if (!this.props.TopicList) {
      this.props.getTopicList();
    }
    if (!this.props.MarketList) {
      this.props.getMarketList();
    }
    if (!this.props.VehicleList) {
      this.props.getVehicleList();
    }
    if (!this.props.SearchTagList) {
      this.props.getSearchTagList();
    }
    if (!this.props.GroupList) {
      this.props.getGroupList();
    }
    if (!this.props.DriveTypeList) {
      this.props.getDriveTypeList();
    }
    if (!this.props.VehicleFuelTypeList) {
      this.props.getVehicleFuelTypeList();
    }
    if (!this.props.ScopeList) {
      this.props.getScopeList();
    }
    if (!this.props.SubscribedTopicList) {
      this.props.getSubscibedTopics();
    }

    const userId = JSON.parse(localStorage.getItem("user"))?.id;
    const lastLogin = JSON.parse(localStorage.getItem("user"))?.lastLogin;
    const newDate = moment(lastLogin).format("DD/MM/YYYY, HH:mm:ss");
    const splittedString = newDate.split(",");
    const d = new Date();
    const month = d.getMonth() + 1;
    const currentDate = `${d.getDate()}/${month}/${d.getFullYear()}`;
    this.props.getSubscriptions(
      splittedString.length > 0 ? splittedString[0] : currentDate
    );
    this.props.getRegulationUpdates(
      splittedString.length > 0 ? splittedString[0] : currentDate
    );
    this.props.getUserById(userId);
  }

  regUpdateInfo(regNum?: any, data?: any) {
    //this.props.getRegulationDetailsById(id);
    this.props.getReferenceRegulationDetails(regNum);
    this.setState({ regTableData: regNum, flag: false });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      if (this.props.location?.state?.data === "searchFilter") {
        this.props.searchFilter(true);
      }
    }

    if (this.props.user !== prevProps.user) {
      const topics =
        this.props.user &&
        this.props.user?.subscribedTopics?.filter(
          (topic) => topic.languageCode === localStorage.getItem("i18nextLng")
        );
      this.setState({ subTopics: topics });
    }

    if (
      this.props.regulationIdFetched &&
      prevProps.regulationIdFetched !== this.props.regulationIdFetched
    ) {
      this.setState({ showDetailRegulation: true });
    }
    if (this.props.referenceRegFetched &&prevProps.referenceRegFetched !== this.props.referenceRegFetched) {
      if (this.props.RefRegulationDetailsList.id !== null) {
        this.setState({showDetailRegulation: true,regTableData: this.props.RefRegulationDetailsList,flag: true,});
      }
    }
  }
  toggle() {
    this.setState({
      showFaq: !this.state.showFaq,
    });
    this.props.getFaqList();
  }

  toggleChatBot () {
    this.setState({
      showChatBot: !this.state.showChatBot
    })
    this.props.getRecentGPTQuestions();
  }
  //close edit profile and reset password modal.
  closeModal = () => {
    this.setState({ showFaq: false, showChatBot: false });
  };

  //close detailed regulation modal
  closeModalRegDetail = () => {
    this.setState({
      showDetailRegulation: false,
      showUpdateDetailsTable: false,
    });
  };

  openGRM = () => {
    const tab = "1";
    this.props.getTabChange(tab);
    localStorage.setItem("tab", "1");
    const dateUTC = new Date().toISOString();
    let obj = {
      recentTab: 1,
      recentSearch: localStorage.getItem("recentSearch"),
      lastLogin: localStorage.getItem("lastLogin"),
    };
    this.props.editUserList(obj);
    if (this.props.location.pathname.includes("/GRM")) {
      this.props.history.push({
        pathname: "/app/dashboard/1/GRM",
        search: this.props.location.search,
      });
    } else {
      this.props.history.push({
        pathname: "/app/dashboard/1",
      });
    }
  };
  handleLastVisitedTab = () => {
    // const lng = localStorage.getItem("i18nextLng");
    // const user = JSON.parse(localStorage.getItem("user"));
    // const token = localStorage.getItem("token").toString();
    // const withoutQuotes = token.replace(/"/g, "");
    // const queryString = `id=${user?.id}&token=${withoutQuotes}&i18nextLng=${lng}`;
    // const tab = localStorage.getItem("tab");

    // this.props.getTabChange(tab);

    // const commonPath = `/app/dashboard/${tab}`;
    // let pathname = commonPath;

    // if (this.props.location.pathname.includes("/GRM")) {
    //   pathname += "/GRM";
    // }

    // const pathObject = {
    //   pathname,
    //   search: queryString,
    // };

    // this.props.history.push(pathObject);

    let lng = localStorage.getItem("i18nextLng");
    let user = JSON.parse(localStorage.getItem("user"));
    let token = localStorage.getItem("token").toString();
    let withoutQuotes = token.replace(/"/g, "");
    let queryString = `id=${user?.id}` + `&token=${withoutQuotes}` + `&i18nextLng=${lng}`;
    this.props.getTabChange(localStorage.getItem("tab"));
    if (this.props.location.pathname.includes("/GRM")) {
      this.props.history.push({
        pathname: `/app/dashboard/${localStorage.getItem("tab")}/GRM`,
        search: queryString,
      });
    } else {
      this.props.history.push({
        pathname: `/app/dashboard/${localStorage.getItem("tab")}`,
      });
    }
  };
  handleLastSearchFilter = () => {
    let lng = localStorage.getItem("i18nextLng");
    let user = JSON.parse(localStorage.getItem("user"));
    let token = localStorage.getItem("token").toString();
    let withoutQuotes = token.replace(/"/g, "");
    let queryString =
      `id=${user?.id}` + `&token=${withoutQuotes}` + `&i18nextLng=${lng}`;
    const tab = 2;
    const dateUTC = new Date().toISOString();
    let obj = {
      recentTab: 2,
      recentSearch: localStorage.getItem("recentSearch"),
      lastLogin: localStorage.getItem("lastLogin"),
    };
    this.props.editUserList(obj);
    if (this.props.location.pathname.includes("/GRM")) {
      this.props.history.push({
        pathname: "/app/dashboard/2/GRM",
        search: queryString,
        state: {
          data: "searchFilter",
        },
      });
    } else {
      this.props.history.push({
        pathname: "/app/dashboard/2",
        state: {
          data: "searchFilter",
        },
      });
    }
  };
  goToSubcription = () => {
    const dateUTC = new Date().toISOString();
    let obj = {
      recentTab: 6,
      recentSearch: localStorage.getItem("recentSearch"),
      lastLogin: localStorage.getItem("lastLogin"),
    };
    this.props.editUserList(obj);
    const tab = 6;
    this.props.getTabChange(tab);
    let lng = localStorage.getItem("i18nextLng");
    let user = JSON.parse(localStorage.getItem("user"));
    let token = localStorage.getItem("token").toString();
    let withoutQuotes = token.replace(/"/g, "");
    let queryString =
      `id=${user?.id}` + `&token=${withoutQuotes}` + `&i18nextLng=${lng}`;

    if (this.props.location.pathname.includes("/GRM")) {
      this.props.history.push({
        pathname: `/app/dashboard/6/GRM`,
        search: queryString,
      });
    } else {
      this.props.history.push({
        pathname: `/app/dashboard/6`,
      });
    }
  };
  handleDetails = (tableData) => {
    this.setState({ showUpdateDetailsTable: true, tableData: tableData });
  };
  revUpdateInfo = (regNum) => {
    this.props.getReferenceRegulationDetails(regNum);
  };

  chatClick= () =>{
     this.props.history.push({
        pathname: `/app/chat`,
      });
  }
  openInNewTab = (url) =>{
    window.open(url, "_blank", "noreferrer")
  }
  render() {
    const spinner4 = <LoaderAnim active type="ball-beat" />;
    const { RegulationUpdates, Subscriptions, FaqList } = this.props;
    const { subTopics } = this.state;
    const lastLogin = JSON.parse(localStorage.getItem("user"))?.lastLogin;
    const newDate = moment(lastLogin).format("DD/MM/YYYY, HH:mm:ss");
 //   const newDate = date.format("DD/MM/YYYY, HH:mm:ss");
    const withoutQuotes = newDate?.replace(/(^"|"$)/g, "");
    const splittedString = withoutQuotes.split(",");
    const user_id=  JSON.parse(localStorage.getItem('user'))?.id
    let formTemplate,
      modalTitle = null;

    if (this.state.showFaq === true) {
      {
        formTemplate = FaqList.map((f) => {
          return <Faq key={f.id} {...f} />;
        });
        modalTitle = i18next.t("homePage.faqTitle");
      }
    }

    if(this.state.showChatBot === true) {
      formTemplate = <Chatbot />
    }
    const tab =
      localStorage.getItem("tab") !== null ? localStorage.getItem("tab") : 0;
    const userRecentActivity = localStorage.getItem("recentSearch");
    return (
      <>
        <Translation>
          {(t) => (
            <>
             {this.state.showChatBot && 
                <EditModalComp 
                  closeModal={this.closeModal}
                  modalID="chatBotModal"
                  modalBody = {formTemplate}
                  modalTitle ={t('chatBot.modalTitle')}
                />
             }
              {this.state.showFaq && (
                <EditModalComp
                  closeModal={this.closeModal}
                  formName={"FAQ"}
                  modalBody={formTemplate}
                  modalTitle={modalTitle}
                />
              )}
              {this.state.showDetailRegulation && (
                <EditModalComp
                  closeModal={this.closeModalRegDetail}
                  modalID="regulationDetails"
                  modalBody={
                    <RegDetails
                      rowData={this.state.regTableData}
                      flag={this.state.flag}
                      market={this.state.regTableData.market.value}
                    />
                  }
                  modalTitle={t("table.regulationDataTitle")}
                />
              )}
              {this.state.showUpdateDetailsTable && (
                <EditModalComp
                  closeModal={this.closeModalRegDetail}
                  modalID="updatedRegulationModal"
                  modalBody={
                    <UpdateDetailsTableComp tableData={this.state.tableData} />
                  }
                  modalTitle={t("homePage.detailUpdateTableTitle")}
                />
              )}
               <Loader message={spinner4} show={this.props.loading}>
              <div className="landpage_container" id="homePageComponent">
                <div className="faq">
                  <a
                    data-tip={i18next.t("homePage.faqTooltip")}
                    data-for="editHandler"
                  >
                    <FaQuestionCircle
                      className="faqIcon"
                      onClick={this.toggle}
                    />
                  </a>
                  <ReactTooltip
                    id="editHandler"
                    place="left"
                    type="info"
                    effect="float"
                  />
                </div>
                { (user_id === 1 || user_id === 2 || user_id === 3) &&
                <div className="chat">
                  <a
                    // data-tip={i18next.t("homePage.faqTooltip")}
                    data-tip={'ChatBot'}
                    data-for="editHandler"
                  >
                    <BsChatDotsFill
                      className="chatIcon"
                      onClick={this.toggleChatBot}
                    />
                  </a>
                  <ReactTooltip
                    id="editHandler"
                    place="left"
                    type="info"
                    effect="float"
                  />
                </div>}
              <div className="col-sm-12 form-inline">
              <div className="col-sm-4 form-inline" style={{paddingLeft : 0,paddingBottom: '20px'}}>
                   <div >
                   <div style={{paddingBottom: '20px', paddingLeft: 0}}>
                        <div className="titleBox roundedCorner">
                          <p className="featTitle">{t("homePage.grmTitle")}</p>
                          <p className="desc">{t("homePage.grmSubTitle")}</p>
                          <button
                            className="open-btn mb-2 align-items-center"
                            onClick={this.openGRM}
                          >
                            {t("homePage.grmBtnText")}
                          </button>
                        </div>
                   </div>
                   <div  style={{paddingBottom: '20px', paddingLeft: 0}}>
                    <div className="meetingBox roundedCorner">
                      <p className="featTitle">{t('dashboard.upcomingMeeting')}</p>
                      <ul style={{paddingLeft: '20px', marginBottom: '0'}}>
                        <li>
                        <Button color="link" className="meetingBtn" onClick={() => this.openInNewTab("https://unece.org/transport/vehicle-regulations/wp29-meetings")} >ECE</Button>
                        </li>
                      </ul>
                      {/* <a style={{fontSize: '18px'}} target="_blank" href={"https://unece.org/transport/vehicle-regulations/wp29-meetings"}>ECE</a> */}
                    </div>
                   </div>
                   <div  style={{ paddingLeft: 0}}>
                        <div className="recentActivityBox roundedCorner">
                           <div className="featTitleBox">
                              <p className="featTitle">
                                {t("homePage.recentActivityTitle")}
                              </p>
                              <img
                                src={RecentActivityIcon}
                                alt="Recent Activity icon"
                                id="recentActIcon"
                                // width={25}
                                // height={21}
                              />
                           </div>
                              {lastLogin !== "null" && (
                                  <p className="lastlogin">{`${t(
                                    "homePage.lastLogin"
                                  )}  ${splittedString[0]}  ${t("homePage.at")} ${
                                    splittedString[1]
                                  } `}</p>
                                )}
                         <div className="featPoints">
                            <ul className="recentActivityPointsList">
                                {this.state.lastTab != 0 && (
                                  <li>
                                    {t("homePage.lastTab")}{" "}
                                    <a
                                      style={{ cursor: "pointer", color: "#545cd8" }}
                                      onClick={this.handleLastVisitedTab}
                                    >
                                      {t(`dashboard.tabTitle${tab}`)}
                                    </a>
                                  </li>
                                )}
                                {userRecentActivity !== "null" &&
                                  userRecentActivity !== "" && (
                                    <li>
                                      {t("homePage.lastFilter")}{" "}
                                      <a
                                        style={{
                                          cursor: "pointer",
                                          color: "#545cd8",
                                        }}
                                        onClick={this.handleLastSearchFilter}
                                      >
                                        https://grm.vetaca-homologation.com/#/app/dashboard/2
                                      </a>
                                    </li>
                                  )}
                            </ul>
                        </div>
                      </div>
                      </div>
                      </div>
                   </div>
                   <div className="col-sm-8" style={{paddingBottom: '20px'}}>
                   <div>
                    <div  style={{paddingBottom: '20px'}}>
                         <div className="subBox roundedCorner">
                          <div className="subcontainer">
                              <div className="featTitleBox">
                                <p className="featTitle">
                                  {t("homePage.subscribeTitle")}
                                </p>
                                <img
                                  src={SubIcon}
                                  alt="Update icon"
                                  id="subIcon"
                                  // width={28}
                                  // height={26}
                                />
                                <p
                                  className="subMoreText"
                                  onClick={this.goToSubcription}
                                >
                                  {t("homePage.subMore")}
                                </p>
                              </div>
                                    <div className="subTopicWrapper">
                                        {subTopics && subTopics.length !== 0 && (
                                          <p className="subText">
                                            {t("homePage.subTopicTitle")}
                                          </p>
                                        )}
                                          <div className="subTopics">
                                            {subTopics &&
                                              subTopics.map((subs, i) => {
                                                if (i === subTopics.length - 1) {
                                                  return (
                                                    <p className="subTopic">{subs.name}</p>
                                                  );
                                                } else {
                                                  return (
                                                    <p className="subTopic">{subs.name} , </p>
                                                  );
                                                }
                                              })}
                                          </div>
                                    </div>
                                    {subTopics && subTopics.length !== 0 && (
                                      <p className="subText" style={{paddingLeft: '10px'}}>
                                        {t("homePage.subUpdateTitle")}
                                      </p>
                                    )}
                                    <div className="subPointContainer">
                                    <ul className="subPoints">
                                      {Subscriptions.length ? (
                                        Subscriptions.map((sub) => {
                                          let d = new Date(sub?.date);
                                          const month = d.getMonth() + 1;
                                          const date =
                                            d.getDate() +
                                            "/" +
                                            month +
                                            "/" +
                                            d.getFullYear();
                                          let rev =  sub?.market === 'EU27' ? sub?.revisionList?.map(
                                            (item, index) =>
                                              item.link !== "" ? (
                                                <a target="_blank" href={item.link}>
                                                  {(index ? ", " : "") + item.euRegulation}
                                                </a>
                                              ) : (
                                                <a>{(index ? ", " : "") + item.euRegulation}</a>
                                              )
                                          ) :
                                          sub?.revisionList?.map(
                                            (item, index) =>
                                              item.link !== "" ? (
                                                <a target="_blank" href={item.link}>
                                                  {(index ? ", " : "") + item.name}
                                                </a>
                                              ) : (
                                                <a>{(index ? ", " : "") + item.name}</a>
                                              )
                                          )
                                          ;
                                          let regLink = (
                                            <a
                                              className="point"
                                              onClick={() => {
                                                this.regUpdateInfo(sub?.regulationNumber);
                                              }}
                                              href="javascript:void(0)"
                                            >
                                              {sub?.regulationNumber}
                                            </a>
                                          );
                                          let regNumbersForRev = (
                                            <a
                                              className="point"
                                              onClick={() => {
                                                this.revUpdateInfo(sub?.regulationNumber);
                                              }}
                                              href="javascript:void(0)"
                                            >
                                              {sub?.regulationNumber}
                                            </a>
                                          );
                                          let removedRegLink = (
                                            <a>{sub?.regulationNumber}</a>
                                          );
                                          // return <li>{t('homePage.UpdateStart')} {date} : {" "}{t('homePage.in')} {sub?.regulation.market?.label} {t('homePage.market')}, {regLink} {t('homePage.forTopic')} {sub?.regulation?.topic?.name}</li>
                                          if (sub?.crudtype === 0) {
                                            return (
                                              <div>
                                                <Row>
                                                  <li>
                                                    {t("homePage.UpdateStart")} {date} :{" "}
                                                    {t("homePage.in")} {sub?.market}{" "}
                                                    {t("homePage.market")}, {regLink}{" "}
                                                    {t("homePage.forTopic")}{" "}
                                                    {sub?.topicName}
                                                  </li>
                                                </Row>
                                              </div>
                                            );
                                          } else if (
                                            sub?.crudtype === 1 &&
                                            sub?.attributeChanges.length !== 0
                                          ) {
                                            return (
                                              <div>
                                                <Row>
                                                  <li>
                                                    {t("homePage.UpdateStart")} {date} :{" "}
                                                    {t("homePage.in")} {sub?.market}{" "}
                                                    {t("homePage.market")}, {regLink}{" "}
                                                    {t("homePage.forTopic")}{" "}
                                                    {sub?.topicName}
                                                  </li>
                                                  <a
                                                    data-tip={i18next.t(
                                                      "homePage.detailIconTooltip"
                                                    )}
                                                    data-for="resendMailHandler"
                                                  >
                                                    <img
                                                      onClick={() => {
                                                        this.handleDetails(
                                                          sub.attributeChanges
                                                        );
                                                      }}
                                                      src={infoImage}
                                                      style={{
                                                        cursor: "pointer",
                                                        marginLeft: "10px",
                                                      }}
                                                      height="18px"
                                                    />
                                                  </a>
                                                  <ReactTooltip
                                                    id="resendMailHandler"
                                                    place="left"
                                                    type="info"
                                                    effect="float"
                                                  />
                                                </Row>
                                              </div>
                                            );
                                          } else if (sub?.crudtype === 2) {
                                            return (
                                              <div>
                                                <Row>
                                                  <li>
                                                    {t("homePage.UpdateStart")} {date} :{" "}
                                                    {t("homePage.in")} {sub?.market}{" "}
                                                    {t("homePage.market")},{" "}
                                                    {removedRegLink}{" "}
                                                    {t("homePage.forTopic")}{" "}
                                                    {sub?.topicName}
                                                  </li>
                                                </Row>
                                              </div>
                                            );
                                          } else if (sub?.crudtype === 3) {
                                            return (
                                              <div>
                                                <Row>
                                                  <li>
                                                    {t("homePage.newRevHistoryAddedText")}{" "}
                                                    {rev} {t("homePage.AddedText")} {date}{" "}
                                                    : {t("homePage.in")} {sub?.market}{" "}
                                                    {t("homePage.market")},{" "}
                                                    {t("homePage.regulations")}{" "}
                                                    {regNumbersForRev}.
                                                  </li>
                                                </Row>
                                              </div>
                                            );
                                          } else if (sub?.crudtype === 4) {
                                            return (
                                              <div>
                                                <Row>
                                                  <li>
                                                    {t("homePage.revHistoryUpdateText")}{" "}
                                                    {rev} {t("homePage.updateOnText")}{" "}
                                                    {date} : {t("homePage.in")}{" "}
                                                    {sub?.market} {t("homePage.market")},{" "}
                                                    {t("homePage.regulations")}{" "}
                                                    {regNumbersForRev}.
                                                  </li>
                                                  <a
                                                    data-tip={i18next.t(
                                                      "homePage.detailIconTooltip"
                                                    )}
                                                    data-for="resendMailHandler"
                                                  >
                                                    <img
                                                      onClick={() => {
                                                        this.handleDetails(
                                                          sub?.attributeChanges
                                                        );
                                                      }}
                                                      src={infoImage}
                                                      style={{ cursor: "pointer",marginLeft: "10px" }}
                                                      height="18px"
                                                    />
                                                  </a>
                                                  <ReactTooltip
                                                    id="resendMailHandler"
                                                    place="left"
                                                    type="info"
                                                    effect="float"
                                                  />
                                                </Row>
                                              </div>
                                            );
                                          } else if (sub?.crudtype === 5) {
                                            return (
                                              <div>
                                                <Row>
                                                  <li>
                                                    {t("homePage.revHistoryUpdateText")}{" "}
                                                    {rev} {t("homePage.removedOnText")}{" "}
                                                    {date} : {t("homePage.in")}{" "}
                                                    {sub?.market} {t("homePage.market")},{" "}
                                                    {t("homePage.regulations")}{" "}
                                                    {regNumbersForRev}.
                                                  </li>
                                                </Row>
                                              </div>
                                            );
                                          }
                                        })
                                      ) : (
                                        <p>
                                          {this.props.user &&
                                          this.props.user.subscribedTopics?.length
                                            ? t("homePage.noSubUpdateStart")
                                            : t("homePage.noSubstart")}
                                        </p>
                                      )}
                                    </ul>
                                </div>
                                </div>
                         </div>
                     </div>
                    {/* <div className="col-sm-12 form-inline" style={{minHeight: '30vh',paddingLeft: 0}}> 
                      <div className="col-4" style={{paddingBottom: '10px', paddingLeft: 0}}>
                        <div className="recentActivityBox roundedCorner">
                           <div className="featTitleBox">
                              <p className="featTitle">
                                {t("homePage.recentActivityTitle")}
                              </p>
                              <img
                                src={RecentActivityIcon}
                                alt="Recent Activity icon"
                                id="recentActIcon"
                                // width={25}
                                // height={21}
                              />
                           </div>
                              {lastLogin !== "null" && (
                                  <p className="lastlogin">{`${t(
                                    "homePage.lastLogin"
                                  )}  ${splittedString[0]}  ${t("homePage.at")} ${
                                    splittedString[1]
                                  } `}</p>
                                )}
                         <div className="featPoints">
                            <ul className="recentActivityPointsList">
                                {this.state.lastTab != 0 && (
                                  <li>
                                    {t("homePage.lastTab")}{" "}
                                    <a
                                      style={{ cursor: "pointer", color: "#545cd8" }}
                                      onClick={this.handleLastVisitedTab}
                                    >
                                      {t(`dashboard.tabTitle${tab}`)}
                                    </a>
                                  </li>
                                )}
                                {userRecentActivity !== "null" &&
                                  userRecentActivity !== "" && (
                                    <li>
                                      {t("homePage.lastFilter")}{" "}
                                      <a
                                        style={{
                                          cursor: "pointer",
                                          color: "#545cd8",
                                        }}
                                        onClick={this.handleLastSearchFilter}
                                      >
                                        https://grm.vetaca-homologation.com/#/app/dashboard/2
                                      </a>
                                    </li>
                                  )}
                            </ul>
                        </div>
                      </div>
                      </div> */}
                      <div style={{ paddingRight: 0}} >
                          <div className="updateBox roundedCorner">
                                <div className="featTitleBox">
                                    <p className="featTitle">
                                      {t("homePage.updatesTitle")}
                                    </p>
                                    <img
                                      src={UpdateIcon}
                                      alt="Update icon"
                                      id="updateIcon"
                                      // width={26}
                                      // height={21}
                                    />
                                </div>
                                    <div className="updatesPoints">
                                          <ul className="updatePointsList">
                                            {RegulationUpdates.length ? (
                                              RegulationUpdates.map((update, i) => {
                                                let d = new Date(update.date);
                                                const month = d.getMonth() + 1;
                                                const date =
                                                  d.getDate() +
                                                  "/" +
                                                  month +
                                                  "/" +
                                                  d.getFullYear();
                                                let regNumbers = (
                                                  <a
                                                    className="point"
                                                    onClick={() => {
                                                      this.regUpdateInfo(
                                                        update?.regulationNumber
                                                      );
                                                    }}
                                                    href="javascript:void(0)"
                                                  >
                                                    {update?.regulationNumber}
                                                  </a>
                                                );
                                                let regNumbersForRev = (
                                                  <a
                                                    className="point"
                                                    onClick={() => {
                                                      this.revUpdateInfo(
                                                        update?.regulationNumber
                                                      );
                                                    }}
                                                    href="javascript:void(0)"
                                                  >
                                                    {update?.regulationNumber}
                                                  </a>
                                                );
                                                let removedRegNumbers = (
                                                  <a>{update?.regulationNumber}</a>
                                                );
                                                let rev = update.market === 'EU27' ? update?.revisionList?.map(
                                                  (item, index) =>
                                                    item.link !== "" ? (
                                                      <a target="_blank" href={item.link}>
                                                        {(index ? ", " : "") + item.euRegulation}
                                                      </a>
                                                    ) : (
                                                      <a>{(index ? ", " : "") + item.euRegulation}</a>
                                                    )
                                                ) :
                                                update?.revisionList?.map(
                                                  (item, index) =>
                                                    item.link !== "" ? (
                                                      <a target="_blank" href={item.link}>
                                                        {(index ? ", " : "") + item.name}
                                                      </a>
                                                    ) : (
                                                      <a>{(index ? ", " : "") + item.name}</a>
                                                    )
                                                )
                                                ;
                                                if (update?.crudtype === 0) {
                                                  return (
                                                    <div>
                                                      <Row>
                                                        <li
                                                          style={{ paddingRight: "20px" }}
                                                          key={i}
                                                        >
                                                          {t("homePage.newReg")} {date} :{" "}
                                                          {t("homePage.in")} {update?.market}{" "}
                                                          {t("homePage.market")},{" "}
                                                          {t("homePage.regulations")}{" "}
                                                          {regNumbers}.
                                                        </li>
                                                      </Row>
                                                    </div>
                                                  );
                                                } else if (
                                                  update?.crudtype === 1 &&
                                                  update?.attributeChanges.length !== 0
                                                ) {
                                                  return (
                                                    <div>
                                                      <Row>
                                                        <li
                                                          style={{ paddingRight: "10px" }}
                                                          key={i}
                                                        >
                                                          {t("homePage.UpdateStart")} {date} :{" "}
                                                          {t("homePage.in")} {update?.market}{" "}
                                                          {t("homePage.market")},{" "}
                                                          {t("homePage.regulations")}{" "}
                                                          {regNumbers}.
                                                        </li>
                                                        <a
                                                          data-tip={i18next.t(
                                                            "homePage.detailIconTooltip"
                                                          )}
                                                          data-for="resendMailHandler"
                                                        >
                                                          <img
                                                            onClick={() => {
                                                              this.handleDetails(
                                                                update?.attributeChanges
                                                              );
                                                            }}
                                                            src={infoImage}
                                                            style={{ cursor: "pointer" }}
                                                            height="18px"
                                                          />
                                                        </a>
                                                        <ReactTooltip
                                                          id="resendMailHandler"
                                                          place="left"
                                                          type="info"
                                                          effect="float"
                                                        />
                                                      </Row>
                                                    </div>
                                                  );
                                                } else if (update?.crudtype === 2) {
                                                  return (
                                                    <div>
                                                      <Row>
                                                        <li
                                                          style={{ paddingRight: "10px" }}
                                                          key={i}
                                                        >
                                                          {t("homePage.deleteReg")} {date} :{" "}
                                                          {t("homePage.in")} {update?.market}{" "}
                                                          {t("homePage.market")},{" "}
                                                          {t("homePage.regulations")}{" "}
                                                          {removedRegNumbers}.
                                                        </li>
                                                      </Row>
                                                    </div>
                                                  );
                                                } else if (update?.crudtype === 3) {
                                                  return (
                                                    <div>
                                                      <Row>
                                                        <li
                                                          style={{ paddingRight: "10px" }}
                                                          key={i}
                                                        >
                                                          {t("homePage.newRevHistoryAddedText")}{" "}
                                                          {rev} {t("homePage.AddedText")} {date}{" "}
                                                          : {t("homePage.in")} {update?.market}{" "}
                                                          {t("homePage.market")},{" "}
                                                          {t("homePage.regulations")}{" "}
                                                          {regNumbersForRev}.
                                                        </li>
                                                      </Row>
                                                    </div>
                                                  );
                                                } else if (
                                                  update?.crudtype === 4 &&
                                                  update?.attributeChanges.length !== 0
                                                ) {
                                                  return (
                                                    <div>
                                                      <Row>
                                                        <li
                                                          style={{ paddingRight: "10px" }}
                                                          key={i}
                                                        >
                                                          {t("homePage.revHistoryUpdateText")}{" "}
                                                          {rev} {t("homePage.updateOnText")}{" "}
                                                          {date} : {t("homePage.in")}{" "}
                                                          {update?.market}{" "}
                                                          {t("homePage.market")},{" "}
                                                          {t("homePage.regulations")}{" "}
                                                          {regNumbersForRev}.
                                                        </li>
                                                        <a
                                                          data-tip={i18next.t(
                                                            "homePage.detailIconTooltip"
                                                          )}
                                                          data-for="resendMailHandler"
                                                        >
                                                          <img
                                                            onClick={() => {
                                                              this.handleDetails(
                                                                update?.attributeChanges
                                                              );
                                                            }}
                                                            src={infoImage}
                                                            style={{ cursor: "pointer" }}
                                                            height="18px"
                                                          />
                                                        </a>
                                                        <ReactTooltip
                                                          id="resendMailHandler"
                                                          place="left"
                                                          type="info"
                                                          effect="float"
                                                        />
                                                      </Row>
                                                    </div>
                                                  );
                                                } else if (update?.crudtype === 5) {
                                                  return (
                                                    <div>
                                                      <Row>
                                                        <li
                                                          style={{ paddingRight: "10px" }}
                                                          key={i}
                                                        >
                                                          {t("homePage.revHistoryUpdateText")}{" "}
                                                          {rev} {t("homePage.removedOnText")}{" "}
                                                          {date} : {t("homePage.in")}{" "}
                                                          {update?.market}{" "}
                                                          {t("homePage.market")},{" "}
                                                          {t("homePage.regulations")}{" "}
                                                          {regNumbersForRev}.
                                                        </li>
                                                      </Row>
                                                    </div>
                                                  );
                                                }
                                              })
                                            ) : (
                                              <p>{t("homePage.noRegUpdate")}</p>
                                            )}
                                          </ul>
                                    </div>
                                </div>
                                </div>
                          </div>
                          </div>
                
            </div>
            <Footer />
            </div>
            </Loader>
            </>
          )}
        </Translation>
      </>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({
  MarketList: appState.user.MarketList,
  TopicList: appState.user.TopicList,
  VehicleList: appState.user.VehicleList,
  SearchTagList: appState.user.SearchTagList,
  DriveTypeList: appState.user.DriveTypeList,
  GroupList: appState.user.GroupList,
  VehicleFuelTypeList: appState.user.VehicleFuelTypeList,
  Subscriptions: appState.user.Subscriptions,
  RegulationUpdates: appState.user.RegulationUpdates,
  ErrorRegulationUpdates: appState.user.RegulationUpdatesRequest.$errorMessage,
  RegulationIdList: appState.user.RegulationIdList,
  regulationIdFetched: appState.user.regulationIdFetched,
  FaqList: appState.user.FaqList,
  SubscribedTopicList: appState.user.SubscribedTopicList,
  user: appState.user.user,
  subscriptionAdded: appState.user.subscriptionAdded,
  loading: appState.loading.loading,
  userLogged: appState.user.userLogged,
  activeKey: appState.user.activeKey,
  referenceRegFetched: appState.user.referenceRegFetched,
  RefRegulationDetailsList: appState.user.RefRegulationDetailsList,
});

const mapDispatchToProps = (dispatch) => ({
  reloadTemplate: (role) => Actions.user.reloadTemplate(role, dispatch),
  getSubscriptions: (lastLogin) =>
    Actions.user.getSubscriptions(lastLogin, dispatch),
  getRegulationUpdates: (lastLogin) =>
    Actions.user.getRegulationUpdates(lastLogin, dispatch),
  getRegulationDetailsById: (id) =>
    Actions.user.getRegulationDetailsById(id, dispatch),
  getFaqList: () => Actions.user.getFaqList(dispatch),
  getTabChange: (tab) => Actions.user.getTabChange(tab, dispatch),
  getMarketList: () => Actions.user.getMarketList(dispatch),
  getSearchTagList: () => Actions.user.getSearchTagList(dispatch),
  getTopicList: () => Actions.user.getTopicList(dispatch),
  getVehicleList: () => Actions.user.getVehicleList(dispatch),
  getFavoritesList: (user) => Actions.user.getFavoritesList(dispatch, user),
  getGroupList: () => Actions.user.getGroupList(dispatch),
  getVehicleFuelTypeList: () => Actions.user.getVehicleFuelTypeList(dispatch),
  getDriveTypeList: () => Actions.user.getDriveTypeList(dispatch),
  getScopeList: () => Actions.user.getScopeList(dispatch),
  getSubscibedTopics: () => Actions.user.getSubscibedTopics(dispatch),
  getUserById: (userId) => Actions.user.getUserById(userId, dispatch),
  editUserList: (user) => Actions.user.editUserList(dispatch, user),
  getReferenceRegulationDetails: (regulationNumber) =>
    Actions.user.getReferenceRegulationDetails(regulationNumber, dispatch),
  getRecentGPTQuestions : () => Actions.user.getRecentGPTQuestions(dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LandingPage));
