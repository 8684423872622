import React, {Fragment, Component} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Typeahead} from 'react-bootstrap-typeahead';
import i18n from '../../i18n/I18n';
import i18next from 'i18next';
import {toast} from 'react-toastify';
import avatar1 from '../../assets/utils/images/avatars/dummyUser.png';
import ImageUploading from "react-images-uploading";
import './style.css';
import { useEffect, useState } from 'react';
import {ProfileImage} from '../ManageUser/UserColData';
import ChangeProfile from 'react-ionicons/lib/MdCamera';
import DeleteIcon from 'react-ionicons/lib/MdTrash';
import ReactTooltip from 'react-tooltip';
import {
    Row, Col,
    Card, CardBody,
    CardTitle,
    Button
} from 'reactstrap';
import { es } from 'date-fns/locale';


interface Props extends Translate{
    closeModal?: any,
    loading?: any,
    editUserList: (any) => {};
    userProfile?: any
  }

interface State{
    userData?: any,
    setImages?: any,
    profileImage ?: any,
    firstName?: any,
    lastName?: any,
    id?: any,
    errors?: any,
    removeProfile: boolean
  }

class EditProfileForm extends Component<Props, State> {

    constructor(props){
      super(props)
      this.state={
        userData : JSON.parse(localStorage.getItem('user')),
        setImages: [],
        profileImage : JSON.parse(localStorage.getItem('user')).profileImage,
        id: JSON.parse(localStorage.getItem('user')).id,
        errors: {},
        removeProfile: false
      }
    }

    componentDidMount(){
        let user = JSON.parse(localStorage.getItem('user'))
       if(user.profileImage === null) {
           this.setState({setImages : [{'data_url' : ProfileImage}]})
       }
       else{
           this.setState({setImages : [{'data_url' : user.profileImage}]})
       }
    }

    //setting value for first name and last name input field.
    handleChange = (e) =>{
       let data = {...this.state.userData};
        data[e.target.id] = e.target.value;
        this.setState({userData: data});
    }

    //upload or change propfile picture.
    onChange = (imageList, addUpdateIndex) => {
        this.setState({setImages: imageList})
        this.state.setImages.map((image, index) => {
              this.setState({profileImage:image.data_url})
        })
    };

    // save changes edit user list api call.
    saveProfile = () =>{
        let {userData} = this.state;
        let errFlag = false;
        let errors = {}
        if(userData['firstName'].trim() === ''){
          userData['firstName'] = '';
          errFlag = true;
          errors['firstName'] = i18next.t('ErrorMessage');
        }
        if(userData['lastName'].trim() === ''){
          userData['lastName'] = '';
          errFlag = true;
          errors['lastName'] = i18next.t('ErrorMessage');
        }
        this.setState({
            errors: errors
          });

        if(!errFlag){
            let {removeProfile} = this.state;
            if(removeProfile === true){
              let obj ={
                firstName : this.state.userData['firstName'],
                lastName: this.state.userData['lastName'],
                id: this.state.id,
                removeProfile: this.state.removeProfile
              }
              this.props.editUserList(obj);
            }
            else{
              let obj = {
               firstName : this.state.userData['firstName'],
               lastName: this.state.userData['lastName'],
               id: this.state.id,
               profileImage: this.state.profileImage
              }
             this.props.editUserList(obj);
            }
        }
    }

    // remove profile image.
    removeImage = () =>{
     this.setState({setImages : [{'data_url' : ''}], removeProfile: true})
    }

    render() {
      const classImg = this.state.setImages.length > 0 && this.state.setImages[0]['data_url'] !==ProfileImage ? 'changeImg' : 'Img';
        return (
            <Translation>{t => (<Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                     </ReactCSSTransitionGroup>
                  <div id="editUserDetailsForm">
                      <div className="form-group row wrapper">
                        <ImageUploading
                            multiple
                           value={this.state.setImages.data_url}
                           onChange={this.onChange}
                           dataURLKey="data_url"
                         >
                          {({
                            onImageUpload
                           }) => (
                           <div className="rounded-circle">
                              {this.state.setImages.map((image) => (
                                <div>
                                  <img  className="rounded-circle" src={image.data_url} alt="" width={60} height={60} />
                                </div>
                               ))
                              }
                             <div className={classImg}>
                               <a data-tip={i18next.t('changeProfile')} data-for="changeProfile">
                                 <ChangeProfile className="changeIcon" onClick={onImageUpload} color="white" />
                               </a>
                               <ReactTooltip id="changeProfile" place="bottom" type="info" effect="float"/>
                             </div>
                             {  this.state.setImages.length > 0 && this.state.setImages[0]['data_url'] !== ProfileImage  &&
                             <div className="deleteImg">
                               <a data-tip={i18next.t('deleteProfile')} data-for="deleteProfile">
                                 <DeleteIcon onClick={this.removeImage} className="deleteIcon" color="white"/>
                               </a>
                               <ReactTooltip id="deleteProfile" place="bottom" type="info" effect="float"/>
                             </div> }
                           </div>
                          )}
                        </ImageUploading>
                      </div>
                       <div className="form-group row">
                          <h5 className="col-sm-4 col-form-label"><b>{t('firstName')} *</b></h5>
                             <div className="col-sm-8">
                                <input  type="text" placeholder={t('userForm.inputName')} id="firstName" className="form-control" value={this.state.userData['firstName']} onChange={this.handleChange}/>
                                <div className="errPassword">{this.state.errors.firstName}</div>
                              </div>
                        </div>
                        <div className="form-group row">
                           <h5 className="col-sm-4 col-form-label"><b>{t('lastName')} *</b></h5>
                              <div className="col-sm-8">
                                <input type="text" placeholder={t('userForm.inputLastName')} id="lastName" className="form-control" value={this.state.userData['lastName']} onChange={this.handleChange}/>
                                <div className="errPassword">{this.state.errors.lastName}</div>
                              </div>
                        </div>
                    </div>
                    <hr />
                    <div className="editUserBtn">
                        <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
                        <Button color="primary" onClick={this.saveProfile}  disabled={this.props.loading}>{this.props.loading ? i18next.t('updatingProfile') : i18next.t('save')} </Button>
                    </div>
              </Fragment>)}
             </Translation>
            )
        }
    }

    const mapStateToProps = (appState: AppState) => ({
      loading: appState.loading.loading
     });

    const mapDispatchToProps = dispatch => ({
        editUserList: (user) => Actions.admin.editUserList(dispatch, user),
     });
    export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditProfileForm));
