import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import Actions from '../../internal/modules/Actions';
import {Button} from 'reactstrap';
import $ from 'jquery';
import i18next from 'i18next';
import {languageArr} from './DriveTypeColData';

interface Props extends Translate{
  rowData?: any,
  formtype?: any;
  eventHandler?: any;
  closeModal?: any;
  editDriveTypeList: (any) => {};
  createDriveType: (any) => {};
}

interface State{
  formData?: any;
  rowData?: any;
  fieldMsg?: any;
}

class DriveTypeForm extends Component<Props, State> {
  constructor(props) {
      super(props)
      this.state = {
        rowData: this.props.rowData,
        fieldMsg: {
          driveTypeErr:''
        }
      }
  }

   componentDidMount(){
     //$("#driveTypeForm").find("span").hide();
   }
   // setting the drive type value on change.
  inputHandler = (id, lang, event) =>{
    let arr = Object.keys(this.state.rowData).length < 1 ? {data:{}} : {...this.state.rowData};
    if (!arr.data[lang]) {
      arr.data[lang] = {};
    }
    arr.data[lang][id] = event.target.value;
    this.setState({rowData: arr});
  }

  //call on form save button ,used to call create and edit drive type api and save changes.
  SaveFormHandler = (event) =>{
    event.preventDefault();
    let {fieldMsg} = this.state;
    fieldMsg['driveTypeErr'] = '';
    let flag = false;

    //Set error message if drive type is blank else call api to save data
    [...languageArr].map(lang =>{
      if(!this.state.rowData.data || !this.state.rowData.data[lang.code] || !this.state.rowData.data[lang.code].driveType ||
        (this.state.rowData.data[lang.code].driveType && this.state.rowData.data[lang.code].driveType.trim()) === ''){
        fieldMsg['name'] = i18next.t('mngDriveType.inputNameErr');
        flag = true;
      }
  });
  this.setState(fieldMsg);

    if (!flag) {
      let data = {...this.state.rowData.data}
      let postArr = [];
      [...languageArr].map(lang =>{
       let obj = data[lang.code] !== undefined ? data[lang.code] : {"driveType":''};
       obj['languageCode'] = lang.code;
       obj['id'] = this.state.rowData.id;
       postArr.push(obj);
      });
      if (this.props.formtype === 'edit') {
        this.props.editDriveTypeList(postArr);
      }
      else{
        this.props.createDriveType(postArr);
      }
     this.props.closeModal();
    }
  }

  render() {
    let {rowData} = this.state;
    return (
      <Translation>{t => (
        <Fragment>
          <form id="driveTypeForm">
            <div className="form-inline col-12">
              <div className="col-4"><h6 className="col-form-label"><b>{i18next.t('mngDriveType.attribute')}</b></h6></div>
              {
                [...languageArr].map(lang =>{
                  return(
                    <div className="col-4"><h6 className="col-form-label"><b>{i18next.t('language.'+lang.code)} *</b></h6></div>
                  )
                })
              }
            </div>
            <div className="form-inline col-12">
              <div className="col-4"><h6><b>{i18next.t('mngDriveType.driveTypeName')} *</b></h6></div>
                {
                  [...languageArr].map(lang =>{
                    return(
                      <div className="col-4 labelTextCss">
                        <h6 className="driveTypeContainer">
                          <input  title={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].driveType } type="text" id={'driveTypeContainer'+lang.code} value={rowData.data && rowData.data[lang.code] && rowData.data[lang.code].driveType} onChange={this.inputHandler.bind(this, 'driveType', lang.code)} className="form-control" placeholder={t('driveTypeForm.inputDriveType')} required/>
                        </h6>
                      </div>
                    )
                  })
                }
            </div>
            {
              <ul>
              {Object.keys(this.state.fieldMsg).map(errKey =>{
                if (this.state.fieldMsg[errKey] !== '') {
                  return(
                    <li style={{'color':'red'}}>{this.state.fieldMsg[errKey]}</li>
                  )
                }
              })}
              </ul>
            }
            <hr />
            <div className="form-group row">
              <div className="col-12" style={{'textAlignLast':'end'}}>
              <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
              <Button color="primary" onClick={this.SaveFormHandler}>{i18next.t('save')}</Button>
              </div>
            </div>
        </form>
          </Fragment>
      )}</Translation>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({

});

const mapDispatchToProps = dispatch => ({
  editDriveTypeList: (driveType) => Actions.admin.editDriveTypeList(dispatch, driveType),
  createDriveType: (driveType) => Actions.admin.createDriveType(dispatch, driveType),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriveTypeForm);
