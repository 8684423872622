import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
// import configureStore from "./config/configureStore";
import configureStore from './internal/store'
import { Provider } from "react-redux";
// import Main from './Pages/Main';
import { toast, Slide } from 'react-toastify';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/I18n'

const store = configureStore();
const rootElement = document.getElementById("root");

toast.configure({
  transition: Slide,
  closeButton: true,
  autoClose: 2000,
  position: 'bottom-center'
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <HashRouter>
          <App />
        </HashRouter>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
