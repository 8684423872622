import { ModuleEvents } from "./Actions"
import Actions from "../../internal/modules/Actions"

//Resetting states after success and failure api.
export default {
    [ModuleEvents.NOTIFICATION_LIST]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_NOTIFICATION_LIST });
    },

    [ModuleEvents.ERROR_NOTIFICATION_LIST]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_NOTIFICATION_LIST });
    },

    [ModuleEvents.DELETE_NOTIFICATION]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_DELETE_NOTIFICATION });
    },

    [ModuleEvents.ERROR_DELETE_NOTIFICATION]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_DELETE_NOTIFICATION });
    },

    [ModuleEvents.DELETE_ALL_NOTIFICATION]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_DELETE_ALL_NOTIFICATION });
    },

    [ModuleEvents.ERROR_DELETE_ALL_NOTIFICATION]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_DELETE_ALL_NOTIFICATION });
    },

    [ModuleEvents.MARK_AS_READ]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_MARK_AS_READ });
    },

    [ModuleEvents.ERROR_MARK_AS_READ]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_MARK_AS_READ });
    },

    [ModuleEvents.MARK_AS_READ_ALL]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_MARK_AS_READ_ALL });
    },

    [ModuleEvents.ERROR_MARK_AS_READ_ALL]: ({ dispatch, payload, appState }) => {
      dispatch({ type: ModuleEvents.RESET_MARK_AS_READ_ALL });
    }
}
