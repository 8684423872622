import React, {Fragment, Component} from 'react';
import { Translate } from './../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import Actions from './../../internal/modules/Actions';
import './UserMarketProfile.css';
import $  from 'jquery';
import { AppState } from './../../internal/modules/Reducers';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import Flag from 'react-flagkit';
import DocumentTableComp from '../Table/DocumentTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import DownArrow from './../../chevron-sign-down.png';
import RightArrow from './../../chevron-sign-right.png';
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';
import uneceFlag from './../../assets/utils/images/unece.png';
import UserMarketProfileTableComp from './UserMarketProfileTable';
import i18next from 'i18next';

export const continentArr = {
   en:[{
      "AS": "Asia",
      "SA": "South America",
      "NA": "North America",
      "AF": "Africa",
      "EU": "Europe",
      "OC": "Oceania",
   }],
   de:[{
      "AS": "Asien",
      "SA": "Südamerika",
      "NA": "Nordamerika",
      "AF": "Afrika",
      "EU": "Europa",
      "OC": "Ozeanien",
   }]
};
interface Props extends Translate{
   MarketProfileList?: any;
   RegulationCountList?: any;
   loading: boolean;
  }
  
  interface State{
   rowData?: any;
   toggleIconDocuments : boolean;
   toggleIconComments : boolean;
   authLinkArr?: any;
   stdLinkArr?: any;
   generalData?: any;
   infraEnvData?: any;
   typeApprovalData?: any;
  }
  
  class MarketProfileUser extends Component<Props, State> {
      constructor(props) {
          super(props);
          this.state = {
              rowData : {},
              toggleIconDocuments : false,
              toggleIconComments : false,
              authLinkArr : [],
              stdLinkArr : [],
              generalData : [],
              infraEnvData : [],
              typeApprovalData : []
          };
      }
      componentDidMount(){
         
      }
      componentDidUpdate(prevProps, prevState){
         if (this.props.MarketProfileList && prevProps.MarketProfileList !== this.props.MarketProfileList) {
             let {rowData} = this.state;
             let lang = localStorage.getItem('i18nextLng')
             let generalSectionArr = []
             let envSectionArr = []
             let typeApprovalArr = []
             
            this.props.MarketProfileList.map(item =>{
             if(item.languageCode === lang ){
               rowData = item
                //Setting continent value
               continentArr[lang].map(continent =>{
                  Object.entries(continent).map(([key,val]) =>{
                     if(key === item.market.continent){
                        rowData['continent'] = val
                     }
                  })
                this.setState(rowData)
               })
               Object.keys(item).map((key) =>{
                  if(key === 'iso') {
                     generalSectionArr.push({['label']: i18next.t('marketProfile.isoLable'), ['data']:item[key]})  
                  }
                  else if(key === 'officialLanguage'){
                     generalSectionArr.push({['label']: i18next.t('marketProfile.officialLangLable'), ['data']:item[key]})  
                  }
                  else if(key === 'documentLanguage'){
                     generalSectionArr.push({['label']: i18next.t('marketProfile.docLangLable'), ['data']:item[key]})  
                  }
                  else if(key === 'economicRegion'){
                     generalSectionArr.push({['label']: i18next.t('marketProfile.economicLangLable'), ['data']:item[key]})  
                  }
                  else if(key === 'generalFurtherInformation'){
                     generalSectionArr.push({['label']: i18next.t('marketProfile.furtherInfoLable'), ['data']:item[key]})  
                  }
                  else if(key === 'roadCondition'){
                     envSectionArr.push({['label']: i18next.t('marketProfile.roadCondLable'), ['data']:item[key]?.label})  
                  }
                  else if(key === 'handDrive'){
                     envSectionArr.push({['label']: i18next.t('marketProfile.driveTypeLable'), ['data']:item[key]?.label})  
                  }
                  else if(key === 'avgTemp'){
                     envSectionArr.push({['label']: i18next.t('marketProfile.avgTempLable'), ['data']:item[key]?.label})  
                  }
                  else if(key === 'airConditions'){
                     const val =  item[key].map((el) =>  el.label)
                     envSectionArr.push({['label']: i18next.t('marketProfile.airCondLable'), ['data']:val.join(", ")})  
                  }
                  else if(key === 'humidity'){
                     const val =  item[key].map((el) =>  el.label)
                     envSectionArr.push({['label']: i18next.t('marketProfile.humidityLable'), ['data']: val.join(", ")})  
                  }
                  else if(key === 'envConditions'){
                    const val =  item[key].map((el) =>  el.label)
                    envSectionArr.push({['label']: i18next.t('marketProfile.envCondLable'), ['data']:val.join(", ")})  
                  }
                  else if(key === 'certification'){
                     typeApprovalArr.splice(0,0,{['label']: i18next.t('marketProfile.certificationLabel'), ['data']:item[key]?.label})  
                  }
                  else if(key === 'standards'){
                     typeApprovalArr.push({['label']: i18next.t('marketProfile.stdLabel'), ['data']:item[key]})  
                  }
                  else if(key === 'authorities'){
                     typeApprovalArr.push({['label']: i18next.t('marketProfile.authoritiesLable'), ['data']:item[key]})  
                  }
                  else if(key === 'ece_based'){
                     typeApprovalArr.push({['label']: i18next.t('marketProfile.eceBasedLabel'), ['data']:item[key]})  
                  }
                  else if(key === 'typeApprovalFurtherInformation'){
                     typeApprovalArr.push({['label']: i18next.t('marketProfile.furtherInfoLableType'), ['data']:item[key]})  
                  }
               })
               generalSectionArr.splice(1,0,{['label']: i18next.t('marketProfile.continantLable'), ['data']:rowData.continent})  
              //Setting regulation count value
                  this.props.RegulationCountList.map(regCount =>{
                     if(regCount.market === item.market.label){
                        typeApprovalArr.splice(1,0,{['label']: i18next.t('marketProfile.regLable'), ['data']: regCount?.regulationCount})  
                     }
                  })
               //Store values in arrays for authorities and standards link
               let authData = item?.authoritiesLinkUrl?.split(',');
               let stdData = item?.standardsLinkUrl?.split(',')
               this.setState({authLinkArr: authData, stdLinkArr: stdData})
            }
           })
         this.setState({rowData})
         this.setState({generalData: generalSectionArr, infraEnvData: envSectionArr , typeApprovalData: typeApprovalArr})
        }
      }
     //Documents toggle icon handler
      toggleIconDocuments = (market, e) =>{
         let {toggleIconDocuments} = this.state;
         toggleIconDocuments = !toggleIconDocuments;
         this.setState({toggleIconDocuments});
      }
      //Comments toggle icon handler
      toggleIconComments = (market, e) =>{
         let {toggleIconComments} = this.state;
         toggleIconComments = !toggleIconComments;
         this.setState({toggleIconComments});
      }
      render() {
         let {rowData} = this.state;
         //Store index for display authorities and standards link 
         let stdData = rowData?.standards?.split(',')
         let authData = rowData?.authorities?.split(',')
         let stdLink = rowData.standardsLinkUrl?.split(',').findIndex((item ,index) => item === ''  )
         let authLink = rowData.authoritiesLinkUrl?.split(',').findIndex((item ,index) => item === ''  )
         const spinner4 = <LoaderAnim active type="ball-beat"/>;
        return (
            <Translation>{t => (<Fragment>
             <Loader message={spinner4} show={this.props.loading}>
                <div id='MarketProfile'>
                   <div className="col-12">
                  
                     <div className="col-sm-6 form-inline"  style={{ 'padding': '0' }}>
                        {rowData?.market?.value !== 'ECE' ?
                     <Flag className="opacity-8 marketFlag" country={rowData?.market?.value} size={25}/>
                   :
                   <img src={uneceFlag} style={{'height':'25px', 'width':'25px', 'float':'right'}}/>}
                         <h5 style={{ 'fontSize': 'larger','marginLeft':'5px' }}><b>{rowData?.market?.value === 'ECE' ? 'UNECE' : t(`map.countries.`+rowData?.market?.value)}</b></h5>
                         <h5 style={{ 'fontSize': 'larger','marginLeft':'5px' }}><b>({rowData?.market?.value})</b></h5>
                         </div>
                    </div>
                    <Row>
                      <Col style={{'marginBottom' : '10px'}}>
                           <div className="form-inline col-12">
                             <div className="col-sm-4"  style={{ 'padding': '0' }}>
                                 <h6 style={{'paddingBottom' : '13px','fontSize': 'medium', 'marginTop' : '8px'}}><b>{t('marketProfile.generalLable')}:</b></h6>
                              </div>
                            </div>
                            <div className="form-inline col-12 rowDiv">
                               <UserMarketProfileTableComp tableData = {this.state.generalData}  />
                            </div>
                            <div className="form-inline col-sm-6">
                                <h6 style={{'paddingBottom' : '13px', 'fontSize': 'medium', 'marginTop' : '18px'}}><b>{t('marketProfile.climateLabel')}:</b></h6>
                            </div>
                            <div className="form-inline col-12 rowDiv">
                                <UserMarketProfileTableComp tableData = {this.state.infraEnvData}  />
                            </div>
                            <div className="form-inline col-sm-4">
                                <h6 style={{'paddingBottom' : '13px', 'fontSize': 'medium', 'marginTop' : '18px'}}><b>{t('marketProfile.typeApprovalLable')}:</b></h6>
                            </div>
                            <div className="form-inline col-12 rowDiv">
                               <UserMarketProfileTableComp stdData = {stdData} authData = {authData} authLinkData = {this.state.authLinkArr} authLink = {authLink} stdLinkData = {this.state.stdLinkArr} stdLink = {stdLink} tableData = {this.state.typeApprovalData}  />
                            </div>
                            <div className="form-inline col-sm-12" style={{ 'marginTop': '10px' }}>
                               <div className="col-2 form-inline" style={{ 'padding': '0' }}>
                                    <h6  style={{ 'fontSize': 'medium','marginBottom': '5px', 'marginTop': '5px' }}><b>{t('marketProfile.documents')}:</b></h6>
                               </div>
                               <div className="col-10">
                                    <div id={'accordionActualReg'}>
                                       <div id={'headingAccordionActualReg'}>
                                          <h5 className="mb-0">
                                           <button onClick={this.toggleIconDocuments.bind(this)} className="btn btn-link" data-toggle="collapse" data-target={'#collapseDocumentCard'} aria-expanded="true" aria-controls={'collapseDocumentCard'}>
                                             {this.state.toggleIconDocuments === false ?
                                              <img src={RightArrow} style={{'height': '20px'}} />
                                              :
                                              <img src={DownArrow} style={{'height': '20px'}} />
                                             }
                                           </button>
                                          </h5>
                                       </div>
                                    </div>
                                 </div>
                                 <div id={'collapseDocumentCard'} className="card collapse col-sm-12 noPadding" aria-labelledby={'headingAccordionActualReg'} data-parent={'#accordionActualReg'}>
                                    <div className="card-body">
                                      <DocumentTableComp data={rowData?.documents} type="marketProfile"/>
                                    </div>
                                 </div>
                                 </div>
                                 <div className="form-inline col-sm-12">
                                       <div className="col-2 form-inline" style={{ 'padding': '0' }}>
                                         <h6  style={{ 'fontSize': 'medium','marginBottom': '5px', 'marginTop': '5px' }}><b>{t('marketProfile.comments')}:</b></h6>
                                        </div>
                                       <div className="col-10">
                                         <div id={'accordionComments'}>
                                            <div id={'headingAccordionActualReg'}>
                                              <h5 className="mb-0">
                                                 <button onClick={this.toggleIconComments.bind(this)} className="btn btn-link" data-toggle="collapse" data-target={'#collapseCommentsCard'} aria-expanded="true" aria-controls={'collapseCommentsCard'}>
                                                   {
                                                     this.state.toggleIconComments === false ?
                                                     <img src={RightArrow} style={{'height': '20px'}} />
                                                      :
                                                      <img src={DownArrow} style={{'height': '20px'}} />
                                                   }
                                                  </button>
                                               </h5>
                                            </div>
                                         </div>
                                      </div>
                                       <div id={'collapseCommentsCard'} className="card collapse col-sm-12 noPadding" aria-labelledby={'headingAccordionActualReg'} data-parent={'#accordionComments'}>
                                         <div className="card-body">
                                    <h6 className='labelCss'>{rowData?.comments}</h6>
                                    </div>
                                 </div>
                               </div>
                           </Col>
                    </Row>
              </div>
              </Loader>
            </Fragment>)}</Translation>
        )
    }
}

const mapStateToProps = (appState: AppState) =>  ({
   MarketProfileList: appState.user.MarketProfileList,
   RegulationCountList: appState.user.RegulationCountList,
   loading: appState.loading.loading,
});

const mapDispatchToProps = dispatch => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MarketProfileUser));
