import i18next from 'i18next';

//Manage fuel types table columns
export const FuelTypeColData = () => {
  return [
    {
        dataField: 'rowId',
        isKey: true,
        label: i18next.t('id'),
        hidden: true,
        placeholder: i18next.t('idPlaceholder')
    },
    {
        isKey: false,
        label: 'Action',
        hidden: false
    },
    {
        dataField: 'type',
        isKey: false,
        label: i18next.t('mngFuelType.vehicleFuelType'),
        dataFormat: (cell, row) =>{
          return row.data && row.data[localStorage.getItem('i18nextLng')].type;
        },
        hidden: false,
        placeholder: i18next.t('mngFuelType.fuelTypePlaceholder')
    }
  ]
};

export const languageArr = [
  {
    id:1,
    code:"en"
  },
  {
    id:2,
    code:"de"
  }
];
