import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import { AppState } from '../../internal/modules/Reducers';
import './MarketProfile.css';
import Actions from './../../internal/modules/Actions';
import i18next from 'i18next';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import PageTitle from '../../Layout/AppMain/PageTitle';
import EditModalComp from '../Table/EditModal';
import MarketValueForm from './AddMarketValueForm';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MarketValueTableComp from './MarketValueTable';
import {toast} from 'react-toastify';
import DeleteModalComp from '../Table/DeleteModal';
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';

interface Props extends Translate{
  getMarketProfileValue: () => {};
  deleteMarketValue: (id,type) =>{};
  MarketValueFetched: boolean;
  MarketValueList ?: any;
  marketValueAdded : boolean;
  marketValueUpdated : boolean;
  marketValueDeleted : boolean;
  loading?: any;
  }
  
interface State{
  roadConditionList?:any;
  airConditionList ?: any;
  driveTypeList ?: any;
  humidityList ?: any;
  avgTempList ?: any;
  extrEnvList ?: any;
  certificationList ?: any;
  editFlag: boolean;
  modalID : string;
  type : string;
  activeIndex : number,
  errorMessage ?: any,
  formType ?: any,
  rowData ?: any,
  modalDelete : boolean,
  deleteRowData ?: any,

}
  
class MarketValueComp extends Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            roadConditionList : [],
            editFlag : false,
            modalID : '',
            type : '',
            activeIndex: -1 ,
            errorMessage: null,
            formType : '',
            rowData : {},
            modalDelete : false,
            deleteRowData : {}
        }
    }

   static getDerivedStateFromProps(newState, prevState) {
      return {
        errorMessage: newState.errorMessage,
      };
   }  
  componentDidMount(){
    this.props.getMarketProfileValue()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.MarketValueFetched !== this.props.MarketValueFetched) {
     let filterData = [];
      filterData.push(this.props.MarketValueList)
      filterData && filterData.map((item, index) => {
        //update state
          this.setState({roadConditionList:item.roadCondition ,
            airConditionList: item.airCondition,
            driveTypeList: item.handDrive,
            certificationList: item.certification,
            extrEnvList: item.envCondition,
            avgTempList: item.avgTemp,
            humidityList: item.humidity,})
       })
     }
     if (prevProps.marketValueAdded !== this.props.marketValueAdded && prevProps.MarketValueList !== this.props.MarketValueList) {
      toast['success'](i18next.t('marketValue.addMarketValueSuccess'), { position: 'top-right' });
      this.closeModal()
      this.props.getMarketProfileValue() 
     }

     if(prevProps.MarketValueList !== this.props.MarketValueList){
      let filterData = [];
      filterData.push(this.props.MarketValueList)
      filterData && filterData.map((item, index) => {
        //Update state if market value list updated
          this.setState({roadConditionList:item.roadCondition,
            airConditionList: item.airCondition,
            driveTypeList: item.handDrive,
            certificationList: item.certification,
            extrEnvList: item.envCondition,
            avgTempList: item.avgTemp,
            humidityList: item.humidity})
       })  
     }
     if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
      toast['error'](i18next.t(this.state.errorMessage), { position: 'top-right' });
    }
    if (prevProps.marketValueUpdated !== this.props.marketValueUpdated && prevProps.MarketValueList !== this.props.MarketValueList) {
      toast['success'](i18next.t('marketValue.editMarketValueSuccess'), { position: 'top-right' });
      this.closeModal()
     }
     if (prevProps.marketValueDeleted !== this.props.marketValueDeleted && prevProps.MarketValueList !== this.props.MarketValueList) {
      toast['success'](i18next.t('marketValue.deleteMarketValueSuccess'), { position: 'top-right' });
      this.closeModal()
     }
  }
   //Open add market value modal
    add = (type) =>{
     this.setState({editFlag: true,modalID: 'marketValueModal', type, formType: 'add'})
    }
    //Close modal
    closeModal = () => {
        this.setState({editFlag: false,modalDelete: false})
    }
    //Open edit market value modal
    handleEdit = (row,type) =>{
        this.setState({editFlag: true, modalID: 'marketValueModal',type , formType: 'edit', rowData: row})
    }
    //Open delete market value modal
    handleDelete = (row,type) =>{
      this.setState({modalDelete: true, modalID: 'marketValueModal',type : type, deleteRowData: row})
    }
    //Delete market value api call
    eventHandlerDelete = () =>{
      let {deleteRowData} = this.state
        this.props.deleteMarketValue(deleteRowData.id , this.state.type);
        this.closeModal();
    }
  render() {
    const tableSpinner = <LoaderAnim active type="ball-beat"/>;
    let formTemplate, modalTitle = null;
    if(this.state.editFlag === true) {
        formTemplate = <MarketValueForm  type= {this.state.type}{...this.props} rowData={this.state.formType === 'edit' ? this.state.rowData : {} } formType={this.state.formType} closeModal={this.closeModal}/>
        modalTitle = i18next.t('marketValue.addConditionHeading');
      }
   return (
      <Translation>{t => (
        <Fragment>
              <PageTitle
                {...this.props}
                heading={t('sidebar.mngMarketProfileValue')}
                subheading={''}
                buttonText={''}
                buttonCallback={''}
              />
                      { this.state.editFlag  &&
                         <EditModalComp  closeModal={this.closeModal} modalID={this.state.modalID}  modalBody={formTemplate} modalTitle={modalTitle} />
                      }
                      { this.state.modalDelete &&
                           <DeleteModalComp modalMsg={i18next.t('marketValue.confirmDeleteMsg')} closeModal={this.closeModal} eventHandlerDelete={this.eventHandlerDelete}/>
                       }
                        <Loader message={tableSpinner} show={this.props.loading}>
                          <div id="marketValue">
                          <div className="form-inline col-12">
                            <div className="form-inline col-sm-6" style={{ 'paddingBottom': '5px' }}>
                            <div className="col-sm-12">
                            <h6 style={{ 'fontSize': 'small'}}><b>{t('marketValue.roadLable')}:</b></h6></div>
                              <div className="inputs">
                                <i className="pe-7s-plus addValue" style={{'cursor':'pointer'}}  onClick={() => this.add('roadCondition')}></i>
                                <MarketValueTableComp roadConditionData = {this.state.roadConditionList} onEdit = {(row) => this.handleEdit(row, 'roadCondition')} onDelete = {(row) => this.handleDelete(row,'roadCondition')}/>
                              </div>
                           </div>
                            <div className="form-inline col-sm-6" style={{ 'paddingBottom': '5px' }}>
                            <div className="col-sm-12">
                            <h6 style={{ 'fontSize': 'small'}}><b>{t('marketValue.airLable')}:</b></h6></div>
                              <div className="inputs">
                                <i className="pe-7s-plus addValue" style={{'cursor':'pointer'}}  onClick={() => this.add('airCondition')}></i>
                                <MarketValueTableComp roadConditionData = {this.state.airConditionList} onEdit = {(row) => this.handleEdit(row, 'airCondition')} onDelete = {(row) => this.handleDelete(row,'airCondition')} />
                              </div>
                           </div>
                    </div>
                    <div className="form-inline col-12">
                            <div className="form-inline col-sm-6" style={{ 'paddingBottom': '5px' }}>
                            <div className="col-sm-12">
                            <h6 style={{ 'fontSize': 'small'}}><b>{t('marketValue.driveLable')}:</b></h6></div>
                              <div className="inputs">
                                <i className="pe-7s-plus addValue" style={{'cursor':'pointer'}}  onClick={() => this.add('handDrive')}></i>
                                <MarketValueTableComp roadConditionData = {this.state.driveTypeList} onEdit = {(row) => this.handleEdit(row, 'handDrive')} onDelete = {(row) => this.handleDelete(row,'handDrive')}/>
                              </div>
                           </div>
                            <div className="form-inline col-sm-6" style={{ 'paddingBottom': '5px' }}>
                            <div className="col-sm-12">
                            <h6 style={{ 'fontSize': 'small'}}><b>{t('marketValue.humidityLable')}:</b></h6></div>
                              <div className="inputs">
                                <i className="pe-7s-plus addValue" style={{'cursor':'pointer'}}  onClick={() => this.add('humidity')}></i>
                                <MarketValueTableComp roadConditionData = {this.state.humidityList} onEdit = {(row) => this.handleEdit(row, 'humidity')} onDelete = {(row) => this.handleDelete(row,'humidity')}/>
                              </div>
                           </div>
                    </div>
                    <div className="form-inline col-12">
                            <div className="form-inline col-sm-6" style={{ 'paddingBottom': '5px' }}>
                            <div className="col-sm-12">
                            <h6 style={{ 'fontSize': 'small'}}><b>{t('marketValue.avgTempLable')}:</b></h6></div>
                              <div className="inputs">
                                <i className="pe-7s-plus addValue" style={{'cursor':'pointer'}}  onClick={() => this.add('avgTemp')}></i>
                                <MarketValueTableComp roadConditionData = {this.state.avgTempList} onEdit = {(row) => this.handleEdit(row, 'avgTemp')} onDelete = {(row) => this.handleDelete(row,'avgTemp')}/>
                              </div>
                           </div>
                            <div className="form-inline col-sm-6" style={{ 'paddingBottom': '5px' }}>
                            <div className="col-sm-12">
                            <h6 style={{ 'fontSize': 'small'}}><b>{t('marketValue.envCondLable')}:</b></h6></div>
                              <div className="inputs">
                                <i className="pe-7s-plus addValue" style={{'cursor':'pointer'}}  onClick={() => this.add('envCondition')}></i>
                                <MarketValueTableComp roadConditionData = {this.state.extrEnvList} onEdit = {(row) => this.handleEdit(row, 'envCondition')} onDelete = {(row) => this.handleDelete(row,'envCondition')}/>
                              </div>
                           </div>
                    </div>
                    <div className="form-inline col-12">
                            <div className="form-inline col-sm-6" style={{ 'paddingBottom': '5px' }}>
                            <div className="col-sm-12">
                            <h6 style={{ 'fontSize': 'small'}}><b>{t('marketValue.certificate')}:</b></h6></div>
                              <div className="inputs">
                                <i className="pe-7s-plus addValue" style={{'cursor':'pointer'}}  onClick={() => this.add('certification')}></i>
                                <MarketValueTableComp roadConditionData = {this.state.certificationList} onEdit = {(row) => this.handleEdit(row, 'certification')} onDelete = {(row) => this.handleDelete(row,'certification')}/>
                              </div>
                           </div>
                    </div>
                    </div>
                    </Loader>
           </Fragment>
      )}</Translation>
    );
  }
}

  const mapStateToProps = (appState: AppState) => ({
    MarketValueFetched: appState.admin.MarketValueFetched,
    MarketValueList: appState.admin.MarketValueList,
    marketValueAdded : appState.admin.marketValueAdded,
    errorMessage: appState.admin.MarketValueRequest.$errorMessage,
    marketValueUpdated: appState.admin.marketValueUpdated,
    marketValueDeleted: appState.admin.marketValueDeleted,
    loading: appState.loading.loading,
  });

  const mapDispatchToProps = dispatch => ({
    getMarketProfileValue: () => Actions.admin.getMarketProfileValue(dispatch),
    deleteMarketValue: (id, type) => Actions.admin.deleteMarketValue(dispatch, id,type),
  });

  export default connect(mapStateToProps, mapDispatchToProps)(MarketValueComp);
