import React, {Component,Fragment, useState} from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import i18next from 'i18next';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactTooltip from 'react-tooltip';
import './UserMarketProfile.css'

interface Props extends Translate{
   tableData ?: any;
    onEdit : (row) =>{};
    onDelete : (row) => {};
    stdData ?: any;
    stdLink ?: any;
    stdLinkData ?: any;
    authData ?: any;
    authLink ?: any;
    authLinkData ?: any;
  }
  
  interface State{
    tableData?: any;
  }
  
  class UserMarketProfileTableComp extends Component<Props, State> {
      constructor(props){
        super(props)
        this.state={
            tableData: []
        }
      }
   
      componentDidUpdate(prevProps, prevState){
        if(prevProps.tableData !== this.props.tableData){
            this.setState({tableData: this.props.tableData})
         }
      }

      //setting the label to one column of table.
      labelColumn = (cell,row) => {
        if(row.label === 'Further information1'){
            return(<div><b>{i18next.t('marketProfile.furtherInfoLable')}</b>
            <ReactTooltip id={`${row.id+'fileName'}`} place="bottom" type="info" effect="float"/></div>)
        }
          return(<div><b>{row?.label}</b>
          <ReactTooltip id={`${row.id+'fileName'}`} place="bottom" type="info" effect="float"/></div>)
       }
       //setting data.
       dataColumn = (cell,row) => {
            if((row.label === 'Further information') || (row.label === 'Weitere Informationen')){
                return(<div><a href={row?.data} target="_blank">{row?.data}</a>
                <ReactTooltip id={`${row.id+'fileName'}`} place="bottom" type="info" effect="float"/></div>)
            }
            if((row.label === 'Standards') || (row.label === 'Normen')){
                let std =row?.data?.split(',')
                return(<div>
                    {std.map((item, index) =>(this.props.stdLink !== undefined && this.props.stdLinkData[index] ?
                    <h6 style={{'fontSize': '12px'}}><a href={this.props.stdLinkData[index]} target="_blank"><span style={{cursor: 'pointer', color: '#545cd8'}}>{item}</span></a></h6>
                    :
                    <h6 style={{ 'fontSize': '12px' }}><span style={{color: 'black'}}>{item}</span></h6>
                    ))}
                <ReactTooltip id={`${row.id+'fileName'}`} place="bottom" type="info" effect="float"/>
                </div>)
            }
            else if((row.label === 'Authorities') || (row.label === 'Behörden')){
                let auth = row?.data?.split(',')
                return(<div>
                    {auth.map((item, index) =>(this.props.authLink !== undefined && this.props.authLinkData[index] ?
                    <h6 style={{ 'fontSize': '12px' }}><a href={this.props.authLinkData[index]} target="_blank"><span style={{cursor: 'pointer', color: '#545cd8'}}>{item}</span></a></h6>
                    :
                    <h6 style={{ 'fontSize': '12px' }}><span style={{color: 'black'}}>{item}</span></h6>
                    ))}
                <ReactTooltip id={`${row.id+'fileName'}`} place="bottom" type="info" effect="float"/>
                </div>)
            }
            else{
                return(<div><a>{row?.data}</a>
                <ReactTooltip id={`${row.id+'fileName'}`} place="bottom" type="info" effect="float"/></div>)
            }
        }
      render() {
        const options = {
          noDataText: i18next.t('table.noDataMsg')
        };
         return(
            <div id="marketProfileTable">
              <Translation>{t => (
                <Fragment>
                    <BootstrapTable data={this.state.tableData}  options={options} striped hover condensed>
                         <TableHeaderColumn dataField='label'   className="labelCol" dataFormat={this.labelColumn} isKey></TableHeaderColumn>
                         <TableHeaderColumn dataField='data'  className="labelCol" dataFormat={this.dataColumn}></TableHeaderColumn>
                    </BootstrapTable>
                  </Fragment>
          )}</Translation>
          </div>
        );
      }
    }
    const mapStateToProps = (appState: AppState) => ({
    
    });
    
    const mapDispatchToProps = dispatch => ({
    
    });
    
    export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserMarketProfileTableComp));
    