//API's for admin and user
export const LOGIN = "login";
export const USE_MOCKS = false;
export const REGULATION_LIST_ADMIN = "regulationFilter/admin";
export const REGULATION_LIST_USER = "regulationFilter";
export const FAVORITES_LIST_USER = 'favorites';
export const FAVORITES_LIST_GET = 'favorites/user';
export const REGULATION_COUNT_USER = "market/regulation_count";
export const VEHICLE_LIST_ADMIN = "vehicle/admin";
export const VEHICLE_LIST_USER = "vehicle";
export const MARKET_LIST_ADMIN = "market/admin";
export const MARKET_LIST_USER = "market";
export const TOPIC_LIST_ADMIN = "topic/admin";
export const TOPIC_LIST_USER = "topic";
export const SAVE_FILTER_USER = "favorites/user";
export const USERS_LIST_ADMIN = 'user';
export const LANGUAGE_LIST_ADMIN = 'language/admin';
export const GRM_LIST_ADMIN = 'regulation/admin';
export const PDF = 'regulationFilter/export/pdf';
export const EXCEL = 'regulationFilter/export/xsl';
export const LOGOUT = "logout";
export const ALL_LANG_DATA = "regulation/langValue";
export const SEARCH_TAG = "regulation/tags";
export const SHARE_FAVORITES ="favorites/share";
export const RESENDE_MAIL = "user/resendVerificationLink";
export const USER_VERIFICATION = "user/verify";
export const TIMELINE_PDF = "regulationTimeLine/pdf";
export const TIMELINE_DATA = "regulationTimeLine";
export const EDIT_PASSWORD = "user/changePassword";
export const NOTIFICATION_LIST = "notification/user";
export const DELETE_NOTIFICATION = "notification/remove";
export const DELETE_ALL_NOTIFICATION = "notification/user/remove";
export const MARK_AS_READ = "notification/markRead";
export const MARK_AS_READ_ALL = "notification/user/markAllRead";
export const FORGOT_PASSWORD = "user/forgotPassword";
export const RESET_PASSWORD = "user/resetPassword";
export const REGULATION_NUMBER_LIST_ADMIN = "regulationNumber/admin";
export const REGULATION_BY_ID = "regulation";
export const GROUP_LIST_USER = "group";
export const SUB_GROUP_LIST_USER = "sub-group";
export const VEHICLE_FUEL_TYPE_LIST_USER = "fuelType";
export const TYPE_OF_DRIVE_LIST_USER = "driveType";
export const DRIVE_TYPE_LIST_ADMIN = "driveType/admin";
export const GROUP_LIST_ADMIN = "group/admin";
export const GROUP_SUBGROUP_RELATION_LIST_ADMIN = "group/admin/topic";
export const FUEL_TYPE_LIST_ADMIN = "fuelType/admin";
export const SCOPE_LIST_ADMIN = "scope/admin";
export const SCOPE_LIST_USER = "scope";
export const SCOPE_SUBSCOPE_RELATION_LIST_ADMIN = "scope/admin/topic";
export const TIMELINE_MATRIX = "regulationTimelineMatrixFilter";
export const UPLOAD_FILES_ADMIN = "document/admin/upload";
export const DOCUMENT = "document";
export const EDIT_FILES_ADMIN ="document/admin/";
export const REF_REGULATION ="regulation";
export const MARKET_VALUE ="marketProfile/values/admin";
export const ADD_MARKET_VALUE = "admin";
export const GET_ROAD_CONDITION = "roadCondition/admin";
export const GET_AIR_CONDITION = "airCondition/admin";
export const GET_AVG_TEMP = "avgTemp/admin";
export const GET_CERTIFICATION = "certification/admin";
export const GET_ENV_CONDITION = "envCondition/admin";
export const GET_HAND_DRIVE = "handDrive/admin";
export const GET_HUMIDITY = "humidity/admin";
export const CREATE_MARKET_PROFILE = "marketProfile/admin";
export const GET_MARKET_PROFILE = "marketProfile";
export const UPLOAD_FILE_MARKET_PROFILE = "marketProfileDocument/admin/upload";
export const GET_FILE_MARKET_PROFILE = "marketProfileDocument";
export const EDIT_MARKET_PROFILE = "marketProfile/admin";
export const EDIT_FILE_MARKET_PROFILE = "marketProfileDocument/admin";
export const SUBSCRIBE_TOPICS = "subscribeTopics";
export const GET_SUBSCRIBED_TOPICS = "subscribedTopicsWithAllTopics";
export const GET_SUBSCRIPTIONS = 'subscribedTopicUpdates'
export const GET_REGULATION_UPDATES = "regulationUpdates";
export const GET_FAQ = 'faq';
export const FAQ_ADMIN = 'faq/admin';
export const REVISION_HISTORY = 'revisionHistory/admin';
export const SERIES_REV_HISTORY = 'revisionHistory/series/admin';
export const GET_REGULATION_LIST = 'regulationNumber';
export const GET_REVISION_HISTORY = 'revisionHistory';
export const GET_RECENT_GPT_QUESTIONS = '/user/recentGPTQ';
export const REMOVE_RECENT_GPT_QUESTIONS = 'removeRecentGPTQ';
