import React, { Fragment, Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Translate } from "./../../../model/interfaces/translate.interface";
import { Translation } from "react-i18next";
import { Link, Redirect, withRouter } from "react-router-dom";
import Actions from "./../../../internal/modules/Actions";
import { AppState } from "./../../../internal/modules/Reducers";
import i18n from "i18next";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
} from "reactstrap";
import i18next from "i18next";
import { toast, Bounce } from "react-toastify";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import city3 from "../../../assets/utils/images/dropdown-header/city3.jpg";
import avatar1 from "../../../assets/utils/images/avatars/dummyUser.png";
import UserProfileForm from "../../../pages/UserProfile/UserProfilePage";
import EditModalComp from "../../../pages/Table/EditModal";
import { ProfileImage } from "../../../pages/ManageUser/UserColData";
interface Props extends Translate {
  logout: () => {};
  userUpdated?: any;
  reloadTemplate: (any) => {};
  reloadState: number;
  history?: any;
  location?: any;
  callBack?: any;
  data?: any;
  editUserList: (any) => {};
  activeKey?: any,
}

interface State {
  active: boolean;
  modelFlag: boolean;
  formName: string;
  setImages?: any;
  selectedRole: string;
}

class UserBox extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      modelFlag: false,
      formName: "Edit User",
      setImages: { data_url: "" },
      selectedRole: sessionStorage.getItem("selectedRole"),
    };
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user?.profileImage === null || "null") {
      this.setState({ setImages: { data_url: ProfileImage } });
    } else {
      this.setState({ setImages: { data_url: user?.profileImage } });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.userUpdated &&
      prevProps.userUpdated !== this.props.userUpdated
    ) {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user?.profileImage === null) {
        this.setState({ setImages: { data_url: ProfileImage } });
      } else {
        this.setState({ setImages: { data_url: user?.profileImage } });
      }
    }
    if (this.props.reloadState !== prevProps.reloadState) {
      if (sessionStorage.getItem("selectedRole") != null) {
        this.setState({ selectedRole: sessionStorage.getItem("selectedRole") });
      }
    }
  }

  //logout from app.
  logout = () => {
    const dateUTC = new Date().toISOString();
    let obj = {
      "recentTab": localStorage.getItem('tab'),
      "recentSearch": localStorage.getItem('recentSearch'),
      "lastLogin": dateUTC
    }
    this.props.editUserList(obj)
    if (this.props.data === "xyz") {
      this.props.logout();
      window.location.href = "http://ithomas.meconit.in/login";
      localStorage.setItem("iThomos", "");
    } 
    else if (this.props.location.pathname.includes("/GRM")) {
      this.props.logout();
      window.location.href = "http://ithomas.meconit.in/login";
    } 
    else {
      this.props.history.push({
        pathname: '/login'
       })
      sessionStorage.setItem("selectedRole", null);
      this.props.logout();
    }
  };

  //open user profile modal.
  editProfile = () => {
    this.setState({ modelFlag: true });
  };

  //close user profile modal.
  closeModal = () => {
    this.setState({ modelFlag: false });
  };

  //if user role is admin then admin can switch between admin side and user side.
  switchRole = () => {
    let selectedRole = sessionStorage.getItem("selectedRole");
    if (selectedRole === "user") {
      this.setState({ selectedRole: "admin" });
      if (this.props.location.pathname.includes("/GRM")) {
        this.props.callBack("xyz");
         let user = JSON.parse(localStorage.getItem("user"));
        this.props.history.push({
          pathname: '/app/dashboard'
         })
        this.props.reloadTemplate("admin");
      } else {
        this.props.history.push('/app/dashboard')
        this.props.reloadTemplate("admin");
      }
    } else if (selectedRole === "admin") {
      this.setState({ selectedRole: "user" });
      this.props.history.push('/app/dashboard/1')
      this.props.reloadTemplate("user");
    }
  };

  render() {
    let user = JSON.parse(localStorage.getItem("user"));
    let selectedRole = sessionStorage.getItem("selectedRole");
    let formTemplate,
      modalTitle = null;
    if (this.state.formName === "Edit User") {
      formTemplate = (
        <UserProfileForm {...this.props} closeModal={this.closeModal} />
      );
      modalTitle = i18next.t("userModalTitle");
    }
    return (
      <Translation>
        {(t) => (
          <Fragment>
            {this.state.modelFlag && (
              <EditModalComp
                closeModal={this.closeModal}
                modalBody={formTemplate}
                modalTitle={modalTitle}
              />
            )}
            <div className="header-btn-lg pr-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle color="link" className="p-0">
                        <img
                          className="rounded-circle"
                          src={this.state.setImages.data_url}
                          alt=""
                          width={44}
                          height={44}
                        />
                        <FontAwesomeIcon
                          className="ml-2 opacity-8"
                          icon={faAngleDown}
                        />
                      </DropdownToggle>
                      <DropdownMenu
                        right
                        className="rm-pointers dropdown-menu-lg"
                        style={{ minWidth: "200px", paddingBottom: "0" }}
                      >
                        <div
                          className="dropdown-menu-header"
                          style={{ marginBottom: "0" }}
                        >
                          <div className="dropdown-menu-header-inner bg-info">
                            <div
                              className="menu-header-image opacity-2"
                              style={{
                                backgroundImage: "url(" + city3 + ")",
                              }}
                            />
                            <div className="menu-header-content">
                              <Button
                                onClick={this.editProfile}
                                className="btn-pill btn-shadow btn-shine"
                                style={{ width: "150px" }}
                                color="focus"
                              >
                                {t("profileBtn")}
                              </Button>
                            </div>
                            {user?.role === "admin" && (
                              <div
                                className="menu-header-content"
                                style={{ marginTop: "10px" }}
                              >
                                <Link
                                  to="dashboard"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Button
                                    onClick={this.switchRole}
                                    className="btn-pill btn-shadow btn-shine"
                                    style={{ width: "150px" }}
                                    color="focus"
                                  >
                                    {t(this.state.selectedRole + "GoTo")}
                                  </Button>
                                </Link>
                              </div>
                            )}
                            <div
                              className="menu-header-content"
                              style={{ marginTop: "10px" }}
                            >
                              <Button
                                onClick={this.logout}
                                className="btn-pill btn-shadow btn-shine"
                                style={{ width: "150px" }}
                                color="focus"
                              >
                                {t("logout")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                  <div className="widget-content-left  ml-3 header-user-info">
                    <div className="widget-heading">
                      {`${user && user.firstName}` +
                        " " +
                        `${user && user.lastName}`}
                    </div>
                    <div className="widget-subheading">
                      {`${
                        this.state.selectedRole &&
                        i18next.t(this.state.selectedRole)
                      }`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (appState: AppState) => ({
  userUpdated: appState.admin.userUpdated,
  reloadState: appState.user.reloadState,
  activeKey : appState.user.activeKey
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => Actions.user.logout(dispatch),
  reloadTemplate: (role) => Actions.user.reloadTemplate(role, dispatch),
  editUserList: (user) => Actions.user.editUserList(dispatch, user),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserBox));
