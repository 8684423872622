import React, {Fragment, Component} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Typeahead} from 'react-bootstrap-typeahead';
import i18n from '../../i18n/I18n';
import i18next from 'i18next';
import {toast} from 'react-toastify';
import avatar1 from '../../assets/utils/images/avatars/dummyUser.png';
import './style.css';
import {
    Row, Col,
    Card, CardBody,
    CardTitle,
    Button
} from 'reactstrap';

interface Props extends Translate{
    closeModal?: any;
    loading?: any;
    editPassword: (any: object) => void;
    passwordUpdated: boolean;
  }

interface State{
  errors?: any,
  id?: any,
  errorMessage: string,
  currentPass?: any,
  newPass?: any,
  confirmNewPass?: any
}

class EditPasswordForm extends Component<Props, State> {

    constructor(props){
      super(props)
      this.state={
            errors: {
              currentPassErr:'',
              newPassErr:'',
              confirmNewPassErr:''
            },
            id: null,
            errorMessage: null,
            currentPass: '',
            newPass: '',
            confirmNewPass: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static getDerivedStateFromProps(newState, prevState) {
      return {
        loading: newState.loading,
        success: newState.success,
        errorMessage: newState.errorMessage,
      };
    }

    componentDidUpdate(prevProps, prevState){
      if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
        let {errors} = this.state;
        let errFlag = true;
        errors['currentPassErr'] = i18next.t(this.state.errorMessage);
        this.setState(errors);
      }
    }

    //setting current password input field.
    currentPassHandler = (e) =>{
      this.setState({currentPass: e.target.value})
    }

    //setting new password input field.
    newPassHandler = (e) =>{
      this.setState({newPass: e.target.value})
    }

    //setting value re-enter password field
    confirmPassHandler = (e) => {
      this.setState({confirmNewPass: e.target.value})
    }

    // checking mandatory fields,call edit password api and save changes.
    handleSubmit = () =>{
      let errFlag = false;
      let {errors} = this.state;
      errors['currentPassErr'] = '';
      errors['newPassErr'] = '';
      errors['confirmNewPassErr'] = '';

      if(!this.state.currentPass){
        errFlag= true;
        errors['currentPassErr'] = i18next.t('PasswordError');
      }
      if(!this.state.newPass){
        errFlag= true;
        errors['newPassErr'] = i18next.t('newPasswordErr');
      }
      else if(this.state.currentPass === this.state.newPass){
        errFlag = true;
        errors['newPassErr'] = i18next.t('samePasswordError');
      }
      if(!this.state.confirmNewPass){
        errFlag= true;
       errors['confirmNewPassErr'] = i18next.t('confirmNewPassword');
      }
      else if(this.state.newPass !== this.state.confirmNewPass){
        errFlag= true;
        errors['confirmNewPassErr'] =i18next.t('confirmPasswordError');
      }

      this.setState(errors);

      if(!errFlag){
       let obj={
        id : JSON.parse(localStorage.getItem('user')).id,
        oldPassword : this.state.currentPass,
        newPassword : this.state.newPass
       }
      this.props.editPassword(obj)
    }
  }

    render() {
      const {errors} = this.state;
        return (
            <Translation>{t => (<Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                     </ReactCSSTransitionGroup>
                     <div>
                        <form id="editPasswordForm">
                             <div className="form-group row">
                                <h5 className="col-sm-4 col-form-label"><b>{t('passLabel')} *</b></h5>
                                <div className="col-sm-8">
                                   <input type="password" placeholder={t('passLabelPlaceholder')} id="currentPass" className="form-control" value={this.state.currentPass} onChange={this.currentPassHandler} disabled={this.props.passwordUpdated} required/>
                                   {errors.currentPassErr !== '' && <div id="currentPassErrField" className="errPassword">{this.state.errors.currentPassErr}</div>}
                                 </div>
                              </div>
                              <div className="form-group row">
                                  <h5 className="col-sm-4 col-form-label"><b>{t('newPassLabel')} *</b></h5>
                                   <div className="col-sm-8">
                                      <input type="password" placeholder={t('newPassLabelPlaceholder')} id="newPass" className="form-control" value={this.state.newPass} onChange={this.newPassHandler} disabled={this.props.passwordUpdated} required/>
                                      {errors.newPassErr !== '' && <div id="newPassErrField" className="errPassword">{this.state.errors.newPassErr}</div>}
                                   </div>
                               </div>
                               <div className="form-group row">
                                  <h5 className="col-sm-4 col-form-label"><b>{t('confPassLabel')} *</b></h5>
                                   <div className="col-sm-8">
                                      <input type="password" placeholder={t('confPassLabelPlaceholder')} id="confirmNewPass" className="form-control" value={this.state.confirmNewPass} onChange={this.confirmPassHandler} disabled={this.props.passwordUpdated} required/>
                                      {errors.confirmNewPassErr !== '' && <div id="confirmNewPassErrField" className="errPassword">{this.state.errors.confirmNewPassErr}</div>}
                                   </div>
                                </div>
                            </form>
                        </div>
                        <hr />
                        {this.props.passwordUpdated === false ?
                        <div className="editUserBtn">
                            <Button color="link" onClick={this.props.closeModal}>{i18next.t('cancel')}</Button>
                            <Button color="primary" onClick={this.handleSubmit} disabled={this.props.loading}>{this.props.loading ? i18next.t('passChanging') : i18next.t('passChange')} </Button>
                        </div>  :
                        <h6 className="label">{t('redirectMsg')}</h6>}
                  </Fragment>)}
             </Translation>
            )
        }
    }
    
    const mapStateToProps = (appState: AppState) => ({
      loading: appState.loading.loading,
      errorMessage: appState.user.UpdatePasswordRequest.$errorMessage,
      passwordUpdated: appState.user.passwordUpdated
     });
    
    const mapDispatchToProps = dispatch => ({
     editPassword : (password) => Actions.user.editPassword(dispatch, password)
     });
    export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditPasswordForm));
    