import React, {Component,Fragment, useState} from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import i18next from 'i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import RegulationList from './../../model/RegulationList';
import AllLangDataList from './../../model/AllLangDataList';
import i18n from '../../i18n/I18n';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import filterFactory, { textFilter, numberFilter  } from 'react-bootstrap-table2-filter';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './table.css';
import DownArrow from './../../chevron-sign-down.png';
import UpArrow from './../../up-chevron-button.png';
import RightIcon from './../../right-icon.png';
import DownIcon from './../../down-icon.png';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import {
    Row, Col, Button
} from 'reactstrap';
import { HideColList } from './HideColList';
import mockData from './../../utils/StaticData.json';
import EditModalComp from '../Table/EditModal';
import AllLangComp from './AllLangComp';
import {toast} from 'react-toastify';
import {languageArr} from './../ManageRegulations/RegulationsColData';
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';
import {saveFilterApi} from './../AdvancedFilterComp/ToggleFilter';
import infoImage from './../../InfoImage.png';
import CustomSearchFilter from './CustomSearchFilter';
import { isThisSecond } from 'date-fns';
import moment from "moment";
import DocumentTableComp from './DocumentTable';
import RegDetailsTableComp from './RegDetailsTable';
import RegDetails from './RegDetails';
import { ro } from 'date-fns/locale';
var qs = require('qs');
const t = TranslationService.t;


export const RenderDataComponent = ({className, row, id, title , attr}) => {
  const [hovered, setHovered] = useState(false)
  return (
      <span
        id={row.id+id}
        className={hovered ? `readMoreCss ${className}` : `truncateCss readMoreCss ${className}`}
        onMouseEnter={id !== 'toggleMoreInfo' ? () => setHovered(true) : null}
        onMouseLeave={id !== 'toggleMoreInfo' ? () => setHovered(false) : null}
        >
        {title ? title :
      <span
       id={row.id+'Formatter'+row.btnId} className={attr} style={{cursor: 'pointer', color: '#545cd8'}}>

       </span> }
      </span>
     )
}
export function getCustomFilter(handleFilter, customFilterParameters) {
  return (
    <CustomSearchFilter handleFilter={ handleFilter } dataField={customFilterParameters.dataField} placeholder={customFilterParameters.placeholder}/>
  );
}

  interface Props extends Translate{
    RegulationDataList ?: any;
    resetMap: () => {};
    goBack: () => {};
    handleFileExport: (any) => {};
    getAllLangData: (regulation, attr) => {};
    allLangDataFetched: boolean;
    allLangDataArr?: any;
    imgflag?: any;
    loading: boolean;
    marketSelected?: any;
    saveSearchFilter: (any) => {};
    favorites ?: any;
    filterSaved: boolean;
    compID ?: any;
    RegulationIdList ?: any;
    showRegulationType : string;
    activeKey ?: any,
    regulationFetched?: boolean,
    regulationIdFetched: boolean,
    location ?:  any;
    history ?:any;
  }

  interface State{
    marketHidden: boolean,
    moreInfoHidden: boolean,
    regNoHidden: boolean,
    titleHidden: boolean,
    statusHidden: boolean,
    topicTypeHidden: boolean,
    topicHidden: boolean,
    regulationDataArr?: any,
    editModalFlag: boolean,
    errorMessage: string,
    allLangDataArr: AllLangDataList[],
    allLangTopic: string,
    allLangAttr: string,
    filterName: string,
    showFilterInput:boolean,
    resizeListener: boolean,
    groupHidden: boolean,
    subGroupHidden: boolean,
    selectedHideShowCol?: any,
    showDetailRegulation: boolean,
    referenceRegErrMsg?:any,
    rowData ?: any
  }

  class UserTableComp extends Component<Props, State> {
    constructor(props){
      super(props)
      this.state={
        marketHidden: false,
        moreInfoHidden: false,
        regNoHidden: false,
        titleHidden: false,
        statusHidden: false,
        topicTypeHidden: false,
        topicHidden: false,
        regulationDataArr:[],
        editModalFlag: false,
        errorMessage: null,
        allLangDataArr:[],
        allLangTopic: "",
        allLangAttr: "",
        filterName:"",
        showFilterInput: false,
        resizeListener:false,
        groupHidden: false,
        subGroupHidden: false,
        selectedHideShowCol:[],
        showDetailRegulation: false,
        referenceRegErrMsg: null,
        rowData : {}
      }
    }

    componentDidMount(){
      //Prevent page refresh on size per page selection using jquery.
      $(document).ready(function(){
        $(".react-bs-table-sizePerPage-dropdown").find("a").click(function(event){
          event.preventDefault();
        });
      });
    }

    static getDerivedStateFromProps(props, state) {
      return {
        success: props.success,
        errorMessage: props.errorMessage,
        referenceRegErrMsg: props.referenceRegErrMsg
      };
    }

    componentDidUpdate(prevProps, prevState) {
      //Resetting table data state to blank after tab change
      if (prevProps.activeKey !== this.props.activeKey) {
        this.setState({regulationDataArr:[]});
      }
      if (prevProps.regulationIdFetched !== this.props.regulationIdFetched) {
          let filterData = [];
          if (this.props.RegulationIdList) {
            filterData.push(this.props.RegulationIdList);
          }
          filterData && filterData.map((item, index) =>{
          let categoryTypes = '';
          item['btnId'] = (index*10);
          item['rowId'] = index+1;
          item['topicsType'] = i18next.t(item.topic.type);
        });
        this.setState({regulationDataArr: filterData});
      }

      if(this.state.referenceRegErrMsg && this.state.referenceRegErrMsg !== prevState.referenceRegErrMsg){
        const t = TranslationService.t;
        toast['error'](t(this.state.referenceRegErrMsg), { position: 'top-right' });
      }
      //Updating documents table when regulations fetched from api
      if (prevProps.regulationFetched !== this.props.regulationFetched) {
        let filterData = this.props.RegulationDataList ? [...this.props.RegulationDataList] : [];
          filterData && filterData.map((item, index) =>{
          let categoryTypes = '';
          item['btnId'] = (index*10);
          item['rowId'] = index+1;
          item['topicsType'] = i18next.t(item.topic.type);
        });

         this.setState({regulationDataArr: filterData});
      }
      //Hide favorites save form when filter saved successfully
      if (this.props.filterSaved && prevProps.filterSaved !== this.props.filterSaved) {
        this.setState({showFilterInput:false});
      }
      if(this.props.allLangDataFetched && prevProps.allLangDataFetched !== this.props.allLangDataFetched){
        let allLangData = [];
        this.props.allLangDataArr && [...this.props.allLangDataArr].map(item =>{
          /* Api returns status ID, so based on ID we are showing lang specific label for status using mockData JSON
          since status is not managed by admin but we have kept it static in UI(inside mockData) */
            if (item.key === 'actualStatus' || item.key === 'futureStatus') {
              if (item.value != '0') {
                [...mockData.statusUser[item.languageCode]].map(elem =>{
                  if (elem.id == item.value) {
                    let obj = {...item};
                    obj['value'] = item.name;
                    allLangData.push(obj);
                  }
                })
              }
              else{
                let obj = {...item};
                obj['value'] = null;
                allLangData.push(obj);
              }
            }
            else{
              allLangData.push(item);
            }
        });
        this.setState({allLangDataArr: allLangData});
        //Show info popup if attribute data for all lang returns blank from api
        if (this.props.allLangDataArr && this.props.allLangDataArr.length < 1) {
          toast['info'](i18next.t('allLangDataBlank'), { position: 'top-right' });
        }
      }
      if(this.state.errorMessage && this.state.errorMessage !== prevState.errorMessage){
        toast['error'](i18next.t(this.state.errorMessage), { position: 'top-right' });
      }
      //Setting hideshow column listing language text when language changes
      if (this.props.t !== prevProps.t) {
        this.state.selectedHideShowCol.map(item =>{
          item['name'] = i18next.t('hideShowCol.'+item.id);
        });
      }
    }
    //close all lang modal component.
    closeModal = () =>{
        this.setState({editModalFlag: false, allLangTopic:"", allLangAttr: "", allLangDataArr:[]});
    }

    // format topic column.
    categoryFormatter(cell, row){
      return <RenderDataComponent className='' row={row} id={'togglename'} title={cell.name} attr=''/>
    }

    //format topic type column.
    topicTypeFormatter(cell, row){
      return <RenderDataComponent className='' row={row} id={'toggletopicsType'} title={row.topicsType} attr='' />
    }

    //format group column.
    groupFormatter(cell,row){
      return <RenderDataComponent className='' row={row} id={'togglegroup'} title={!row.topic.group ? '' : row.topic.group.name} attr='togglegroup'/>
    }

    //format sub-group column.
    subGroupFormatter(cell, row){
      return <RenderDataComponent className='' row={row} id={'togglesubGroup'}  title={!row.topic.subGroup ? null : row.topic.subGroup.name} attr=''/>
    }

    //market column data formatter
    marketFormatter(cell,row){
      return cell.label;
    }

    //display all lang modal.
    showPopup = (row, attrKey) =>{
      this.setState({ editModalFlag: true, allLangAttr:attrKey, allLangDataArr:[]});
      this.state.regulationDataArr && [...this.state.regulationDataArr].map(item =>{
        if (row.id === item.id) {
          this.setState({allLangTopic: item.topic.name});
          this.props.getAllLangData(row, {key: attrKey});
        }
      });
    }

    //Actual regulation number column data formatter
    regNoFormatterActual = (cell,row) =>{
      if (row.actualRegulationNumber) {
        return  <RenderDataComponent className='' row={row} id={'toggleactualRegulationNumber'} title={
          <a href="javascript:void(0);" onClick={() =>this.regulationClick(row)}>{row.actualRegulationNumber}</a>} attr='' />
      }
    }

    regulationClick = (row) =>{
      this.setState({showDetailRegulation: true , rowData: row});
      // this.props.getRegulationDetailsById(rowId);
    }

    //call on change of hide column typehead value.
    hideShowHandler = (data) =>{
      let obj = {marketHidden: false,
                moreInfoHidden: false,
                regNoHidden: false,
                titleHidden: false,
                statusHidden: false,
                topicTypeHidden: false,
                topicHidden: false,
                subGroupHidden: false,
                groupHidden:false};

      for (let index = 0; index < data.length; index++) {
          obj[data[index].id] = true;
    }
    this.setState(obj);
    this.setState({selectedHideShowCol: data});
  }

  //call on click of save button in map table and open filter input form.
  handleSaveFilter = ()=>{
    this.setState({showFilterInput: true});
  }

  //call on click of save button and save the filter in favorites.
  saveFilter = (event) =>{
    event.preventDefault();
    const filterNamePattern = /^[a-zA-Z0-9_-\s]+$/;
    let {filterName} = this.state;
    if (filterName.trim() === '') {
      this.setState({filterName: ''});
      toast(i18next.t('favorites.filterBlankMsg'), { position: 'top-right', type: 'error'});
    }
    else if(!filterNamePattern.test(filterName)){
      toast(i18next.t('favorites.noSplChar'), { position: 'top-right', type: 'error'});
    }
    else{
      const filterUrl = qs.stringify(this.props.marketSelected,{arrayFormat: 'repeat'});
      saveFilterApi('add', '', filterName, filterUrl, this.props);
    }
  }

  //setting the filter name input field.
  handleFilterName(e){
    this.setState({filterName: e.target.value});
  }

  //display the total number of rows in table.
  renderShowsTotal(start, to, total){
    return (
      <p className="totalRows">
        {i18next.t('table.totalRecords')} { total }
      </p>
    );
  }

  //Close detailed regulation modal
  closeModalRegDetail = () =>{
    this.setState({showDetailRegulation: false});
  }
  handleBack =() =>{
    this.props.history.goBack()
  }
  render() {
    const options = {
      noDataText: i18next.t('table.noDataMsg'),
      paginationShowsTotal:this.renderShowsTotal,
      paginationPosition:'both'
    };
    const spinner4 = <LoaderAnim active type="ball-beat"/>;
   return(
        <div id="userDataTable">
          <Translation>{t => (
            <Fragment>
            { this.state.editModalFlag && this.state.allLangDataArr.length > 0 && 
            <EditModalComp modalID="allLangModal" closeModal={this.closeModal} modalBody={<AllLangComp {...this.props} allLangAttr2={i18next.t(this.state.allLangAttr+'AllLangLabel2')} allLangAttr1={i18next.t(this.state.allLangAttr+'AllLangLabel1')} allLangTopic={this.state.allLangTopic} allLangDataArr={this.state.allLangDataArr}/>} modalTitle={i18next.t('allLangData')} />
            }
            {this.state.showDetailRegulation && <EditModalComp modalID="regulationDetails" closeModal={this.closeModalRegDetail} modalBody={<RegDetails  rowData={this.state.rowData} market={this.state.rowData && this.state.rowData.market.value} flag={false}/>} modalTitle={t('table.regulationDataTitle')} />}
            {!this.props.compID &&
              <div id="hideShowCol">
                <Row>
                  <div className="form-inline col-sm-12">
                  {this.props.resetMap &&
                    <div className="col-sm-6 col-md-6 col-xs-12" style={{'padding':'0.2rem'}}>
                      <div className="form-inline col-sm-12">
                        <Button color="primary" id="backToMap" onClick={this.props.resetMap}>{t('map.showMap')}</Button>
                        <a data-tip={t('advancedFilters.saveFilterTooltip')} data-for="saveFilter">
                         { this.state.regulationDataArr.length > 0 &&
                        <div>
                           <Button id="saveFilter" className="btn btn-success Filter" onClick={this.handleSaveFilter} style={{'margin':'0 5px', 'fontSize':'small'}}>
                             {t('advancedFilters.saveFilter')}
                           </Button>
                           </div>}
                        </a>
                        <ReactTooltip id="saveFilter" place="bottom" type="info" effect="float"/>
                        {this.state.showFilterInput &&
                          <form className="form-inline" onSubmit={this.saveFilter}>
                            <input type="text" title={[t('favorites.filterBlankMsg'),t('favorites.noSplChar')].join('')} value={this.state.filterName}
                              className="form-control" onChange={this.handleFilterName.bind(this)} placeholder={t('advancedFilters.saveFilterInputPlaceholder')}
                              style={{'fontSize':'small'}} required/>
                            <Button type="submit" id="saveFilterHandler" style={{'margin':'0 5px', 'fontSize':'small'}} className="btn btn-success">
                              {t('save')}
                            </Button>
                          </form>
                        }
                      </div>
                    </div>
                  }
                  {this.props.goBack &&
                    <div className="col-sm-6 col-md-6 col-xs-12">
                        <Button color="primary" id="goBack" onClick={this.handleBack}>{t('back')}</Button>
                    </div>
                  }
                  <div className={this.props.goBack || this.props.resetMap ? 'col-sm-6' : 'col-sm-12'}>
                  <Col md="12" id="mapButton" className="form-inline">
                      {/*<div className="col-sm-2" style={{'alignSelf':'flex-end'}}>
                        <h6 className="tableLabel">{t('table.tableLabel')}</h6>
                      </div>*/}
                      <div className="col-sm-12 form-inline" style={{'flexFlow':'row-reverse'}}>
                      <a data-tip={this.props.imgflag === true ? t('table.excelTooltip') : t('advancedFilters.createExcel')} data-for="ImgtipExcel">
                        <img src={require("../AdvancedFilterComp/Excel.png")} id="excelImg" onClick={() => this.props.handleFileExport('EXCEL')} style={this.props.imgflag === true?{opacity:0.5, 'pointerEvents':'none'}: {}}/>
                      </a>
                      <ReactTooltip id="ImgtipExcel"  place="left" type="info" effect="float"/>
                      <a data-tip={this.props.imgflag === true ? t('table.pdfTooltip') : t('advancedFilters.createPDF')} data-for="ImgtipPdf">
                        <img src={require("../AdvancedFilterComp/pdf.png")} id="image" onClick={() => this.props.handleFileExport('PDF')} style={this.props.imgflag === true?{opacity:0.5, 'pointerEvents':'none'}: {}}/>
                      </a>
                      <ReactTooltip id="ImgtipPdf"  place="left" type="info" effect="float"/>
                      <Typeahead
                        id="basic-typeahead-multiple"
                        emptyLabel={t('noMatchFound')}
                        clearButton
                        labelKey={option =>`${t(option.name)}`}
                        multiple
                        onChange={this.hideShowHandler}
                        options={HideColList()}
                        selected={this.state.selectedHideShowCol}
                        placeholder={t('table.hideColText')}
                      />
                      </div>
                   </Col>
                  </div>
                  </div>
                </Row>
            </div>}
            <Loader message={spinner4} show={this.props.loading}>
              <BootstrapTable striped hover options={options} data={ this.state.regulationDataArr ? this.state.regulationDataArr : [] } pagination={this.props.compID ? false : true}>
                <TableHeaderColumn hidden={this.props.compID} dataField='rowId' isKey={true}>{t('id')}</TableHeaderColumn>
                <TableHeaderColumn
                 hidden={this.state.groupHidden}
                 filterFormatted
                 dataField='group'
                 dataFormat={this.groupFormatter}
                 filter={
                   this.props.compID ? {} : {
                    type: 'CustomFilter',
                    placeholder:i18next.t('groupPlaceholder'),
                    getElement: getCustomFilter,
                    customFilterParameters:  { dataField: 'group',placeholder: i18next.t('groupPlaceholder') }
                   }
                  } >{t('table.group')}</TableHeaderColumn>
                <TableHeaderColumn
                 hidden={this.state.subGroupHidden}
                 filterFormatted
                 dataField='subGroup'
                 dataFormat={this.subGroupFormatter}
                 filter={
                   this.props.compID ? {} : {
                     type: 'CustomFilter',
                     placeholder: i18next.t('subgroupPlaceholder'),
                     getElement: getCustomFilter,
                     customFilterParameters:  { dataField: 'subGroup',placeholder: i18next.t('groupPlaceholder') }
                    }
                  } >{t('table.subGroup')}</TableHeaderColumn>
                <TableHeaderColumn
                 hidden={this.state.topicHidden}
                 dataField='topic'
                 filterFormatted
                 dataFormat={this.categoryFormatter}
                 filter={
                   this.props.compID ? {} : {
                     type: 'CustomFilter',
                     placeholder: i18next.t('topicPlaceholder'),
                     getElement: getCustomFilter,
                     customFilterParameters:  { dataField: 'topic',placeholder:  i18next.t('topicPlaceholder') }
                     }
                  }>{t('table.topic')}</TableHeaderColumn>
                <TableHeaderColumn
                 hidden={this.state.topicTypeHidden}
                 filterFormatted
                 dataField= 'topicsType'
                 dataFormat={this.topicTypeFormatter}
                 filter={
                   this.props.compID ? {} : {
                     type: 'CustomFilter',
                     placeholder: i18next.t('topicTypePlaceholder'),
                     getElement: getCustomFilter,
                     customFilterParameters:  { dataField: 'topicsType',placeholder:  i18next.t('topicTypePlaceholder')}
                     }} >{t('table.topicType')}</TableHeaderColumn>
                <TableHeaderColumn
                 hidden={this.state.marketHidden}
                 filterFormatted
                 dataField= 'market'
                 dataFormat={this.marketFormatter}
                 filter={
                   this.props.compID ? {} : {
                     type: 'TextFilter',
                     placeholder: i18next.t('marketPlaceholder')
                    }
                  } >{t('table.market')}</TableHeaderColumn>
                  <TableHeaderColumn
                  hidden={this.state.regNoHidden}
                  filterFormatted
                  dataField='actualRegulationNumber'
                  dataFormat={this.regNoFormatterActual}
                  filter={
                    this.props.compID ? {} : {
                      type: 'CustomFilter',
                      placeholder: i18next.t('regNoPlaceholder'),
                      getElement: getCustomFilter,
                      customFilterParameters:  { dataField: 'actualRegulationNumber',placeholder:  i18next.t('regNoPlaceholder') }
                    }
                  }>{t('table.regulationNo')}</TableHeaderColumn>
              </BootstrapTable>
            </Loader>
          </Fragment>
      )}</Translation>
      </div>
        );
       }
    }

  const mapStateToProps = (appState: AppState) => ({
    RegulationDataList: appState.user.RegulationDataList,
    allLangDataFetched: appState.user.allLangDataFetched,
    allLangDataArr: appState.user.allLangDataArr,
    loading: appState.loading.loading,
    success: appState.user.allLangDataArrRequest.$success,
    errorMessage: appState.user.allLangDataArrRequest.$errorMessage,
    favorites : appState.user.favorites,
    filterSaved: appState.user.filterSaved,
    RegulationIdList: appState.user.RegulationIdList,
    activeKey : appState.user.activeKey,
    regulationFetched: appState.user.regulationFetched,
    regulationIdFetched: appState.user.regulationIdFetched,
  });

  const mapDispatchToProps = dispatch => ({
    getAllLangData: (regulation, attr) => Actions.user.getAllLangData(regulation, attr, dispatch),
    saveSearchFilter: (filter) => Actions.user.saveSearchFilter(filter, dispatch),
   });

  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserTableComp));
