import React, { Fragment, Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import Actions from "../internal/modules/Actions";
import AppHeader from '../Layout/AppHeader';
import Dashboards from './Dashboards';
import { AppState } from './../internal/modules/Reducers';
import FavoritesDataComp from './FavoritesDataComp/favoritesData';
import ShowAllVehicles from './ManageVehicles/ShowAllVehicles';
import ShowAllTopics from './ManageTopics/ShowAllTopics';
import ShowAllGroups from './ManageGroups/ShowAllGroups';
import ShowAllScopes from './ManageScopes/ShowAllScopes';
import ShowAllFuelTypes from './ManageFuelTypes/ShowAllFuelTypes';
import ShowAllUsers from './ManageUser/ShowAllUsers';
import ShowAllRegulations from './ManageRegulations/ShowAllRegulations';
import AppSidebar from '../Layout/AppSidebar';
import SettingComp from './Setting/Market';
import watermarkimg from './../watermark.png';
import { connect } from 'react-redux';
import ConfirmComp from './EmailConfirmation/Confirm';
import LoginBoxed from "./login/index";
import ResetPasswordComp from './ResetPassword/ResetPassword';
import ShowAllDriveType from './ManageDriveType/ShowAllDriveType';
import MarketValueComp from './Setting/MarketValue';
import LandingPage from './LandingPage';
import ManageFaq from './ManageFaqs/ManageFaq';
import ShowRevisionHistory from './ManageRevisionHistory/ShowRevisionHistory';
import ChatApp from './ChatGPT/chat'

class DashboardsRoutes extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem('user'))
        }
    }
    render(){
        const { match } = this.props;
        const {user} = this.state;
        let shareUrlFlag = false;
        if (!localStorage.getItem('token') || localStorage.getItem('token') === 'null'){
          shareUrlFlag = true;
        }
        if (this.props.location.search.includes('share=true') && (!localStorage.getItem('token') || localStorage.getItem('token') === 'null')) {
          localStorage.removeItem('shareURL');
        }
        let selectedRole = sessionStorage.getItem('selectedRole');
        let iThomos = localStorage.getItem('iThomos')
        return (
            <Fragment>
              {!shareUrlFlag && <AppHeader/>}
                <div className="app-main" style={shareUrlFlag ? {'padding':'0px'} : null}>
                  {!shareUrlFlag && selectedRole === 'admin' &&  !this.props.location.pathname.includes('/app/favorites') && <AppSidebar data={iThomos === 'iThosmos' ? 'iThomos' : ''}/>}
                    <div style={!shareUrlFlag ? (selectedRole !== 'admin' ? {'paddingLeft':'0px'} : (this.props.location.pathname.includes('/app/favorites') ? {'paddingLeft':'0px'} : null)) : {'padding':'0px'}} className="app-main__outer">
                        <div className="app-main__inner" style={shareUrlFlag ? {'padding':'0px'} : null}>
                          {
                            !shareUrlFlag ?
                              <div className="waterMarkContainer" style={selectedRole === 'user' ? {'display':'contents'} : {}}>
                              <img className="watermarkimg" src={watermarkimg} alt="Notebook" style={{'width':'50%'}} />
                                
                                <Route path={`${match.url}/home`} component={LandingPage} />
                                <Route path={`${match.url}/chat`} component={ChatApp} />
                                <Route path={`${match.url}/dashboard`} component={Dashboards} />
                              
                                <Route exact path={`${match.url}/favorites`} component={FavoritesDataComp} />
                                {selectedRole === 'admin' &&
                                <Fragment>
                                <Route exact path={`${match.url}/users`} component={ShowAllUsers} />
                                <Route exact path={`${match.url}/manage-regulations`} component={ShowAllRegulations} />
                                <Route exact path={`${match.url}/manage-markets`} component={SettingComp} />
                                <Route exact path={`${match.url}/manage-vehicles`} component={ShowAllVehicles} />
                                <Route exact path={`${match.url}/manage-topics`} component={ShowAllTopics} />
                                <Route exact path={`${match.url}/manage-drive-type`} component={ShowAllDriveType} />
                                <Route exact path={`${match.url}/manage-groups`} component={ShowAllGroups} />
                                <Route exact path={`${match.url}/manage-fuel-type`} component={ShowAllFuelTypes} />
                                <Route exact path={`${match.url}/manage-scopes`} component={ShowAllScopes} />
                                <Route exact path={`${match.url}/manage-value`} component={MarketValueComp} />
                                <Route exact path={`${match.url}/manage-faqs`} component={ManageFaq} />
                                <Route exact path={`${match.url}/manage-revision-history`} component={ShowRevisionHistory} />
                              </Fragment>}
                              </div>
                              :
                              <Fragment>
                              {/* <Route path="/email/confirm/:uniqueId" component={ConfirmComp} />
                              <Route  path="https://grm.vetaca-homologation.com/#/forgotPassword/:ID"  component={ResetPasswordComp} /> */}
                              {
                                (this.props.location.search.includes('share=true')) ?
                                <Route exact path={`${match.url}/favorites`} component={LoginBoxed} />
                                :
                                <Redirect from="/" to="/login" />
                                }
                              </Fragment>
                          }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
};

const mapStateToProps = (AppState) => ({
  loginRandomNo: AppState.user.loginRandomNo,
  loginRandomNoAdmin: AppState.admin.loginRandomNoAdmin,
  reloadState: AppState.user.reloadState
 });

 const mapDispatchToProps = dispatch => ({
 reloadTemplate: (role) => Actions.user.reloadTemplate(role, dispatch)
 });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardsRoutes));
