import React, {Component,Fragment} from 'react';
import { withRouter } from "react-router-dom";
import { AppState } from '../../internal/modules/Reducers';
import { connect } from 'react-redux';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import i18next from 'i18next';

interface Props extends Translate{
  allLangDataArr?: any;
  allLangAttr1: string;
  allLangAttr2: string;
  allLangTopic: string;
}

interface State{
}

class AllLangComp extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Translation>{t => (
              <div className="container">
              <dl className="row">
              <dt className="col-3">{i18next.t('attribute')}</dt>
              <dd className="col-9">{this.props.allLangAttr1}{this.props.allLangTopic.toLowerCase()}{this.props.allLangAttr2}</dd>
              </dl>
                {
                  this.props.allLangDataArr && [...this.props.allLangDataArr].map(item =>{
                      return(
                        <dl className="row">
                        <dt className="col-3">{i18next.t(item.languageCode)}</dt>
                        <dd className="col-9">{item.value ? item.value : i18next.t('notAvailablePopup')}</dd>
                        </dl>
                      )
                  })
                }
              </div>
            )}
            </Translation>
        );
    }
}

const mapStateToProps = (appState: AppState) => ({

 });

const mapDispatchToProps = dispatch => ({
 });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllLangComp));
