import {ModuleEvents} from './Actions';

//Resetting states after api call success/failure and verifiying user session if api call fails
export default {
  [ModuleEvents.USERS]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_USER_STATUS })
  },

  [ModuleEvents.ERROR_USERS]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_USER_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.CREATE_USERS]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_USER_STATUS })
  },

  [ModuleEvents.ERROR_CREATE_USERS]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_USER_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.UPDATE_USERS]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_USER_STATUS })
  },

  [ModuleEvents.ERROR_UPDATE_USERS]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_USER_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.DELETE_USERS]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_USER_STATUS })
  },

  [ModuleEvents.ERROR_DELETE_USERS]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_USER_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.GRM_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GRM_LIST_STATUS })
  },

  [ModuleEvents.ERROR_GRM_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GRM_LIST_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.CREATE_GRM]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GRM_LIST_STATUS })
  },

  [ModuleEvents.ERROR_CREATE_GRM]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GRM_LIST_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.DELETE_GRM]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GRM_LIST_STATUS })
  },

  [ModuleEvents.ERROR_DELETE_GRM]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GRM_LIST_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.VEHICLE_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_VEHICLE_STATUS })
  },

  [ModuleEvents.ERROR_VEHICLE_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_VEHICLE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.CREATE_VEHICLES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_VEHICLE_STATUS })
  },

  [ModuleEvents.ERROR_CREATE_VEHICLES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_VEHICLE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.UPDATE_VEHICLES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_VEHICLE_STATUS })
  },

  [ModuleEvents.ERROR_UPDATE_VEHICLES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_VEHICLE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.DELETE_VEHICLES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_VEHICLE_STATUS })
  },

  [ModuleEvents.ERROR_DELETE_VEHICLES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_VEHICLE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.TOPIC_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_TOPIC_STATUS })
  },

  [ModuleEvents.ERROR_TOPIC_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_TOPIC_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.CREATE_TOPIC]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_TOPIC_STATUS })
  },

  [ModuleEvents.ERROR_CREATE_TOPIC]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_TOPIC_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.UPDATE_TOPIC]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_TOPIC_STATUS })
  },

  [ModuleEvents.ERROR_UPDATE_TOPIC]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_TOPIC_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.DELETE_TOPIC]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_TOPIC_STATUS })
  },

  [ModuleEvents.ERROR_DELETE_TOPIC]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_TOPIC_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },
  [ModuleEvents.MARKET_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_STATUS })
  },

  [ModuleEvents.ERROR_MARKET_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.CREATE_MARKET]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_STATUS })
  },

  [ModuleEvents.ERROR_CREATE_MARKET]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.DELETE_MARKET]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_STATUS })
  },

  [ModuleEvents.ERROR_DELETE_MARKET]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.RESEND_EMAIL]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_RESEND_EMAIL })
  },

  [ModuleEvents.ERROR_RESEND_EMAIL]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_RESEND_EMAIL })
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.REGULATION_NUMBER_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_REGULATION_NUMBER_LIST })
  },

  [ModuleEvents.ERROR_REGULATION_NUMBER_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_REGULATION_NUMBER_LIST });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },
  [ModuleEvents.DRIVE_TYPE_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DRIVE_TYPE_STATUS })
  },

  [ModuleEvents.ERROR_DRIVE_TYPE_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DRIVE_TYPE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.CREATE_DRIVE_TYPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DRIVE_TYPE_STATUS })
  },

  [ModuleEvents.ERROR_CREATE_DRIVE_TYPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DRIVE_TYPE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.UPDATE_DRIVE_TYPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DRIVE_TYPE_STATUS })
  },

  [ModuleEvents.ERROR_UPDATE_DRIVE_TYPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DRIVE_TYPE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.DELETE_DRIVE_TYPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DRIVE_TYPE_STATUS })
  },

  [ModuleEvents.ERROR_DELETE_DRIVE_TYPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DRIVE_TYPE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },


  [ModuleEvents.GROUP_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GROUP_STATUS })
  },

  [ModuleEvents.ERROR_GROUP_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GROUP_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.CREATE_GROUP]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GROUP_STATUS })
  },

  [ModuleEvents.ERROR_CREATE_GROUP]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GROUP_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.UPDATE_GROUP]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GROUP_STATUS })
  },

  [ModuleEvents.ERROR_UPDATE_GROUP]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GROUP_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.DELETE_GROUP]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GROUP_STATUS })
  },

  [ModuleEvents.ERROR_DELETE_GROUP]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GROUP_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.GROUP_SUBGROUP_RELATION_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GROUP_SUBGROUP_RELATION_LIST })
  },

  [ModuleEvents.ERROR_GROUP_SUBGROUP_RELATION_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_GROUP_SUBGROUP_RELATION_LIST });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.FUEL_TYPE_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FUEL_TYPE_STATUS })
  },

  [ModuleEvents.ERROR_FUEL_TYPE_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FUEL_TYPE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.CREATE_FUEL_TYPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FUEL_TYPE_STATUS })
  },

  [ModuleEvents.ERROR_CREATE_FUEL_TYPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FUEL_TYPE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.UPDATE_FUEL_TYPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FUEL_TYPE_STATUS })
  },

  [ModuleEvents.ERROR_UPDATE_FUEL_TYPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FUEL_TYPE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.DELETE_FUEL_TYPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FUEL_TYPE_STATUS })
  },

  [ModuleEvents.ERROR_DELETE_FUEL_TYPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FUEL_TYPE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.SCOPE_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_SCOPE_STATUS })
  },

  [ModuleEvents.ERROR_SCOPE_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_SCOPE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.CREATE_SCOPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_SCOPE_STATUS })
  },

  [ModuleEvents.ERROR_CREATE_SCOPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_SCOPE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.UPDATE_SCOPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_SCOPE_STATUS })
  },

  [ModuleEvents.ERROR_UPDATE_SCOPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_SCOPE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.DELETE_SCOPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_SCOPE_STATUS })
  },

  [ModuleEvents.ERROR_DELETE_SCOPE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_SCOPE_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.SCOPE_SUBSCOPE_RELATION_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_SCOPE_SUBSCOPE_RELATION_LIST })
  },

  [ModuleEvents.ERROR_SCOPE_SUBSCOPE_RELATION_LIST]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_SCOPE_SUBSCOPE_RELATION_LIST });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.ERROR_UPLOAD_FILES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_UPLOAD_FILES_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.ERROR_EDIT_FILES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_EDIT_FILES_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.ERROR_DELETE_FILES_ADMIN]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DELETE_FILES_ADMIN_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },
  [ModuleEvents.CREATE_MARKET_VALUE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_PROFILE_VALUES})
  },
  [ModuleEvents.UPLOAD_MARKET_VALUE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_PROFILE_VALUES })
  },
  [ModuleEvents.DELETE_MARKET_VALUE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_PROFILE_VALUES })
  },
  [ModuleEvents.MARKET_PROFILE_VALUES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_PROFILE_VALUES })
  },

  [ModuleEvents.ERROR_UPLOAD_MARKET_PROFILE_FILES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_UPLOAD_MARKET_PROFILE_FILES_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.ERROR_EDIT_MARKET_PROFILE_FILES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_EDIT_MARKET_PROFILE_FILES_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },

  [ModuleEvents.ERROR_DELETE_MARKET_PROFILE_ADMIN]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DELETE_MARKET_PROFILE_ADMIN });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },
  [ModuleEvents.CREATE_MARKET_PROFILE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_PROFILE_STATUS })
  },
  [ModuleEvents.EDIT_MARKET_PROFILE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_PROFILE_STATUS })
  },
  [ModuleEvents.GET_MARKET_PROFILE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_MARKET_PROFILE_STATUS })
  },
  [ModuleEvents.DELETE_MARKET_PROFILE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DELETE_MARKET_PROFILE })
  },
  [ModuleEvents.ERROR_DELETE_MARKET_PROFILE]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DELETE_MARKET_PROFILE })
  },
  [ModuleEvents.GET_FAQ]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FAQ_STATUS })
  },
  [ModuleEvents.ERROR_GET_FAQ]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FAQ_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },
  [ModuleEvents.UPDATE_FAQ]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FAQ_STATUS })
  },
  [ModuleEvents.ERROR_UPDATE_FAQ]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FAQ_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },
  [ModuleEvents.ADD_FAQ]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FAQ_STATUS })
  },
  [ModuleEvents.ERROR_ADD_FAQ]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FAQ_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },
  [ModuleEvents.DELETE_FAQ]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FAQ_STATUS })
  },
  [ModuleEvents.ERROR_DELETE_FAQ]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_FAQ_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },
  [ModuleEvents.ADD_REV_HISTORY]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_ADD_REV_HISTORY_STATUS })
  },
  [ModuleEvents.ERROR_ADD_REV_HISTORY]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_ADD_REV_HISTORY_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  },
  [ModuleEvents.DELETE_SERIES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DELETE_SERIES_STATUS })
  },
  [ModuleEvents.ERROR_DELETE_SERIES]: ({ dispatch, payload, appState }) => {
    dispatch({ type: ModuleEvents.RESET_DELETE_SERIES_STATUS });
    dispatch({ type: ModuleEvents.VERIFY_SESSION });
  }
};
