import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment, Component } from "react";
import Loader from "react-loaders";
import { connect } from "react-redux";
import Actions from "../../internal/modules/Actions";
import LoginBoxed from "../../pages/login/index";
import App from "../../pages/index";
import { Translate } from "../../model/interfaces/translate.interface";
import { Translation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ConfirmComp from "../../pages/EmailConfirmation/Confirm";
import ResetPasswordComp from "../../pages/ResetPassword/ResetPassword";
import i18n from "../../i18n/I18n";

interface Props extends Translate {
  loginRandomNo: number;
  location?: any;
  fcmToken?: any;
  loginRandomNoAdmin: number;
  match?: any;
  reloadTemplate: (any) => {};
  logoutFlagIthomas: boolean;
  getUserById: (id: number) => {};
  getTabChange : (any) => {};
}

interface State {
  loading: boolean;
}
class AppMain extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    let lang = localStorage.getItem('i18nextLng')
    let role = sessionStorage.getItem('selectedRole')
    i18n.changeLanguage(lang)
    this.props.reloadTemplate(role);
  }

  static getDerivedStateFromProps(newState, prevProps) {
    return {
      // loading: newState.loading,
    };
  }
  handleIthomos = () => {
    if (this.props.logoutFlagIthomas === false) {
      const queryString = this.props.location.search;
      const obj1 = Object.fromEntries(new URLSearchParams(queryString));
      this.props.reloadTemplate("user");
      localStorage.setItem("token",JSON.stringify(obj1.token));
      var result= window.location.href.split('/');
      var Param = result[result.length-2];
      this.props.getTabChange(Param)
      this.props.getUserById(parseInt(obj1.id))
      if(!localStorage.getItem('change')){
        localStorage.setItem('i18nextLng',obj1.i18nextLng)
      }
      else{
        localStorage.setItem('i18nextLng',localStorage.getItem('change'))
      }
    
    }
  };
  render() {
    return (
      <Translation>
        {(t) => (
          <Fragment>
            {/* Components */}

            <Suspense
              fallback={
                <div className="loader-container">
                  <div className="loader-container-inner">
                    <div className="text-center">
                      <Loader type="ball-grid-beat" active={true} />
                    </div>
                    <h6 className="mt-3">{t("login.dashboardLoadMsg")}</h6>
                  </div>
                </div>
              }
            ></Suspense>
            <Route path="/app" component={App} />

            <Suspense
              fallback={
                <div className="loader-container">
                  <div className="loader-container-inner">
                    <div className="text-center">
                      <Loader type="ball-grid-beat" active={true} />
                    </div>
                    <h6 className="mt-3">{t("login.loginLoadMsg")}</h6>
                  </div>
                </div>
              }
            ></Suspense>
            {this.props.location.pathname.includes("/GRM") &&
              this.handleIthomos()
              }
            {this.props.location.pathname.includes("/email/confirm") && (
              <Route path="/email/confirm/:uniqueId" component={ConfirmComp} />
            )}
            {this.props.location.pathname.includes("/forgotPassword") && (
              <Route path="/forgotPassword/:ID" component={ResetPasswordComp} />
            )}

            {/* Redirecting user to login page if user is logged out */}
            {!this.props.location.pathname.includes("/forgotPassword") &&
            !this.props.location.pathname.includes("/email/confirm") &&
            (!localStorage.getItem("token") ||
              localStorage.getItem("token") === "null")
              ? (localStorage.getItem("shareURL") == "false" ||
                  !this.props.location.search.includes("share=true")) && (
                  <Redirect to="/login" />
                )
              : this.props.location.pathname === "/" && (
                  <Redirect to="/app/home" />
                )}

            {this.props.location.pathname.includes("/login") &&
              (!localStorage.getItem("token") ||
              localStorage.getItem("token") === "null" ? (
                <Route
                  path="/login"
                  render={() => (
                    <LoginBoxed
                      fcmToken={this.props.fcmToken}
                      {...this.props}
                    />
                  )}
                />
              ) : (
                <Redirect to="/app/home" />
              ))}
          </Fragment>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (AppState) => ({
  loginRandomNo: AppState.user.loginRandomNo,
  loginRandomNoAdmin: AppState.admin.loginRandomNoAdmin,
  logoutFlagIthomas: AppState.user.logoutFlagIthomas,
});

const mapDispatchToProps = (dispatch) => ({
  reloadTemplate: (role) => Actions.user.reloadTemplate(role, dispatch),
  getUserById: (userId) => Actions.user.getUserById(userId, dispatch),
  getTabChange: (tab) =>Actions.user.getTabChange(tab,dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppMain));
