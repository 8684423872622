import i18next from 'i18next';

export const RevisionColData = () => {
  return [
    {
        dataField: 'rowId',
        isKey: true,
        label: i18next.t('id'),
        hidden: true,
        placeholder: i18next.t('idPlaceholder')
    },
    {
        isKey: false,
        label: 'Action',
        hidden: false
    },
    {
        dataField: 'RegNumber',
        isKey: false,
        label: "Regulation Number",
        dataFormat: (cell, row) =>{
          return row?.regulationNumber;
        },
        hidden: false,
        placeholder: i18next.t('revHistory.regNumberPlaceholder')
    },
    {
        dataField: 'MarketName',
        isKey: false,
        label: "Market",
        dataFormat: (cell, row) =>{
          let mkt = ''
          if(row.market === 'ECE'){
              mkt = 'UNECE'
          }
          else if(row.market === 'EU'){
            mkt = 'EU27'
          }
          else{
            mkt = 'India'
          }
          return mkt;
        },
        hidden: false,
        placeholder: i18next.t('revHistory.marketPlaceholder')
    }
  ]
}

export const languageArr = [
  {
    id:1,
    code:"en"
  },
  {
    id:2,
    code:"de"
  }
];
