import React, {Component,Fragment, useState} from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from '../../internal/modules/Actions';
import { AppState } from '../../internal/modules/Reducers';
import { Translate } from '../../model/interfaces/translate.interface';
import { Translation  } from 'react-i18next';
import i18next from 'i18next';
import TranslationService from '../../utils/TranslationService';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import i18n from '../../i18n/I18n';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import filterFactory, { textFilter, numberFilter  } from 'react-bootstrap-table2-filter';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './UpdateDetailsTable.css';
import {toast} from 'react-toastify';
import Loader from 'react-loader-advanced';
import LoaderAnim from 'react-loaders';
import CustomSearchFilter from './CustomSearchFilter';

const products = [{
    id: 'Drive type',
    name: "All",
    price: "RWD"
}, {
    id: 'Fuel type',
    name: "BEV",
    price: "CNG"
},
{
  id: 'Vehicle category',
  name: "All",
  price: "M"
},
{
  id: 'Vehicle category',
  name: "All",
  price: "M"
}];
interface Props extends Translate{
  tableData?: any;
}

interface State{
  
}

class UpdateDetailsTableComp extends Component<Props, State> {
    constructor(props){
      super(props)
      this.state={
       
      }
    }
    modifiedAttribute = (cell,row) =>{
    if(cell === 'actualAddendum'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.addendum')}</b></div>
    </div>)
    }
    if(cell === 'actualAmendment'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.actualAmendCorr')}</b></div>
    </div>)
    }
    if(cell === 'actualEnforcementDateAllModel'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.actualEnforcementDateAllModel')}</b></div>
    </div>)
    }
    if(cell === 'enforcementDateNewModel'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.actualEnforcementDateNewModel')}</b></div>
    </div>)
    }
    if(cell === 'actualExpirationDate'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.actualExpirationDate')}</b></div>
    </div>)
    }
    if(cell === 'actualLastChange'){
      return(  <div className="row">
      <div className="col-5"><b>{i18next.t('advancedFilters.actualLastChangeDate')}</b></div>
    </div>)
    }
    if(cell === 'actualLinkTOAmendment'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.actualLinkTOAmendment')}</b></div>
    </div>)
    }
    if(cell === 'actualLinkToRevision'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.actualLinkToRevision')}</b></div>
    </div>)
    }
    if(cell === 'actualRegulationComments'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.actualComments')}</b></div>
    </div>)
    }
    if(cell === 'actualRegulationNumber'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.actualRegNo')}</b></div>
    </div>)
    }
    if(cell === 'actualRevision'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.revisionActual')}</b></div>
    </div>)
    }
    if(cell === 'actualSoftwareRelevant'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.softwareRelTitle')}</b></div>
    </div>)
    }
    if(cell === 'actualStatus'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.statusOfActual')}</b></div>
    </div>)
    }
    if(cell === 'actualStatusOfDocument'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.documentStatus')}</b></div>
    </div>)
    }
    if(cell === 'documents'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.documents')}</b></div>
    </div>)
    }
    if(cell === 'driveTypeNames'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.typeOfDrive')}</b></div>
    </div>)
    }
    if(cell === 'equivalentECERegulation'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.equivalentRegECE')}</b></div>
    </div>)
    }
    if(cell === 'equivalentEU27Regulation'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.equivalentRegEU27')}</b></div>
    </div>)
    }
    if(cell === 'equivalentFMVSSRegulation'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.equivalentRegFMVSS')}</b></div>
    </div>)
    }
    if(cell === 'equivalentRegulationComments'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.equivalentRegComments')}</b></div>
    </div>)
    }
    if(cell === 'equivalentRegulationOther'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.equivalentRegOthers')}</b></div>
    </div>)
    }
    if(cell === 'equivalentRegulationSource'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.equivalentRegSource')}</b></div>
    </div>)
    }
    if(cell === 'fuelTypeNames'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.fuelType')}</b></div>
    </div>)
    }
    if(cell === 'forecastClass'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('table.futureForecastClass')}</b></div>
    </div>)
    }
    // if(cell === 'futureAddendum'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('advancedFilters.addendum')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureAmendment'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('advancedFilters.futureAmendCorr')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureComments'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('advancedFilters.futureComments')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureEnforcementDateAllModel'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('advancedFilters.futureEnforcementDateAllModel')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureEnforcementDateNewModel'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('advancedFilters.futureEnforcementDateNewModel')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureExpirationDate'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('advancedFilters.typeOfDrive')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureForecastClass'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('advancedFilters.futureForecastClass')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureLinkTOAmendment'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('mngRegulations.futureLinkTOAmendment')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureLinkToRevision'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('mngRegulations.futureLinkToRevision')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureRegulationNumber'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('advancedFilters.futureRegNo')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureRevision'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('advancedFilters.revisionFuture')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureSoftwareRelevant'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('advancedFilters.softwareRelTitle')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureStatus'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('advancedFilters.statusOfFuture')}</b></div>
    // </div>)
    // }
    // if(cell === 'futureStatusOfDocument'){
    //   return(  <div className="row">
    //   <div className="col-12"><b>{i18next.t('advancedFilters.documentStatus')}</b></div>
    // </div>)
    // }
    if(cell === 'linkToAdditionalInfo'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('mngRegulations.linkToAdditionalInfo')}</b></div>
    </div>)
    }
    if(cell === 'linkToAuthority'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('mngRegulations.linkToAuthority')}</b></div>
    </div>)
    }
    if(cell === 'linkToTranslation'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.linkToTranslation')}</b></div>
    </div>)
    }
    if(cell === 'referenceAffectedByNames'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('mngRegulations.affectedBy')}</b></div>
    </div>)
    }
    if(cell === 'referenceDirectlyAffectsNames'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('mngRegulations.directlyAffects')}</b></div>
    </div>)
    }
    if(cell === 'referenceMentionedExampleAnnexNames'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('mngRegulations.mentionedExpAnnex')}</b></div>
    </div>)
    }
    if(cell === 'referenceNames'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('mngRegulations.references')}</b></div>
    </div>)
    }
    if(cell === 'shortDescription'){
      return(  <div className="row"><div className="col-12"><b>{i18next.t('advancedFilters.shortDesc')}</b></div>
    </div>)
    }
    if(cell === 'tags'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.typeOfDrive')}</b></div>
    </div>)
    }
    if(cell === 'title'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.title')}</b></div>
    </div>)
    }
    if(cell === 'vehicleNames'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.vehicleCategory')}</b></div>
    </div>)
    }
    if(cell === 'series'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.series')}</b></div>
    </div>)
    }
    if(cell === 'softwareRelevant'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.softwareRelevant')}</b></div>
    </div>)
    }
    if(cell === 'name'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.revName')}</b></div>
    </div>)
    }
    if(cell === 'link'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.link')}</b></div>
    </div>)
    }
    if(cell === 'enforcementDateAllModel'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.actualEnforcementDateAllModel')}</b></div>
    </div>)
    }
    if(cell === 'enforcementDateNewModel'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.actualEnforcementDateNewModel')}</b></div>
    </div>)
    }
    if(cell === 'expirationDate'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.actualExpirationDate')}</b></div>
    </div>)
    }
    if(cell === 'status'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.status')}</b></div>
    </div>)
    }
    if(cell === 'euRegulation'){
      return(  <div className="row">
      <div className="col-12"><b>{i18next.t('advancedFilters.actualRegNo')}</b></div>
    </div>)
    }
    }
    render() {
        const options = {
         noDataText: i18next.t('table.noDataMsg')
       };
       const spinner4 = <LoaderAnim active type="ball-beat"/>;
      return(
        <div id="updateDetailsTable">
           <Translation>{t => (
            <Fragment>
                 <BootstrapTable striped hover options={options} data={this.props.tableData }>
                 <TableHeaderColumn  isKey dataField='attribute' dataFormat={this.modifiedAttribute}>{t('homePage.attributeText')}</TableHeaderColumn>
                 <TableHeaderColumn dataField='oldValue'>{t('homePage.prevValueText')}</TableHeaderColumn>
                 <TableHeaderColumn dataField='newValue'>{t('homePage.updatedValueText')}</TableHeaderColumn></BootstrapTable>
            </Fragment>
          )}</Translation>
         </div>
        );
       }
    }
    const mapStateToProps = (appState: AppState) => ({

     });
    const mapDispatchToProps = dispatch => ({
       
    });
    export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateDetailsTableComp));
